import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { makeStyles, Switch } from "@material-ui/core";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from 'linkifyjs/html';

import FileCard from "./FileCard";

const useStyles = makeStyles((theme) => {
	return {
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto"
		},
	}
});

const BankStatements = forwardRef((props, _ref) => {
	const {
		data,
		refData,
		response,
		onChange,
		onPreview,
		additionalData,
	} = props;
	const classes = useStyles();
	const profileBankStatementUrl = refData?.bankStatementsUrl;
	const {
		hideDownload,
	} = additionalData;

	const [showOriginalName, setShowOriginalName] = useState(false);
	const hasValidOriginalName = useMemo(() => {
		return response.files.some(({ originalName, name }) => name && originalName && name !== originalName );
	}, [response.files]);

	const handleDelete = useCallback((fileIndexToDelete) => {
		const files = response.files.filter((file, index) => index !== fileIndexToDelete);

		onChange({
			...response,
			files,
		});
	}, [
		onChange,
		response,
	]);

	return (
		<div>
			<div className="d-flex mr-5 mr-md-0 pr-3">
				<div className="flex-grow-1">
					<span className="px-0 text-dark font-weight-medium f-18px">
						{data.title}
					</span>
				</div>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="mb-10px">
				<a
					href={profileBankStatementUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL_TEXT" />
				</a>
			</div>

			<div
				className={clsx(
					classes.fileListContainer,
					"rounded bg-white p-10px mb-10px",
					{ "d-none": !response.files.length }
				)}
			>
				<div className={classes.fileList}>
					{
						response.files.map((file, index, arr) => (
							<div
								key={index}
								className={clsx({
									"mb-5px": index !== arr.length - 1
								})}
							>
								<FileCard
									canDownload={!hideDownload && file._id}
									onDelete={() => handleDelete(index)}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();

										if (typeof onPreview !== "function") {
											console.log("preview function is not available");
											return;
										}

										onPreview(file);
									}}
									file={file}
								/>
							</div>
						))
					}
				</div>
			</div>

			{
					hasValidOriginalName && (
						<div className="d-flex align-items-center mt-5px px-3">
							<div className="f-16px text-muted">
								<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.ORIGINAL_FILE_NAME.LABEL" />
							</div>

							<div className="ml-2">
								<Switch
									checked={showOriginalName}
									classes={{
										thumb: showOriginalName ? 'bg-dark' : 'bg-white'
									}}
									onChange={(e) => setShowOriginalName(!showOriginalName)}
								/>
							</div>
						</div>
					)
				}
		</div>
	)
});

export default BankStatements;
