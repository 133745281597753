import React, { useCallback, useEffect, useState } from "react";
import { Icon, makeStyles, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import clsx from "clsx";

import CircularProgress from "../CircularProgress";
import { removeTask } from "../../store/modules/actions/tasks.actions";
import { CancelIcon } from "../icons";
import CircleCheck from "../icons/CircleCheck";
import CancelCircle from "../icons/CancelCircle";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			position: "sticky",
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
			bottom: 25,
			width: 350,
			minWidth: 350,
			zIndex: 99999,
			[`@media (max-width: 768px)`]: {
				width: 350,
				minWidth: "auto",
				bottom: 50,
			},
			[`@media (max-width: 400px)`]: {
				width: 300,
				minWidth: "auto",
				bottom: 50,
			}
		},
		anchorLeft: {
			marginLeft: 25,
			marginRight: "auto",
			[`@media (max-width: 768px)`]: {
				marginLeft: "auto",
			},
			[`@media (max-width: 400px)`]: {
				marginLeft: "auto",
			}
		},
		anchorRight: {
			marginRight: 25,
			marginLeft: "auto",
			[`@media (max-width: 768px)`]: {
				marginRight: "auto",
			},
			[`@media (max-width: 400px)`]: {
				marginRight: "auto",
			}
		},
		taskRow: {
			height: 75,
		}
	}
})

export const TASK_VIEWER_ANCHOR = {
	LEFT: "LEFT",
	RIGHT: "RIGHT",
}

const TaskStatusViewer = ({ anchor }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = useStyles();

	const tasks = useSelector(({ tasks }) => tasks);
	const [anchorClass, setAnchorClass] = useState(classes.anchorRight);
	const [hoveringOverTask, setHoveringOverTask] = useState("");

	useEffect(() => {
		let className = classes.anchorRight;

		if (anchor === TASK_VIEWER_ANCHOR.LEFT) {
			className = classes.anchorLeft;
		}

		setAnchorClass(className);
	}, [anchor, classes]);

	const cancelAllTasks = useCallback(() => {
		_.forEach(tasks.status, (task, taskId) => {
			dispatch(removeTask(taskId));
		});
	}, [
		tasks,
		dispatch,
	]);

	return (
		<div className={clsx(classes.container, anchorClass, { "d-none": _.isEmpty(tasks.status) })}>
			<div className="bg-primary text-white p-15px d-flex rounded-top">
				<div className="flex-grow-1 mr-4">
					<FormattedMessage id="REQUEST.DOWNLOAD_ALL_FILES_DIALOG_TITLE" />
				</div>
				<div
					className="cursor-pointer"
					onClick={cancelAllTasks}
				>
					<CancelIcon fill="white" />
				</div>
			</div>

			{
				_.map(tasks.status, (task, taskId) => {
					return (
						<div
							key={taskId}
							className={clsx("px-25px d-flex align-items-center bg-white rounded-bottom", classes.taskRow)}
							onMouseEnter={() => setHoveringOverTask(taskId)}
							onMouseLeave={() => setHoveringOverTask(null)}
						>
							{
								task.icon && (
									<div className="pr-20px">
										<Icon color={task.iconColor || "primary"}>{task.icon}</Icon>
									</div>
								)
							}
							<div className={clsx("flex-grow-1 pr-20px max-line-1")}>
								{task.text}
							</div>

							{
								task.progress > -1 && (
									<div>
										{
											hoveringOverTask === taskId ? (
												<div className="cursor-pointer" onClick={() => dispatch(removeTask(taskId))}>
													<CancelCircle size={25} fill={theme.palette.grey.A400} />
												</div>
											) : (
												task.progress === 100 ? (
													<CircleCheck size={25} fill={theme.palette.success.main} />
												) : (
													<CircularProgress
														size={25}
														thickness={7}
														value={task.progress}
														variant="static"
														className="text-success"
													/>
												)
											)
										}
									</div>
								)
							}
						</div>
					);
				})
			}
		</div>
	)
};

export default TaskStatusViewer;
