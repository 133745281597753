import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import {
	Card,
	CardContent,
	useMediaQuery,
	useTheme,
	makeStyles,
} from "@material-ui/core";
import {
	ReportProblemSharp,
	DoneAll,
	ErrorOutline,
} from "@material-ui/icons";
import {
	useParams
} from "react-router-dom";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	useIntl,
} from "react-intl";
import clsx from "clsx";
import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce/lib";

import {
	saveProviderResponse,
	performOperation,
	updateProviderStatus,
	V2_OPERATIONS,
	setElementsWithError,
	updateDocument,
	flagNames,
	updateElementStatus,
} from '../../../../store/modules/actions/provider.actions';
import List from '../../../../common/dnd-element-list/List';
import { ELEMENT_TYPES } from '../../../../common/dnd-element-list/ElementType';
import Constants from '../../../../common/constants';
import { InfoIcon } from '../../../../common/icons';

import ChatModal from "../../Chat";
import ElementComments from "../../ElementComments";

import MarkNotRequired from "../../MarkNotRequiredV2";
import Confirm from "../../../../common/modals/confirm";
import { CalendarIcon, DangerBlocksIcon } from "../../../../common/icons";
import Confetti from "../../Confetti";
import { DarkBorderLinearProgress, PrimaryBorderLinearProgress } from "../../../../common/LinearProgress";
import Preview from "../../../../common/Preview";
import getIncompleteElements from "../../../../utils/get-incomplete-elements";
import { READ_ONLY_TYPES } from "../../../../common/dnd-element-list/Element";
import { addAlpha } from "../../../../../_metronic";
import HeaderLogo from "../../HeaderLogo";
import { doesRequirePassword, getElementToMarkNotRequired, getElementToViewCommentsFor, getElementsWithError, getFileToPreview, getProvider } from "../../../../store/modules/selectors/provider.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		layoutContainer: {
			width: 932,
			'@media (max-width:850px)': { // eslint-disable-line no-useless-computed-key
				width: "inherit",
				padding: "0 15px",
			},
		},
		dangerAlert: {
			background: addAlpha(theme.palette.danger.main, 0.15),
			border: '1px solid ' + addAlpha(theme.palette.danger.main, 0.3),
			color: theme.palette.danger.main,
		},
		markAsCompletedButton: {
			[theme.breakpoints.up('md')]: {
				maxWidth: 250,
			},
		},
		unreadCommentsCount: {
			height: 28,
			width: 28,
			borderRadius: 14,

			[theme.breakpoints.down('sm')]: {
				position: 'absolute',
				top: 0,
				right: 0,
				transform: 'translate(50%, -35%)'
			},
		},
		markCompletedModalIcon: {
			'& svg': {
				fontSize: 54,
			}
		},
		footer: {
			marginBottom: 150,
			'& .MuiLinearProgress-root': {
				width: 200,
			}
		},
		layoutFooter: {
			background: theme.palette.extraColors.blueSecondary,
			height: 100,
		}
	}
})

function Provider(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const theme = useTheme();
	const classes = useStyles();
	const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const intl = useIntl();
	// somehow it was not working with useState
	const [state, setComponentState] = useState({
		showChatModal: false,
		showPreviewWarning: false,
		showMarkCompletedModal: false,
		showMarkCompletedSuccessModal: false,
		incompleteWarnings: [],
		documentIdToShowUploadsOf: null,
		password: null,
		document: null,
		previewInfo: null,
		deleteFileInfo: null,
		tooltipStatus: false,
		hasTriedToComplete: false,
	});

	useEffect(() => {
		document.body.classList.add("modern-input-container");

		return () => {
			document.body.classList.remove("modern-input-container");
		}
	}, []);

	const setState = useCallback((obj) => {
		setComponentState({
			...state,
			...obj,
		});
	}, [
		state
	]);

	const provider = useSelector(getProvider);
	const elementToViewCommentsFor = useSelector(getElementToViewCommentsFor);
	const fileToPreview = useSelector(getFileToPreview);
	const elementToMarkNotRequired = useSelector(getElementToMarkNotRequired);
	const isPasswordProtected = useSelector(doesRequirePassword);
	const flags = useSelector(getFlags);
	const elementsWithError = useSelector(getElementsWithError);

	const {
		data: {
			dueDate,
			firstName,
			lastName,
			status: providerStatus,
			documentRequest,
		},
	} = provider;

	useEffect(() => {
		const elementsWithError = getIncompleteElements(provider.data.elements);
		dispatch(setElementsWithError(elementsWithError));
	}, [provider.data.elements, dispatch]);

	const {
		completedElementCount,
		totalUpdatableElements,
	} = useMemo(() => {
		const actionableElements = provider.data.elements.filter(({ type }) => {
			const isReadOnlyElement = READ_ONLY_TYPES.includes(type);
			return !isReadOnlyElement;
		});
		const totalUpdatableElements = actionableElements.length;
		const completedElementCount = actionableElements.reduce((p, { status }) => {
			const submittedToRequester = [
				Constants.DocumentStatus.APPROVED,
				Constants.DocumentStatus.NOT_REQUIRED,
				Constants.DocumentStatus.UPLOADED,
			].includes(status);

			return submittedToRequester ? p+1 : p;
		}, 0);
		
		return {
			totalUpdatableElements,
			completedElementCount,
		};
	}, [provider.data.elements]);

	const isRequestUnderReview = useMemo(() => isUnderReview(providerStatus), [providerStatus]);

	const hideCompleteButton = useMemo(() => {
		const areAllElementsRequested = provider.data.elements
			.filter(({ type }) => {
				const isReadOnlyElement = READ_ONLY_TYPES.includes(type);
				return !isReadOnlyElement;
			})
			.every(({ status }) => status === Constants.DocumentStatus.REQUESTED);

		return areAllElementsRequested;
	}, [provider.data.elements]);
	const disableCompleteButton = useMemo(() => {
		const areAllElementsApproved = provider.data.elements
			.filter(({ type }) => {
				const isReadOnlyElement = READ_ONLY_TYPES.includes(type);
				return !isReadOnlyElement;
			})
			.every(({ status }) => status === Constants.DocumentStatus.APPROVED)

		const canMarkComplete = [
			Constants.ProviderStatus.IN_PROGRESS,
			Constants.ProviderStatus.PENDING,

			// TODO: ask gani about this
			// Constants.ProviderStatus.CANCELLED,
		].includes(providerStatus);

		return areAllElementsApproved || !canMarkComplete;
	}, [provider.data.elements, providerStatus]);

	const getRequestCompletionErrors = useCallback(() => {
		const {
			data: {
				documentRequest,
				elements,
			}
		} = provider;

		if (documentRequest.status === Constants.ProviderStatus.COMPLETED) {
			return [
				intl.formatMessage({ id: "PROVIDER.MARK_COMPLETED.ALREADY_COMPLETED" }),
			];
		}
		
		return elements
			.filter((element) => elementsWithError[element._id])
			.map((element) => element.dataObj.title);
	}, [provider, intl, elementsWithError]);

	const handlePreview = useCallback((fileRecord) => {
		const {
			_id: fileId,
		} = fileRecord;
		let wasUploadedByProvider = false;

		const {
			data: {
				elements,
			}
		} = provider;

		for (const element of elements) {
			if (element.type === ELEMENT_TYPES.FILE_UPLOAD) {
				const isFilePartOfElement = element.responseObj.files.some((file) => file._id === fileId);

				if (isFilePartOfElement) {
					wasUploadedByProvider = true;
					break;
				}
			}
			else if (element.type === ELEMENT_TYPES.SHARED_FILES) {
				const isFilePartOfElement = element.dataObj.attachments.some((file) => file._id === fileId);

				if (isFilePartOfElement) {
					wasUploadedByProvider = false;
					break;
				}
			}
			else if (element.type === ELEMENT_TYPES.BANK_STATEMENTS) {
				const isFilePartOfElement = element.responseObj.files.some((file) => file._id === fileId);

				if (isFilePartOfElement) {
					wasUploadedByProvider = true;
					break;
				}
			}
			else if (element.type === ELEMENT_TYPES.VIDEO_INPUT) {
				const isFilePartOfElement = element.responseObj.files.some((file) => file._id === fileId);
				
				if (isFilePartOfElement) {
					// for now we want to allow c2 to preview uploaded files even if it is password protected
					wasUploadedByProvider = false;
					break;
				}
			}
			else if (element.type === ELEMENT_TYPES.AUDIO_INPUT) {
				const isFilePartOfElement = element.responseObj.files.some((file) => file._id === fileId);
				
				if (isFilePartOfElement) {
					// for now we want to allow c2 to preview uploaded files even if it is password protected
					wasUploadedByProvider = false;
					break;
				}
			}
		}

		debugger;
		if (!wasUploadedByProvider || isPasswordProtected) {
			dispatch(
				performOperation(
					V2_OPERATIONS.PREVIEW,
					fileRecord,
				)
			)
		}
		else {
			setState({
				showPreviewWarning: true,
			})
		}
	}, [provider, isPasswordProtected, dispatch, setState]);

	const handleDeleteFileSubmit = useCallback(async (isSuccess) => {
		if (isSuccess) {
			let selectedDocument = null;

			for (let doc of provider.data.documents) {
				if (doc._id === state.deleteFileInfo.doc._id) {
					selectedDocument = doc;
					break;
				}
			}

			const updatedFiles = selectedDocument.files.filter((file) => file._id !== state.deleteFileInfo.file._id);

			if (selectedDocument) {
				await dispatch(updateDocument(
					params.request,
					params.provider,
					state.deleteFileInfo.doc._id,
					{ files: updatedFiles }
				));
			}
		}

		setState({ deleteFileInfo: null });
	}, [
		dispatch,
		params,
		provider,
		setState,
		state
	])

	const handleMarkComplete = useCallback(() => {
		const errors = getRequestCompletionErrors();
		const hasNoErrors = errors.length === 0;

		setState({
			hasTriedToComplete: true,
			incompleteWarnings: errors,
			showMarkCompletedModal: hasNoErrors,
		});

		if (hasNoErrors && window._sva) {
			const {
				data: {
					_id,
					firstName,
					lastName,
					email,
					documentRequest: {
						user: {
							company
						}
					},
					requestId,
				}
			} = provider;

			const visitorDetails = {
				user_id: _id,
				first_name: firstName,
				last_name: lastName,
				email: email,
				organization: company,
				kaddim_request_id: requestId,
			};

			window._sva.setVisitorTraits(visitorDetails);
		}
	}, [
		getRequestCompletionErrors,
		setState,
		provider,
	]);

	const handleMarkCompleteSubmit = useCallback(async (wasSubmitted) => {
		const objectToUpdate = {
			showMarkCompletedModal: false,
		};

		if (wasSubmitted) {
			const {
				data: {
					requestId,
					_id: providerId,
				}
			} = provider;
			await dispatch(
				updateProviderStatus(
					requestId,
					providerId,
					Constants.ProviderStatus.COMPLETED
				),
			);
			objectToUpdate.showMarkCompletedSuccessModal = true;
		}

		setState(objectToUpdate);
	}, [dispatch, provider, setState]);

	const debouncedSaveResponse = useDebouncedCallback(
		(elements) => {
			dispatch(saveProviderResponse(elements))
		},
		500
	);

	const handleProviderResponse = useCallback((provider) => {
		if (!provider) return;
		debouncedSaveResponse(provider.elements);
	}, [
		debouncedSaveResponse
	]);

	const chatService = _.get(documentRequest, `user.chatService`, true);

	return (
		<div className="pb-25 modern-input-container">
			<div className={clsx("mx-auto", classes.layoutContainer)}>
				<div className="provider-container mb-0 mb-md-3">
					<div>
						<div className="mt-40px px-4 px-md-0 flex-1 mb-md-0 mr-0 f-15px mr-md-5 text-dark">
							<FormattedHTMLMessage
								id={`PROVIDER.TITLE.V2`}
								values={{
									requesterName: `${documentRequest.user.firstName} ${documentRequest.user.lastName}`,
									// todo: this value is undefined
									providerName: `${firstName} ${lastName}`
								}}
							/>
						</div>

						{
							isRequestUnderReview && (
								<div className={clsx("my-3 py-3 rounded d-flex align-items-center justify-content-center", classes.dangerAlert)}>
									<div className="mr-15px">
										<ErrorOutline />
									</div>
									<div>
										<FormattedMessage id="REQUESTS.PROVIDER.IN_REVIEW_WARNING" />
									</div>
								</div>
							)
						}

						<Card elevation={0} className="request-status-changer px-4 py-3 rounded mt-15px mb-4 mb-md-5">
							<CardContent className="p-0 d-flex justify-content-between align-items-center flex-column flex-md-row">
								{ /* we have different layouts for below and above md media query due to complex layout configurations */}
								<div className="flex-3 d-flex flex-column flex-md-row w-100">
									{ /* layout for and above md */}
									<div className="mb-md-0 mb-3 mr-0 mr-md-3 flex-1 d-flex align-items-center justify-content-center">
										<div
											className="flex-grow-1 d-flex px-15px py-10px"
											style={{
												background: "rgba(255, 184, 0, 0.1)",
												borderRadius: 30,
											}}
										>
											<div className="f-15px font-weight-light">
												<FormattedMessage id="PROVIDER.PROGRESS.TEXT" />
											</div>
											<div className="flex-grow-1	d-flex align-items-center mx-10px">
												<DarkBorderLinearProgress
													className="flex-1"
													variant="determinate"
													value={(completedElementCount / totalUpdatableElements) * 100}
												/>
											</div>
											<div className="d-flex ml-2 align-items-center">
												<div className="d-flex text-dark align-items-end">
													<div className="f-12px font-weight-medium">{completedElementCount}</div>
													<div className="f-10px font-weight-light" style={{ marginBottom: 1 }}>/</div>
													<div className="f-10px font-weight-light" style={{ marginBottom: 1 }}>{totalUpdatableElements}</div>
												</div>
											</div>
										</div>
									</div>

									<div
										className={
											clsx(
												"mb-md-0 mr-md-3 mr-0 d-flex align-items-center justify-content-center",
												{ "mb-4": !hideCompleteButton }
											)
										}
									>
										<div
											className="px-15px font-weight-light py-10px d-flex align-items-center flex-grow-1 text-center justify-content-center"
											style={{
												background: theme.palette.extraColors.backgroundBlue,
												borderRadius: 30,
											}}
										>
											<div className="mr-10px mb-1">
												<CalendarIcon fill={theme.palette.dark.primary.main} />
											</div>
											<div className="f-15px">
												<FormattedMessage
													id="PROVIDER.DUE_DATE_RELATIVE.V2"
													values={{
														dateString: moment(dueDate).format("DD MMM YYYY"),
													}}
												/>
											</div>
										</div>
									</div>

									<div className="mb-0 mb-md-0 flex-1 d-flex flex-column align-items-end col-23 pr-0">
										<button
											className={
												clsx(
													classes.markAsCompletedButton,
													"flex-grow-1 justify-content-center align-items-center px-25px py-15px btn btn-success w-100",
													{
														"d-none": hideCompleteButton,
														"d-flex": !hideCompleteButton,
														"btn-disabled btn-secondary": disableCompleteButton
													}
												)
											}
											onClick={handleMarkComplete}
										>
											<div className="f-16px font-weight-bold mr-10px">
												<FormattedMessage id="PROVIDER.MARK_COMPLETED.TITLE" />
											</div>
											<DoneAll fontSize="large" />
										</button>
									</div>
								</div>
							</CardContent>
						</Card>
					</div>

					<div>
						<div className="d-flex flex-column-reverse flex-md-row justify-content-start w-100 order-2 order-md-1">
							<div
								className={clsx(
									"flex-grow-1 justify-content-between justify-content-md-end align-items-center mb-5 mb-md-0",
									{
										"d-flex flex-column flex-md-row": chatService,
										"bg-transparent": !isDownMd,
										"bg-white px-20px py-15px": isDownMd,
										"d-none": !chatService
									}
								)}
							>
								<div
									className={
										clsx(
											"mb-3 mb-md-0 f-16px text-dark",
											{
												"font-weight-bold": isDownMd,
											}
										)
									}
								>
									<FormattedMessage
										id="PROVIDER.ASK_QUESTION_NEW"
										values={{
											name: `${documentRequest.user.firstName} ${documentRequest.user.lastName}`,
										}}
									/>
								</div>
								<div className={clsx("ml-md-3 ml-0", { "w-100": isDownMd })}>
									<div className="d-flex align-items-center">
										<button
											className={clsx(
												"btn btn-dark btn-sm d-flex align-items-center justify-content-center f-16px",
												{
													"py-5px px-10px": !isDownMd,
													"btn-block py-10px": isDownMd,
												}
											)}
											onClick={() => setState({ showChatModal: true })}
										>
											<div className="mr-10px d-flex align-items-center justify-content-center">
												<span className="material-icons-outlined">
													question_answer
												</span>
											</div>
											<FormattedMessage id="REQUEST.PROVIDER.CHAT.TITLE" />

											{
												provider.data.unreadMessagesCount > 0 && (
													<div className={clsx(classes.unreadCommentsCount, "ml-2 p-2 d-flex justify-content-center align-items-center bg-danger text-light")}>
														{provider.data.unreadMessagesCount}
													</div>
												)
											}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div className="bg-white rounded p-3 p-md-4">
						<Formik
							initialValues={provider.data}
							enableReinitialize
							onSubmit={handleProviderResponse}
						>
							{({
								values,
								setFieldValue,
								submitForm
							}) => {
								return (
									<div className={clsx("bg-light-blue rounded", { "pointer-events-none": isRequestUnderReview })}>
										<List
											className="p-0 p-md-1"
											elements={values.elements}
											providerMode
											handleChange={(elements) => {
												setFieldValue('elements', elements)
												submitForm();
											}}
											handlePreview={handlePreview}
											additionalData={{
												providerUUID: provider.data.uuid,
												requestUUID: provider.data.requestId,
												hideDownload: !isPasswordProtected,
												hideDownloadAllFiles: true,

												// dont show error status of elements unless 
												// user presses on mark as complete button
												elementsWithError: state.hasTriedToComplete
													? elementsWithError
													: {},
											}}
										/>
									</div>
								)
							}}
						</Formik>
					</div>
				</div>

				<div
					className={
						clsx(
							classes.footer,
							"mt-15px rounded bg-white p-20px flex-column flex-md-row justify-content-between shadow-sm",
							{
								"d-none": hideCompleteButton,
								"d-flex": !hideCompleteButton,
							}
						)
					}
				>
					<div className="col-12 col-md-6 d-flex">
						<button
							className={
								clsx(
									classes.markAsCompletedButton,
									"flex-grow-1 shadow d-flex justify-content-center align-items-center px-25px py-15px btn btn-success",
									{
										"btn-disabled btn-secondary": disableCompleteButton,
									}
								)
							}
							onClick={handleMarkComplete}
						>
							<div className="f-16px font-weight-bold mr-10px">
								<FormattedMessage id="PROVIDER.MARK_COMPLETED.TITLE" />
							</div>
							<DoneAll fontSize="large" />
						</button>
					</div>
					<div className="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">
						<div>
							<div className="f-15px mb-10px">
								<FormattedMessage id="PROVIDER.PROGRESS.TEXT" />
							</div>
							<div className="flex-grow-1	d-flex align-items-center">
								<div>
									<PrimaryBorderLinearProgress
										className="flex-1"
										variant="determinate"
										value={(completedElementCount/totalUpdatableElements)*100}
									/>
								</div>
								<div className="d-flex ml-2 align-items-center">
									<div className="d-flex text-dark align-items-end">
										<div className="f-12px font-weight-medium">{completedElementCount}</div>
										<div className="f-10px font-weight-light" style={{ marginBottom: 1 }}>/</div>
										<div className="f-10px font-weight-light" style={{ marginBottom: 1 }}>{totalUpdatableElements}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				open={state.showMarkCompletedModal}
				icon={
					<div className={clsx("text-success", classes.markCompletedModalIcon)}>
						<DoneAll />
					</div>
				}
				variant="success"
				handleClose={handleMarkCompleteSubmit}
				title="PROVIDER.V2_MARK_COMPLETED.TITLE"
				message={
					intl.formatMessage({
						id: "PROVIDER.V2_MARK_COMPLETED.MESSAGE"
					}, {
						requesterName: `${documentRequest.user.firstName} ${documentRequest.user.lastName}`,
					})
				}
				submitButtonText="GENERAL.CONFIRM"
				cancelButtonText="GENERAL.CANCEL"
			/>

			{
				fileToPreview && (
					<Preview
						canDownload={isPasswordProtected}
						file={fileToPreview}
						handleClose={() => dispatch(performOperation())}
					/>
				)
			}

			
			{
				state.incompleteWarnings && state.incompleteWarnings.length > 0 && (
					<Confirm
						open
						icon={<ReportProblemSharp color="error" style={{ height: 80, width: 100 }} />}
						variant="danger"
						handleClose={() => setState({ incompleteWarnings: [] })}
						title="PROVIDER.MARK_COMPLETED.INCOMPLETE_REQUEST"
						message={
							<ol>
								{
									state.incompleteWarnings.map((error, index) => (
										<li
											key={index}
											className="text-left text-uppercase"
										>
											{error}
										</li>
									))
								}
							</ol>
						}
						submitButtonText="GENERAL.DISMISS"
					/>
				)
			}

			{
				state.deleteFileInfo && state.deleteFileInfo.doc && state.deleteFileInfo.file && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={handleDeleteFileSubmit}
						title="PROVIDER.DOCUMENT.FILE.DELETE.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.DOCUMENT.FILE.DELETE.DESCRIPTION"
							}, {
								documentName: state.deleteFileInfo.doc.name,
								fileName: state.deleteFileInfo.file.name
							})
						}
						loading={flags.loading[flagNames.UPDATE]}
						error={flags.error[flagNames.UPDATE]}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			<Confirm
				open={state.showMarkCompletedSuccessModal}
				icon={
					<div className={clsx("text-success", classes.markCompletedModalIcon)}>
						<DoneAll />
					</div>
				}
				variant="success"
				handleClose={() => setState({ showMarkCompletedSuccessModal: false })}
				title="PROVIDER.V2_MARKED_COMPLETED.TITLE"
				submitButtonText="GENERAL.DONE"
			/>
			<Confetti show={state.showMarkCompletedSuccessModal} />

			{
				state.showChatModal && chatService && (
					<ChatModal
						handleClose={() => setState({ showChatModal: false })}
					/>
				)
			}

			{
				!!elementToViewCommentsFor && (
					<ElementComments element={elementToViewCommentsFor} />
				)
			}

			{
				!!elementToMarkNotRequired && (
					<MarkNotRequired
						id={elementToMarkNotRequired._id}
						name={elementToMarkNotRequired.dataObj.title}
						handleClose={() => dispatch(performOperation())}
						handleMarkNotRequired={async (reason) => {
							await dispatch(
								updateElementStatus(
									params.request,
									params.provider,
									elementToMarkNotRequired._id, {
										markNotRequiredReason: reason,
										status: Constants.DocumentStatus.NOT_REQUIRED,
									}
								)
							);
						}}
					/>
				)
			}

			<Confirm
				open={state.showPreviewWarning}
				icon={<InfoIcon />}
				variant="primary"
				handleClose={() => setState({ showPreviewWarning: false })}
				title="PREVIEW.NO_PASSWORD_WARNING"
				submitButtonText="GENERAL.DISMISS"
			/>

			<div className={clsx(classes.layoutFooter, "d-flex justify-content-center align-items-center flex-column border-top text-center")}>
				<div className="pt-25px f-18px">
					<FormattedHTMLMessage id="APP.PROVIDER.FOOTER" />
				</div>

				<div className="pt-15px">
					<HeaderLogo appTitleClassName="f-24px" />
				</div>
			</div>
		</div>
	);
}

export function isUnderReview (status) {
	return status === Constants.ProviderStatus.IN_REVIEW;
};

export default Provider;