import React, { useEffect, useRef, useState } from "react";
import { FormControlLabel, makeStyles, TextareaAutosize } from "@material-ui/core";
import { CloseOutlined, DragHandleOutlined } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce/lib";
import clsx from "clsx";

import Checkbox from "./Checkbox";
import ErrorMessage from "../ErrorMessage";

const useStyles = makeStyles((theme) => {
	return {
		customOptionCheckbox: {
			position: 'relative',
			top: -2,
		}
	};
});

const Option = ({
	index,
	isElementActive,
	dragHandleProps,
	data,
	onChange,
	onSelect,
	onKeyPress,
	onDelete,
	isSelected,
	error,
}) => {
	const intl = useIntl();
	const ref = useRef();
	const [isActive, setIsActive] = useState(false);

	const shouldShowControls = isActive && isElementActive;

	useEffect(() => {
		ref.current.value = data.title;
	}, [data.title]);

	const handleTitleChange = useDebouncedCallback((title) => {
		if (!onChange) {
			return;
		}

		// dont listen to changes in onChange field since it depends on options array but triggering it also 
		// updates the options so it goes into loop call 
		// onChange -> 
		// update options -> 
		// update onChange -> 
		// this useEffect gets triggered -> 
		// call onChange
		// eslint-disable-next-line react-hooks/exhaustive-deps
		onChange({
			target: {
				value: title,
			}
		});
	}, 1000);

	return (
		<div
			className="d-flex mb-1 align-items-start"
			onMouseOver={() => setIsActive(true)}
			onMouseOut={() => setIsActive(false)}
		>
			<div>
				<FormControlLabel
					className="mr-0 mb-0 mt-2"
					control={
						<Checkbox
							className="py-0"
							checked={isSelected}
							onClick={onSelect}
							color="primary"
							tabIndex="-1"
						/>
					}
				/>
			</div>
			<div className="invisible-input-container col-9 pl-0">
				<TextareaAutosize
					ref={(inputRef) => ref.current = inputRef}
					minRows={1}
					className="span mb-0 f-16px text-light-text w-100 resize-none border-0 bg-transparent"
					onChange={(e) => handleTitleChange(e.target.value)}
					onKeyPress={onKeyPress}
					defaultValue={data.title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.CHOICES.TITLE.PLACEHOLDER",
						}, {
							index: index + 1,
						})
					}
				/>

				{
					error && (
						<ErrorMessage message={error} />
					)
				}
			</div>

			<div
				style={{ visibility: shouldShowControls ? "visible" : "hidden", cursor: "move" }}
				className="mr-3 d-flex align-items-center option-drag-handle cursor-pointer p-2"
				{...dragHandleProps}
			>
				<DragHandleOutlined />
			</div>

			<div
				className="cursor-pointer d-flex align-items-center p-2"
				style={{ visibility: shouldShowControls ? "visible" : "hidden" }}
				onClick={onDelete}
			>
				<CloseOutlined />
			</div>
		</div>
	);
}

const CustomCheckboxOption = ({
	data,
	isElementActive,
	onDelete,
	error,
	isSelected,
	onChange,
	onSelect,
	onKeyPress,
}) => {
	const classes = useStyles();
	const intl = useIntl();
	const [isActive, setIsActive] = useState(false);
	const ref = useRef();

	useEffect(() => {
		ref.current.value = data.title;
	}, [data.title]);

	const handleTitleChange = useDebouncedCallback((title) => {
		if (!onChange) {
			return;
		}
		// dont listen to changes in onChange field since it depends on options array but triggering it also 
		// updates the options so it goes into loop call 
		// onChange -> 
		// update options -> 
		// update onChange -> 
		// this useEffect gets triggered -> 
		// call onChange
		// eslint-disable-next-line react-hooks/exhaustive-deps
		onChange({
			target: {
				value: title,
			}
		});
	}, 1000);

	const shouldShowControls = isActive && isElementActive;

	return (
		<div
			className="d-flex mb-1 align-items-start"
			onMouseOver={() => setIsActive(true)}
			onMouseOut={() => setIsActive(false)}
		>
			<div>
				<FormControlLabel
					className="mr-0 mb-0 mt-2"
					control={
						<Checkbox
							className={clsx("py-0", classes.customOptionCheckbox)}
							checked={isSelected}
							onClick={onSelect}
							color="primary"
							tabIndex="-1"
						/>
					}
				/>
			</div>
			<div className="invisible-input-container col-9 pl-0">
				<TextareaAutosize
					ref={(inputRef) => ref.current = inputRef}
					minRows={1}
					className="span mb-0 f-16px text-light-text w-100 resize-none border-0 bg-transparent"
					onChange={(e) => handleTitleChange(e.target.value)}
					onKeyPress={onKeyPress}
					defaultValue={data.title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.CHECKBOXES.CHOICES.CUSTOM_CHOICE.TITLE.PLACEHOLDER"
						})
					}
					disabled={!onChange}
				/>

				{
					error && (
						<ErrorMessage message={error} />
					)
				}
			</div>

			<div
				className="cursor-pointer d-flex align-items-center p-2"
				style={{ visibility: shouldShowControls ? "visible" : "hidden" }}
				onClick={onDelete}
			>
				<CloseOutlined />
			</div>
		</div>
	)
}

export const CustomOption = CustomCheckboxOption;
export default Option;