import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";

import {
	performOperation,
} from '../../../../store/modules/actions/requests.actions';
import Confirm from "../../../../common/modals/confirm";

export default function DeleteElement({
	onSubmit,
}) {
	const dispatch = useDispatch();
	const theme = useTheme();
	
  const handleClose = useCallback(() => {
		dispatch(performOperation());
	}, [
		dispatch,
	]);

	return (
		<Confirm
			open
			icon={
				<DeleteOutline
					style={{
						color: theme.palette.dark.primary.main,
						fontSize: 65
					}}
				/>
			}
			variant="dark"
			handleClose={async (wasSubmitted) => {
				if (wasSubmitted) {
					onSubmit();
				}
        else {
				  handleClose(wasSubmitted);
        }
			}}
			title="ELEMENT_LIST.ITEM.DELETE.CONFIRM_MESSAGE"
			submitButtonText="GENERAL.CONFIRM"
			cancelButtonText="GENERAL.CANCEL"
		/>
	);
}