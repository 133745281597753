import React, { useState } from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	Card,
	CardContent,
} from "@material-ui/core";
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import {
	useParams
} from "react-router-dom";
import {
	getProviderData,
	flagNames,
} from '../../store/modules/actions/provider.actions';
import Input from "../../common/Input";
import ResetPassword from "./ResetPassword/index";
import * as builder from "../../../_metronic/ducks/builder";
import { getFlags } from "../../store/modules/selectors/common.selector";

function ValidatePassword(props) {
	const params = useParams();
	const {
		request,
		provider
	} = params;
	const {
		handleClose,
		getProviderData,
		intl,
	} = props;

	const flags = useSelector(getFlags);
	const headerLogo = useSelector(builder.selectors.getLogo);

	const [showResetPassword, setShowResetPassword] = useState(false);

	const isLoading = flags.loading[flagNames.PROVIDER_DATA];
	const error = flags.error[flagNames.PROVIDER_DATA];

	if (showResetPassword) {
		return (
			<ResetPassword
				onComplete={() => setShowResetPassword(false)}
			/>
		);
	}

	return (
		<div className="container px-4">
			<div className="py-50 row flex-grow-1">
				<div className="col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 px-0">
					<div className="f-30 strong mb-25 text-center">
						<FormattedMessage id="PROVIDER.VALIDATE_PASSWORD.TITLE" />
					</div>
					<div className="m-0 f-15 text-center mb-40">
						<FormattedMessage id="PROVIDER.VALIDATE_PASSWORD.DESCRIPTION" />
					</div>

					<Card className="rounded-card" elevation={0}>
						<CardContent className='p-25 m-25'>
							{
								// <div className="text-center mb-50">
								// 	<h3>
								// 		<FormattedMessage id="PROVIDER.VALIDATE_PASSWORD.CARD_TITLE" />
								// 	</h3>
								// </div>
							}

							<Formik
								initialValues={{
									password: '',
								}}
								validate={values => {
									const error = {};

									if (!values.password) {
										error.password = intl.formatMessage({
											id: "PROVIDER.VALIDATE_PASSWORD.PASSWORD.VALIDATION.REQUIRED"
										});
									}

									return error;
								}}
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={async (values) => {
									await getProviderData(request, provider, values.password);
									handleClose(values.password);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue,
								}) => {
									return (
										<form
											noValidate={true}
											autoComplete="off"
											onSubmit={handleSubmit}
										>
											<Input
												containerClass="mb-15"
												type="password"
												variant="outlined"
												labelId="PROVIDER.VALIDATE_PASSWORD.PASSWORD"
												fullWidth
												name="password"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.password}
												helperText={touched.password && errors.password}
												error={Boolean(touched.password && errors.password)}
											/>

											{
												error && (
													<Alert color="danger" className="d-flex justify-content-center mb-15">
														{error}
													</Alert>
												)
											}

											<div className="row align-items-center">
												<div className="col-12 col-md-4 offset-md-4 d-flex justify-content-center mb-3 mb-md-0">
													<button
														type="submit"
														disabled={isLoading}
														className={`btn btn-primary btn-elevate py-3 ${clsx(
															{
																"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
															}
														)}`}
													>
														<FormattedMessage id="PROVIDER.VALIDATE_PASSWORD.SUBMIT" />
													</button>
												</div>
												<div className="col-12 col-md-4 text-center">
													<span
														className="text-primary cursor-pointer"
														onClick={() => setShowResetPassword(true)}
													>
														<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
													</span>
												</div>
											</div>
										</form>
									);
								}}
							</Formik>
						</CardContent>
					</Card>

					<div className="pt-50px d-flex flex-column align-items-center justify-content-center">
						<div className="f-18px">
							<FormattedHTMLMessage id="APP.PROVIDER.SET_PASSWORD_FOOTER" />
						</div>
						
						<div className="mt-15px d-flex">
							<div className="mr-15px d-flex align-items-center">
								<img
									src={headerLogo}
									style={{ height: 30, width: 30 }}
									alt="company_logo"
								/>
							</div>
							<div className="text-dark f-24px">
								<FormattedMessage id="APP.NAME" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(
	connect(
		null,
		{
			getProviderData
		}
	)(ValidatePassword)
)
