import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import {
	useParams
} from "react-router-dom";

import {
	resetPassword,
	flagNames,
} from '../../../store/modules/actions/provider.actions';
import Input from "../../../common/Input";
import { PASSWORD_REGEX } from "../../../../_metronic";
import { getFlags } from "../../../store/modules/selectors/common.selector";

function ResetPassword(props) {
	const params = useParams();
	const {
		request,
		provider
	} = params;
	const {
		nextStep,
		intl,
		resetPassword,
		data
	} = props;

	const flags = useSelector(getFlags);
	const isLoading = flags.loading[flagNames.RESET_PASSWORD];
	const error = flags.error[flagNames.RESET_PASSWORD];

	return (
		<React.Fragment>
			<div className='text-center'>
				<FormattedMessage
					id="PROVIDER.RESET_PASSWORD.DESCRIPTION"
					values={data['1']}
				/>
			</div>

			<div className="mt-25">
				<Formik
					initialValues={{
						password: '',
						code: ''
					}}
					validate={values => {
						const errors = {};

						if (!values.code) {
							errors.code = intl.formatMessage({
								id: "PROVIDER.RESET_PASSWORD.CODE.VALIDATION.REQUIRED_FIELD"
							});
						}

						if (!values.password) {
							errors.password = intl.formatMessage({
								id: "AUTH.VALIDATION.REQUIRED_FIELD"
							});
						}
						else {
							const passwordRegex = PASSWORD_REGEX;

							if (!passwordRegex.test(values.password)) {
								errors.password = intl.formatMessage({
									id: "AUTH.VALIDATION.PASSWORD.WEAK"
								});
							}
						}

						return errors;
					}}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={async (values) => {
						try {
							await resetPassword(request, provider, values.code, values.password);
							nextStep();
						}
						catch (e) {
							console.log(e);
						}
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
					}) => {
						return (
							<form
								noValidate={true}
								autoComplete="off"
								onSubmit={handleSubmit}
							>
								<div className="container">
									<div className="row">
										<div className="col-12">
											<Input
												containerClass="mb-25"
												labelId="PROVIDER.RESET_PASSWORD.CODE"
												variant="outlined"
												type="text"
												fullWidth
												name="code"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.code}
												helperText={touched.code && errors.code}
												error={Boolean(touched.code && errors.code)}
											/>
										</div>
									</div>

									<div className="row">
										<div className="col-12">
											<Input
												containerClass="mb-25"
												labelId="PROVIDER.RESET_PASSWORD.PASSWORD"
												variant="outlined"
												type="password"
												fullWidth
												name="password"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.password}
												helperText={touched.password && errors.password}
												error={Boolean(touched.password && errors.password)}
											/>
										</div>
									</div>

									{
										error && (
											<Alert color="danger" className="d-flex justify-content-center mb-25">
												{error}
											</Alert>
										)
									}

									<div className="pt-20 d-flex justify-content-center">
										<button
											type="submit"
											disabled={isLoading}
											className={`btn btn-primary btn-elevate kt-login__btn-primary py-3 ${clsx(
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
												}
											)}`}
										>
											<FormattedMessage id="PROVIDER.RESET_PASSWORD.SUBMIT" />
										</button>
									</div>
								</div>
							</form>
						);
					}}
				</Formik>
			</div>
		</React.Fragment>
	)
}

export default injectIntl(
	connect(
		null,
		{
			resetPassword
		}
	)(ResetPassword)
);