import { Info } from "@material-ui/icons";
import { withStyles, makeStyles, Accordion, AccordionSummary, Chip, AccordionDetails, FormLabel } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import { useFormikContext } from "formik";
import _ from "lodash";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Input from "../../../../../common/Input";
import LightTooltip from "../../../../../common/LightTooltip";
import { isValidEmail } from "../../../../../../_metronic";

const useStyles = makeStyles((theme) => {
	return {
		bccChipsContainer: {
			columnGap: 5,
			rowGap: 5,
		},
		bccInput: {
			maxHeight: 24,
		},
	}
});

const BCCAccordion = withStyles({
	root: {
		boxShadow: 'none !important',
		width: "100%",
	},
})(Accordion);
const BCCAccordionSummary = withStyles({
	root: {
		minHeight: 'inherit !important'
	},
	content: {},
	expanded: {
		minHeight: 'inherit !important'
	},
})(AccordionSummary);

export function EmailTemplate () {
	const intl = useIntl();
	const classes = useStyles();
	const emailDataKeyPrefix = useRef(`emailMeta`);
	const bccDataKeyPrefix = useRef(`bcc`);
	const {
		values,
		setFieldValue,
		setFieldTouched,
		errors,
	} = useFormikContext();

	const data = _.get(values, emailDataKeyPrefix.current);
	const error = _.get(errors, emailDataKeyPrefix.current, {});

	const bccList = _.get(values, bccDataKeyPrefix.current);

	const [showBCC, setShowBCC] = useState(bccList.length > 0);
	const [BCCError, setBCCError] = useState("");

	useEffect(() => {
		if (showBCC === false) {
			setShowBCC(bccList.length > 0);
		}
	}, [showBCC, bccList]);

	const updateField = useCallback((field, value) => {
		setFieldTouched(field);
		setFieldValue(field, value);
	}, [
		setFieldTouched,
		setFieldValue,
	]);

	const validateEmail = useCallback((str) => {
		if (str && !isValidEmail(str)) {
			setBCCError("invalid value");
			return false;
		}

		setBCCError(null);
		return true;
	}, []);

	return (
		<div className={clsx("p-20px rounded bg-light-blue")}>
			<div className="f-14px font-weight-medium mb-15px d-flex">
				<div className="text-body">
					<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.EMAIL.TITLE" />
				</div>
				<div className="text-muted">
					<LightTooltip
						className="ml-1"
						title={"something"}
						placement="bottom"
						arrow
					>
						<Info fontSize="small" />
					</LightTooltip>
				</div>
			</div>

			<div>
				<div className="col-md-6 col-12 pl-0">
					<Input
						containerClass="mb-5px"
						placeholder={
							intl.formatMessage({
								id: "REQUEST.PROVIDER.TEMPLATE.EMAIL.SUBJECT"
							})
						}
						type="text"
						variant="outlined"
						fullWidth
						onChange={(e) => updateField(`${emailDataKeyPrefix.current}.subject`, e.target.value)}
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						value={data.subject}
						helperText={error.subject}
						error={error.subject}
					/>
				</div>
				<div className="col-md-6 col-12 pl-0">
					<Input
						containerClass="mb-5px bg-white"
						placeholder={
							intl.formatMessage({
								id: "REQUEST.PROVIDER.TEMPLATE.EMAIL.MESSAGE"
							})
						}
						type="text"
						variant="outlined"
						fullWidth
						multiline
						minRows={8}
						onChange={(e) => updateField(`${emailDataKeyPrefix.current}.content`, e.target.value)}
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						value={data.content}
						helperText={error.content}
						error={error.content}
					/>
				</div>
			</div>


			<div className="d-flex align-items-center mt-25px">
				<BCCAccordion
					classes={{
						root: "bg-transparent"
					}}
					expanded={showBCC}
					square
				>
					<BCCAccordionSummary
						classes={{
							root: "p-0",
							content: "m-0",
						}}
					>
						<div
							className="f-16px text-dark text-decoration-underline cursor-pointer"
							onClick={() => setShowBCC(bccList.length > 0 || !showBCC)}
						>
							<FormattedMessage id="REQUESTS.PROVIDER.BCC.TITLE" />
						</div>
						<div className="text-muted">
							<LightTooltip
								className="ml-1"
								title={"something"}
								placement="bottom"
								arrow
							>
								<Info />
							</LightTooltip>
						</div>
					</BCCAccordionSummary>
					<AccordionDetails classes={{ root: "p-0 col-12 col-md-6" }}>
						<div
							className={clsx(
								"dark-custom-input form-group d-flex flex-column flex-grow-1 pr-0 pr-md-3 mb-0",
								{
									"pb-15px": !!BCCError
								}
							)}
						>
							<FormLabel className="mb-10">
								<FormattedMessage id="REQUEST.PROVIDER.BCC.EMAIL_ADDRESSES" />
							</FormLabel>

							<ChipInput
								className="bg-white"
								value={bccList}
								onChange={(bcc) => updateField(bccDataKeyPrefix.current, bcc)}
								variant="outlined"
								InputProps={{
									classes: {
										root: "blue-border",
										input: `w-65 bg-transparent pl-0 py-0 my-0 ${classes.bccInput}`,
										adornedStart: `d-flex flex-wrap w-100 py-2 ${classes.bccChipsContainer}`
									},
									error: !!BCCError
								}}
								helperText={BCCError}
								FormHelperTextProps={{
									className: "mt-5px",
									error: !!BCCError
								}}
								chipRenderer={({ chip, value, handleDelete, text }) => {
									return (
										<Chip
											key={text}
											label={text}
											clickable
											color="primary"
											size="small"
											onDelete={handleDelete}
										/>
									)
								}}
								onAdd={(email) => {
									if (!bccList.includes(email)) {
										updateField('bcc', [...bccList, email]);
									}
								}}
								onDelete={(emailToDelete, index) => {
									const newBccList = bccList.filter((email) => email !== emailToDelete);
									updateField('bcc', newBccList);
								}}
								onBeforeAdd={validateEmail}
							/>
						</div>
					</AccordionDetails>
				</BCCAccordion>
			</div>
		</div>
	)
}