import React from "react";
import { FormattedMessage } from "react-intl";

function LogsHistoryToggler ({ toggleBy = "kt_logs_history_toggler_btn" }) {
	return (
		<span
			className="kt-header__topbar-icon text-decoration-underline cursor-pointer"
			id={toggleBy}
		>
			<FormattedMessage id="APP.LOGS_HISTORY.BUTTON" />
		</span>
	);
}

export default LogsHistoryToggler;