import { CircularProgress, Switch, makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { ELEMENT_TYPES } from '..';
import useDebounce from '../../../useDebounce';
import { getEmptyErrorObj, validateBankStatements } from '../../ElementPicker';
import ErrorMessage from '../ErrorMessage';
import FileCard from './FileCard';
import { bulkDownloadV2, flagNames } from '../../../../store/modules/actions/requests.actions';
import AutoExpandInput from '../../../auto-expand-input';
import Settings from './Settings';
import { getSelectedRequest } from '../../../../store/modules/selectors/request.selector';
import { getFlags } from '../../../../store/modules/selectors/common.selector';

const useStyles = makeStyles((theme) => {
	return {
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto"
		},
	}
});

const BankStatements = forwardRef((props, _ref) => {
	const {
		id,
		data,
		refData,
		additionalData,
		onChange,
		onChangeResponse,
		response,
		onPreview
	} = props;
	const {
		providerUUID,
		hideDownloadAllFiles,
	} = additionalData;
	const [error, setError] = useState({});
	const theme = useTheme();
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();

	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	const selectedRequest = useSelector(getSelectedRequest);
	const flags = useSelector(getFlags);

	const provider = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;

		const provider = selectedRequest?.documentProviders.find(({ uuid }) => uuid === providerUUID);
		return provider;
	}, [hideDownloadAllFiles, providerUUID, selectedRequest]);
	const elementId = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;

		if (selectedRequest && provider) {
			const element = provider.elements.find(({ uuid }) => uuid === id);

			if (element) {
				return element._id;
			}
		}
	}, [hideDownloadAllFiles, id, provider, selectedRequest]);

	const isDownloadingAllFiles = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;
		const loadingFlag = flags.loading[flagNames.BULK_DOWNLOAD] || {};
		return !!loadingFlag[elementId];
	}, [elementId, flags.loading, hideDownloadAllFiles]);
	
	const hasValidOriginalName = useMemo(() => {
		return response.files.some(({ originalName, name }) => name && originalName && name !== originalName );
	}, [response.files]);

	useEffect(() => {
		const error = validateBankStatements(data);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.BANK_STATEMENTS)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange({
				...data,
				title: debouncedTitle,
				subtitle: debouncedSubtitle,
			});
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	]);

	const handleDelete = useCallback((fileIndexToDelete) => {
		const files = response.files.filter((file, index) => index !== fileIndexToDelete);

		onChangeResponse({
			...response,
			files,
		});
	}, [
		onChangeResponse,
		response,
	]);

	const handleDownloadAllFiles = useCallback(() => {
		const filename = `${title}.zip`;

		dispatch(
			bulkDownloadV2(
				provider,
				{ _id: elementId },
				filename,
				'',
				false,
			)
		)
	}, [
		provider,
		title,
		elementId,
		dispatch,
	]);


	return (
		<div>
			<div>
				<AutoExpandInput
					className="f-18px text-dark font-weight-medium mb-0"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.BANK_STATEMENTS.TITLE.PLACEHOLDER"
						})
					}
				/>
			</div>

			{
				error.title && (
					<ErrorMessage message={error.title} />
				)
			}

			<div className="mb-10px">
				<AutoExpandInput
					className="f-14px text-dark font-weight-regular mb-10px w-100 pb-0 break-word"
					style={{ color: theme.palette.extraColors.lightText }}
					onChange={(e) => setSubtitle(e.target.value)}
					value={subtitle}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.BANK_STATEMENTS.SUBTITLE.PLACEHOLDER"
						})
					}
					allowHTML
				/>
			</div>

			{
				refData && (
					<div className="mb-10px">
						<a
							href={refData.bankStatementsUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL_TEXT" />
						</a>
					</div>
				)
			}

			{
				response.files && response.files.length > 0 && (
					<div className={clsx(classes.fileListContainer, "rounded bg-white p-10px mb-10px")}>
						<div className={classes.fileList}>
							{
								response.files.map((file, index, arr) => (
									<div
										key={index}
										className={clsx({
											"mb-5px": index !== arr.length - 1
										})}
									>
										<FileCard
											canDownload={file._id}
											fileNameField={data.viewOriginalFileName ? 'originalName' : undefined}
											onDelete={() => handleDelete(index)}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();

												onPreview(file);
											}}
											file={file}
											isAttachment
										/>
									</div>
								))
							}
						</div>
					</div>
				)
			}

			{
				!additionalData.hideDownloadAllFiles && response.files.length > 0 && (
					<div className="d-flex">
						<div
							className={
								clsx(
									"text-decoration-underline cursor-pointer text-body f-16px d-flex align-items-center",
									{
										"pointer-events-none text-muted": isDownloadingAllFiles
									}
								)
							}
							onClick={() => handleDownloadAllFiles()}
						>
							{
								isDownloadingAllFiles && (
									<div className="mr-2">
										<CircularProgress size={10} />
									</div>
								)
							}
							<div className="text-dark font-weight-light">
								<FormattedMessage id="ELEMENT_LIST.ITEM.BANK_STATEMENTS.DOWNLOAD_ALL_FILES" />
							</div>
						</div>
					</div>
				)
			}

			{
				hasValidOriginalName && (
					<div className="d-flex align-items-center mt-5px">
						<div className="f-16px text-muted">
							<FormattedMessage id="ELEMENT_LIST.ITEM.BANK_STATEMENTS.ORIGINAL_FILE_NAME.LABEL" />
						</div>

						<div className="ml-2">
							<Switch
								checked={!!data.viewOriginalFileName}
								classes={{
									thumb: data.viewOriginalFileName ? 'bg-dark' : 'bg-white'
								}}
								onChange={(e) => {
									onChange({
										...data,
										viewOriginalFileName: e.target.checked
									})
								}}
							/>
						</div>
					</div>
				)
			}

			<Settings
				id={id}
				data={data}
				onChange={onChange}
			/>
		</div>
	)
});

export default BankStatements;
