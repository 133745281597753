import React, { useCallback } from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	AppBar,
	Dialog,
	IconButton,
	makeStyles,
	Toolbar,
	Typography,
	useTheme,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import { CloseOutlined } from "@material-ui/icons";

import {
	flagNames,
	setDocumentsWithError,
} from '../../store/modules/actions/provider.actions';
import Input from "../../common/Input";
import { getFlags } from "../../store/modules/selectors/common.selector";
import { getDocumentsWithError } from "../../store/modules/selectors/provider.selector";

const useStyles = makeStyles((theme) => {
	return {
		reasonInput: {
			'& textarea': {
				background: `${theme.palette.extraColors.blueSecondary} !important`,
			}
		},
	};
});

function MarkNotRequired({ id, name, handleMarkNotRequired, handleClose, }) {
	const dispatch = useDispatch();
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles();

	const flags = useSelector(getFlags);
	const documentsWithError = useSelector(getDocumentsWithError);
	const error = flags.error[flagNames.UPDATE];
	const onValidate = useCallback((values) => {
		const error = {};

		if (!values.reason) {
			error.reason = intl.formatMessage({
				id: "PROVIDER.DOCUMENT.MARK_NOT_REQUIRED.REASON.VALIDATION.REQUIRED"
			});
		}

		return error;
	}, [ intl ]);
	const onSubmit = useCallback(async ({ reason }) => {
		await handleMarkNotRequired(reason);
		dispatch(
			setDocumentsWithError({
				...documentsWithError,
				[id]: false,
			})
		);

		handleClose(true);
	}, [
		dispatch,
		id,
		handleMarkNotRequired,
		handleClose,
		documentsWithError,
	]);

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth="xs"
			classes={{
				paper: "rounded-card"
			}}
			className="mark-not-required-container"
		>
			<AppBar
				position="static"
				classes={{
					root: "rounded-top"
				}}
				style={{ backgroundColor: theme.palette.dark.primary.main }}
			>
				<Toolbar classes={{ root: "pr-1" }}>
					<div className="flex-grow-1">
						<Typography
							variant="h6"
							style={{ color: theme.palette.dark.primary.contrastText }}
						>
							<FormattedHTMLMessage id="PROVIDER.ELEMENT.MARK_NOT_REQUIRED.TITLE" />
						</Typography>
					</div>
					<div>
						<IconButton onClick={() => handleClose()}>
							<CloseOutlined style={{ color: theme.palette.dark.primary.contrastText }} />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>

			<Formik
				initialValues={{
					reason: '',
				}}
				validate={onValidate}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => {
					return (
						<form
							noValidate={true}
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							{
								error ? (
									<div className="d-flex justify-content-center">
										<Alert color="danger">
											{error}
										</Alert>
									</div>
								) : (
									<div>
										<div className="mx-25px my-20px">
											<div className="mb-10px">
												<FormattedMessage id="PROVIDER.ELEMENT.MARK_NOT_REQUIRED.REASON.LABEL" />
											</div>

											<div>
												<Input
													containerClass={classes.reasonInput}
													type="text"
													variant="outlined"
													fullWidth
													defaultValue={values.reason}
													name="reason"
													onChange={handleChange}
													multiline
													rows="4"
												/>
											</div>
										</div>

										{
											flags.error[flagNames.UPDATE] && (
												<div className="d-flex justify-content-center">
													<Alert color="danger">
														{flags.error[flagNames.UPDATE]}
													</Alert>
												</div>
											)
										}

										<div className="shadow-lg px-20px py-15px">
											<button
												type="submit"
												className={
													clsx(
														"btn",
														{
															"btn-secondary": !values.reason,
															"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--right kt-spinner--primary": flags.loading[flagNames.UPDATE],
															"btn-primary": !!values.reason,
														}
													)
												}
												onClick={handleSubmit}
											>
												<FormattedMessage id="GENERAL.SUBMIT" />
											</button>
										</div>
									</div>
								)
							}
						</form>
					);
				}}
			</Formik>
		</Dialog>
	);
}

export default MarkNotRequired;
