import { makeStyles } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useFormikContext } from "formik";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Input from "../../../../common/Input";
import LightTooltip from "../../../../common/LightTooltip";
import useDebounce from "../../../../common/useDebounce";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			background: theme.palette.extraColors.blueSecondary,
		},
		emailFields: {
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.inputFields.maxWidth,
			}
		}
	};
});

export function EmailTemplate ({
	keyPrefix,
	isRequestDisabled,
}) {
	const intl = useIntl();
	const classes = useStyles();
	const {
		values,
		setFieldValue,
		setFieldTouched,
		errors,
	} = useFormikContext();
	const data = _.get(values, keyPrefix, values);
	const error = _.get(errors, keyPrefix, {});

	const [subject, setSubject] = useState(data.subject);
	const debouncedSubject = useDebounce(subject, 750);

	const [content, setContent] = useState(data.content);
	const debouncedContent = useDebounce(content, 750);

	const updateField = useCallback((field, value) => {
		const key = `${keyPrefix}${keyPrefix ? '.' : ''}${field}`;
		setFieldValue(key, value);
		setTimeout(() => setFieldTouched(key, true))
	}, [
		keyPrefix,
		setFieldTouched,
		setFieldValue,
	]);

	useEffect(() => {
		setSubject((subject) => {
			if (subject !== data.subject) {
				return data.subject;
			}

			return subject;
		});
	}, [
		data.subject
	])
	useEffect(() => {
		setContent((content) => {
			if (content !== data.content) {
				return data.content;
			}

			return content;
		});
	}, [
		data.content
	]);

	useEffect(() => {
		updateField("subject", debouncedSubject);
	}, [
		updateField,
		debouncedSubject,
	]);
	useEffect(() => {
		updateField("content", debouncedContent);
	}, [
		updateField,
		debouncedContent,
	]);

	return (
		<div className={clsx(classes.container, "rounded p-15px")}>
			<div className="f-14px font-weight-medium mb-15px d-flex">
				<div className="text-body">
					<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.EMAIL.TITLE" />
				</div>
				<div className="text-muted">
					<LightTooltip
						className="ml-1"
						title={"something"}
						placement="bottom"
						arrow
					>
						<Info fontSize="small" />
					</LightTooltip>
				</div>
			</div>

			<div>
				<div className={clsx(classes.emailFields, "w-100 pl-0")}>
					<Input
						containerClass="mb-5px"
						placeholder={
							intl.formatMessage({
								id: "REQUEST.PROVIDER.TEMPLATE.EMAIL.SUBJECT"
							})
						}
						type="text"
						variant="outlined"
						fullWidth
						onChange={(e) => setSubject(e.target.value)}
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						disabled={isRequestDisabled}
						value={subject}
						helperText={error.subject}
						error={error.subject}
					/>
				</div>
				<div className={clsx(classes.emailFields, "w-100 pl-0")}>
					<Input
						containerClass="mb-5px bg-white"
						placeholder={
							intl.formatMessage({
								id: "REQUEST.PROVIDER.TEMPLATE.EMAIL.MESSAGE"
							})
						}
						type="text"
						variant="outlined"
						fullWidth
						multiline
						minRows={8}
						onChange={(e) => setContent(e.target.value)}
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						disabled={isRequestDisabled}
						value={content}
						helperText={error.content}
						error={error.content}
					/>
				</div>
			</div>
		</div>
	)
}