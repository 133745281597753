import { combineReducers } from "redux";

import * as auth from "./modules/auth.reducer";
import * as requests from "./modules/requests.reducer";
import * as templates from "./modules/templates.reducer";
import * as flags from "./modules/loading-flag.reducer";
import * as provider from "./modules/provider.reducer";
import * as notifications from "./modules/notifications.reducer";
import * as notistack from "./modules/notistack.reducer";
import * as tasks from "./modules/tasks.reducer";
import * as integrations from "./modules/integrations.reducer";
import * as features from "./modules/features.reducer";
import * as app from "./modules/app.reducer";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  app: app.reducer,
  auth: auth.reducer,
  requests: requests.reducer,
  templates: templates.reducer,
  provider: provider.reducer,
  notifications: notifications.reducer,
  notistack: notistack.reducer,
  flags: flags.reducer,
  tasks: tasks.reducer,
  features: features.reducer,
  integrations: integrations.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
});
