import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AccordionSummary, FormLabel, Accordion, AccordionDetails, withStyles, Chip, makeStyles, Fab, Tooltip } from "@material-ui/core";
import { ErrorOutline, Info, LinkOutlined } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormattedMessage } from "react-intl";
import ChipInput from "material-ui-chip-input";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useFormikContext } from "formik";
import { useParams } from 'react-router';
import { useSelector } from "react-redux";

import Input from "../../../../common/Input";
import {
	PrimaryBorderLinearProgress, SecondaryBorderLinearProgress, SuccessBorderLinearProgress,
} from "../../../../common/LinearProgress";
import LightTooltip from "../../../../common/LightTooltip";
import Constants from "../../../../common/constants";
import { addAlpha, copyTextToClipboard, isValidEmail } from "../../../../../_metronic";
import { getUser } from "../../../../store/modules/selectors/account.selector";

const useStyles = makeStyles((theme) => {
	return {
		bccChipsContainer: {
			columnGap: 5,
			rowGap: 5,
		},
		bccInput: {
			maxHeight: 24,
		},
		progressBar: {
			minWidth: 175
		},
		cancelledRequest: {
			color: "#C8C8C8"
		},
		dangerAlert: {
			background: addAlpha(theme.palette.danger.main, 0.15),
			border: '1px solid ' + addAlpha(theme.palette.danger.main, 0.3),
			color: theme.palette.danger.main,
		},
		unreadCommentsCount: {
			height: 28,
			width: 28,
			borderRadius: 14,

			[theme.breakpoints.down('sm')]: {
				position: 'absolute',
				top: 0,
				right: 0,
				transform: 'translate(50%, -35%)'
			},
		}
	}
})

const BCCAccordion = withStyles({
	root: {
		boxShadow: 'none !important',
		width: "100%",
	},
})(Accordion);
const BCCAccordionSummary = withStyles({
	root: {
		minHeight: 'inherit !important'
	},
	content: {},
	expanded: {
		minHeight: 'inherit !important'
	},
})(AccordionSummary);

export function ProviderSettings ({
	onChat,
	isRequestDisabled,
	handleResendRequest,
}) {
	const {
		values: data,
		setFieldValue,
		setFieldTouched,
		errors: error,
	} = useFormikContext();
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const { request: requestId } = useParams();
	const classes = useStyles();
	const errorMessageId = useMemo(() => {
		if (data.emailStatus === Constants.ProviderEmailStatus.UNDELIVERED) {
			return "GENERAL.EMAIL_STATUS.UNDELIVERED";
		}

		return null;
	}, [data.emailStatus]);

	const user = useSelector(getUser);
	const chatService = useMemo(() => user?.chatService, [user.chatService]);
	const isCancelled = data.status === Constants.ProviderStatus.CANCELLED;
	const isCompleted = data.status === Constants.ProviderStatus.COMPLETED;

	const [showBCC, setShowBCC] = useState(false);
	const [BCCError, setBCCError] = useState("");

	useEffect(() => {
		if (showBCC === false) {
			setShowBCC(data.bcc.length > 0);
		}
	}, [showBCC, data.bcc]);

	const updateField = useCallback((key, value) => {
		setFieldValue(key, value);
		setTimeout(() => setFieldTouched(key, true))
	}, [
		setFieldTouched,
		setFieldValue,
	]);

	const validateEmail = useCallback((str) => {
		if (!isValidEmail(str)) {
			setBCCError("invalid value");
			return false;
		}

		setBCCError(null);
		return true;
	}, []);

	const copyRequestLink = useCallback(async (event, provider) => {
		try {
			event.stopPropagation();
			event.preventDefault();
			const link = `${window.location.origin}/provider/${requestId}/${provider._id}`;
			await copyTextToClipboard(link)
			const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_SUCCESS" });
			enqueueSnackbar(msg, { variant: 'success' });
		} catch (err) {
			const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_ERROR" });
			enqueueSnackbar(msg, { variant: 'error' });
			console.log(err);
		}
	}, [
		intl,
		enqueueSnackbar,
		requestId,
	]);
	const unreadMessageCount = data.unreadMessagesCount;

	return (
		<div className="rounded bg-white p-md-4 p-3">
			<div className="d-flex align-items-md-center align-items-start justify-content-between mb-10px">
				<div className="d-flex flex-column flex-md-row align-items-md-center align-items-start">
					<div className="f-16px text-dark">
						{
							data._id ? (
								<span>{`${data.firstName} ${data.lastName}`}</span>
							) : (
								<FormattedMessage id="REQUESTS.PROVIDER.TITLE" />
							)
						}
					</div>

					{
						data._id && (
							<div className="ml-0 ml-md-3">
								<div className="d-flex flex-column flex-md-row align-items-start align-items-md-center flex-grow-1">
									{
										(isCancelled || isCompleted) ? (
											isCancelled ? (
												<div className="d-flex align-items-center flex-grow-1">
													<div className={clsx("mr-2", classes.progressBar)}>
														<SecondaryBorderLinearProgress
															variant="determinate"
															value={100}
														/>
													</div>
													<div className={clsx("f-12px", classes.cancelledRequest)}>
														<FormattedMessage id={`REQUEST.PROVIDER.STATUS.${data.status}`} />
													</div>
												</div>
											) : (
												<div className="d-flex align-items-center flex-grow-1">
													<div className={clsx("mr-2", classes.progressBar)}>
														<SuccessBorderLinearProgress
															variant="determinate"
															value={100}
														/>
													</div>
													<div className="text-success f-12px">
														<FormattedMessage id={`REQUEST.PROVIDER.STATUS.${data.status}`} />
													</div>
												</div>
											)
										) : (
											data.stats && data.stats.approved > 0 ? (
												<div className="d-flex align-items-center flex-grow-1">
													<div className={clsx("mr-2", classes.progressBar)}>
														<PrimaryBorderLinearProgress
															variant="determinate"
															value={data.stats.percentage}
														/>
													</div>
													<div className="d-flex ml-2 align-items-center">
														<div className="d-flex text-primary align-items-end">
															<div className="f-15px font-weight-bold">{data.stats.approved}</div>
															<div className="f-12px" style={{ marginBottom: 1 }}>/</div>
															<div className="f-12px" style={{ marginBottom: 1 }}>{data.stats.total}</div>
														</div>
													</div>
												</div>
											) : (
												<div className={clsx(
													"align-items-center flex-grow-1",
													{
														"d-flex": !!data.emailStatus,
														"d-none": !data.emailStatus
													}
												)}>
													<div className={clsx("mr-2", classes.progressBar)}>
														<PrimaryBorderLinearProgress
															variant="determinate"
															value={0}
														/>
													</div>
													<div className={clsx("text-muted f-12px", classes.cancelledRequest)}>
														<FormattedMessage id={`GENERAL.EMAIL_STATUS.${data.emailStatus}`} />
													</div>
												</div>
											)
										)
									}

									<div className="ml-0 ml-md-3 my-2 mt-md-0 d-flex">
										<div>
											<Tooltip title={intl.formatMessage({ id: "REQUEST.PROVIDER.RESEND_REQUEST.CONFIRM.TITLE" })}>
												<Fab
													className="bg-white text-dark mr-3"
													size="small"
													onClick={handleResendRequest}
												>
													<span className="material-icons-outlined">
														mark_as_unread
													</span>
												</Fab>
											</Tooltip>
										</div>
										<div>
											<Tooltip title={intl.formatMessage({ id: "REQUEST.PROVIDER.COPY_LINK" })}>
												<Fab
													className="bg-white text-primary mr-3"
													size="small"
													onClick={(e) => copyRequestLink(e, data)}
												>
													<LinkOutlined fontSize="large" />
												</Fab>
											</Tooltip>
										</div>
									</div>
								</div>
							</div>
						)
					}
				</div>
				<div className="position-relative">
					{
						data._id && chatService && (
							<button
								className="btn btn-dark btn-sm d-flex align-items-center justify-content-center f-16px"
								onClick={onChat}
							>
								<div className={"mr-0 mr-md-2 d-flex align-items-center justify-content-center"}>
									<span className="material-icons-outlined">
										question_answer
									</span>
								</div>

								<div className={clsx("d-none d-md-block mr-0", { "mr-md-3": unreadMessageCount > 0 })}>
									<FormattedMessage id="REQUEST.PROVIDER.CHAT.TITLE" />
								</div>
								
								{
									unreadMessageCount > 0 && (
										<div className={clsx(classes.unreadCommentsCount, "p-2 d-flex justify-content-center align-items-center bg-danger text-light")}>
											{unreadMessageCount}
										</div>
									)
								}
							</button>
						)
					}
				</div>
			</div>

			{
				!!errorMessageId && (
					<div className={clsx("my-3 py-3 rounded d-flex align-items-center justify-content-center", classes.dangerAlert)}>
						<div className="mr-15px">
							<ErrorOutline />
						</div>
						<div>
							<FormattedMessage id={errorMessageId} />
						</div>
					</div>
				)
			}

			<div className="row">
				<div className="col-12 col-md-6">
					<Input
						containerClass="mb-20px"
						labelId="REQUEST.PROVIDER.FIRST_NAME"
						type="text"
						variant="outlined"
						fullWidth
						name="firstName"
						onChange={(e) => updateField('firstName', e.target.value)}
						disabled={isRequestDisabled}
						value={data.firstName}
						helperText={error.firstName}
						error={!!error.firstName}
					/>
				</div>
				<div className="col-12 col-md-6">
					<Input
						containerClass="mb-20px"
						labelId="REQUEST.PROVIDER.LAST_NAME"
						type="text"
						variant="outlined"
						fullWidth
						name="lastName"
						onChange={(e) => updateField('lastName', e.target.value)}
						disabled={isRequestDisabled}
						value={data.lastName}
						helperText={error.lastName}
						error={!!error.lastName}
					/>
				</div>
				<div className="col-12 col-md-6">
					<Input
						containerClass="mb-20px"
						labelId="REQUEST.PROVIDER.EMAIL"
						type="text"
						variant="outlined"
						fullWidth
						name="email"
						onChange={(e) => updateField('email', e.target.value)}
						disabled={isRequestDisabled}
						value={data.email}
						helperText={error.email}
						error={!!error.email}
					/>
				</div>
				<div className="col-12 col-md-6">
					<div className={`dark-custom-input input-phone form-group mb-20px`}>
						<FormLabel className="mb-10">
							<FormattedMessage id="REQUEST.PROVIDER.PHONE" />
						</FormLabel>
						<MuiPhoneNumber
							enableLongNumbers	
							disableAreaCodes
							defaultCountry="au"
							variant="outlined"
							type="text"
							fullWidth
							name="phone"
							onChange={(phone) => updateField('phone', phone)}
							disabled={isRequestDisabled}
							value={data.phone}
							helperText={error.phone}
							error={!!error.phone}
						/>
					</div>
				</div>
			</div>

			<div>
				<div className="d-flex align-items-center">
					<BCCAccordion
						expanded={showBCC}
						square
					>
						<BCCAccordionSummary
							classes={{
								root: "p-0",
								content: "m-0",
							}}
						>
							<div
								className="f-16px text-dark text-decoration-underline cursor-pointer"
								onClick={() => setShowBCC(data.bcc.length > 0 || !showBCC)}
							>
								<FormattedMessage id="REQUESTS.PROVIDER.BCC.TITLE" />
							</div>
							<div className="text-muted">
								<LightTooltip
									className="ml-1"
									title={"something"}
									placement="bottom"
									arrow
								>
									<Info />
								</LightTooltip>
							</div>
						</BCCAccordionSummary>
						<AccordionDetails classes={{ root: "p-0 col-12 col-md-6" }}>
							<div
								className={clsx(
									"dark-custom-input form-group d-flex flex-column flex-grow-1 pr-0 pr-md-3 mb-0",
									{
										"pb-15px": !!BCCError
									}
								)}
							>
								<FormLabel className="mb-10">
									<FormattedMessage id="REQUEST.PROVIDER.BCC.EMAIL_ADDRESSES" />
								</FormLabel>

								<ChipInput
									className="bg-white"
									value={data.bcc}
									variant='outlined'
									InputProps={{
										classes: {
											input: `w-65 bg-transparent pl-0 py-0 my-0 ${classes.bccInput}`,
											adornedStart: `d-flex flex-wrap w-100 py-2 ${classes.bccChipsContainer}`
										},
										error: !!BCCError
									}}
									helperText={BCCError}
									FormHelperTextProps={{
										className: "mt-5px",
										error: !!BCCError
									}}
									chipRenderer={({ chip, value, handleDelete, text }) => {
										return (
											<Chip
												key={text}
												label={text}
												clickable
												color="primary"
												size="small"
												onDelete={handleDelete}
											/>
										)
									}}
									onAdd={(email) => {
										if (!data.bcc.includes(email)) {
											updateField('bcc', [...data.bcc, email]);
										}
									}}
  									onDelete={(emailToDelete, index) => {
  										const newBccList = data.bcc.filter((email) => email !== emailToDelete);
  										updateField('bcc', newBccList);
  									}}
									onBeforeAdd={validateEmail}
								/>
							</div>
						</AccordionDetails>
					</BCCAccordion>
				</div>
			</div>
		</div>
	)
}