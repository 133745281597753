import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { ClickAwayListener, makeStyles, Popper, useMediaQuery } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import clsx from "clsx";
import { useFormikContext } from "formik";
import _ from "lodash";
import { uuid } from "uuidv4";

import DocumentSelector from "../../../../common/document-selector";
import { ElementList } from "./ElementList";
import { EmailTemplate } from "./EmailTemplate";

const DOCUMENT_SELECTOR_MEDIA_QUERY = "(min-width:550px)";

const useStyles = makeStyles((theme) => {
	return {
		templatePickerTrigger: {
			width: "100%",
			background: theme.palette.extraColors.backgroundBlue,
			maxWidth: theme.elementSizes.templatePicker.maxWidth,
		},
		templatePicker: {
			// leave some space around the sides for better UI
			width: window.screen.availWidth - 50,

			// if screen is wide enough then lock selector card at 450px width
			[`@media ${DOCUMENT_SELECTOR_MEDIA_QUERY}`]: {
				width: 450,
			}
		},
		elementListContainer: {
			background: theme.palette.extraColors.blueSecondary,
		}
	}
});

export function Template({
	keyPrefix,
	isRequestDisabled,
	additionalData,
	showElementPicker,
	onElementPickerClose,
}) {
	const {
		values,
		setFieldValue,
		setFieldTouched,
	} = useFormikContext();
	const classes = useStyles();
	const isWideEnoughForDocumentSelector = useMediaQuery(DOCUMENT_SELECTOR_MEDIA_QUERY);

	const [templateSelectorAnchorEl, setTemplateSelectorAnchorEl] = useState(null);
	const data = _.get(values, keyPrefix, values);

	const updateField = useCallback((field, value) => {
		const key = `${keyPrefix}${keyPrefix ? '.' : ''}${field}`;
		setFieldValue(key, value);
		setTimeout(() => setFieldTouched(key, true), 500)
	}, [
		setFieldTouched,
		setFieldValue,
		keyPrefix,
	]);

	return (
		<div className="rounded bg-white p-20px">
			<div className="f-16px text-dark mb-15px">
				<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.TITLE" />
			</div>

			<div>
				<div
					className={clsx(
						classes.templatePickerTrigger,
						"mb-15px rounded px-15px py-10px d-flex justify-content-between align-items-center",
						{
							"pointer-events-none": isRequestDisabled,
							"cursor-pointer": !isRequestDisabled,
						}
					)}
					onClick={(e) => !templateSelectorAnchorEl && setTemplateSelectorAnchorEl(e.currentTarget)}
				>
					<div className="f-16px">
						<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT.COPY_TEMPLATE" />
					</div>

          <div className="text-primary">
						{
							templateSelectorAnchorEl ? (
								<ExpandLess />
							) : (
								<ExpandMore />
							)
						}
					</div>
				</div>

				<Popper
					open={!!templateSelectorAnchorEl}
					anchorEl={templateSelectorAnchorEl}
					placement={isWideEnoughForDocumentSelector ? "bottom-start" : "bottom"}
					transition
				>
					<ClickAwayListener onClickAway={() => setTemplateSelectorAnchorEl(null)}>
						<div className="mt-3"> {/* wrapped in div because DocumentSelector is a functional component and click away listener can't work directly with functional components */}
							<DocumentSelector
								className={classes.templatePicker}
								classes={{
									templatesListContainer: classes.templatesListContainer,
									templateListItem: isWideEnoughForDocumentSelector ? undefined : "align-items-start",
									templateListItemTitle: isWideEnoughForDocumentSelector ? undefined : "flex-column",
									templateListItemSelectedPill: isWideEnoughForDocumentSelector ? undefined : "mt-5px",
								}}
								showModern
								onSubmit={({ elements, emailMeta, bcc, selectedTemplateId }) => {
									const currentBcc = _.get(data, `bcc`);
									const currentEmailMeta = _.get(data, `emailMeta`);
									const existingElements = _.get(data, `elements`);
									const orderOfLastElement = existingElements?.[existingElements.length - 1]?.order || existingElements.length;

									if (!currentEmailMeta.subject && !currentEmailMeta.content && selectedTemplateId) {
										const {
											subject,
											content,
										} = emailMeta;
										
										updateField("emailMeta", {
											subject,
											content,
										});
									}

									if (currentBcc.length === 0 && bcc.length > 0) {
										updateField("bcc", bcc);
									}

									elements.forEach((element, index) => {
										element.uuid = uuid();
										element.order = orderOfLastElement + index + 1;
									});

									updateField("elements", [
										...existingElements,
										...elements
									]);

									setTemplateSelectorAnchorEl(null);
									// globalGA("add_document_via_template", {
									// 	eventAction: "success",
									// 	eventCategory: "request_creation_C1"
									// });
								}}
							/>
						</div>
					</ClickAwayListener>
				</Popper>
			</div>

			<div className="mb-15px">
				<EmailTemplate
					keyPrefix={`${keyPrefix}${keyPrefix ? '.' : ''}emailMeta`}
					isRequestDisabled={isRequestDisabled}
				/>
			</div>
			
			<div className={clsx(classes.elementListContainer, "rounded")}>
				<ElementList
					className="px-15px pb-15px pt-10px"
					keyPrefix={keyPrefix}
					isRequestDisabled={isRequestDisabled}
					additionalData={additionalData}
					showElementPicker={showElementPicker}
					onElementPickerClose={onElementPickerClose}
				/>
			</div>
		</div>
	)
}