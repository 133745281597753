import { actionTypes } from './actions/notistack.actions';

const defaultState = {
	notifications: [],
};

export const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.ENQUEUE_SNACKBAR:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						key: action.key,
						...action.notification,
					},
				],
			};

		case actionTypes.CLOSE_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.map(notification => (
					(action.dismissAll || notification.key === action.key)
						? { ...notification, dismissed: true }
						: { ...notification }
				)),
			};

		case actionTypes.REMOVE_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.filter(
					notification => notification.key !== action.key,
				),
			};

		default:
			return state;
	}
};
