import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { stringifyUrl } from 'query-string';
import { LOOM_VIDEO, VIMEO_VIDEO, YOUTUBE_VIDEO } from './urlTypes';

const useStyles = makeStyles((theme) => {
	return {
		videoContainer: {
			position: 'relative',
			paddingBottom: '56.25%',
			paddingTop: ({ type }) => type === VIMEO_VIDEO ? '5px' : '30px',
			height: 0,
			overflow: 'hidden',
			[theme.breakpoints.up('sm')]: {
				paddingBottom: theme.elementSizes.video.maxHeight,
			},

			'& iframe, & object, & embed': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%', 
				[theme.breakpoints.up('sm')]: {
					maxWidth: theme.elementSizes.video.maxWidth,
					maxHeight: theme.elementSizes.video.maxHeight,
				},
			}
		}
	}
})

function EmbeddedVideo ({ type, videoId, start }) {
	const classes = useStyles({ type });
	const url = useMemo(() => {
		let videoLink = null;
		
		if (type === YOUTUBE_VIDEO) {
			const query = {};

			if (start) {
				query.start = start;
			}

			videoLink = stringifyUrl({
				url: `https://www.youtube.com/embed/${videoId}`,
				query,
			});
		}
		else if (type === VIMEO_VIDEO) {
			videoLink = `https://player.vimeo.com/video/${videoId}`;
		}
		else if (type === LOOM_VIDEO) {
			videoLink = `https://www.loom.com/embed/${videoId}`;
		}
		else {
			videoLink = '';
		}

		return videoLink;
	}, [start, type, videoId]);

	return (
		<div className={classes.videoContainer}>
			{
				type === YOUTUBE_VIDEO && (
					<iframe
						title="Youtube Embedded Video"
						src={url}
						className="rounded"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				)
			}
			{
				type === VIMEO_VIDEO && (
					<iframe
						title="Vimeo Embedded Video"
						src={url}
						className="rounded"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
					/>
				)
			}
			{
				type === LOOM_VIDEO && (
					<iframe
						title="Loom Embedded Video"
						src={url}
						className="rounded"
						frameBorder="0"
						allow="fullscreen;"
					/>
				)
			}
		</div>
	);
}

export default EmbeddedVideo;
