import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	connect
} from "react-redux";
import {
	Dialog,
	DialogTitle,
	makeStyles,
} from '@material-ui/core';
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../_metronic";

import { CloudDownloadIcon } from "../../common/icons";
import { getToken } from "../../store/modules/selectors/provider.selector";

const useStyles = makeStyles({
	uploadInput: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
	},
});

function Upload(props) {
	const {
		handleClose,
		item,
		uploadFiles,
		isUploading,
		selectForPreview,
		isRequestProtected,
	} = props;
	const classes = useStyles();
	const token = useSelector(getToken);

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth="sm"
			classes={{
				paper: "rounded-card"
			}}
		>
			<DialogTitle className='d-none'>
				{''}
			</DialogTitle>

			<div className="p-20">
				<div className="row f-15 label-4">
					<div className="col-10 offset-1">
						<h4 className="mb-0 text-center">
							<FormattedMessage id="REQUEST.UPLOAD.TITLE" />
						</h4>
					</div>
					<div className="col-1">
						<div className="float-right cursor-pointer" onClick={() => handleClose()}>
							<span className="fas fa-times"></span>
						</div>
					</div>
				</div>

				<div className="m-20 border rounded">
					{
						item.files.map((upload, index, arr) => {
							const FILE_NAME_MAX_CHAR_LENGTH = 30;

							return (
								<React.Fragment key={index}>
									<div className="file-item px-40 my-20 d-flex align-items-center justify-content-between flex-column flex-sm-row">
										<div className="d-flex align-items-center flex-column flex-sm-row mb-3 mb-sm-0">
											<div
												className={`mr-0 mr-sm-3 ${isRequestProtected ? 'cursor-pointer' : ''} mb-3 mb-sm-0`}
												onClick={() => isRequestProtected && selectForPreview(index)}
											>
												<img
													src={upload.thumbnailUrl || toAbsoluteUrl("/media/icons/svg/Files/Simple-File.svg")}
													alt={upload.name}
												/>
											</div>
											<div className="text-center text-sm-left">
												<div className="filename" title={upload.name}>
													<h5 className="m-0">
														{`${upload.name.slice(0, FILE_NAME_MAX_CHAR_LENGTH)} ${upload.name.length > FILE_NAME_MAX_CHAR_LENGTH ? '...' : ''}`}
													</h5>						
												</div>
												<div>
													<span>
														{moment(upload.date).format('DD MMM YYYY - HH:mm')}
													</span>
												</div>
											</div>
										</div>

										<div className="d-flex pl-0 pl-sm-4 justify-content-between align-items-center cursor-pointer">
											{
												isRequestProtected && (
													<div>
														<a href={`${axios.defaults.baseURL}/api/v1/document-requests/${item.requestId}/${item.documentProviderId}/${item._id}/${upload._id}?token=${token}`} download>
															<CloudDownloadIcon />
														</a>
													</div>
												)
											}
											{
												// <div className="pl-4">
												// 	<a href="#">
												// 		<DeleteIcon />
												// 	</a>
												// </div>
											}
										</div>
									</div>

									{
										index !== arr.length - 1 && (
											<hr />
										)
									}
								</React.Fragment>
							)
						})
					}
				</div>

				<div className="d-flex justify-content-center">
					<div>
						<button
							className={`btn btn-primary position-relative ${clsx({
								"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isUploading
							})}`}
						>
							<input
								onChange={({ target: { files } }) => uploadFiles(files)}
								type="file"
								className={`${classes.uploadInput} w-100`}
								multiple
							/>

							<FormattedMessage id="PROVIDER.DOCUMENT.UPLOAD" />
						</button>
					</div>
				</div>

			</div>
		</Dialog>
	);
}

const mapStateToProps = (store, props) => {
	const {
		provider: {
			check,
			data
		},
	} = store;

	const isRequestProtected = check.requirePassword;
	const item = data.documents.find(({ _id }) => _id === props.itemId);

	return {
		item,
		isRequestProtected,
	};
};

export default injectIntl(
	connect(
		mapStateToProps,
		null,
	)(Upload)
)
