import React, { useEffect, useState, useRef } from "react";
import { CloseOutlined, DragHandleOutlined, CheckCircle, CheckCircleOutline } from "@material-ui/icons";
import { useIntl } from "react-intl";
import clsx from "clsx";
import ErrorMessage from "../ErrorMessage";
import { useDebouncedCallback } from "use-debounce/lib";
import { useTheme } from "@material-ui/core";

const Option = ({
	index,
	dragHandleProps,
	isElementActive,
	data,
	onSelect,
	onChange,
	onKeyPress,
	onDelete,
	isSelected,
	error,
}) => {
	const intl = useIntl();
	const ref = useRef();
	const theme = useTheme();

	const [isActive, setIsActive] = useState(false);
	
	const shouldShowControls = isActive && isElementActive;

	useEffect(() => {
		ref.current.value = data.title;
	}, [data.title]);

	const handleTitleChange = useDebouncedCallback((title) => {
		if (!onChange) {
			return;
		}

		// dont listen to changes in onChange field since it depends on options array but triggering it also 
		// updates the options so it goes into loop call 
		// onChange -> 
		// update options -> 
		// update onChange -> 
		// this useEffect gets triggered -> 
		// call onChange
		// eslint-disable-next-line react-hooks/exhaustive-deps
		onChange({
			target: {
				value: title,
			}
		});
	}, 1000);

	return (
		<div
			className={
				clsx(
					"d-flex align-items-start mb-1 px-2",
					{ "rounded bg-light-blue border border-success": isSelected }
				)
			}
			onMouseOver={() => setIsActive(true)}
			onMouseOut={() => setIsActive(false)}
		>
			<div 
				className="cursor-pointer my-2"
				onClick={onSelect}
			>
				{
					isSelected ? (
						<CheckCircle style={{ color: theme.palette.success.main }} />
					) : (
						<CheckCircleOutline style={{ color: theme.palette.extraColors.darkBlueBorder }} />
					)
				}
			</div>

			<div className="invisible-input-container col-9 mt-1">
				<input
					ref={(inputRef) => ref.current = inputRef}
					type="text"
					className="span mb-0 f-16px text-dark w-100"
					onChange={(e) => handleTitleChange(e.target.value)}
					onKeyPress={onKeyPress}
					defaultValue={data.title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.CHOICES.TITLE.PLACEHOLDER",
						}, {
							index: index + 1,
						})
					}
				/>

				{
					error && (
						<ErrorMessage message={error} />
					)
				}
			</div>

			<div
				style={{ visibility: shouldShowControls ? "visible" : "hidden", cursor: "move" }}
				className="mr-3 d-flex align-items-center option-drag-handle cursor-pointer p-2"
				{...dragHandleProps}
			>
				<DragHandleOutlined />
			</div>

			<div
				className="cursor-pointer d-flex align-items-center p-2"
				style={{ visibility: shouldShowControls ? "visible" : "hidden" }}
				onClick={onDelete}
			>
				<CloseOutlined />
			</div>
		</div>
	);
}

export default Option;