import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import NotificationListToggler from "./NotificationListToggler";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
  }

  render() {
    const {
      // headerLogo,
      asideDisplay,
      // headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes
    } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile align-items-center ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        {
          // <div className="kt-header-mobile__logo">
          //   <Link to="/">
          //     <img alt="logo" src={headerLogo} />
          //   </Link>
          // </div>
        }

        <div className="kt-header-mobile__toolbar">
          {
            asideDisplay && (
              <button
                className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
                id="kt_aside_mobile_toggler"
              >
                <span />
              </button>
            )
          }
        </div>

        <div className="actions flex-grow-1 d-flex justify-content-end f-20">
          <NotificationListToggler toggleBy="kt_notification_list_toggler_btn_mobile" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  })
});

export default connect(mapStateToProps)(HeaderMobile);
