import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	Dialog,
	AppBar,
	Toolbar,
} from '@material-ui/core';
import {
	Close
} from '@material-ui/icons';
import { Alert } from "reactstrap";
import {
	useDispatch
} from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { CloudDownloadIcon } from "./icons";
import PDFPreview from "./PDFPreview";
import AudioPreview from "./AudioPreview";
import { requestSignedUrl } from "../../_metronic";
import { downloadFileTask } from "../store/modules/actions/tasks.actions";

function Preview(props) {
	const {
		handleClose,
		file,
	} = props;
	const dispatch = useDispatch();
	const [signedUrl, setSignedUrl] = useState(null);
	const [isImageLoaded, setImageLoaded] = useState(false);

	const getSignedUrl = useCallback(async () => {
		if (file instanceof File) {
			const url = URL.createObjectURL(file);
			return Promise.resolve({
				data: {
					url,
				}
			});
		}
		else {
			return requestSignedUrl(file.url);
		}
	}, [
		file,
	]);

	useEffect(() => {
		getSignedUrl()
			.then(({ data }) => {
				setSignedUrl(data.url);
				return Promise.resolve()
			})
			.catch((err) => {
				console.log(err);
				setSignedUrl(null);
			})
	}, [
		file,
		getSignedUrl
	]);

	const downloadFile = useCallback(async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const { data } = await getSignedUrl();
		try {
			await dispatch(
				downloadFileTask(
					data.url,
					file.name
				)
			);
		}
		catch (e) {
			console.log(e);
		}
	}, [
		file,
		getSignedUrl,
		dispatch,
	])

	const type = file.name.split('.').pop();
	const isImageFile = useMemo(() => [/png/i, /jpg/i, /jpeg/i].some((imageType) => imageType.test(type)), [type]);
	const isAudioFile = useMemo(() => [/wav/i, /mp3/i].some((audioType) => audioType.test(type)), [type]);
	const isVideoFile = useMemo(() => [/mp4/i, /avi/i, /mkv/i, /webm/i].some((videoType) => videoType.test(type)), [type]);
	const isPdf = useMemo(() => type === "pdf", [type]);
	const canPreview = useMemo(() => isImageFile || isVideoFile || isPdf || isAudioFile, [isAudioFile, isImageFile, isPdf, isVideoFile]);

	return (
		<Dialog
			fullScreen
			open
			onClose={handleClose}
			className="d-flex flex-column"
		>
			<AppBar position="relative">
				<Toolbar className="d-flex">
					<div className="flex-grow-1 d-flex align-items-center text-white justify-content-between">
						<div>
							<h6 className="m-0 pr-20">
								{`${file.name.slice(0, 30)}${file.name.length > 30 ? '...' : ''}`}
							</h6>
						</div>
						<div>
							<div className="text-center cursor-pointer" onClick={downloadFile}>
								<div>
									<span className="text-light white-download-icon">
										<CloudDownloadIcon />
									</span>
								</div>
								<div>
									<span>
										<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="pl-4 cursor-pointer" onClick={() => handleClose()}>
						<Close />
					</div>
				</Toolbar>
			</AppBar>

			<div className="flex-grow-1 d-flex flex-column preview-container">
				{
					isImageFile && (
						<TransformWrapper
							wheel={{
								step: 2,
							}}
							pan={{
								paddingSize: 1
							}}
							options={{
								minScale: 0,
							}}
						>
							{
								() => (
									<div className={`image-preview flex-grow-1 d-flex justify-content-center align-items-center w-100 p-4 ${isImageLoaded ? '' : 'loading'}`}>
										<TransformComponent>
											<img
												onLoad={() => setImageLoaded(true)}
												className="rounded"
												src={signedUrl}
												alt={file.name}
											/>
										</TransformComponent>
									</div>
								)
							}
						</TransformWrapper>
					)
				}
					
				{
					isPdf && (
						file instanceof File ? (
							<div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column">
								<Alert color="primary">
									<FormattedMessage id="REQUEST.DOCUMENT.PREVIEW.FILE_NOT_UPLOADED_YET" />
								</Alert>
								<div
									className={`rounded border border-primary mt-2 py-2 px-4 cursor-pointer text-primary`}
									onClick={downloadFile}
								>
									<span className="mr-2">
										<CloudDownloadIcon />
									</span>
									<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
								</div>
							</div>
						) : (
							<PDFPreview
								fileName={file.name}
								url={signedUrl}
								containerClass="flex-grow-1"
							/>
						)
					)
				}

				{
					isVideoFile && (
						file instanceof File ? (
							<div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column">
								<Alert color="primary">
									<FormattedMessage id="REQUEST.DOCUMENT.PREVIEW.FILE_NOT_UPLOADED_YET" />
								</Alert>
								<div
									className={`rounded border border-primary mt-2 py-2 px-4 cursor-pointer text-primary`}
									onClick={downloadFile}
								>
									<span className="mr-2">
										<CloudDownloadIcon />
									</span>
									<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
								</div>
							</div>
						) : (
							<video src={signedUrl} controls autoPlay style={{ height: "calc(100vh - 64px)", }} />
						)
					)	
				}

				{
					isAudioFile && (
						file instanceof File ? (
							<div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column">
								<Alert color="primary">
									<FormattedMessage id="REQUEST.DOCUMENT.PREVIEW.FILE_NOT_UPLOADED_YET" />
								</Alert>
								<div
									className={`rounded border border-primary mt-2 py-2 px-4 cursor-pointer text-primary`}
									onClick={downloadFile}
								>
									<span className="mr-2">
										<CloudDownloadIcon />
									</span>
									<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
								</div>
							</div>
						) : (
							signedUrl && (
								<div className="d-flex flex-grow-1 align-items-center justify-content-center px-4">
									<AudioPreview src={signedUrl} />
								</div>
							)
						)
					)	
				}

				{
					!canPreview && (
						<div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column px-4 px-md-2">
							<Alert color="danger">
								<FormattedMessage id="REQUEST.DOCUMENT.PREVIEW.UNSUPPORTED_CONTENT" />
							</Alert>

							<div
								className={`rounded border border-primary mt-2 py-2 px-4 cursor-pointer text-primary`}
								onClick={downloadFile}
							>
								<span className="mr-2">
									<CloudDownloadIcon />
								</span>
								<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
							</div>
						</div>
					)
				}
			</div>
		</Dialog>
	);
}

export default Preview;