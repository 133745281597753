import axios from "axios";
import moment from "moment";
import heic2any from "heic2any";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const vanillaAxios = axios.create();

export const SERVER_URL = process.env.REACT_APP_API_URL;

export const PASSWORD_REGEX = /(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&\-_|\\:;"'<,>.?/[\]{}`~])/;

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, isMocked) {
  if (!isMocked) {
    axios.defaults.baseURL = SERVER_URL;
  }

  axios.interceptors.request.use(
    config => {
      const authToken = getStorage("token");
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use((response) => {
    return response;
  }, (err) => {
    console.log(err);
    if (err && err.response && err.response.status === 405) {
      removeStorage("token");
      let errorMessage = err.response.data && err.response.data.error && err.response.data.error.message;

      errorMessage = errorMessage || "Session timed out, Please log-in again.";

      window.location = "/auth/login?error=" + errorMessage;
    };

    return Promise.reject(err);
  });
}

export function setupSentry () {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
      key <string> : localStorage key
    returns:
      <string> : value of localStorage key
      null : in case of expired key or failure
 */
export function getStorage(key) {
  try {
    const value = localStorage.getItem(key);
    return value;
  } catch (e) {
    console.log(
      "getStorage: Error reading key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return null;
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
      key <string>     : localStorage key
      value <string>   : localStorage value
    returns:
      <boolean> : telling if operation succeeded
 */
export function setStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export const getFileExtension = (filename) => {
  const fragments = filename.split(".");
  const extension = fragments.pop().toUpperCase();

  return extension;
}

export const getFileIcon = (filename) => {
  const extension = filename ? getFileExtension(filename) : undefined;

  switch (extension) {
    case "PDF": {
      return "/media/files/pdf.png";
    }
    case "JPEG":
    case "PNG":
    case "TIFF":
    case "JPG": {
      return "/media/files/image.png";
    }

    default: {
      return "/media/files/file.png";
    }
  }
}

export function getFileName(filename) {
  if (!filename) return "";

  const CHAR_LIMIT = 24;
  const fragment = filename.slice(0, CHAR_LIMIT);
  return `${fragment}${filename.length > CHAR_LIMIT ? "..." : ""}`;
}

export function dataURLtoFile(dataUrl, filename) {

  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function isAttachmentValid(file) {
  const invalidFileExtension = [
    ".ade",
    ".adp",
    ".apk",
    ".appx",
    ".appxbundle",
    ".bat",
    ".cab",
    ".chm",
    ".cmd",
    ".com",
    ".cpl",
    ".dll",
    ".dmg",
    ".exe",
    ".hta",
    ".ins",
    ".isp",
    ".iso",
    ".jar",
    ".js",
    ".jse",
    ".lib",
    ".lnk",
    ".mde",
    ".msc",
    ".msi",
    ".msix",
    ".msixbundle",
    ".msp",
    ".mst",
    ".nsh",
    ".pif",
    ".ps1",
    ".scr",
    ".sct",
    ".shb",
    ".sys",
    ".vb",
    ".vbe",
    ".vbs",
    ".vxd",
    ".wsc",
    ".wsf",
    ".wsh",
  ];

  const isFileInvalid = invalidFileExtension.some((invalidExtension) => {
    return file.name.endsWith(invalidExtension);
  });

  return !isFileInvalid;
}

/**
 * Convert HEIC/HEIF images to PNG on client side before uploading since
 * browser do not support those image files natively
 * @param {File} file 
 */
export async function sanitizeFile (file) {
  const extension = getFileExtension(file.name);
  
  if (["HEIF", "HEIC"].includes(extension)) {
    try {
      const CONVERT_TO_MIME_TYPE = "image/png";
      const fileBlob = await heic2any({
        blob: file,
        toType: CONVERT_TO_MIME_TYPE,
        quality: 1,
      });
      const fileNameFragments = file.name.split(".");
      
      // remove extension
      fileNameFragments.pop();
      
      let fileName = fileNameFragments.join(".");
      
      // add new extension
      fileName += ".png";

      file = new File([fileBlob], fileName, {
        type: CONVERT_TO_MIME_TYPE
      });
    }
    catch (e) {
      console.log(e);
    }
    finally {
      return file;
    }
  }

  return file;
}

export async function downloadLocalFile (file, filename) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(file);

  // Use download attribute to set set desired file name
  a.setAttribute("download", filename);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

const generateId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

// since it"s bad practice to store non-serializable objects into redux store and axios
// provides no utility to get source instance from token we are maintaining that mapping 
// manually in global window object;
window.AXIOS_GLOBAL_CANCEL_TOKENS = {};

export const getNewCancelToken = () => {
  const source = axios.CancelToken.source();
  source.id = generateId();
  window.AXIOS_GLOBAL_CANCEL_TOKENS[source.id] = source;

  return source;
};

export const cancelRequest = (id) => {
  const source = window.AXIOS_GLOBAL_CANCEL_TOKENS[id];

  if (source) {
    source.cancel();
  }

  delete window.AXIOS_GLOBAL_CANCEL_TOKENS[id];
}

export async function downloadFileFromUrl (url, filename, cancelTokenId = undefined, onProgress) {
  const cancelSource = cancelTokenId && window.AXIOS_GLOBAL_CANCEL_TOKENS[cancelTokenId];
  const cancelToken = cancelSource && cancelSource.token;

  try {
    const response = await vanillaAxios({
      url,
      responseType: "blob",

      cancelToken,
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
    });

    const fileData = new Blob([response.data]);
    downloadLocalFile(fileData, filename);
  }
  finally {
    if (cancelTokenId) {
      delete window.AXIOS_GLOBAL_CANCEL_TOKENS[cancelTokenId];
    }
  }
}

export async function requestSignedUrl (url) {
  return axios.post(`/services/preview-from-url`, { url })
}

export function getDummyFileRenameStrings (fileRename = []) {
  const sanitizedFileRename = fileRename.filter((fragment) => !!fragment);

  if (!sanitizedFileRename.length) {
    return [];
  }

  const fileRenameStrings = {
    "{{firstName}}": "John",
    "{{lastName}}": "Doe",
    "{{mm}}": moment().format("MM"),
    "{{yyyy}}": moment().format("YYYY"),
    "{{date}}": moment().format("DD-MM-YYYY-HH-mm-ss"),
  };
  const fileRenameDummyString = fileRename
    .map((fragment) => fileRenameStrings[fragment] || fragment)
    .join("_");
  const extensions = ["pdf", "png"];
  const fileRenameHintStrings = extensions.map((extension, index) => {
    let appendStr = index ? `_${index}.${extension}` : `.${extension}`;
    return `${index+1}. ${fileRenameDummyString}${appendStr}`;
  });

  return fileRenameHintStrings;
}

function fallbackCopyTextToClipboard(text) {
  return new Promise((resolve, reject) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        resolve();
      }
      else {
        reject();
      }
    } catch (err) {
      reject(err);
    }

    document.body.removeChild(textArea);
  });
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }

  return navigator.clipboard.writeText(text);
}

export function globalGA (event, data = {}) {
  if (window.dataLayer && process.env.REACT_APP_ENV !== "local") {
    window.dataLayer.push({
      ...data,
      event,
    })
  }
  else {
    console.warn("Google Tag Manager is not configured", arguments)
  }
}

export function getInputSize (value) {
  const reductionOffset = value.length / 7;
  return value.length - reductionOffset;
}

export function isValidEmail (email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return !!email && emailRegex.test(email);
}

function getElementY(query) {
  return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
}

export function doScrolling(elementQuerySelector, duration = 1000, offset = 0) {
	var startingY = window.pageYOffset
  var elementY = getElementY(elementQuerySelector)
  // If element is close to page's bottom then window will scroll only to some position above the element.
  var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY

  targetY += offset;

	var diff = targetY - startingY;
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
  var start

  if (!diff) return

	// Bootstrap our animation - it will get called right before next frame shall be rendered.
	window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    var time = timestamp - start
		// Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

		// Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

export async function base64ToFile(dataUrl, fileName, options) {
  // dataUrl is of format 'data:image/png;base6....';
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, options);
}

export function addAlpha(color, opacity) {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function pickTextColor(bgColor = "#E0E0E0", lightColor = "white", darkColor = "black") {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    darkColor : lightColor;
}

window.globalGA = globalGA;