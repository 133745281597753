import React, { useRef, useState, useMemo, useEffect } from "react"; 

const COUNTDOWN_DURATION_SECONDS = 3;
function CountdownTimer ({ status, wasPausedManually, onComplete }) {
	const intervalRef = useRef();
	const [timerSeconds, setTimerSeconds] = useState(COUNTDOWN_DURATION_SECONDS);
	const text = useMemo(() => {
		if (timerSeconds <= 0) return '';

		return `${timerSeconds}`;
	}, [timerSeconds]);

	useEffect(() => {
		if (status === "paused" && !wasPausedManually) {
			if (intervalRef.current) {
				if (timerSeconds === 0) {
					clearInterval(intervalRef.current);
					onComplete();
				}
			}
			else {
				setTimerSeconds(COUNTDOWN_DURATION_SECONDS);
				intervalRef.current = setInterval(() => {
					setTimerSeconds((val) => val - 1);
				}, 1000);
			}
		}
	}, [onComplete, status, timerSeconds, wasPausedManually]);

	return (
		<div className='text-white text-center' style={{ fontSize: 80 }}>
			{text}
		</div>
	)
}

export default CountdownTimer;