import { actionTypes } from "./actions/notifications.actions";

const initialState = {
	unreadCount: null,
	list: [],
	total: 0,
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.NOTIFICATIONS_CLEAR: {
			return {
				...state,
				list: [],
				total: 0,
			};
		}

		case actionTypes.NOTIFICATIONS_LOADED: {
			const {
				list,
				total,
			} = action.payload;

			return {
				...state,
				list: [
					...state.list,
					...list,
				],
				total,
			};
		}

		case actionTypes.NOTIFICATION_DELETED: {
			const {
				notificationId,
			} = action.payload;
			const {
				list,
				total,
				unreadCount
			} = state;
			const notification = list.find(({ _id }) => _id === notificationId);
			const newTotal = total - 1;
			const newUnreadCount = !notification.hasRead ? unreadCount - 1 : unreadCount;

			return {
				...state,
				list: list.filter(({ _id }) => _id !== notificationId),
				total: newTotal,
				unreadCount: newUnreadCount,
			};
		}

		case actionTypes.MARK_NOTIFICATIONS_READ: {
			const { notificationIds } = action.payload;
			const updatedList = state.list.map((notification) => {
				if (notificationIds.includes(notification._id)) {
					notification.hasRead = true;
				}

				return {
					...notification,
				};
			}); 

			return {
				...state,
				list: updatedList,
				unreadCount: updatedList.filter(({ hasRead }) => !hasRead),
			};
		}

		case actionTypes.UNREAD_COUNT: {
			const { count } = action.payload;

			return {
				...state,
				unreadCount: count,
			};
		}

		default:
			return state;
	}
}
