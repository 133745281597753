import Constants from "../common/constants";
import { READ_ONLY_TYPES } from "../common/dnd-element-list/Element";

export default function getIncompleteElements (elements) {
    const incompleteElements = elements
        .filter((element) => {
            const completedStatuses = [
                Constants.DocumentStatus.APPROVED,
                Constants.DocumentStatus.NOT_REQUIRED,
                Constants.DocumentStatus.UPLOADED,
            ];
            const isReadOnlyElement = READ_ONLY_TYPES.includes(element.type);

            return !isReadOnlyElement && !completedStatuses.includes(element.status)
        });

    const elementsWithError = incompleteElements.reduce((p, { _id }) => ({
        ...p,
        [_id]: true
    }), {});

    return elementsWithError;
}