import { actionTypes } from "./actions/provider.actions";

const initialState = {
	check: null,
	data: {},
	documentsWithError: {},
	elementsWithError: {},
	messages: {
		data: [],
		count: 0,
	},
	comments: {
		data: [],
		count: 0,
	},
	operation: {},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CHECK: {
			const { data } = action.payload;

			return {
				...state,
				check: data,
			};
		}
		case actionTypes.PROVIDER_DATA: {
			return {
				...state,
				data: {
					...state.data,
					...action.payload,
				},
			};
		}
		case actionTypes.PROVIDER_MESSAGES_LOADED: {
			return {
				...state,
				data: {
					...state.data,
					unreadMessagesCount: 0,
				},
				messages: action.payload,
			};
		}

		case actionTypes.PROVIDER_MESSAGE_ADDED: {
			return {
				...state,
				messages: {
					data: [
						action.payload.data,
						...state.messages.data,
					],
					count: state.messages.count + 1
				},
			};
		}

		case actionTypes.ELEMENT_COMMENTS_LOADED: {
			const {
				payload: {
					comments,
					elementId,
				}
			} = action;
			const {
				data,
			} = state;

			let updatedElements = data.elements.slice();

			updatedElements = updatedElements.map((element) => {
				if (element._id === elementId) {
					// set count of unread comments as 0 as user has loaded the comments
					element.unreadCommentsCount = 0;
				}

				return element;
			});

			return {
				...state,
				data: {
					...data,
					elements: updatedElements,
				},
				comments,
			};
		}

		case actionTypes.ELEMENT_COMMENT_ADDED: {
			return {
				...state,
				comments: {
					data: [
						action.payload.data,
						...state.comments.data,
					],
					count: state.comments.count + 1
				},
			};
		}

		case actionTypes.DOCUMENT_UPDATED: {
			const documents = state.data.documents.map((doc) => {
				const docObj = doc._id === action.payload._id ? action.payload : doc;
				
				return {
					...docObj,
				};
			});

			return {
				...state,
				data: {
					...state.data,
					documents,
				},
			};
		}
		case actionTypes.ELEMENT_UPDATED: {
			const elements = state.data.elements.map((doc) => {
				const docObj = doc._id === action.payload._id ? action.payload : doc;
				return docObj;
			});

			return {
				...state,
				data: {
					...state.data,
					elements,
				},
			};
		}

		case actionTypes.SET_DOCUMENTS_WITH_ERROR: {
			return {
				...state,
				documentsWithError: action.payload.documentIds,
			}
		}
		case actionTypes.SET_ELEMENTS_WITH_ERROR: {
			return {
				...state,
				elementsWithError: action.payload.elementIds,
			}
		}

		case actionTypes.PERFORM_OPERATION: {
			return {
				...state,
				operation: {
					action: action.payload.action,
					data: action.payload.data,
				}
			}
		}

		default:
			return state;
	}
}
