import React from "react";

class IframeLoader extends React.Component {
	constructor(props) {
		super(props);
		this.iframeRef = React.createRef();
	}

  componentDidMount() {
		this.iframeRef.current.addEventListener('load', this.props.onLoad);
  }

  render() {
    return (
			<iframe
				title="loader"
				ref={this.iframeRef}
				{...this.props}
			/>
		);
  }
}

export default IframeLoader;