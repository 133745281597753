import React, {
	useState,
	useCallback,
	useRef,
	useMemo,
} from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl
} from "react-intl";
import {
	useSelector,
	useDispatch,
} from "react-redux";
import {
	Alert,
} from "reactstrap";
import {
	FormLabel,
	MenuItem,
	Fab,
	makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import _ from "lodash";
import { useSnackbar } from "notistack";

import Input from "../../../../../../common/Input";
import Select from "../../../../../../common/Select";
import { PlusIcon, FileErrorIcon } from "../../../../../../common/icons";
import Constants from "../../../../../../common/constants";

import {
	updateTemplate,
	flagNames as templateFlagNames
} from '../../../../../../store/modules/actions/templates.actions';

import ItemCard from "../../common/ItemCard";
import Confirm from "../../../../../../common/modals/confirm";
import useUserRestriction from "../../../../../../common/hooks/user-restriction";
import { setUpgradeDialog } from "../../../../../../store/modules/actions/app.actions";
import Preview from "../../../../../../common/Preview";
import { getSelectedTemplate, getTemplatesList } from "../../../../../../store/modules/selectors/template.selector";
import { getFlags } from "../../../../../../store/modules/selectors/common.selector";

const DEFAULT_CUSTOM_ITEM = {
	enabled: true,
	name: '',
	description: '',
	type: Constants.DocumentType.REGULAR,
	attachments: [],
	fileRename: [],
};

const useStyles = makeStyles((theme) => {
	return {
		addDocumentButton: {
			background: 'white',
			height: '28px',
			width: '28px',
			minHeight: '28px',

			'&:hover': {
				background: 'white'
			}
		}
	}
});

function Edit({ template: templateId }) {
	const dispatch = useDispatch();
	const intl = useIntl();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const submittedManually = useRef(false);
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const allTemplates = useSelector(getTemplatesList);
	const template = useSelector(getSelectedTemplate);
	const flags = useSelector(getFlags);
	const templates = useMemo(() => {
		return allTemplates.filter(({ _id, format }) => (
				_id !== templateId &&
				format !== "MODERN"
			));
	}, [allTemplates, templateId]);
	
	const [previewInfo, setPreviewInfo] = useState(null);
	const [showAttachmentSizeWarning, setAttachmentSizeWarning] = useState(false);

	const doesAttachmentsExceedsLimit = useCallback((values, files) => {
		const existingAttachmentSizeKB = values.items.reduce((p, item) => {
			const itemAttachmentSize = item.attachments.reduce((q, attachment) => {
				return q + (attachment instanceof File ? (attachment.size / 1024) : attachment.size);
			}, 0);

			return p + itemAttachmentSize;
		}, 0);
		const newFilesKB = files.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}, []);

	const submit = useCallback(async (values) => {
		await dispatch(updateTemplate(templateId, values));
		const message = intl.formatMessage({ id: "TEMPLATES.EDIT.SUCCESS" });
		enqueueSnackbar(message, {
			variant: "success",
			autoHideDuration: 5000
		});
	}, [
		templateId,
		intl,
		enqueueSnackbar,
		dispatch,
	]);

	if (!restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	if (!restrictions.features.TEMPLATES) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<Alert color="danger">
					<FormattedHTMLMessage id="GENERAL.UPGRADE_MESSAGE_WITH_LINK" />
				</Alert>
			</div>
		);
	}

	return (
		<div className="edit-template-container bg-white rounded px-3 py-4">
			<div className="rounded-card template-card">
				<div className="p-0">
					<div className="row px-20px mb-25px">
						<div className="col-12 d-flex align-items-center px-0">
							<div className="f-20px text-dark font-weight-bold">
								{template.name}
							</div>
						</div>
					</div>

					<Formik
						initialValues={{
							...template,
						}}
						validate={values => {
							const error = {};
							const toastErrors = [];

							if (!values.name) {
								error.name = intl.formatMessage({
									id: "TEMPLATE.NAME.VALIDATION.REQUIRED"
								});
								toastErrors.push("TEMPLATE.TOAST_ERROR.NAME.REQUIRED")
							}
							else {
								const isAlreadyExists = templates.find(({ name, _id }) => name === values.name && _id !== template._id);

								if (isAlreadyExists) {
									error.name = intl.formatMessage({
										id: "TEMPLATE.NAME.VALIDATION.DUPLICATE",
									}, values);
								}
							}

							if (!values.items || !values.items.length) {
								error.items = intl.formatMessage({
									id: "TEMPLATE.ITEMS.VALIDATION.REQUIRED"
								});
								toastErrors.push("TEMPLATE.TOAST_ERROR.DOCUMENTS.REQUIRED");
							}
							else {
								for (let item of values.items) {
									if (!item.name) {
										toastErrors.push("TEMPLATE.TOAST_ERROR.DOCUMENT.NAME.REQUIRED")
										break;
									}
								}
							}

							if (submittedManually.current) {
								toastErrors.forEach((messageId) => {
									const message = intl.formatMessage({ id: messageId });
									enqueueSnackbar(message, {
										variant: 'error',
										autoHideDuration: 5000
									});
								});

								submittedManually.current = false;
							}

							return error;
						}}
						validateOnChange={false}
						validateOnBlur={true}
						onSubmit={submit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
						}) => {
							return (
								<form
									noValidate
									autoComplete="off"
								>
									<fieldset disabled={isInReadOnlyMode}>
										<div className="row px-10">
											<div className="col-md-6 col-12">
												<Input
													labelId="TEMPLATE.NAME"
													containerClass="mb-30px"
													type="text"
													variant="outlined"
													fullWidth
													name="name"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.name}
													helperText={touched.name && errors.name}
													error={Boolean(touched.name && errors.name)}
												/>
											</div>

											<div className="col-md-6 col-12">
												<div className="dark-custom-input form-group mb-30px">
													<FormLabel className="mb-10">
														<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE" />
													</FormLabel>
													<Select
														value={values.template || ''}
														classes={{
															root: `d-flex align-items-center`
														}}
														name="template"
														variant="outlined"
														onChange={(e) => {
															const selectedTemplateId = e.target.value;
															const selectedTemplate = templates.find(({ _id }) => _id === selectedTemplateId);

															handleChange(e);

															setFieldValue("items", selectedTemplate ? selectedTemplate.items.slice() : []);
														}}
														fullWidth
														placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
													>
														{
															templates && templates.length ? (
																templates.map(({ _id, name }) => (
																	<MenuItem value={_id} key={_id}>{name}</MenuItem>
																))
															) : (
																<MenuItem value={null} disabled>
																	<FormattedMessage
																		id="GENERAL.NO_ITEMS_FOUND"
																		values={{ itemTitle: "Templates" }}
																	/>
																</MenuItem>
															)
														}
													</Select>
												</div>
											</div>
										</div>

										<hr className="mt-0 mb-30px" />

										<div className="row px-10">
											<div className="col-md-5 col-12">
												<Input
													labelId="TEMPLATE.EMAIL.SUBJECT"
													containerClass="mb-30px"
													type="text"
													variant="outlined"
													fullWidth
													name={`emailMeta.subject`}
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.emailMeta.subject}
													helperText={_.get(touched, "emailMeta.subject") && _.get(errors, "emailMeta.subject")}
													error={Boolean(_.get(touched, "emailMeta.subject") && _.get(errors, "emailMeta.subject"))}
												/>
											</div>

											<div className="col-md-7 col-12">
												<Input
													labelId="TEMPLATE.EMAIL.MESSAGE"
													containerClass="mb-30px"
													type="text"
													variant="outlined"
													fullWidth
													multiline
													rows="4"
													name={`emailMeta.content`}
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.emailMeta.content}
													helperText={_.get(touched, "emailMeta.content") && _.get(errors, "emailMeta.content")}
													error={Boolean(_.get(touched, "emailMeta.content") && _.get(errors, "emailMeta.content"))}
												/>
											</div>
										</div>

										<hr className="mb-30px mt-0" />

										<div className="row mt-4 px-10">
											{
												values.items && values.items.length > 0 && values.items.map((item, itemIndex) => {
													return (
														<div className="col-12 mb-4" key={itemIndex}>
															<ItemCard
																className="p-15"
																item={item}
																handleNameChange={(e) => {
																	setFieldValue(
																		`items[${itemIndex}].name`,
																		e.target.value
																	);
																}}
																handleDelete={() => {
																	const items = values.items.filter((item, index) => index !== itemIndex);
																	setFieldValue(
																		`items`,
																		items
																	);
																}}
																handleMessageChange={(e) => {
																	setFieldValue(
																		`items[${itemIndex}].description`,
																		e.target.value
																	)
																}}
																handleTypeChange={(newType) => {
																	setFieldValue(
																		`items[${itemIndex}].type`,
																		newType
																	);
																}}
																handleFileRenameAdd={() => {
																	if (!restrictions.features.FILE_RENAME_FEATURE) {
																		dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
																		return;
																	}

																	setFieldValue(
																		`items[${itemIndex}].fileRename`,
																		[...item.fileRename, ""]
																	)
																}}
																handleFileRenameChange={(index, value) => {
																	if (!restrictions.features.FILE_RENAME_FEATURE) {
																		dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
																		return;
																	}

																	setFieldValue(
																		`items[${itemIndex}].fileRename[${index}]`,
																		value
																	)
																}}
																handleFileRenameRemove={(index) => {
																	const updatedFileRename = item.fileRename.filter((v, i) => i !== index);
																	setFieldValue(
																		`items[${itemIndex}].fileRename`,
																		updatedFileRename
																	)
																}}
																handleAddFiles={(files) => {
																	if (!restrictions.storageAllowed) {
																		dispatch(setUpgradeDialog("USER.UPLOAD.PLAN_LIMIT_REACHED"));
																		return;
																	}
																	
																	if (doesAttachmentsExceedsLimit(values, files)) {
																		setAttachmentSizeWarning(true);
																		return;
																	}

																	setFieldValue(
																		`items[${itemIndex}].attachments`,
																		[
																			...item.attachments,
																			...files
																		]
																	)
																}}
																handleDeleteFile={(index) => {
																	setFieldValue(
																		`items[${itemIndex}].attachments`,
																		item.attachments.filter((f, i) => i !== index),
																	);
																}}
																handleFilePreview={(attachmentIndex) => setPreviewInfo({ docIndex: itemIndex, attachmentIndex })}
															/>
														</div>
													);
												})
											}
										</div>

										<div className="row px-10px">
											<div className="col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
												<div
													className="d-flex align-items-center cursor-pointer"
													onClick={() => {
														setFieldValue(
															`items`,
															[
																...values.items,
																{ ...DEFAULT_CUSTOM_ITEM, enabled: false, }
															]
														);
													}}
												>
													<Fab
														classes={{
															root: classes.addDocumentButton
														}}
														size="small"
														color="primary"
													>
														<PlusIcon />
													</Fab>
													<span className="ml-15 f-16px text-dark">
														<FormattedMessage id="TEMPLATE.ADD_DOCUMENT" />
													</span>
												</div>
											</div>
											<div className="col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
												<div>
													<button
														type="button"
														onClick={() => {
															submittedManually.current = true;
															handleSubmit();
														}}
														disabled={flags.loading[templateFlagNames.UPDATE]}
														className={clsx(
															"btn py-4 btn-primary btn-lg btn-block kt-login__btn-primary",
															{
																"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[templateFlagNames.UPDATE]
															}
														)}
													>
														<FormattedMessage id="TEMPLATES.EDIT" className="mr-2" />
													</button>
												</div>
											</div>
										</div>

										{
											previewInfo &&
											previewInfo.docIndex > -1 &&
											previewInfo.attachmentIndex > -1 && (
												<Preview
													documentId={values.items[previewInfo.docIndex]._id}
													file={values.items[previewInfo.docIndex].attachments[previewInfo.attachmentIndex]}
													handleClose={() => setPreviewInfo(null)}
												/>
											)
										}
									</fieldset>
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
			
			<Confirm
				open={showAttachmentSizeWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setAttachmentSizeWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>
		</div>
	);
}

export default Edit;