import { createStyles, withStyles, withTheme } from "@material-ui/core";
import {
  PauseCircleFilled,
  PlayCircleFilled,
  VolumeDown,
  VolumeOff,
  VolumeUp,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { Component, useCallback, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import IOSSlider from "../IOSSlider";


const styles = (theme) =>
  createStyles({
    waveformContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100px",
      width: "100%",
      background: "transparent",
    },
    waveContainer: {
      width: "100%",
      position: "relative",

      "& .kt-spinner": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -8px)",
      }
    },
    wave: {
      width: "100%",
      height: "200px",

      "& > wave": {
        border: "none",
        borderRadius: 4,
        boxShadow: "0 0 0 1px " + theme.palette.extraColors.grey,
        outline: "none",
      }
    },
    fabProgress: {
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
  });

function VolumeSlider({ onChange, width = 150 }) {
  const [valueBeforeMute, setValueBeforeMute] = useState(null);
  const [value, setValue] = useState(100);

  const setVolume = useCallback((event, newValue) => {
    // clear before mute value if user manually moves the slider
    if (newValue !== 0) {
      setValueBeforeMute(null);
    }

    setValue(newValue);
    onChange(newValue / 100);
  }, [onChange]);
  const handleMute = useCallback(() => {
    setValueBeforeMute(value);
    setVolume(null, 0);
  }, [setVolume, value]);
  const handleUnmute = useCallback(() => {
    setVolume(null, valueBeforeMute);
    setValueBeforeMute(null);
  }, [setVolume, valueBeforeMute]);

  return (
    <div className="d-flex" style={{ width }}>
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => valueBeforeMute ? handleUnmute() : handleMute()}
      >
        {
          value === 0 ? (
            <VolumeOff />
          ) : (
            <VolumeDown />
          )
        }
      </div>
      <div className="d-flex align-items-center flex-grow-1 px-3">
        <IOSSlider
          value={value} 
          onChange={setVolume}
        />
      </div>
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => setVolume(null, 100)}
      >
        <VolumeUp />
      </div>
    </div>
  );
}

class AudioPreview extends Component {
  state = {
    playing: false,
    loading: true,
  };

  audioRef = null;

  componentDidMount() {
    const track = document.querySelector("#track");

    this.waveform = WaveSurfer.create({
      barWidth: 3,
      hideCursor: true,
      container: "#waveform",
      mediaControls: true,
      backend: "WebAudio",
      height: 185,
      progressColor: this.props.theme.palette.primary.main,
      responsive: true,
      waveColor: this.props.theme.palette.extraColors.grey,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            "background-color": this.props.theme.palette.primary.main,
            color: "#fff",
            padding: "4px 8px",
            "margin-left": "5px",
            "border-radius": "3px",
            "font-size": "15px",
          },
          formatTimeCallback: (seconds) => {
            const min = Math.floor(seconds / 60).toString();
            const sec = Math.floor(seconds % 60).toString();

            return `${min.padStart(2, '0')}:${sec.padStart(2, '0')}`;
          }
        }),
      ],
    });

    this.waveform.load(track);
    this.waveform.on("finish", () => {
      this.setState({ playing: false });
    });
    this.waveform.on("ready", () => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.waveform.destroy();
  }

  handlePlay = () => {
    this.setState({ playing: !this.state.playing });
    this.waveform.playPause();
  };

  render() {
    const { classes, src } = this.props;
    const { playing, loading } = this.state;

    return (
      <div className={classes.waveformContainer}>
        <div className={classes.waveContainer}>
          {loading && (
            <div className="kt-spinner kt-spinner--lg kt-spinner--primary" />
          )}

          <div className={classes.wave} id="waveform" />
        </div>

        <div className="relative">
          <div className={clsx("d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center", { "opacity-0": loading })}>
            <div className="cursor-pointer mr-3" onClick={this.handlePlay}>
              {playing ? (
                <PauseCircleFilled style={{ fontSize: 50 }} />
              ) : (
                <PlayCircleFilled style={{ fontSize: 50 }} />
              )}
            </div>
            <div className="flex-grow-1">
              <VolumeSlider
                onChange={(volume) => this.waveform.setVolume(volume)}
              />
            </div>
          </div>
        </div>

        <audio id="track" src={src} controls onEnded={() => alert("a")} />
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(AudioPreview));
