import React, {
	useState,
	useCallback,
	useEffect,
} from "react";
import {
	Card,
	CardContent,
	ClickAwayListener,
	useTheme,
	// makeStyles
} from "@material-ui/core";
import {
	ReportProblemSharp
} from "@material-ui/icons";
import {
	useParams
} from "react-router-dom";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	useIntl,
} from "react-intl";
import {
	useSnackbar
} from "notistack";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import * as Sentry from "@sentry/react";

import LightTooltip from '../../../../common/LightTooltip';
import Constants from '../../../../common/constants';
import { CircleCheckIcon } from '../../../../common/icons';

import ItemCard from "../../ItemCard";
import ChatModal from "../../Chat";
import ShowUploads from "../../Upload";
import MarkCompleted from "../../MarkCompleted";
import Preview from "./Preview";

import {
	loadCheckData,
	getProviderData,
	uploadFile,
	updateDocument,
	flagNames as providerFlagNames,
	flagNames,
	setDocumentsWithError,
} from '../../../../store/modules/actions/provider.actions';

import MarkNotRequired from "../../MarkNotRequired";
import Confirm from "../../../../common/modals/confirm";
import { HourGlassIcon, CalendarIcon, ChatIcon, DangerBlocksIcon } from "../../../../common/icons";
import Confetti from "../../Confetti";
import { getProvider } from "../../../../store/modules/selectors/provider.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const OPERATION = {
	MARK_NOT_REQUIRED: 'MARK_NOT_REQUIRED',
};

function Provider(props) {
	const params = useParams();
	const dispatch = useDispatch();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const intl = useIntl();
	// somehow it was not working with useState

	const [state, setComponentState] = useState({
		showChatModal: false,
		showMarkCompletedModal: false,
		showMarkCompletedSuccessModal: false,
		incompleteWarnings: [],
		documentIdToShowUploadsOf: null,
		document: null,
		previewInfo: null,
		deleteFileInfo: null,
		tooltipStatus: false,
		zeroSizeFiles: [],
		uploadingZeroSizeFiles: false,
		tempDocId: null,
	});

	// const classes = useStyles();
	const setState = useCallback((obj, log) => {
		if (log) {
			console.log(obj, {
				...state,
				...obj,
			})
		}
		setComponentState({
			...state,
			...obj,
		});
	}, [
		state
	]);

	const provider = useSelector(getProvider);
	const flags = useSelector(getFlags);
	
	const getRequestCompletionErrors = useCallback(() => {
		const {
			data: {
				documentRequest,
				documents,
			}
		} = provider;

		if (documentRequest.status === Constants.ProviderStatus.COMPLETED) {
			return [
				intl.formatMessage({ id: "PROVIDER.MARK_COMPLETED.ALREADY_COMPLETED" }),
			];
		}

		const incompleteDocuments = documents
			.filter((doc) => {
				const completedStatuses = [
					Constants.DocumentStatus.APPROVED,
					Constants.DocumentStatus.NOT_REQUIRED,
					Constants.DocumentStatus.UPLOADED,
				];
				const documentTypes = [
					Constants.DocumentType.REGULAR,
					Constants.DocumentType.BANK_STATEMENTS,
				]

				return documentTypes.includes(doc.type) && !completedStatuses.includes(doc.status)
			});

		const documentsWithError = incompleteDocuments.reduce((p, { _id }) => ({
			...p,
			[_id]: true
		}), {});

		dispatch(setDocumentsWithError(documentsWithError));

		return incompleteDocuments.map((doc) => doc.name);
	}, [provider, intl, dispatch]);

	const uploadFiles = useCallback(async (documentId, files) => {
		let selectedDocument = null;

		for (let doc of provider.data.documents) {
			if (doc._id === documentId) {
				selectedDocument = doc;
				break;
			}
		}

		const [zeroSizeFiles, sizedFiles] = _.partition(files, (file) => file.size < 1024)

		if (selectedDocument) {
			const existingFiles = selectedDocument.files;

			if (existingFiles.length + sizedFiles.length > Constants.FILE_UPLOAD_LIMIT) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.DOCUMENT.UPLOAD.LIMIT_REACHED"
				}, {
					limit: Constants.FILE_UPLOAD_LIMIT,
					maxPossible: Constants.FILE_UPLOAD_LIMIT - existingFiles.length
				});

				enqueueSnackbar(errorMessage, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
			else {
				await dispatch(
					uploadFile(
						documentId,
						sizedFiles
					)
				);

				dispatch(
					setDocumentsWithError({
						...provider.documentsWithError,
						[documentId]: false,
					})
				);

				if (zeroSizeFiles.length) {
					Sentry.captureMessage(`user ${params.provider} uploaded ${zeroSizeFiles.length} files with size less than 1kb`);
				}

				setState({
					tempDocId: documentId,
					zeroSizeFiles,
				});
			}
		}
	}, [
		dispatch,
		enqueueSnackbar,
		intl,
		provider,
		params,
		setState,
	]);

	const handleDeleteFileSubmit = useCallback(async (isSuccess) => {
		if (isSuccess) {
			let selectedDocument = null;

			for (let doc of provider.data.documents) {
				if (doc._id === state.deleteFileInfo.doc._id) {
					selectedDocument = doc;
					break;
				}
			}

			const updatedFiles = selectedDocument.files.filter((file) => file._id !== state.deleteFileInfo.file._id);

			if (selectedDocument) {
				await dispatch(updateDocument(
					params.request,
					params.provider,
					state.deleteFileInfo.doc._id,
					{ files: updatedFiles }
				));
			}
		}

		setState({ deleteFileInfo: null });
	}, [
		dispatch,
		params,
		provider,
		setState,
		state
	])

	const handleMarkComplete = useCallback(() => {
		const errors = getRequestCompletionErrors();
		const canMarkComplete = errors.length === 0;

		setState({
			incompleteWarnings: errors,
			showMarkCompletedModal: canMarkComplete,
		});

		if (canMarkComplete && window._sva) {
			const {
				data: {
					_id,
					firstName,
					lastName,
					email,
					documentRequest: {
						user: {
							company
						}
					},
					requestId,
				}
			} = provider;

			const visitorDetails = {
				user_id: _id,
				first_name: firstName,
				last_name: lastName,
				email: email,
				organization: company,
				kaddim_request_id: requestId,
			};

			window._sva.setVisitorTraits(visitorDetails);
		}
	}, [
		getRequestCompletionErrors,
		setState,
		provider,
	]);

	const handleMarkCompleteSubmit = useCallback((wasSubmitted) => {
		const objectToUpdate = {
			showMarkCompletedModal: false,
		};

		if (wasSubmitted) {
			objectToUpdate.showMarkCompletedSuccessModal = true;
		}

		setState(objectToUpdate);
	}, [
		setState,
	]);

	const handleMarkCompleteSuccessSubmit = useCallback(() => {
		setState({ showMarkCompletedSuccessModal: false });

		if (window._sva) {
			window._sva.showSurvey(Constants.SURVICATE_SURVEY_ID);
		}
	}, [
		setState,
	]);

	useEffect(() => {
		dispatch(loadCheckData(params.request, params.provider));
	}, [params, dispatch]);

	useEffect(() => {
		if (provider.check && !provider.check.requirePassword) {
			dispatch(getProviderData(params.request, params.provider));
		}
	}, [provider.check, dispatch, params]);

	const {
		data: {
			dueDate,
			firstName,
			lastName,
			status: providerStatus,
			documentRequest,
		},
		documentsWithError,
	} = provider;
	const chatService = _.get(documentRequest, `user.chatService`, true);

	return (
		<div className="pb-25">
			<div className="provider-container col-12 col-lg-10 offset-lg-1">
				<div>
					<Card elevation={0} className="request-status-changer p-5 p-md-4 rounded-card my-40px">
						<CardContent className="p-0 d-flex justify-content-between align-items-center flex-column flex-md-row">
							<div className="mb-3 flex-1 provider-list mb-md-0 mr-0 f-15px mr-md-5">
								<FormattedHTMLMessage
									id={`PROVIDER.TITLE`}
									values={{
										requesterName: `${documentRequest.user.firstName} ${documentRequest.user.lastName}`,
										providerName: `${firstName} ${lastName}`
									}}
								/>
							</div>

							{ /* we have different layouts for below and above md media query due to complex layout configurations */}
							<div className="flex-3 d-flex flex-column flex-md-row w-100">
								{ /* layout for and above md */}
								<div className="mb-0 mr-3 flex-1 d-none d-md-flex align-items-center justify-content-center">
									<div
										className="d-flex justify-content-center align-items-center mr-15px"
										style={{
											height: 35,
											width: 35,
											borderRadius: 18,
											background: "rgba(255, 184, 0, 0.15)",
										}}
									>
										<HourGlassIcon />
									</div>
									<div className="f-15px">
										<FormattedMessage id={`PROVIDER.STATUS.${providerStatus}`} />
									</div>
								</div>

								<div className="mb-md-0 mr-md-3 flex-1 d-none d-md-flex align-items-center justify-content-center">
									<div
										className="d-flex justify-content-center align-items-center mr-15px"
										style={{
											height: 35,
											width: 35,
											borderRadius: 18,
											background: "rgba(143, 0, 255, 0.15)",
										}}
									>
										<CalendarIcon />
									</div>
									<div className="f-15px">
										<FormattedMessage
											id={`PROVIDER.DUE_DATE_RELATIVE`}
											values={{
												dateString: moment(dueDate).format("DD MMM YYYY"),
												relatedDateString: moment.duration(moment(dueDate).diff(moment())).humanize(true)
											}}
										/>
									</div>
								</div>

								<div className="mb-0 mb-md-0 flex-1 d-none d-md-flex flex-column col-23 pr-0">
									<button
										className={`flex-grow-1 d-flex justify-content-center align-items-center py-4 btn btn-success f-15 ${clsx({
											"btn-disabled btn-secondary": [Constants.ProviderStatus.IN_REVIEW, Constants.ProviderStatus.COMPLETED].includes(providerStatus)
										})}`}
										onClick={handleMarkComplete}
									>
										<FormattedMessage id={`PROVIDER.MARK_COMPLETED.TITLE`} />
									</button>
								</div>

								{ /* layout for below md */}
								<div className="flex-1 d-flex d-md-none flex-column align-items-center justify-content-center">
									<div className="mb-3 d-flex align-items-center" style={{ width: 185 }}>
										<div
											className="d-flex justify-content-center align-items-center mr-15px"
											style={{
												height: 35,
												width: 35,
												borderRadius: 18,
												background: "rgba(255, 184, 0, 0.15)",
											}}
										>
											<HourGlassIcon />
										</div>
										<div className="f-15px">
											<FormattedMessage id={`PROVIDER.STATUS.${providerStatus}`} />
										</div>
									</div>
									<div className="mb-3 d-flex align-items-center" style={{ width: 185 }}>
										<div
											className="d-flex justify-content-center align-items-center mr-15px"
											style={{
												height: 35,
												width: 35,
												borderRadius: 18,
												background: "rgba(143, 0, 255, 0.15)",
											}}
										>
											<CalendarIcon />
										</div>
										<div className="f-15px">
											<FormattedMessage id={`PROVIDER.DUE_DATE`} values={{ dateString: moment(dueDate).format("DD MMM YYYY") }} />
										</div>
									</div>
									<div className="d-flex w-75">
										<button
											className={`flex-grow-1 d-flex justify-content-center align-items-center py-4 btn btn-success f-15 ${clsx({
												"btn-disabled btn-secondary": [Constants.ProviderStatus.IN_REVIEW, Constants.ProviderStatus.COMPLETED].includes(providerStatus)
											})}`}
											onClick={handleMarkComplete}
										>
											<FormattedMessage id={`PROVIDER.MARK_COMPLETED.TITLE`} />
										</button>
									</div>
								</div>
							</div>
						</CardContent>
					</Card>
				</div>

				<div className="mt-25">
					<Card elevation={0} className="rounded-card bg-white pt-25 pr-25 pl-25 mb-50">
						<CardContent className="p-0">
							<div className="d-flex justify-content-between align-items-center text-dark f-15px flex-column flex-md-row">
								<div className="d-flex justify-content-start w-100 order-2 order-md-1">
									<div
										className="d-flex"
										style={{
											fontWeight: 500,
											letterSpacing: "0.04em",
											textTransform: "uppercase",
										}}
									>
										<div>
											<FormattedMessage id="PROVIDER.DOCUMENTS_REQUESTED" />
										</div>
										<ClickAwayListener onClickAway={() => setState({ tooltipStatus: false })}>
											<FormattedMessage
												id="PROVIDER.DOCUMENTS_REQUESTED_TOOLTIP"
												values={documentRequest.user}
											>
												{
													(text) => (
														<LightTooltip
															open={state.tooltipStatus}
															className="ml-2"
															title={text}
															placement="right"
														>
															<span
																className="cursor-pointer"
																onClick={() => setState({ tooltipStatus: true })}
															>
																<i className="fas fa-info-circle" />
															</span>
														</LightTooltip>
													)
												}
											</FormattedMessage>
										</ClickAwayListener>
									</div>
								</div>
								<div className={clsx("justify-content-between justify-content-md-end align-items-center w-100 order-1 order-md-2 mb-5 mb-md-0", { "d-flex": chatService, "d-none": !chatService })}>
									<div>
										<FormattedMessage
											id="PROVIDER.ASK_QUESTION"
											values={{
												name: `${documentRequest.user.firstName.toUpperCase()} ${documentRequest.user.lastName.toUpperCase()}`,
											}}
										/>
									</div>
									<div className="ml-15">
										<div className="d-flex align-items-center">
											<button
												className="btn btn-primary btn-sm d-flex align-items-center f-15px"
												onClick={() => setState({ showChatModal: true })}
											>
												<div className="mr-10px d-flex align-items-center justify-content-center">
													<ChatIcon />
												</div>
												<FormattedMessage id="REQUEST.PROVIDER.CHAT.TITLE" />
											</button>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-30 row documents-container">
								{
									provider.data.documents.map((documentItem, index, arr) => {
										let className = "p-15 mb-25";

										if (documentsWithError[documentItem._id]) {
											className += " border-3 border-danger";
										}

										return (
											<div className="col-12" key={index}>
												<ItemCard
													className={className}
													itemId={documentItem._id}
													isUploading={flags.loading[providerFlagNames.UPLOAD] === documentItem._id}
													uploadFiles={(files) => uploadFiles(documentItem._id, files)}
													onMarkNotRequired={() => setState({ document: documentItem, operation: OPERATION.MARK_NOT_REQUIRED })}
													showUploads={() => setState({ documentIdToShowUploadsOf: documentItem._id })}
													selectFileForPreview={(fileIndex) => setState({ previewInfo: { docId: documentItem._id, fileIndex } })}
													deleteFile={(file) => setState({ deleteFileInfo: { doc: documentItem, file: file } })}
													selectAttachmentForPreview={(attachmentIndex) => setState({ previewInfo: { docId: documentItem._id, attachmentIndex } })}
												/>
											</div>
										);
									})
								}
							</div>
						</CardContent>
					</Card>
				</div>
			</div>

			{
				state.showChatModal && chatService && (
					<ChatModal
						handleClose={() => setState({ showChatModal: false })}
					/>
				)
			}

			{
				!!state.document && state.operation === OPERATION.MARK_NOT_REQUIRED && (
					<MarkNotRequired
						id={state.document._id}
						name={state.document.name}
						handleClose={() => setState({ document: null, operation: null })}
						handleMarkNotRequired={async (reason) => {
							await dispatch(
								updateDocument(
									params.request,
									params.provider,
									state.document._id, {
										markNotRequiredReason: reason,
										status: Constants.DocumentStatus.NOT_REQUIRED,
									}
								)
							);
						}}
					/>
				)
			}

			{
				!!state.documentIdToShowUploadsOf && (
					<ShowUploads
						open
						itemId={state.documentIdToShowUploadsOf}
						isUploading={flags.loading[providerFlagNames.UPLOAD] === state.documentIdToShowUploadsOf}
						uploadFiles={(files) => uploadFiles(state.documentIdToShowUploadsOf._id, files)}
						handleClose={() => setState({ documentIdToShowUploadsOf: null })}
						selectForPreview={(fileIndex) => setState({ previewInfo: { docId: state.documentIdToShowUploadsOf, fileIndex } })}
					/>
				)
			}

			{
				state.previewInfo &&
				state.previewInfo.docId &&
				(
					state.previewInfo.fileIndex > -1 ||
					state.previewInfo.attachmentIndex > -1
				) && (
					<Preview
						docId={state.previewInfo.docId}
						fileIndex={state.previewInfo.fileIndex}
						attachmentIndex={state.previewInfo.attachmentIndex}
						handleClose={() => setState({ previewInfo: null })}
					/>
				)
			}

			{
				state.showMarkCompletedModal && (
					<MarkCompleted
						handleClose={handleMarkCompleteSubmit}
					/>
				)
			}

			<Confirm
				open={state.showMarkCompletedSuccessModal}
				icon={<CircleCheckIcon fill={theme.palette.success.main} />}
				variant="success"
				handleClose={handleMarkCompleteSuccessSubmit}
				title="PROVIDER.MARK_COMPLETED.SUCCESS"
				submitButtonText="GENERAL.OK"
			/>

			{
				state.incompleteWarnings && state.incompleteWarnings.length && (
					<Confirm
						open
						icon={<ReportProblemSharp color="error" style={{ height: 80, width: 100 }} />}
						variant="danger"
						handleClose={() => setState({ incompleteWarnings: [] })}
						title="PROVIDER.MARK_COMPLETED.INCOMPLETE_REQUEST"
						message={
							<ol>
								{
									state.incompleteWarnings.map((error, index) => (
										<li
											key={index}
											className="text-left text-uppercase"
										>
											{error}
										</li>
									))
								}
							</ol>
						}
						submitButtonText="GENERAL.DISMISS"
					/>
				)
			}

			{
				state.deleteFileInfo &&
				state.deleteFileInfo.doc &&
				state.deleteFileInfo.file && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={handleDeleteFileSubmit}
						title="PROVIDER.DOCUMENT.FILE.DELETE.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.DOCUMENT.FILE.DELETE.DESCRIPTION"
							}, {
								documentName: state.deleteFileInfo.doc.name,
								fileName: state.deleteFileInfo.file.name
							})
						}
						loading={flags.loading[flagNames.UPDATE]}
						error={flags.error[flagNames.UPDATE]}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			{
				state.zeroSizeFiles &&
				state.zeroSizeFiles.length && (
					<Confirm
						open
						icon={<ReportProblemSharp color="error" style={{ height: 80, width: 100 }} />}
						variant="danger"
						handleClose={() => setState({ zeroSizeFiles: [], tempDocId: null })}
						title={intl.formatMessage({ id: 'PROVIDER.FILES.ZERO_SIZE_ERROR.TITLE' })}
						message={
							<div>
								<div>
									<FormattedMessage id="PROVIDER.FILES.ZERO_SIZE_ERROR.MESSAGE" />
								</div>

								<div className="mt-4">
									<ol>
										{
											state.zeroSizeFiles.map((file, index) => {
												return (
													<li
														key={index}
														className="text-left"
													>
														{file.name}
													</li>
												)
											})
										}
									</ol>
								</div>
							</div>
						}
						submitButtonText="GENERAL.DISMISS"
					/>
				)
			}

			{
				state.showMarkCompletedSuccessModal && (
					<div>
						<Confetti />
					</div>
				)
			}

			<div className="footer border-top text-center">
				<FormattedHTMLMessage id="APP.PROVIDER.FOOTER" />
			</div>
		</div>
	);
}

export default Provider;