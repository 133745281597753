import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import Input from "../../../Input";
import useDebounce from "../../../useDebounce";

const useStyles = makeStyles((theme) => {
	return {
		answerField: {
			"& textarea": {
				resize: "vertical",
				lineHeight: 1.4,
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.inputFields.readableMaxWidth,
			}
		}
	};
});

const LongText = forwardRef((props, _ref) => {
	const {
		data,
		response,
		onChange,
	} = props;
	const intl = useIntl();
	const classes = useStyles();
	const [value, setValue] = useState(response && response.value);
	const debouncedValue = useDebounce(value, 750);

	useEffect(() => {
		const newResponse = {
			...response,
			value: debouncedValue,
		};

		onChange(newResponse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedValue,
	]);

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="row">
				<div className="col-12">
					<Input
						containerClass="mb-2"
						type="text"
						variant="outlined"
						fullWidth
						defaultValue={value}
						name="value"
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.LONG_TEXT.INPUT.PLACEHOLDER"
							})
						}
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						onChange={(e) => setValue(e.target.value)}
						classes={{
							root: clsx(classes.answerField, "MuiTextField-bg-white condensed-textarea")
						}}
						multiline
						minRows="4"
					/>
				</div>
			</div>
		</div>
	)
})

export default LongText;
