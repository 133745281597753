import axios from "axios";

export const SUBSCRIPTIONS_BASE_URL = "api/v1/subscriptions";
export const USER_SUBSCRIPTIONS_BASE_URL = "api/v1/user-subscriptions";
export const FEATURES_BASE_URL = "api/v1/features";
export const ADDONS_BASE_URL = "api/v1/addons";
export const USER_ADDONS_BASE_URL = "api/v1/user-addons/purchase-addons";

export const getFeatures = () => {
	return axios.get(FEATURES_BASE_URL);
}

export const getPlans = () => {
	return axios.get(SUBSCRIPTIONS_BASE_URL);
}

export const getAddons = () => {
	return axios.get(ADDONS_BASE_URL);
}

export const purchasePlan = (subscriptionId) => {
	return axios.post(`${USER_SUBSCRIPTIONS_BASE_URL}/select-subscription`, {
		subscriptionId
	})
}

export const purchaseAddons = (data) => {
	return axios.post(USER_ADDONS_BASE_URL, { data });
}

export function cancelSubscription() {
	return axios.post(`${USER_SUBSCRIPTIONS_BASE_URL}/cancel-subscription`);
}
