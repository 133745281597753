import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import clsx from 'clsx';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";

import {
  Alert,
} from "reactstrap";
import Input from "../../common/Input";
import { requestPassword } from "../../crud/auth.crud";

class ForgotPassword extends Component {
  state = {
    isRequested: false,
    lastValidEmail: "",
  };

  render() {
    const { intl } = this.props;
    const { isRequested, lastValidEmail } = this.state;

    return (
      <React.Fragment>
        <div className="text-center mb-50">
          <h3>
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
        </div>

        <div className='mt-2 text-center'>
          <FormattedHTMLMessage id="AUTH.FORGOT.DESC" />
        </div>

        <Formik
          initialValues={{ email: "" }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            return errors;
          }}
          onSubmit={async (values, { setStatus, setSubmitting }) => {
            this.setState({
              isRequested: false,
              lastValidEmail: '',
            });

            try {
              await requestPassword(values.email)
              this.setState({
                isRequested: true,
                lastValidEmail: values.email,
              });
            }
            catch(e) {
              setSubmitting(false);
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.NOT_FOUND" },
                  { name: values.email }
                )
              );
            }
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <Input
                containerClass="my-25"
                labelId="AUTH.INPUT.EMAIL"
                variant="outlined"
                type="email"
                fullWidth
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />

              {
                isRequested && (
                  <div className="text-center mb-10">
                    <Alert color="success" className="mb-0">
                      <FormattedMessage
                        id="AUTH.FORGOT.SUCCESS"
                        values={{ email: lastValidEmail }}
                      />
                    </Alert>
                  </div>
                )
              }

              <div className="row pt-20">
                <div className='col-12 text-center'>
                  <button
                    type="submit"
                      className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
                        {
                          "kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light": isSubmitting
                        }
                      )}`}
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="AUTH.FORGOT.BUTTON" />
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

export default injectIntl(connect(null, null)(ForgotPassword));
