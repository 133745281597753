import React, { useCallback, useMemo } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

import { ProviderSettings } from "../common/ProviderSettings";
import { isValidEmail } from "../../../../../_metronic";
import { flagNames } from "../../../../store/modules/actions/requests.actions";
import { Alert } from "reactstrap";
import moment from "moment";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

export default function AddProviderModal ({
	handleSubmit,
	handleClose,
}) {
	const intl = useIntl();

	const flags = useSelector(getFlags);
	const loading = useMemo(() => flags.loading[flagNames.PROVIDER_ADD], [flags.loading]);
	const error = useMemo(() => flags.error[flagNames.PROVIDER_ADD], [flags.error]);
	
	const validate = useCallback((provider) => {
		const providerError = {};

		if (!provider.firstName) {
			providerError.firstName = intl.formatMessage({
				id: "PROVIDER.FIRST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.lastName) {
			providerError.lastName = intl.formatMessage({
				id: "PROVIDER.LAST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.email) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.REQUIRED"
			});
		} else if (!isValidEmail(provider.email)) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.INVALID"
			});
		}

		return providerError;
	}, [
		intl,
	])

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			classes={{
				paper: "rounded-card"
			}}
			maxWidth="sm"
		>
			<DialogTitle className="d-none">
				{''}
			</DialogTitle>

			<div className="container px-20px pb-20px pt-10px">
				<Formik
					initialValues={{
						log: true,
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						elements: [],
						smsNotification: false,
						reminderNotification: true,
						requiredPassword: false,
						dueDate: moment().startOf('day').add(4, 'days').toDate(),
						emailMeta: {
							subject: "",
							message: ""
						}
					}}
					validate={(provider) => validate(provider)}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={(provider) => handleSubmit(provider)}
				>
					{({ handleSubmit }) => {
						return (
							<div>
								<ProviderSettings
									isRequestDisabled={false}
								/>

								{
									error && (
										<div className="mb-10px d-flex justify-content-center">
											<Alert color="danger">
												{error}
											</Alert>
										</div>
									)
								}

								<div className="d-flex justify-content-end">
									<div>
										<button
											type="submit"
											onClick={handleSubmit}
											className={
												clsx(
													"btn btn-block btn-primary",
													{
														"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--right kt-spinner--primary": loading,
													}
												)
											}
										>
											<FormattedMessage id="REQUEST.ADD_PROVIDER" />
										</button>
									</div>
								</div>
							</div>
						)
					}}
				</Formik>
			</div>
		</Dialog>
	)
}