import React, { useCallback, useEffect, useState } from "react";
import { Fab, Tooltip, useTheme } from "@material-ui/core";
import { DoneAll, NoSim } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { Archive, Unarchive } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";

import Constants from "../../../../common/constants";
import { flagNames, updateRequestTitle } from "../../../../store/modules/actions/requests.actions";
import useDebounce from "../../../../common/useDebounce";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import AutoExpandInput from "../../../../common/auto-expand-input";
import { SettingsIcon } from "../../../../common/icons";
import LogsHistoryToggler from "./LogsHistoryToggler";
import { getFlags } from "../../../../store/modules/selectors/common.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";

function TitleCard({
	onArchive,
	onCancel,
	onComplete,
	onInProgress
}) {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const theme = useTheme();
	// eslint-disable-next-line no-unused-vars
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const flags = useSelector(getFlags);
	const request = useSelector(getSelectedRequest);
	
	const canCancel = request.status !== Constants.RequestStatus.CANCELLED;
	const canComplete = request.status !== Constants.RequestStatus.COMPLETED;
	const canArchive = !canCancel || !canComplete;
	const canUnarchive = request.archived;
	const [title, setTitle] = useState(request.title);
	const debouncedTitle = useDebounce(title, 500);

	useEffect(() => {
		if (debouncedTitle !== request.title) {
			if (!debouncedTitle && !request.title) {
				return;
			}

			dispatch(updateRequestTitle(request._id, debouncedTitle))
		}
	}, [
		dispatch,
		request._id,
		debouncedTitle,
		request,
	]);

	useEffect(() => {
		if (flags.error[flagNames.UPDATE_TITLE]) {
			enqueueSnackbar(flags.error[flagNames.UPDATE_TITLE], { variant: "error" });
		}
	}, [
		flags.error,
		enqueueSnackbar,
	]);

	const onArchiveRequest = useCallback((event) => {
		event.stopPropagation();
		event.preventDefault();

		onArchive();
	}, [
		onArchive,
	]);

	return (
		<fieldset
			className="d-flex justify-content-between align-items-center"
			disabled={isInReadOnlyMode}
		>
			<div className="d-flex align-items-center">
				<div className="mr-20px">
					<AutoExpandInput
						className="f-24px font-weight-medium text-dark mr-2"
						name="title"
						onChange={(e) => setTitle(e.target.value)}
						placeholder={
							intl.formatMessage({
								id: 'REQUEST.TITLE.PLACEHOLDER'
							})
						}
						value={title}
						disabled={request.archived}
					/>
				</div>

				<div className="d-flex">
					{
						request.status !== Constants.RequestStatus.IN_PROGRESS && (
							<div className="mr-10px">
								<Tooltip title={intl.formatMessage({ id: "REQUEST.IN_PROGRESS.TOOLTIP" })}>
									<Fab
										size="small"
										className="bg-light"
										onClick={onInProgress}
									>
										<SettingsIcon fill={theme.palette.success.main} size={20} />
									</Fab>
								</Tooltip>
							</div>
						)
					}
					{
						canCancel && canComplete && (
							<div className="mr-10px">
								<Tooltip title={intl.formatMessage({ id: "REQUEST.COMPLETE.TOOLTIP" })}>
									<Fab
										size="small"
										className="bg-light"
										onClick={onComplete}
									>
										<DoneAll className="text-success" />
									</Fab>
								</Tooltip>
							</div>
						)
					}
					{
						canCancel && (
							<div className="mr-10px">
								<Tooltip title={intl.formatMessage({ id: "REQUEST.CANCEL.TOOLTIP" })}>
									<Fab
										size="small"
										className="bg-light"
										onClick={onCancel}
									>
										<NoSim className="text-danger" />
									</Fab>
								</Tooltip>
							</div>
						)
					}
				</div>
			</div>

			{
				(canArchive || canUnarchive) && (
					<button
						className="btn bg-light-blue text-dark f-16px d-flex align-items-center"
						onClick={onArchiveRequest}
					>
						<div className="mr-2">
							{
								canUnarchive ? (
									<Unarchive fontSize="large" />
								) : (
									<Archive fontSize="large" />
								)
							}
						</div>
						<FormattedMessage id={canUnarchive ? "REQUEST.UNARCHIVE" : "REQUEST.ARCHIVE"} />
					</button>
				)
			}

			{
				process.env.REACT_APP_ENABLED_ACTIVITY_HISTORY === "true" && (
					<LogsHistoryToggler toggleBy="kt_logs_history_toggler_btn" />
				)
			}
		</fieldset>
	)
}

export default TitleCard;