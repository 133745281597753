import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import { getEmptyErrorObj, validateNote } from '../../ElementPicker';
import ErrorMessage from '../ErrorMessage';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '../../ElementType';
import AutoExpandInput from '../../../auto-expand-input';

const Note = forwardRef((props, _ref) => {
	const { data, onChange } = props;
	const intl = useIntl();

	const [content, setContent] = useState(data.content);
	const debouncedContent = useDebounce(content, 750);

	const [error, setError] = useState({});

	useEffect(() => {
		setContent(data.content);
	}, [data.content]);
	
	useEffect(() => {
		const newData = {
			...data,
			content: debouncedContent,
		};
		const error = validateNote(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.NOTES)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedContent,
	])

	return (
		<div>
			<AutoExpandInput
				className="f-14px text-dark font-weight-light mb-10px w-100 font-weight-light break-word"
				onChange={(e) => setContent(e.target.value)}
				value={content}
				placeholder={
					intl.formatMessage({
						id: "ELEMENT_LIST.ITEM.NOTES.CONTENT.PLACEHOLDER"
					})
				}
				allowHTML
			/>

			{
				error.content && (
					<ErrorMessage message={error.content} />
				)
			}
		</div>
	)
});

export default Note;
