import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import _ from "lodash";
import {
	FormattedMessage, useIntl,
} from "react-intl";
import {
	Divider,
	InputAdornment,
	InputBase,
	makeStyles,
	Paper,
	Typography,
	ButtonBase,
	Icon,
} from '@material-ui/core';
import {
	CheckBox,
	CheckBoxOutlineBlankOutlined,
	IndeterminateCheckBoxOutlined,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from "@material-ui/icons";
import {
	Accordion,
} from "react-bootstrap";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { uuid } from "uuidv4";
import { addAlpha } from "../../../_metronic";
import { getTemplatesList } from "../../store/modules/selectors/template.selector";

const useStyles = makeStyles((theme) => {
	const headerVerticalPadding = 15;

	return {
		container: {
			background: "#FFFFFF",
			border: "1px solid rgba(49, 94, 255, 0.4)",
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
			borderRadius: "4px",
		},
		searchBoxContainer: {
			padding: `${headerVerticalPadding}px 20px`
		},
		sortButtonContainer: {
			position: "absolute",
			top: headerVerticalPadding,
			bottom: headerVerticalPadding,
		},
		sortButton: {
			border: `1px solid ${addAlpha("#cac6c6", 0.42)}`,
			borderRadius: 4,

			"& .MuiIcon-root": {
				position: "relative",
				width: 18,

				"&.ascending": {
					transform: 'rotate(0deg)',
  				transition: '0.25s ease',
					top: 1,
				},
				"&.descending": {
					transform: 'rotate(-180deg)',
  				transition: '0.25s ease',
					position: "relative",
					bottom: 1,
					left: 1,
				}
			},
			"& .text": {
				writingMode: "vertical-lr",
				textOrientation: "upright",
				position: "relative",
				left: -5,
			}
		},
		searchBoxInput: {
			padding: "5px 15px",
			opacity: 0.42,
			border: "1px solid #cac6c6",
			borderRadius: 4,

			"&::placeholder": {
				color: "black"
			}
		},
		expandedTemplate: {
			background: theme.palette.extraColors.blueSecondary,
		},
		reset: {
			textDecoration: "underline"
		},
		footer: {
			filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15))"
		},
		selectedPillTextContainer: {
			background: "#EBEFFF",
			borderRadius: 12,
			padding: "2px 8px",
		}
	}
});

function DocumentSelector(props) {
	const {
		onSubmit,
		className,
		showModern,
	} = props;
	const classes = useStyles();
	const intl = useIntl();

	const field = useRef(showModern ? 'elements' : 'items');
	const [isInAscendingOrder, setIsInAscendingOrder] = useState(true);
	const passedClasses = props.classes || {};

	const templates = useSelector(getTemplatesList)
	const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
	const [selectedDocuments, setSelectedDocuments] = useState({});
	const [expandedTemplates, setExpandedTemplates] = useState({});
	const [searchText, setSearchText] = useState("");

	const filteredTemplates = useMemo(() => {
		const regex = new RegExp(searchText, "i");

		let result = _.cloneDeep(templates)
			.filter(({ format }) => {
				return showModern ? format === "MODERN" : format !== "MODERN";
			})
			.map((template) => {
				template[field.current] = template[field.current].filter(({ name }) => regex.test(name));
				return template;
			})
			.filter((template) => {
				return template[field.current].length > 0;
			});

		const sortByName = (template) => {
			return template.name.toUpperCase();
		};
		result = _.orderBy(result, [sortByName], [isInAscendingOrder ? "asc" : "desc"]);

		return result;
	}, [searchText, templates, isInAscendingOrder, showModern]);
	
	const selectedDocumentCount = useMemo(() => {
		const count = _.reduce(selectedDocuments, (p, value) => {
			return p + (value ? 1 : 0);
		}, 0);

		return count;
	}, [selectedDocuments]);

	const unselectAllDocuments = useCallback(() => {
		const newSelection = templates.reduce((p, template) => {
			const templateDocumentObj = template[field.current].reduce((q, item) => {
				q[item._id] = false;
				return q;
			}, {});

			return {
				...p,
				...templateDocumentObj,
			};
		}, {});

		setSelectedDocuments(newSelection);
	}, [
		templates,
		setSelectedDocuments
	]);

	useEffect(() => {
		unselectAllDocuments();

		const totalCount = templates.reduce((p, template) => {
			return p + (template[field.current] ? template[field.current].length : 0);
		}, 0);

		setTotalDocumentsCount(totalCount);
	}, [
		templates,
		unselectAllDocuments,
	]);

	const addSelectedDocuments = useCallback((ids) => {
		const newSelection = {
			...selectedDocuments
		};

		ids.forEach((id) => newSelection[id] = true);

		setSelectedDocuments(newSelection);
	}, [
		selectedDocuments,
		setSelectedDocuments
	]);

	const removeSelectedDocuments = useCallback((ids) => {
		const newSelection = {
			...selectedDocuments
		};

		ids.forEach((id) => delete newSelection[id]);
		
		setSelectedDocuments(newSelection);
	}, [
		selectedDocuments,
		setSelectedDocuments
	]);

	const toggleTemplate = useCallback((templateId) => {
		const newObj = { ...expandedTemplates };

		if (newObj[templateId]) {
			delete newObj[templateId];
		}
		else {
			newObj[templateId] = true;
		}

		setExpandedTemplates(newObj);
	}, [
		expandedTemplates,
		setExpandedTemplates
	]);

	const handleSubmit = useCallback(() => {
		const documents = [];

		templates.forEach((template) => {
			template[field.current].forEach((doc) => {
				if (selectedDocuments[doc._id]) {
					let selectedItem = {
						type: doc.type,
						enabled: true,
						uuid: uuid(),
						attachments: doc.attachments,
						templateId: template._id,
						fileRename: doc.fileRename,
					};

					if (showModern) {
						selectedItem = {
							...selectedItem,
							dataObj: doc.dataObj,
							responseObj: doc.responseObj,
						}
					}
					else {
						selectedItem = {
							...selectedItem,
							name: doc.name,
							description: doc.description,
						};
					}

					documents.push(selectedItem);
				}
			});
		});

		// pick bcc and emailMeta from template which have highest number of elements out of selected elements
		const templateCountObj = documents.reduce((acc, { templateId }) => {
			if (!acc[templateId]) {
				acc[templateId] = 0;
			}

			acc[templateId]++;

			return acc;
		}, {});
		const templateIds = _.keys(templateCountObj);

		// list of templateIds in order from most to least used out of all selected elements
		const orderedTemplates = _.sortBy(templateIds, (templateId) => {
		    return templateCountObj[templateId];
		});
		orderedTemplates.reverse();

		// loop though ordered templateId by selected and iterate until we get valid bcc and emailMeta
		let bcc = [];
		let emailMeta = {
			subject: '',
			content: '',
		};
		const selectedTemplate = {
			emailMeta: null,
			bcc: null,
		};

		// get bcc from most to least used templates
		for (const templateId of orderedTemplates) {
			const template = templates.find((template) => template._id === templateId);

			if (template.bcc && template.bcc.length > 0) {
				bcc = template.bcc.slice();
				selectedTemplate.bcc = templateId;
				break;
			}
		}
		
		// get emailMeta from most to least used templates
		for (const templateId of orderedTemplates) {
			const template = templates.find((template) => template._id === templateId);

			if (template.emailMeta && (template.emailMeta.subject || template.emailMeta.content)) {
				emailMeta = template.emailMeta;
				selectedTemplate.emailMeta = templateId;
				break;
			}
		}


		// submit computed and selected data
		onSubmit({
			[field.current]: documents,

			selectedTemplateId: selectedTemplate.emailMeta,
			emailMeta: {
				subject: emailMeta.subject,
				content: emailMeta.content,
			},

			bcc: bcc.slice(),
		});
	}, [
		selectedDocuments,
		showModern,
		templates,
		onSubmit,
	]);

	return (
		<div className={clsx(classes.container, "d-flex flex-column", className)}>
			<div className="flex-grow-1">
				<div className={clsx(classes.searchBoxContainer, "d-flex align-items-center position-relative")}>
					<div className={classes.sortButtonContainer}>
						<ButtonBase
							className={clsx(classes.sortButton, "d-flex small pl-1 py-1 h-100")}
							onClick={() => setIsInAscendingOrder(!isInAscendingOrder)}
						>
							<div className="d-flex align-items-center">
								<Icon
									className={
										clsx(
											{
												"ascending": isInAscendingOrder,
												"descending": !isInAscendingOrder,
											}
										)
									}
								>
									south
								</Icon>
							</div>
							<div className="text small d-flex flex-column align-items-center">
								AZ
							</div>
						</ButtonBase>
					</div>
					<div className="flex-grow-1 ml-50px">
						<InputBase
							className={classes.searchBoxInput}
							startAdornment={
								<InputAdornment position="start">
									<span className="fa fa-search" />
								</InputAdornment>
							}
							onChange={(e) => setSearchText(e.target.value)}
							placeholder={intl.formatMessage({ id: "DOCUMENT_SELECTOR.SEARCH_PLACEHOLDER" }, { totalCount: totalDocumentsCount })}
							size="small"
							fullWidth
						/>
					</div>
				</div>

				<div className={passedClasses.templatesListContainer}>
					{
						filteredTemplates.map((template, index, arr) => {
							const itemIds = template[field.current].map(({ _id }) => _id);
							const selectedDocumentCountInTemplate = itemIds.reduce((p, itemId) => p + (selectedDocuments[itemId] ? 1 : 0), 0)
							const areAllSelected = itemIds.length === selectedDocumentCountInTemplate;

							return (
								<div
									key={index}
									className={clsx("px-20px", { "pb-20px": index === arr.length - 1 })}
								>
									<Accordion
										activeKey={expandedTemplates[template._id] ? template._id : null}
										className={
											clsx(
												"d-flex flex-column",
												{ [classes.expandedTemplate]: expandedTemplates[template._id] }
											)
										}
									>
										<Accordion.Toggle
											className={clsx("px-15px py-10px f-15px d-flex flex-grow-1 border-0 bg-transparent", passedClasses.templateListItem || "align-items-center")}
											eventKey={template._id}
											onClick={() => toggleTemplate(template._id)}
										>
											<div className={clsx("flex-grow-1 d-flex text-left ml-5px", passedClasses.templateListItemTitle)}>
												<div onClick={(e) => e.stopPropagation()}>
													{
														selectedDocumentCountInTemplate === 0 ? (
															<CheckBoxOutlineBlankOutlined
																color="primary"
																onClick={() => addSelectedDocuments(itemIds)}
															/>
														) : (
															areAllSelected ? (
																<CheckBox
																	color="primary"
																	onClick={() => removeSelectedDocuments(itemIds)}
																/>
															) : (
																<IndeterminateCheckBoxOutlined
																	color="primary"
																	onClick={() => addSelectedDocuments(itemIds)}
																/>
															)
														)
													}
												</div>
												<div className="flex-grow-1 ml-2">
													{template.name}
												</div>
												{
													selectedDocumentCountInTemplate > 0 && (
														<div className={clsx("d-flex", passedClasses.templateListItemSelectedPill)}>
															<div className={clsx(classes.selectedPillTextContainer, "selected-count-pill text-center text-primary f-12px mr-2")}>
																<FormattedMessage
																	id="DOCUMENT_SELECTOR.SELECTED_PILL_TEXT"
																	values={{ selectedDocumentCount: selectedDocumentCountInTemplate }}
																/>
															</div>
														</div>
													)
												}
											</div>
											<div className="d-flex align-items-center">
												{
													expandedTemplates[template._id] ? (
														<KeyboardArrowUp />
													) : (
														<KeyboardArrowDown />
													)
												}
											</div>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey={template._id}>
											<div className="px-25px">
												{
													template[field.current].map((item) => {
														const isChecked = selectedDocuments[item._id];
														return (
															<div
																key={item._id}
																className="cursor-pointer d-flex mb-10px"
																onClick={() => isChecked ? removeSelectedDocuments([item._id]) : addSelectedDocuments([item._id])}
															>
																{
																	isChecked ? (
																		<CheckBox color="primary" />
																	) : (
																		<CheckBoxOutlineBlankOutlined color="primary" />
																	)
																}

																<Typography className="ml-2">
																	{showModern ? item.dataObj.title : item.name}
																</Typography>
															</div>
														)
													})
												}
											</div>
										</Accordion.Collapse>
									</Accordion>

									{
										index !== arr.length - 1 && (
											<Divider />
										)
									}
								</div>
							)
						})
					}
				</div>
			</div>

			<div>
				<Paper
					elevation={0}
					className={
						clsx(
							classes.footer,
							"align-items-center px-20px py-15px",
							{
								"d-flex": selectedDocumentCount > 0,
								"d-none": selectedDocumentCount === 0,
							}
						)
					}
				>
					<div>
						<button
							className="f-14px btn btn-primary mr-3 font-weight-bold"
							onClick={handleSubmit}
						>
							<FormattedMessage
								id="DOCUMENT_SELECTOR.SUBMIT"
								values={{ selectedDocumentCount }}
							/>
						</button>
					</div>
					<div>
						<span
							className={clsx("f-14px text-primary cursor-pointer font-weight-bold", classes.reset)}
							onClick={unselectAllDocuments}
						>
							<FormattedMessage id="DOCUMENT_SELECTOR.RESET" />
						</span>
					</div>
				</Paper>
			</div>
		</div>
	);
}

export default DocumentSelector;
