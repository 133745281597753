import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";

class RegistrationSuccess extends Component {
	render() {
		const { history } = this.props;
		
		return (
			<React.Fragment>
				<div className="text-center mb-50">
					<h3>
						<FormattedMessage id="AUTH.REGISTRATION_SUCCESS.TITLE" />
					</h3>
				</div>

				<div className='mt-2 text-center mb-25'>
					<FormattedHTMLMessage id="AUTH.REGISTRATION_SUCCESS.DESC" />
				</div>

				<button className="btn btn-primary btn-block btn-lg" onClick={() => history.push('/auth/login')}>
					<FormattedMessage id="AUTH.REGISTRATION_SUCCESS.SUBMIT" />
				</button>
			</React.Fragment>
		);
	}
}

export default injectIntl(connect(null, null)(RegistrationSuccess));
