import clsx from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import * as builder from "../../../_metronic/ducks/builder";

export default function HeaderLogo ({ customLogoUrl, appTitleClassName = "f-22px" }) {
	const headerLogo = useSelector(builder.selectors.getLogo);
	
	if (customLogoUrl) {
		return (
			<img
				src={customLogoUrl}
				alt="company_logo"
				style={{ height: 40 }}
			/>
		)
	}
	else {
		return (
			<div className="d-flex align-items-center">
				<div className="mr-15px">
					<img
						src={headerLogo}
						style={{ height: 30, width: 30 }}
						alt="company_logo"
					/>
				</div>
				<div className={clsx("text-dark", appTitleClassName)}>
					<FormattedMessage id="APP.NAME" />
				</div>
			</div>
		)
	}
}
