import React, { useEffect, useState, } from "react";
import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import ReactDOM from "react-dom";

import ElementSettings from '../Settings';

function Settings ({ id, data, rootProps, inputProps }) {
	const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
	const [rootElement, setRootElement] = useState(null);

	useEffect(() => {
		// let timerHandle = null;

		// if (!rootElement) {
		// 	timerHandle = setInterval(() => {
				const rootElement = document.getElementById(`element-settings-container-${id}`);
				// if (rootElement) {
					setRootElement(rootElement);
		// 		}
		// 	}, 3000);
		// }

		// return () => {
		// 	clearInterval(timerHandle)
		// };
	}, [
		id,
		rootElement,
	]);

	if (!rootElement) return null;

	return ReactDOM.createPortal(
		<React.Fragment>
			<button
				className="btn btn-light btn-block d-flex align-items-center"
				onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
			>
				<Icon className="mr-2">settings</Icon>
				<span className="f-14px">
					<FormattedMessage id="ELEMENT_LIST.ITEM.SETTINGS" />
				</span>
			</button>

			<ElementSettings
				anchorEl={settingsAnchorEl}
				handleClose={() => setSettingsAnchorEl(null)}
			>
				<div {...rootProps}>
					<button className="btn btn-primary btn-block">
						<FormattedMessage id="ELEMENT_LIST.ITEM.IMAGE.UPLOAD" />
						<input {...inputProps} />
					</button>
				</div>
			</ElementSettings>
		</React.Fragment>,
		rootElement
	);
}

export default Settings;