import _ from "lodash";
import * as SubscriptionsCRUD from "../../../crud/subscriptions.crud";

export const actionTypes = {
	FEATURES_LOADED: "[FEATURES] FEATURES_LOADED",
};

export const loadFeatures = () => {
	return async (dispatch) => {
		try {
			const { status, data } = await SubscriptionsCRUD.getFeatures();
			const features = _.sortBy(data.data, "order");

			if (status === 200) {
				dispatch({
					type: actionTypes.FEATURES_LOADED,
					payload: features,
				});
			}
			
			return Promise.resolve({ status, data });
		}
		catch (e) {
			return Promise.reject(e);
		}
	}
};
