import {
	ELEMENT_TYPES,
} from "./ElementType";

import ReadOnly from "./ReadableElement";
import Updatable from "./UpdatableElement";

export const READ_ONLY_TYPES = [
	ELEMENT_TYPES.HEADING,
	ELEMENT_TYPES.IMAGE,
	ELEMENT_TYPES.VIDEO,
	ELEMENT_TYPES.NOTES,
	ELEMENT_TYPES.SHARED_FILES,
];
export const TYPE_WITH_SETTINGS = [
	ELEMENT_TYPES.IMAGE,
	ELEMENT_TYPES.VIDEO,
	ELEMENT_TYPES.FILE_UPLOAD,
	ELEMENT_TYPES.BANK_STATEMENTS,
	ELEMENT_TYPES.VIDEO_INPUT,
	ELEMENT_TYPES.AUDIO_INPUT,
];

export default {
	Updatable,
	ReadOnly,
};