import { getStorage } from "../../_metronic";
import axios from 'axios';

const BASE_URL = `/api/v1/documents`;

export function updateDocument(documentId, data) {
	return axios.patch(`${BASE_URL}/${documentId}`, data);
}

export function deleteDocument(documentId) {
	return axios.delete(`${BASE_URL}/${documentId}/remove`);
}

export function updateDocumentStatus(documentId, data) {
	return axios.post(`${BASE_URL}/${documentId}/status-update`, data);
}

export function downloadFile(documentId, fileId, filename, type = 'download') {
	let url = `${axios.defaults.baseURL}${BASE_URL}/${documentId}/file/${fileId}?access_token=${getStorage('token')}`;

	if (type) {
		url += `&type=${type}`;
	}

	fetch(url)
	.then((res) => {
		console.log(res);
	});

	// response.blob().then(blob => {
	// 	let url = window.URL.createObjectURL(blob);
	// 	let a = document.createElement('a');
	// 	a.href = url;
	// 	a.download = filename;
	// 	a.click();
	// });
}

export function uploadFile(documentId, file, existingFiles = [], onProgress) {
	return axios.post(`${BASE_URL}/${documentId}/upload-file`, {
		fileName: file.name,
		extension: file.name.split('.').pop(),
		contentType: file.type,
		files: existingFiles,
	}, {
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			console.log(progressEvent);

			if (typeof onProgress === "function") {
				onProgress(percentCompleted);
			}
		}
	});
}

export function getSignedUrlForFile(documentId, fileId) {
	return axios.get(`${BASE_URL}/${documentId}/file/${fileId}`);
}
export function getSignedUrlForAttachment(documentId, fileId) {
	return axios.get(`${BASE_URL}/${documentId}/attachment/${fileId}`);
}