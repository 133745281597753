import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import clsx from "clsx";
// import getCroppedImg from './cropImage'

const useStyles = makeStyles(theme => ({
	cropContainer: {
		position: 'relative',
		width: '100%',
		height: 200,
		background: '#333',
		[theme.breakpoints.up('sm')]: {
			height: 400,
		},
	},
}));

const createImage = url =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	const maxSize = Math.max(image.width, image.height)
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea
	canvas.height = safeArea

	// translate canvas context to a central location on image to allow rotating around the center.
	ctx.translate(safeArea / 2, safeArea / 2)
	ctx.rotate(getRadianAngle(rotation))
	ctx.translate(-safeArea / 2, -safeArea / 2)

	// draw rotated image and store data.
	ctx.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5
	)
	const data = ctx.getImageData(0, 0, safeArea, safeArea)

	// set canvas width to final desired crop size - this will clear existing context
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height

	// paste generated rotate image with correct offsets for x,y crop values.
	ctx.putImageData(
		data,
		0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
		0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
	)

	// As Base64 string
	return canvas.toDataURL('image/png');

	// As a blob
	// return new Promise(resolve => {
	// 	canvas.toBlob(file => {
	// 		resolve(URL.createObjectURL(file))
	// 	}, 'image/png')
	// })
}

function ImageRenderer ({ file, onDimensionLoaded }) {
	const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
	
	useEffect(() => {

		if (file instanceof File) {
			let reader = new FileReader();

			reader.onloadend = () => {
				setImagePreviewUrl(reader.result);
			}

			reader.readAsDataURL(file)
		}
		else {
			setImagePreviewUrl(file);
		}
	}, [file]);

	const onImgLoad = useCallback((event) => {
		const image = event.target;
		onDimensionLoaded(image.width, image.height);
	}, [ onDimensionLoaded ]);

	if (!imagePreviewUrl) {
		return null;
	}

	return (
		<img
			alt="dummy"
			style={{ opacity: 0, position: "absolute" }}
			onLoad={onImgLoad}
			src={imagePreviewUrl}
		/>
	)
}

const CropModal = ({
	file,
	titleLabelId,
	handleClose,
	restrictPosition = true,
	cropWindow = null,
}) => {
	const classes = useStyles();
	const [fileUrl, setFileUrl] = useState('');
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [dimension, setDimension] = useState(cropWindow);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, [])

	useEffect(() => {
		const url = URL.createObjectURL(file);
		setFileUrl(url);
	}, [file, handleClose])

	const cropImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				fileUrl,
				croppedAreaPixels,
			)

			handleClose(croppedImage);
		} catch (e) {
			console.error(e)
		}
	}, [
		croppedAreaPixels,
		fileUrl,
		handleClose
	])

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open
			scroll="body"
			classes={{
				paper: "h-75"
			}}
			onClose={() => handleClose()}
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<div className="p-20 d-flex flex-column h-100">
				<div className="f-15 label-4 mb-15 flex-grow-0">
					<div className="row">
						<div className="col-8 offset-2 text-center">
							<h4 className="m-0">
								<FormattedMessage id={titleLabelId} />
							</h4>
						</div>
						<div className="col-2">
							<div className="float-right cursor-pointer" onClick={() => handleClose()}>
								<span className="fas fa-times"></span>
							</div>
						</div>
					</div>
				</div>

				{
					dimension ? (
						<div className={clsx(classes.cropContainer, "flex-grow-1")}>
							<Cropper
								restrictPosition={restrictPosition}
								image={fileUrl}
								crop={crop}
								zoom={zoom}
								aspect={dimension.width / dimension.height}
								onCropChange={setCrop}
								onCropComplete={onCropComplete}
								onZoomChange={setZoom}
							/>
						</div>
					) : (
						<ImageRenderer
							file={fileUrl}
							onDimensionLoaded={(width, height) => setDimension({ width, height })}
						/>
					)
				}

				<div className="mt-30px d-flex justify-content-end">
					<div>
						<button
							className={`btn btn-primary btn-lg btn-block btn-elevate py-3`}
							onClick={cropImage}
						>
							<FormattedMessage id="GENERAL.SUBMIT" />
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	)
}

export default CropModal;