import { V2_OPERATIONS } from "../actions/provider.actions";

export const getProvider = (store) => store?.provider;
export const getProviderData = (store) => store?.provider?.data;
export const getProviderId = (store) => store?.provider?.data?._id;
export const getProviderRequest = (store) => store?.provider?.data?.documentRequest;
export const getProviderInfo = (store) => store?.provider?.data?.documentRequest?.user;
export const getRequestType = (store) => store?.provider?.data?.documentRequest?.type;

export const getDocuments = (store) => store?.provider?.data?.documents;
export const getElementToViewCommentsFor = (store) => {
  const {
    operation,
    data: {
      elements
    },
  } = store.provider;
  let element = null;
  
  if (operation.action === V2_OPERATIONS.VIEW_COMMENTS) {
    element = elements.find((element) => {
      return element.uuid === operation?.data?.uuid;
    });
  }

  return element;
};
export const getElementToMarkNotRequired = (store) => {
  const {
    provider: {
      operation,
      data: {
        elements
      },
    }
  } = store;
  let element = null;
  
  if (operation.action === V2_OPERATIONS.NOT_REQUIRED) {
    element = elements.find((element) => {
      return element.uuid === operation?.data?.uuid;
    });
  }

  return element;
};
export const getFileToPreview = (store) => {
  const { operation } = store.provider;
  
  if (operation.action === V2_OPERATIONS.PREVIEW) {
    return operation.data;
  }

  return null;
};

export const doesRequirePassword = (store) => {
  const {
    provider: {
      check: {
        requirePassword,
      }
    }
  } = store;

  return requirePassword;
}

export const getElementsWithError = (store) => {
  const {
    provider: {
      elementsWithError,
    },
  } = store;

  return elementsWithError;
}
export const getDocumentsWithError = (store) => {
  const {
    provider: {
      documentsWithError,
    },
  } = store;

  return documentsWithError;
}

export const getToken = (store) => {
  const {
    provider: {
      data: {
        token,
      },
    },
  } = store;

  return token;
}

export const getComments = (store) => store.provider.comments.data;
export const getMessages = (store) => store.provider.messages.data;