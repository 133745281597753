import React from "react";
import {
	FormattedMessage,
} from "react-intl";
import { useHistory } from "react-router";

function ExpiredPlanInfo({ billingData }) {
	const history = useHistory();

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-16px">
				<FormattedMessage id="BILLING.PLAN_INFO.TITLE" />
			</div>
			<div className="f-20px fw-400 mb-10px text-danger">
				<FormattedMessage
					id="BILLING.EXPIRED_PLAN_INFO.TITLE"
					values={{
						planName: billingData.PREVIOUS_PLAN_INFO.name
					}}
				/>
			</div>

			<div className="mb-30px">
				<FormattedMessage
					id="BILLING.EXPIRED_PLAN_INFO.SUBTITLE"
					values={{
						planName: billingData.PREVIOUS_PLAN_INFO.name
					}}
				/>
			</div>

			<div className="pb-10px">
				<button
					className="btn btn-primary mr-20px"
					onClick={() => {
						history.push("/pricing")
					}}
				>
					<FormattedMessage id="GENERAL.UPGRADE" />
				</button>
			</div>
		</div>
	);
}

export default ExpiredPlanInfo;
