import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import Billing from './Billing';

export default function BillingPage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/billing" exact component={Billing} />
			</Switch>
		</Suspense>
	);
}
