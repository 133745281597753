import axios from "axios";

export const BASE_URL = "api/v1/integrations";
export const BASE_URL_INTEGRATION_CONFIG = "api/v1/integration-configs";

export function getIntegrations() {
	return axios.get(BASE_URL);
}

export function addIntegrationConfig(integrationId, data) {
	return axios.post(`${BASE_URL}/${integrationId}/add-config`, data);
}

export function updateIntegrationConfig(integrationConfigId, data) {
	return axios.patch(`${BASE_URL_INTEGRATION_CONFIG}/${integrationConfigId}`, data);
}

export function regenerateIntegrationConfig(integrationConfigId) {
	return axios.post(`${BASE_URL_INTEGRATION_CONFIG}/${integrationConfigId}/regenerate`);
}

export function activateIntegration(integrationConfigId) {
	return axios.post(`${BASE_URL}/${integrationConfigId}/activate`);
}