import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import AutoExpandInput from '../../../auto-expand-input';
import { getEmptyErrorObj, validateLongText } from '../../ElementPicker';
import Input from '../../../Input';
import ErrorMessage from '../ErrorMessage';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '..';

const useStyles = makeStyles((theme) => {
	return {
		answerField: {
			"& textarea": {
				resize: "vertical",
				lineHeight: 1.4,
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.inputFields.maxWidth,
			}
		}
	};
});

const LongText = forwardRef((props, _ref) => {
	const {
		data,
		response,
		onChange,
		onChangeResponse,
	} = props;
	const theme = useTheme();
	const intl = useIntl();
	const classes = useStyles();

	const [error, setError] = useState({});
	
	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	const [responseValue, setResponseValue] = useState(response.value);
	const debouncedResponseValue = useDebounce(responseValue, 750);

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);
	useEffect(() => {
		setResponseValue(response.value);
	}, [response.value]);

	useEffect(() => {
		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
		};
		const error = validateLongText(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.LONG_TEXT)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	])

	useEffect(() => {
		onChangeResponse({
			...response,
			value: debouncedResponseValue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedResponseValue]);

	return (
		<div>
			<div>
				<AutoExpandInput
					className="f-18px text-dark font-weight-medium mb-0"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.LONG_TEXT.TITLE.PLACEHOLDER"
						})
					}
				/>
			</div>

			{
				error.title && (
					<ErrorMessage message={error.title} />
				)
			}

			<div>
				<AutoExpandInput
					className="f-14px text-dark font-weight-regular mb-10px w-100 pb-0 break-word"
					style={{ color: theme.palette.extraColors.lightText }}
					onChange={(e) => setSubtitle(e.target.value)}
					value={subtitle}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.LONG_TEXT.SUBTITLE.PLACEHOLDER"
						})
					}
					allowHTML
				/>
			</div>

			<Input
				type="text"
				variant="outlined"
				fullWidth
				placeholder={
					intl.formatMessage({
						id: "ELEMENT_LIST.ITEM.LONG_TEXT.INPUT.PLACEHOLDER"
					})
				}
				InputProps={{
					classes: {
						root: "blue-border"
					},
				}}
				value={responseValue || ''}
				onChange={(e) => setResponseValue(e.target.value)}
				classes={{
					root: clsx(classes.answerField, 'MuiTextField-bg-white col-12 pl-0 condensed-textarea')
				}}
				multiline
				minRows="4"
			/>
		</div>
	)
});

export default LongText;
