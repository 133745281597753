import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	injectIntl
} from "react-intl";
import {
	connect
} from "react-redux";
import {
	Prompt,
} from "react-router-dom";
import {
	MenuItem,
	Card,
	Fab,
	CircularProgress,
	withStyles,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import { withSnackbar } from "notistack";
import {
	Archive,
	CheckBox,
	CheckBoxOutlineBlankOutlined,
	Info,
	Unarchive
} from "@material-ui/icons";

import {
	updateDocument,
	addProvider,
	updateProvider,
	updateProviderStatus,
	uploadFile,
	bulkDownload,
	cancelRequest,
	loadRequest,
	completeRequest,
	flagNames as requestFlagName,
	moveRequestToInProgress,
	archiveRequests,
} from '../../../../store/modules/actions/requests.actions';
import {
	setUpgradeDialog,
} from '../../../../store/modules/actions/app.actions';

import TitleCard from "./TitleCard";
import ItemCard from "../common/ItemCard";

import Select from '../../../../common/Select';
import Constants from '../../../../common/constants';
import {
	PlusIcon,
	CancelCircleIcon,
	FileErrorIcon,
	CloudDownloadIcon,
	CircleCheckIcon,
	SettingsIcon
} from '../../../../common/icons';
import Confirm, { Color } from "../../../../common/modals/confirm";
import { globalGA } from "../../../../../_metronic";

import ProviderDetails from "../common/ProviderDetails";

import ChatModal from "./Chat";
import AcceptDocument from "./AcceptDocument";
import RejectDocument from "./RejectDocument";
import DeleteDocument from "./DeleteDocument";
import DeleteFile from "./DeleteFile";
import ResendRequest from "./ResendRequest";
import Preview from "../../../../common/Preview";

const EDITABLE_PROVIDER_FIELDS = [
	'firstName',
	'lastName',
	'email',
	'phone',
	'dueDate',
	'documents',
	'smsNotification',
	'requirePassword',
	'reminderNotification',
];
const EDITABLE_DOCUMENT_FIELDS = [
	'name',
	'enabled',
	'description'
];
const REQUEST_OPERATION = {
	CANCEL: "CANCEL",
	MARK_COMPLETE: "MARK_COMPLETE",
	MOVE_TO_IN_PROGRESS: "MOVE_TO_IN_PROGRESS",
	ARCHIVE: "ARCHIVE",
}
const MAX_PROVIDERS_LIMIT = 4;

const DEFAULT_CUSTOM_ITEM = {
	enabled: true,
	name: '',
	description: '',
	files: [],
	attachments: [],
	fileRename: [],
	type: Constants.DocumentType.REGULAR
};

const DEFAULT_PROVIDER = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	documents: [],
	smsNotification: false,
	reminderNotification: true,
	requiredPassword: false,
	dueDate: moment().startOf('day').add(4, 'days').toDate(),
	customItem: { ...DEFAULT_CUSTOM_ITEM },
	emailMeta: {
		subject: "",
		content: ""
	}
};

const classesGenerator = (theme) => {
	return {
		addProviderContainer: {
			paddingLeft: 28
		},
		addDocumentButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		addProviderButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		downloadInPDF: {
			fontSize: 12,
			fontWeight: 300,
		}
	};
};

class Edit extends React.Component {
	submittedProviderIndex = -1;

	constructor(props) {
		super(props);

		const {
			selectedRequest,
		} = props;

		this.state = {
			requestOperation: '',
			chatProviderId: '',
			documentToAccept: null,
			documentToDelete: null,
			documentToReject: null,
			fileToDelete: null,
			originalProviders: selectedRequest.documentProviders.map((p) => _.pick(p, EDITABLE_PROVIDER_FIELDS)),
			documentIdToShowUploadsOf: null,
			showResendRequestModal: false,
			newProviders: [],
			shouldDownloadInPDF: false,

			previewInfo: {
				providerIndex: null,
				docId: null,
				fileIndex: null,
				attachmentIndex: null,
			},

			showAttachmentSizeWarning: false,
		};
	}

	bulkDownload = async (provider, documentObj) => {
		const {
			bulkDownload,
			intl,
		} = this.props;
		const {
			shouldDownloadInPDF,
		} = this.state;
		let filename = `${provider.firstName} ${provider.lastName}.zip`;
		let message = intl.formatMessage({ id: "REQUEST.DOWNLOADING_TEXT" }, { filename });

		if (documentObj) {
			filename = `${documentObj.name}.zip`;
			message = intl.formatMessage({ id: "REQUEST.DOWNLOADING_TEXT" }, { filename });
		}

		await bulkDownload(provider, documentObj, filename, message, shouldDownloadInPDF);

		// if request was made to download all files of all documents then
		// reset convert to pdf flag
		if (!documentObj) {
			this.setState({
				shouldDownloadInPDF: false,
			});
		}
	}

	addNewProvider = () => {
		const {
			selectedRequest,
			restrictions,
		} = this.props;
		const {
			newProviders,
		} = this.state;

		const providerObjList = [
			...selectedRequest.documentProviders,
			...newProviders,
		];

		if (providerObjList.length >= MAX_PROVIDERS_LIMIT) {
			return;
		}

		if (newProviders.length + 1 >= restrictions.requestAllowed) {
			this.props.setUpgradeDialog("REQUEST.EDIT.PROVIDER.PLAN_LIMIT_REACHED");
			return;
		}

		this.setState({
			newProviders: [
				...this.state.newProviders,
				{
					...DEFAULT_PROVIDER,
				},
			]
		});
	}

	saveProvider = async (providerIndex, provider) => {
		const {
			updateProvider,
			intl,
			enqueueSnackbar,
		} = this.props;
		const {
			originalProviders,
		} = this.state

		const updatedDocuments = provider.documents.filter((doc, docIndex) => {
			const currentDoc = _.pick(doc, EDITABLE_DOCUMENT_FIELDS);
			const originalDoc = _.pick(originalProviders[providerIndex].documents[docIndex], EDITABLE_DOCUMENT_FIELDS);

			return !_.isEqual(currentDoc, originalDoc);
		});

		// for (const doc of updatedDocuments) {
		// 	await updateDocument(doc._id, doc);
		// }

		try {
			await updateProvider(provider._id, provider, updatedDocuments);

			const msg = intl.formatMessage({
				id: "REQUESTS.PROVIDER.UPDATE.SUCCESS"
			});
			enqueueSnackbar(msg, { variant: 'success' })
		}
		catch (e) {
			console.log(e);
		}
	};

	addProvider = async (index, provider) => {
		const {
			addProvider,
			intl,
			enqueueSnackbar,
			selectedRequest,
		} = this.props;
		const {
			newProviders,
		} = this.state

		await addProvider(selectedRequest._id, provider, index);

		const indexInNewProvider = index - selectedRequest.documentProviders.length;
		this.setState({
			newProviders: newProviders.filter((_, providerIndex) => providerIndex !== indexInNewProvider)
		});

		const msg = intl.formatMessage({
			id: "REQUESTS.PROVIDER.ADD.SUCCESS"
		});
		enqueueSnackbar(msg, { variant: 'success' })
	}

	validateProvider = (providerIndex, provider) => {
		const { intl, enqueueSnackbar } = this.props;
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		const providerError = {};
		let showNoDocumentError = false, showInvalidDataError = false;

		if (!provider.firstName) {
			providerError.firstName = intl.formatMessage({
				id: "PROVIDER.FIRST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.lastName) {
			providerError.lastName = intl.formatMessage({
				id: "PROVIDER.LAST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.email) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.REQUIRED"
			});
		} else if (!emailRegex.test(provider.email)) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.INVALID"
			});
		}
		if (!provider.dueDate) {
			providerError.dueDate = intl.formatMessage({
				id: "PROVIDER.DUE_DATE.VALIDATION.REQUIRED"
			});
		}

		if (!provider.documents || !provider.documents.length) {
			providerError.documentsMain = intl.formatMessage({
				id: "PROVIDER.DOCUMENT.VALIDATION.REQUIRED"
			});
		}

		if (provider.documents && provider.documents.length) {
			providerError.documents = provider.documents.map((doc) => {
				const error = {};

				if (!doc.name) {
					error.name = intl.formatMessage({
						id: "PROVIDER.DOCUMENT.NAME.VALIDATION.REQUIRED"
					});
				}

				return _.keys(error).length ? error : undefined;
			});

			const areAllDocumentsValid = providerError.documents.some((err) => !!err);

			if (!areAllDocumentsValid) {
				delete providerError.documents;
			}
		}

		// console.log(providerError);
		if (!_.isEmpty(providerError) && providerIndex === this.submittedProviderIndex) {
			const errorKeys = _.keys(providerError);
			// if errorKeys has documentsMain key then show no documents error
			showNoDocumentError = errorKeys.includes('documentsMain');

			// if errorKeys does not have only documentsMain error key then show invalid field error
			showInvalidDataError = errorKeys.length > 1 || errorKeys[0] !== 'documentsMain'

			if (showInvalidDataError) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.EDIT.PROVIDER.VALIDATION_FAILED"
				});

				enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 });
			}

			if (showNoDocumentError) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.EDIT.PROVIDER.NO_DOCUMENTS_ERRORS"
				});

				enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 5000 });
			}

			this.submittedProviderIndex = -1;
		}

		return providerError;
	}

	uploadFiles = (docId, files) => {
		const {
			uploadFile,
			selectedRequest,
			enqueueSnackbar,
			intl,
		} = this.props;
		let selectedDocument = null;
		const uuid = {
			request: selectedRequest.uuid,
		};

		for (let provider of selectedRequest.documentProviders) {
			uuid.provider = provider.uuid;

			for (let doc of provider.documents) {
				if (doc._id === docId) {
					selectedDocument = doc;
					break;
				}
			}

			if (selectedDocument) {
				uuid.document = selectedDocument.uuid;
				break;
			}
		}

		if (selectedDocument) {
			const existingFiles = selectedDocument.files;
			if (existingFiles.length + files.length > Constants.FILE_UPLOAD_LIMIT) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.DOCUMENT.UPLOAD.LIMIT_REACHED"
				}, {
					limit: Constants.FILE_UPLOAD_LIMIT,
					maxPossible: Constants.FILE_UPLOAD_LIMIT - existingFiles.length
				});

				enqueueSnackbar(errorMessage, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
			else {
				uploadFile(uuid.request, uuid.provider, uuid.document, files);
			}
		}
	}

	doesAttachmentsExceedsLimit = (provider, files) => {
		let existingAttachmentSizeKB = 0;

		provider.documents.forEach((doc) => {
			doc.attachments.forEach((attachment) => {
				existingAttachmentSizeKB += (attachment instanceof File ? (attachment.size / 1024) : attachment.size);
			});
		});

		const newFilesKB = files.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}

	downloadInPDF = () => {
		const {
			restrictions,
		} = this.props;
		const {
			shouldDownloadInPDF,
		} = this.state;

		if (!restrictions.features.PDF_CONVERSION) {
			this.props.setUpgradeDialog("GENERAL.UPGRADE_MESSAGE");
			return;
		}

		this.setState({
			shouldDownloadInPDF: !shouldDownloadInPDF
		});
	}

	render() {
		const {
			intl,
			selectedRequest,
			flags,
			updateProviderStatus,
			classes,
			cancelRequest,
			completeRequest,
			archiveRequests,
			moveRequestToInProgress,
			user,
			loadRequest,
			setUpgradeDialog,
			requestId,
			restrictions,
		} = this.props;
		const {
			chatProviderId,
			documentToAccept,
			documentToReject,
			documentToDelete,
			fileToDelete,
			requestOperation,
			originalProviders,
			documentIdToShowUploadsOf,
			previewInfo,
			newProviders,
			showResendRequestModal,
			showAttachmentSizeWarning,
			shouldDownloadInPDF,
		} = this.state;

		let documentToShowUploadsOf = null;

		if (documentIdToShowUploadsOf) {
			selectedRequest.documentProviders.some((provider) => {
				provider.documents.some((doc) => {
					if (doc._id === documentIdToShowUploadsOf) {
						documentToShowUploadsOf = doc;
					}

					return !!documentToShowUploadsOf;
				});

				return !!documentToShowUploadsOf;
			});
		}

		const providerObjList = [
			...selectedRequest.documentProviders,
			...newProviders,
		];
		const isRequestArchived = selectedRequest.archived;
		const isInReadOnlyMode = user && user.readOnly;
		const isDisabled = isRequestArchived || isInReadOnlyMode;

		return (
			<div>
				{
					isRequestArchived && (
						<div className="d-flex justify-content-center">
							<Alert color="secondary" className="mb-3 d-flex align-items-center">
								<div>
									<div className="d-none d-sm-block">
										<Info />
									</div>
									<div className="d-block d-sm-none">
										<Info fontSize="large" />
									</div>
								</div>
								<div className="pl-3">
									<FormattedMessage id="REQUEST.ARCHIVED_MESSAGE" />
								</div>
							</Alert>
						</div>
					)
				}

				<div>
					<TitleCard
						status={selectedRequest.status}
						onArchive={() => this.setState({ requestOperation: REQUEST_OPERATION.ARCHIVE })}
						onInProgress={() => this.setState({ requestOperation: REQUEST_OPERATION.MOVE_TO_IN_PROGRESS })}
						onCancel={() => this.setState({ requestOperation: REQUEST_OPERATION.CANCEL })}
						onComplete={() => this.setState({ requestOperation: REQUEST_OPERATION.MARK_COMPLETE })}
					/>
				</div>

				{
					providerObjList && providerObjList.map((provider, providerIndex, arr) => {
						return (
							<Formik
								key={providerIndex}
								initialValues={provider}
								enableReinitialize
								validate={(provider) => this.validateProvider(providerIndex, provider)}
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={(provider) => {
									if (provider._id) {
										this.saveProvider(providerIndex, provider)
									}
									else {
										this.addProvider(providerIndex, provider)
									}
								}}
							>
								{({
									values: provider,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue,
									setFieldTouched,
								}) => {
									const isDataSame = originalProviders && _.isEqual(originalProviders[providerIndex], _.pick(provider, EDITABLE_PROVIDER_FIELDS));
									const isProviderSaving = provider._id
										? flags.loading[requestFlagName.PROVIDER_UPDATE] === provider._id
										: flags.loading[requestFlagName.PROVIDER_ADD] === providerIndex;
									const isDocumentSaving = flags.loading[requestFlagName.DOCUMENT_UPDATE] && provider.documents.find(({ _id }) => _id === flags.loading[requestFlagName.DOCUMENT_UPDATE]);
									const isLoading = isProviderSaving || isDocumentSaving;

									return (
										<fieldset disabled={isInReadOnlyMode}>
											<Card elevation={0} className="mb-4 p-15 provider-card rounded-card" key={providerIndex}>
												<div className="row d-none my-15 px-10">
													<div className="col-12 d-flex align-items-sm-center flex-column flex-sm-row px-0">
														<div className="provider-title d-flex flex-grow-1">
															<div className="d-flex flex-grow-1 justify-content-between align-items-center w-100">
																<div>
																	<span>
																		<span>
																			{`${provider.firstName} ${provider.lastName}`}
																		</span>
																		{
																			arr.length > 1 && !!provider._id && (
																				<FormattedMessage id={`REQUEST.PROVIDER.STATUS.${provider.status}`}>
																					{
																						(statusText) => (
																							<span
																								className={`${clsx({
																									'text-primary-dark': [Constants.ProviderStatus.PENDING, Constants.ProviderStatus.IN_PROGRESS].includes(provider.status),
																									'text-success': [Constants.ProviderStatus.COMPLETED, Constants.ProviderStatus.IN_REVIEW].includes(provider.status),
																									'text-danger': provider.status === Constants.ProviderStatus.REJECTED
																								})}`}
																							>
																								{` - ${statusText}`}
																							</span>
																						)
																					}
																				</FormattedMessage>
																			)
																		}
																	</span>
																</div>
															</div>

															{
																!!provider._id && arr.length > 1 && (
																	<div className="custom-input d-flex flex-grow-1 align-items-center justify-content-end flex-column flex-sm-row">
																		<Select
																			classes={{
																				root: `pr-5 d-flex align-items-center`
																			}}
																			value={[Constants.ProviderStatus.COMPLETED, Constants.ProviderStatus.CANCELLED].includes(provider.status) ? provider.status : ""}
																			onChange={(e) => {
																				const newStatus = e.target.value;

																				if (newStatus) {
																					updateProviderStatus(provider._id, newStatus);
																				}
																			}}
																			variant="outlined"
																			placeholder="Actions"
																		>
																			<MenuItem value={Constants.ProviderStatus.COMPLETED} className="pr-3">
																				<FormattedMessage id="REQUEST.MARK_COMPLETED" />
																			</MenuItem>
																			<MenuItem value={Constants.ProviderStatus.CANCELLED} className="pr-3">
																				<FormattedMessage id="REQUEST.MARK_REJECTED" />
																			</MenuItem>
																		</Select>
																	</div>
																)
															}
														</div>
													</div>
												</div>

												<ProviderDetails
													index={providerIndex}
													provider={provider}
													handleBlur={handleBlur}
													handleChange={handleChange}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
													touched={touched}
													errors={errors}
													onOpenChat={() => user.chatService && this.setState({ chatProviderId: provider._id })}
												/>

												<hr className="my-30px" />

												<div className="row px-0 w-100 m-0">
													{
														touched.templateId &&
														errors.documentsMain && (
															<div className="col-12 mb-4 d-flex justify-content-center">
																<Alert color="danger">
																	{errors.documentsMain}
																</Alert>
															</div>
														)
													}

													{
														provider.documents && provider.documents.length > 0 && provider.documents.map((item, itemIndex) => {
															const itemObj = {
																...item,
																submittedBy: provider.firstName + ' ' + provider.lastName
															};

															const errorStatus = _.get(errors, `documents.${itemIndex}`, undefined);
															const touchedStatus = _.get(touched, `documents.${itemIndex}`, undefined);

															return (
																<div className="col-12 mb-4" key={`${itemObj._id}_${itemObj.status}_${itemIndex}`}>
																	<ItemCard
																		className="p-15"
																		isEdit
																		item={itemObj}
																		handleStatusChange={(newStatus) => {
																			if (newStatus === Constants.DocumentStatus.REJECTED) {
																				this.setState({ documentToReject: itemObj });
																			}
																			else {
																				this.setState({ documentToAccept: itemObj });
																			}
																		}}
																		handleNameChange={(e) => {
																			const field = `documents[${itemIndex}].name`;
																			setFieldTouched(field, true);
																			setFieldValue(
																				field,
																				e.target.value,
																				true
																			);
																		}}
																		handleTypeChange={(newType) => {
																			const field = `documents[${itemIndex}].type`;
																			setFieldTouched(field, true);
																			setFieldValue(
																				field,
																				newType,
																				true
																			);
																		}}
																		handleDelete={() => {
																			if (itemObj._id) {
																				this.setState({
																					documentToDelete: itemObj,
																				});
																			}
																			else {
																				setFieldValue(
																					`documents`,
																					provider.documents.filter((doc, index) => index !== itemIndex),
																					true
																				);
																			}
																		}}
																		handleDeleteFile={(index) => {
																			this.setState({
																				fileToDelete: {
																					document: itemObj,
																					index: index
																				}
																			})
																		}}
																		handleMessageChange={(e) => {
																			const field = `documents[${itemIndex}].description`;
																			setFieldTouched(field, true);
																			setFieldValue(
																				field,
																				e.target.value,
																				true
																			)
																		}}

																		handleAddAttachments={(files) => {
																			if (this.doesAttachmentsExceedsLimit(provider, files)) {
																				this.setState({ showAttachmentSizeWarning: true })
																				return;
																			}

																			const field = `documents[${itemIndex}].attachments`;
																			setFieldTouched(field, true);
																			setFieldValue(
																				field,
																				[
																					...itemObj.attachments,
																					...files
																				],
																				true
																			)
																		}}
																		handleDeleteAttachment={(index) => {
																			const newAttachments = itemObj.attachments.filter((file, attachmentIndex) => attachmentIndex !== index)
																			setFieldValue(
																				`documents[${itemIndex}].attachments`,
																				newAttachments,
																				true
																			)
																		}}

																		handleBulkDownload={(documentObj) => this.bulkDownload(provider, documentObj)}

																		showUploads={() => {
																			this.setState({ documentIdToShowUploadsOf: itemObj._id });
																		}}

																		handleFileRenameAdd={() => {
																			if (!restrictions.features.FILE_RENAME_FEATURE) {
																				setUpgradeDialog("GENERAL.UPGRADE_MESSAGE");
																				return;
																			}

																			setFieldValue(
																				`documents[${itemIndex}].fileRename`,
																				[...item.fileRename, ""],
																				true
																			)
																		}}
																		handleFileRenameChange={(index, value) => {
																			if (!restrictions.features.FILE_RENAME_FEATURE) {
																				setUpgradeDialog("GENERAL.UPGRADE_MESSAGE");
																				return;
																			}

																			const field = `documents[${itemIndex}].fileRename[${index}]`;
																			setFieldTouched(field, true);
																			setFieldValue(
																				field,
																				value,
																				true
																			)
																		}}
																		handleFileRenameRemove={(index) => {
																			const updatedFileRename = item.fileRename.filter((v, i) => i !== index);
																			setFieldValue(
																				`documents[${itemIndex}].fileRename`,
																				updatedFileRename,
																				true
																			)
																		}}

																		error={errorStatus}
																		touched={touchedStatus}
																		uploadFiles={(files) => this.uploadFiles(itemObj._id, files)}
																		isUploading={flags.loading[requestFlagName.UPLOAD] === itemObj._id}
																		selectFileForPreview={(fileIndex) => this.setState({ previewInfo: { providerIndex, itemIndex, fileIndex } })}
																		selectAttachmentForPreview={(attachmentIndex) => this.setState({ previewInfo: { providerIndex, itemIndex, attachmentIndex } })}
																	/>
																</div>
															);
														})
													}
												</div>

												<div className="mb-4 d-flex align-items-center flex-column flex-sm-row px-3">
													{
														!isDisabled && (
															<div
																className="mb-4 mb-sm-0 d-flex align-items-center cursor-pointer"
																onClick={() => {
																	globalGA("add_document", {
																		eventAction: "success",
																		eventCategory: "request_creation_C1"
																	});
																	setFieldValue(
																		`documents`,
																		[
																			...provider.documents,
																			{ ...DEFAULT_CUSTOM_ITEM, enabled: true, }
																		],
																		true
																	);
																}}
															>
																<Fab className={clsx(classes.addDocumentButton, "bg-white text-primary")}>
																	<PlusIcon />
																</Fab>
																<span className="ml-15 f-15">
																	<FormattedMessage id="TEMPLATE.ADD_DOCUMENT" />
																</span>
															</div>
														)
													}
													<div
														className="mb-4 mb-sm-0 d-flex align-items-center ml-auto"
													>
														<Fab
															className={clsx(
																"bg-white text-primary cursor-pointer",
																classes.addDocumentButton,
																{
																	// "disabled btn-disabled": flags.loading[requestFlagName.BULK_DOWNLOAD] && flags.loading[requestFlagName.BULK_DOWNLOAD] !== provider._id
																}
															)}
															onClick={() => this.bulkDownload(provider)}
														>
															{
																flags.loading[requestFlagName.BULK_DOWNLOAD] &&
																	flags.loading[requestFlagName.BULK_DOWNLOAD][provider._id] === true ? (
																	<CircularProgress size={10} />
																) : (
																	<CloudDownloadIcon />
																)
															}
														</Fab>
														<div>
															<div className="ml-15 f-15">
																<FormattedMessage id="REQUEST.DOWNLOAD_ALL_FILES" />
															</div>
															<div
																className="ml-15 f-15 cursor-pointer"
																onClick={this.downloadInPDF}
															>
																{
																	shouldDownloadInPDF ? (
																		<CheckBox color="primary" />
																	) : (
																		<CheckBoxOutlineBlankOutlined color="primary" />
																	)
																}

																<span className={clsx("ml-1", classes.downloadInPDF)}>
																	<FormattedMessage id="REQUEST.DOWNLOAD_ALL_FILES.DOWNLOAD_IN_PDF" />
																</span>
															</div>
														</div>
													</div>
												</div>

												{
													!isDisabled && (
														<div className="d-flex justify-content-center justify-content-md-end">
															<button
																disabled={isDataSame}
																className={`py-3 btn btn-primary ${clsx(
																	{
																		"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading,
																		"btn-disabled": isDataSame
																	}
																)}`}
																onClick={() => {
																	this.submittedProviderIndex = providerIndex;
																	handleSubmit();
																}}
															>
																<FormattedMessage id="REQUESTS.PROVIDER.UPDATE" />
															</button>
														</div>
													)
												}

												<Prompt
													when={!isDataSame}
													message={location => intl.formatMessage({ id: "REQUEST.PROVIDER.UNSAVED_CHANGES" }, { values: provider })}
												/>

												{
													previewInfo &&
													previewInfo.providerIndex === providerIndex &&
													previewInfo.itemIndex > -1 &&
													previewInfo.fileIndex > -1 && (
														<Preview
															file={provider.documents[previewInfo.itemIndex].files[previewInfo.fileIndex]}
															handleClose={() => this.setState({ previewInfo: null })}
														/>
													)
												}

												{
													previewInfo &&
													previewInfo.providerIndex === providerIndex &&
													previewInfo.itemIndex > -1 &&
													previewInfo.attachmentIndex > -1 && (
														<Preview
															file={provider.documents[previewInfo.itemIndex].attachments[previewInfo.attachmentIndex]}
															handleClose={() => this.setState({ previewInfo: null })}
														/>
													)
												}
											</Card>

											{
												!!documentToDelete && (
													<DeleteDocument
														open={!!documentToDelete}
														document={documentToDelete}
														handleClose={(wasSubmitted) => {
															if (wasSubmitted) {
																debugger;
																const newDocuments = provider.documents.filter((document) => document._id !== documentToDelete._id);
																setFieldValue('documents', newDocuments);
															}

															this.setState({ documentToDelete: null });
														}}
													/>
												)
											}
										</fieldset>
									);
								}}
							</Formik>
						);
					})
				}

				{
					!isDisabled && (
						<div className="row mb-4">
							<div
								className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start mb-5 mb-md-0 cursor-pointer"
								onClick={this.addNewProvider}
							>
								<div className={clsx(classes.addProviderContainer, "d-flex align-items-center flex-column flex-sm-row")}>
									<Fab
										className={clsx(classes.addProviderButton, "mb-2 mb-sm-0")}
										color="primary"
										disabled={providerObjList.length >= MAX_PROVIDERS_LIMIT}
									>
										<PlusIcon fill="white" />
									</Fab>
									<div>
										<span className="ml-0 ml-sm-3 f-15 text-dark">
											<FormattedMessage id="REQUEST.ADD_PROVIDER" />
										</span>
									</div>
								</div>
							</div>
						</div>
					)
				}

				{
					requestOperation === REQUEST_OPERATION.CANCEL && (
						<Confirm
							open
							icon={<CancelCircleIcon />}
							variant="danger"
							handleClose={async (wasSubmitted) => {
								if (wasSubmitted) {
									await cancelRequest(requestId);
									await loadRequest(requestId);
								}

								this.setState({ requestOperation: null });
							}}
							title="REQUEST.CANCEL.TITLE"
							submitButtonText="GENERAL.YES"
							cancelButtonText="GENERAL.NO"
							loading={flags.loading[requestFlagName.CANCEL]}
						/>
					)
				}

				{
					requestOperation === REQUEST_OPERATION.MOVE_TO_IN_PROGRESS && (
						<Confirm
							open
							icon={
								<SettingsIcon
									size={75}
									viewBox="0 0 20 20"
								/>
							}
							variant="primary"
							handleClose={async (wasSubmitted) => {
								if (wasSubmitted) {
									await moveRequestToInProgress(requestId);
									await loadRequest(requestId);
								}

								this.setState({ requestOperation: null });
							}}
							title="REQUEST.ACTION.MOVE_TO_IN_PROGRESS"
							submitButtonText="GENERAL.YES"
							cancelButtonText="GENERAL.NO"
							loading={flags.loading[requestFlagName.IN_PROGRESS]}
						/>
					)
				}

				{
					requestOperation === REQUEST_OPERATION.ARCHIVE && (
						<Confirm
							open
							icon={
								selectedRequest.archived ? (
									<Unarchive color="action" style={{ height: 75, width: 75 }} />
								) : (
									<Archive color="action" style={{ height: 75, width: 75 }} />
								)
							}
							variant="primary"
							handleClose={async (wasSubmitted) => {
								if (wasSubmitted) {
									await archiveRequests([selectedRequest], !selectedRequest.archived);
									await loadRequest(requestId);
								}

								this.setState({ requestOperation: null });
							}}
							title={selectedRequest.archived ? "REQUEST.UNARCHIVE.CONFIRM.TITLE" : "REQUEST.ARCHIVE.CONFIRM.TITLE"}
							titleValues={{ totalCount: 1 }}
							submitButtonText="GENERAL.YES"
							submitButtonForcedColor={Color.secondary}
							cancelButtonText="GENERAL.NO"
							loading={flags.loading[requestFlagName.ARCHIVE]}
							error={flags.error[requestFlagName.ARCHIVE]}
						/>
					)
				}

				{
					!!fileToDelete && (
						<DeleteFile
							open
							document={fileToDelete.document}
							fileIndexToDelete={fileToDelete.index}
							handleClose={(wasSubmitted) => {
								this.setState({ fileToDelete: null });
							}}
						/>
					)
				}

				{
					!!documentToReject && (
						<RejectDocument
							open={!!documentToReject}
							document={documentToReject}
							handleClose={(wasSubmitted) => {
								this.setState({ documentToReject: null });
							}}
						/>
					)
				}

				{
					!!documentToAccept && (
						<AcceptDocument
							open={!!documentToAccept}
							document={documentToAccept}
							handleClose={(wasSubmitted) => {
								this.setState({ documentToAccept: null });
							}}
						/>
					)
				}

				{
					!!chatProviderId && user.chatService && (
						<ChatModal
							provider={chatProviderId}
							handleClose={() => this.setState({ chatProviderId: null })}
						/>
					)
				}

				{
					requestOperation === REQUEST_OPERATION.MARK_COMPLETE && (
						<Confirm
							open
							icon={({ color }) => <CircleCheckIcon fill={color} />}
							variant="success"
							handleClose={async (wasSubmitted) => {
								if (wasSubmitted) {
									await completeRequest(requestId);
									await loadRequest(requestId);
								}

								this.setState({ requestOperation: null });
							}}
							title="REQUEST.COMPLETE.TITLE"
							submitButtonText="GENERAL.YES"
							cancelButtonText="GENERAL.NO"
							loading={flags.loading[requestFlagName.COMPLETE]}
						/>
					)
				}

				{
					// !!documentToShowUploadsOf && (
					// 	<ShowUploads
					// 		open
					// 		item={documentToShowUploadsOf}
					// 		uploadFiles={(files) => this.uploadFiles(documentToShowUploadsOf._id, files)}
					// 		isUploading={flags.loading[requestFlagName.UPLOAD]}
					//    alo need providerIndex now for previewInfo
					// 		selectForPreview={(docId, fileIndex) => this.setState({ previewInfo: { docId, fileIndex } })}
					// 		handleDeleteFile={(index) => this.setState({ fileToDelete: { document: documentToShowUploadsOf, index } })}
					// 		handleClose={() => this.setState({ documentIdToShowUploadsOf: null })}
					// 	/>
					// )
				}

				{
					showResendRequestModal && (
						<ResendRequest
							open
							request={selectedRequest}
							handleClose={() => this.setState({ showResendRequestModal: false })}
						/>
					)
				}

				<Confirm
					open={showAttachmentSizeWarning}
					icon={<FileErrorIcon />}
					variant="danger"
					handleClose={() => this.setState({ showAttachmentSizeWarning: false })}
					title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
					message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
					submitButtonText="GENERAL.OK"
				/>

				<Confirm
					open={showAttachmentSizeWarning}
					icon={<FileErrorIcon />}
					variant="danger"
					handleClose={() => this.setState({ showAttachmentSizeWarning: false })}
					title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
					message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
					submitButtonText="GENERAL.OK"
				/>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		selectedRequest: store.requests.selectedRequest,
		user: store.auth.user,
		flags: store.flags,
	};
}

export default withSnackbar(
	injectIntl(
		withStyles(classesGenerator)(
			connect(
				mapStateToProps,
				{
					updateDocument,
					updateProvider,
					addProvider,
					updateProviderStatus,
					uploadFile,
					bulkDownload,
					loadRequest,
					cancelRequest,
					moveRequestToInProgress,
					archiveRequests,
					completeRequest,
					setUpgradeDialog,
				}
			)(Edit)
		)
	)
);
