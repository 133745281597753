import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	Dialog,
	DialogTitle,
	TextField,
	DialogContent,
	makeStyles
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	updateDocumentStatus,
	flagNames,
} from '../../../../store/modules/actions/requests.actions';

import Constants from "../../../../common/constants";
import { FileErrorIcon } from "../../../../common/icons";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		header: {
			height: "150px",
		},
		title: {
			fontWeight: 500,
			fontSize: '18px'
		},
		message: {
			fontSize: '14px'
		},
		cancelButton: {
			background: theme.palette.extraColors.gray,
		},
		actionButton: {
			minWidth: 145,
		},
		rejectReasonInput: {
			color: "#EB5757 !important",
			"& ::placeholder": {
				color: "#EB5757AA",
			},

			"& :-ms-input-placeholder": {
				color: "#EB5757AA",
			},
		}
	}
})



function RejectDocument(props) {
	const {
		open,
		document: doc,
		handleClose,
		updateDocumentStatus,
		intl,
	} = props;
	const classes = useStyles();
	
	const flags = useSelector(getFlags);

	const loading = flags.loading[flagNames.DOCUMENT_STATUS_UPDATE];
	const error = flags.error[flagNames.DOCUMENT_STATUS_UPDATE];

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			fullWidth
			classes={{
				paper: "rounded-card"
			}}
			maxWidth="xs"
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<Formik
				initialValues={{
					reason: '',
				}}
				validate={values => {
					const error = {};

					if (!values.reason) {
						error.reason = intl.formatMessage({
							id: "REQUEST.CANCEL.REASON.VALIDATION.REQUIRED"
						});
					}

					return error;
				}}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={async (values) => {
					await updateDocumentStatus(doc._id, {
						status: Constants.DocumentStatus.REJECTED,
						rejectedReason: values.reason
					});
					handleClose(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => {
					return (
						<form
							noValidate={true}
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<DialogContent className="p-0">
								<div
									className={clsx(classes.header, "d-flex align-items-center justify-content-center header-container")}
									style={{ background: "#FFEBEB" }}
								>
									<div>
										<FileErrorIcon />
									</div>
								</div>

								<div className="text-center px-30px pt-30px pb-0">
									<div className={clsx(classes.title)}>
										<FormattedMessage id="REQUEST.DOCUMENT.REJECT.TITLE" />
									</div>

									<div className="row">
										<div className="col-12 my-15">
											<div className="form-group mb-0">
												<FormattedMessage id="REQUEST.CANCEL.REASON">
													{
														(text) => (
															<TextField
																type="text"
																variant="outlined"
																placeholder={text}
																className={clsx(classes.rejectReasonInput, "m-0 w-100")}
																name="reason"
																classes={{
																	root: 'MuiTextField-bg-white condensed-textarea',
																}}
																multiline
																rows="4"
																onBlur={handleBlur}
																onChange={handleChange}
																value={values.reason}
																helperText={touched.reason && errors.reason}
																error
															/>
														)
													}
												</FormattedMessage>
											</div>
										</div>
									</div>
								</div>

								{
									error && (
										<div className="d-flex justify-content-center">
											<Alert color="danger">
												{error}
											</Alert>
										</div>
									)
								}

								<div className={clsx(classes.buttonContainer, "d-flex justify-content-center align-items-center p-40px")}>
									{
										<div>
											<button
												className={clsx(classes.actionButton, classes.cancelButton, "btn btn-lg btn-elevate py-3 mr-30px")}
												onClick={() => handleClose(false)}
												type="button"
											>
												<FormattedMessage id="REQUEST.DOCUMENT.REJECT.CLOSE" />
											</button>
										</div>										
									}

									<div>
										<button
											className={`btn btn-lg btn-elevate py-3 text-white ${clsx(
												classes.actionButton,
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
												}
											)}`}
											style={{ background: "#EB5757" }}
											type="submit"
										>
											<FormattedMessage id="REQUEST.DOCUMENT.REJECT.SUBMIT" />
										</button>
									</div>
								</div>
							</DialogContent>
						</form>
					);
				}}
			</Formik>
		</Dialog>
	);
}

export default injectIntl(
	connect(
		null,
		{
			updateDocumentStatus,
		}
	)(RejectDocument)
)
