import { actionTypes } from "./actions/tasks.actions";
import _ from "lodash";

const initialState = {
	status: {},
	result: {},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_TASK: {
			const {
				id,
				type,
				text,
				progress,
				...rest
			} = action.payload;
			
			return {
				...state,
				status: {
					...state.status,
					[id]: {
						type,
						text,
						progress,
						...rest
					}
				}
			};
		}

		case actionTypes.REMOVE_TASK: {
			const {
				id,
			} = action.payload;

			const newStatus = _.omit(state.status, id);
			
			return {
				...state,
				status: newStatus
			};
		}

		case actionTypes.TASK_RESULT: {
			const {
				payload: {
					uploadId,
					result,
				}
			} = action;
			return {
				...state,
				result: {
					...state.result,
					[uploadId]: result,
				}
			}
		}

		default:
			return state;
	}
}
