import React, { useCallback, useEffect, useState } from "react";
import {
	Card,
	CardContent,
	Fab,
	InputBase,
	makeStyles,
	Menu,
	MenuItem,
	ListItemIcon,
	useMediaQuery,
	useTheme,
	ListItemText,
	CircularProgress
} from '@material-ui/core';
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Archive, Edit, MoreVert, NoSim, OfflinePin, Unarchive } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";

import Constants from '../../../../common/constants';
import { SettingsIcon } from "../../../../common/icons";
import { flagNames, updateRequestTitle } from "../../../../store/modules/actions/requests.actions";
import useDebounce from "../../../../common/useDebounce";
import { useSnackbar } from "notistack";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { getFlags } from "../../../../store/modules/selectors/common.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => {
	return {
		elevation: {
			boxShadow: '0px 4px 8px rgba(46, 91, 255, 0.24) !important',
			'&:hover': {
				boxShadow: '0px 4px 8px rgba(46, 91, 255, 0.24) !important',
			}
		},
		addDocumentButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		requestMenuOption: {
			minWidth: 35,
		},
		fabWrapper: {
			margin: theme.spacing(1),
			position: 'relative',
		},
		fabProgress: {
			position: 'absolute',
			top: -5,
			left: -5,
			zIndex: 1,
		},
	}
});

function TitleCard({
	onArchive,
	onCancel,
	onComplete,
	onInProgress
}) {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
	const [anchorEl, setAnchorEl] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const flags = useSelector(getFlags);
	const request = useSelector(getSelectedRequest);
	
	const canArchive = request.status === Constants.RequestStatus.CANCELLED || request.status === Constants.RequestStatus.COMPLETED;
	const canUnarchive = request.archived;
	const [title, setTitle] = useState(request.title);
	const debouncedTitle = useDebounce(title, 500);

	useEffect(() => {
		if (debouncedTitle !== request.title) {
			if (!debouncedTitle && !request.title) {
				return;
			}

			dispatch(updateRequestTitle(request._id, debouncedTitle))
		}		
	}, [
		dispatch,
		request._id,
		debouncedTitle,
		request,
	]);

	useEffect(() => {
		if (flags.error[flagNames.UPDATE_TITLE]) {
			enqueueSnackbar(flags.error[flagNames.UPDATE_TITLE], { variant: "error" });
		}
	}, [
		flags.error,
		enqueueSnackbar,
	]);

	const handleMenuOpen = useCallback((event) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	}, [
		setAnchorEl
	]);

	const handleMenuClose = useCallback((event) => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		setAnchorEl(null);
	}, [
		setAnchorEl
	]);

	const onCancelRequest = useCallback((event, provider) => {
		event.preventDefault();
		event.stopPropagation();

		onCancel();
		handleMenuClose();
	}, [
		onCancel,
		handleMenuClose
	]);

	const onArchiveRequest = useCallback((event) => {
		event.stopPropagation();
		event.preventDefault();

		onArchive();
		handleMenuClose();
	}, [
		onArchive,
		handleMenuClose
	]);

	const onCompleteRequest = useCallback((event) => {
		event.stopPropagation();
		event.preventDefault();

		onComplete();
		handleMenuClose();
	}, [
		onComplete,
		handleMenuClose
	]);

	const onInProgressRequest = useCallback((event) => {
		event.stopPropagation();
		event.preventDefault();

		onInProgress();
		handleMenuClose();
	}, [
		onInProgress,
		handleMenuClose
	]);

	return (
		<Card
			elevation={0}
			className={clsx(
				"title-card px-15px py-20px rounded-card mt-0 mt-md-4 mb-3 mb-md-5",
				{
					"bg-transparent": isDownSm,
					"bg-white": !isDownSm
				}
			)}
		>
			<CardContent className="p-0">
				<fieldset
					className="d-flex justify-content-between align-items-center"
					disabled={isInReadOnlyMode}
				>
					<div className="d-flex">
						<FormattedMessage id="REQUEST.TITLE.PLACEHOLDER">
							{
								(text) => (
									<InputBase
										className="f-20px font-weight-bold mr-2"
										name="title"
										onChange={(e) => setTitle(e.target.value)}
										placeholder={text}
										value={title}
										disabled={request.archived}
									/>
								)
							}
						</FormattedMessage>

						<div className={clsx(classes.fabWrapper, { "d-none": request.archived })}>
							<Fab
								onClick={() => {}}
								className={clsx(classes.addDocumentButton, "bg-white pointer-events-none text-primary")}
							>
								<Edit color="primary" />
							</Fab>
							{
								flags.loading[flagNames.UPDATE_TITLE] && (
									<CircularProgress
										size={40}
										className={classes.fabProgress}
									/>
								)
							}
						</div>
					</div>

					<div className="d-flex align-items-center justify-content-center">
						<button
							className="ml-10px text-muted cursor-pointer bg-transparent border-0"
							onClick={handleMenuOpen}
						>
							<MoreVert />
						</button>

						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={!!anchorEl}
							onClose={handleMenuClose}
						>
							{
								request.status !== Constants.RequestStatus.IN_PROGRESS && (
									<MenuItem onClick={onInProgressRequest}>
										<ListItemIcon className={classes.requestMenuOption}>
											<SettingsIcon fill="#757575" size={20} />
										</ListItemIcon>

										<FormattedMessage id="REQUEST.ACTION.MOVE_TO_IN_PROGRESS">
											{
												(text) => (
													<ListItemText primary={text} />
												)
											}
										</FormattedMessage>
									</MenuItem>
								)
							}

							{
								request.status !== Constants.RequestStatus.COMPLETED && (
									<MenuItem onClick={onCompleteRequest}>
										<ListItemIcon className={classes.requestMenuOption}>
											<OfflinePin />
										</ListItemIcon>

										<FormattedMessage id="REQUEST.ACTION.MARK_AS_COMPLETED">
											{
												(text) => (
													<ListItemText primary={text} />
												)
											}
										</FormattedMessage>
									</MenuItem>
								)
							}

							{
								request.status !== Constants.RequestStatus.CANCELLED && (
									<MenuItem onClick={onCancelRequest}>
										<ListItemIcon className={classes.requestMenuOption}>
											<NoSim />
										</ListItemIcon>
										<FormattedMessage id="REQUEST.ACTION.MARK_AS_CANCELLED">
											{
												(text) => (
													<ListItemText primary={text} />
												)
											}
										</FormattedMessage>
									</MenuItem>
								)
							}
						</Menu>

						{
							(canArchive || canUnarchive) && (
								<button
									className="cursor-pointer text-muted ml-10px bg-transparent border-0"
									onClick={onArchiveRequest}
								>
									{
										canUnarchive ? (
											<Unarchive fontSize="large" />
										) : (
											<Archive fontSize="large" />
										)
									}
								</button>
							)
						}
					</div>
				</fieldset>
			</CardContent>
		</Card>
	)
}

export default TitleCard;