import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { uuid } from "uuidv4";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Fab, makeStyles, Tabs, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory, Prompt } from "react-router";
import clsx from "clsx";
import { Add, Send, ControlPointOutlined, Launch, DeleteForever, DeleteOutlined } from "@material-ui/icons";

import RequestSettings from "../common/RequestSettings";
import StyledTab from "../common/StyledTab";
import { ProviderSettings } from "../common/ProviderSettings";
import { loadTemplates } from "../../../../store/modules/actions/templates.actions";
import { Template } from "../common/Template";
import { copyTextToClipboard, globalGA, isValidEmail } from "../../../../../_metronic";
import Confirm from "../../../../common/modals/confirm";
import { EnvelopeIcon } from "../../../../common/icons";
import {
	validateElement,
	getEmptyErrorObj,
} from "../../../../common/dnd-element-list/ElementPicker";
import {
	createRequestV2,
	flagNames as requestFlagNames
} from '../../../../store/modules/actions/requests.actions';
import AutoExpandInput from "../../../../common/auto-expand-input";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const MAX_PROVIDERS_LIMIT = 4;

const useStyles = makeStyles((theme) => {
	const {
		layoutSizes: {
			elementListContainer: {
				leftMenuSpace,
				editContainerPadding,
				containerWidth,
				spaceBetweenContainerAddButton,
				addButtonWidth,
				safeSpace,
			}
		}
	} = theme;
	const finalWidth = leftMenuSpace +
		(editContainerPadding * 2) +
		containerWidth +
		spaceBetweenContainerAddButton +
		addButtonWidth +
		safeSpace;

	return {
		container: {
			maxWidth: containerWidth,

			'& .request-settings': {
				width: '100%',
				maxWidth: 323,
			},

			[`@media (min-width: ${finalWidth}px)`]: {
				maxWidth: containerWidth + spaceBetweenContainerAddButton + addButtonWidth,

				'& .provider > div:not(.template-container):not(.request-settings)': {
					maxWidth: containerWidth,
				}
			}
		},
		providerTabs: {
			"& .MuiTabs-flexContainer": {
				columnGap: 15,
			}
		},
		addElementButton: {
			position: "fixed",
			right: 10,
			bottom: 90,

			'& button': {
				background: 'white !important',
				width: addButtonWidth,
			},

			[`@media (min-width: ${finalWidth}px)`]: {
				marginLeft: 15,
				height: "fit-content",
				position: "sticky",
				bottom: 40,
				alignSelf: 'flex-end',
			}
		},
		bottomBar: {
			position: "absolute",
			left: 0,
			right: 0,
		},
	};
});

const DEFAULT_PROVIDER = {
	uuid: uuid(),
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	elements: [],
	bcc: [],
	templateId: "",
	smsNotification: false,
	reminderNotification: true,
	requiredPassword: false,
	dueDate: moment().startOf('day').add(4, 'days').toDate(),
	emailMeta: {
		subject: "",
		content: ""
	}
};

function CreateNewRequest(props) {
	const intl = useIntl();
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [restrictions] = useUserRestriction();

	const providerErrorRef = useRef({});
	const triggerNotificationRef = useRef(false);
	const nextLocation = useRef(null);

	const [wasRequestCreated, setWasRequestCreated] = useState(false);
	const [request, setRequest] = useState({
		uuid: uuid(),
		title: "",
		providers: [
			{
				...DEFAULT_PROVIDER,
			}
		]
	});
	const [showElementPicker, setShowElementPicker] = useState(false);
	const [confirmModalStatus, setConfirmModalStatus] = useState(false);
	const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
	const [showUnsavedWarningModal, setShowUnsavedWarningModal] = useState(false);
	const [deleteProviderIndex, setDeleteProviderIndex] = useState(-1);
	
	const hasSomeData = useMemo(() => {
		const hasProviderChanges = request.providers.some((provider) => !_.isEqual(provider, DEFAULT_PROVIDER));
		return request.title || hasProviderChanges;
	}, [request.providers, request.title]);

	const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

	const flags = useSelector(getFlags);

	useEffect(() => {
		if (restrictions && !restrictions.features.FORM_REQUEST) {
			history.push('/requests');
		}
	}, [restrictions, history]);

	useEffect(() => {
		document.body.classList.add("modern-input-container");

		return () => {
			document.body.classList.remove("modern-input-container");
		}
	}, []);

	useEffect(() => {
		dispatch(loadTemplates());
		globalGA("request_creator_launched", {
			eventAction: "success",
			eventCategory: "request_creation_C1"
		});
	}, [dispatch]);

	useEffect(() => {
		if (wasRequestCreated) {
			setTimeout(() => {
				history.push('/requests');
			}, 300);
		}
	}, [wasRequestCreated, history]);

	const validate = useCallback((provider) => {
		const providerError = {};

		if (!provider.firstName) {
			providerError.firstName = intl.formatMessage({
				id: "PROVIDER.FIRST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.lastName) {
			providerError.lastName = intl.formatMessage({
				id: "PROVIDER.LAST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.email) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.REQUIRED"
			});
		} else if (!isValidEmail(provider.email)) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.INVALID"
			});
		}
		if (provider.dueDate) {
			if (moment(provider.dueDate).isBefore(moment().startOf('day'))) {
				providerError.dueDate = intl.formatMessage({
					id: "PROVIDER.DUE_DATE.VALIDATION.IN_PAST"
				});
			}
		}
		else {
			providerError.dueDate = intl.formatMessage({
				id: "PROVIDER.DUE_DATE.VALIDATION.REQUIRED"
			});
		}

		if (!provider.elements.length) {
			providerError.elements = intl.formatMessage({
				id: "PROVIDER.ELEMENTS.VALIDATION.REQUIRED"
			});
		}
		else {
			for (let element of provider.elements) {
				const elementError = validateElement(element);
				const emptyErrorObj = getEmptyErrorObj(element.type);
				const isErroredElement = !_.isEqual(elementError, emptyErrorObj);

				if (isErroredElement) {
					providerError.elements = intl.formatMessage({
						id: "TEMPLATE.TOAST_ERROR.ELEMENTS.ERROR"
					});
					break;
				}
			}
		}
		
		const hasError = !_.isEmpty(providerError);

		providerErrorRef.current[selectedProviderIndex.toString()] = hasError;

		const providers = request.providers.map((p, index) => {
			return index === selectedProviderIndex ? provider : p
		});

		setRequest({
			...request,
			providers,
		});

		return providerError;
	}, [
		intl,
		request,
		selectedProviderIndex,
	]);

	const addNewProvider = useCallback(() => {
		const newProvider = {
			...DEFAULT_PROVIDER,
			uuid: uuid(),
		};
		const indexToSwitchTo = request.providers.length;

		setRequest({
			...request,
			providers: [
				...request.providers,
				newProvider,
			],
		});

		setSelectedProviderIndex(indexToSwitchTo);
	}, [
		request,
	]);

	const handleCreateSubmit = useCallback(() => {
		const toastErrors = [];

		request.providers.forEach((provider, index) => {
			if (providerErrorRef.current[index.toString()]) {
				const providerName = `${provider.firstName} ${provider.lastName}`.trim() || `Document Provider ${index + 1}`;
				const errorMessage = intl.formatMessage({
					id: "REQUEST.CREATE.PROVIDER.VALIDATION_FAILED"
				}, {
					providerName,
				});
				toastErrors.push(errorMessage);
			}

			if (!provider.elements.length) {
				const providerName = `${provider.firstName} ${provider.lastName}`.trim() || `Document Provider ${index + 1}`;
				const errorMessage = intl.formatMessage({
					id: "REQUEST.CREATE.PROVIDER.NO_ELEMENTS_ERRORS"
				}, {
					providerName,
				});
				toastErrors.push(errorMessage);
			}
		});

		if (toastErrors.length) {
			toastErrors.forEach((errorMessage) => {
				const options = {
					variant: 'error',
					autoHideDuration: 5000
				};
				enqueueSnackbar(errorMessage, options);
			});
		}
		else {
			setConfirmModalStatus(true);
		}
	}, [
		intl,
		enqueueSnackbar,
		request,
	]);

	const confirmModalHandleClose = useCallback(async (isSuccess) => {
		if (isSuccess) {
			const providerData = request.providers.map((provider) => {
				if (!provider.templateId) {
					delete provider.templateId;
				}

				return {
					...provider,
				};
			});

			const {
				data: {
					data,
				}
			} = await dispatch(
				createRequestV2({
					title: request.title,
					uuid: request.uuid,
					data: providerData,
					triggerNotification: triggerNotificationRef.current,
				}),
			);

			try {
				const link = `${window.location.origin}/provider/${data._id}/${data.documentProviders[0]._id}`;
				await copyTextToClipboard(link);
				const msg = intl.formatMessage({ id: "REQUEST.MARK_AS_SENT.COPY_SUCCESS" });
				enqueueSnackbar(msg, { variant: 'success' });
				setWasRequestCreated(true);
			}
			catch (e) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		}
		else {
			setConfirmModalStatus(false);
		}
	}, [intl, enqueueSnackbar, dispatch, request]);

	const handleBlockedNavigation = useCallback((newLocation) => {
		if (hasSomeData) {
			nextLocation.current = newLocation;
			setShowUnsavedWarningModal(true);
			return false;
		}

		return true;
	}, [hasSomeData]);

	const handleUnsavedModalClose = useCallback(async (shouldLeave) => {
		if (shouldLeave) {
			history.push(nextLocation.current.pathname);
		}

		setShowUnsavedWarningModal(false);
	}, [history]);

	const handleDeleteProviderClose = useCallback((wasSubmitted) => {
		if (wasSubmitted) {
			const selectedProviderUUID = request.providers[deleteProviderIndex].uuid;
			const newProviders = request.providers.filter((provider, index) => index !== deleteProviderIndex);
			let newIndex = newProviders.findIndex(({ uuid }) => uuid === selectedProviderUUID);

			if (newIndex === -1) {
				newIndex = newProviders.length - 1;
			}

			setSelectedProviderIndex(newIndex);
			setRequest({
				...request,
				providers: newProviders,
			});
		}

		setDeleteProviderIndex(-1);
	}, [deleteProviderIndex, request]);

	return (
		<div className={`modern-input-container ${classes.container}`}>
			
			<div className="row">
				<div className="col-12">
					<AutoExpandInput
						className="f-24px font-weight-medium text-dark mr-2"
						name="title"
						onChange={(e) => setRequest({
							...request,
							title: e.target.value,
						})}
						value={request.title}
						placeholder={
							intl.formatMessage({
								id: 'REQUEST.CREATE.TITLE'
							})
						}
					/>
				</div>
			</div>

			<div className="mb-10px d-flex align-items-center">
				<Tabs
					className={classes.providerTabs}
					value={selectedProviderIndex}
					onChange={(e, index) => setSelectedProviderIndex(index)}
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					classes={{
						flexContainer: "mt-1",
						indicator: "d-none",
					}}
				>
					{
						request.providers.map((provider, providerIndex, arr) => {
							const tabTitle = `${provider.firstName} ${provider.lastName}`;
							const hasError = providerErrorRef.current[providerIndex.toString()];
							const canDelete = arr.length > 1;

							return (
								<StyledTab
									label={tabTitle}
									autoCapitalize="false"
									isSelected={providerIndex === selectedProviderIndex}
									hasError={hasError}
									actionIcon={
										canDelete && (
											<div className="text-danger">
												<DeleteForever />
											</div>
										)
									}
									onActionClick={() => setDeleteProviderIndex(providerIndex)}
									classes={{
										root: "f-16px font-weight-regular px-15px py-10px",
									}}
								/>
							);
						})
					}
				</Tabs>

				{
					request.providers.length < MAX_PROVIDERS_LIMIT && (
						<div className="ml-20px">
							<Fab
								className="bg-white"
								size="small"
								onClick={addNewProvider}
							>
								<Add />
							</Fab>
						</div>
					)
				}
			</div>

			<Formik
				initialValues={request.providers[selectedProviderIndex]}
				enableReinitialize
				validate={validate}
				validateOnChange={false}
				validateOnBlur={true}
			>
				{({
					values
				}) => {
					return (
						<div className="provider">
							<div className="request-settings mb-20px col-12 col-md-6 px-0">
								<RequestSettings
									keyPrefix=""
									isInCreateMode
								/>
							</div>

							<div className="provider-settings mb-20px">
								<ProviderSettings
									forceUpdateTrigger={selectedProviderIndex}
									isRequestDisabled={false}
								/>
							</div>

							<div className="d-flex template-container">
								<div className="flex-grow-1">
									<div>
										<Template
											additionalData={{
												requestUUID: request.uuid,
												providerUUID: values.uuid,
												fileUpload: {
													preventFileUpload: true,
												},
												hideStatus: true,
												hideApprove: true,
												hideReject: true,
												hideComment: true,
												hideDownloadAllFiles: true,
											}}
											keyPrefix=""
											isRequestDisabled={false}
											showElementPicker={showElementPicker}
											onElementPickerClose={() => setShowElementPicker(false)}
										/>
									</div>

									<div className="p-20px mt-20px bg-white d-flex flex-column flex-md-row justify-content-between align-items-center">
										<div className={clsx("d-flex flex-column flex-md-row", { "w-100": isDownMd })}>
											<div className="mr-0 mr-md-2 mb-3 mb-md-0">
												<button
													type="button"
													onClick={() => {
														triggerNotificationRef.current = true;
														handleCreateSubmit();
													}}
													className={clsx("btn btn-success px-25px py-15px f-16px", { "btn-block": isDownMd })}
												>
													<div className="d-flex align-items-center justify-content-md-center justify-content-sm-between">
														<div className="mr-10px font-weight-bold">
															<FormattedMessage id="REQUEST.SEND_REQUEST" />
														</div>
														<div>
															<Send />
														</div>
													</div>
												</button>
											</div>
										</div>

										<div
											className="text-decoration-underline cursor-pointer text-success mt-3 mt-md-0 f-16px"
											onClick={() => {
												triggerNotificationRef.current = false;
												handleCreateSubmit();
											}}
										>
											<span>
												<FormattedMessage id="REQUESTS.CREATE.MARK_AS_SENT" />
											</span>
										</div>
									</div>
								</div>

								<div className={classes.addElementButton}>
									<button
										className="btn-elevate d-flex btn btn-light pl-2 f-16px px-20px"
										onClick={() => setShowElementPicker(true)}
									>
										<div className="mr-2">
											<ControlPointOutlined fontSize="large" />
										</div>
										<div>
											<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT.ADD_ELEMENT" />
										</div>
									</button>
								</div>
							</div>
						</div>
					)
				}}
			</Formik>

			<Confirm
				open={confirmModalStatus}
				icon={<EnvelopeIcon />}
				variant="primary"
				handleClose={(isSuccess) => confirmModalHandleClose(isSuccess)}
				title={triggerNotificationRef.current ? "REQUEST.CREATE_V2.CONFIRM" : "REQUEST.CREATE_V2.MARK_AS_SENT.CONFIRM"}
				submitButtonText="GENERAL.YES"
				cancelButtonText="GENERAL.NO"
				loading={flags.loading[requestFlagNames.CREATE]}
				error={flags.error[requestFlagNames.CREATE]}
			/>

			<Prompt
				when={hasSomeData && !showUnsavedWarningModal && !wasRequestCreated}
				message={handleBlockedNavigation}
			/>

			<Confirm
				open={showUnsavedWarningModal}
				icon={<Launch style={{ fontSize: 56 }} color="primary" />}
				variant="primary"
				handleClose={handleUnsavedModalClose}
				title="REQUEST.CREATE_V2.CONFIRM_UNSAVED_CHANGES.TITLE"
				submitButtonText="REQUEST.CREATE_V2.CONFIRM_UNSAVED_CHANGES.CONFIRM"
				cancelButtonText="GENERAL.CANCEL"
			/>

			{
				deleteProviderIndex > -1 && (
					<Confirm
						open
						icon={<DeleteOutlined color="error" style={{ fontSize: "4rem" }} />}
						variant="danger"
						handleClose={handleDeleteProviderClose}
						title="REQUEST.PROVIDER.REMOVE.CONFIRM.TITLE"
						titleValues={{ providerName: `${request.providers[deleteProviderIndex].firstName} ${request.providers[deleteProviderIndex].lastName}` }}
						submitButtonText="REQUEST.PROVIDER.REMOVE.CONFIRM.SUBMIT"
						cancelButtonText="GENERAL.CANCEL"
					/>
				)
			}
		</div>
	)
};

export default CreateNewRequest;
