import React from "react";
import moment from "moment";
import {
	FormattedMessage, useIntl,
} from "react-intl";
import {
	makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { useCallback } from "react";
import { managePayment } from "../../../crud/auth.crud";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => {
	return {
		managePaymentButton: {
			background: "#EBEFFF",
			borderRadius: 4,
			padding: "10px 15px",
			color: theme.palette.primary.main
		}
	}
});

function PaymentInfo({ billingData }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const intl = useIntl();

	const handleManagePayment = useCallback(async () => {
		try {
			const {
				data: {
					url
				}
			} = await managePayment();

			window.open(url, "_blank");
		}
		catch (e) {
			console.log(e);
			const message = intl.formatMessage({ id: "GENERAL.ERROR" });
			enqueueSnackbar(message, {
				variant: 'error',
				autoHideDuration: 5000
			});
		}
	}, [
		enqueueSnackbar,
		intl,
	]);

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-20px text-dark">
				<FormattedMessage id="BILLING.PAYMENT_INFO.TITLE" />
			</div>

			<div className="f-20px fw-400 text-dark">
				{`$${billingData.PAYMENT_INFO.amount}/${billingData.PAYMENT_INFO.type === "MONTHLY" ? "mo" : "yr"}`}
			</div>

			<div className="f-15px text-dark fw-300">
				<FormattedMessage
					id={
						billingData.PLAN_INFO && billingData.PLAN_INFO.status !== "CANCELLED_ON_BILLING" ?
							"BILLING.PAYMENT_INFO.NEXT_PAYMENT_MESSAGE" :
							"BILLING.PAYMENT_INFO.CANCELLED_SUBSCRIPTION_MESSAGE"
					}
					values={{
						dateString: moment(billingData.PAYMENT_INFO.nextPaymentDate).format("MMM DD, YYYY")
					}}
				/>
			</div>

			<div className="mt-30px mb-10px d-flex">
				<div
					className={clsx(classes.managePaymentButton, "cursor-pointer")}
					onClick={handleManagePayment}
				>
					<FormattedMessage id="BILLING.PAYMENT_INFO.MANAGE_PAYMENT" />
				</div>
			</div>
		</div>
	);
}

export default PaymentInfo;
