import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import IOSSwitch from "../../../common/IOSSwitch";
import Constants from "../../../common/constants";
import {
  activateConfig,
  flagNames,
  regenerateConfig,
  updateConfig,
} from "../../../store/modules/actions/integrations.actions";
import { copyTextToClipboard } from "../../../../_metronic";
import { getFlags } from "../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
  return {
    fieldSubtitle: {
      fontSize: 13,
      color: "#C3C9D5",
    },
  };
});

function ZapierIntegration(props) {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isRegenerating, setIsRegenerating] = useState();

  const flags = useSelector(getFlags);
  const integration = useSelector((state) => {
    const zapierIntegration = state.integrations.list.find(
      ({ slug }) => slug === Constants.IntegrationsType.ZAPIER
    );

    return zapierIntegration;
  });

  const isAdding = useMemo(
    () =>
      flags.loading[flagNames.ADDING_INTEGRATION] &&
      flags.loading[flagNames.ADDING_INTEGRATION] === integration?._id,
    [flags.loading, integration]
  );
  const isUpdating = useMemo(() => {
    const integrationId = _.get(integration, `config._id`);
    return (
      flags.loading[flagNames.UPDATING_INTEGRATION] &&
      flags.loading[flagNames.UPDATING_INTEGRATION] === integrationId
    );
  }, [flags.loading, integration]);
  const isLoading = useMemo(() => isAdding || isUpdating, [
    isAdding,
    isUpdating,
  ]);

  const submit = useCallback(
    (values) => {
      const { isActive, apiKey } = values;
      const obj = {
        apiKey,
      };

      if (integration?.config) {
        dispatch(updateConfig(integration?.config._id, isActive, obj));
      } else {
        dispatch(activateConfig(integration?._id));
      }
    },
    [integration, dispatch]
  );

  const copyApiKeyToClipboard = useCallback(
    (apiKey) => {
      copyTextToClipboard(apiKey)
        .then(() => {
          const msg = intl.formatMessage({
            id: "INTEGRATIONS.ZAPIER.COPY_KEY.SUCCESS",
          });
          enqueueSnackbar(msg, { variant: "success" });
        })
        .catch((err) => {
          const msg = intl.formatMessage({
            id: "INTEGRATIONS.ZAPIER.COPY_KEY.ERROR",
          });
          enqueueSnackbar(msg, { variant: "error" });
          console.log(err);
        });
    },
    [enqueueSnackbar, intl]
  );

  const regenerateKey = useCallback(async () => {
    const integrationId = _.get(integration, `config._id`);

    setIsRegenerating(true);

    const { status, data } = await dispatch(regenerateConfig(integrationId));

    if (status === 200) {
      const apiKey = _.get(data, "data.integrationConfig.configObj.apiKey", "");

      if (apiKey) {
        copyApiKeyToClipboard(apiKey);
      }
    }

    setIsRegenerating(false);
  }, [copyApiKeyToClipboard, dispatch, integration]);

  if (!integration) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
      </div>
    );
  }

  return (
    <div className="mx-20px">
      <div className="mb-3 cursor-pointer" onClick={() => history.goBack()}>
        <ArrowBack style={{ fontSize: 26 }} />
      </div>

      <div className="px-40px py-30px bg-white rounded">
        <Formik
          initialValues={{
            isActive: Boolean(_.get(integration, `config.isActive`, false)),
            apiKey: _.get(integration, `config.configObj.apiKey`, ""),
          }}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={submit}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="f-20px text-dark mr-15px">
                      <FormattedMessage id="INTEGRATIONS.ZAPIER.FIELD.ZAPIER_SERVICE" />
                    </div>
                    <div>
                      <IOSSwitch
                        rootClass={clsx("my-0 mr-2 ml-0", {
                          "opacity-30":
                            flags.loading[
                              flagNames.UPDATE_BANK_STATEMENT_SERVICE
                            ],
                        })}
                        enabledTrackClass="bg-primary"
                        checked={values.isActive}
                        disabled={isLoading}
                        onChange={(e) => {
                          setFieldValue("isActive", !values.isActive);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "mt-10px text-dark f-14px fw-300",
                      classes.fieldSubtitle
                    )}
                  >
                    <FormattedMessage id="INTEGRATIONS.ZAPIER.FIELD.ZAPIER_SERVICE.HINT" />
                  </div>
                </div>

                {values.isActive && (
                  <div className="row mt-20px">
                    <div className="col-12 col-md-8 d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-center justify-content-md-start">
                      <div>
                        <div>
                          <label>
                            <FormattedMessage id="INTEGRATIONS.ZAPIER.FIELD.API_KEY" />
                          </label>
                        </div>
                        <div className="text-dark f-16px">{values.apiKey}</div>
                      </div>

                      <div className="ml-0 ml-md-5 mt-25px text-center d-flex align-items-center">
                        <div className="mr-15px">
                          <span
                            className={clsx(
                              "btn p-10px cursor-pointer bg-light-blue",
                              {
                                "text-primary": !isLoading,
                                "text-muted": isLoading || isRegenerating,
                              }
                            )}
                            onClick={() => copyApiKeyToClipboard(values.apiKey)}
                          >
                            <FormattedMessage id="INTEGRATIONS.ZAPIER.COPY_KEY" />
                          </span>
                        </div>

                        <div>
                          <span
                            className={clsx(
                              "btn p-0 px-md-2 py-md-3 cursor-pointer d-flex align-items-center",
                              {
                                "text-primary": !isLoading,
                                "text-muted": isLoading,
                              }
                            )}
                            onClick={() => !isRegenerating && regenerateKey()}
                          >
                            {
                              isRegenerating && (
                                <div className="d-flex justify-content-center align-items-center mr-3">
                                  <div className="kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--primary" />
                                </div>
                              )
                            }

                            <FormattedMessage id="INTEGRATIONS.ZAPIER.REGENERATE_KEY" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ZapierIntegration;
