import React, { useCallback } from 'react';
import {
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	makeStyles,
	useTheme
} from '@material-ui/core';
import {
	CloseOutlined, Mic, VideoCallOutlined,
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { parseUrl } from 'query-string';

import { ELEMENT_TYPES } from './ElementType';
import {
	LOOM_VIDEO,
	VIMEO_VIDEO,
	YOUTUBE_VIDEO,
} from './ElementType/Video/urlTypes';
import { CUSTOM_OPTION_ID as CHECKBOX_CUSTOM_OPTION_ID } from './ElementType/Checkbox';
import { CUSTOM_OPTION_ID as RADIO_BUTTON_CUSTOM_OPTION_ID } from './ElementType/RadioButton';
import { kaddimIntl } from '../../../_metronic/i18n/intl';
import { getBankStatementUrl } from '../../store/modules/selectors/common.selector';

const useStyles = makeStyles((theme) => {
	return {
		paper: {
			minWidth: 265,
			backgroundColor: theme.palette.dark.primary.main,
			color: theme.palette.dark.primary.contrastText,
		},
		header: {
			backgroundColor: theme.palette.dark.primary.main,
			color: theme.palette.dark.primary.contrastText,
		},
		headerItem: {
			height: 55,
		}
	}
});

export const validateHeading = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.HEADING.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateNote = (element) => {
	const error = {};

	if (!element.content) {
		error.content = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.NOTES.CONTENT.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateLongText = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.LONG_TEXT.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateShortText = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.SHORT_TEXT.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateDropdown = (element) => {
	const error = {}

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.DROPDOWN.TITLE.VALIDATION.EMPTY"
		});
	}

	if (!element.options || element.options.length < 2) {
		error.optionsMain = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.DROPDOWN.CHOICES.VALIDATION.EMPTY"
		});
	}

	error.options = element.options.reduce((p, option, index) => {
		if (!option.title) {
			p[index] = kaddimIntl.intl.formatMessage({
				id: "ELEMENT_LIST.ITEM.DROPDOWN.CHOICES.TITLE.VALIDATION.EMPTY"
			});
		}

		return p;
	}, {});

	return error;
}

export const validateCheckbox = (element) => {
	const error = {}

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.CHECKBOXES.TITLE.VALIDATION.EMPTY"
		});
	}

	if (!element.options || element.options.length < 2) {
		error.optionsMain = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.CHECKBOXES.CHOICES.VALIDATION.EMPTY"
		});
	}

	error.options = element.options.reduce((p, option, index) => {
		if (!option.title && option.id !== CHECKBOX_CUSTOM_OPTION_ID) {
			p[index] = kaddimIntl.intl.formatMessage({
				id: "ELEMENT_LIST.ITEM.CHECKBOXES.CHOICES.TITLE.VALIDATION.EMPTY"
			});
		}

		return p;
	}, {});

	return error;
}

export const validateRadioButton = (element) => {
	const error = {}

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.TITLE.VALIDATION.EMPTY"
		});
	}

	if (!element.options || element.options.length < 2) {
		error.optionsMain = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.CHOICES.VALIDATION.EMPTY"
		});
	}

	error.options = element.options.reduce((p, option, index) => {
		if (!option.title && option.id !== RADIO_BUTTON_CUSTOM_OPTION_ID) {
			p[index] = kaddimIntl.intl.formatMessage({
				id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.CHOICES.TITLE.VALIDATION.EMPTY"
			});
		}

		return p;
	}, {});

	return error;
}

export const validateImage = (element) => {
	const error = {}

	if (!element.attachment || !element.attachment.url) {
		error.image = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.IMAGE.IMAGE.VALIDATION.EMPTY"
		});
	}

	// if (!element.title) {
	// 	error.title = kaddimIntl.intl.formatMessage({
	// 		id: "ELEMENT_LIST.ITEM.IMAGE.TITLE.VALIDATION.EMPTY"
	// 	});
	// }

	return error;
}

export const getVideoConfig = (url) => {
	const youtubeRegex = new RegExp("https://www\\.youtube\\.com/watch\\?v=([A-z0-9-]+)");
	const youtubeShortLinkRegex = new RegExp("https://youtu\\.be/([A-z0-9-]+)");
	const loomRegex = new RegExp("https://www\\.loom\\.com/share/([A-z0-9-]+)");
	const vimeoRegex = new RegExp("https://vimeo.com/([A-z0-9-]+)");

	if (youtubeRegex.test(url)) {
		const {
			query: {
				t,
			},
		} = parseUrl(url);
		const videoId = youtubeRegex.exec(url)[1];
		const config = {
			type: YOUTUBE_VIDEO,
			videoId,
		};

		if (t) {
			config.start = t;
		}

		return config;
	}
	if (youtubeShortLinkRegex.test(url)) {
		const {
			query: {
				t,
			},
		} = parseUrl(url);
		const videoId = youtubeShortLinkRegex.exec(url)[1];
		const config = {
			type: YOUTUBE_VIDEO,
			videoId,
		};

		if (t) {
			config.start = t;
		}

		return config;
	}
	else if (loomRegex.test(url)) {
		const videoId = loomRegex.exec(url)[1];
		return {
			type: LOOM_VIDEO,
			videoId
		}
	}
	else if (vimeoRegex.test(url)) {
		const videoId = vimeoRegex.exec(url)[1];
		return {
			type: VIMEO_VIDEO,
			videoId
		}
	}
	else {
		return {
			type: "",
			videoId: "",
		}
	}
}
export const validateVideo = (element) => {
	const error = {}

	// if (!element.title) {
	// 	error.title = kaddimIntl.intl.formatMessage({
	// 		id: "ELEMENT_LIST.ITEM.VIDEO.TITLE.VALIDATION.EMPTY"
	// 	});
	// }

	if (!element.videoObj || !element.videoObj.url) {
		error.url = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.VIDEO.URL.VALIDATION.INVALID"
		});
	}
	else {
		const {
			type,
		} = getVideoConfig(element.videoObj.url);

		if (!type) {
			error.url = kaddimIntl.intl.formatMessage({
				id: "ELEMENT_LIST.ITEM.VIDEO.URL.VALIDATION.INVALID"
			});
		}
	}

	return error;
}

export const validateBankStatements = (element) => {
	const error = {}

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.IMAGE.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateSharedFiles = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.SHARED_FILES.TITLE.VALIDATION.EMPTY"
		});
	}

	if (!element.attachments || element.attachments.length === 0) {
		error.attachments = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.SHARED_FILES.FILES.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateFileUpload = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.FILE_UPLOAD.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateVideoInput = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.VIDEO_INPUT.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateAudioInput = (element) => {
	const error = {};

	if (!element.title) {
		error.title = kaddimIntl.intl.formatMessage({
			id: "ELEMENT_LIST.ITEM.AUDIO_INPUT.TITLE.VALIDATION.EMPTY"
		});
	}

	return error;
}

export const validateElement = (element) => {
	switch (element.type) {
		case ELEMENT_TYPES.HEADING: {
			return validateHeading(element.dataObj);
		}
		case ELEMENT_TYPES.NOTES: {
			return validateNote(element.dataObj);
		}
		case ELEMENT_TYPES.SHORT_TEXT: {
			return validateShortText(element.dataObj);
		}
		case ELEMENT_TYPES.LONG_TEXT: {
			return validateLongText(element.dataObj);
		}
		case ELEMENT_TYPES.RADIO_BUTTONS: {
			return validateRadioButton(element.dataObj);
		}
		case ELEMENT_TYPES.CHECKBOXES: {
			return validateCheckbox(element.dataObj);
		}
		case ELEMENT_TYPES.DROPDOWN: {
			return validateDropdown(element.dataObj);
		}
		case ELEMENT_TYPES.IMAGE: {
			return validateImage(element.dataObj);
		}
		case ELEMENT_TYPES.VIDEO: {
			return validateVideo(element.dataObj);
		}
		case ELEMENT_TYPES.BANK_STATEMENTS: {
			return validateBankStatements(element.dataObj);
		}
		case ELEMENT_TYPES.SHARED_FILES: {
			return validateSharedFiles(element.dataObj);
		}
		case ELEMENT_TYPES.FILE_UPLOAD: {
			return validateFileUpload(element.dataObj);
		}
		case ELEMENT_TYPES.VIDEO_INPUT: {
			return validateVideoInput(element.dataObj);
		}
		case ELEMENT_TYPES.AUDIO_INPUT: {
			return validateAudioInput(element.dataObj);
		}
		default: {
			return {};
		}
	};
}

export const getEmptyErrorObj = (type) => {
	switch (type) {
		case ELEMENT_TYPES.CHECKBOXES:
		case ELEMENT_TYPES.DROPDOWN:
		case ELEMENT_TYPES.RADIO_BUTTONS: {
			return {
				options: {},
			};
		}
		default: {
			return {};
		}
	};
};

const getDefaultData = (type) => {
	switch (type) {
		case ELEMENT_TYPES.HEADING: {
			return {
				title: '',
			}
		}
		case ELEMENT_TYPES.SHORT_TEXT:
		case ELEMENT_TYPES.LONG_TEXT: {
			return {
				title: '',
				subtitle: '',
				value: '',
			}
		}
		case ELEMENT_TYPES.RADIO_BUTTONS:
		case ELEMENT_TYPES.CHECKBOXES: {
			return {
				title: '',
				subtitle: '',
				options: [
					{
						title: '',
						id: '0',
					},
					{
						title: '',
						id: '1',
					},
				],
			}
		}
		case ELEMENT_TYPES.DROPDOWN: {
			return {
				title: '',
				subtitle: '',
				options: [
					{
						title: '',
						id: '0',
					},
					{
						title: '',
						id: '1',
					},
				],
			}
		}
		case ELEMENT_TYPES.VIDEO: {
			return {
				title: '',
				subtitle: '',
				videoObj: {
					url: '',
					type: '',
					videoId: '',
				}
			}
		}
		case ELEMENT_TYPES.IMAGE: {
			return {
				title: '',
				subtitle: '',
				attachment: {},
			}
		}
		case ELEMENT_TYPES.NOTES: {
			return {
				content: '',
			}
		}
		case ELEMENT_TYPES.SHARED_FILES: {
			return {
				title: '',
				subtitle: '',
				attachments: [],
			}
		}
		case ELEMENT_TYPES.FILE_UPLOAD: {
			return {
				title: '',
				subtitle: '',
				attachments: [],
				fileRename: [],
			}
		}
		case ELEMENT_TYPES.VIDEO_INPUT: {
			return {
				title: '',
				subtitle: '',
				attachments: [],
				fileRename: [],
			}
		}
		case ELEMENT_TYPES.AUDIO_INPUT: {
			return {
				title: '',
				subtitle: '',
				attachments: [],
				fileRename: [],
			}
		}
		case ELEMENT_TYPES.BANK_STATEMENTS: {
			return {
				title: 'Bank Statements',
				subtitle: `The easiest way to retrieve your bank statements is by using the link below - this is secure and fast. <div>1. Click the URL link below</div><div>2. Type your name and agree to the terms and conditions</div><div>3. Select your bank and log in as you normally would to your internet banking</div>`,
				fileRename: [],
			}
		}
		default: {
			return {};
		}
	}
};
const getDefaultResponse = (type) => {
	switch (type) {
		case ELEMENT_TYPES.VIDEO_INPUT:
		case ELEMENT_TYPES.AUDIO_INPUT:
		case ELEMENT_TYPES.FILE_UPLOAD: {
			return {
				files: [],
			}
		}
		case ELEMENT_TYPES.CHECKBOXES: {
			return {
				value: [],
			}
		}
		case ELEMENT_TYPES.BANK_STATEMENTS: {
			return {
				files: [],
			}
		}
		default: {
			return {};
		}
	}
};

function ElementPicker ({ open, handleClose, onAddElement }) {
	const intl = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	
	const canUseBankStatementElement = useSelector(getBankStatementUrl);

	const onSelect = useCallback((elementType) => {
		const data = getDefaultData(elementType);
		const response = getDefaultResponse(elementType);
		onAddElement(elementType, data, response);
		handleClose();
	}, [
		onAddElement,
		handleClose,
	])

	return (
		<>
			<Drawer
				anchor="left"
				open={open}
				classes={{
					paper: classes.paper
				}}
				onClose={handleClose}
			>
				<List>
					<ListItem classes={{ root: classes.headerItem }}>
						<ListItemText
							color="white"
							primary="Add Elements"
						/>
						<ListItemSecondaryAction>
							<IconButton
								edge="end"
								onClick={handleClose}
							>
								<CloseOutlined style={{ color: theme.palette.dark.primary.contrastText }} />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.HEADING)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								title
							</span>
						</ListItemIcon>
						<ListItemText
							color="white"
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.HEADING.TITLE",
									defaultMessage: "Heading"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.SHORT_TEXT)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								short_text
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.SHORT_TEXT.TITLE",
									defaultMessage: "Short Text"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.LONG_TEXT)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								notes
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.LONG_TEXT.TITLE",
									defaultMessage: "Long Text"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.NOTES)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								sticky_note_2
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.NOTES.TITLE",
									defaultMessage: "Notes"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.DROPDOWN)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								list_alt
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.DROPDOWN.TITLE",
									defaultMessage: "Dropdown"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.RADIO_BUTTONS)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								radio_button_checked
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.TITLE",
									defaultMessage: "Single Choice"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.CHECKBOXES)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								check_box
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.MULTI_CHOICE.TITLE",
									defaultMessage: "Multi Choice"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.IMAGE)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								image
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.IMAGE.TITLE",
									defaultMessage: "Image"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.VIDEO)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								smart_display
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.VIDEO.TITLE",
									defaultMessage: "Video"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.SHARED_FILES)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								insert_drive_file
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.SHARED_FILES.TITLE",
									defaultMessage: "Share Files"
								})
							}
						/>
					</ListItem>
					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.FILE_UPLOAD)}
					>
						<ListItemIcon>
							<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
								drive_folder_upload
							</span>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.FILE_UPLOAD.TITLE",
									defaultMessage: "File Upload"
								})
							}
						/>
					</ListItem>

					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.VIDEO_INPUT)}
					>
						<ListItemIcon>
							<VideoCallOutlined
								fontSize="large"
								style={{ color: theme.palette.dark.primary.contrastText }}
							/>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.VIDEO_INPUT.TITLE",
									defaultMessage: "Video Input"
								})
							}
						/>
					</ListItem>

					<ListItem
						button
						onClick={() => onSelect(ELEMENT_TYPES.AUDIO_INPUT)}
					>
						<ListItemIcon>
							<Mic
								fontSize="large"
								style={{ color: theme.palette.dark.primary.contrastText }}
							/>
						</ListItemIcon>
						<ListItemText
							primary={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.AUDIO_INPUT.TITLE",
									defaultMessage: "Audio Input"
								})
							}
						/>
					</ListItem>

					{
						canUseBankStatementElement && (
							<ListItem
								button
								onClick={() => onSelect(ELEMENT_TYPES.BANK_STATEMENTS)}
							>
								<ListItemIcon>
									<span className="material-icons-outlined" style={{ color: theme.palette.dark.primary.contrastText }}>
										account_balance
									</span>
								</ListItemIcon>
								<ListItemText
									primary={
										intl.formatMessage({
											id: "ELEMENT_LIST.ITEM.BANK_STATEMENTS.TITLE",
											defaultMessage: "Bank Statements"
										})
									}
								/>
							</ListItem>
						)
					}
				</List>
			</Drawer>
		</>
	)
}

export default ElementPicker;
