import React, { forwardRef } from 'react';
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

const Heading = forwardRef((props, _ref) => {
	const { data } = props;

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<div className="pr-0 f-32px text-dark font-weight-medium">
					{data.title}
				</div>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}
		</div>
	)
})

export default Heading;
