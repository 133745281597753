import { setStorage, removeStorage } from "../../../_metronic/utils/utils";

import { actionTypes } from "./actions/auth.actions";
// import * as routerHelpers from "../../router/RouterHelpers";

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  restrictions: {
    loadedOn: null,
    data: null,
  }
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { authToken, user } = action.payload;
      setStorage('token', authToken);

      return { authToken, user };
    }

    // case actionTypes.Register: {
    //   const { authToken, user } = action.payload;
    //   setStorage('token', authToken);

    //   return { authToken, user };
    // }

    case actionTypes.Logout: {
      // routerHelpers.forgotLastLocation();
      removeStorage('token');
      return initialAuthState;
    }

    case actionTypes.UserUpdated:
    case actionTypes.UserRequested: {
      const { user } = action.payload;

      return { ...state, user };
    }

    case actionTypes.UpdateUserRestrictions: {
      return {
        ...state,
        restrictions: {
          loadedOn: action.payload ? new Date() : null,
          data: action.payload,
        }
      }
    }

    case  actionTypes.UpdateSettings: {
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...state.user.settings,
            ...action.payload,
          },
        },
      };
    }

    default:
      return state;
  }
}