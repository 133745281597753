import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import ManageAddon from './ManageAddon';

export default function ManageAddonPage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/manage-addon" exact component={ManageAddon} />
			</Switch>
		</Suspense>
	);
}
