import React, { useCallback, useEffect, useState } from 'react';
import useWindowSize from "react-use/lib/useWindowSize";
import ReactConfetti from "react-confetti";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
	return {
		confettiContainer: {
			position: 'fixed',
			top: 0,
			bottom: 0,
		},
	}
})

export function randomInt(min, max) {
	return Math.floor(min + (Math.random() * ((max - min) + 1)))
}

function drawStar(ctx) {
	const numPoints = this.numPoints || randomInt(4, 6)
	this.numPoints = numPoints
	const outerRadius = this.w
	const innerRadius = outerRadius / 2
	ctx.beginPath()
	ctx.moveTo(0, 0 - outerRadius)

	for (let n = 1; n < numPoints * 2; n++) {
		const radius = n % 2 === 0 ? outerRadius : innerRadius
		const x = radius * Math.sin((n * Math.PI) / numPoints)
		const y = -1 * radius * Math.cos((n * Math.PI) / numPoints)
		ctx.lineTo(x, y)
	}
	ctx.fill()
	ctx.closePath()
}

const CONFETTI_PIECES_COUNT = 300;
export default function Confetti ({ show = false }) {
	const classes = useStyles();
	const { width, height } = useWindowSize();
	const [shouldRender, setShouldRender] = useState(false);
	const [pieceCount, setPieceCount] = useState(CONFETTI_PIECES_COUNT);

	useEffect(() => {
		if (show) {
			setShouldRender(true);
			setPieceCount(CONFETTI_PIECES_COUNT);
		}
		else {
			setPieceCount(0);
		}
	}, [show]);

	const onAnimationComplete = useCallback(() => {
		setShouldRender(false);
	}, []);

	if (!shouldRender) {
		return null;
	}

	return (
		<div className={classes.confettiContainer}>
			<ReactConfetti
				numberOfPieces={pieceCount}
				height={height}
				width={width}
				drawShape={drawStar}
				onConfettiComplete={onAnimationComplete}
			/>
		</div>
	)
}