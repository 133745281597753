import React, { useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@material-ui/core";
import { useState } from "react";

const Accordion = withStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none !important',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 0,
    margin: 0,
    padding: 0,
  },
  content: {
    margin: 0,
  },
  expanded: {
    minHeight: '0 !important',
    margin: '0 !important',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

export default function AccordionComponent({
  title,
  expandIcon,
  content,
  onExpandStatusChange,
  AccordionSummaryProps = {},
  AccordionDetailsProps = {},
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (typeof onExpandStatusChange === "function") {
      onExpandStatusChange(isExpanded);
    }
  }, [isExpanded, onExpandStatusChange]);

  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <AccordionSummary
        expandIcon={expandIcon}
        {...AccordionSummaryProps}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails {...AccordionDetailsProps}>
        {content}
      </AccordionDetails>
    </Accordion>
  )
}