import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Icon, useMediaQuery, useTheme, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
	ELEMENT_TYPES,
	Heading,
	ShortText,
	LongText,
	RadioButton,
	Checkbox,
	Video,
	Dropdown,
	Image,
	BankStatement,
	Note,
	SharedFiles,
	FileUpload,
	VideoInput,
	AudioInput,
} from "./ElementType";
import Constants from "../constants";
import {
	performOperation as performProviderOperation,
	V2_OPERATIONS as V2_PROVIDER_OPERATIONS,
} from "../../store/modules/actions/provider.actions";
import { READ_ONLY_TYPES } from "./Element";
import ElementStatus from "./ElementStatus";
import { isUnderReview } from "../../pages/provider/Layout/new";

export const ALLOWED_STATUSES = [
	Constants.DocumentStatus.APPROVED,
	Constants.DocumentStatus.REJECTED,
	Constants.DocumentStatus.NOT_REQUIRED,
];

const useStyles = makeStyles((theme) => {
	return {
		container: {
			// to ensure that when action buttons showup in the UI then readable element's
			// height dont increase
			minHeight: 95,
		},
		actionColumn: {
			minWidth: 175,
		},
		handleStyle: {
			color: theme.palette.primary.main,
			cursor: "move",
		},
		unreadCommentsCount: {
			height: 28,
			width: 28,
			borderRadius: 14,

			position: "absolute",
			top: 0,
			right: 0,
			transform: "translate(50%, -50%)",
		},
		unreadCommentsCountMobile: {
			height: 23,
			width: 23,
			borderRadius: 14,

			position: "absolute",
			top: 0,
			right: 0,
		},
		errorOutline: {
			border: 'none',
			borderRadius: 2,
			boxShadow: `0 0 0 2pt ${theme.palette.danger.main}`,
		}
	}
});

function ReadableComponentDesktopActionButtons({ id, show, status, type, unreadCommentsCount }) {
	const dispatch = useDispatch();
	const classes = useStyles();

	const providerStatus = useSelector(({ provider }) => provider?.data?.status);
	const isRequestUnderReview = useMemo(() => isUnderReview(providerStatus), [providerStatus]);

	return (
		<div>
			<div className="mb-2 position-relative">
				<button
					className={
						clsx(
							"btn btn-block align-items-center shadow",
							{
								"btn-dark d-flex text-light": !!unreadCommentsCount,
								"bg-white d-flex text-dark": !unreadCommentsCount && show,
								"d-none": !show && !unreadCommentsCount,
							}
						)
					}
					onClick={() => dispatch(
						performProviderOperation(
							V2_PROVIDER_OPERATIONS.VIEW_COMMENTS,
							{ uuid: id }
						)
					)}
				>
					<Icon className="mr-2">chat</Icon>
					<span className="f-14px d-none d-md-block">
						<FormattedMessage id="ELEMENT_LIST.ITEM.COMMENTS" />
					</span>
				</button>

				{
					unreadCommentsCount > 0 && (
						<div className={clsx(classes.unreadCommentsCount, "p-2 d-flex justify-content-center align-items-center bg-danger text-light")}>
							{unreadCommentsCount}
						</div>
					)
				}
			</div>

			{
				!READ_ONLY_TYPES.includes(type) &&
				status !== Constants.DocumentStatus.NOT_REQUIRED &&
				status !== Constants.DocumentStatus.APPROVED && (
					<div className="mb-2">
						<button
							className={clsx(
								"btn bg-white btn-block shadow",
								{
									"d-flex align-items-center": show,
									"d-none": !show,
									"btn-disabled": isRequestUnderReview,
								}
							)}
							onClick={() => dispatch(
								performProviderOperation(
									V2_PROVIDER_OPERATIONS.NOT_REQUIRED,
									{ uuid: id }
								)
							)}
						>
							<Icon className="mr-2">block</Icon>
							<span className="f-14px">
								<FormattedMessage id="ELEMENT_LIST.ITEM.NOT_REQUIRED" />
							</span>
						</button>
					</div>
				)
			}
		</div>
	)
}
function ReadableComponentMobileActionButtons({ id, status, type, unreadCommentsCount }) {
	const dispatch = useDispatch();
	const classes = useStyles();

	const providerStatus = useSelector(({ provider }) => provider?.data?.status);
	const isRequestUnderReview = useMemo(() => isUnderReview(providerStatus), [providerStatus]);

	return (
		<div className="d-flex align-items-center flex-grow-1 d-md-none">
			{
				!READ_ONLY_TYPES.includes(type) &&
				status !== Constants.DocumentStatus.NOT_REQUIRED &&
				status !== Constants.DocumentStatus.APPROVED && (
					<div
						className={clsx({
							"text-dark text-decoration-underline cursor-pointer": !isRequestUnderReview,
							"text-muted pointer-events-none": isRequestUnderReview,
						})}
						onClick={() => dispatch(
							performProviderOperation(
								V2_PROVIDER_OPERATIONS.NOT_REQUIRED,
								{ uuid: id }
							)
						)}
					>
						<FormattedMessage id="ELEMENT_LIST.ITEM.NOT_REQUIRED" />
					</div>
				)
			}
			<div className="ml-auto">
				<button
					className={
						clsx(
							"btn d-flex d-md-none align-items-center",
							{
								"btn-dark text-light": unreadCommentsCount > 0,
								"bg-white text-dark": unreadCommentsCount === 0,
							}
						)
					}
					onClick={() => dispatch(
						performProviderOperation(
							V2_PROVIDER_OPERATIONS.VIEW_COMMENTS,
							{ uuid: id }
						)
					)}
				>
					<Icon>chat</Icon>

					{
						unreadCommentsCount > 0 && (
							<div className={clsx(classes.unreadCommentsCountMobile, "p-2 d-flex justify-content-center align-items-center bg-danger text-light")}>
								{unreadCommentsCount}
							</div>
						)
					}
				</button>
			</div>
		</div>
	)
}

function ReadableWrapperComponent({ Component, type, ...otherProps }) {
	const {
		id,
		status,
		unreadCommentsCount,
		markNotRequiredReason,
		rejectedReason,
	} = otherProps;
	const elementRef = useRef();
	const classes = useStyles();
	const theme = useTheme();
	const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const [isActive, setIsActive] = useState(false);

	const providerStatus = useSelector(({ provider }) => provider?.data?.status);

	const isRequestUnderReview = useMemo(() => isUnderReview(providerStatus), [providerStatus])

	const tooltipText = useMemo(() => {
		if (status === Constants.DocumentStatus.NOT_REQUIRED || status === Constants.DocumentStatus.APPROVED) {
			return markNotRequiredReason;
		}
		else if (status === Constants.DocumentStatus.REJECTED) {
			return rejectedReason;
		}
		else {
			return undefined;
		}
	}, [
		status,
		markNotRequiredReason,
		rejectedReason,
	]);

	return (
		<div
			className="d-flex flex-column pointer-events-all"
			onMouseOver={() => !isDownMd && !(elementRef.current?.shouldStopMouseListener()) &&  setIsActive(true)}
			onMouseOut={() => !isDownMd && !(elementRef.current?.shouldStopMouseListener()) && setIsActive(false)}
		>
			<div className="d-flex align-items-center mb-md-2 mb-0">
				{
					ALLOWED_STATUSES.includes(status) && (
						<div className="mr-10px">
							<ElementStatus
								status={status}
								tooltipText={tooltipText}
							/>
						</div>
					)
				}

				<ReadableComponentMobileActionButtons
					id={id}
					type={type}
					status={status}
					unreadCommentsCount={unreadCommentsCount}
				/>
			</div>

			<div className="d-flex flex-grow-1 flex-column flex-md-row">
				<div
					className={
						clsx(
							"flex-grow-1 px-0",
							classes.container,
							{ "pointer-events-none": isRequestUnderReview }
						)
					}
				>
					<Component
						{...otherProps}
						ref={elementRef}
						isActive={isActive}
					/>
				</div>

				<div
					className={
						clsx(
							classes.actionColumn,
							"d-none d-md-flex flex-column px-0 pl-md-2 px-md-0",
						)
					}
				>
					<ReadableComponentDesktopActionButtons
						id={id}
						show={isActive}
						type={type}
						status={status}
						unreadCommentsCount={unreadCommentsCount}
					/>
				</div>
			</div>
		</div>
	)
}


const ReadOnly = ({
	element,
	onChangeResponse,
	onPreview,
	onClick,
	additionalData,
	className = "",
}) => {
	const classes = useStyles();
	const {
		_id: elementId,
		uuid: id,
		type,
		refData,
		dataObj: data,
		responseObj: response,
		unreadCommentsCount,
		status,
		markNotRequiredReason,
		rejectedReason,
	} = element;
	const commonProps = {
		id,
		elementId,
		data,
		refData,
		additionalData,
		onChange: onChangeResponse,
		onPreview,
		response,
		unreadCommentsCount,
		status,
		markNotRequiredReason,
		rejectedReason,
	};
	const {
		elementsWithError = {}
	} = additionalData;
	const hasError = elementsWithError[elementId];

	return (
		<div
			className={clsx(
				"p-3 p-md-4 rounded d-flex position-relative",
				className,
				{
					[classes.errorOutline]: hasError,
				}
			)}
			onClick={onClick}
		>
			<div className="flex-grow-1">
				{
					type === ELEMENT_TYPES.HEADING && (
						<ReadableWrapperComponent
							type={type}
							Component={Heading.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.SHORT_TEXT && (
						<ReadableWrapperComponent
							type={type}
							Component={ShortText.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.LONG_TEXT && (
						<ReadableWrapperComponent
							type={type}
							Component={LongText.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.RADIO_BUTTONS && (
						<ReadableWrapperComponent
							type={type}
							Component={RadioButton.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.CHECKBOXES && (
						<ReadableWrapperComponent
							type={type}
							Component={Checkbox.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.VIDEO && (
						<ReadableWrapperComponent
							type={type}
							Component={Video.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.DROPDOWN && (
						<ReadableWrapperComponent
							type={type}
							Component={Dropdown.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.IMAGE && (
						<ReadableWrapperComponent
							type={type}
							Component={Image.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.BANK_STATEMENTS && (
						<ReadableWrapperComponent
							type={type}
							Component={BankStatement.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.NOTES && (
						<ReadableWrapperComponent
							type={type}
							Component={Note.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.SHARED_FILES && (
						<ReadableWrapperComponent
							type={type}
							Component={SharedFiles.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.FILE_UPLOAD && (
						<ReadableWrapperComponent
							type={type}
							Component={FileUpload.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.VIDEO_INPUT && (
						<ReadableWrapperComponent
							type={type}
							Component={VideoInput.ReadOnly}
							{...commonProps}
						/>
					)
				}
				{
					type === ELEMENT_TYPES.AUDIO_INPUT && (
						<ReadableWrapperComponent
							type={type}
							Component={AudioInput.ReadOnly}
							{...commonProps}
						/>
					)
				}
			</div>
		</div>
	);
}

export default ReadOnly;
