import { ClickAwayListener, makeStyles, Popper, useMediaQuery } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";

import List from '../../../../../common/dnd-element-list/List';
import DocumentSelector from "../../../../../common/document-selector";
import { performOperation, V2_OPERATIONS } from "../../../../../store/modules/actions/templates.actions";
import DeleteElement from "../../../Requests/Edit/DeleteElement";

const DOCUMENT_SELECTOR_MEDIA_QUERY = "(min-width:550px)";

const useStyles = makeStyles((theme) => {
	return {
		templatePickerTrigger: {
			width: "100%",
			background: theme.palette.extraColors.backgroundBlue,
			maxWidth: theme.elementSizes.templatePicker.maxWidth,
		},
		documentSelector: {
			// leave some space around the sides for better UI
			width: window.screen.availWidth - 50,

			// if screen is wide enough then lock selector card at 450px width
			[`@media ${DOCUMENT_SELECTOR_MEDIA_QUERY}`]: {
				width: 450,
			}
		},
	}
});

export function ElementList({
	showElementPicker,
	handleElementPickerClose,
	additionalData = {},
}) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const {
		values,
		setFieldValue,
		setFieldTouched,
		// errors,
	} = useFormikContext();
	const [documentSelectorAnchorEl, setDocumentSelectorAnchorEl] = useState(null);
	const isWideEnoughForDocumentSelector = useMediaQuery(DOCUMENT_SELECTOR_MEDIA_QUERY);
	const data = values;
	// const error = errors;

	const updateField = useCallback((field, value) => {
		const key = field;
		setFieldTouched(key);
		setFieldValue(key, value);
	}, [
		setFieldTouched,
		setFieldValue,
	]);

	const elementToDelete = useSelector((state) => {
		const {
			requests: {
				operation,
			}
		} = state;
		if (operation.action !== V2_OPERATIONS.DELETE_ELEMENT) {
			return null;
		}

		const elementToDelete = data.elements.find((element) => {
			return element.uuid === operation?.data?.uuid;
		});

		return elementToDelete;
	});

	const handleElementDelete = useCallback((elementUUID) => {
		const newElements = data.elements.filter(
			(element) => element.uuid !== elementUUID
		);

		updateField('elements', newElements)
	}, [data.elements, updateField]);

	return (
		<div className="p-20px rounded bg-light-blue">
			<div className="f-14px font-weight-medium text-dark">
				<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT" />
			</div>

			<div className="d-flex">
				<div className="flex-grow-1">
					<div
						className={clsx(
							classes.templatePickerTrigger,
							"mt-15px cursor-pointer rounded px-15px py-10px d-flex justify-content-between align-items-center"
						)}
						onClick={(e) => !documentSelectorAnchorEl && setDocumentSelectorAnchorEl(e.currentTarget)}
					>
						<div className="f-16px">
							<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT.COPY_TEMPLATE" />
						</div>
						<div className="text-primary">
							{
								documentSelectorAnchorEl ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)
							}
						</div>
					</div>

					<Popper
						open={!!documentSelectorAnchorEl}
						anchorEl={documentSelectorAnchorEl}
						placement={isWideEnoughForDocumentSelector ? "bottom-start" : "bottom"}
						transition
					>
						<ClickAwayListener onClickAway={() => setDocumentSelectorAnchorEl(null)}>
							<div className="mt-3"> {/* wrapped in div because DocumentSelector is a functional component and click away listener can't work directly with functional components */}
								<DocumentSelector
									className={classes.documentSelector}
									classes={{
										templatesListContainer: classes.templatesListContainer,
										templateListItem: isWideEnoughForDocumentSelector ? undefined : "align-items-start",
										templateListItemTitle: isWideEnoughForDocumentSelector ? undefined : "flex-column",
										templateListItemSelectedPill: isWideEnoughForDocumentSelector ? undefined : "mt-5px",
									}}
									showModern
									onSubmit={({ elements, emailMeta, bcc, selectedTemplateId }) => {
										const currentEmailMeta = data.emailMeta;
										const existingElements = data.elements;
										const currentBcc = data.bcc;

										if (!currentEmailMeta.subject && !currentEmailMeta.content && selectedTemplateId) {
											setFieldValue(`emailMeta`, emailMeta);
										}
										if (currentBcc.length === 0 && bcc.length > 0) {
											setFieldValue(`bcc`, bcc);
										}

										setFieldValue(`elements`, [
											...existingElements,
											...elements
										]);
										
										setDocumentSelectorAnchorEl(null);
										// globalGA("add_document_via_template", {
										// 	eventAction: "success",
										// 	eventCategory: "request_creation_C1"
										// });
									}}
								/>
							</div>
						</ClickAwayListener>
					</Popper>
				</div>
			</div>

			<List
				elements={data.elements}
				handleChange={(elements) => updateField('elements', elements)}
				handlePreview={(file) => dispatch(performOperation(V2_OPERATIONS.PREVIEW, file))}

				showElementPicker={showElementPicker}
				handleElementPickerClose={handleElementPickerClose}

				additionalData={{
					hideStatus: true,
					hideApprove: true,
					hideReject: true,
					hideComment: true,
					hideDownloadAllFiles: true,

					templateUUID: data.uuid,

					...additionalData,
				}}
			/>

			{
				!!elementToDelete && (
					<DeleteElement
						onSubmit={() => handleElementDelete(elementToDelete.uuid)}
					/>
				)
			}
		</div>
	)
}