import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import List from '../../../../common/dnd-element-list/List';
import ErrorMessage from '../../../../common/dnd-element-list/ElementType/ErrorMessage';
import { performOperation, V2_OPERATIONS } from "../../../../store/modules/actions/requests.actions";
import AcceptElement from "../Edit/AcceptElement";
import ElementComments from "../Edit/ElementComments";
import RejectElement from "../Edit/RejectElement";
import DeleteElement from "../Edit/DeleteElement";
import { getRequestOperation } from "../../../../store/modules/selectors/request.selector";

export function ElementList({
	className,
	keyPrefix,
	pathToTemplate = '',
	isRequestDisabled,
	additionalData = {},
	showElementPicker,
	onElementPickerClose,
}) {
	const dispatch = useDispatch();
	const requestUUID = useSelector((state) => state.requests.selectedRequest && state.requests.selectedRequest.uuid);
	const {
		values,
		errors,
		setFieldValue,
		setFieldTouched,
	} = useFormikContext();
	const data = _.get(values, keyPrefix, values);
	const providerUUID = values.uuid;

	const operation = useSelector(getRequestOperation);
	const elementToAccept = useMemo(() => {
		if (operation.action !== V2_OPERATIONS.APPROVE) {
			return null;
		}

		const element = data.elements.find((element) => {
			return element.documentProviderId === values._id && element.uuid === operation?.data?.uuid;
		});

		return element;
	}, [data.elements, operation, values._id]);

	const elementToReject = useMemo(() => {
		if (operation.action !== V2_OPERATIONS.REJECT) {
			return null;
		}

		const element = data.elements.find((element) => {
			return element.documentProviderId === values._id && element.uuid === operation?.data?.uuid;
		});

		return element;
	}, [data.elements, operation, values._id]);
	const elementToViewCommentsFor = useMemo(() => {
		if (operation.action !== V2_OPERATIONS.VIEW_COMMENTS) {
			return null;
		}

		const element = data.elements.find((element) => {
			return element.documentProviderId === values._id && element.uuid === operation?.data?.uuid;
		});

		return element;
	}, [data.elements, operation, values._id]);

	const elementToDelete = useMemo(() => {
		if (operation.action !== V2_OPERATIONS.DELETE_ELEMENT) {
			return null;
		}

		// no need to check for documentProviderId as user might want to delete element before it is saved 
		// and if that's the case then element will not have documentProviderId in it
		const element = data.elements.find((element) => {
			return element.uuid === operation?.data?.uuid;
		});

		return element;
	}, [data.elements, operation]);
	
	const updateField = useCallback((field, value) => {
		const key = `${keyPrefix}${keyPrefix ? '.' : ''}${field}`;
		setFieldValue(key, value, true);
		setFieldTouched(key, true, false);
	}, [
		setFieldTouched,
		setFieldValue,
		keyPrefix,
	]);

	const handleElementChange = useCallback((elements) => {
		updateField('elements', elements);
	}, [ updateField ]);

	const handleElementDelete = useCallback((elementUUID) => {
		const newElements = data.elements.filter(
			(element) => element.uuid !== elementUUID
		);

		handleElementChange(newElements);
	}, [data.elements, handleElementChange]);

	return (
		<div className={className}>
			<div className="f-14px font-weight-medium text-dark">
				<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT" />
			</div>

			{
				typeof errors.elements === 'string' && (
					<ErrorMessage
						className="mt-2"
						message={errors.elements}
					/>
				)
			}

			<List
				key={providerUUID}
				refreshId={providerUUID}
				elements={data.elements}
				handleChange={handleElementChange}
				handlePreview={(file) => dispatch(performOperation(V2_OPERATIONS.PREVIEW, file))}

				showElementPicker={showElementPicker}
				handleElementPickerClose={onElementPickerClose}

				additionalData={{
					providerUUID,
					requestUUID,
					showOptions: true,
					...additionalData,
				}}
			/>

			{
				!!elementToReject && (
					<RejectElement
						providerUUID={providerUUID}
						element={elementToReject}
					/>
				)
			}

			{
				!!elementToAccept && (
					<AcceptElement
						providerUUID={providerUUID}
						element={elementToAccept}
					/>
				)
			}

			{
				!!elementToDelete && (
					<DeleteElement
						onSubmit={() => handleElementDelete(elementToDelete.uuid)}
					/>
				)
			}

			{
				!!elementToViewCommentsFor && (
					<ElementComments element={elementToViewCommentsFor} />
				)
			}
		</div>
	)
}