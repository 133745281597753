import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { FormLabel, ClickAwayListener, FormControlLabel, makeStyles, Popper, useMediaQuery, InputBase } from "@material-ui/core";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import MuiPhoneNumber from "material-ui-phone-number";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { KeyboardArrowDown, KeyboardArrowUp, ArrowDropDown, Link, Autorenew } from "@material-ui/icons";
import { Collapse } from "reactstrap";
import clsx from "clsx";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { useSelector } from "react-redux";

import Input from '../../../../common/Input';
import LightTooltip from "../../../../common/LightTooltip";
import { ChatIcon } from "../../../../common/icons";
import IOSSwitch from "../../../../common/IOSSwitch";
import DocumentSelector from "../../../../common/document-selector";
import Constants from "../../../../common/constants";
import {
	PrimaryBorderLinearProgress,
	SecondaryBorderLinearProgress,
	SuccessBorderLinearProgress
} from "../../../../common/LinearProgress";
import { useSnackbar } from "notistack";
import { copyTextToClipboard, globalGA } from "../../../../../_metronic";
import Confirm from "../../../../common/modals/confirm";
import { resendRequest } from "../../../../crud/requests.crud";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { getUser } from "../../../../store/modules/selectors/account.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";
import { getTemplatesList } from "../../../../store/modules/selectors/template.selector";

const DOCUMENT_SELECTOR_MEDIA_QUERY = "(min-width:550px)";

const useStyles = makeStyles((theme) => {
	return {
		emailAccordionHeader: {
			background: theme.palette.extraColors.blueSecondary,
			boxShadow: '0px 8px 8px rgba(229, 229, 229, 0.65)',
		},
		emailAccordionBody: {
			boxShadow: '0px 8px 8px rgba(229, 229, 229, 0.42)',
		},
		documentSelector: {
			// leave some space around the sides for better UI
			width: window.screen.availWidth - 50,

			// if screen is wide enough then lock selector card at 450px width
			[`@media ${DOCUMENT_SELECTOR_MEDIA_QUERY}`]: {
				width: 450,
			}
		},
		templatesListContainer: {
			maxHeight: 200,
			overflowY: "auto"
		},
		templateControlCaret: {
			position: "absolute",
			right: 10,
		},
		emailTemplateListContainer: {
			position: "relative",
			height: 195,
		},
		emailTemplateList: {
			position: 'absolute',
			overflowY: 'scroll',
			height: 180,
			left: 0,
			right: 0,
			paddingLeft: 15,
			direction: "rtl",

			'&::-webkit-scrollbar': {
				width: 5,
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				borderRadius: 5,
				background: 'rgba(224, 231, 255, 0.2)'
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: theme.palette.primary.main,
				borderRadius: 15,
			}
		},
		emailTemplateCardContainer: {
			padding: "10px 15px",
		},
		emailTemplateCardTitleLabel: {
			// opacity: 0.5,
			fontSize: 11,
		},
		emailTemplateCardContent: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			"-webkit-line-clamp": 4,
			"-webkit-box-orient": "vertical",
		},
		selectedEmailTemplateCardContainer: {
			background: theme.palette.primary.main,
		},
		progressBar: {
			minWidth: 175
		},
		cancelledRequest: {
			color: "#C8C8C8"
		},
		providerOperationButtonMobile: {
			height: 24,
			width: 24,
			borderRadius: 12,
		},
		copyLinkButton: {
			border: `1px solid ${theme.palette.dark.primary.main}`,
			boxShadow: '0px 8px 8px rgba(229, 229, 229, 0.65)',
		},
		copyLinkButtonIcon: {
			borderRadius: 15,
			padding: 5,
		},
		resendRequestButton: {
			border: `1px solid ${theme.palette.dark.primary.main}`,
		},
		datePickerIcon: {
      "& .MuiSvgIcon-root": {
				fill: theme.palette.dark.primary.main
			},
    },
	}
});

function EmailTemplateCard({ id, template, isSelected, isLast, handleClick, disabled }) {
	const classes = useStyles();

	return (
		<div
			id={id}
			className={clsx(
				classes.emailTemplateCardContainer,
				"rounded border",
				{
					"cursor-pointer": !disabled,
					"pointer-events-none": disabled,
					"mb-10px": !isLast,
					[`selected ${classes.selectedEmailTemplateCardContainer}`]: isSelected
				},
			)}
			onClick={handleClick}
		>
			<div
				className={clsx(
					"mb-5px", 
					classes.emailTemplateCardTitleLabel,
					{ "opacity-50": !isSelected, }
				)}
			>
				<span className={isSelected ? "text-white" : "text-dark"}>
					<FormattedMessage id="REQUEST.PROVIDER.EMAIL_TEMPLATE.LABEL" />
				</span>
				<span className={clsx("ml-2", isSelected ? "text-white" : "text-primary")}>
					{template.name}
				</span>
			</div>

			<div className="mb-10px">
				<span className={clsx("email-subject font-weight-bold f-12px", isSelected ? "text-white" : "text-dark")}>
					{template.emailMeta.subject}
				</span>
			</div>

			<div className={classes.emailTemplateCardContent}>
				<span className={clsx("email-content f-12px", isSelected ? "text-white" : "text-dark")}>
					{template.emailMeta.content}
				</span>
			</div>
		</div>
	)
}

export default function ProviderDetails(props) {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const {
		fieldNamePrefix = '',
		provider,
		handleBlur,
		handleChange,
		setFieldValue,
		touched,
		errors,
		onOpenChat,
		isInCreateMode,
		// setFieldTouched,
	} = props;
	const [documentSelectorAnchorEl, setDocumentSelectorAnchorEl] = useState(null);
	const [restrictions] = useUserRestriction();
	const [isEmailPanelExpanded, setEmailPanelExpanded] = useState(!!isInCreateMode);
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
	const [resendingRequestStatus, setResendingRequestStatus] = useState({
		show: false,
		resending: false,
		error: null
	});
	const [tooltipStatus, setTooltipStatus] = useState({
		smsNotification: false,
		requirePassword: false,
		reminderNotification: false,
	});
	const classes = useStyles();

	const user = useSelector(getUser);
	const request = useSelector(getSelectedRequest);
	const templates = useSelector(getTemplatesList);
	
	const isRequestDisabled = isInCreateMode ? false : request.archived;
	const requestSettings = useMemo(() => {
		const isSmsAllowed = restrictions && restrictions.smsAllowed;

		return [
			{
				label: "REQUEST.PROVIDER.REQUIRE_PASSWORD",
				key: "requirePassword",
				isDisabled: isRequestDisabled,
				value: provider.requirePassword,
				tooltip: "REQUEST.PROVIDER.REQUIRE_PASSWORD_TOOLTIP",
				disabledTooltip: "REQUEST.PROVIDER.REQUIRE_PASSWORD_DISABLED_TOOLTIP",
			},
			{
				label: "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS",
				key: "reminderNotification",
				isDisabled: isRequestDisabled,
				value: provider.reminderNotification,
				tooltip: "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS_TOOLTIP",
				disabledTooltip: "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS_DISABLED_TOOLTIP",
			},
			{
				label: "REQUEST.PROVIDER.NOTIFY_VIA_SMS",
				key: "smsNotification",
				isDisabled: isRequestDisabled || !user.smsService || !isSmsAllowed,
				value: (user.smsService && isSmsAllowed) ? provider.smsNotification : false,
				tooltip: "REQUEST.PROVIDER.NOTIFY_VIA_SMS_TOOLTIP",
				disabledTooltip: isSmsAllowed ? "REQUEST.PROVIDER.NOTIFY_VIA_SMS_DISABLED_TOOLTIP" : "REQUEST.PROVIDER.NOTIFY_VIA_SMS_UPGRADE_TOOLTIP",
			},
		];
	}, [
		isRequestDisabled,
		provider,
		user,
		restrictions,
	])

	useEffect(() => {
		if (isInCreateMode && templates.length) {
			// setFieldValue(`${fieldNamePrefix}emailMeta`, templates[0].emailMeta);
			// setSelectedEmailTemplate(templates[0]._id);
		}
	}, [
		templates,
		fieldNamePrefix,
		isInCreateMode,
		setFieldValue
	])

	const handleTooltipToggle = useCallback((key) => {
		const newTooltipStatus = _.reduce(tooltipStatus, (p, value, tooltipKey) => {
			p[tooltipKey] = key === tooltipKey;
			return p;
		}, {});

		setTooltipStatus(newTooltipStatus);
	}, [
		tooltipStatus
	]);

	const closeAllTooltips = useCallback(() => {
		setTooltipStatus({
			smsNotification: false,
			requirePassword: false,
			reminderNotification: false,
		});
	}, [
		setTooltipStatus
	]);

	const copyRequestLink = useCallback(async (event, provider) => {
		try {
			event.stopPropagation();
			event.preventDefault();
			const link = `${window.location.origin}/provider/${request._id}/${provider._id}`;
			await copyTextToClipboard(link)
			const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_SUCCESS" });
			enqueueSnackbar(msg, { variant: 'success' });
		} catch (err) {
			const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_ERROR" });
			enqueueSnackbar(msg, { variant: 'error' });
			console.log(err);
		}
	}, [
		intl,
		enqueueSnackbar,
		request,
	]);

	const handleResendRequestSubmit = useCallback(async (wasSubmitted) => {
		try {
			if (wasSubmitted) {
				setResendingRequestStatus({
					show: true,
					resending: true,
					error: null,
				});

				await resendRequest(request._id, [provider._id]);

				const msg = intl.formatMessage({ id: "REQUEST.RESEND.NOTIFICATION_SUCCESS" }, provider);
				enqueueSnackbar(msg, { variant: 'success' });
			}

			setResendingRequestStatus({
				show: false,
				resending: false,
				error: null,
			});
		} catch (err) {
			setResendingRequestStatus({
				show: false,
				resending: false,
				error: err.message || err.toString(),
			});
		}
	}, [
		intl,
		enqueueSnackbar,
		request,
		provider,
	]);

	const stats = provider.stats;
	const isCancelled = provider.status === Constants.ProviderStatus.CANCELLED;
	const isCompleted = provider.status === Constants.ProviderStatus.COMPLETED;

	// document selector's width is set to 450px so if there's not enough space then fill entire width 
	const isWideEnoughForDocumentSelector = useMediaQuery(DOCUMENT_SELECTOR_MEDIA_QUERY);

	return (
		<div className="row px-0 w-100 m-0 pt-15px pb-5px">

			{
				!!provider._id && (
					<div className="col-12 mb-3 d-flex align-items-center">
						<div className="flex-grow-1">
							<div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
								<div className="mr-3">
									{`${provider.firstName} ${provider.lastName}`}
								</div>

								<div>
									<div className="d-flex align-items-center">
										{
											(isCancelled || isCompleted) ? (
												isCancelled ? (
													<div className="d-flex align-items-center flex-grow-1">
														<div className={clsx("mr-2", classes.progressBar)}>
															<SecondaryBorderLinearProgress
																variant="determinate"
																value={100}
															/>
														</div>
														<div className={clsx("f-12px", classes.cancelledRequest)}>
															<FormattedMessage id={`REQUEST.PROVIDER.STATUS.${provider.status}`} />
														</div>
													</div>
												) : (
													<div className="d-flex align-items-center flex-grow-1">
														<div className={clsx("mr-2", classes.progressBar)}>
															<SuccessBorderLinearProgress
																variant="determinate"
																value={100}
															/>
														</div>
														<div className="text-success f-12px">
															<FormattedMessage id={`REQUEST.PROVIDER.STATUS.${provider.status}`} />
														</div>
													</div>
												)
											) : (
												stats && stats.approved > 0 ? (
													<div className="d-flex align-items-center flex-grow-1">
														<div className={clsx("mr-2", classes.progressBar)}>
															<PrimaryBorderLinearProgress
																variant="determinate"
																value={stats.percentage}
															/>
														</div>
														<div className="d-flex ml-2 align-items-center">
															<div className="d-flex text-primary align-items-end">
																<div className="f-15px font-weight-bold">{stats.approved}</div>
																<div className="f-12px" style={{ marginBottom: 1 }}>/</div>
																<div className="f-12px" style={{ marginBottom: 1 }}>{stats.total}</div>
															</div>
														</div>
													</div>
												) : (
													<div className={clsx(
														"align-items-center flex-grow-1",
														{
															"d-flex": !!provider.emailStatus,
															"d-none": !provider.emailStatus
														}
													)}>
														<div className={clsx("mr-2", classes.progressBar)}>
															<PrimaryBorderLinearProgress
																variant="determinate"
																value={0}
															/>
														</div>
														<div className={clsx("text-muted f-12px", classes.cancelledRequest)}>
															<FormattedMessage id={`GENERAL.EMAIL_STATUS.${provider.emailStatus}`} />
														</div>
													</div>
												)
											)
										}
									</div>
								</div>
							</div>
						</div>

						{
							!isRequestDisabled && (
								<div className="d-flex">
									<div className="resend-request-button mr-3">
										<div
											className={clsx(classes.providerOperationButtonMobile, "cursor-pointer bg-light-blue d-flex justify-content-center align-items-center d-md-none")}
											onClick={() => {
												setResendingRequestStatus({
													show: true,
													resending: false,
													error: null
												})
											}}
										>
											<Autorenew color="primary" fontSize="small" />
										</div>

										<div
											className={clsx(classes.resendRequestButton, "cursor-pointer bg-white d-none justify-content-between rounded py-10px px-15px align-items-center d-md-flex")}
											onClick={() => {
												setResendingRequestStatus({
													show: true,
													resending: false,
													error: null
												})
											}}
										>
											<div className={clsx(classes.resendRequestButtonIcon, "d-flex position-absolute justify-content-center align-items-center")}>
												<Autorenew color="dark" fontSize="small" />
											</div>
											<div className="pl-5 text-dark">
												<FormattedMessage id="REQUEST.RESEND_REQUEST" />
											</div>
										</div>
									</div>
									<div className="copy-link-button">
										<div
											className={clsx(classes.providerOperationButtonMobile, "cursor-pointer bg-light-blue d-flex justify-content-center align-items-center d-md-none")}
											onClick={(e) => copyRequestLink(e, provider)}
										>
											<Link color="dark" fontSize="small" />
										</div>

										<div
											className={clsx(classes.copyLinkButton, "cursor-pointer bg-white d-none justify-content-between rounded py-10px px-15px align-items-center d-md-flex")}
											onClick={(e) => copyRequestLink(e, provider)}
										>
											<div className={clsx(classes.copyLinkButtonIcon, "position-absolute bg-light-blue d-flex justify-content-center align-items-center")}>
												<Link color="dark" fontSize="small" />
											</div>
											<div className="pl-5 text-dark">
												<FormattedMessage id="REQUEST.PROVIDER.COPY_LINK" />
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>
				)
			}

			<div className="col-md-4 col-12">
				<Input
					containerClass="mb-20px"
					labelId="REQUEST.PROVIDER.FIRST_NAME"
					type="text"
					variant="outlined"
					fullWidth
					name={`${fieldNamePrefix}firstName`}
					onBlur={handleBlur}
					onChange={handleChange}
					disabled={isRequestDisabled}
					value={provider.firstName}
					helperText={_.get(touched, `firstName`) && _.get(errors, `firstName`)}
					error={Boolean(_.get(touched, `firstName`) && _.get(errors, `firstName`))}
				/>
			</div>

			<div className="col-md-4 col-12">
				<Input
					labelId="REQUEST.PROVIDER.LAST_NAME"
					containerClass="mb-20px"
					type="text"
					variant="outlined"
					fullWidth
					name={`${fieldNamePrefix}lastName`}
					onBlur={handleBlur}
					onChange={handleChange}
					disabled={isRequestDisabled}
					value={provider.lastName}
					helperText={touched.lastName && errors.lastName}
					error={Boolean(touched.lastName && errors.lastName)}
				/>
			</div>

			<div className="col-md-4 col-12">
				<Input
					labelId="REQUEST.PROVIDER.EMAIL"
					containerClass="mb-20px"
					type="email"
					variant="outlined"
					fullWidth
					name={`${fieldNamePrefix}email`}
					onBlur={handleBlur}
					onChange={handleChange}
					disabled={isRequestDisabled}
					value={provider.email}
					helperText={touched.email && errors.email}
					error={Boolean(touched.email && errors.email)}
				/>
			</div>

			<div className="col-md-4 col-12">
				<div className={`dark-custom-input input-phone form-group mb-20px`}>
					<FormLabel className="mb-10">
						<FormattedMessage id="REQUEST.PROVIDER.PHONE" />
					</FormLabel>
					<MuiPhoneNumber
						enableLongNumbers
						disableAreaCodes
						defaultCountry="au"
						variant="outlined"
						type="text"
						fullWidth
						onBlur={handleBlur}
						onChange={(v) => setFieldValue(`${fieldNamePrefix}phone`, v)}
						disabled={isRequestDisabled}
						value={provider.phone}
						helperText={touched.phone && errors.phone}
						error={Boolean(touched.phone && errors.phone)}
					/>
				</div>
			</div>

			<div className="col-md-4 col-12">
				<div
					className="testing nishant"
					onClick={
						(e) => !documentSelectorAnchorEl &&
							!isRequestDisabled &&
							setDocumentSelectorAnchorEl(e.currentTarget)
					}
				>
					<Input
						labelId="REQUEST.PROVIDER.TEMPLATE"
						containerClass={`mb-20px pointer-events-none ${isRequestDisabled ? 'pointer-events-none' : 'cursor-pointer'}`}
						type="email"
						variant="outlined"
						fullWidth
						value="Select Template"
						InputProps={{
							className: "pr-0",
							endAdornment: (
								<div className={classes.templateControlCaret}>
									<ArrowDropDown color="primary" />
								</div>
							)
						}}
					/>
				</div>

				<Popper
					open={!!documentSelectorAnchorEl}
					anchorEl={documentSelectorAnchorEl}
					placement={isWideEnoughForDocumentSelector ? "bottom-start" : "bottom"}
					transition
				>
					<ClickAwayListener onClickAway={() => setDocumentSelectorAnchorEl(null)}>
						<div className="mt-3"> {/* wrapped in div because DocumentSelector is a functional component and click away listener can't work directly with functional components */}
							<DocumentSelector
								className={classes.documentSelector}
								classes={{
									templatesListContainer: classes.templatesListContainer,
									templateListItem: isWideEnoughForDocumentSelector ? undefined : "align-items-start",
									templateListItemTitle: isWideEnoughForDocumentSelector ? undefined : "flex-column",
									templateListItemSelectedPill: isWideEnoughForDocumentSelector ? undefined : "mt-5px",
								}}
								onSubmit={({ items, emailMeta, selectedTemplateId }) => {
									if (!provider.emailMeta.subject && !provider.emailMeta.content && selectedTemplateId) {
										setFieldValue(`${fieldNamePrefix}emailMeta`, emailMeta);
										setSelectedEmailTemplate(selectedTemplateId);

										// scroll so that element shows up in the list
										const templateInSelector = document.getElementById(`email-template-selector-${selectedTemplateId}`);
										const topPos = templateInSelector.offsetTop;
										const containerList = document.getElementById("email-template-selector-list");
										containerList.scrollTop = topPos - 20;
									}
									
									setFieldValue(`${fieldNamePrefix}documents`, [
										...provider.documents,
										...items
									]);
									setDocumentSelectorAnchorEl(null);
									globalGA("add_document_via_template", {
										eventAction: "success",
										eventCategory: "request_creation_C1"
									});
								}}
							/>
						</div>
					</ClickAwayListener>
				</Popper>
			</div>

			<div className="col-md-4 col-12">
				<div className="dark-custom-input form-group mb-20px">
					<FormLabel className="mb-10">
						<FormattedMessage id="REQUEST.PROVIDER.DUE_DATE" />
					</FormLabel>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							InputAdornmentProps={{
								className: classes.datePickerIcon,
							}}
							variant="inline"
							inputVariant="outlined"
							fullWidth
							value={provider.dueDate}
							onChange={(date) => setFieldValue(`${fieldNamePrefix}dueDate`, moment(date).startOf('day').toDate())}
							disablePast={isInCreateMode}
							disabled={isRequestDisabled}
							format="dd/MM/yyyy"
							helperText={_.get(touched, `${fieldNamePrefix}dueDate`) && _.get(errors, `${fieldNamePrefix}dueDate`)}
							error={Boolean(_.get(touched, `${fieldNamePrefix}dueDate`) && _.get(errors, `${fieldNamePrefix}dueDate`))}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>

			<div className="col-12">
				<div className="dark-input-label f-15px">
					<FormattedMessage id="REQUEST.PROVIDER.REQUEST_SETTINGS" />
				</div>

				<div className="d-flex justify-content-between flex-column flex-md-row">
					<ClickAwayListener onClickAway={closeAllTooltips}>
						<div className="d-flex request-settings-container flex-column flex-md-row">
							{
								requestSettings
									.map(({ key, value, label, isDisabled, disabledTooltip, tooltip }, index) => {
										return (
											<div className="mb-4 mb-md-0 mr-4 dark-input-label d-flex align-items-center" key={index}>
												<FormControlLabel
													control={
														<IOSSwitch
															rootClass={clsx("my-0 mr-2 ml-0", { "pointer-events-none": isDisabled })}
															enabledTrackClass={isDisabled ? "bg-secondary" : "bg-primary"}
															onChange={() => !isDisabled && setFieldValue(`${fieldNamePrefix}${key}`, !provider[key])}
														/>
													}
													className="m-0 text-dark"
													checked={value}
													label={<FormattedMessage id={label} />}
												/>

												<span>
													<FormattedHTMLMessage id={isDisabled ? disabledTooltip : tooltip}>
														{
															(text) => (
																<LightTooltip
																	// onClose={() => this.handleTooltipToggle(key, 2)}
																	open={!!tooltipStatus[key]}
																	disableFocusListener
																	disableHoverListener
																	disableTouchListener
																	className="ml-1"
																	title={
																		<div dangerouslySetInnerHTML={{ __html: text }} />
																	}
																	placement="bottom"
																	arrow
																>
																	<span onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		handleTooltipToggle(key, 3)
																	}}>
																		<i className="text-dark fas fa-info-circle cursor-pointer" />
																	</span>
																</LightTooltip>
															)
														}
													</FormattedHTMLMessage>
												</span>
											</div>
										);
									})
							}
						</div>
					</ClickAwayListener>

					{
						!!provider._id && user.chatService && (
							<div className="d-flex align-items-end justify-content-center mt-4 mt-md-0">
								<div className="d-flex align-items-center">
									<div className="text-dark">
										<FormattedMessage
											id="PROVIDER.ASK_QUESTION"
											values={{
												name: `${provider.firstName.toUpperCase()} ${provider.lastName.toUpperCase()}`,
											}}
										/>
									</div>
									<div className="ml-15">
										<div className="d-flex align-items-center">
											<button
												type="button"
												className="btn btn-primary btn-sm d-flex align-items-center"
												onClick={onOpenChat}
											>
												<div className="mr-10px d-flex align-items-center justify-content-center">
													<ChatIcon />
												</div>
												<FormattedMessage id="REQUEST.PROVIDER.CHAT.TITLE" />
											</button>
										</div>
									</div>
								</div>
							</div>
						)
					}
				</div>
			</div>

			<div className="col-12 pt-50px">
				<div
					className={
						clsx(
							"d-flex align-items-center p-15px rounded cursor-pointer",
							classes.emailAccordionHeader
						)
					}
					onClick={() => setEmailPanelExpanded(!isEmailPanelExpanded)}
				>
					<div className="dark-input-label f-15px flex-grow-1">
						<FormattedMessage id="PROVIDER.SECTION.EMAIL" />
					</div>
					<div className="text-primary">
						{
							isEmailPanelExpanded ? (
								<KeyboardArrowUp />
							) : (
								<KeyboardArrowDown />
							)
						}
					</div>
				</div>
				<Collapse isOpen={isEmailPanelExpanded}>
					<div className={clsx("row border-left border-right border-bottom rounded w-100 mx-auto", classes.emailAccordionBody)}>
						<div className="col-12 col-md-6 pl-4 pb-0 pb-md-4 pt-4 pr-4 pr-md-0 d-flex flex-column">
							<fieldset className="border border-secondary flex-grow-1 pl-15px rounded pr-20px pt-10px">
								<legend className="dark-input-label px-2 w-auto">
									<FormattedMessage id="REQUEST.PROVIDER.SELECT_EMAIL_TEMPLATE" />
								</legend>

								<div className={classes.emailTemplateListContainer}>
									<div
										id="email-template-selector-list"
										className={classes.emailTemplateList}
									>
										{
											templates.map((template, index, arr) => (
												<EmailTemplateCard
													key={index}
													id={`email-template-selector-${template._id}`}
													disabled={isRequestDisabled}
													template={template}
													isLast={index === arr.length - 1}
													isSelected={selectedEmailTemplate === template._id}
													handleClick={() => {
														setFieldValue(`${fieldNamePrefix}emailMeta`, template.emailMeta);
														setSelectedEmailTemplate(template._id);
													}}
												/>
											))
										}
									</div>
								</div>
							</fieldset>
						</div>
						
						<div className="col-12 col-md-6 p-4 d-flex flex-column">
							<fieldset className="border border-secondary flex-grow-1 px-20px pt-10px rounded">
								<legend className="dark-input-label px-2 w-auto">
									<FormattedMessage id="REQUEST.PROVIDER.SELECT_EMAIL_TEMPLATE_CUSTOMIZE" />
								</legend>
								<div>
									<div className="mb-3 bg-white rounded border border-dark p-10px">
										<div className="f-11px">
											<FormattedMessage id="PROVIDER.SECTION.EMAIL.SUBJECT" />
										</div>
										<div>
											<InputBase
												className="f-15px text-dark"
												name={`${fieldNamePrefix}emailMeta.subject`}
												onBlur={handleBlur}
												onChange={handleChange}
												disabled={isRequestDisabled}
												value={provider.emailMeta.subject}
												fullWidth
											/>
										</div>
									</div>

									<div className="mb-3 bg-white rounded border border-dark p-10px">
										<div className="f-11px">
											<FormattedMessage id="PROVIDER.SECTION.EMAIL.MESSAGE" />
										</div>
										<div>
											<InputBase
												className="f-15px text-dark"
												multiline
												fullWidth
												rows={3}
												name={`${fieldNamePrefix}emailMeta.content`}
												onBlur={handleBlur}
												onChange={handleChange}
												disabled={isRequestDisabled}
												value={provider.emailMeta.content}
												helperText={_.get(touched, `${fieldNamePrefix}emailMeta.content`) && _.get(errors, `${fieldNamePrefix}emailMeta.content`)}
												error={Boolean(_.get(touched, `${fieldNamePrefix}emailMeta.content`) && _.get(errors, `${fieldNamePrefix}emailMeta.content`))}
											/>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</Collapse>
			</div>

			<Confirm
				open={resendingRequestStatus.show}
				icon={({ color }) => <Autorenew color="primary" style={{ height: 68, width: 61 }} />}
				variant="primary"
				handleClose={handleResendRequestSubmit}
				title="REQUEST.PROVIDER.RESEND_REQUEST.CONFIRM.TITLE"
				submitButtonText="GENERAL.YES"
				cancelButtonText="GENERAL.NO"
				loading={resendingRequestStatus.resending}
				error={resendingRequestStatus.error}
			/>
		</div>
	)
}