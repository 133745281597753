import React, {
	useState,
	useCallback,
	useMemo,
} from "react";
import {
	useDispatch,
	useSelector
} from "react-redux";
import clsx from "clsx";
import moment from "moment";
import {
	Card,
	CardContent,
	ClickAwayListener,
	makeStyles,
} from "@material-ui/core";
import {
	FormattedMessage
} from "react-intl";
import { useDropzone } from 'react-dropzone';
import Linkify from 'react-linkify';

import { getFileIcon, getFileName, requestSignedUrl, sanitizeFile } from "../../../_metronic/utils/utils";
import { CloudDownloadIcon, DeleteIcon, InfoIcon, UploadIcon } from '../../common/icons';
import LightTooltip from '../../common/LightTooltip';
import Constants from '../../common/constants';
import { downloadFileTask } from "../../store/modules/actions/tasks.actions";
import Confirm from "../../common/modals/confirm";
import { doesRequirePassword, getDocuments } from "../../store/modules/selectors/provider.selector";
import { getBankStatementUrl } from "../../store/modules/selectors/common.selector";

const useStylesForFileCard = makeStyles((theme) => {
	return {
		container: {
			padding: "10px 15px",
			background: "rgba(224, 231, 255, 0.2)",
			border: `2px solid ${theme.palette.extraColors.blueBorder}`,
			borderRadius: "8px",
		},
		actionButton: {
			height: "24px",
			width: "24px",
			background: theme.palette.extraColors.blueSecondary,
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.06)",
			borderRadius: "5px",

			"&.pressed": {
				boxShadow: "none"
			}
		},
		fileNameContainer: {
			minWidth: 0
		},
		fileName: {
			display: "block",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	}
});

function AttachmentFileCard({ onClick, itemId, file, canDownload }) {
	const classes = useStylesForFileCard();
	const dispatch = useDispatch();
	const {
		name: filename
	} = file;

	const [isDownloading, setDownloading] = useState(false);

	const downloadFile = useCallback(async () => {
		if (!file._id) {
			console.log(`file is not uploaded yet`);
			return null;
		}

		const { data } = await requestSignedUrl(file.url);

		setDownloading(true);

		try {
			await dispatch(
				downloadFileTask(
					data.url,
					filename
				)
			);
		}
		finally {
			setDownloading(false);
		}
	}, [
		file,
		filename,
		dispatch
	]);

	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				if (canDownload) {
					onClick(e);
				}
			}}
			className={clsx(classes.container, "d-flex align-items-center bg-white", { "cursor-pointer": !!onClick })}
		>
			<div className="mr-10px">
				<div>
					<img
						alt="file"
						src={getFileIcon(filename)}
						style={{
							height: 22,
							width: 18
						}}
					/>
				</div>
			</div>
			<div className="flex-grow-1 d-flex justify-content-between px-0 align-items-center">
				<div>
					{getFileName(filename)}
				</div>

				<div className="d-flex">
					{
						canDownload && (
							<div
								className={clsx(
									classes.actionButton,
									{ "pressed": isDownloading },
									"d-flex justify-content-center align-items-center cursor-pointer"
								)}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										downloadFile();
									}}
								>
									<CloudDownloadIcon />
								</span>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
}

function FileCard({ onClick, file, onDelete, canDownload }) {
	const classes = useStylesForFileCard();
	const dispatch = useDispatch();
	const {
		name: filename
	} = file;
	const [isDownloading, setDownloading] = useState(false);

	const downloadFile = useCallback(async () => {
		if (!file._id) {
			console.log(`file is not uploaded yet`);
			return null;
		}

		const { data } = await requestSignedUrl(file.url);

		setDownloading(true);
		
		try {
			await dispatch(
				downloadFileTask(
					data.url,
					filename
				)
			);
		}
		finally {
			setDownloading(false);
		}
	}, [
		file,
		filename,
		dispatch
	]);

	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				onClick(e);
			}}
			className={clsx(classes.container, "d-flex align-items-center bg-white", { "cursor-pointer": !!onClick })}
		>
			<div>
				<img
					alt="icon"
					src={getFileIcon(filename)}
					style={{
						height: 22,
						width: 18
					}}
				/>
			</div>
			
			<div className={clsx(classes.fileNameContainer, "mx-2 flex-grow-1")}>
				<span className={classes.fileName}>
					{filename}
				</span>
			</div>

			<div className="d-flex">
				{
					canDownload && (
						<div
							className={clsx(
								classes.actionButton,
								{ "pressed": isDownloading },
								"d-flex justify-content-center align-items-center cursor-pointer"
							)}
						>
							<span onClick={(e) => {
								e.stopPropagation();
								downloadFile();
							}}>
								<CloudDownloadIcon />
							</span>
						</div>
					)
				}
				{
					typeof onDelete === "function" && (
						<div
							className={clsx(classes.actionButton, "d-flex justify-content-center align-items-center ml-10px", { "cursor-pointer": !!onDelete })}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();

								onDelete();
							}}
						>
							<DeleteIcon />
						</div>
					)
				}
			</div>
		</div>
	);
}

const useStyles = makeStyles({
	uploadInput: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
	},
	status: {
		background: "#E5EAF9",
		borderRadius: 3
	},
	buttonElevation: {
		boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
	},
	description: {
		minHeight: 75,
		overflowY: "auto",
	},
	smallDescription: {
		height: 75,
		maxHeight: 75,
	},
	largeDescription: {
		height: 160,
		maxHeight: 160,
	},
	emptyListContainer: {
		background: "rgba(255, 255, 255, 0.33)",
		border: "1px solid white",
		borderRadius: 5,
	},
	fileListContainer: {
		background: "rgba(229, 234, 249, 0.35)",
		borderRadius: 6,
		minHeight: 160,
	},
	filesList: {
		position: 'absolute',
		overflowY: 'scroll',
		minHeight: 140, // height of fileListContainer - padding of 10px on top and bottom
		top: 10,
		bottom: 10,
		left: 10,
		right: 10,
		paddingRight: 10,

		'&::-webkit-scrollbar': {
			width: 5,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			borderRadius: 5,
			background: 'rgba(255, 255, 255, 0.3)'
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: '#FFFFFF',
			borderRadius: 15,
		}
	}
});

function ItemCard(props) {
	const classes = useStyles();

	const [tooltipStatus, setTooltipStatus] = useState(false);
	const [showPreviewWarning, setShowPreviewWarning] = useState(false);
	const {
		className,
		itemId,
		isUploading,
		uploadFiles,
		onMarkNotRequired,
		selectFileForPreview,
		selectAttachmentForPreview,
		deleteFile,
	} = props;

	const canModify = useSelector(doesRequirePassword);
	const documents = useSelector(getDocuments);
	const profileBankStatementUrl = useSelector(getBankStatementUrl);
	
	const item = useMemo(() => documents.find(({ _id }) => _id === itemId), [documents, itemId]);

	const onDrop = useCallback(async (acceptedFiles) => {
		const filesToUpload = acceptedFiles.slice(0, 10);
		const promises = filesToUpload.map((fileToUpload) => sanitizeFile(fileToUpload));
		const files = await Promise.all(promises);
		uploadFiles(files);
	}, [
		uploadFiles,
	])
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: false,
		disabled: isUploading ||
			[Constants.DocumentType.READ_ONLY, Constants.DocumentType.BANK_STATEMENTS].includes(item.type) ||
			item.status === Constants.DocumentStatus.APPROVED
	})

	const bankStatementDocumentUrl = item.refData && item.refData.bankStatementsUrl;

	return (
		<Card
			elevation={0}
			className={`document-card border rounded-card ${className} ${isDragActive && !isUploading ? 'border-primary' : ''}`}
		>
			<CardContent>
				<div className="row flex-grow-1 flex-column flex-md-row">
					<div className="col-md-6 col-12 order-1 d-flex flex-column flex-md-row align-items-center justify-content-between">
						<div 
							className="flex-grow-1 f-15px ls-04 text-transform-uppercase fw-500 max-line-2"
							title={item.name}
						>
							{item.name}
						</div>

						{
							item.type !== Constants.DocumentType.READ_ONLY && (
								<div className={clsx(classes.status, "py-5px flex-shrink-0 px-15px ml-md-2 ml-0 mt-4 mt-md-0")}>
									<FormattedMessage
										id={`REQUEST.DOCUMENT.STATUS.${item.status}${(item.status === Constants.DocumentStatus.APPROVED && item.markNotRequiredReason ? "_NOT_REQUIRED" : "")}`}
										values={{
											dateString: moment(item.createdAt).format('DD/MM/YYYY'),
											name: "you"
										}}
									>
										{
											text => (
												<span
													className={`${clsx({
														"text-warning": [Constants.DocumentStatus.NOT_REQUIRED, Constants.DocumentStatus.APPROVED].includes(item.status) && item.markNotRequiredReason,
														"text-danger": item.status === Constants.DocumentStatus.REJECTED,
														"text-success": item.status === Constants.DocumentStatus.APPROVED && !item.markNotRequiredReason,
														"text-primary": [Constants.DocumentStatus.UPLOADED, Constants.DocumentStatus.REQUESTED].includes(item.status),
													})}`}
												>
													{text}

													<ClickAwayListener onClickAway={() => setTooltipStatus(false)}>
														<span>
															{
																[Constants.DocumentStatus.NOT_REQUIRED, Constants.DocumentStatus.APPROVED].includes(item.status) &&
																item.markNotRequiredReason && (
																	<LightTooltip
																		className="ml-2"
																		open={tooltipStatus}
																		title={item.markNotRequiredReason}
																		placement="right"
																	>
																		<span onClick={() => setTooltipStatus(true)}>
																			<i className="fas fa-info-circle cursor-pointer" />
																		</span>
																	</LightTooltip>
																)
															}

															{
																item.status === Constants.DocumentStatus.REJECTED && (
																	<LightTooltip
																		className="ml-2"
																		open={tooltipStatus}
																		title={item.rejectedReason}
																		placement="right"
																		arrow
																	>
																		<span onClick={() => setTooltipStatus(true)}>
																			<i className="fas fa-info-circle cursor-pointer" />
																		</span>
																	</LightTooltip>
																)
															}
														</span>
													</ClickAwayListener>
												</span>
											)
										}
									</FormattedMessage>
								</div>
							)
						}
					</div>

					<div className="col-12 col-md-6 col-md-2 order-2 d-flex flex-md-row flex-column justify-content-end mt-4 mt-md-0 position-relative">
						{
							item.type !== Constants.DocumentType.READ_ONLY && item.status !== Constants.DocumentStatus.APPROVED && (
								<React.Fragment>
									<label
										className={`f-12px py-10px btn btn-sm btn-block mt-3 mt-md-0 mr-0 mr-md-3 mb-0 ${clsx(
											classes.buttonElevation,
											{
												"btn-primary": item.status !== Constants.DocumentStatus.APPROVED,
												"btn-secondary btn-disabled": item.status === Constants.DocumentStatus.APPROVED,
												"opacity-50 pointer-events-none": isUploading,
												"opacity-0 pointer-events-none d-none d-md-block": item.type === Constants.DocumentType.BANK_STATEMENTS
											}
										)}`}
									>
										{
											item.status !== Constants.DocumentStatus.APPROVED && (
												<input
													onChange={({ target: { files } }) => {
														const filesArr = Array.from(files);
														onDrop(filesArr);
													}}
													type="file"
													className={clsx("d-none", classes.uploadInput)}
													multiple
												/>
											)
										}

										<FormattedMessage id="PROVIDER.DOCUMENT.UPLOAD" />
									</label>

									<button
										disabled={[Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.NOT_REQUIRED].includes(item.status)}
										className={`f-12px py-10px btn btn-sm btn-block mt-3 mt-md-0 ${clsx(
											classes.buttonElevation,
											{
												"bg-white text-danger": ![Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.NOT_REQUIRED].includes(item.status),
												"btn-secondary btn-disabled": [Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.NOT_REQUIRED].includes(item.status)
											}
										)}`}
										onClick={() => onMarkNotRequired()}
									>
										<FormattedMessage id="PROVIDER.DOCUMENT.NOT_REQUIRED" />
									</button>
								</React.Fragment>
							)
						}
					</div>

					<div
						className={clsx(
							"col-12 order-3 d-flex flex-column",
							{
								"flex-md-row": item.type === Constants.DocumentType.READ_ONLY,
								"col-md-6": item.type !== Constants.DocumentType.READ_ONLY,
							}
						)}
					>
						<div
							className={clsx(
								"description mt-4 d-flex flex-column flex-grow-1",
								{ "col-12 col-md-6 pl-0 pr-0 pr-md-3": item.type === Constants.DocumentType.READ_ONLY }
							)}
						>
							<div className="input-label mb-10px">
								<FormattedMessage id="PROVIDER.DOCUMENT.DESCRIPTION" />
							</div>

							<div
								className={clsx(
									classes.description,
									"bg-white p-2 rounded border border-secondary f-15px flex-grow-1",
									{
										[classes.smallDescription]: item.attachments.length > 0,
										[classes.largeDescription]: item.attachments.length === 0,
									}
								)}
							>
								<pre className="wrappable h-100 mb-0">
									<Linkify
										componentDecorator={(href, text, key) => (
											<a
												key={key}
												href={href}
												target="_blank"
												rel="noopener noreferrer"
											>
												{text}
											</a>
										)}
									>
										{item.description}
									</Linkify>
								</pre>
							</div>

							{
								item.type === Constants.DocumentType.BANK_STATEMENTS && (
									<div className="mt-10px d-flex align-items-center">
										<div className="input-label mr-2">
											<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL" />
										</div>
										<div>
											<a
												href={item._id ? bankStatementDocumentUrl : profileBankStatementUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL_TEXT" />
											</a>
										</div>
									</div>
								)
							}
						</div>

						{
							item.attachments.length > 0 && (
								<div
									className={clsx(
										"mt-4 mt-md-3",
										{ "col-md-6 col-12 pr-0 pl-0 pl-md-2": item.type === Constants.DocumentType.READ_ONLY }
									)}
								>
									<div className="input-label mb-10px">
										<FormattedMessage id="PROVIDER.ATTACHMENTS.TITLE" />
									</div>

									<div
										className={clsx(
											classes.fileListContainer,
											"p-10px position-relative",
											{
												"d-flex justify-content-center align-items-center": !item.attachments.length
											}
										)}
									>
										<div className={classes.filesList}>
											{
												item.attachments.map((file, index, arr) => (
													<div
														key={index}
														className={clsx({
															"mb-5px": index !== arr.length - 1
														})}
													>
														<AttachmentFileCard
															onClick={() => selectAttachmentForPreview(index)}
															itemId={item._id}
															file={file}
															canDownload
														/>
													</div>
												))
											}
										</div>
									</div>
								</div>
							)
						}
					</div>

					{
						item.type !== Constants.DocumentType.READ_ONLY && (
							<div className="col-12 col-md-6 order-4 mt-4 d-flex flex-column">
								<div className="input-label mb-10px">
									<FormattedMessage id="PROVIDER.FILES.TITLE" />
								</div>

								<div
									className={clsx(
										classes.fileListContainer,
										"p-10px position-relative flex-grow-1",
										{
											"d-flex justify-content-center align-items-center flex-column": !item.files.length
										}
									)}
									{...getRootProps()}
								>
									<input {...getInputProps()} />
									{
										item.files.length > 0 ? (
											<div className={classes.filesList}>
												{
													item.files.map((file, index, arr) => (
														<div
															key={index}
															className={clsx({
																"mb-5px": index !== arr.length - 1
															})}
														>
															<FileCard
																onClick={() => {
																	if (canModify) {
																		selectFileForPreview(index)
																	}
																	else {
																		setShowPreviewWarning(true);
																	}
																}}
																requestId={item.requestId}
																providerId={item.documentProviderId}
																itemId={item._id}
																file={file}
																canDownload={canModify}
																onDelete={() => deleteFile(file)}
															/>
														</div>
													))
												}
											</div>
										) : (
											<div
												className={clsx(
													classes.emptyListContainer, 
													"w-100 h-100 d-flex align-items-center justify-content-center flex-column flex-grow-1",
													{
														"opacity-0": item.type === Constants.DocumentType.BANK_STATEMENTS,
													}
												)}
											>
												<div className="mb-15px text-center">
													<UploadIcon />
												</div>
												<div className="text-center message text-muted">
													<FormattedMessage id="TEMPLATE.DOCUMENT.EMPTY_DOCUMENT.MESSAGE" />
												</div>
											</div>
										)
									}
								</div>
							</div>
						)
					}
				</div>
			</CardContent>

			<Confirm
				open={showPreviewWarning}
				icon={<InfoIcon />}
				variant="primary"
				handleClose={() => setShowPreviewWarning(false)}
				title="PREVIEW.NO_PASSWORD_WARNING"
				submitButtonText="GENERAL.DISMISS"
			/>
		</Card>
	)
}

export default ItemCard;