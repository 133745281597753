import React, { useCallback, useEffect, useState } from "react";
import {
	useDispatch, useSelector
} from "react-redux";
import {
	FormattedMessage,
} from "react-intl";
import { useHistory } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core";
import { useStripe } from '@stripe/react-stripe-js';
import clsx from "clsx";
import _ from "lodash";

import { loadFeatures } from "../../../store/modules/actions/features.actions"
import IOSSwitch from "../../../common/IOSSwitch";
import ComparisonMD from "./ComparisonMD";
import ComparisonSM from "./ComparisonSM";
import AccountUsage from "../Billing/AccountUsage";
import FAQ from "./FAQ";
import { getPlans, purchasePlan } from "../../../crud/subscriptions.crud";
import { getBillingDetails } from "../../../crud/auth.crud";
import Confirm from "../../../common/modals/confirm";
import { CircleCheckIcon } from "../../../common/icons";
import { globalGA } from "../../../../_metronic";

const ENTERPRISE_PLAN = "ENTERPRISE";

const useStyles = makeStyles((theme) => {
	return {
		savePercentageButton: {
			background: "#EBEFFF",
			borderRadius: 4,
			padding: "10px 15px",
			color: theme.palette.primary.main,
			fontWeight: 500,
			fontSize: 14,
		}
	}
});

function PricingTable(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	
	const [isBillingAnnually, setIsBillingAnnually] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [plans, setPlans] = useState(null)
	const stripe = useStripe();
	const history = useHistory();
	const [billingData, setBillingData] = useState(null);

	const auth = useSelector(({ auth }) => auth);

	useEffect(() => {
		async function fn() {
			if (auth.user._id) {
				const { data } = await getBillingDetails();
				setBillingData(data);
			}
		}

		fn();
	}, [
		auth,
	]);

	const processPurchasePlan = useCallback(async (subscriptionId) => {
		const {
			data: {
				sessionId
			}
		} = await purchasePlan(subscriptionId);

		if (sessionId) {
			stripe
				.redirectToCheckout({
					sessionId
				})
		}
		else {
			history.push("/billing?type=success&message=Plan upgrade in process");
		}
	}, [
		stripe,
		history,
	]);

	const handleConfirmationPlanChangeClose = useCallback(async (wasSubmitted) => {
		if (wasSubmitted) {
			const selectedPlanObj = plans.find((plan) => plan._id === selectedPlan);

			if (selectedPlanObj) {
				const planName = _.capitalize(selectedPlanObj.name);
				const planType = _.capitalize(selectedPlanObj.type);

				globalGA("select_plan", {
					eventAction: "success",
					eventCategory: "subscription",
					planName,
					planType,
				});
			}

			await processPurchasePlan(selectedPlan);
		}

		setSelectedPlan(null);
	}, [
		processPurchasePlan,
		selectedPlan,
		plans,
	]);

	useEffect(() => {
		Promise.all([
			getPlans(),
			dispatch(loadFeatures()),
		])
		.then(([planResponse]) => {
			setPlans([
				...planResponse.data.data,
				{
					name: "Enterprise",
					description: "Contact us to discuss a custom plan",
					isEnterprisePlan: true,
					slug: ENTERPRISE_PLAN
				}
			]);
		});
	}, [
		dispatch
	]);

	const features = useSelector((state) => state.features.list);

	const selectPlan = useCallback((planId) => {
		setSelectedPlan(planId);
	}, [])

	if (!features || !features.length || !plans) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
			</div>
		)
	}

	return (
		<div>
			<div className="d-flex align-items-center justify-content-center">
				<div className={clsx("f-18px", { "font-weight-bold text-dark": !isBillingAnnually })}>
					<FormattedMessage id="PRICING.TOGGLE.MONTHLY" />
				</div>
				
				<div className="px-30px">
					<IOSSwitch
						enabledTrackClass="bg-primary"
						checked={isBillingAnnually}
						onChange={(e) => setIsBillingAnnually(!isBillingAnnually)}
					/>
				</div>

				<div className="d-flex">
					<div className={clsx("mr-10px f-18px d-flex align-items-center", { "font-weight-bold text-dark": isBillingAnnually })}>
						<FormattedMessage id="PRICING.TOGGLE.ANNUALLY" />
					</div>

					<div className={classes.savePercentageButton}>
						<FormattedMessage id="PRICING.ANNUAL_PLANS_DISCOUNT" />
					</div>
				</div>
			</div>

			{
				!!(billingData && billingData.ACCOUNT_USAGE) && (
					<div className="mt-50px row">
						<div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-12">
							<AccountUsage billingData={billingData} />
						</div>
					</div>
				)
			}

			<div className="my-50px pt-10px d-flex justify-content-center">
				<ComparisonMD
					className="d-none d-md-block"
					isBillingAnnually={isBillingAnnually}
					features={features}
					plans={plans}
					onPlanSelect={selectPlan}
				/>
				<ComparisonSM
					className="d-block d-md-none w-100"
					isBillingAnnually={isBillingAnnually}
					plans={plans}
					features={features}
					onPlanSelect={selectPlan}
				/>
			</div>

			<Confirm
				open={!!selectedPlan}
				icon={<CircleCheckIcon fill={theme.palette.success.main} />}
				variant="success"
				handleClose={handleConfirmationPlanChangeClose}
				title="PRICING.PLAN.SELECT.CONFIRMATION.TITLE"
				submitButtonText="GENERAL.YES"
				cancelButtonText="GENERAL.NO"
			/>

			<div className="mb-50px col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 pb-50px d-flex justify-content-center">
				<FAQ />
			</div>
		</div>
	);
}

export default PricingTable;
