import React, { useRef } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import Input from '../Input';

const filter = createFilterOptions();

function CustomAutocomplete (props) {
	const {
		inputProps,
		onChange,
		options,
		value,
		...rest
	} = props;
	const ref = useRef();

	return (
		<Autocomplete
			{...rest}
			ref={ref}
			multiple
			value={[value]}
			options={options}
			onChange={(event, newValue) => {
				const nv = newValue[1] || '';

				onChange(
					// provided option selected
					nv.value ||
					// new option created
					nv.inputValue ||
					// directly pressed enter after entering custom value
					nv
				);
				// setInputValue("");
			}}
			
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				// Suggest the creation of a new value
				if (params.inputValue !== '') {
					filtered.push({
						inputValue: params.inputValue,
						title: `Use "${params.inputValue}"`,
					});
				}

				return filtered;
			}}
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === 'string') {
					return option;
				}
				
				// Regular option
				return option.title;
			}}
			renderTags={(tags, getTagProps) => {
				const selectedValue = tags[0];
				const selectedOption = options.find((option) => option.value === selectedValue);

				if (!selectedOption && !selectedValue) {
					return null;
				}

				return (
					<div className="ml-2">
						{
							selectedOption ? (
								<Chip
									color="primary"
									label={selectedOption.title}
								/>
							) : (
								<span>{selectedValue}</span>
							)
						}
					</div>
				)
			}}
			renderOption={({ title }) => title}
			renderInput={(params) => {
				return (
					<Input
						{...params}
						{...inputProps}
						// Input component expects startAdornment at root level
						startAdornment={params.InputProps.startAdornment}
					/>
				);
			}}
		/>		
	);
}

export default CustomAutocomplete;