import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles(() => {
	return {
		container: {
			cursor: 'pointer',
			minWidth: ({ minWidth }) => minWidth,
			display: 'flex',
			'& [contenteditable=true]': {
				outline: 'none',
				'-webkit-user-select': 'text',
    			userSelect: 'text',
			},
			'& [contenteditable=true]:empty:before': {
				content: ({ placeholder }) => `'${placeholder}'`,
				display: 'block',
				color: 'rgba(0, 0, 0, 0.5)'
			}
		}
	}
})

function AutoExpandInput ({
	name,
	className = '',
	style,
	onChange,
	value = '',
	disabled,
	placeholder = '',
	minWidth = 75,
	triggerRerender,
	allowHTML = false,
}) {
	const valuePropRef = useRef(value);
	const inputRef = useRef();
	const classes = useStyles({ placeholder, minWidth });
	const handleChange = useDebouncedCallback(() => {
		const domElement = inputRef.current;
		const textValue = allowHTML
			? sanitizeHtml(domElement.innerHTML)
			: domElement.innerText || domElement.textContent;

		onChange({
			target: {
				name,
				value: textValue,
			}
		});
	}, 1000);
	const setInputRef = useCallback((ref) => {
		if (inputRef.current || !ref) {
			return;
		}

		inputRef.current = ref;

		if (allowHTML) {
			inputRef.current.innerHTML = valuePropRef.current;
		}
		else {
			inputRef.current.innerText = valuePropRef.current;
		}
	}, [allowHTML]);

	// keep track of value prop in a ref so that we can use it in useEffect hook without having to add
	// it in dependency because otherwise value will get caught in closure and will use older value
	useEffect(() => {
		valuePropRef.current = value;
	}, [value]);

	useEffect(() => {
		// delay execution of this snippet to ensure that above use effect runs first and 
		// ensures that the latest value is in valuePropRef.current
		setTimeout(() => {
			if (allowHTML) {
				inputRef.current.innerHTML = valuePropRef.current;
			}
			else {
				inputRef.current.innerText = valuePropRef.current;
			}
		}, 20)
	}, [allowHTML, triggerRerender]);

	return (
		<div
			className={
				clsx(
					className,
					classes.container,
					{
						'pointer-events-none': disabled,
					}
				)
			}
		>
			<div
				className="w-100"
				ref={setInputRef}
				contentEditable={true}
				suppressContentEditableWarning={true}
				style={style}
				onInput={handleChange}
			/>
		</div>
	)
}

export default AutoExpandInput;