// import { toAbsoluteUrl } from "../utils/utils";

export default {
  header: {
    self: {},
    items: [
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Requests",
        root: true,
        // iconUrl: toAbsoluteUrl("/media/icons/requests-icon.svg"),
        icon: "fas fa-layer-group",
        page: "requests",
        translate: "MENU.REQUESTS",
        bullet: "dot"
      },
      // {
      //   title: "Reminder Settings",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "reminder-settings",
      //   translate: "MENU.REMINDER_SETTINGS",
      //   bullet: "dot"
      // },
      {
        title: "Templates",
        root: true,
        icon: "fas fa-edit",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "templates",
        translate: "MENU.TEMPLATES",
        bullet: "dot"
      },
      {
        title: "Profile",
        root: true,
        icon: "fas fa-user",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "profile",
        translate: "USER_MENU.PROFILE",
        bullet: "dot"
      },
      {
        title: "Notification Settings",
        root: true,
        icon: "fas fa-bell",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "notification-settings",
        translate: "USER_MENU.NOTIFICATION_SETTINGS",
        bullet: "dot"
      },
      {
        title: "Integrations",
        root: true,
        icon: "fas fa-puzzle-piece",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "integrations",
        translate: "USER_MENU.INTEGRATIONS",
        bullet: "dot"
      },
      {
        title: "Billing",
        root: true,
        icon: "fas fa-money-check-alt",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "billing",
        translate: "USER_MENU.BILLING",
        bullet: "dot"
      },
      {
        title: "Logout",
        root: true,
        icon: "fas fa-sign-out-alt",
        // iconUrl: toAbsoluteUrl("/media/icons/templates-icon.svg"),
        page: "logout",
        translate: "USER_MENU.LOGOUT",
        bullet: "dot"
      },
    ]
  }
};
