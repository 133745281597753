import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTheme } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

import Confirm from "../../../common/modals/confirm";
import { cancelSubscription, flagNames } from "../../../store/modules/actions/auth.actions";
import { globalGA } from "../../../../_metronic";
import { getFlags } from "../../../store/modules/selectors/common.selector";

function PlanInfo({ billingData }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [confirmCancel, setConfirmCancel] = useState(false);

	const flags = useSelector(getFlags);

	const handleConfirmCancelClose = useCallback(async (wasSubmitted) => {
		if (wasSubmitted) {
			await dispatch(cancelSubscription())
			window.location.href = "/billing?type=success&message=Subscription cancelled successfully. Your subscription will end by end of billing period";
		}

		setConfirmCancel(false)
	}, [
		dispatch,
	])

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-20px text-dark">
				<FormattedMessage id="BILLING.PLAN_INFO.TITLE" />
			</div>
			<div className="f-20px text-dark">
				<FormattedMessage
					id="BILLING.PLAN_INFO.PLAN_NAME"
					values={{
						planName: billingData.PLAN_INFO.name
					}}
				/>
			</div>

			<div className="mb-30px text-dark f-15px fw-300">
				<FormattedMessage
					id={
						false
							? "BILLING.PLANS.TRIAL.SUBTITLE"
							: (
								billingData.PLAN_INFO.type === "MONTHLY"
									? "BILLING.PLANS.PAYMENT_FREQUENCY.MONTHLY"
									: "BILLING.PLANS.PAYMENT_FREQUENCY.YEARLY"
								)
					}
				/>
			</div>

			<div className="pb-10px">
				<button
					className="btn btn-primary mr-20px"
					onClick={() => {
						globalGA("open_plan_billing_screen", {
							eventAction: "success",
							eventCategory: "subscription"
						});
						history.push("/pricing")
					}}
				>
					<FormattedMessage
						id={
							(billingData.PLAN_INFO.slug === "TRIAL_MONTHLY" || billingData.PLAN_INFO.status === "CANCELLED_ON_BILLING")
								? "GENERAL.SELECT_PLAN"
								: "GENERAL.CHANGE_PLAN"
						}
					/>
				</button>

				{
					(billingData.PLAN_INFO.slug !== "TRIAL_MONTHLY" && billingData.PLAN_INFO.status !== "CANCELLED_ON_BILLING") && (
						<span
							className="cursor-pointer text-decoration-underline text-dark"
							onClick={() => setConfirmCancel(true)}
						>
							<FormattedMessage id="BILLING.PLANS.CANCEL" />
						</span>
					)
				}

				<Confirm
					open={confirmCancel}
					icon={
						<Warning 
							style={{
								color: theme.palette.danger.main,
								fontSize: 65
							}}
						/>
					}
					variant="danger"
					handleClose={handleConfirmCancelClose}
					title="BILLING.PLANS.CANCEL.CONFIRM.TITLE"
					submitButtonText="BILLING.PLANS.CANCEL.CONFIRM.YES"
					cancelButtonText="BILLING.PLANS.CANCEL.CONFIRM.NO"
					error={flags.error[flagNames.CANCEL_SUBSCRIPTION]}
				/>
			</div>

		</div>
	);
}

export default PlanInfo;
