import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback } from "react";

const useStyles = makeStyles(() => {
	return {
		root: {
			minWidth: 65,
		},
	};
});

export const UNITS = {
	B: "B",
	KB: "KB",
	MB: "MB",
	GB: "GB",
};

function SizeIndicator({ sizeInBytes, unit = UNITS.MB, className = "", hideIfZero = false }) {
	const classes = useStyles();
	const getSizeString = useCallback((sizeInBytes, unit) => {
		const supportedUnits = [
			UNITS.B,
			UNITS.KB,
			UNITS.MB,
			UNITS.GB,
		];
		
		const powerIndex = supportedUnits.indexOf(unit);

		if (powerIndex > -1) {
			const size = sizeInBytes / Math.pow(1024, powerIndex);
			return `${size.toFixed(1)} ${unit}`;
		}
		else {
			return sizeInBytes;
		}
	}, []);

	if (hideIfZero && !sizeInBytes) {
		return null;
	}

	return (
		<div className={clsx(classes.root, className)}>
			{getSizeString(sizeInBytes, unit)}
		</div>
	)
}

export default SizeIndicator;