import React, {
	useState,
	useCallback,
} from "react";
import {
	FormattedMessage, useIntl,
} from "react-intl";
import {
	Card,
	CardContent,
	ClickAwayListener,
	makeStyles,
	Fab,
	CircularProgress,
	Select,
	MenuItem,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import {
	getFileIcon,
	isAttachmentValid,
	downloadLocalFile,
	requestSignedUrl,
	getDummyFileRenameStrings,
	sanitizeFile,
} from "../../../../../_metronic";

import Input from "../../../../common/Input";
import LightTooltip from "../../../../common/LightTooltip";
import Constants from "../../../../common/constants";
import { CloudDownloadIcon, DeleteIcon, CancelIcon, PlusIcon, CancelCircleIcon } from "../../../../common/icons";
import EmptyDocumentImage from "../../../../common/icons/empty-document.png";
import CustomAutocomplete from "../../../../common/autocomplete";
import SizeIndicator from "../../../../common/size-indicator";
import { downloadFileTask } from "../../../../store/modules/actions/tasks.actions";
import { flagNames as requestFlagName } from "../../../../store/modules/actions/requests.actions";
import { getBankStatementUrl, getFlags } from "../../../../store/modules/selectors/common.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => ({
	uploadInput: {
		cursor: "pointer",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
	},
	descriptionField: {
		minHeight: 75,

		'& textarea': {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderTopWidth: 4,
		}
 	},
	title: {
		fontWeight: 500,
		fontSize: 14,
		letterSpacing: "0.04em",
		textTransform: "uppercase"
	},
	status: {
		background: "#E5EAF9",
		borderRadius: 3,
		position: "absolute",
		top: -10,
		right: 15,
	},
	actionButton: {
		height: 40,
		boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
		borderRadius: 6,
		fontSize: 12,
	},
	actionButtonText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
	},
	emptyListContainer: {
		background: "rgba(255, 255, 255, 0.33)",
		border: "1px solid white",
		borderRadius: 5,
	},
	fileListContainer: {
		background: "rgba(229, 234, 249, 0.35)",
		borderRadius: 6,
		height: 160,
	},
	filesList: {
		position: 'absolute',
		overflowY: 'scroll',
		height: 140, // height of fileListContainer - padding of 10px on top and bottom
		left: 10,
		right: 10,
		paddingRight: 10,

		'&::-webkit-scrollbar': {
			width: 5,
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			borderRadius: 5,
			background: 'rgba(255, 255, 255, 0.3)'
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: '#FFFFFF',
			borderRadius: 15,
		}
	},
	cancelIcon: {
		position: "absolute",
		top: 8,
		right: 20,
	},

	addFileRenameButton: {
		backgroundColor: "white !important",
		height: "28px !important",
		width: "28px !important",
		minHeight: "28px !important",

		"&:hover": {
			backgroundColor: "white !important",
		}
	},
	removeFileRenameButton: {
		position: "absolute !important",
		top: -9,
		right: 2,
		height: "18px !important",
		width: "18px !important",
		minHeight: "18px !important",
		backgroundColor: `${theme.palette.extraColors.grey} !important`,
		borderRadius: 9,

		'& svg': {
			position: 'relative',
			top: -2,
			left: -1,
		}
	},
	autoComplete: {
		background: theme.palette.extraColors.background,
	},
	downloadAllFilesButton: {
		minHeight: "30px !important",
		height: "30px !important",
		width: "30px !important"
	},
	sizeContainer: {
		background: "rgba(229, 234, 249, 0.35)",
		borderRadius: "5px",
	}
}));

const useStylesForFileCard = makeStyles((theme) => {
	return {
		container: {
			padding: "10px 15px",
			background: "rgba(224, 231, 255, 0.2)",
			border: `2px solid ${theme.palette.extraColors.blueBorder}`,
			borderRadius: "8px",
		},
		actionButton: {
			height: "24px !important",
			width: "24px !important",
			background: theme.palette.extraColors.blueSecondary,
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.06)",
			borderRadius: "5px",

			"&.pressed": {
				boxShadow: "none"
			}
		},
		fileName: {
			display: "block",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		sizeContainer: {
			background: theme.palette.extraColors.blueSecondary,
			borderRadius: "5px",
		},
		fileNameContainer: {
			overflow: "hidden",
			textOverflow: "ellipsis",
			display: "-webkit-box",
			"-webkit-line-clamp": 2,
			"-webkit-box-orient": "vertical",
		},
	}
});

function FileCard({
	onClick,
	file,
	onDelete,
	canDownload,
	isAttachment,
	log
}) {
	const classes = useStylesForFileCard();
	const dispatch = useDispatch();
	const {
		name: filename
	} = file;
	const [isDownloading, setDownloading] = useState(false);

	const downloadFile = useCallback(async () => {
		if (!file._id) {
			if (file instanceof File) {
				downloadLocalFile(file, filename);
				return;
			}

			console.log(`file is not uploaded yet`);
			return null;
		}

		const { data } = await requestSignedUrl(file.url);
		
		setDownloading(true);
		
		try {
			await dispatch(
				downloadFileTask(
					data.url,
					filename
				)
			);
		}
		finally {
			setDownloading(false);
		}
	}, [
		file,
		filename,
		dispatch
	]);

	return (
		<div
			onClick={onClick}
			className={clsx(classes.container, "d-flex align-items-center bg-white cursor-pointer")}
		>
			<div className="mr-2">
				<img
					alt="file"
					src={getFileIcon(filename)}
					style={{
						height: 22,
						width: 18
					}}
				/>
			</div>
			<div className="flex-grow-1 d-flex justify-content-between px-0 align-items-center">
				<div className={classes.fileNameContainer}>
					{filename}
				</div>

				<div className="d-flex align-items-center align-items-md-stretch">
					{
						isAttachment && (
							<SizeIndicator
								className={clsx(classes.sizeContainer, "d-flex justify-content-center align-items-center px-2 text-primary")}
								sizeInBytes={file._id ? file.size * 1024 : file.size}
							/>
						)
					}

					{
						canDownload && (
							<div
								className={
									clsx(
										classes.actionButton,
										{ "pressed": isDownloading },
										"d-flex justify-content-center align-items-center cursor-pointer ml-10px"
									)
								}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										downloadFile();
									}}
								>
									<CloudDownloadIcon />
								</span>
							</div>
						)
					}

					{
						typeof onDelete === "function" && (
							<div
								className={clsx(classes.actionButton, "d-flex justify-content-center align-items-center cursor-pointer ml-10px")}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onDelete()
								}}
							>
								<DeleteIcon />
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
}

function ItemButtons({ item, handleStatusChange, isUploading, uploadFiles, disabled }) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<button
				type="button"
				disabled={[Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.REQUESTED].includes(item.status) || disabled}
				className={`py-10px btn btn-block mt-3 mt-md-0 mr-3 ${clsx(
					classes.actionButton,
					{
						"bg-white text-success": ![Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.REQUESTED].includes(item.status),
						"btn-disabled bg-secondary text-muted": [Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.REQUESTED].includes(item.status) || item.type === Constants.DocumentType.READ_ONLY || disabled,
					}
				)}`}
				onClick={() => handleStatusChange(Constants.DocumentStatus.APPROVED)}
			>
				<div className={classes.actionButtonText}>
					<FormattedMessage id="REQUEST.DOCUMENT.ACCEPT" />
				</div>
			</button>

			<button
				className={`py-10px btn bg-white text-dark btn-block position-relative mt-3 mt-md-0 mr-3 ${clsx(
					classes.actionButton,
					{
						"kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--primary": isUploading,
						"btn-secondary btn-disabled": item.type === Constants.DocumentType.READ_ONLY || disabled,
					}
				)}`}
			>
				<input
					onChange={({ target: { files } }) => {
						const filesArr = Array.from(files);
						uploadFiles(filesArr);
					}}
					type="file"
					className={`${classes.uploadInput} w-100`}
					multiple
					disabled={disabled}
				/>

				<div className={classes.actionButtonText}>
					<FormattedMessage id="PROVIDER.DOCUMENT.UPLOAD" />
				</div>
			</button>

			<button
				type="button"
				disabled={[Constants.DocumentStatus.REJECTED, Constants.DocumentStatus.REQUESTED].includes(item.status) || disabled}
				className={`btn py-10px btn-sm btn-block mt-3 mt-md-0 ${clsx(
					classes.actionButton,
					{
						"bg-white text-danger": ![Constants.DocumentStatus.REJECTED, Constants.DocumentStatus.REQUESTED].includes(item.status),
						"btn-disabled bg-secondary text-muted": [Constants.DocumentStatus.REJECTED, Constants.DocumentStatus.REQUESTED].includes(item.status) || item.type === Constants.DocumentType.READ_ONLY || disabled
					}
				)}`}
				onClick={() => handleStatusChange(Constants.DocumentStatus.REJECTED)}
			>
				<div className={classes.actionButtonText}>
					<FormattedMessage id="REQUEST.DOCUMENT.REJECT" />
				</div>
			</button>
		</React.Fragment>
	)
}

function ItemCard(props) {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	
	const flags = useSelector(getFlags);
	const request = useSelector(getSelectedRequest);
	const bankStatementUrl = useSelector(getBankStatementUrl);
	
	const [tooltipStatus, setTooltipStatus] = useState(false);
	const [fileRenameTooltipStatus, setFileRenameTooltipStatus] = useState(false);

	const {
		className,
		isEdit,

		item,
		handleNameChange,
		handleTypeChange,
		handleDelete,
		handleMessageChange,
		handleStatusChange,
		
		selectFileForPreview,
		selectAttachmentForPreview,
		
		uploadFiles,
		handleDeleteFile,
		isUploading,
		
		handleAddAttachments,
		handleDeleteAttachment,
		// isAttachmentUploading,
		
		handleBulkDownload,

		handleFileRenameAdd,
		handleFileRenameChange,
		handleFileRenameRemove,

		error = {},
		touched = {},
	} = props;
	const isRequestDisabled = isEdit ? request.archived : false;

	const classes = useStyles();

	const onFileSelect = useCallback(async (selectedFiles) => {
		const promises = selectedFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		uploadFiles(files);
	}, [
		uploadFiles,
	])

	const {
		getRootProps: getFileInputRootProps,
		getInputProps: getFileInputProps,
		isDragActive: fileInputIsDragActive
	} = useDropzone({
		onDrop: onFileSelect,
		noClick: false,
		disabled: item.type === Constants.DocumentType.READ_ONLY || isRequestDisabled
	});

	const onAttachmentDrop = useCallback(async (acceptedFiles) => {
		const validFiles = acceptedFiles.filter((file) => isAttachmentValid(file));

		acceptedFiles
			.filter((file) => !validFiles.includes(file))
			.forEach((file) => {
				const msg = intl.formatMessage({
					id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
				}, file);
				enqueueSnackbar(msg, { variant: 'error' })
			});

		const promises = validFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		handleAddAttachments(files);
	}, [
		enqueueSnackbar,
		handleAddAttachments,
		intl,
	]);

	const getDescriptionInputHeight = useCallback(() => {
		if (isEdit) {
			if (!!item._id) {
				return 6;
			}
			else {
				return 10;
			}
		}
		else {
			if (item.type === Constants.DocumentType.BANK_STATEMENTS && bankStatementUrl) {
				return 10;
			}
			else {
				return 13;
			}
		}
	}, [
		item,
		isEdit,
		bankStatementUrl,
	])

	const getTotalAttachmentSize = useCallback(() => {
		const totalSize = item.attachments.reduce((p, attachment) => {
			const attachmentSizeInB = attachment._id ? attachment.size * 1024 : attachment.size;
			return p + attachmentSizeInB;
		}, 0);

		return totalSize;
	}, [
		item
	]);

	const {
		getRootProps: getAttachmentInputRootProps,
		getInputProps: getAttachmentInputProps,
		isDragActive: attachmentInputIsDragActive
	} = useDropzone({
		onDrop: onAttachmentDrop,
		noClick: false,
		disabled: isRequestDisabled
	});

	const fileRenameHintStrings = [
		item.fileRename.length > 0
			? intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.HINT_TITLE" })
			: intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.EMPTY.HINT_TITLE" }),
		...getDummyFileRenameStrings(item.fileRename),
	];

	return (
		<Card
			elevation={0}
			className={`document-card border rounded-card ${className} ${(fileInputIsDragActive || attachmentInputIsDragActive) && !isUploading ? 'border-primary' : ''}`}
		>
			<CardContent className="d-flex">
				<div
					className={clsx(
						"row mt-10px mx-auto col-12 px-0"
					)}
				>
					<div className="d-md-flex d-block col-12">
						<div className={"order-1 col-12 col-md-6 pl-0 pr-0 pr-md-3"}>
							<Input
								value={item.name}
								onChange={handleNameChange}
								containerClass="d-flex flex-column flex-grow-1"
								classes={{
									root: 'MuiTextField-bg-white condensed-textarea flex-grow-1 d-flex flex-column',
								}}
								InputProps={{
									classes: {
										root: "blue-border"
									},
								}}
								labelId="REQUEST.PROVIDER.ITEM.NAME"
								labelClass="mb-15px"
								className="m-0 w-100"
								variant="outlined"
								disabled={isRequestDisabled}
								helperText={touched.name && error.name}
								error={Boolean(touched.name && error.name)}
							/>

							{
								isEdit && !!item._id && (
									<div className={clsx(classes.status, "d-inline-block py-5px px-15px")}>
										<FormattedMessage
											id={`REQUEST.DOCUMENT.STATUS.${item.status}${(item.status === Constants.DocumentStatus.APPROVED && item.markNotRequiredReason ? "_NOT_REQUIRED" : "")}`}
											values={{
												dateString: moment(item.createdAt).format('DD/MM/YYYY'),
												name: item.submittedBy
											}}
										>
											{
												text => (
													<span
														className={`${clsx({
															"text-warning": [Constants.DocumentStatus.NOT_REQUIRED, Constants.DocumentStatus.APPROVED].includes(item.status) && item.markNotRequiredReason,
															"text-danger": item.status === Constants.DocumentStatus.REJECTED,
															"text-success": item.status === Constants.DocumentStatus.APPROVED && !item.markNotRequiredReason,
															"text-primary": [Constants.DocumentStatus.UPLOADED, Constants.DocumentStatus.REQUESTED].includes(item.status),
														})}`}
													>
														{text}

														<ClickAwayListener onClickAway={() => setTooltipStatus(false)}>
															<span>
																{
																	[Constants.DocumentStatus.NOT_REQUIRED, Constants.DocumentStatus.APPROVED].includes(item.status) &&
																	item.markNotRequiredReason && (
																		<LightTooltip
																			className="ml-2"
																			open={tooltipStatus}
																			title={item.markNotRequiredReason}
																			placement="right"
																		>
																			<span onClick={() => setTooltipStatus(true)}>
																				<i className="fas fa-info-circle cursor-pointer" />
																			</span>
																		</LightTooltip>
																	)
																}

																{
																	item.status === Constants.DocumentStatus.REJECTED && (
																		<LightTooltip
																			className="ml-2"
																			open={tooltipStatus}
																			title={item.rejectedReason}
																			placement="right"
																			arrow
																		>
																			<span onClick={() => setTooltipStatus(true)}>
																				<i className="fas fa-info-circle cursor-pointer" />
																			</span>
																		</LightTooltip>
																	)
																}
															</span>
														</ClickAwayListener>
													</span>
												)
											}
										</FormattedMessage>
									</div>
								)
							}
						</div>

						<div className="flex-grow-1 mt-4 mt-md-0 order-2 d-flex justify-content-md-start justify-content-center align-items-center col-12 col-md-4 px-0 pl-md-3">
							<div className="dark-custom-input form-group flex-grow-1">
								<div className="dark-input-label mb-10px d-flex align-items-center">
									<FormattedMessage id="REQUEST.PROVIDER.ITEM.TYPE" />
								</div>

								<Select
									className="blue-border"
									value={item.type || ''}
									classes={{
										root: `d-flex align-items-center bg-white`
									}}
									name="template"
									variant="outlined"
									onChange={(e) => handleTypeChange(e.target.value)}
									fullWidth
									placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
								>
									<MenuItem value={Constants.DocumentType.REGULAR}>
										<FormattedMessage id="TEMPLATE.TYPE.REGULAR" />
									</MenuItem>
									<MenuItem value={Constants.DocumentType.READ_ONLY}>
										<FormattedMessage id="TEMPLATE.TYPE.READ_ONLY" />
									</MenuItem>
									{
										bankStatementUrl && (
											<MenuItem value={Constants.DocumentType.BANK_STATEMENTS}>
												<FormattedMessage id="TEMPLATE.TYPE.BANK_STATEMENTS" />
											</MenuItem>
										)
									}
								</Select>
							</div>
						</div>
					</div>

					<div
						className={clsx(
							"col-12 order-3",
							{
								"col-md-6": isEdit && !!item._id,
								"d-flex flex-md-row flex-column": !isEdit || (isEdit && !item._id)
							}
						)}
					>
						<div
							className={clsx(
								`description`,
								{
									"d-flex flex-column flex-grow-1": isEdit,
									"col-md-6 col-12 pl-0 pr-0 pr-md-3": !isEdit || (isEdit && !item._id),
								}
							)}
						>
							<Input
								value={item.description}
								onChange={handleMessageChange}
								InputProps={{
									classes: {
										root: "blue-border"
									},
								}}
								containerClass="d-flex flex-column flex-grow-1"
								classes={{
									root: 'MuiTextField-bg-white condensed-textarea flex-grow-1 d-flex flex-column',
								}}
								labelId="REQUEST.DOCUMENT.DESCRIPTION"
								labelClass="mb-15px"
								multiline
								className="m-0 w-100"
								rows={getDescriptionInputHeight()}
								variant="outlined"
								disabled={isRequestDisabled}
							/>

							{
								item.type === Constants.DocumentType.BANK_STATEMENTS && (
									<div className="mt-10px d-flex align-items-center">
										<div className="dark-input-label mr-2">
											<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL" />
										</div>
										<div>
											<a
												href={item.refData ? item.refData.bankStatementsUrl : bankStatementUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL_TEXT" />
											</a>
										</div>
									</div>
								)
							}
						</div>
						
						<div
							className={clsx(
								"my-4 d-md-flex d-block flex-md-column flex-grow-1 pl-md-2 px-0",
								{
									"my-md-0": !isEdit || (isEdit && !item._id),
									"col-md-6 col-12 pr-0 pl-0 pl-md-3": !isEdit,
								}
							)}
						>
							<div className="dark-input-label mb-10px d-flex align-items-center">
								<FormattedMessage id="PROVIDER.ATTACHMENTS.TITLE" />

								<SizeIndicator
									className={clsx(classes.sizeContainer, "px-2 py-1 text-primary ml-10px")}
									sizeInBytes={getTotalAttachmentSize()}
									hideIfZero
								/>
							</div>

							<div
								className={clsx(
									classes.fileListContainer,
									"p-10px position-relative flex-grow-1",
									{
										"d-flex justify-content-center align-items-center": !item.attachments.length
									}
								)}
								{...getAttachmentInputRootProps()}
							>
								<input {...getAttachmentInputProps()} />
								{
									item.attachments.length > 0 ? (
										<div className={classes.filesList}>
											{
												item.attachments.map((file, index, arr) => (
													<div
														key={index}
														className={clsx({
															"mb-5px": index !== arr.length - 1
														})}
													>
														<FileCard
															canDownload={file._id}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();

																selectAttachmentForPreview(index)
															}}
															onDelete={!file._id ? () => handleDeleteAttachment(index) : null}
															itemId={item._id}
															templateId={item.templateId}
															file={file}
															isAttachment
														/>
													</div>
												))
											}
										</div>
									) : (
										<div className={clsx(classes.emptyListContainer, "w-100 h-100 d-flex align-items-center justify-content-center flex-column")}>
											<div className="mb-15px text-center">
												<img alt="no_document_icon" src={EmptyDocumentImage} />
											</div>
											<div className="text-center message text-muted">
												<FormattedMessage id="REQUEST.DOCUMENT.EMPTY_ATTACHMENTS.MESSAGE" />
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>

					{
						isEdit && !!item._id && (
							<div className={"col-12 col-md-6 d-flex flex-column-reverse flex-md-column justify-content-between align-items-center order-4"}>
								<div className="d-flex w-100 flex-grow-1 justify-content-center align-items-top align-items-top pt-md-3 mt-md-3">
									<ItemButtons
										item={item}
										handleStatusChange={handleStatusChange}
										isUploading={isUploading}
										uploadFiles={onFileSelect}
										disabled={isRequestDisabled}
									/>
								</div>

								<div className="w-100 mb-0 mb-md-4">
									<div className="dark-input-label mb-10px">
										<FormattedMessage id="PROVIDER.FILES.TITLE" />
									</div>

									<div
										className={clsx(
											classes.fileListContainer,
											"p-10px position-relative w-100",
											{
												"d-flex justify-content-center align-items-center": !item.files.length
											}
										)}
										{...getFileInputRootProps()}
									>
										<input {...getFileInputProps()} />
										{
											item.files.length > 0 ? (
												<div className={classes.filesList}>
													{
														item.files.map((file, index, arr) => {
															return (
																<div
																	key={index}
																	className={clsx({
																		"mb-5px": index !== arr.length - 1
																	})}
																>
																	<FileCard
																		canDownload
																		onClick={(e) => {
																			e.preventDefault();
																			e.stopPropagation();
																			selectFileForPreview(index)
																		}}
																		file={file}
																		itemId={item._id}
																		onDelete={isRequestDisabled ? null : () => handleDeleteFile(index)}
																	/>
																</div>
															)
														})
													}
												</div>
											) : (
												<div className={clsx(classes.emptyListContainer, "w-100 h-100 d-flex align-items-center justify-content-center flex-column")}>
													<div className="mb-15px text-center">
														<img alt="no_document_icon" src={EmptyDocumentImage} />
													</div>
													<div className="text-center message text-muted">
														<FormattedMessage id="REQUEST.DOCUMENT.EMPTY_FILES.MESSAGE" />
													</div>
												</div>
											)
										}
									</div>
								</div>
							</div>
						)
					}

					{
						(isEdit && item._id && item.files && item.files.length >= 2) && (
							<div className={"d-flex col-12 mt-md-2 mt-4 order-7 justify-content-end align-items-center"}>
								<Fab
									className={clsx(
										"bg-white text-primary",
										classes.downloadAllFilesButton,
										{
											// "disabled btn-disabled": flags.loading[requestFlagName.BULK_DOWNLOAD] && flags.loading[requestFlagName.BULK_DOWNLOAD] !== item._id
										}
									)}
									onClick={() => handleBulkDownload(item)}
								>
									{
										flags.loading[requestFlagName.BULK_DOWNLOAD] &&
										flags.loading[requestFlagName.BULK_DOWNLOAD][item._id] === true ? (
											<CircularProgress size={10} />
										) : (
											<CloudDownloadIcon />
										)
									}
								</Fab>
							</div>
						)
					}

					<div className="col-12 mt-20px order-8 mt-4">
						<div className="dark-input-label mb-10px">
							<FormattedMessage id="TEMPLATE.FILE_RENAME" />

							<ClickAwayListener onClickAway={() => setFileRenameTooltipStatus(false)}>
								<LightTooltip
									className="ml-2"
									open={fileRenameTooltipStatus}
									title={
										<div>
											{fileRenameHintStrings.map((str, index) => <div key={index}>{str}</div>)}
										</div>
									}
									placement="right"
								>
									<span onClick={() => setFileRenameTooltipStatus(true)}>
										<i className="fas fa-info-circle cursor-pointer" />
									</span>
								</LightTooltip>
							</ClickAwayListener>
						</div>

						<div className="row align-items-center">
							{
								item.fileRename.map((item, index) => {
									return (
										<div key={index} className="col-md-3 col-sm-6 col-12 mb-15px">
											<CustomAutocomplete
												value={item}
												options={Constants.FILE_RENAME_OPTIONS}
												freeSolo
												disableClearable
												classes={{
													inputRoot: `p-0 ${classes.autoComplete}`,
													input: "px-10px py-15px bg-transparent"
												}}
												disabled={isRequestDisabled}
												onChange={(value) => handleFileRenameChange(index, value)}
												inputProps={{
													fullWidth: true,
													type: "text",
													variant: "outlined",
													onBlur: (e) => {
														if (!item && e.target.value) {
															handleFileRenameChange(index, e.target.value)
														}
													}
												}}
											/>

											<div
												className={`shadow cursor-pointer ${classes.removeFileRenameButton}`}
												onClick={() => handleFileRenameRemove(index)}
											>
												<CancelCircleIcon
													size={20}
													fill="white"
												/>
											</div>
										</div>
									);
								})
							}

							{
								!isRequestDisabled && item.fileRename.length < Constants.FILE_RENAME_LIMIT && (
									<div className="col-3 mb-15px">
										<Fab
											classes={{
												root: classes.addFileRenameButton
											}}
											size="small"
											color="primary"
											onClick={handleFileRenameAdd}
										>
											<PlusIcon />
										</Fab>
									</div>
								)
							}
						</div>
					</div>
				</div>

				{
					!isRequestDisabled && (
						<div
							className={clsx(classes.cancelIcon, "cursor-pointer")}
							onClick={handleDelete}
						>
							<CancelIcon />
						</div>
					)
				}
			</CardContent>
		</Card>
	)
}

export default ItemCard;