import _ from "lodash";
import axios from "axios";
import * as Sentry from "@sentry/react";

import * as RequestsCRUD from '../../../crud/requests.crud';
import * as ProviderCRUD from "../../../crud/provider.crud";
import { setErrorFlag, setLoadingFlag } from './loading-flag.actions';
import { uploadFileTask, uploadFileTaskResult } from "./tasks.actions";

export const actionTypes = {
	CHECK: "[PROVIDER] CHECK",
	PROVIDER_DATA: "[PROVIDER] PROVIDER_DATA",
	PROVIDER_MESSAGE_ADDED: "[PROVIDER] PROVIDER_MESSAGE_ADDED",
	PROVIDER_MESSAGES_LOADED: "[PROVIDER] PROVIDER_MESSAGES_LOADED",
	DOCUMENT_UPDATED: "[PROVIDER] DOCUMENT_UPDATED",
	ELEMENT_UPDATED: "[PROVIDER] ELEMENT_UPDATED",
	FORGOT_PASSWORD: "[PROVIDER] FORGOT_PASSWORD",
	RESET_PASSWORD: "[PROVIDER] RESET_PASSWORD",
	SET_DOCUMENTS_WITH_ERROR: "[PROVIDER] SET_DOCUMENTS_WITH_ERROR",
	SET_ELEMENTS_WITH_ERROR: "[PROVIDER] SET_ELEMENTS_WITH_ERROR",
	SAVE_PROVIDER_RESPONSE: "[PROVIDER] SAVE_PROVIDER_RESPONSE",
	PERFORM_OPERATION: "[PROVIDER] PERFORM_OPERATION",
	ELEMENT_COMMENTS_LOADED: "[PROVIDER] ELEMENT_COMMENTS",
	ELEMENT_COMMENT_ADDED: "[PROVIDER] ELEMENT_COMMENT_ADDED",
};

export const flagNames = {
	CHECK: '[PROVIDER] CHECK',
	SET_PASSWORD: '[PROVIDER] SET_PASSWORD',
	PROVIDER_DATA: '[PROVIDER] PROVIDER_DATA',
	PROVIDER_ADD_MESSAGE: "[PROVIDER] PROVIDER_ADD_MESSAGE",
	PROVIDER_MESSAGES:"[PROVIDER] PROVIDER_MESSAGES",
	UPLOAD: "[PROVIDER] UPLOAD",
	UPDATE: "[PROVIDER] UPDATE",
	UPDATE_STATUS: "[PROVIDER] UPDATE_STATUS",
	FORGOT_PASSWORD: "[PROVIDER] FORGOT_PASSWORD",
	RESET_PASSWORD: "[PROVIDER] RESET_PASSWORD",
	SAVE_PROVIDER_RESPONSE: "[PROVIDER] SAVE_PROVIDER_RESPONSE",
	ELEMENT_COMMENTS: "[REQUEST] ELEMENT_COMMENTS",
	ELEMENT_ADD_COMMENT: "[REQUEST] ELEMENT_ADD_COMMENT",
};

export const V2_OPERATIONS = {
	NOT_REQUIRED: "NOT_REQUIRED",
	VIEW_COMMENTS: "COMMENTS",
	PREVIEW: "PREVIEW",
};

export const loadCheckData = (requestId, providerId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.CHECK, true));

		try {
			const { status, data } = await RequestsCRUD.checkStatus(requestId, providerId);

			if (status === 200) {
				dispatch({
					type: actionTypes.CHECK,
					payload: {
						data,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.CHECK, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CHECK, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CHECK, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const setProviderPassword = (requestId, providerId, password) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		
		dispatch(setLoadingFlag(flagNames.SET_PASSWORD, true));

		try {
			const { status, data } = await RequestsCRUD.setProviderPassword(requestId, providerId, password, token);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.SET_PASSWORD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.SET_PASSWORD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.SET_PASSWORD, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const getProviderData = (requestId, providerId, password, isRefresh) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		
		dispatch(setLoadingFlag(flagNames.PROVIDER_DATA, true));

		try {
			const { status, data } = await RequestsCRUD.getProviderData(requestId, providerId, password, isRefresh, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_DATA,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_DATA, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_DATA, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_DATA, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const getProviderMessages = (requestId, providerId) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.PROVIDER_MESSAGES, providerId));

		try {
			const { status, data } = await RequestsCRUD.getProviderMessages(requestId, providerId, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_MESSAGES_LOADED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_MESSAGES, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_MESSAGES, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_MESSAGES, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addProviderMessage = (requestId, providerId, message) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		const isChatEnabled = _.get(store, 'provider.data.documentRequest.user.chatService', true);

		if (!isChatEnabled) {
			return;
		}

		dispatch(setLoadingFlag(flagNames.PROVIDER_ADD_MESSAGE, providerId));

		try {
			const { status, data } = await RequestsCRUD.addProviderMessage(requestId, providerId, message, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_MESSAGE_ADDED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_ADD_MESSAGE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_ADD_MESSAGE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_ADD_MESSAGE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const getElementComments = (elementId) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		const provider = _.get(store, 'provider.data', {});

		dispatch(setLoadingFlag(flagNames.ELEMENT_COMMENTS, provider._id));

		try {
			const { status, data } = await ProviderCRUD.getElementComments(
				provider.requestId,
				provider._id,
				elementId,
				token
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_COMMENTS_LOADED,
					payload: {
						comments: data,
						elementId,
					},
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ELEMENT_COMMENTS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ELEMENT_COMMENTS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ELEMENT_COMMENTS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addElementComment = (elementId, comment) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		const provider = _.get(store, 'provider.data', {});

		dispatch(setLoadingFlag(flagNames.ELEMENT_ADD_COMMENT, provider._id));

		try {
			const { status, data } = await ProviderCRUD.addElementComment(provider.requestId, provider._id, elementId, token, comment);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_COMMENT_ADDED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ELEMENT_ADD_COMMENT, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ELEMENT_ADD_COMMENT, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ELEMENT_ADD_COMMENT, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const uploadFile = (documentId, files) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);
		const provider = _.get(store, 'provider.data', null);
		const request = provider.documentRequest;

		const fileData = [];
		const documentToUpdate = store.provider.data.documents.find((doc) => doc._id === documentId);

		dispatch(setLoadingFlag(flagNames.UPLOAD, documentId));

		try {
			for (const file of files) {
				const {
					size,
				} = file;
				const {
					data: {
						key,
						name: uploadedFileName,
						signedRequest,
						url
					}
				} = await ProviderCRUD.uploadFileToDocument(
					request._id,
					provider._id,
					documentToUpdate._id,
					file,
					token,
					fileData
				);

				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);

				if (response.wasCancelled) {
					continue;
				}

				const {
					data: {
						size: uploadedFileSize
					}
				} = await axios.post("/services/get-file-info", {
					key,
				});

				if (uploadedFileSize < 1024) {
					Sentry.captureMessage(`user ${provider._id} uploaded a file which has less than 1kb size in s3`);

					const formData = new FormData();
					formData.append("key", key);
					formData.append("file", file);
					formData.append("token", token);

					await dispatch(
						uploadFileTask(
							'',
							file,
							'Something went wrong, Re-uploading ' + file.name,
							{
								method: "POST",
								url: `${process.env.REACT_APP_SCHEDULER_URL}/api/v1/document-requests/${request._id}/${provider._id}/${documentToUpdate._id}/upload-file`,
								data: formData,
								headers: {
									"Content-Type": "multipart/form-data"
								}
							}
						)
					)
				}

				fileData.push({
					key,
					name: uploadedFileName,
					url,
					size: (size / 1024)
				});
			}

			const { status: updateStatus, data: updateData } = await RequestsCRUD.updateDocument(request._id, provider._id, documentId, token, {
				files: [
					...documentToUpdate.files,
					...fileData,
				],
			});

			if (updateStatus === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_UPDATED,
					payload: updateData.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPLOAD, updateData.message));
			}

			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
			return Promise.resolve({ status: updateStatus, data: updateData });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const uploadFileToElement = (elementUUID, files, uploadId) => {
	return async (dispatch, getStore) => {
		const {
			provider: {
				check: {
					token,
				},
				data: {
					_id: providerId,
					documentRequest: {
						_id: requestId,
					},
					elements,
				},
			}
		} = getStore();

		const fileData = [];
		const {
			_id: elementId,
		} = elements.find(({ uuid }) => uuid === elementUUID);

		dispatch(setLoadingFlag(flagNames.UPLOAD, elementId));

		try {
			for (const file of files) {
				const {
					name: originalName,
					size,
				} = file;
				const {
					data: {
						key,
						name: uploadedFileName,
						signedRequest,
						url
					}
				} = await ProviderCRUD.uploadFileToElement(
					requestId,
					providerId,
					elementId,
					file,
					token,
					fileData
				);

				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);

				if (response.wasCancelled) {
					continue;
				}

				const {
					data: {
						size: uploadedFileSize
					}
				} = await axios.post("/services/get-file-info", {
					key,
				});

				if (uploadedFileSize < 1024) {
					Sentry.captureMessage(`user ${providerId} uploaded a file which has less than 1kb size in s3`);

					const formData = new FormData();
					formData.append("key", key);
					formData.append("file", file);
					formData.append("token", token);

					await dispatch(
						uploadFileTask(
							'',
							file,
							'Something went wrong, Re-uploading ' + file.name,
							{
								method: "POST",
								url: `${process.env.REACT_APP_SCHEDULER_URL}/api/v2/document-requests/${requestId}/${providerId}/${elementId}/upload-file`,
								data: formData,
								headers: {
									"Content-Type": "multipart/form-data"
								}
							}
						)
					)
				}

				fileData.push({
					key,
					originalName,
					name: uploadedFileName,
					url,
					size: (size / 1024)
				});
			}

			dispatch(
				uploadFileTaskResult(
					uploadId,
					fileData,
				)
			);
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
		finally {
			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
		}
	}
}

export const updateDocument = (requestId, providerId, documentId, documentData) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.UPDATE, documentId));

		try {
			const { status, data } = await RequestsCRUD.updateDocument(requestId, providerId, documentId, token, documentData);

			if (status === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateElementStatus = (requestId, providerId, elementId, statusObj) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.UPDATE, elementId));

		try {
			const { status, data } = await ProviderCRUD.updateElementStatus(
				requestId,
				providerId,
				elementId,
				token,
				statusObj
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateElement = (requestId, providerId, elementId, elementData) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.UPDATE, elementId));

		try {
			const { status, data } = await ProviderCRUD.updateElement(
				requestId,
				providerId,
				elementId,
				token,
				elementData
			);

			if (status === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}


export const updateProviderStatus = (requestId, providerId, documentStatus) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.UPDATE_STATUS));

		try {
			const { status, data } = await RequestsCRUD.updateProviderStatus(requestId, providerId, documentStatus, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_DATA,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_STATUS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_STATUS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_STATUS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const forgotPassword = (requestId, providerId, email) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.FORGOT_PASSWORD, true));

		try {
			const { status, data } = await RequestsCRUD.providerForgotPasswordRequest(requestId, providerId, email, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.FORGOT_PASSWORD,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.FORGOT_PASSWORD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.FORGOT_PASSWORD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.FORGOT_PASSWORD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const resetPassword = (requestId, providerId, code, password) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.RESET_PASSWORD, true));

		try {
			const { status, data } = await RequestsCRUD.providerResetPassword(requestId, providerId, code, password, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.RESET_PASSWORD,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.RESET_PASSWORD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.RESET_PASSWORD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.RESET_PASSWORD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const setDocumentsWithError = (documentIds) => {
	return {
		type: actionTypes.SET_DOCUMENTS_WITH_ERROR,
		payload: {
			documentIds
		}
	}
}
export const setElementsWithError = (elementIds) => {
	return {
		type: actionTypes.SET_ELEMENTS_WITH_ERROR,
		payload: {
			elementIds
		}
	}
}

export const saveProviderResponse = (elements) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const requestId = _.get(store, 'provider.data.requestId', null);
		const providerId = _.get(store, 'provider.data._id', null);
		const token = _.get(store, 'provider.check.token', null);

		dispatch(setLoadingFlag(flagNames.SAVE_PROVIDER_RESPONSE, true));

		try {
			const { status, data } = await ProviderCRUD.saveProviderResponse(requestId, providerId, elements, token);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_DATA,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.SAVE_PROVIDER_RESPONSE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.SAVE_PROVIDER_RESPONSE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.SAVE_PROVIDER_RESPONSE, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const performOperation = (action, data) => {
	return {
		type: actionTypes.PERFORM_OPERATION,
		payload: {
			action,
			data,
		},
	}
}
