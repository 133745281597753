import React, { useEffect, useState, } from "react";
import { Icon } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import ReactDOM from "react-dom";

import Input from '../../../Input';
import ElementSettings from '../Settings';

function Settings({ id, videoConfig, handleChangeUrl, videoUrl }) {
	const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
	const [rootElement, setRootElement] = useState(null);

	useEffect(() => {
		// let timerHandle = null;

		// if (!rootElement) {
		// 	timerHandle = setInterval(() => {
				const rootElement = document.getElementById(`element-settings-container-${id}`);
				// if (rootElement) {
					setRootElement(rootElement);
		// 		}
		// 	}, 3000);
		// }

		// return () => {
		// 	clearInterval(timerHandle)
		// };
	}, [
		id,
		rootElement,
	]);

	if (!rootElement) return null;

	return ReactDOM.createPortal(
		<React.Fragment>
			<button
				className={
					clsx(
						"btn btn-light btn-block d-flex align-items-center",
						{
							"pointer-events-none opacity-0": !videoConfig.type
						}
					)
				}
				onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
			>
				<Icon className="mr-2">settings</Icon>
				<span className="f-14px">
					<FormattedMessage id="ELEMENT_LIST.ITEM.SETTINGS" />
				</span>
			</button>

			<ElementSettings
				anchorEl={settingsAnchorEl}
				handleClose={() => setSettingsAnchorEl(null)}
			>
				<div className="text-dark f-16px mb-10px">
					<FormattedMessage
						id="ELEMENT_LIST.ITEM.VIDEO.URL.PLACEHOLDER"
					/>
				</div>
				<Input
					type="text"
					variant="outlined"
					fullWidth
					name="videoUrl"
					onChange={(e) => handleChangeUrl(e.target.value)}
					defaultValue={videoUrl}
				/>
			</ElementSettings>
		</React.Fragment>,
		rootElement
	);
}

export default Settings;