import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import Input from "../../../Input";
import useDebounce from "../../../useDebounce";

const useStyles = makeStyles((theme) => {
	return {
		answerField: {
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.inputFields.readableMaxWidth,
			}
		}
	};
});

const ShortText = forwardRef((props, _ref) => {
	// eslint-disable-next-line no-unused-vars
	const { data, response, status, rejectedReason, markNotRequiredReason, onChange } = props;
	const intl = useIntl();
	const classes = useStyles();
	const [value, setValue] = useState(response && response.value);
	const debouncedValue = useDebounce(value, 750);

	useEffect(() => {
		const responseObj = response || {};
		const newResponse = {
			...responseObj,
			value: debouncedValue,
		};

		onChange(newResponse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedValue,
	]);

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}
			
			<div className="row">
				<div className="col-12">
					<Input
						containerClass={clsx(classes.answerField, "mb-2")}
						type="text"
						variant="outlined"
						fullWidth
						defaultValue={value}
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.SHORT_TEXT.INPUT.PLACEHOLDER"
							})
						}
						name="value"
						onChange={(e) => setValue(e.target.value)}
						InputProps={{
							classes: {
								root: "blue-border",
								input: "bg-white"
							}
						}}
					/>
				</div>
			</div>
		</div>
	)
})

export default ShortText;
