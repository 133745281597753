import React, { useMemo, useEffect } from "react";
import {
	useDispatch, useSelector
} from "react-redux";
import {
	FormattedMessage,
} from "react-intl";
import _ from "lodash";
import { Col, Row } from "reactstrap";
import { Close, Done } from "@material-ui/icons";

import { loadFeatures } from "../../../store/modules/actions/features.actions";

function PlanFeatures({ billingData }) {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadFeatures());
	}, [
		dispatch
	]);
	
	const features = useSelector((state) => state.features.list);

	const featureList = useMemo(() => {
		if (!features || !features.length) {
			return null;
		}

		const list = _.map(billingData.FEATURES, (value, key) => {
			const selectedFeature = features.find((feature) => feature.isActive && feature.key === key);

			return {
				text: selectedFeature ? selectedFeature.name : "",
				isEnabled: value
			};
		})
		.filter((feature) => !!feature.text);

		return list;
	}, [
		billingData.FEATURES,
		features,
	]);

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-20px text-dark">
				<FormattedMessage id="BILLING.PLAN_FEATURES.TITLE" />
			</div>

			{
				featureList ? (
					<div>
						<Row>
							{
								featureList.map((feature, index) => {
									return (
										<Col sm={6} xs={12} className="d-flex align-items-center mb-20px" key={index}>
											<div className="mr-10px">
												{
													feature.isEnabled ? (
														<Done color="primary" />
													) : (
														<Close />
													)
												}
											</div>
											<div className="f-15px text-dark">
												{feature.text}
											</div>
										</Col>
									)
								})
							}
						</Row>
					</div>
				) : (
					<div className="d-flex flex-grow-1 justify-content-center align-items-center pb-4">
						<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
					</div>
				)
			}
		</div>
	);
}

export default PlanFeatures;
