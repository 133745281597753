import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	FormattedHTMLMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	useParams,
} from "react-router-dom"
import {
	Card,
	CardContent,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	setProviderPassword,
	getProviderData,
	flagNames,
} from '../../store/modules/actions/provider.actions';
import Input from "../../common/Input";
import { PASSWORD_REGEX } from "../../../_metronic";
import * as builder from "../../../_metronic/ducks/builder";
import { getFlags } from "../../store/modules/selectors/common.selector";

function SetPassword(props) {
	const params = useParams();
	const {
		request,
		provider
	} = params;
	const {
		handleClose,
		setProviderPassword,
		getProviderData,
		intl,
	} = props;

	const flags = useSelector(getFlags);
	const headerLogo = useSelector(builder.selectors.getLogo)
	const providerDetails = useSelector((store) => store.provider.check.provider);

	const isLoading = flags.loading[flagNames.SET_PASSWORD] || flags.loading[flagNames.PROVIDER_DATA];
	const error = flags.error[flagNames.SET_PASSWORD] || flags.error[flagNames.PROVIDER_DATA];

	return (
		<div className="container px-4">
			<div className="py-50 row flex-grow-1">
				<div className="col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 px-0 pb-50">
					<div className="f-30 strong mb-25 text-center">
						<FormattedMessage id="PROVIDER.SET_PASSWORD.TITLE" />
					</div>
					<div className="m-0 f-15 text-center mb-40">
						<FormattedMessage id="PROVIDER.SET_PASSWORD.DESCRIPTION" values={providerDetails} />
					</div>

					<Card className="rounded-card" elevation={0}>
						<CardContent className='p-25 m-25'>
							<div className="text-center mb-50">
								<h3>
									<FormattedMessage id="PROVIDER.SET_PASSWORD.CARD_TITLE" />
								</h3>
							</div>

							<Formik
								initialValues={{
									password: '',
									confirmPassword: '',
								}}
								validate={values => {
									const errors = {};
									const passwordRegex = PASSWORD_REGEX;

									if (!values.password) {
										errors.password = intl.formatMessage({
											id: "PROVIDER.SET_PASSWORD.PASSWORD.VALIDATION.REQUIRED"
										});
									}
									if (!passwordRegex.test(values.password)) {
										errors.password = intl.formatMessage({
											id: "AUTH.VALIDATION.PASSWORD.WEAK"
										});
									}

									if (values.password !== values.confirmPassword) {
										errors.confirmPassword = intl.formatMessage({
											id: "PROVIDER.SET_PASSWORD.CONFIRM_PASSWORD.VALIDATION.MISMATCH"
										});
									}

									return errors;
								}}
								validateOnChange={false}
								validateOnBlur={true}
								onSubmit={async (values) => {
									await setProviderPassword(request, provider, values.password);
									await getProviderData(request, provider, values.password);
									handleClose(values.password);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue,
								}) => {
									console.log(errors, values);
									return (
										<form
											noValidate={true}
											autoComplete="off"
											onSubmit={handleSubmit}
										>
											<div className="container">
												<div className="row">
													<div className="col-12">
														<Input
															containerClass="mb-25"
															type="password"
															variant="outlined"
															labelId={"PROVIDER.SET_PASSWORD.PASSWORD"}
															fullWidth
															name="password"
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.password}
															helperText={touched.password && errors.password}
															error={Boolean(touched.password && errors.password)}
														/>
													</div>

													<div className="col-12">
														<Input
															containerClass="mb-25"
															type="password"
															variant="outlined"
															labelId={"PROVIDER.SET_PASSWORD.CONFIRM_PASSWORD"}
															fullWidth
															name="confirmPassword"
															onBlur={handleBlur}
															onChange={handleChange}
															value={values.confirmPassword}
															helperText={touched.confirmPassword && errors.confirmPassword}
															error={Boolean(touched.confirmPassword && errors.confirmPassword)}
														/>
													</div>
												</div>

												{
													error && (
														<Alert color="danger" className="d-flex justify-content-center mb-25">
															{error}
														</Alert>
													)
												}

												<div className="pt-20 d-flex justify-content-center">
													<button
														type="submit"
														disabled={isLoading}
														className={`btn btn-primary btn-elevate kt-login__btn-primary py-3 ${clsx(
															{
																"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
															}
														)}`}
													>
														<FormattedMessage id="PROVIDER.SET_PASSWORD.SUBMIT" />
													</button>
												</div>
											</div>
										</form>
									);
								}}
							</Formik>
						</CardContent>
					</Card>

					<div className="pt-50px d-flex flex-column align-items-center justify-content-center">
						<div className="f-18px">
							<FormattedHTMLMessage id="APP.PROVIDER.SET_PASSWORD_FOOTER" />
						</div>
						
						<div className="mt-15px d-flex">
							<div className="mr-15px d-flex align-items-center">
								<img
									src={headerLogo}
									style={{ height: 30, width: 30 }}
									alt="company_logo"
								/>
							</div>
							<div className="text-dark f-24px">
								<FormattedMessage id="APP.NAME" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(
	connect(
		null,
		{
			setProviderPassword,
			getProviderData,
		}
	)(SetPassword)
)
