import React, { useCallback, useEffect, useMemo, useRef, useState, forwardRef } from "react";
import { FormGroup, FormControlLabel, makeStyles, TextareaAutosize } from "@material-ui/core";
import { useIntl } from "react-intl";
import clsx from "clsx";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import Checkbox from "./Checkbox";
import { CUSTOM_OPTION_ID } from ".";
import useDebounce from "../../../useDebounce";

const useStyles = makeStyles((theme) => {
	return {
		customOptionContainer: {
			position: 'relative',
			left: '-2px',
		},
		customOptionCheckbox: {
			position: 'relative',
			top: 3,
		},
		checkboxes: {
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.checkboxes.maxWidth,
			}
		}
	};
});

const CheckboxElement = forwardRef((props, _ref) => {
	const {
		data,
		response,
		onChange,
	} = props;
	const intl = useIntl();
	const classes = useStyles();
	const customOptionInputRef = useRef();
	const hasCustomOption = useMemo(() => {
		return data.options.some(({ id }) => id === CUSTOM_OPTION_ID);
	}, [data.options]);

	const [customOptionTitle, setCustomOptionTitle] = useState(response.custom);
	const [selectedOptions, setSelectedOptions] = useState(response.value || []);
	const debouncedCustomTitle = useDebounce(customOptionTitle, 750);
	const debouncedSelectedOptions = useDebounce(selectedOptions, 750);

	useEffect(() => {
		const newData = {
			...response,
			custom: debouncedCustomTitle,
		};

		onChange(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ debouncedCustomTitle ]);

	useEffect(() => {
		const newData = {
			...response,
			value: debouncedSelectedOptions,
		};

		onChange(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSelectedOptions])

	const handleToggleCheckbox = useCallback((value) => {
		const newValue = selectedOptions.slice();
		const index = selectedOptions.indexOf(value);

		if (index === -1) {
			newValue.push(value);
		}
		else {
			newValue.splice(index, 1);
		}

		setSelectedOptions(newValue);
	}, [selectedOptions]);

	return (
		<div>
			<div className="d-flex align-items-center mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="row">
				<div className="col-12">
					<FormGroup className={classes.checkboxes}>
						{
							data.options
								.filter(({ id }) => id !== CUSTOM_OPTION_ID)
								.map((option) => {
									const isSelected = selectedOptions.includes(option.id);

									return (
										<FormControlLabel
											key={option.id}
											classes={{
												root: "row",
												label: `f-16px text-dark ${isSelected ? 'font-weight-medium' : 'font-weight-regular'} col pl-0 break-word`
											}}
											control={
												<Checkbox
													className="py-0"
													checked={isSelected}
													color="primary"
												/>
											}
											label={option.title}
											onChange={() => handleToggleCheckbox(option.id)}
										/>
									)
								})
						}

						{
							hasCustomOption && (
								<div className={clsx(classes.customOptionContainer, "d-flex")}>
									<div className={classes.customOptionCheckbox}>
										<Checkbox
											className="py-0 pl-0 pr-2"
											checked={selectedOptions.includes(CUSTOM_OPTION_ID)}
											color="primary"
											onClick={() => customOptionInputRef.current?.focus()}
											onChange={() => handleToggleCheckbox(CUSTOM_OPTION_ID)}
										/>
									</div>

									<div>
										<TextareaAutosize
											ref={(ref) => customOptionInputRef.current = ref}
											minRows={1}
											className="span mb-0 f-16px text-light-text w-100 resize-none border-0 bg-transparent"
											onChange={(e) => setCustomOptionTitle(e.target.value)}
											defaultValue={customOptionTitle}
											placeholder={
												intl.formatMessage({
													id: "ELEMENT_LIST.ITEM.CHECKBOXES.CHOICES.CUSTOM_CHOICE.TITLE.PLACEHOLDER"
												})
											}
										/>
									</div>
								</div>
							)
						}
					</FormGroup>
				</div>
			</div>
		</div>
	)
})

export default CheckboxElement;
