import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Alert } from "reactstrap";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import sanitizeHtml from 'sanitize-html';

import KTOffcanvas from "../../../../../_metronic/_assets/js/offcanvas";
import {
  flagNames, loadLogsHistory,
} from "../../../../store/modules/actions/requests.actions";
import AccordionComponent from "../../../../common/Accordion";
import { getFlags } from "../../../../store/modules/selectors/common.selector";
import { getRequestLogs } from "../../../../store/modules/selectors/request.selector";
import { getUserId } from "../../../../store/modules/selectors/account.selector";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

const useStyles = makeStyles((theme) => {
	return {
    log: {
      '& .title': {
        '& a': {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },
      '& .content': {
        '& a': {
          color: 'inherit',
          textDecoration: 'underline',
        },
      },

      '& .description pre': {
        color: 'inherit',
        marginBottom: 0,
        whiteSpace: 'pre-line',
      }
    },
    highlighted: {
      background: `${theme.palette.error.main}0F`,
    },
		date: {
			background: theme.palette.extraColors.backgroundBlue,
		},
	}
});

function Log ({ createdAt, description, content, shouldHighlight }) {
  const classes = useStyles();
  const [seeMoreStatus, setSeeMoreStatus] = useState(false);
  const dateString = useMemo(() => moment(createdAt).format("MMM DD, YYYY"), [createdAt]);
  const timeString = useMemo(() => moment(createdAt).format("hh:mm A"), [createdAt]);
  const sanitizedContent = useMemo(() => sanitizeHtml(content), [content]);
  const sanitizedDescription = useMemo(() => sanitizeHtml(description).trim(), [description]);
  
  return (
    <div className={clsx(classes.log, { [`${classes.highlighted} py-3`]: shouldHighlight })}>
      <div className="mx-4">
        <div className="d-flex justify-content-start mb-2">
          <div className={clsx(classes.date, "px-2 py-1 f-14px")}>
            {dateString}
          </div>
        </div>

        <div className="mb-2 f-12px">
          {timeString}
        </div>
        
        {
          !!sanitizedContent && (
            <div
              className="mb-2 f-14px text-muted content"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
          )
        }

        {
          !!sanitizedDescription && (
            <AccordionComponent
              title={
                <div className="text-muted">
                  <FormattedMessage id={seeMoreStatus ? "APP.LOGS_HISTORY.LOG.SEE_LESS" : "APP.LOGS_HISTORY.LOG.SEE_MORE" } />
                </div>
              }
              onExpandStatusChange={(isExpanded) => setSeeMoreStatus(isExpanded)}
              content={
                <div
                  className="f-14px text-muted description"
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                />
              }
            />
          )
        }
        
      </div>
    </div>
  )
}

function LogsHistoryList ({
  loading,
  error,
  logs,
  totalCount,
  onLoadMore,
}) {
  const loggedInUserId = useSelector(getUserId);

  return (
    <div className="kt-quick-panel__content pt-0">
      {
        !!error && (
          <div className="d-flex justify-content-center mt-2">
            <Alert color="danger">
              {error}
            </Alert>
          </div>
        )
      }

      <PerfectScrollbar
        options={perfectScrollbarOptions}
        style={{
          maxHeight: "calc(100vh - 100px)",
          position: "relative"
        }}
      >
        <div>
          {
            logs.map((log) => (
                <div className="my-25" key={log._id}>
                  <Log
                    shouldHighlight={log.userId !== loggedInUserId}
                    createdAt={log.createdAt}
                    content={log.content}
                    description={log.description}
                  />
                </div>
              )
            )
          }
        </div>

        {
          (!loading && !error && logs.length < totalCount) && (
            <div
              className="mt-1 text-center text-primary cursor-pointer"
              onClick={() => onLoadMore()}
            >
              <FormattedMessage id="APP.NOTIFICATIONS.LOAD_MORE" />
            </div>
          )
        }
      </PerfectScrollbar>

      {
        loading && (
          <div className="mt-15 kt-spinner kt-spinner--center kt-spinner--md kt-spinner--brand"></div>
        )
      }
    </div>
  );
}

function LogsHistory ({ providerId }) {
  const [offCanvas, setOffCanvas] = useState();
  const dispatch = useDispatch();

  const flags = useSelector(getFlags);
  const logs = useSelector(getRequestLogs); 
  const loading = useMemo(() => flags.loading[flagNames.LOAD_LOGS_HISTORY], [flags.loading]);
  const error = useMemo(() => flags.error[flagNames.LOAD_LOGS_HISTORY], [flags.error]);
  
  useEffect(() => {
    // eslint-disable-next-line
    const panel = document.getElementById("kt_logs_history_panel");

    // eslint-disable-next-line
    const offCanvas = new KTOffcanvas(panel, {
      overlay: true,
      baseClass: "kt-quick-panel",
      closeBy: "kt_logs_history_panel_close_btn",
      toggleBy: [
        "kt_logs_history_toggler_btn",
        "kt_logs_history_toggler_btn_mobile"
      ],
    });

    setOffCanvas(offCanvas);
  }, []);
  
  useEffect(() => {
    if (!offCanvas) return;

    offCanvas.on('beforeShow', () => {
      dispatch(loadLogsHistory(providerId));
      
      const toggler = document.getElementById('kt_logs_history_toggler_btn');
      
      if (toggler) {
        toggler.style.opacity = 0;
      }
    });

    offCanvas.on('afterHide', () => {
      const toggler = document.getElementById('kt_logs_history_toggler_btn');
      
      if (toggler) {
        toggler.style.opacity = 100;
      }
    });
  }, [
    providerId,
    dispatch,
    offCanvas,
  ]);

  return (
    <div id="kt_logs_history_panel" className="kt-quick-panel">
      <div
        className="d-flex justify-content-start align-items-center p-4 cursor-pointer"
        id="kt_logs_history_panel_close_btn"
      >
        <div className="mr-2">
          <span className="material-icons">
            keyboard_double_arrow_right
          </span>
        </div>
        <div>
          <FormattedMessage id="APP.LOGS_HISTORY.HIDE" />
        </div>
      </div>

      <LogsHistoryList
        loading={loading}
        error={error}
        logs={logs}
        totalCount={logs.length}
        onLoadMore={() => {}}
      />
    </div>
  );
}

export default LogsHistory;