import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThumbUpAltOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";

import {
	flagNames,
	performOperation,
	updateElementStatus,
} from '../../../../store/modules/actions/requests.actions';
import Constants from "../../../../common/constants";
import Confirm from "../../../../common/modals/confirm";
import { getFlags } from "../../../../store/modules/selectors/common.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";

export default function AcceptElement({
	providerUUID,
	element,
}) {
	const dispatch = useDispatch();
	const theme = useTheme();

	const flags = useSelector(getFlags);
	const request = useSelector(getSelectedRequest);
	const providerId = useMemo(() => {
		const providers = request?.documentProviders;
		const provider = providers?.find(({ uuid }) => uuid === providerUUID);
		return provider?._id;
	}, [providerUUID, request]);
	
	const loading = flags.loading[flagNames.UPDATE_ELEMENT_STATUS];
	const error = flags.error[flagNames.UPDATE_ELEMENT_STATUS];

	const handleClose = useCallback(() => {
		dispatch(performOperation());
	}, [
		dispatch,
	]);

	const handleSubmit = useCallback(async () => {
		await dispatch(
			updateElementStatus(
				providerId,
				element._id,
				{ status: Constants.DocumentStatus.APPROVED }
			)
		);
	}, [dispatch, element, providerId]);

	return (
		<Confirm
			open
			icon={
				<ThumbUpAltOutlined
					style={{
						color: theme.palette.success.main,
						fontSize: 65
					}}
				/>
			}
			variant="success"
			handleClose={async (wasSubmitted) => {
				if (wasSubmitted) {
					await handleSubmit();
				}
				handleClose(wasSubmitted);
			}}
			title="REQUEST.ELEMENT.APPROVE.MESSAGE"
			submitButtonText="GENERAL.CONFIRM"
			cancelButtonText="GENERAL.CANCEL"
			loading={loading}
			error={error}
		/>
	);
}