import CloudDownload from "./CloudDownload";
import Delete from "./Delete";
import WarningBlocks from "./WarningBlocks";
import DangerBlocks from "./DangerBlocks";
import Plus from "./Plus";
import Edit from "./Edit";
import Cancel from "./Cancel";
import CancelCircle from "./CancelCircle";
import HourGlass from "./HourGlass";
import Calendar from "./Calendar";
import Chat from "./Chat";
import Envelope from "./Envelope";
import FileError from "./FileError";
import ArrowRight from "./ArrowRight";
import Upload from "./Upload";
import CircleCheck from "./CircleCheck";
import FileZip from "./FileZip";
import Search from "./Search";
import Settings from "./Settings";
import Info from "./Info";
import SMS from "./SMS";
import ShortText from "./ShortText";
import Kaddim from "./Kaddim";
import SmartDisplay from "./SmartDisplay";
import FolderUpload from "./FolderUpload";

export const CloudDownloadIcon = CloudDownload;
export const DeleteIcon = Delete;
export const WarningBlocksIcon = WarningBlocks;
export const DangerBlocksIcon = DangerBlocks;
export const PlusIcon = Plus;
export const EditIcon = Edit;
export const CancelIcon = Cancel;
export const CancelCircleIcon = CancelCircle;
export const HourGlassIcon = HourGlass;
export const CalendarIcon = Calendar;
export const ChatIcon = Chat;
export const EnvelopeIcon = Envelope;
export const FileErrorIcon = FileError;
export const ArrowRightIcon = ArrowRight;
export const UploadIcon = Upload;
export const CircleCheckIcon = CircleCheck;
export const FileZipIcon = FileZip;
export const SearchIcon = Search;
export const SettingsIcon = Settings;
export const InfoIcon = Info;
export const SMSIcon = SMS;
export const ShortTextIcon = ShortText;
export const KaddimIcon = Kaddim;
export const SmartDisplayIcon = SmartDisplay;
export const FolderUploadIcon = FolderUpload;