import { actionTypes } from "./actions/app.actions";

const initialAuthState = {
	upgradeModalMessageId: null,
};

export const reducer = (state = initialAuthState, action) => {
	switch (action.type) {
		case actionTypes.SET_UPGRADE_DIALOG: {
			const { messageId } = action.payload;
			
			return {
				...state,
				upgradeModalMessageId: messageId,
			};
		}

		default:
			return state;
	}
}