import React, {
  useState,
  useEffect,
} from "react";
import {
  Formik
} from "formik";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  Link,
  useHistory
} from "react-router-dom";
import {
  FormattedMessage, useIntl,
} from "react-intl";
import clsx from "clsx";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  MenuItem,
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Alert,
} from "reactstrap";
import queryString from 'query-string';

import Input from "../../common/Input";
import AgreementModal from "./AgreementModal";
import { register, flagNames } from "../../store/modules/actions/auth.actions";
import { getLatestPP, getLatestTOS } from "../../crud/auth.crud";
import { globalGA, PASSWORD_REGEX } from "../../../_metronic";
import Select from "../../common/Select";
import { getFlags } from "../../store/modules/selectors/common.selector";

function Registration(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [agreementType, setAgreementType] = useState(false);
  const [agreementData, setAgreementData] = useState(false);
  const queryStringObj = queryString.parse(window.location.search);

	const flags = useSelector(getFlags);
  
  useEffect(() => {
    async function fetchData() {
      const [
        latestPP,
        latestTOS,
      ] = await Promise.all([
        getLatestPP(),
        getLatestTOS(),
      ]);
    
      setAgreementData({
        pp: latestPP,
        tos: latestTOS
      });
    }

    fetchData();
  }, []);

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="text-center mb-50">
        <h3>
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
      </div>

      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          companyName: "",
          phone: "",
          acceptTerms: false,
          confirmPassword: "",
          referralCode: "",
          howFoundOut: "",
          howFoundOutSource: "",
          referrer: queryStringObj.utm_source_platform,
        }}
        validate={values => {
          const errors = {};

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.firstName) {
            errors.firstName = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (!values.lastName) {
            errors.lastName = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          if (values.referralCode) {
            if (values.referralCode.length < 4 || values.referralCode.length > 20) {
              errors.referralCode = intl.formatMessage({
                id: "AUTH.VALIDATION.REFERRAL_CODE.INVALID"
              });
            }
          }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          else {
            const passwordRegex = PASSWORD_REGEX;

            if (!passwordRegex.test(values.password)) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.PASSWORD.WEAK"
              });
            }
          }

          if (!values.confirmPassword) {
            errors.confirmPassword = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword =
              "Password and Confirm Password didn't match.";
          }

          if (!values.acceptTerms) {
            errors.acceptTerms = "Accept Terms";
          }

          return errors;
        }}
        onSubmit={async (values) => {
          await dispatch(
            register(
              values.email,
              values.firstName,
              values.lastName,
              values.password,
              values.companyName,
              values.phone,
              values.referralCode,
              values.howFoundOut,
              values.howFoundOutSource,
            )
          );

          globalGA("sign_up", {
            eventAction: "success",
            eventCategory: "user",
            eventReferrer: values.referrer || "",
          });
          history.push('/auth/registered-successfully');
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          const customFoundOutValues = ["REFERRAL", "OTHER"];
          const shouldAskForSource = customFoundOutValues.includes(values.howFoundOut);

          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <Input
                labelId="AUTH.INPUT.FIRST_NAME"
                containerClass="mb-25"
                variant="outlined"
                fullWidth
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                helperText={touched.firstName && errors.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
              />
              
              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.LAST_NAME"
                variant="outlined"
                fullWidth
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                helperText={touched.lastName && errors.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
              />
            
              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.EMAIL"
                variant="outlined"
                fullWidth
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />

              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.COMPANY_NAME"
                type="text"
                variant="outlined"
                fullWidth
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyName}
                helperText={touched.companyName && errors.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
              />

              <div className="col-12 p-0 mb-25">
                <div className={`dark-custom-input input-phone form-group`}>
                  <FormLabel className="mb-10">
                    <FormattedMessage id="AUTH.INPUT.PHONE" />
                  </FormLabel>
                  <MuiPhoneNumber
                    enableLongNumbers
                    disableAreaCodes
                    defaultCountry="au"
                    variant="outlined"
                    type="text"
                    fullWidth
                    name="phone"
                    onBlur={handleBlur}
                    onChange={(v) => setFieldValue(`phone`, v)}
                    value={values.phone}
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                </div>
              </div>

              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.REFERRAL_CODE"
                type="text"
                variant="outlined"
                fullWidth
                name="referralCode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.referralCode}
                helperText={touched.referralCode && errors.referralCode}
                error={Boolean(touched.referralCode && errors.referralCode)}
              />
              
              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.PASSWORD"
                type="password"
                variant="outlined"
                fullWidth
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                helperText={(touched.password && errors.password) || intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.WEAK" })}
                error={Boolean(touched.password && errors.password)}
              />
              
              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.CONFIRM_PASSWORD"
                type="password"
                variant="outlined"
                fullWidth
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              />

              <div className="dark-custom-input">
                <div className="dark-input-label mb-10px d-flex align-items-center">
                  <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US" />
                </div>
              
                <div className="mb-25px">
                  <Select
                    value={values.howFoundOut || ''}
                    classes={{
                      root: `d-flex align-items-center bg-white`
                    }}
                    name="howFoundOut"
                    variant="outlined"
                    onChange={(e) => {
                      if (!customFoundOutValues.includes(e.target.value) && values.howFoundOutSource) {
                        setFieldValue("howFoundOutSource", "");
                      }

                      handleChange(e);
                    }}
                    fullWidth
                    placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
                  >
                    <MenuItem value="SEARCH_ENGINE">
                      <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US.SEARCH_ENGINE" />
                    </MenuItem>

                    <MenuItem value="SOCIAL_MEDIA">
                      <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US.SOCIAL_MEDIA" />
                    </MenuItem>

                    <MenuItem value="WORD_OF_MOUTH">
                      <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US.WORD_OF_MOUTH" />
                    </MenuItem>

                    <MenuItem value="REFERRAL">
                      <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US.REFERRAL" />
                    </MenuItem>

                    <MenuItem value="OTHER">
                      <FormattedMessage id="AUTH.REGISTRATION.HOW_FOUND_US.OTHER" />
                    </MenuItem>
                  </Select>
                </div>

                {
                  shouldAskForSource && (
                    <Input
                      containerClass="mb-25"
                      labelId="AUTH.REGISTRATION.HOW_FOUND_US_SOURCE"
                      variant="outlined"
                      fullWidth
                      name="howFoundOutSource"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.howFoundOutSource}
                      helperText={touched.howFoundOutSource && errors.howFoundOutSource}
                      error={Boolean(touched.howFoundOutSource && errors.howFoundOutSource)}
                    />
                  )
                }
              </div>
              
              <div className="form-group mb-0 text-center my-25 d-flex align-items-center justify-content-center">
                {
                  !agreementData ? (
                    <div className="kt-spinner kt-spinner--center kt-spinner--lg kt-spinner--brand"></div>
                  ) : (
                    <FormControlLabel
                      className='mb-0 mr-1'
                      label={
                        <div>
                          <FormattedMessage id="AUTH.INPUT.TNC_CHECKBOX" />
                          <span
                            className='text-primary mx-1 cursor-pointer'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAgreementType('tos')
                            }}
                          >
                            <FormattedMessage id="TERMS_AND_CONDITIONS.TITLE" />
                          </span>
                          and
                          <span
                            className='text-primary mx-1 cursor-pointer'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAgreementType('pp')
                            }}
                          >
                            <FormattedMessage id="PRIVACY_POLICY.TITLE" />
                          </span>
                        </div>
                      }
                      control={
                        <Checkbox
                          color="primary"
                          name="acceptTerms"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          checked={values.acceptTerms}
                        />
                      }
                    />
                  )
                }
              </div>

              {
                !!flags.error[flagNames.REGISTER] && (
                  <Alert color="danger" className="mb-15 text-center">
                    {flags.error[flagNames.REGISTER]}
                  </Alert>
                )
              }

              <div className="row pt-20">
                <div className='col-12 mb-3 text-center'>
                  <button
                    disabled={flags.loading[flagNames.REGISTER] || !values.acceptTerms}
                      className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[flagNames.REGISTER]
                        }
                      )}`}
                  >
                    <FormattedMessage id="AUTH.REGISTER.BUTTON" />
                  </button>
                </div>

                <div className='col-12'>
                  <FormattedMessage id="AUTH.REGISTER.LOGIN_TEXT" />
                  <Link
                    to="/auth/login"
                    className="kt-link kt-login__link-forgot ml-2"
                  >
                    <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  </Link>
                </div>

              </div>
            </form>
          )
        }}
      </Formik>

      {
        !!agreementType && (
          <AgreementModal
            handleClose={() => setAgreementType(null)}
            titleLabelId={
              agreementType === "tos"
                ? "TERMS_AND_CONDITIONS.TITLE"
                : "PRIVACY_POLICY.TITLE"
            }
            url={agreementData[agreementType].url}
          />
        )
      }
    </React.Fragment>
  );
}

export default Registration;
