import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	useMemo
} from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useSelector,
	useDispatch,
} from "react-redux";
import {
	Alert,
} from "reactstrap";
import {
	FormLabel,
	MenuItem,
	Fab,
	makeStyles,
} from "@material-ui/core";
import {
	useHistory,
} from "react-router-dom";
import clsx from "clsx";
import { uuid } from "uuidv4";
import _ from "lodash";
import { useSnackbar } from "notistack";

import Input from "../../../../../../common/Input";
import Select from "../../../../../../common/Select";
import { PlusIcon, FileErrorIcon } from "../../../../../../common/icons";
import Constants from "../../../../../../common/constants";

import {
	loadTemplates,
	createTemplate,
	flagNames as templateFlagNames
} from "../../../../../../store/modules/actions/templates.actions";

import ItemCard from "../../common/ItemCard";
import Confirm from "../../../../../../common/modals/confirm";
import useUserRestriction from "../../../../../../common/hooks/user-restriction";
import Preview from "../../../../../../common/Preview";
import { getFlags } from "../../../../../../store/modules/selectors/common.selector";
import { getTemplatesList } from "../../../../../../store/modules/selectors/template.selector";

const DEFAULT_CUSTOM_ITEM = {
	enabled: true,
	name: "",
	description: "",
	attachments: [],
	type: Constants.DocumentType.REGULAR,
	fileRename: [],
};

const useStyles = makeStyles((theme) => {
	return {
		addDocumentButton: {
			background: "white",
			height: "28px",
			width: "28px",
			minHeight: "28px",

			"&:hover": {
				background: "white"
			}
		}
	}
});

function Create(props) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const flags = useSelector(getFlags);
	const allTemplates = useSelector(getTemplatesList);
	const templates = useMemo(() => allTemplates.filter(({ format }) => format !== "MODERN"), [allTemplates]);

	const [showAttachmentSizeWarning, setAttachmentSizeWarning] = useState(false);
	const [previewInfo, setPreviewInfo] = useState(null);
	const history = useHistory();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const submittedManually = useRef(false);

	useEffect(() => {
		dispatch(loadTemplates());
	}, [dispatch]);

	const doesAttachmentsExceedsLimit = useCallback((values, files) => {
		const existingAttachmentSizeKB = values.items.reduce((p, item) => {
			const itemAttachmentSize = item.attachments.reduce((q, attachment) => {
				return q + (attachment instanceof File ? (attachment.size / 1024) : attachment.size);
			}, 0);

			return p + itemAttachmentSize;
		}, 0);
		const newFilesKB = files.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}, []);

	if (flags.loading[templateFlagNames.TEMPLATES] || flags.loading[templateFlagNames.DEFAULT_TEMPLATES] || !restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	if (isInReadOnlyMode || !restrictions.features.TEMPLATES) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<Alert color="danger">
					<FormattedHTMLMessage id="GENERAL.UPGRADE_MESSAGE_WITH_LINK" />
				</Alert>
			</div>
		);
	}

	return (
		<div className="create-template-container bg-white rounded py-4 px-3">
			<div className="rounded-card template-card">
				<div className="p-0">
					<div className="row px-20px">
						<div className="col-12 d-flex align-items-center px-0">
							<div className="f-20px text-dark">
								<FormattedMessage id="TEMPLATE.CREATE" />
							</div>
						</div>
					</div>

					<div className="mt-40px mb-25px px-10px f-15px">
						<FormattedMessage id="CUSTOMIZATION.TEMPLATES.DESCRIPTION" />
					</div>

					<Formik
						initialValues={{
							name: "",
							template: "",
							items: [],
							emailMeta: {
								subject: "",
								content: ""
							}
						}}
						validate={values => {
							const error = {};
							const toastErrors = [];

							if (!values.name) {
								error.name = intl.formatMessage({
									id: "TEMPLATE.NAME.VALIDATION.REQUIRED"
								});
								toastErrors.push("TEMPLATE.TOAST_ERROR.NAME.REQUIRED")
							}
							else {
								const isAlreadyExists = templates.find(({ name, _id }) => name === values.name);

								if (isAlreadyExists) {
									error.name = intl.formatMessage({
										id: "TEMPLATE.NAME.VALIDATION.DUPLICATE"
									}, values);
								}
							}

							if (!values.items || !values.items.length) {
								error.items = intl.formatMessage({
									id: "TEMPLATE.ITEMS.VALIDATION.REQUIRED"
								});
								toastErrors.push("TEMPLATE.TOAST_ERROR.DOCUMENTS.REQUIRED");
							}
							else {
								for (let item of values.items) {
									if (!item.name) {
										toastErrors.push("TEMPLATE.TOAST_ERROR.DOCUMENT.NAME.REQUIRED")
										break;
									}
								}
							}

							if (submittedManually.current) {
								toastErrors.forEach((messageId) => {
									const message = intl.formatMessage({ id: messageId });
									enqueueSnackbar(message, {
										variant: 'error',
										autoHideDuration: 5000
									});
								});

								submittedManually.current = false;
							}

							return error;
						}}
						validateOnChange={false}
						validateOnBlur={true}
						onSubmit={(values) => {
							dispatch(createTemplate(values))
								.then(() => {
									history.goBack();
								});
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							setFieldTouched,
						}) => {
							return (
								<form
									noValidate={true}
									autoComplete="off"
								>
									<div className="row px-10">
										<div className="col-md-5 col-12">
											<Input
												labelId="TEMPLATE.NAME"
												containerClass="mb-30px"
												type="text"
												variant="outlined"
												fullWidth
												name={`name`}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.name}
												helperText={touched.name && errors.name}
												error={Boolean(touched.name && errors.name)}
											/>
										</div>

										<div className="col-md-7 col-12">
											<div className="dark-custom-input form-group mb-30px">
												<FormLabel className="mb-10">
													<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE" />
												</FormLabel>
												<Select
													labelId="template-provider"
													value={values.template || ""}
													name="template"
													classes={{
														root: `d-flex align-items-center`
													}}
													onChange={(e) => {
														const selectedTemplateId = e.target.value;
														const selectedTemplate = templates.find(({ _id }) => _id === selectedTemplateId);
														const items = selectedTemplate.items.map((item) => {
															return {
																templateId: selectedTemplate._id,
																...item,
															};
														});

														handleChange(e);
														setFieldValue("items", selectedTemplate ? items : []);
													}}
													fullWidth
													variant="outlined"
													placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
												>
													{
														templates && templates.length ? (
															templates.map(({ _id, name }) => (
																<MenuItem value={_id} key={_id}>{name}</MenuItem>
															))
														) : (
															<MenuItem value={null} disabled>
																<FormattedMessage
																	id="GENERAL.NO_ITEMS_FOUND"
																	values={{ itemTitle: "Templates" }}
																/>
															</MenuItem>
														)
													}
												</Select>
											</div>
										</div>
									</div>

									<hr className="mb-30px mt-0" />

									<div className="row px-10">
										<div className="col-md-5 col-12">
											<Input
												labelId="TEMPLATE.EMAIL.SUBJECT"
												containerClass="mb-30px"
												type="text"
												variant="outlined"
												fullWidth
												name={`emailMeta.subject`}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.emailMeta.subject}
												helperText={_.get(touched, "emailMeta.subject") && _.get(errors, "emailMeta.subject")}
												error={Boolean(_.get(touched, "emailMeta.subject") && _.get(errors, "emailMeta.subject"))}
											/>
										</div>

										<div className="col-md-7 col-12">
											<Input
												labelId="TEMPLATE.EMAIL.MESSAGE"
												containerClass="mb-30px"
												type="text"
												variant="outlined"
												fullWidth
												multiline
												rows="4"
												name={`emailMeta.content`}
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.emailMeta.content}
												helperText={_.get(touched, "emailMeta.content") && _.get(errors, "emailMeta.content")}
												error={Boolean(_.get(touched, "emailMeta.content") && _.get(errors, "emailMeta.content"))}
											/>
										</div>
									</div>

									<hr className="mb-30px mt-0" />

									<div className="row mt-4 px-10">
										{
											values.items && values.items.length > 0 && values.items.map((item, itemIndex) => {
												return (
													// not sure why but attachments array is not changing whe I upload a file hence added attachments length in key
													<div className="col-12 mb-4" key={`${itemIndex}-${item.attachments.length}`}>
														<ItemCard
															className="p-15"
															item={item}
															handleNameChange={(e) => {
																setFieldValue(
																	`items[${itemIndex}].name`,
																	e.target.value
																);
															}}
															handleDelete={() => {
																const items = values.items.filter((item, index) => index !== itemIndex);
																setFieldValue(
																	`items`,
																	items
																);
															}}
															handleMessageChange={(e) => {
																setFieldValue(
																	`items[${itemIndex}].description`,
																	e.target.value
																)
															}}
															handleTypeChange={(newType) => {
																setFieldValue(
																	`items[${itemIndex}].type`,
																	newType
																);
															}}
															handleFileRenameAdd={() => {
																setFieldValue(
																	`items[${itemIndex}].fileRename`,
																	[...item.fileRename, ""]
																)
															}}
															handleFileRenameChange={(index, value) => {
																setFieldValue(
																	`items[${itemIndex}].fileRename[${index}]`,
																	value
																)
															}}
															handleFileRenameRemove={(index) => {
																const updatedFileRename = item.fileRename.filter((v, i) => i !== index);
																setFieldValue(
																	`items[${itemIndex}].fileRename`,
																	updatedFileRename
																)
															}}
															handleAddFiles={(files) => {
																if (doesAttachmentsExceedsLimit(values, files)) {
																	setAttachmentSizeWarning(true);
																	return;
																}

																setFieldValue(
																	`items[${itemIndex}].attachments`,
																	[
																		...item.attachments,
																		...files
																	]
																)
															}}
															handleDeleteFile={(index) => {
																setFieldValue(
																	`items[${itemIndex}].attachments`,
																	item.attachments.filter((f, i) => i !== index),
																);
															}}
															handleFilePreview={(attachmentIndex) => setPreviewInfo({ docIndex: itemIndex, attachmentIndex })}
														/>
													</div>
												);
											})
										}
									</div>

									{
										!!flags.error[templateFlagNames.CREATE] && (
											<div>
												<Alert color="danger" className="d-flex justify-content-center">
													{flags.error[templateFlagNames.CREATE]}
												</Alert>
											</div>
										)
									}

									<div className="row px-10px">
										<div className="col-sm-12 col-md-6 d-flex justify-content-start align-items-center">
											<div
												className="mb-3 mb-sm-0 d-flex align-items-center cursor-pointer"
												onClick={() => {
													setFieldValue(
														`items`,
														[
															...values.items,
															{
																...DEFAULT_CUSTOM_ITEM,
																enabled: false,
																uuid: uuid(),
															}
														]
													);
												}}
											>
												<Fab
													classes={{
														root: classes.addDocumentButton
													}}
													size="small"
													color="primary"
												>
													<PlusIcon />
												</Fab>
												<span className="ml-15 f-16px text-dark">
													<FormattedMessage id="TEMPLATE.ADD_DOCUMENT" />
												</span>
											</div>
										</div>
										<div className="col-sm-12 col-md-6 d-flex justify-content-end align-items-center">
											<div>
												<button
													type="button"
													onClick={() => {
														submittedManually.current = true;
														handleSubmit();
													}}
													disabled={flags.loading[templateFlagNames.CREATE]}
													className={`btn py-4 btn-primary btn-lg btn-block kt-login__btn-primary ${clsx(
														{
															"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[templateFlagNames.CREATE]
														}
													)}`}
												>
													<FormattedMessage id="TEMPLATE.CREATE.SUBMIT" />
												</button>
											</div>
										</div>
									</div>
									{
										previewInfo &&
										previewInfo.docIndex > -1 &&
										previewInfo.attachmentIndex > -1 && (
											<Preview
												file={values.items[previewInfo.docIndex].attachments[previewInfo.attachmentIndex]}
												handleClose={() => setPreviewInfo(null)}
											/>
										)
									}
								</form>
							);
						}}

					</Formik>
				</div>
			</div>

			<Confirm
				open={showAttachmentSizeWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setAttachmentSizeWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>
		</div>
	);
}

export default Create;