import { withStyles } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

const LightTooltip = withStyles((theme) => {
	return {
		tooltip: {
			cursor: 'pointer',
			backgroundColor: theme.palette.common.white,
			color: 'rgba(0, 0, 0, 0.87)',
			boxShadow: theme.shadows[1],
			fontSize: 11,
		},
		// arrow: {
		// 	boxShadow: theme.shadows[1],
		// 	color: theme.palette.common.white,
		// }
	};
})(Tooltip);

export default LightTooltip;