import React from "react";
import {
	FormattedHTMLMessage,
	FormattedMessage,
} from "react-intl";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	makeStyles
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import { pickTextColor } from "../../../_metronic";

export const Background = {
	primary: "#D8E0FF",
	warning: "#FFF6EB",
	danger: "#FFEBEB",
	success: "#E0FFF9",
	secondary: "#C9CED1",
	dark: "#D8E0FF",
};
export const Color = {
	primary: "#2E5BFF",
	warning: "#E79633",
	danger: "#EB5757",
	success: "#2CC2A5",
	secondary: "#626C71",
	dark: "#2E384D",
}

const useStyles = makeStyles((theme) => {
	return {
		header: {
			height: "150px",
		},
		title: {
			fontWeight: 500,
			fontSize: '18px'
		},
		message: {
			fontSize: '14px'
		},
		cancelButton: {
			background: theme.palette.extraColors.gray,
		},
		actionButton: {
			minWidth: 145,
		}
	}
})

function Confirm(props) {
	const classes = useStyles();
	const {
		className,
		open,
		icon,
		variant = "warning",
		handleClose,
		title,
		titleValues = {},
		message,
		messageValues = {},
		submitButtonText = "GENERAL.YES",
		submitButtonForcedColor,
		cancelButtonText,
		cancelButtonForcedColor,
		loading,
		error,
	} = props;

	let headerBackgroundColor = undefined;
	let submitButtonColor = submitButtonForcedColor;
	
	if (variant) {
		headerBackgroundColor = Background[variant];

		if (!submitButtonColor) {
			submitButtonColor = Color[variant];
		}
	}
	
	return (
		<Dialog
			open={open}
			onClose={() => handleClose(null)}
			fullWidth
			maxWidth="xs"
			className={className}
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>
			<DialogContent className="p-0">
				{
					!!variant && (
						<div
							className={clsx(classes.header, "d-flex align-items-center justify-content-center header-container")}
							style={{ background: headerBackgroundColor }}
						>
							{
								!!icon && (
									<div>
										{typeof icon === "function" ? React.createElement(icon, { color: submitButtonColor }) : icon}
									</div>
								)
							}
						</div>
					)
				}

				<div className="text-center p-30px pb-0">
					<div className={clsx(classes.title)}>
						<FormattedMessage
							id={title}
							values={titleValues}
						/>
					</div>

					{
						!!message && (
							<div className={clsx(classes.message, "mt-15px")}>
								{
									(typeof message === "string") ? (
										<FormattedHTMLMessage
											id={message}
											values={messageValues}
										/>
									) : message
								}
							</div>
						)
					}
				</div>

				{
					!!error && (
						<div className="d-flex justify-content-center mt-3 px-5">
							<Alert color="danger">
								{error}
							</Alert>
						</div>
					)
				}

				<div className={clsx(classes.buttonContainer, "d-flex justify-content-center align-items-center p-40px")}>
					{
						!!cancelButtonText && (
							<div>
								<button
									className={clsx(classes.actionButton, classes.cancelButton, "btn btn-lg btn-elevate py-3 mr-30px")}
									onClick={() => handleClose(false)}
									style={{
										background: cancelButtonForcedColor,
										color: pickTextColor(cancelButtonForcedColor),
									}}
								>
									<FormattedMessage id={cancelButtonText} />
								</button>
							</div>
						)
					}

					<div>
						<button
							className={`btn btn-lg btn-elevate py-3 ${clsx(
								classes.actionButton,
								{
									"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
								}
							)}`}
							style={{
								background: submitButtonColor,
								color: pickTextColor(submitButtonColor)
							}}
							onClick={() => handleClose(true)}
						>
							<FormattedMessage id={submitButtonText} />
						</button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default Confirm;
