import React from "react";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	
	Dialog,
	DialogTitle,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	updateDocumentStatus,
	flagNames,
} from '../../../../store/modules/actions/requests.actions';
import Constants from "../../../../common/constants";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

function AcceptDocument(props) {
	const {
		open,
		document: doc,
		handleClose,
		updateDocumentStatus,
	} = props;

	const flags = useSelector(getFlags);
	const loading = flags.loading[flagNames.DOCUMENT_STATUS_UPDATE];
	const error = flags.error[flagNames.DOCUMENT_STATUS_UPDATE];

	const handleSubmit = async () => {
		await updateDocumentStatus(doc._id, {
			status: Constants.DocumentStatus.APPROVED
		});
		handleClose(true);
	}

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			fullWidth
			classes={{
				paper: "rounded-card"
			}}
			maxWidth="xs"
		>
			<DialogTitle className="d-none">
				{''}
			</DialogTitle>

			<div className="container p-20">
				<div className="row f-15 label-4">
					<div className="col-10 offset-1">
						<h4 className="mb-0 text-center">
							<FormattedMessage id="REQUEST.DOCUMENT.ACCEPT.TITLE" />
						</h4>
					</div>
					<div className="col-1">
						<div className="float-right cursor-pointer" onClick={() => handleClose()}>
							<span className="fas fa-times"></span>
						</div>
					</div>
				</div>

				<div className="text-center my-15">
					<FormattedMessage
						id="REQUEST.DOCUMENT.ACCEPT.MESSAGE"
						values={{ ...doc }}
					/>
				</div>

				{
					error && (
						<div className="d-flex justify-content-center">
							<Alert color="danger">
								{error}
							</Alert>
						</div>
					)
				}
				
				<div className="row">
					<div className="col-6 px-4">
						<button
							type="button"
							className={`btn btn-danger btn-block`}
							onClick={() => handleClose()}
						>
							<FormattedMessage id="GENERAL.NO" />
						</button>
					</div>
					<div className="col-6 px-4">
						<button
							type="submit"
							className={`btn btn-block btn-primary ${clsx({
								"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--right kt-spinner--primary": loading,
							})}`}
							onClick={handleSubmit}
							disabled={loading}
						>
							<FormattedMessage id="GENERAL.YES" />
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

export default injectIntl(
	connect(
		null,
		{
			updateDocumentStatus,
		}
	)(AcceptDocument)
)
