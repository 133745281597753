export const TRIAL_PLAN = {
	price: {
		AUD: {
			monthly: 19,
			annually: 228
		}
	},
	userCount: 1,
	storage: 5,
	requestsPerMonth: 10,
	canUseTemplates: true,
	canUseReminders: true,
	canAttachFiles: true,
	smsNotifications: 0,
	canUseBrandedInvites: false,
	canUseBrandedPortal: false,
	canRenameFiles: false,
	canUsePDFConversion: false,
	canUseBankStatementsIntegration: false,
};

export const STARTER_PLAN = {
	price: {
		AUD: {
			monthly: 49,
			annually: 49*12
		}
	},
	userCount: 3,
	storage: 15,
	requestsPerMonth: 30,
	canUseTemplates: true,
	canUseReminders: true,
	canAttachFiles: true,
	smsNotifications: 20,
	canUseBrandedInvites: false,
	canUseBrandedPortal: false,
	canRenameFiles: false,
	canUsePDFConversion: false,
	canUseBankStatementsIntegration: false,
};

export const PRO_PLAN = {
	price: {
		AUD: {
			monthly: 99,
			annually: 99 * 12
		}
	},
	userCount: 5,
	storage: 50,
	requestsPerMonth:150,
	canUseTemplates: true,
	canUseReminders: true,
	canAttachFiles: true,
	smsNotifications: 50,
	canUseBrandedInvites: true,
	canUseBrandedPortal: true,
	canRenameFiles: true,
	canUsePDFConversion: true,
	canUseBankStatementsIntegration: true,
};

export const TEAM_PLAN = {
	price: {
		AUD: {
			monthly: 149,
			annually: 149 * 12
		}
	},
	userCount: 10,
	storage: 120,
	requestsPerMonth: 400,
	canUseTemplates: true,
	canUseReminders: true,
	canAttachFiles: true,
	smsNotifications: 150,
	canUseBrandedInvites: true,
	canUseBrandedPortal: true,
	canRenameFiles: true,
	canUsePDFConversion: true,
	canUseBankStatementsIntegration: true,
};

export default {
	TRIAL: TRIAL_PLAN,
	LITE: TRIAL_PLAN,
	STARTER: STARTER_PLAN,
	PRO: PRO_PLAN,
	TEAM: TEAM_PLAN,
}