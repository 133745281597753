import React, { useState } from "react";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import clsx from 'clsx';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";

import Input from "../../common/Input";
import { flagNames, resetPassword } from "../../store/modules/actions/auth.actions";
import { PASSWORD_REGEX } from "../../../_metronic";
import { getFlags } from "../../store/modules/selectors/common.selector";

const ResetPassword = (props) => {
	const { intl, resetPassword } = props;
	const {
		user,
		token
	} = useParams();
	
	const history = useHistory();
	const flags = useSelector(getFlags);
	const [wasPasswordReset, setPasswordReset] = useState(false);

	const loading = flags.loading[flagNames.RESET_PASSWORD];
	const error = flags.error[flagNames.RESET_PASSWORD];

	return (
		<React.Fragment>
			<div className="text-center mb-50">
				<h3>
					<FormattedMessage id="AUTH.RESET.TITLE" />
				</h3>
			</div>

			<div className='mt-2 text-center'>
				<FormattedHTMLMessage id="AUTH.RESET.DESC" />
			</div>

			<Formik
				initialValues={{ password: "", confirmPassword: "" }}
				validate={values => {
					const errors = {};

					if (!values.password) {
						errors.password = intl.formatMessage({
							id: "AUTH.VALIDATION.REQUIRED_FIELD"
						});
					}
					else {
						const passwordRegex = PASSWORD_REGEX;

						if (!passwordRegex.test(values.password)) {
							errors.password = intl.formatMessage({
								id: "AUTH.VALIDATION.PASSWORD.WEAK"
							});
						}
					}

					if (!values.confirmPassword) {
						errors.confirmPassword = intl.formatMessage({
							id: "AUTH.VALIDATION.REQUIRED_FIELD"
						});
					} else if (values.password !== values.confirmPassword) {
						errors.confirmPassword = intl.formatMessage({
							id: "AUTH.VALIDATION.PASSWORD_MISMATCH"
						});
					}

					return errors;
				}}
				onSubmit={async (values) => {
					await resetPassword(user, token, values.password);
					setPasswordReset(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => (
					<form onSubmit={handleSubmit}>
						
						<Input
							containerClass="mb-25"
							labelId="AUTH.INPUT.PASSWORD"
							fullWidth
							type="password"
							variant="outlined"
							name="password"
							disabled={wasPasswordReset}
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.password}
							helperText={(touched.password && errors.password) || intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD.WEAK" })}
							error={Boolean(touched.password && errors.password)}
						/>

						<Input
							containerClass="mb-25"
							labelId="AUTH.INPUT.CONFIRM_PASSWORD"
							fullWidth
							type="password"
							variant="outlined"
							name="confirmPassword"
							disabled={wasPasswordReset}
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.confirmPassword}
							helperText={touched.confirmPassword && errors.confirmPassword}
							error={Boolean(touched.confirmPassword && errors.confirmPassword)}
						/>
						
						{
							error && (
								<div className="text-center mb-25">
									<Alert color="danger">{error}</Alert>
								</div>
							)
						}

						<div className="row pt-20">
							{
								wasPasswordReset ? (
									<div className="col-12 text-center mb-25">
										<Alert color="success">
											<FormattedMessage id="AUTH.RESET.SUCCESS" />
										</Alert>

										<button
											className="btn btn-primary btn-lg btn-block btn-elevate"
											onClick={() => history.push('/auth/login')}
										>
											<FormattedMessage id="AUTH.LOGIN.TITLE" />
										</button>
									</div>
								) : (
									<div className='col-12 text-center'>
										<button
											type="submit"
												className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
													{
														"kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light": loading
													}
												)}`}
											disabled={loading}
										>
											<FormattedMessage id="AUTH.RESET.SUBMIT" />
										</button>
									</div>
								)
							}
						</div>
					</form>
				)}
			</Formik>
		</React.Fragment>
	);
}

export default injectIntl(
	connect(
		null, 
		{
			resetPassword,
		}
	)(ResetPassword)
);
