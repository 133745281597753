import React, { useCallback, useEffect, useState } from "react";
import { AppBar, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert } from "reactstrap";
import clsx from "clsx";
import queryString from "query-string";
import { useSnackbar } from "notistack";

import Input from "../../common/Input";
import { login } from '../../store/modules/actions/auth.actions';
import { flagNames } from '../../store/modules/actions/auth.actions';
import { globalGA } from "../../../_metronic";
import { getFlags } from "../../store/modules/selectors/common.selector";

function Login() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

	const flags = useSelector(getFlags);

  const [requiresTwoFactorAuth, setRequiresTwoFactorAuth] = useState(false);

  const handleLogin = useCallback(async (values) => {
    const { data } = await dispatch(
      login(
        values.email,
        values.password,
        values.twoFactorAuthCode
      )
    );

    if (data.token) {
      globalGA("login", {
        eventAction: "success",
        eventCategory: "user"
      });
    }

    if (data.code === "MFA_CODE_REQUIRED") {
      setRequiresTwoFactorAuth(true);
    }
  }, [dispatch]);

  useEffect(() => {
    const queryStringObj = queryString.parse(window.location.search);

    if (queryStringObj.error) {
      enqueueSnackbar(queryStringObj.error, { variant: "error" });
    }
  }, [
    enqueueSnackbar
  ]);

  const isLoading = flags.loading[flagNames.LOGIN] || flags.loading[flagNames.LOGGED_IN_USER];
  const error = flags.error[flagNames.LOGIN] || flags.error[flagNames.LOGGED_IN_USER];

  return (
    <React.Fragment>
      <div className="text-center mb-50">
        <h3>
          {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
      </div>

      <Formik
        initialValues={{
          email: "",
          password: "",
          twoFactorAuthCode: "",
        }}
        validate={values => {
          const errors = {};

          if (!values.email) {
            // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (requiresTwoFactorAuth) {
            if (!values.twoFactorAuthCode) {
              errors.twoFactorAuthCode = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.twoFactorAuthCode.length !== 6) {
              errors.twoFactorAuthCode = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }
          }

          return errors;
        }}
        onSubmit={(values) => handleLogin(values)}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <div>
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.EMAIL"
                variant="outlined"
                type="email"
                fullWidth
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
              />

              <Input
                containerClass="mb-25"
                labelId="AUTH.INPUT.PASSWORD"
                variant="outlined"
                type="password"
                fullWidth
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
              />

              {
                error && !requiresTwoFactorAuth && (
                  <Alert color="danger" className="d-flex justify-content-center mb-25">
                    {error}
                  </Alert>
                )
              }

              <div className="pt-20">
                <div className='mb-4 text-center'>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isLoading}
                    className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                      }
                    )}`}
                  >
                    <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                  </button>
                </div>
                <div className='d-flex justify-content-center justify-content-xl-between flex-column flex-xl-row mb-4'>
                  <div className="text-center">
                    <span className="kt-login__signup-label">
                      Don't have an account yet?
                    </span>
                    &nbsp;&nbsp;
                    <Link to="/auth/registration" className="kt-link kt-login__signup-link">
                      Sign Up!
                    </Link>
                  </div>
                  <div className="text-center">
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>
                  </div>
                </div>
              </div>
            </form>

            <Dialog open={requiresTwoFactorAuth}>
              <DialogTitle className="p-0">
                <AppBar
                  elevation={0}
                  position="static"
                  classes={{
                    root: "rounded-top bg-light"
                  }}
                >
                  <Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
                    <div className="flex-grow-1">
                      <Typography
                        variant="span"
                        className="f-16px"
                      >
                        <FormattedMessage id="AUTH.LOGIN.TWO_FACTOR_AUTH.TITLE" />
                      </Typography>
                    </div>
                    <div>
                      <IconButton onClick={() => setRequiresTwoFactorAuth(false)}>
                        <CloseOutlined />
                      </IconButton>
                    </div>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                <div className="row my-10px">
                  <div className="col-12">
                    <div>
                      <div className="d-flex justify-content-center mb-10px p-10px f-15px">
                        <FormattedMessage id="AUTH.LOGIN.TWO_FACTOR_AUTH.MESSAGE" />
                      </div>
                    </div>
                    <div className="p-15px">
                      <Input
                        containerClass="mb-25"
                        labelId="AUTH.INPUT.TWO_FACTOR_AUTH_CODE"
                        variant="outlined"
                        fullWidth
                        name="twoFactorAuthCode"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.twoFactorAuthCode}
                        helperText={touched.twoFactorAuthCode && errors.twoFactorAuthCode}
                        error={Boolean(touched.twoFactorAuthCode && errors.twoFactorAuthCode)}
                      />

                      {
                        error && (
                          <Alert color="danger" className="d-flex justify-content-center mb-25">
                            {error}
                          </Alert>
                        )
                      }

                      <div>
                        <button
                          id="kt_login_signin_submit"
                          type="button"
                          onClick={handleSubmit}
                          disabled={isLoading}
                          className={`btn btn-primary btn-lg btn-block btn-elevate kt-login__btn-primary py-3 ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                            }
                          )}`}
                        >
                          <FormattedMessage id="AUTH.LOGIN.BUTTON" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default Login;
