import React, { forwardRef, useCallback } from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { useIntl } from "react-intl";
import clsx from "clsx";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

const useStyles = makeStyles((theme) => {
	return {
		dropdown: {
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.dropdown.readableMaxWidth,
			}
		}
	};
});

const Dropdown = forwardRef((props, _ref) => {
	// eslint-disable-next-line no-unused-vars
	const { data, response, status, rejectedReason, markNotRequiredReason, onChange } = props;
	const intl = useIntl();
	const classes = useStyles();

	const handleOptionChange = useCallback((value) => {
		const newData = {
			...response,
			value,
		};

		onChange(newData);
	}, [onChange, response]);

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="row">
				<div className={clsx(classes.dropdown, "col-12 dark-custom-input")}>
					<Select
						value={response.value || ''}
						className="blue-border"
						classes={{
							root: `d-flex align-items-center bg-white`
						}}
						name="template"
						variant="outlined"
						onChange={(e) => handleOptionChange(e.target.value)}
						fullWidth
						placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
					>
						{
							data.options.map((option) => (
								<MenuItem key={option.id} value={option.id}>
									{option.title}
								</MenuItem>
							))
						}
					</Select>
				</div>
			</div>
		</div>
	)
});

export default Dropdown;
