import _ from "lodash";
import moment from "moment";
import * as RequestsCRUD from '../../../crud/requests.crud';
import * as DocumentsCRUD from "../../../crud/document.crud";
import * as ProviderCRUD from "../../../crud/provider.crud";
import { setErrorFlag, setLoadingFlag } from './loading-flag.actions';
import { enqueueSnackbar } from './notistack.actions';
import { uploadFileTask, downloadFileTask, uploadFileTaskResult } from './tasks.actions';
import Constants from "../../../common/constants";
import { getStorage } from "../../../../_metronic";
import { setUpgradeDialog } from "./app.actions";

export const actionTypes = {
	REQUESTS_LOADED: "[REQUEST] REQUESTS_LOADED",
	REQUEST_LOADED: "[REQUEST] REQUEST_LOADED",
	NEW_REQUEST: "[REQUEST] NEW_REQUEST",
	ASKED_QUESTION: "[REQUEST] ASKED_QUESTION",
	MARKED_NOT_REQUIRED: "[REQUEST] MARKED_NOT_REQUIRED",
	DOCUMENT_UPDATED: "[REQUEST] DOCUMENT_UPDATED",
	DOCUMENT_DELETED: "[REQUEST] DOCUMENT_DELETED",
	DOCUMENT_STATUS_UPDATED: "[REQUEST] DOCUMENT_STATUS_UPDATED",
	PROVIDER_UPDATED: "[REQUEST] PROVIDER_UPDATED",
	PROVIDER_ADDED: "[REQUEST] PROVIDER_ADDED",
	PROVIDER_REMOVED: "[REQUEST] PROVIDER_REMOVED",
	MESSAGES_LOADED: "[REQUEST] MESSAGES_LOADED",
	MESSAGE_ADDED: "[REQUEST] MESSAGE_ADDED",
	ELEMENT_STATUS_UPDATED: "[REQUEST] ELEMENT_STATUS_UPDATED",
	ELEMENT_COMMENTS_LOADED: "[REQUEST] ELEMENT_COMMENTS_LOADED",
	ELEMENT_COMMENT_ADDED: "[REQUEST] ELEMENT_COMMENT_ADDED",
	CHANGE_PAGE: "[REQUEST] CHANGE_PAGE",
	UPDATE_FILTER: "[REQUEST] UPDATE_FILTER",
	SELECT_REQUEST: "[REQUEST] SELECT_REQUEST",
	UNSELECT_REQUEST: "[REQUEST] UNSELECT_REQUEST",
	UPDATE_TITLE: "[REQUEST] UPDATE_TITLE",
	REQUESTS_ARCHIVED: "[REQUEST] REQUESTS_ARCHIVED",
	REQUESTS_UNARCHIVED: "[REQUEST] REQUESTS_UNARCHIVED",
	REQUESTS_DELETED: "[REQUEST] REQUESTS_DELETED",
	REQUEST_STATUS_CHANGED: "[REQUEST] REQUEST_STATUS_CHANGED",
	MARKED_AS_UNREAD: "[REQUEST] MARKED_AS_UNREAD",
	LOGS_HISTORY_LOADED: "[REQUEST] LOGS_HISTORY_LOADED",
	
	PROVIDER_FORM: "[REQUEST] PROVIDER_FORM",
	PERFORM_OPERATION: "[REQUEST] PERFORM_OPERATION",
};

export const flagNames = {
	REQUESTS: '[REQUEST] REQUESTS',
	REQUEST: '[REQUEST] REQUEST',
	CREATE: '[REQUEST] CREATE',
	ASK_QUESTION: '[REQUEST] ASK_QUESTION',
	NOT_REQUIRED: '[REQUEST] NOT_REQUIRED',
	CANCEL: '[REQUEST] CANCEL',
	IN_PROGRESS: '[REQUEST] IN_PROGRESS',
	COMPLETE: '[REQUEST] COMPLETE',
	DELETE: '[REQUEST] DELETE',
	DOCUMENT_UPDATE: "[REQUEST] DOCUMENT_UPDATE",
	DOCUMENT_DELETE: "[REQUEST] DOCUMENT_DELETE",
	DOCUMENT_STATUS_UPDATE: "[REQUEST] DOCUMENT_STATUS_UPDATE",
	UPDATE_ELEMENT_STATUS: "[REQUEST] UPDATE_ELEMENT_STATUS",
	PROVIDER_UPDATE: "[REQUEST] PROVIDER_UPDATE",
	PROVIDER_ADD: "[REQUEST] PROVIDER_ADD",
	PROVIDER_REMOVE: "[REQUEST] PROVIDER_REMOVE",
	MESSAGES: "[REQUEST] MESSAGES",
	ADD_MESSAGE: "[REQUEST] ADD_MESSAGE",
	ELEMENT_COMMENTS: "[REQUEST] ELEMENT_COMMENTS",
	ADD_ELEMENT_COMMENT: "[REQUEST] ADD_ELEMENT_COMMENT",
	UPLOAD: "[REQUEST] UPLOAD",
	BULK_DOWNLOAD: "[REQUEST] BULK_DOWNLOAD",
	ARCHIVE: "[REQUEST] ARCHIVE",
	UPDATE_TITLE: "[REQUEST] UPDATE_TITLE",
	MARK_UNREAD: "[REQUEST] MARK_UNREAD",
	LOAD_LOGS_HISTORY: "[REQUEST] LOAD_LOGS_HISTORY",
};

export const V2_OPERATIONS = {
	APPROVE: "APPROVE",
	REJECT: "REJECT",
	NOT_REQUIRED: "NOT_REQUIRED",
	VIEW_COMMENTS: "COMMENTS",
	PREVIEW: "PREVIEW",
	DELETE_ELEMENT: "DELETE_ELEMENT",
};

export const loadRequests = (forcedFilter = {}) => {
	return async (dispatch, getStore) => {
		const {
			requests: {
				filter
			}
		} = getStore();
		const {
			search,
			fromDate,
			toDate,
			statusFilters,
			archived,
			updatedOnly,
			unreadOnly,
		} = filter;
		let searchFilter = {
			search,
			fromDate,
			toDate,
			statusFilters,
			updatedOnly,
			archived,
		}

		if (unreadOnly) {
			searchFilter.emailStatus = [
				Constants.ProviderEmailStatus.SENT,
				// Constants.ProviderEmailStatus.NOT_SENT,
			]
		}

		searchFilter = {
			...searchFilter,
			...forcedFilter,
		};

		dispatch(setLoadingFlag(flagNames.REQUESTS, true));

		try {
			const { status, data } = await RequestsCRUD.getRequests(
				searchFilter.search,
				searchFilter.fromDate, 
				searchFilter.toDate,
				searchFilter.statusFilters,
				searchFilter.updatedOnly,
				searchFilter.archived,
				searchFilter.emailStatus
			);

			// manipulate due date so that it'll stay same regardless of in which timezone it was loaded
			data.data.forEach((request) => {
				if (request.documentProviders) {
					request.documentProviders.forEach((provider) => {
						if (provider.dueDate) {
							provider.dueDate = moment(provider.dueDate).subtract(moment().utcOffset(), "minutes").toDate();
						}
					});
				}
			})

			if (status === 200) {
				dispatch({
					type: actionTypes.REQUESTS_LOADED,
					payload: {
						list: data,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.REQUESTS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.REQUESTS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.REQUESTS, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const loadRequest = (requestId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.REQUEST, true));

		try {
			const { status, data } = await RequestsCRUD.getRequestV2(requestId);

			// manipulate due date so that it'll stay same regardless of in which timezone it was loaded
			if (data.documentProviders) {
				data.documentProviders.forEach((provider) => {
					if (provider.dueDate) {
						provider.dueDate = moment(provider.dueDate).subtract(moment().utcOffset(), "minutes").toDate();
					}
				})
			}

			if (status === 200) {
				dispatch({
					type: actionTypes.REQUEST_LOADED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.REQUEST, data.message));
			}

			dispatch(setLoadingFlag(flagNames.REQUEST, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.REQUEST, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const createNewRequest = () => {
	return {
		type: actionTypes.NEW_REQUEST
	};
}

export const setPage = (page) => {
	return {
		type: actionTypes.CHANGE_PAGE,
		payload: {
			page
		}
	};
}

export const selectRequest = (request) => {
	return {
		type: actionTypes.REQUEST_LOADED,
		payload: request,
	}
}

export const createRequestV2 = (requestData) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.CREATE, true));

		try {
			for (const provider of requestData.data) {
				provider.dueDate = moment(provider.dueDate).startOf('day').utcOffset(0, true).toDate();
			}

			const { status, data } = await RequestsCRUD.createRequestV2(requestData);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.CREATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CREATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CREATE, errorMessage));
			return Promise.reject(e);
		}
	}	
}

export const createRequest = (requestData) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.CREATE, true));

		try {
			for (const provider of requestData.data) {
				provider.dueDate = moment(provider.dueDate).startOf('day').utcOffset(0, true).toDate();

				for (const doc of provider.documents) {
					for (let fileIndex = 0; fileIndex < doc.attachments.length; fileIndex++) {
						const file = doc.attachments[fileIndex];

						if (file instanceof File) {
							const {
								name,
								type,
								size,
							} = file;

							const filePath = `document-requests/${requestData.uuid}/${provider.uuid}/${doc.uuid}/attachments/${name}`;

							const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
							const {
								signedRequest,
								url
							} = data;

							// upload actual files
							await ProviderCRUD.uploadFile(signedRequest, file);

							doc.attachments[fileIndex] = {
								name,
								url,
								// convert size from bytes to KB
								size: size / 1024
							};
						}
					}
				}
			}

			const { status, data } = await RequestsCRUD.createRequest(requestData);

			if (status === 200) {
			}
			else {
				dispatch(setErrorFlag(flagNames.CREATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CREATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CREATE, errorMessage));
			return Promise.reject(e);
		}
	}	
}

export const askQuestion = (requestId, documentId, message) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.ASK_QUESTION, true));

		try {
			const { status, data } = await RequestsCRUD.askQuestion(requestId, documentId, message);

			if (status === 200) {
				dispatch({
					type: actionTypes.ASKED_QUESTION,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ASK_QUESTION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ASK_QUESTION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ASK_QUESTION, errorMessage));
			return Promise.reject(e);
		}
	}	
}

export const cancelRequest = (requestId, reason) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.CANCEL, true));

		try {
			const { status, data } = await RequestsCRUD.updateRequestStatus(requestId, {
				status: Constants.RequestStatus.CANCELLED,
				reason
			});

			if (status === 200) {
				const { status: fetchStatus, data: fetchedData } = await RequestsCRUD.getRequest(requestId);

				if (fetchStatus === 200) {
					dispatch({
						type: actionTypes.REQUEST_STATUS_CHANGED,
						payload: {
							request: fetchedData
						}
					});
				}
				else {
					dispatch(setErrorFlag(flagNames.CANCEL, fetchedData.message));
				}
			}
			else {
				dispatch(setErrorFlag(flagNames.CANCEL, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CANCEL, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CANCEL, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const markRequestAsUnread = (requestId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.MARK_UNREAD, true));

		try {
			const { status, data } = await RequestsCRUD.markRequestUnread(requestId);

			if (status === 200) {
				dispatch({
					type: actionTypes.MARKED_AS_UNREAD,
					payload: {
						requestId,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.MARK_UNREAD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.MARK_UNREAD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.MARK_UNREAD, errorMessage));
			return Promise.reject(e);
		}
	}
}


export const moveRequestToInProgress = (requestId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.IN_PROGRESS, true));

		try {
			const { status, data } = await RequestsCRUD.updateRequestStatus(requestId, {
				status: Constants.RequestStatus.IN_PROGRESS,
			});

			if (status === 200) {
				const { status: fetchStatus, data: fetchedData } = await RequestsCRUD.getRequest(requestId);

				if (fetchStatus === 200) {
					dispatch({
						type: actionTypes.REQUEST_STATUS_CHANGED,
						payload: {
							request: fetchedData
						}
					});
				}
				else {
					dispatch(setErrorFlag(flagNames.IN_PROGRESS, fetchedData.message));
				}
			}
			else {
				dispatch(setErrorFlag(flagNames.IN_PROGRESS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.IN_PROGRESS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.IN_PROGRESS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const completeRequest = (requestId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.COMPLETE, true));

		try {
			const { status, data } = await RequestsCRUD.updateRequestStatus(requestId, {
				status: Constants.RequestStatus.COMPLETED
			});

			if (status === 200) {
				const { status: fetchStatus, data: fetchedData } = await RequestsCRUD.getRequest(requestId);

				if (fetchStatus === 200) {
					dispatch({
						type: actionTypes.REQUEST_STATUS_CHANGED,
						payload: {
							request: fetchedData
						}
					});
				}
				else {
					dispatch(setErrorFlag(flagNames.COMPLETE, fetchedData.message));
				}
			}
			else {
				dispatch(setErrorFlag(flagNames.COMPLETE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.COMPLETE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.COMPLETE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const markNotRequired = (requestId, documentId, message) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.NOT_REQUIRED, true));

		try {
			const { status, data } = await RequestsCRUD.markNotRequired(requestId, documentId, message);

			if (status === 200) {
				dispatch({
					type: actionTypes.MARKED_NOT_REQUIRED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.NOT_REQUIRED, data.message));
			}

			dispatch(setLoadingFlag(flagNames.NOT_REQUIRED, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.NOT_REQUIRED, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateDocument = (documentId, documentData) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DOCUMENT_UPDATE, documentId));

		try {
			const { status, data } = await DocumentsCRUD.updateDocument(documentId, documentData);

			if (status === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_UPDATED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.DOCUMENT_UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DOCUMENT_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DOCUMENT_UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const deleteDocument = (documentId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DOCUMENT_DELETE, documentId));

		try {
			const { status, data } = await DocumentsCRUD.deleteDocument(documentId);

			if (status === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_DELETED,
					payload: documentId,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.DOCUMENT_DELETE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DOCUMENT_DELETE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DOCUMENT_DELETE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateDocumentStatus = (documentId, statusData) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DOCUMENT_STATUS_UPDATE, {
			...statusData,
			documentId,
		}));

		try {
			const { status, data } = await DocumentsCRUD.updateDocumentStatus(documentId, statusData);

			if (status === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_STATUS_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.DOCUMENT_STATUS_UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DOCUMENT_STATUS_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DOCUMENT_STATUS_UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateElementStatus = (providerId, elementId, statusData) => {
	return async (dispatch, getStore) => {
		const {
			requests: {
				selectedRequest: {
					_id: requestId,
				}
			}
		} = getStore();

		dispatch(setLoadingFlag(flagNames.UPDATE_ELEMENT_STATUS, {
			...statusData,
			elementId,
		}));

		try {
			const { status, data } = await RequestsCRUD.updateElementStatus(requestId, providerId, elementId, statusData);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_STATUS_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_ELEMENT_STATUS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_ELEMENT_STATUS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_ELEMENT_STATUS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateProviderForm = (payload) => {
	return async (dispatch) => {
		dispatch({
			type: actionTypes.PROVIDER_FORM,
			payload,
		});
	}
}

export const updateProviderV2 = (providerId, providerData) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, providerId));

		const {
			requests: {
				selectedRequest: {
					documentProviders,
				},
				providerForm,
			},
		} = getStore();
		const oldProviderData = documentProviders.find((provider) => provider._id === providerId);
		
		const updateProviderInStore = (payload) => {
			dispatch({
				type: actionTypes.PROVIDER_UPDATED,
				payload,
			});
		}
		const handleFailure = (message) => {
			dispatch(setErrorFlag(flagNames.PROVIDER_UPDATE, message));

			// revert back to older data if api call fails
			updateProviderInStore(oldProviderData);
		}
		const customMerge = (newObj, oldObj) => {
			newObj.bcc = oldObj.bcc;
			newObj.dueData = oldObj.dueData;

			newObj.firstName = oldObj.firstName;
			newObj.lastName = oldObj.lastName;
			newObj.email = oldObj.email;
			newObj.phone = oldObj.phone;

			newObj.emailMeta.subject = oldObj.emailMeta.subject;
			newObj.emailMeta.content = oldObj.emailMeta.content;

			newObj.elements = newObj.elements.map((element, elementIndex) => {
				const oldElement = oldObj.elements[elementIndex];

				element.dataObj.title = oldElement.dataObj.title;
				element.dataObj.subtitle = oldElement.dataObj.subtitle;

				return element;
			});

			return newObj;
		};

		try {
			providerData.dueDate = moment(providerData.dueDate).startOf('day').utcOffset(0, true).toDate();

			// set new data in store for UI
			updateProviderInStore(providerData);

			const { status, data } = await ProviderCRUD.updateProviderV2(providerId, providerData);

			if (status === 200) {
				const dataFromBE = data.data;
				const finalData = customMerge(dataFromBE, providerForm);
				updateProviderInStore(finalData);
			}
			else {
				handleFailure(data.message);
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			handleFailure(errorMessage);
			return Promise.reject(e);
		}
	}
}
export const updateProvider = (providerId, providerData) => {
	return async (dispatch, getStore) => {
		const {
			requests: {
				selectedRequest: requestData
			},
			auth: {
				restrictions: {
					data: restrictions
				}
			}
		} = getStore();
		dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, providerId));

		try {
			providerData.dueDate = moment(providerData.dueDate).startOf('day').utcOffset(0, true).toDate();

			for (const doc of providerData.documents) {
				for (let fileIndex = 0; fileIndex < doc.attachments.length; fileIndex++) {
					const file = doc.attachments[fileIndex];

					if (file instanceof File) {
						const {
							name,
							type,
							size,
						} = file;

						if (!restrictions.storageAllowed) {
							dispatch(setUpgradeDialog("USER.UPLOAD.PLAN_LIMIT_REACHED"));
							throw new Error("User cannot upload files");
						}

						const filePath = `document-requests/${requestData.uuid}/${providerData.uuid}/${doc.uuid}/attachments/${name}`;

						const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
						const {
							signedRequest,
							url
						} = data;

						const response = await dispatch(
							uploadFileTask(
								signedRequest,
								file,
								name
							)
						);

						if (response.wasCancelled) {
							continue;
						}
						
						doc.attachments[fileIndex] = {
							name,
							url,
							// convert size from bytes to KB
							size: size / 1024
						};
					}
				}
			}

			const { status, data } = await ProviderCRUD.updateProvider(providerId, providerData);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addProvider = (requestId, providerData, index) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.PROVIDER_ADD, index));

		try {
			const { status, data } = await RequestsCRUD.addProvider(requestId, providerData);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_ADDED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_ADD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_ADD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_ADD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addProviderV2 = (requestId, providerData, index) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.PROVIDER_ADD, index));

		try {
			const { status, data } = await RequestsCRUD.addProviderV2(requestId, providerData);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_ADDED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_ADD, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_ADD, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_ADD, errorMessage));
			return Promise.reject(e);
		}
	}
}
export const updateProviderStatus = (providerId, newStatus) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, providerId));

		try {
			const { status, data } = await ProviderCRUD.updateProviderStatus(providerId, newStatus);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_UPDATED,
					payload: data.data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
}
export const providerRemove = (requestId, providerId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.PROVIDER_REMOVE, providerId));

		try {
			const { status, data } = await RequestsCRUD.removeProvider(requestId, providerId);

			if (status === 200) {
				dispatch({
					type: actionTypes.PROVIDER_REMOVED,
					payload: { providerId },
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.PROVIDER_REMOVE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.PROVIDER_REMOVE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.PROVIDER_REMOVE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const getProviderMessages = (providerId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.MESSAGES, providerId));

		try {
			const { status, data } = await ProviderCRUD.getProviderMessages(providerId);

			if (status === 200) {
				dispatch({
					type: actionTypes.MESSAGES_LOADED,
					payload: {
						messages: data,
						providerId,
					},
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.MESSAGES, data.message));
			}

			dispatch(setLoadingFlag(flagNames.MESSAGES, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.MESSAGES, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addProviderMessage = (providerId, message) => {
	return async (dispatch, getStore) => {
		const { auth: { user } } = getStore();

		if (!user.chatService) {
			return;
		}

		dispatch(setLoadingFlag(flagNames.ADD_MESSAGE, providerId));

		try {
			const { status, data } = await ProviderCRUD.addProviderMessage(providerId, message);

			if (status === 200) {
				dispatch({
					type: actionTypes.MESSAGE_ADDED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ADD_MESSAGE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ADD_MESSAGE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ADD_MESSAGE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const uploadFile = (requestUUID, providerUUID, documentUUID, files) => {
	return async (dispatch, getStore) => {
		const store = getStore();
		const request = _.get(store, 'requests.selectedRequest', {});
		const restrictions = _.get(store, 'auth.restrictions.data', {});
		const providers = request.documentProviders || [];
		let documentObj = null;

		if (!restrictions.storageAllowed) {
			dispatch(setUpgradeDialog("USER.UPLOAD.PLAN_LIMIT_REACHED"));
			return;
		}
		
		providers.some(({ documents }) => {
			documentObj = documents.find(({uuid}) => uuid === documentUUID);
			return !!documentObj;
		});

		if (!documentObj) {
			dispatch(setErrorFlag(flagNames.DOCUMENT_UPDATE, "Document not found"));
		}

		const fileData = [];

		dispatch(setLoadingFlag(flagNames.UPLOAD, documentObj._id));

		try {
			for (const file of files) {
				const {
					size,
				} = file;

				const { data } = await DocumentsCRUD.uploadFile(documentObj._id, file, fileData);
				const {
					key,
					name: uploadedFileName,
					signedRequest,
					url
				} = data;

				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);

				if (response.wasCancelled) {
					continue;
				}

				fileData.push({
					key,
					name: uploadedFileName,
					url,
					size: (size/1024)
				});
			}

			const { status: updateStatus, data: updateData } = await DocumentsCRUD.updateDocument(documentObj._id, {
				...documentObj,
				files: [
					...(documentObj.files || []),
					...fileData,
				]
			});
			
			if (updateStatus === 200) {
				dispatch({
					type: actionTypes.DOCUMENT_UPDATED,
					payload: updateData,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPLOAD, updateData.message));
			}

			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
			return Promise.resolve({ status: updateStatus, data: updateData });
		}
		catch (e) {
			console.log(e);
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const uploadAttachmentsToElement = (requestUUID, providerUUID, elementUUID, files, uploadId) => {
	return async (dispatch) => {
		const fileData = [];

		dispatch(setLoadingFlag(flagNames.UPLOAD, elementUUID));

		try {
			for (const file of files) {
				const {
					name,
					type,
					size,
				} = file;

				const filePath = `document-requests/${requestUUID}/${providerUUID}/${elementUUID}/attachments/${name}`;

				const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
				const {
					signedRequest,
					url
				} = data;

				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);

				if (response.wasCancelled) {
					continue;
				}

				fileData.push({
					name,
					url,
					// convert size from bytes to KB
					size: size / 1024
				});
			}

			dispatch(
				uploadFileTaskResult(
					uploadId,
					fileData,
				)
			);
			return Promise.resolve(fileData);
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
		finally {
			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
		}
	}
}
export const uploadFileToElement = (providerUUID, elementUUID, files, uploadId) => {
	return async (dispatch, getStore) => {
		const {
			requests: {
				selectedRequest: {
					documentProviders,
				}
			}
		} = getStore();
		const {
			_id: providerId,
			elements,
		} = documentProviders.find(({ uuid }) => uuid === providerUUID);

		const fileData = [];
		const {
			_id: elementId,
		} = elements.find(({ uuid }) => uuid === elementUUID);

		dispatch(setLoadingFlag(flagNames.UPLOAD, elementUUID));

		try {
			for (const file of files) {
				const {
					name: originalName,
					size,
				} = file;
				const {
					data: {
						key,
						name: uploadedFileName,
						signedRequest,
						url
					}
				} = await RequestsCRUD.uploadFileToElement(
					providerId,
					elementId,
					file,
					fileData
				);

				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);

				if (response.wasCancelled) {
					continue;
				}

				fileData.push({
					key,
					originalName,
					name: uploadedFileName,
					url,
					size: (size / 1024)
				});
			}

			dispatch(
				uploadFileTaskResult(
					uploadId,
					fileData,
				)
			);
			return Promise.resolve(fileData);
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
		finally {
			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
		}
	}
}

export const updateFilter = (data) => {
	return {
		type: actionTypes.UPDATE_FILTER,
		payload: {
			data
		}
	}
}

export const bulkDownload = (provider, documentObj, filename, message, convertToPDF) => {
	return async (dispatch, getStore) => {
		const token = getStorage('token');
		const documentId = documentObj ? documentObj._id : null;
		const store = getStore();
		const flagValue = _.get(store, `flags.loading["${flagNames.BULK_DOWNLOAD}"]`) || {};
		const flagLoadingKey = documentId || provider._id;

		dispatch(
			setLoadingFlag(
				flagNames.BULK_DOWNLOAD,
				{
					...flagValue,
					[flagLoadingKey]: true
				}
			)
		);

		try {
			const {
				status,
				data
			} = await RequestsCRUD.bulkDownload(provider._id, documentId, token, convertToPDF);

			if (status === 200) {
				await dispatch(
					downloadFileTask(
						data.url,
						filename
					)
				)
			}
			else {
				dispatch(enqueueSnackbar({
					message: data.message,
					options: {
						key: new Date().getTime() + Math.random(),
						variant: 'error',
					},
				}));
				dispatch(setErrorFlag(flagNames.BULK_DOWNLOAD, data.message));
			}

			return Promise.resolve({ status, data });
		}
		catch (e) {
			console.log(e);
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.BULK_DOWNLOAD, errorMessage));
			dispatch(enqueueSnackbar({
				message: errorMessage,
				options: {
					key: new Date().getTime() + Math.random(),
					variant: 'error',
				},
			}));
			return Promise.reject(e);
		}
		finally {
			// load data from store again because it might have different loading flags for 
			// flagNames.BULK_DOWNLOAD if user tried to download multiple files at same time
			const store2 = getStore();
			const flagValue2 = _.get(store2, `flags.loading["${flagNames.BULK_DOWNLOAD}"]`) || {};
			const flagLoadingKey2 = documentId || provider._id;

			dispatch(
				setLoadingFlag(
					flagNames.BULK_DOWNLOAD,
					{
						...flagValue2,
						[flagLoadingKey2]: false
					}
				)
			);
		}
	}
}
export const bulkDownloadV2 = (provider, elementObj, filename, message, convertToPDF) => {
	return async (dispatch, getStore) => {
		const token = getStorage('token');
		const elementId = elementObj ? elementObj._id : null;
		const store = getStore();
		const flagValue = _.get(store, `flags.loading["${flagNames.BULK_DOWNLOAD}"]`) || {};
		const flagLoadingKey = elementId || provider._id;

		dispatch(
			setLoadingFlag(
				flagNames.BULK_DOWNLOAD,
				{
					...flagValue,
					[flagLoadingKey]: true
				}
			)
		);

		try {
			const {
				status,
				data
			} = await RequestsCRUD.bulkDownloadV2(provider._id, elementId, token, convertToPDF);

			if (status === 200) {
				await dispatch(
					downloadFileTask(
						data.url,
						filename
					)
				)
			}
			else {
				dispatch(enqueueSnackbar({
					message: data.message,
					options: {
						key: new Date().getTime() + Math.random(),
						variant: 'error',
					},
				}));
				dispatch(setErrorFlag(flagNames.BULK_DOWNLOAD, data.message));
			}

			return Promise.resolve({ status, data });
		}
		catch (e) {
			console.log(e);
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.BULK_DOWNLOAD, errorMessage));
			dispatch(enqueueSnackbar({
				message: errorMessage,
				options: {
					key: new Date().getTime() + Math.random(),
					variant: 'error',
				},
			}));
			return Promise.reject(e);
		}
		finally {
			// load data from store again because it might have different loading flags for 
			// flagNames.BULK_DOWNLOAD if user tried to download multiple files at same time
			const store2 = getStore();
			const flagValue2 = _.get(store2, `flags.loading["${flagNames.BULK_DOWNLOAD}"]`) || {};
			const flagLoadingKey2 = elementId || provider._id;

			dispatch(
				setLoadingFlag(
					flagNames.BULK_DOWNLOAD,
					{
						...flagValue2,
						[flagLoadingKey2]: false
					}
				)
			);
		}
	}
}

export const selectRequestItem = (request) => {
	return {
		type: actionTypes.SELECT_REQUEST,
		payload: {
			request,
		}
	};
}
export const unselectRequestItem = (request) => {
	return {
		type: actionTypes.UNSELECT_REQUEST,
		payload: {
			request,
		}
	};
}

export const archiveRequests = (requests, archiveStatus) => {
	return async (dispatch) => {
		const requestsToArchive = requests.filter((request) => [Constants.RequestStatus.CANCELLED, Constants.RequestStatus.COMPLETED].includes(request.status));
		const requestIds = requestsToArchive.map((request) => request._id);

		if (!requestIds.length) {
			return;
		}

		dispatch(setLoadingFlag(flagNames.ARCHIVE, true));

		try {
			const { status, data } = await RequestsCRUD.archiveRequests(requestIds, archiveStatus);

			if (status === 200) {
				dispatch({
					type: archiveStatus ? actionTypes.REQUESTS_ARCHIVED : actionTypes.REQUESTS_UNARCHIVED,
					payload: {
						requestIds,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ARCHIVE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ARCHIVE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ARCHIVE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const deleteRequests = (requests) => {
	return async (dispatch) => {
		const requestsToDelete = requests.filter((request) => request.archived);
		const requestIds = requestsToDelete.map((request) => request._id);

		if (!requestIds.length) {
			return;
		}

		dispatch(setLoadingFlag(flagNames.DELETE, true));

		try {
			const { status, data } = await RequestsCRUD.deleteRequests(requestIds);

			if (status === 200) {
				dispatch({
					type: actionTypes.REQUESTS_DELETED,
					payload: {
						requestIds,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.DELETE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DELETE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DELETE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const updateRequestTitle = (requestId, title) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_TITLE, true));

		try {
			const { status, data } = await RequestsCRUD.updateTitle(requestId, title);

			if (status === 200) {
				dispatch({
					type: actionTypes.UPDATE_TITLE,
					payload: {
						title: data.data.title
					},
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATE_TITLE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_TITLE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_TITLE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const performOperation = (action, data) => {
	return {
		type: actionTypes.PERFORM_OPERATION,
		payload: {
			action,
			data,
		},
	}
}

export const getElementComments = (providerId, elementId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.ELEMENT_COMMENTS, providerId));

		try {
			const { status, data } = await RequestsCRUD.getElementComments(providerId, elementId);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_COMMENTS_LOADED,
					payload: {
						comments: data,
						providerId,
						elementId,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ELEMENT_COMMENTS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ELEMENT_COMMENTS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ELEMENT_COMMENTS, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const addElementComment = (providerId, elementId, message) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.ADD_ELEMENT_COMMENT, providerId));

		try {
			const { status, data } = await RequestsCRUD.addElementComment(providerId, elementId, message);

			if (status === 200) {
				dispatch({
					type: actionTypes.ELEMENT_COMMENT_ADDED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ADD_ELEMENT_COMMENT, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ADD_ELEMENT_COMMENT, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.ADD_MESSAGE, errorMessage));
			return Promise.reject(e);
		}
	}
}

export const loadLogsHistory = (providerId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOAD_LOGS_HISTORY, providerId));

		try {
			const { status, data } = await RequestsCRUD.getLogsHistory(providerId);

			if (status === 200) {
				dispatch({
					type: actionTypes.LOGS_HISTORY_LOADED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOAD_LOGS_HISTORY, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOAD_LOGS_HISTORY, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.LOAD_LOGS_HISTORY, errorMessage));
			return Promise.reject(e);
		}
	}
}
