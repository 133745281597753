import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert } from "reactstrap";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";

import EditNew from "./new";
import EditLegacy from "./legacy";

import {
	loadRequest,
	flagNames as requestFlagName,
} from "../../../../store/modules/actions/requests.actions";
import {
	loadTemplates,
	flagNames as templateFlagNames,
} from "../../../../store/modules/actions/templates.actions";
import { getUserRestrictions } from '../../../../crud/auth.crud';
import SocketService from "../../../../common/services/SocketService";
import { getUser } from "../../../../store/modules/selectors/account.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

export default function EditRequest() {
	const params = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	// const [commentNotificationMetadata, setCommentNotificationMetadata] = useState({});
	const [restrictions, setRestrictions] = useState(null);
	const reloadEventName = `documentRequest/${params.request}`;
	const reloadNotificationKeys = useRef({});

	const user = useSelector(getUser);
	const selectedRequest = useSelector(getSelectedRequest);
	const flags = useSelector(getFlags);

	const dismissAllReloadNotification = useCallback(() => {
		const keys = Object.keys(reloadNotificationKeys.current);

		// the keys provided are numbers but when we set them as keys and fetch them through .keys it 
		// gets converted to string so convert it back to number
		keys.forEach((key) => {
			closeSnackbar(+key);
		});

		reloadNotificationKeys.current = {};
	}, [
		closeSnackbar,
	]);

	const showReloadNotification = useCallback(() => {
		const message = intl.formatMessage({ id: "REQUEST.RELOAD_DATA" });

		enqueueSnackbar(
			message, {
			variant: 'info',
			persist: true,
			action: (key) => {
				reloadNotificationKeys.current[key] = true;

				return (
					<Fragment>
						<Button
							className="text-light"
							onClick={async (e) => {
								try {
									e.target.textContent = '';
									e.target.classList.add("kt-spinner", "kt-spinner--right", "kt-spinner--md", "kt-spinner--light")

									await dispatch(loadRequest(params.request));
									enqueueSnackbar(
										intl.formatMessage({ id: "REQUEST.RELOADED_SUCCESSFULLY" }),
										{
											variant: 'success',
											autoHideDuration: 2000
										}
									);

									dismissAllReloadNotification()
								}
								catch (e) {
									window.location.reload();
								}
							}}
						>
							<FormattedMessage id="REQUEST.RELOAD_DATA.ACTION.REFRESH" />
						</Button>
						<Button
							className="text-light"
							onClick={dismissAllReloadNotification}
						>
							<FormattedMessage id="REQUEST.RELOAD_DATA.ACTION.IGNORE" />
						</Button>
					</Fragment>
				);
			},
		});
	}, [
		dispatch,
		enqueueSnackbar,
		intl,
		params.request,
		dismissAllReloadNotification,
	]);

	useEffect(() => {
		dispatch(loadRequest(params.request));
		dispatch(loadTemplates());

		getUserRestrictions()
			.then(({ data }) => {
				setRestrictions(data);
			})
	}, [
		params.request,
		dispatch,
	]);

	useEffect(() => {		
		SocketService.socket.on(
			reloadEventName,
			(data) => {
				if (data && data.userId !== user._id) {
					showReloadNotification();
				}
			}
		);

		return () => {
			SocketService.socket.removeAllListeners([reloadEventName]);
			dismissAllReloadNotification();
		}
	}, [
		user,
		showReloadNotification,
		reloadEventName,
		dismissAllReloadNotification,
	]);

	if (
		flags.error[templateFlagNames.TEMPLATES] ||
		flags.error[requestFlagName.REQUEST]
	) {
		const errorMessage = flags.error[templateFlagNames.TEMPLATES] ||
			flags.error[requestFlagName.REQUEST];

		return (
			<div className="d-flex justify-content-center mt-40">
				<Alert color="danger">
					{errorMessage}
				</Alert>
			</div>
		);
	}

	if (
		flags.loading[templateFlagNames.TEMPLATES] ||
		flags.loading[requestFlagName.REQUEST] ||
		!restrictions
	) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
			</div>
		)
	}

	if (!selectedRequest) {
		return (
			<div className="d-flex justify-content-center mt-40">
				<Alert color="danger">
					<FormattedMessage id="REQUEST.NOT_FOUND" />
				</Alert>
			</div>
		);
	}

	if (selectedRequest.type === 'MODERN') {
		return (
			<>
				{/* <Button style={{ position: "absolute", top: 800, left: 500 }} onClick={() => setCommentNotificationMetadata({ elementUUID: "53265eb4-6494-4403-836c-ea8c26967a7b" })}>
					click
				</Button> */}
				<EditNew
					requestId={params.request}
					restrictions={restrictions}
					// commentNotificationMetadata={commentNotificationMetadata}
				/>
			</>
		);
	}

	return (
		<EditLegacy
			requestId={params.request}
			restrictions={restrictions}
		/>
	);
}
