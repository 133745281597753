import clsx from 'clsx';
import React from 'react';

function ErrorMessage ({ message, className }) {
	return (
		<div className={clsx("d-flex align-items-center text-danger", className)}>
			<div className="mr-1">
				<span className="material-icons-outlined f-18px">
					warning
				</span>
			</div>
			<div className="mb-1">
				<span dangerouslySetInnerHTML={{ __html: message }} />
			</div>
		</div>
	);
}

export default ErrorMessage;
