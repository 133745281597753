import { actionTypes } from "./actions/integrations.actions";

const initialState = {
	list: [],
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.INTEGRATIONS_LOADED: {
			return {
				...state,
				list: action.payload,
			}
		}
		case actionTypes.INTEGRATION_ADDED: {
			const updatedList = state.list.map((integration) => {
				if (integration._id === action.payload.integrationId) {
					return {
						...integration,
						config: action.payload
					}
				}

				return integration;
			});

			return {
				...state,
				list: updatedList,
			}
		}
		case actionTypes.INTEGRATION_UPDATED: {
			const updatedList = state.list.map((integration) => {
				if (integration._id === action.payload.integrationId) {
					return {
						...integration,
						config: action.payload
					}
				}

				return integration;
			});

			return {
				...state,
				list: updatedList,
			}
		}
		default:
			return state;
	}
}
