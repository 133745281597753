import React from "react";

export default ({ fill = '#2E5BFF', size = 59, viewBox }) => {
	const viewBoxValue = viewBox || `0 0 ${size} ${size}`;

	return (
		<svg width={size} height={size} viewBox={viewBoxValue} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path d="M19.801 10.3687C19.5586 10.1544 19.1882 10.1775 18.974 10.4199L18.9645 10.4306V10.02C18.9645 7.62529 18.0319 5.37385 16.3385 3.68042C14.6452 1.98715 12.3938 1.05469 9.99906 1.05469C8.55252 1.05469 7.11514 1.40747 5.8421 2.07474C4.61041 2.72034 3.52932 3.65952 2.71572 4.79096C2.52682 5.05356 2.58663 5.41978 2.84939 5.60868C3.11215 5.79774 3.47836 5.73792 3.66726 5.47517C4.37497 4.49097 5.31521 3.67402 6.38623 3.11264C7.49204 2.53296 8.74128 2.22656 9.99906 2.22656C14.2964 2.22656 17.7927 5.72282 17.7927 10.0202V10.4308L17.783 10.4201C17.5688 10.1775 17.1985 10.1546 16.956 10.3688C16.7134 10.5831 16.6905 10.9534 16.9047 11.1958L17.9393 12.3671C18.0505 12.493 18.2104 12.565 18.3784 12.565C18.5464 12.565 18.7063 12.493 18.8176 12.3671L19.8521 11.1958C20.0665 10.9532 20.0436 10.5829 19.801 10.3687Z" fill={fill} />
				<path d="M17.1497 14.4289C16.887 14.24 16.5209 14.2997 16.3318 14.5624C15.6241 15.5465 14.6839 16.3634 13.6129 16.9249C12.5071 17.5046 11.2577 17.811 10 17.811C5.7027 17.811 2.20645 14.3148 2.20645 10.0174V9.60682L2.21606 9.61765C2.43029 9.86011 2.80062 9.88315 3.04309 9.66877C3.2857 9.45453 3.30859 9.0842 3.09436 8.84174L2.05981 7.67047C1.94842 7.54459 1.78866 7.47241 1.62066 7.47241C1.45266 7.47241 1.2926 7.54459 1.18151 7.67047L0.146811 8.84174C-0.0674228 9.08435 -0.0445346 9.45453 0.19808 9.66877C0.30947 9.76734 0.447867 9.81571 0.585807 9.81571C0.747856 9.81571 0.909294 9.74872 1.02511 9.61765L1.03457 9.60682V10.0174C1.03457 12.4123 1.96719 14.6636 3.66061 16.357C5.35388 18.0504 7.60532 18.9829 10 18.9829C11.4464 18.9829 12.884 18.6301 14.157 17.9628C15.3885 17.3172 16.4696 16.3779 17.2832 15.2466C17.4721 14.9839 17.4125 14.6178 17.1497 14.4289Z" fill={fill} />
				<path d="M4.21922 11.5193C4.17894 11.6693 4.2 11.8292 4.27782 11.9638L5.4497 13.9936C5.52736 14.1282 5.65538 14.2263 5.80538 14.2666C5.95553 14.3067 6.11544 14.2858 6.25002 14.208L7.11169 13.7106C7.45577 13.9794 7.83709 14.2001 8.24084 14.364V15.3575C8.24084 15.681 8.50314 15.9434 8.82678 15.9434H11.1705C11.494 15.9434 11.7565 15.681 11.7565 15.3575V14.364C12.1602 14.2001 12.5415 13.9794 12.8856 13.7106L13.7473 14.208C13.8817 14.2857 14.0418 14.3067 14.1918 14.2666C14.3419 14.2263 14.4699 14.1282 14.5476 13.9936L15.7195 11.9638C15.7972 11.8292 15.8182 11.6693 15.7781 11.5193C15.7378 11.3691 15.6395 11.2411 15.5051 11.1634L14.6416 10.6649C14.6712 10.451 14.6862 10.2345 14.6862 10.018C14.6862 9.79884 14.6709 9.58018 14.6405 9.36381L15.5051 8.86469C15.6397 8.78703 15.7378 8.659 15.7781 8.50886C15.8182 8.35871 15.7972 8.1988 15.7195 8.06422L14.5476 6.03449C14.3859 5.75434 14.0274 5.65836 13.7473 5.8201L12.88 6.3209C12.5373 6.05402 12.1579 5.8349 11.7565 5.67194V4.67066C11.7565 4.34717 11.4942 4.08472 11.1705 4.08472H8.82678C8.50314 4.08472 8.24084 4.34717 8.24084 4.67066V5.67194C7.83953 5.8349 7.46004 6.05402 7.11748 6.3209L6.25002 5.8201C5.96987 5.65821 5.61144 5.75434 5.4497 6.03449L4.27782 8.06422C4.20015 8.1988 4.17909 8.35886 4.21922 8.50886C4.25951 8.659 4.35762 8.78703 4.49221 8.86469L5.35677 9.36381C5.3264 9.58018 5.31115 9.79884 5.31115 10.018C5.31115 10.2345 5.3261 10.4508 5.3557 10.6649L4.49236 11.1634C4.35762 11.2411 4.25951 11.3691 4.21922 11.5193ZM9.99865 8.26014C10.9694 8.26014 11.7565 9.04719 11.7565 10.018C11.7565 10.9887 10.9694 11.7758 9.99865 11.7758C9.02774 11.7758 8.24084 10.9887 8.24084 10.018C8.24084 9.04719 9.02789 8.26014 9.99865 8.26014Z" fill={fill} />
			</g>
		</svg>
	);
}
