import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../common/Input";
import IOSSwitch from "../../../common/IOSSwitch";
import Constants from "../../../common/constants";
import {
  addConfig,
  flagNames,
  updateConfig,
} from "../../../store/modules/actions/integrations.actions";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { getFlags } from "../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
  return {
    fieldSubtitle: {
      fontSize: 13,
      color: "#C3C9D5",
    },
  };
});

function BankStatementIntegration(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();

  const flags = useSelector(getFlags);
  const integration = useSelector((state) => {
    const bankStatementIntegration = state.integrations.list.find(
      ({ slug }) => slug === Constants.IntegrationsType.BANK_STATEMENTS
    );

    return bankStatementIntegration;
  });

  const validate = useCallback(
    (values) => {
      const errors = {};
      const pattern = new RegExp(
        "^(https:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator

      if (!values.url || !pattern.test(values.url)) {
        errors.url = intl.formatMessage({
          id: "INTEGRATIONS.BANK_STATEMENT.FIELD.URL_VALIDATE.INVALID",
        });
      }

      return errors;
    },
    [intl]
  );

  const submit = useCallback(
    (values) => {
      const { isActive, url } = values;
      const obj = {
        url,
      };

      if (integration?.config) {
        dispatch(updateConfig(integration?.config._id, isActive, obj));
      } else {
        dispatch(addConfig(integration?._id, isActive, obj));
      }
    },
    [integration, dispatch]
  );

  const isAdding = useMemo(
    () => flags.loading[flagNames.ADDING_INTEGRATION] === integration?._id,
    [flags.loading, integration]
  );
  const isUpdating = useMemo(
    () => {
			const integrationId = _.get(integration, `config._id`);
      return flags.loading[flagNames.UPDATING_INTEGRATION] === integrationId;
		},
    [flags.loading, integration]
  );
  const isLoading = useMemo(() => isAdding || isUpdating, [
    isAdding,
    isUpdating,
  ]);

  if (!integration) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
			</div>
		)
  }

  return (
    <div className="mx-20px">
      <div className="mb-3 cursor-pointer" onClick={() => history.goBack()}>
        <ArrowBack style={{ fontSize: 26 }} />
      </div>
    
      <div className="px-40px py-30px bg-white rounded">
        <Formik
          initialValues={{
            isActive: Boolean(_.get(integration, `config.isActive`, false)),
            allowUrlChange: !_.get(integration, `config.configObj.url`, ""),
            url: _.get(integration, `config.configObj.url`, ""),
          }}
          enableReinitialize
          validate={validate}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={submit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="f-20px text-dark mr-15px">
                      <FormattedMessage id="INTEGRATIONS.BANK_STATEMENT.FIELD.BANK_STATEMENT_SERVICE" />
                    </div>
                    <div>
                      <IOSSwitch
                        rootClass={clsx("my-0 mr-2 ml-0", {
                          "opacity-30":
                            flags.loading[
                              flagNames.UPDATE_BANK_STATEMENT_SERVICE
                            ],
                        })}
                        enabledTrackClass="bg-primary"
                        checked={values.isActive}
                        disabled={isLoading}
                        onChange={(e) => {
                          setFieldValue("isActive", !values.isActive);
                          handleSubmit(e);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      "mt-10px text-dark f-14px fw-300",
                      classes.fieldSubtitle
                    )}
                  >
                    <FormattedMessage id="INTEGRATIONS.BANK_STATEMENT.FIELD.BANK_STATEMENT_SERVICE.HINT" />
                  </div>
                </div>

                {values.isActive && (
                  <div className="row mt-20px">
                    <div className="col-12 col-md-8 d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-center justify-content-md-start">
                      <Input
                        containerClass="mr-0 mr-md-4"
                        labelId="INTEGRATIONS.BANK_STATEMENT.FIELD.URL"
                        type="text"
                        name="url"
                        variant="outlined"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={!values.allowUrlChange}
                        value={values.url}
                        helperText={_.get(touched, `url`) && _.get(errors, `url`)}
                        error={Boolean(
                          _.get(touched, `url`) && _.get(errors, `url`)
                        )}
                      />

                      <div className="mt-25px text-center">
                        {values.allowUrlChange ? (
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary btn-block btn-elevate kt-login__btn-primary"
                          >
                            <FormattedMessage id="INTEGRATIONS.BANK_STATEMENT.SUBMIT" />
                          </button>
                        ) : (
                          <span
                            className="text-primary btn p-0 px-md-2 py-md-3 cursor-pointer"
                            onClick={() => setFieldValue("allowUrlChange", true)}
                          >
                            <FormattedMessage id="INTEGRATIONS.BANK_STATEMENT.CHANGE_URL" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default BankStatementIntegration;