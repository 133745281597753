import React from "react";
import { Close, Done } from "@material-ui/icons";

const ENTERPRISE_PLAN = "ENTERPRISE";

function Value({ feature, plan, currency = "AUD" }) {
	const {
		type,
		key
	} = feature;

	if (plan.slug === ENTERPRISE_PLAN) {
		if (feature.key === "ADDITIONAL_REQUESTS") {
			return (
				<div className="text-dark">3</div>
			)
		}
		else {
			if (type === "BOOLEAN") {
				return (
					<Done color="primary" />
				)
			}
			else {
				return (
					<div className="text-dark">Custom</div>
				);
			}
		}
	}

	const featureValue = plan.features.find((f) => f.key === key).value;

	if (type === "NUMBER") {
		return (
			<div className="text-dark">{featureValue}</div>
		);
	}
	else if (type === "PRICE") {
		return (
			<div className="text-dark">${featureValue[currency]}</div>
		)
	}
	else if (type === "BOOLEAN") {
		return featureValue ? (
			<Done color="primary" />
		) : (
			<Close />
		)
	}

	return null;
}

export default Value;
