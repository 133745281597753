import _ from "lodash";
import * as TemplatesCRUD from '../../../crud/templates.crud';
import * as ProviderCRUD from '../../../crud/provider.crud';
import { setErrorFlag, setLoadingFlag } from './loading-flag.actions';
import store from '../../store';
import { uploadFileTask, uploadFileTaskResult } from "./tasks.actions";

export const actionTypes = {
	TEMPLATES_LOADED: "[TEMPLATE] TEMPLATES_LOADED",
	TEMPLATE_LOADED: "[TEMPLATE] TEMPLATE_LOADED",
	DEFAULT_TEMPLATES_LOADED: "[TEMPLATE] DEFAULT_TEMPLATES_LOADED",
	PERFORM_OPERATION: "[TEMPLATE] PERFORM_OPERATION",
	UPDATE_FILTER: "[TEMPLATE] UPDATE_FILTER",
};

export const flagNames = {
	TEMPLATES: "[TEMPLATE] TEMPLATES",
	TEMPLATE: "[TEMPLATE] TEMPLATE",
	CREATE: "[TEMPLATE] CREATE",
	CREATE_V2: "[TEMPLATE] CREATE_V2",
	UPDATE: "[TEMPLATE] UPDATE",
	UPDATE_V2: "[TEMPLATE] UPDATE_V2",
	DELETE: "[TEMPLATE] DELETE",
	DEFAULT_TEMPLATES: "[TEMPLATE] DEFAULT_TEMPLATES",
};

export const V2_OPERATIONS = {
	PREVIEW: "PREVIEW",
	DELETE_ELEMENT: "DELETE_ELEMENT",
};


export const loadTemplates = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.TEMPLATES, true));

		try {
			const { status, data } = await TemplatesCRUD.getUserTemplates();

			if (status === 200) {
				const state = store.getState();
				const user = state.auth.user;

				dispatch({
					type: actionTypes.TEMPLATES_LOADED,
					payload: {
						list: data,
						user,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.TEMPLATES, data.message));
			}

			dispatch(setLoadingFlag(flagNames.TEMPLATES, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.TEMPLATES, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const loadTemplate = (templateId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.TEMPLATE, true));

		try {
			const { status, data } = await TemplatesCRUD.getUserTemplate(templateId);

			if (status === 200) {
				dispatch({
					type: actionTypes.TEMPLATE_LOADED,
					payload: {
						template: data,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.TEMPLATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.TEMPLATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.TEMPLATE, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const uploadFileToElement = (templateUUID, elementUUID, files, uploadId) => {
	return async (dispatch) => {
		const fileData = [];
		
		dispatch(setLoadingFlag(flagNames.UPLOAD, elementUUID));

		try {
			for (const file of files) {
				const {
					name,
					type,
					size,
				} = file;
				const filePath = `templates/${templateUUID}/${elementUUID}/attachments/${name}`;
	
				const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
				const {
					signedRequest,
					url
				} = data;
	
				const response = await dispatch(
					uploadFileTask(
						signedRequest,
						file
					)
				);
	
				if (response.wasCancelled) {
					continue;
				}
	
				fileData.push({
					name,
					url,
					// convert size from bytes to KB
					size: size / 1024
				});
			}

			dispatch(
				uploadFileTaskResult(
					uploadId,
					fileData,
				)
			);

			return Promise.resolve(fileData);
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPLOAD, errorMessage));
			return Promise.reject(e);
		}
		finally {
			dispatch(setLoadingFlag(flagNames.UPLOAD, false));
		}
	};
}

export const createTemplate = (templateData) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.CREATE, true));

		try {
			for (const item of templateData.items) {
				for (let fileIndex = 0; fileIndex < item.attachments.length; fileIndex++) {
					const file = item.attachments[fileIndex];

					if (file instanceof File) {
						const {
							name,
							type,
							size,
						} = file;
						const filePath = `templates/${item.uuid}/attachments/${name}`;

						const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
						const {
							signedRequest,
							url
						} = data;

						const response = await dispatch(
							uploadFileTask(
								signedRequest,
								file
							)
						);

						if (response.wasCancelled) {
							continue;
						}

						item.attachments[fileIndex] = {
							name,
							url,
							// convert size from bytes to KB
							size: size / 1024
						};
					}
				}
			}

			const { status, data } = await TemplatesCRUD.createTemplate(templateData);

			if (status === 200) {
				dispatch(loadTemplates());
			}
			else {
				dispatch(setErrorFlag(flagNames.CREATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CREATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CREATE, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const createTemplateV2 = (templateData) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.CREATE_V2, true));

		try {
			const { status, data } = await TemplatesCRUD.createTemplateV2(templateData);

			if (status === 200) {
				dispatch(loadTemplates());
			}
			else {
				dispatch(setErrorFlag(flagNames.CREATE_V2, data.message));
			}

			dispatch(setLoadingFlag(flagNames.CREATE_V2, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.CREATE_V2, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const updateTemplate = (_id, templateData) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE, true));

		for (const item of templateData.items) {
			for (let fileIndex = 0; fileIndex < item.attachments.length; fileIndex++) {
				const file = item.attachments[fileIndex];

				if (file instanceof File) {
					const {
						name,
						type,
						size,
					} = file;
					const filePath = `templates/${item.uuid}/attachments/${name}`;

					const { data } = await ProviderCRUD.getSignedUrl(filePath, type);
					const {
						signedRequest,
						url
					} = data;

					const response = await dispatch(
						uploadFileTask(
							signedRequest,
							file
						)
					);

					if (response.wasCancelled) {
						continue;
					}

					item.attachments[fileIndex] = {
						name,
						url,
						// convert size from bytes to KB
						size: size / 1024
					};
				}
			}
		}

		try {
			const { status, data } = await TemplatesCRUD.updateTemplate(_id, templateData);

			if (status !== 200) {
				dispatch(setErrorFlag(flagNames.UPDATE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const updateTemplateV2 = (templateId, templateData) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.UPDATE_V2, true));

		try {
			const { status, data } = await TemplatesCRUD.updateTemplateV2(templateId, templateData);

			if (status !== 200) {
				dispatch(setErrorFlag(flagNames.UPDATE_V2, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATE_V2, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.UPDATE_V2, errorMessage));
			return Promise.reject(e);
		}
	}
};


export const deleteTemplate = (templateId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DELETE, true));

		try {
			const { status, data } = await TemplatesCRUD.deleteUserTemplate(templateId);

			if (status === 200) {
				dispatch(loadTemplates());
			}
			else {
				dispatch(setErrorFlag(flagNames.DELETE, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DELETE, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			const errorMessage = _.get(e, 'response.data.error.message', e.toString());
			dispatch(setErrorFlag(flagNames.DELETE, errorMessage));
			return Promise.reject(e);
		}
	}
};

export const selectTemplate = (template) => {
	return {
		type: actionTypes.TEMPLATE_LOADED,
		payload: {
			template
		},
	}
}

export const performOperation = (action, data) => {
	return {
		type: actionTypes.PERFORM_OPERATION,
		payload: {
			action,
			data,
		},
	}
}

export const updateFilter = (data) => {
	return {
		type: actionTypes.UPDATE_FILTER,
		payload: {
			data
		}
	}
}
