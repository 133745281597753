/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { /*OverlayTrigger, Tooltip,*/ Badge } from "react-bootstrap";

class NotificationListToggler extends React.Component {
  render() {
    const {
      unreadCount,
    } = this.props;
    const toggleBy = this.props.toggleBy || "kt_notification_list_toggler_btn";

    return (
      <div
        className="kt-header__topbar-item kt-header__topbar-item--quick-panel"
        data-toggle="kt-tooltip"
        title="Notifications"
        data-placement="right"
      >
        <span
          className="kt-header__topbar-icon"
          id={toggleBy}
        >
          <h6 className={`${clsx({
            "d-none": !unreadCount || unreadCount <= 0
          })}`}>
            <Badge
              className="unread-count"
              variant="primary"
              pill
            >
              {unreadCount}
            </Badge>
          </h6>

          <span className="fas fa-bell"></span>
        </span>
      </div>
    );
  }
}

export default connect(
  ({ notifications }) => ({
    unreadCount: notifications.unreadCount,
  }),
  null
)(NotificationListToggler);