import React from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Avatar
} from "@material-ui/core";
import clsx from "clsx";

import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";

import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import { logout } from "../../../app/store/modules/actions/auth.actions";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    this.ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  render() {
    const {
      user,
    } = this.props;
    const props = this.props;

    return (
      <React.Fragment>
        <button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt_aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <Brand />

          {
            user && (
              <div className="aside-user-profile px-30 d-flex align-items">
                <div className='d-flex align-items-center'>
                  <div className="mr-1">
                    <Avatar className="user-avatar">{`${user.firstName[0]}${user.lastName[0]}`.toUpperCase()}</Avatar>
                  </div>
                  <div>
                    <div className="username ml-2 text-primary-dark">{user.firstName + ' ' + user.lastName}</div>
                    <div className="username ml-2">{user.company}</div>
                  </div>
                </div>
              </div>
            )
          }

          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            {props.disableScroll && (
              <Menu htmlClassService={props.htmlClassService} />
            )}
            {!props.disableScroll && (
              <PerfectScrollbar
                options={{ wheelSpeed: 2, wheelPropagation: false }}
              >
                <Menu htmlClassService={props.htmlClassService} />
              </PerfectScrollbar>
            )}
          </div>

          <div
            className={clsx(
              "text-center py-4", 
              { "d-none": process.env.REACT_APP_ENV === "production" }
            )}
          >
            {`v ${process.env.REACT_APP_VERSION_CODE}`}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => ({
  user: store.auth && store.auth.user,
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  }
});

export default connect(mapStateToProps, { logout })(AsideLeft);
