import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import {
	useParams
} from "react-router-dom";

import {
	forgotPassword,
	flagNames,
} from '../../../store/modules/actions/provider.actions';
import Input from "../../../common/Input";
import { getFlags } from "../../../store/modules/selectors/common.selector";

function Step1(props) {
	const params = useParams();
	const {
		request,
		provider
	} = params;
	const {
		nextStep,
		intl,
		forgotPassword
	} = props;

	const flags = useSelector(getFlags);
	const isLoading = flags.loading[flagNames.FORGOT_PASSWORD];
	const error = flags.error[flagNames.FORGOT_PASSWORD];

	return (
		<Formik
			initialValues={{
				email: '',
			}}
			validate={values => {
				const errors = {};

				if (!values.email) {
					// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
					errors.email = intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD"
					});
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = intl.formatMessage({
						id: "AUTH.VALIDATION.INVALID_FIELD"
					});
				}

				return errors;
			}}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={async (values) => {
				try {
					await forgotPassword(request, provider, values.email);
					nextStep(values);
				}
				catch (e) {
					console.log(e);
				}
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
			}) => {
				return (
					<form
						noValidate={true}
						autoComplete="off"
						onSubmit={handleSubmit}
					>
						<div className="container">
							<div className="row">
								<div className="col-12">
									<Input
										containerClass="mb-25"
										labelId="AUTH.INPUT.EMAIL"
										variant="outlined"
										type="email"
										fullWidth
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.email}
										helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)}
									/>
								</div>
							</div>

							{
								error && (
									<Alert color="danger" className="d-flex justify-content-center mb-25">
										{error}
									</Alert>
								)
							}

							<div className="pt-20 d-flex justify-content-center">
								<button
									type="submit"
									disabled={isLoading}
									className={`btn btn-primary btn-elevate kt-login__btn-primary py-3 ${clsx(
										{
											"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
										}
									)}`}
								>
									<FormattedMessage id="PROVIDER.FORGOT_PASSWORD.SUBMIT" />
								</button>
							</div>
						</div>
					</form>
				);
			}}
		</Formik>
	)
}

export default injectIntl(
	connect(
		null,
		{
			forgotPassword
		}
	)(Step1)
);