import { makeStyles, Tooltip } from "@material-ui/core";
import { Pause, PlayArrow, Stop } from "@material-ui/icons";
import clsx from "clsx";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { addAlpha } from "../../../../../_metronic";

const timerWidgetStyles = makeStyles((theme) => {
	return {
		divider: {
			borderRight: `1px solid ${addAlpha(theme.palette.dark.primary.contrastText, 0.2)}`
		}
	}
})

const RECORDING_LIMIT_SECONDS = 5 * 60;
function TimerWidget ({ status, onStop, canPause, onPause, onResume }) {
	const intl = useIntl();
	const classes = timerWidgetStyles();
	const intervalRef = useRef();
	const [timerSeconds, setTimerSeconds] = useState(RECORDING_LIMIT_SECONDS);
	const padValue = useCallback((str) => {
		return `0${str}`.substr(-2);
	}, []);
	const timerText = useMemo(() => {
		const mins = parseInt(timerSeconds / 60);
		const seconds = timerSeconds % 60;

		return `${padValue(mins)}:${padValue(seconds)}`;
	}, [timerSeconds, padValue]);
	const timerTextClass = useMemo(() => {
		if (timerSeconds <= 30 && timerSeconds > 0) {
			return 'text-danger';
		}
		else {
			return 'text-white';
		}
	}, [timerSeconds]);

	useEffect(() => {
		if (timerSeconds === 0) {
			onStop();
		}
	}, [onStop, timerSeconds]);

	useEffect(() => {
		if (status === "recording") {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}

			intervalRef.current = setInterval(() => {
				setTimerSeconds((val) => val - 1);
			}, 1000);
		}
		else if (status === "paused") {
			clearInterval(intervalRef.current);
		}
	}, [status]);

	return (
		<div className='bg-dark px-15px py-5px rounded-pill d-flex justify-content-center'>
			<div className='d-flex align-items-center'>
				<div className='mr-10px cursor-pointer' onClick={onStop}>
					<Tooltip title={intl.formatMessage({ id: "ELEMENT_LIST.ITEM.VIDEO_INPUT.RECORD.STOP_BUTTON_TOOLTIP" })}>
						<Stop className='text-danger' />
					</Tooltip>
				</div>

				<div className={timerTextClass} style={{ width: 40 }}>
					{timerText}
				</div>
			</div>

			{
				canPause && (
					<Fragment>
						<div className={clsx('mx-2', classes.divider)} />

						{
							status === "recording" && (
								<div
									className="cursor-pointer"
									onClick={onPause}
								>
									<Tooltip title={intl.formatMessage({ id: "ELEMENT_LIST.ITEM.VIDEO_INPUT.RECORD.PAUSE_BUTTON_TOOLTIP" })}>
										<Pause className='text-white' />
									</Tooltip>
								</div>
							)
						}

						{
							status === "paused" && (
								<div
									className="cursor-pointer"
									onClick={onResume}
								>
									<Tooltip title={intl.formatMessage({ id: "ELEMENT_LIST.ITEM.VIDEO_INPUT.RECORD.RESUME_BUTTON_TOOLTIP" })}>
										<PlayArrow className="text-white" />
									</Tooltip>
								</div>
							)
						}
					</Fragment>
				)
			}
		</div>
	)
}

export default TimerWidget;