import io from "socket.io-client";

import { SERVER_URL } from "../../../_metronic/utils/utils";

class SocketService {
	socket = null;

	constructor() {
		this.socket = io(SERVER_URL);
	}
}

export default new SocketService();