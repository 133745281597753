import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	background: {
		position: "absolute",
		left: 0,
		opacity: 0.05,
		zIndex: 2,
		color: '#000000',
	},
	foreground: {
		zIndex: 100,
	},
	circle: {
		strokeLinecap: 'round',
	}
}));

function CircularProgressWithBackground({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, rest.size ? { height: rest.size } : "")}>
			<CircularProgress
				{...rest}
				value={100}
				variant="static"
				className={classes.background}
			/>
			<CircularProgress
				{...rest}
				className={clsx(classes.foreground, className)}
				classes={{
					circle: classes.circle,
				}}
			/>
		</div>
	);
}

export default CircularProgressWithBackground;
