import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { getFileIcon, getFileName, requestSignedUrl, downloadLocalFile } from "../../../../../../_metronic";
import { CloudDownloadIcon, DeleteIcon } from "../../../../../common/icons";
import SizeIndicator from "../../../../../common/size-indicator";
import { useDispatch } from "react-redux";
import { downloadFileTask } from "../../../../../store/modules/actions/tasks.actions";

const useStylesForFileCard = makeStyles((theme) => {
	return {
		container: {
			padding: "10px 15px",
			background: "rgba(224, 231, 255, 0.2)",
			border: `2px solid ${theme.palette.extraColors.blueBorder}`,
			borderRadius: "8px",
		},
		actionButton: {
			height: "24px",
			width: "24px",
			background: theme.palette.extraColors.blueSecondary,
			boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.06)",
			borderRadius: "5px",

			'&.pressed': {
				boxShadow: "none",
			}
		},
		sizeContainer: {
			background: theme.palette.extraColors.blueSecondary,
			borderRadius: "5px",
		}
	}
});

function FileCard({ onClick, templateId, documentId, file, onDelete }) {
	const classes = useStylesForFileCard();
	const {
		name: filename
	} = file;
	const dispatch = useDispatch();
	const [ isDownloading, setDownloading ] = useState(false);

	const downloadFile = useCallback(async () => {
		if (!file._id) {
			if (file instanceof File) {
				downloadLocalFile(file, filename);
				return;
			}

			console.log(`file is not uploaded yet`);
			return null;
		}

		const { data } = await requestSignedUrl(file.url);
		setDownloading(true);

		try {
			await dispatch(
				downloadFileTask(
					data.url,
					filename
				)
			);
		}
		finally {
			setDownloading(false);
		}
	}, [
		file,
		filename,
		dispatch,
	]);

	return (
		<div
			onClick={onClick}
			className={clsx(classes.container, "d-flex align-items-center bg-white cursor-pointer")}
		>
			<div className="mr-10px">
				<div>
					<img
						alt="file"
						src={getFileIcon(filename)}
						style={{
							height: 22,
							width: 18
						}}
					/>
				</div>
			</div>
			<div className="flex-grow-1 d-flex justify-content-between px-0 align-items-center">
				<div>
					{getFileName(filename)}
				</div>

				<div className="d-flex">
					<div className="d-flex align-items-center justify-content-center mr-10px">
						<SizeIndicator
							className={clsx(classes.sizeContainer, "d-flex justify-content-center align-items-center px-2 h-100 text-primary")}
							sizeInBytes={file._id ? file.size * 1024 : file.size}
						/>
					</div>

					<div className={
						clsx(
							classes.actionButton,
							{ "pressed": isDownloading },
							"d-flex justify-content-center align-items-center cursor-pointer mr-10px"
						)
					}>
						<span
							onClick={(e) => {
								e.stopPropagation();
								downloadFile();
							}}
						>
							<CloudDownloadIcon />
						</span>
					</div>
					<div
						className={clsx(classes.actionButton, "d-flex justify-content-center align-items-center cursor-pointer")}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							onDelete()
						}}
					>
						<DeleteIcon />
					</div>
				</div>
			</div>
		</div>
	);
}

export default FileCard;