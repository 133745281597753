import React from "react";
import { withRouter } from "react-router-dom";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	injectIntl
} from "react-intl";
import {
	connect
} from "react-redux";
import {
	CircularProgress,
	withStyles,
	Tabs,
} from "@material-ui/core";
import {
	Alert,
} from "reactstrap";
import clsx from "clsx";
import _ from "lodash";
import { withSnackbar } from "notistack";
import {
	Archive,
	Info,
	Unarchive,
	DoneAll,
	Refresh,
	CheckBox,
	CheckBoxOutlineBlankOutlined,
	ControlPointOutlined,
	DeleteForever,
	DeleteOutlined,
} from "@material-ui/icons";
import { parse } from "query-string";

import {
	updateDocument,
	updateProviderV2,
	uploadFile,
	bulkDownloadV2,
	cancelRequest,
	loadRequest,
	completeRequest,
	flagNames as requestFlagName,
	moveRequestToInProgress,
	archiveRequests,
	V2_OPERATIONS,
	performOperation,
	addProviderV2,
	providerRemove,
	updateProviderForm,
} from "../../../../store/modules/actions/requests.actions";
import {
	setUpgradeDialog,
} from "../../../../store/modules/actions/app.actions";

import TitleCard from "./TitleCardNew";
import StyledTab from "../common/StyledTab";
import Constants from "../../../../common/constants";
import {
	CancelCircleIcon,
	FileErrorIcon,
	CircleCheckIcon,
	SettingsIcon
} from "../../../../common/icons";
import Confirm, { Color } from "../../../../common/modals/confirm";
import { isValidEmail, doScrolling } from "../../../../../_metronic";

import ChatModal from "./Chat";
import DeleteFile from "./DeleteFile";
import ResendRequest from "./ResendRequest";
import RequestSettings from "../common/RequestSettings";
import { ProviderSettings } from "../common/ProviderSettings";
import { Template } from "../common/Template";
import { getEmptyErrorObj, validateElement } from "../../../../common/dnd-element-list/ElementPicker";
import Preview from "../../../../common/Preview";
import AddProviderModal from "./AddProviderModal";
import { ELEMENT_TYPES } from "../../../../common/dnd-element-list/ElementType";
import LogsHistory from "./LogsHistory";

const EDITABLE_PROVIDER_FIELDS = [
	"firstName",
	"lastName",
	"email",
	"phone",
	"bcc",
	"dueDate",
	"elements",
	"smsNotification",
	"requirePassword",
	"reminderNotification",
];

const REQUEST_OPERATION = {
	CANCEL: "CANCEL",
	MARK_COMPLETE: "MARK_COMPLETE",
	MOVE_TO_IN_PROGRESS: "MOVE_TO_IN_PROGRESS",
	ARCHIVE: "ARCHIVE",
}
const MAX_PROVIDERS_LIMIT = 4;

const classesGenerator = (theme) => {
	const {
		layoutSizes: {
			elementListContainer: {
				leftMenuSpace,
				editContainerPadding,
				containerWidth,
				spaceBetweenContainerAddButton,
				addButtonWidth,
				safeSpace,
			}
		}
	} = theme;
	const finalWidth = leftMenuSpace +
		(editContainerPadding*2) +
		containerWidth +
		spaceBetweenContainerAddButton +
		addButtonWidth + 
		safeSpace;

	return {
		inProgressModal: {
			"& .header-container svg path": {
				fill: theme.palette.success.main
			}
		},
		container: {
			maxWidth: containerWidth,

			"& .request-settings": {
				width: "100%",
				maxWidth: 323,
			},

			[`@media (min-width: ${finalWidth}px)`]: {
				maxWidth: containerWidth + spaceBetweenContainerAddButton + addButtonWidth,

				"& fieldset > div:not(.template-container):not(.request-settings)": {
					maxWidth: containerWidth,
				}
			}
		},
		addElementButton: {
			position: "fixed",
			right: 10,
			bottom: 90,

			"& button": {
				background: "white !important",
				width: addButtonWidth,
			},

			[`@media (min-width: ${finalWidth}px)`]: {
				marginLeft: 15,
				height: "fit-content",
				position: "sticky",
				bottom: 40,
				alignSelf: "flex-end",
			}
		},
		providerTabs: {
			"& .MuiTabs-flexContainer": {
				columnGap: 15,
			}
		},
		addProviderContainer: {
			paddingLeft: 28
		},
		addDocumentButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		addProviderButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		downloadInPDF: {
			fontSize: 12,
			fontWeight: 300,
		},
		footer: {
			[`@media (min-width: ${containerWidth}px)`]: {
				maxWidth: containerWidth,
			},

			"& button": {
				[theme.breakpoints.down("md")]: {
					width: "100%"
				},
			}
		}
	};
};

class Edit extends React.Component {
	submittedProviderIndex = -1;

	constructor(props) {
		super(props);

		const {
			selectedRequest,
		} = props;

		this.state = {
			requestOperation: "",
			chatProviderId: "",
			fileToDelete: null,
			originalProviders: selectedRequest.documentProviders.map((p) => _.pick(p, EDITABLE_PROVIDER_FIELDS)),
			documentIdToShowUploadsOf: null,
			showAddProviderModal: false,
			showResendRequestModal: false,
			shouldDownloadInPDF: false,
			showElementPicker: false,

			showAttachmentSizeWarning: false,
			selectedProviderIndex: 0,

			deleteProviderIndex: -1
		};

		// to prevent excessive api calls
		// indetified cases
		// 1. when user adds element from template to existing request it causes lots of api 
		//    calls and also in many cases adds duplicate elements because of lots of api calls
		this.saveProvider = _.debounce(this.saveProvider, 500);
	}

	componentDidMount() {
		const {
			history,
			performOperation,
			selectedRequest: {
				documentProviders,
			},
		} = this.props;
		const {
			fileId,
		} = parse(history.location.search);

		if (fileId) {
			let file = null;

			for (const provider of documentProviders) {
				for (const element of provider.elements) {
					if (element.type === ELEMENT_TYPES.BANK_STATEMENTS) {
						for (const fileObj of element.responseObj.files) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
					}
					else if (element.type === ELEMENT_TYPES.SHARED_FILES) {
						for (const fileObj of element.dataObj.attachments) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
					}
					else if (element.type === ELEMENT_TYPES.FILE_UPLOAD) {
						for (const fileObj of element.dataObj.attachments) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
						for (const fileObj of element.responseObj.files) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
					}
					else if (element.type === ELEMENT_TYPES.VIDEO_INPUT) {
						for (const fileObj of element.responseObj.files) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
					}
					else if (element.type === ELEMENT_TYPES.AUDIO_INPUT) {
						for (const fileObj of element.responseObj.files) {
							if (fileObj._id === fileId) {
								file = fileObj;
							}
						}
					}
				}
			}

			if (file) {
				performOperation(V2_OPERATIONS.PREVIEW, file);
			}
		}

		document.body.classList.add("modern-input-container");
	}
	componentWillUnmount() {
		document.body.classList.remove("modern-input-container");
	}
	componentDidUpdate(prevProps) {
		const {
			commentNotificationMetadata,
			selectedRequest: {
				documentProviders,
			},
			performOperation,
		} = this.props;
		if (
			commentNotificationMetadata &&
			commentNotificationMetadata.elementUUID !== prevProps.commentNotificationMetadata.elementUUID
		) {
			const {
				elementUUID,
			} = commentNotificationMetadata;
			let providerIndexToSelect = null;

			documentProviders.some((provider, index) => {
				provider.elements.some((element) => {
					if (element.uuid === elementUUID) {
						providerIndexToSelect = index;
					}

					return !!providerIndexToSelect;
				});

				return !!providerIndexToSelect;
			});

			if (providerIndexToSelect !== null) {
				if (providerIndexToSelect !== this.state.selectedProviderIndex) {
					this.setState({
						selectedProviderIndex: providerIndexToSelect,
					});
				}

				setTimeout(() => {
					const animationDuration = 1000;
					doScrolling(`#element-${elementUUID}`, animationDuration, -200);

					setTimeout(() => {
						performOperation(V2_OPERATIONS.VIEW_COMMENTS, { uuid: elementUUID })
					}, animationDuration);
				}, 250);
			}
		}
	}


	bulkDownload = async (provider, element) => {
		const {
			bulkDownloadV2,
			intl,
		} = this.props;
		const {
			shouldDownloadInPDF,
		} = this.state;
		let filename = `${provider.firstName} ${provider.lastName}.zip`;
		let message = intl.formatMessage({ id: "REQUEST.DOWNLOADING_TEXT" }, { filename });

		if (element) {
			filename = `${element.name}.zip`;
			message = intl.formatMessage({ id: "REQUEST.DOWNLOADING_TEXT" }, { filename });
		}

		await bulkDownloadV2(provider, element, filename, message, shouldDownloadInPDF);

		// if request was made to download all files of all documents then
		// reset convert to pdf flag
		if (!element) {
			this.setState({
				shouldDownloadInPDF: false,
			});
		}
	}

	addNewProvider = () => {
		const {
			selectedRequest: {
				documentProviders,
			},
			restrictions,
			setUpgradeDialog,
		} = this.props;

		if (documentProviders.length + 1 >= restrictions.requestAllowed) {
			setUpgradeDialog("REQUEST.EDIT.PROVIDER.PLAN_LIMIT_REACHED");
			return;
		}

		this.setState({
			showAddProviderModal: true
		});
	}

	addNewProviderSubmit = async(provider) => {
		const {
			addProvider,
			requestId,
			selectedRequest: {
				documentProviders,
			},
			intl,
			enqueueSnackbar,
		} = this.props;
		const providerIndex = documentProviders.length + 1;

		await addProvider(requestId, provider, providerIndex);

		const msg = intl.formatMessage({
			id: "REQUESTS.PROVIDER.ADD.SUCCESS"
		});
		enqueueSnackbar(msg, { variant: "success" });

		this.setState({
			showAddProviderModal: false,
			selectedProviderIndex: documentProviders.length,
		});
	}

	saveProvider = async (provider) => {
		const {
			updateProviderV2,
		} = this.props;

		try {
			await updateProviderV2(provider._id, provider);
		}
		catch (e) {
			console.log(e);
		}
	};

	validateProvider = (providerIndex, provider) => {
		const { originalProviders } = this.state;
		const { intl, enqueueSnackbar, updateProviderForm } = this.props;
		const providerError = {};
		let showNoElementError = false, showInvalidDataError = false;

		if (!provider.firstName) {
			providerError.firstName = intl.formatMessage({
				id: "PROVIDER.FIRST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.lastName) {
			providerError.lastName = intl.formatMessage({
				id: "PROVIDER.LAST_NAME.VALIDATION.REQUIRED"
			});
		}
		if (!provider.email) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.REQUIRED"
			});
		} else if (!isValidEmail(provider.email)) {
			providerError.email = intl.formatMessage({
				id: "PROVIDER.EMAIL.VALIDATION.INVALID"
			});
		}
		if (!provider.dueDate) {
			providerError.dueDate = intl.formatMessage({
				id: "PROVIDER.DUE_DATE.VALIDATION.REQUIRED"
			});
		}

		if (!provider.elements || !provider.elements.length) {
			providerError.elementsMain = intl.formatMessage({
				id: "PROVIDER.DOCUMENT.VALIDATION.REQUIRED"
			});
		}

		if (provider.elements && provider.elements.length) {
			providerError.elements = provider.elements.map((element) => {
				const elementError = validateElement(element);
				const emptyErrorObj = getEmptyErrorObj(element.type);
				const hasError = !_.isEqual(elementError, emptyErrorObj);

				return hasError ? elementError : undefined;
			});

			const areAllElementsValid = providerError.elements.some((err) => !!err);

			if (!areAllElementsValid) {
				delete providerError.elements;
			}
		}

		// console.log(providerError);
		if (!_.isEmpty(providerError) && providerIndex === this.submittedProviderIndex) {
			const errorKeys = _.keys(providerError);
			// if errorKeys has elementsMain key then show no documents error
			showNoElementError = errorKeys.includes("elementsMain");

			// if errorKeys does not have only elementsMain error key then show invalid field error
			showInvalidDataError = errorKeys.length > 1 || errorKeys[0] !== "elementsMain"

			if (showInvalidDataError) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.EDIT.PROVIDER.VALIDATION_FAILED"
				});

				enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
			}

			if (showNoElementError) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.EDIT.PROVIDER.NO_DOCUMENTS_ERRORS"
				});

				enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
			}

			this.submittedProviderIndex = -1;
		}

		const isDataSame = originalProviders && _.isEqual(originalProviders[providerIndex], _.pick(provider, EDITABLE_PROVIDER_FIELDS));
		const isSafe = _.isEmpty(providerError);

		if (isSafe && !isDataSame) {
			updateProviderForm(provider);
			this.saveProvider(provider);
		}

		return providerError;
	}

	uploadFiles = (docId, files) => {
		const {
			uploadFile,
			selectedRequest,
			enqueueSnackbar,
			intl,
		} = this.props;
		let selectedDocument = null;
		const uuid = {
			request: selectedRequest.uuid,
		};

		for (let provider of selectedRequest.documentProviders) {
			uuid.provider = provider.uuid;

			for (let doc of provider.documents) {
				if (doc._id === docId) {
					selectedDocument = doc;
					break;
				}
			}

			if (selectedDocument) {
				uuid.document = selectedDocument.uuid;
				break;
			}
		}

		if (selectedDocument) {
			const existingFiles = selectedDocument.files;
			if (existingFiles.length + files.length > Constants.FILE_UPLOAD_LIMIT) {
				const errorMessage = intl.formatMessage({
					id: "REQUEST.DOCUMENT.UPLOAD.LIMIT_REACHED"
				}, {
					limit: Constants.FILE_UPLOAD_LIMIT,
					maxPossible: Constants.FILE_UPLOAD_LIMIT - existingFiles.length
				});

				enqueueSnackbar(errorMessage, {
					variant: "error",
					autoHideDuration: 5000,
				});
			}
			else {
				uploadFile(uuid.request, uuid.provider, uuid.document, files);
			}
		}
	}

	doesAttachmentsExceedsLimit = (provider, files) => {
		let existingAttachmentSizeKB = 0;

		provider.documents.forEach((doc) => {
			doc.attachments.forEach((attachment) => {
				existingAttachmentSizeKB += (attachment instanceof File ? (attachment.size / 1024) : attachment.size);
			});
		});

		const newFilesKB = files.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}

	downloadInPDF = () => {
		const {
			restrictions,
		} = this.props;
		const {
			shouldDownloadInPDF,
		} = this.state;

		if (!restrictions.features.PDF_CONVERSION) {
			this.props.setUpgradeDialog("GENERAL.UPGRADE_MESSAGE");
			return;
		}

		this.setState({
			shouldDownloadInPDF: !shouldDownloadInPDF
		});
	}

	handleDeleteProviderClose = async (wasSubmitted) => {
		const {
			selectedRequest: {
				_id: requestId,
				documentProviders,
			},
			providerRemove,
		} = this.props;
		const {
			selectedProviderIndex,
			deleteProviderIndex,
		} = this.state;
		const state = {
			deleteProviderIndex: -1,
		};
		const isLastProviderBeingRemoved = selectedProviderIndex === documentProviders.length - 1;
		const providerId = documentProviders[deleteProviderIndex]._id;

		if (wasSubmitted) {
			await providerRemove(requestId, providerId);
		}

		if (isLastProviderBeingRemoved) {
			state.selectedProviderIndex = documentProviders.length - 2;
		}

		this.setState(state);
	}

	render() {
		const {
			intl,
			selectedRequest,
			flags,
			classes,
			cancelRequest,
			completeRequest,
			archiveRequests,
			moveRequestToInProgress,
			user,
			loadRequest,
			requestId,
			fileToPreview,
			performOperation,
		} = this.props;
		const {
			chatProviderId,
			fileToDelete,
			requestOperation,
			documentIdToShowUploadsOf,
			showResendRequestModal,
			showAttachmentSizeWarning,
			shouldDownloadInPDF,
			selectedProviderIndex,
			showAddProviderModal,
			showElementPicker,
			deleteProviderIndex,
		} = this.state;
		const {
			status,
			documentProviders,
			archived,
		} = selectedRequest;

		let documentToShowUploadsOf = null;

		if (documentIdToShowUploadsOf) {
			documentProviders.some((provider) => {
				provider.documents.some((doc) => {
					if (doc._id === documentIdToShowUploadsOf) {
						documentToShowUploadsOf = doc;
					}

					return !!documentToShowUploadsOf;
				});

				return !!documentToShowUploadsOf;
			});
		}

		const isInReadOnlyMode = user && user.readOnly;
		const isDisabled = archived || isInReadOnlyMode;

		return (
			<div className={`modern-input-container ${classes.container}`}>
				{
					archived && (
						<div className="d-flex justify-content-center">
							<Alert color="secondary" className="mb-3 d-flex align-items-center">
								<div>
									<div className="d-none d-sm-block">
										<Info />
									</div>
									<div className="d-block d-sm-none">
										<Info fontSize="large" />
									</div>
								</div>
								<div className="pl-3">
									<FormattedMessage id="REQUEST.ARCHIVED_MESSAGE" />
								</div>
							</Alert>
						</div>
					)
				}

				<div className="mb-25px">
					<TitleCard
						status={status}
						onArchive={() => this.setState({ requestOperation: REQUEST_OPERATION.ARCHIVE })}
						onComplete={() => this.setState({ requestOperation: REQUEST_OPERATION.MARK_COMPLETE })}
						onCancel={() => this.setState({ requestOperation: REQUEST_OPERATION.CANCEL })}
						onInProgress={() => this.setState({ requestOperation: REQUEST_OPERATION.MOVE_TO_IN_PROGRESS })}
					/>
				</div>

				<div className="mb-10px d-flex align-items-center">
					<Tabs
						className={classes.providerTabs}
						value={selectedProviderIndex}
						onChange={(e, index) => this.setState({ selectedProviderIndex: index })}
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						classes={{
							flexContainer: "mt-1",
							indicator: "d-none",
						}}
					>
						{
							documentProviders.map((provider, providerIndex, arr) => {
								const tabTitle = `${provider.firstName} ${provider.lastName}`;
								const isSelected = selectedProviderIndex === providerIndex;
								const isLastRemainingProvider = arr.length === 1;
								const canDelete = isSelected && !isLastRemainingProvider;

								return (
									<StyledTab
										key={provider._id}
										label={tabTitle}
										autoCapitalize="false"
										isSelected={providerIndex === selectedProviderIndex}
										actionIcon={
											canDelete && (
												<div className="text-danger">
													<DeleteForever />
												</div>
											)
										}
										onActionClick={() => this.setState({ deleteProviderIndex: providerIndex })}
										classes={{
											root: "f-16px font-weight-regular px-15px py-10px",
										}}
									/>
								);
							})
						}
					</Tabs>

					{
						documentProviders.length < MAX_PROVIDERS_LIMIT && (
							<div className="ml-20px">
								<button
									className="btn bg-white f-16px shadow px-20px py-10px"
									size="small"
									disabled={isDisabled}
									onClick={this.addNewProvider}
								>
									<FormattedMessage id="REQUEST.PROVIDER.ADD_RECIPIENT" />
								</button>
							</div>
						)
					}
				</div>

				{
					!!documentProviders[selectedProviderIndex] && (
						<Formik
							initialValues={documentProviders[selectedProviderIndex]}
							enableReinitialize
							validate={(provider) => this.validateProvider(selectedProviderIndex, provider)}
							validateOnChange={false}
							validateOnBlur={true}
						>
							{({
								values: provider,
							}) => {
								const canAddElements = [
									Constants.ProviderStatus.IN_PROGRESS,
									Constants.ProviderStatus.IN_REVIEW,
									Constants.ProviderStatus.PENDING,
								].includes(provider.status);

								return (
									<fieldset disabled={isInReadOnlyMode}>
										<div className="request-settings mb-20px col-12 col-md-6 px-0">
											<RequestSettings
												keyPrefix=""
												isRequestDisabled={false}
											/>
										</div>

										<div className="mb-20px provider-settings">
											<ProviderSettings
												handleResendRequest={() => this.setState({ showResendRequestModal: true })}
												forceUpdateTrigger={selectedProviderIndex}
												onChat={() => this.setState({ chatProviderId: provider._id })}
												keyPrefix=""
												isRequestDisabled={false}
											/>
										</div>

										<div className="d-flex template-container">
											<div className="flex-grow-1">
												<div>
													<Template
														additionalData={{
															requestUUID: selectedRequest.uuid,
															providerUUID: provider.uuid,
														}}
														keyPrefix=""
														isRequestDisabled={false}
														showElementPicker={showElementPicker}
														onElementPickerClose={() => this.setState({ showElementPicker: false })}
													/>
												</div>

												<div className="bg-white rounded-bottom pb-4 pl-20px">
													<div>
														<div className="d-flex align-items-center">
															{
																flags.loading[requestFlagName.BULK_DOWNLOAD] &&
																flags.loading[requestFlagName.BULK_DOWNLOAD][provider._id] === true && (
																	<div className="position-absolute">
																		<CircularProgress size={10} />
																	</div>
																)
															}
															<div
																className="ml-15px f-15px text-primary cursor-pointer"
																onClick={() => this.bulkDownload(provider)}
															>
																<FormattedMessage id="REQUEST.PROVIDER.DOWNLOAD_ALL_FILES" />
															</div>
														</div>

														<div
															className="ml-15px f-15px cursor-pointer"
															onClick={this.downloadInPDF}
														>
															{
																shouldDownloadInPDF ? (
																	<CheckBox color="primary" />
																) : (
																	<CheckBoxOutlineBlankOutlined color="primary" />
																)
															}

															<span className={clsx("ml-1", classes.downloadInPDF)}>
																<FormattedMessage id="REQUEST.DOWNLOAD_ALL_FILES.DOWNLOAD_IN_PDF" />
															</span>
														</div>
													</div>
												</div>
											</div>

											{
												canAddElements && (
													<div className={classes.addElementButton}>
														<button
															className="btn-elevate d-flex btn btn-light pl-2 f-16px px-20px"
															onClick={() => this.setState({ showElementPicker: true })}
														>
															<div className="mr-2">
																<ControlPointOutlined fontSize="large" />
															</div>
															<div>
																<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT.ADD_ELEMENT" />
															</div>
														</button>
													</div>
												)
											}
										</div>
									</fieldset>
								);
							}}
						</Formik>
					)
				}

				<Confirm
					open={requestOperation === REQUEST_OPERATION.CANCEL}
					icon={<CancelCircleIcon />}
					variant="danger"
					handleClose={async (wasSubmitted) => {
						if (wasSubmitted) {
							await cancelRequest(requestId);
							await loadRequest(requestId);
						}

						this.setState({ requestOperation: null });
					}}
					title="REQUEST.CANCEL.TITLE"
					submitButtonText="GENERAL.YES"
					cancelButtonText="GENERAL.NO"
					loading={flags.loading[requestFlagName.CANCEL]}
				/>

				<Confirm
					className={classes.inProgressModal}
					open={requestOperation === REQUEST_OPERATION.MOVE_TO_IN_PROGRESS}
					icon={
						<SettingsIcon
							size={75}
							viewBox="0 0 20 20"
						/>
					}
					variant="success"
					handleClose={async (wasSubmitted) => {
						if (wasSubmitted) {
							await moveRequestToInProgress(requestId);
							await loadRequest(requestId);
						}

						this.setState({ requestOperation: null });
					}}
					title="REQUEST.IN_PROGRESS.TITLE"
					submitButtonText="GENERAL.YES"
					cancelButtonText="GENERAL.NO"
					loading={flags.loading[requestFlagName.IN_PROGRESS]}
				/>

				<Confirm
					open={requestOperation === REQUEST_OPERATION.ARCHIVE}
					icon={
						archived ? (
							<Unarchive color="action" style={{ height: 75, width: 75 }} />
						) : (
							<Archive color="action" style={{ height: 75, width: 75 }} />
						)
					}
					variant="primary"
					handleClose={async (wasSubmitted) => {
						if (wasSubmitted) {
							await archiveRequests([selectedRequest], !selectedRequest.archived);
							await loadRequest(requestId);
						}

						this.setState({ requestOperation: null });
					}}
					title={archived ? "REQUEST.UNARCHIVE.CONFIRM.TITLE" : "REQUEST.ARCHIVE.CONFIRM.TITLE"}
					titleValues={{ totalCount: 1 }}
					submitButtonText="GENERAL.YES"
					submitButtonForcedColor={Color.secondary}
					cancelButtonText="GENERAL.NO"
					loading={flags.loading[requestFlagName.ARCHIVE]}
					error={flags.error[requestFlagName.ARCHIVE]}
				/>

				{
					!!fileToDelete && (
						<DeleteFile
							open
							document={fileToDelete.document}
							fileIndexToDelete={fileToDelete.index}
							handleClose={(wasSubmitted) => {
								this.setState({ fileToDelete: null });
							}}
						/>
					)
				}

				{
					!!chatProviderId && (
						<ChatModal
							provider={chatProviderId}
							handleClose={() => this.setState({ chatProviderId: null })}
						/>
					)
				}

				{
					requestOperation === REQUEST_OPERATION.MARK_COMPLETE && (
						<Confirm
							open
							icon={({ color }) => <CircleCheckIcon fill={color} />}
							variant="success"
							handleClose={async (wasSubmitted) => {
								if (wasSubmitted) {
									await completeRequest(requestId);
									await loadRequest(requestId);
								}

								this.setState({ requestOperation: null });
							}}
							title="REQUEST.COMPLETE.TITLE"
							submitButtonText="GENERAL.YES"
							cancelButtonText="GENERAL.NO"
							loading={flags.loading[requestFlagName.COMPLETE]}
						/>
					)
				}

				{
					showResendRequestModal && (
						<ResendRequest
							open
							request={selectedRequest}
							handleClose={() => this.setState({ showResendRequestModal: false })}
						/>
					)
				}

				{
					fileToPreview && (
						<Preview
							file={fileToPreview}
							handleClose={() => performOperation()}
						/>
					)
				}

				{
					showAddProviderModal && (
						<AddProviderModal
							handleClose={() => {
								this.setState({
									showAddProviderModal: false,
								});
							}}
							handleSubmit={this.addNewProviderSubmit}
						/>
					)
				}

				<Confirm
					open={showAttachmentSizeWarning}
					icon={<FileErrorIcon />}
					variant="danger"
					handleClose={() => this.setState({ showAttachmentSizeWarning: false })}
					title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
					message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
					submitButtonText="GENERAL.OK"
				/>

				{
					// just adding check for index will not work because after api call the redux store 
					// gets updated with new list of providers and causes re-render for this component
					// before handleDeleteProviderClose() resets value of state.deleteProviderIndex to -1
					// which will cause crash if user deleted provider at last index
					!!documentProviders[deleteProviderIndex] && (
						<Confirm
							open
							icon={<DeleteOutlined color="error" style={{ fontSize: "4rem" }} />}
							variant="danger"
							handleClose={this.handleDeleteProviderClose}
							title="REQUEST.PROVIDER.REMOVE.CONFIRM.TITLE"
							titleValues={{ providerName: `${documentProviders[deleteProviderIndex].firstName} ${documentProviders[deleteProviderIndex].lastName}` }}
							loading={flags.loading[requestFlagName.PROVIDER_REMOVE]}
							error={flags.error[requestFlagName.PROVIDER_REMOVE]}
							submitButtonText="REQUEST.PROVIDER.REMOVE.CONFIRM.SUBMIT"
							cancelButtonText="GENERAL.CANCEL"
						/>
					)
				}

				<Confirm
					open={showAttachmentSizeWarning}
					icon={<FileErrorIcon />}
					variant="danger"
					handleClose={() => this.setState({ showAttachmentSizeWarning: false })}
					title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
					message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
					submitButtonText="GENERAL.OK"
				/>

				<div className={clsx(classes.footer, "rounded p-20px bg-white d-flex flex-column flex-md-row my-15px")}>
					<div className="mr-md-3 mr-0 mb-2 mb-md-0">
						<button
							type="button"
							onClick={() => this.setState({ requestOperation: REQUEST_OPERATION.MARK_COMPLETE })}
							className="btn btn-success px-25px"
						>
							<div className="d-flex align-items-center justify-content-between justify-content-md-center">
								<div>
									<FormattedMessage
										id="PROVIDER.MARK_COMPLETED.TITLE"
									/>
								</div>
								<div className="ml-10px">
									<DoneAll />
								</div>
							</div>
						</button>
					</div>
					<div className="mr-0 mr-md-3">
						<button
							type="button"
							onClick={() => this.setState({ showResendRequestModal: true })}
							className="btn btn-outline-success px-25px"
						>
							<div className="d-flex align-items-center justify-content-between justify-content-md-center">
								<div className="mr-10px">
									<FormattedMessage
										id="REQUEST.RESEND_REQUEST"
									/>
								</div>
								<div>
									<Refresh />
								</div>
							</div>
						</button>
					</div>
				</div>

				<LogsHistory
					providerId={documentProviders[selectedProviderIndex]._id}
				/>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	let fileToPreview = null;

	if (store.requests.operation?.action === V2_OPERATIONS.PREVIEW) {
		fileToPreview = store.requests.operation.data;
	}

	return {
		fileToPreview,
		selectedRequest: store.requests.selectedRequest,
		user: store.auth.user,
		flags: store.flags,
	};
}

export default withRouter(
	withSnackbar(
		injectIntl(
			withStyles(classesGenerator)(
				connect(
					mapStateToProps,
					{
						addProvider: addProviderV2,
						updateDocument,
						updateProviderV2,
						uploadFile,
						bulkDownloadV2,
						cancelRequest,
						loadRequest,
						moveRequestToInProgress,
						archiveRequests,
						completeRequest,
						setUpgradeDialog,
						performOperation,
						providerRemove,
						updateProviderForm,
					}
				)(Edit)
			)
		)
	)
);
