import HeadingComponent from './Heading';
import ShortTextComponent from './ShortText';
import LongTextComponent from './LongText';
import RadioButtonComponent from './RadioButton';
import CheckboxComponent from './Checkbox';
import VideoComponent from './Video';
import DropdownComponent from './Dropdown';
import ImageComponent from './Image';
import BankStatementComponent from './BankStatements';
import NoteComponent from './Note';
import SharedFilesComponent from './SharedFiles';
import FileUploadComponent from './FileUpload';
import VideoInputComponent from './VideoInput';
import AudioInputComponent from './AudioInput';

export const ELEMENT_TYPES = {
	HEADING: 'HEADING',
	SHORT_TEXT: 'SHORT_TEXT',
	LONG_TEXT: 'LONG_TEXT',
	NOTES: 'NOTES',
	DROPDOWN: 'DROPDOWN',
	RADIO_BUTTONS: 'RADIO_BUTTONS',
	CHECKBOXES: 'CHECKBOXES',
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
	FILE_UPLOAD: 'FILE_UPLOAD',
	BANK_STATEMENTS: 'BANK_STATEMENTS',
	SHARED_FILES: 'SHARED_FILES',
	VIDEO_INPUT: 'VIDEO_INPUT',
	AUDIO_INPUT: 'AUDIO_INPUT',
};

export const Heading = HeadingComponent;
export const ShortText = ShortTextComponent;
export const LongText = LongTextComponent;
export const RadioButton = RadioButtonComponent;
export const Checkbox = CheckboxComponent;
export const Video = VideoComponent;
export const Dropdown = DropdownComponent;
export const Image = ImageComponent;
export const BankStatement = BankStatementComponent;
export const Note = NoteComponent;
export const SharedFiles = SharedFilesComponent;
export const FileUpload = FileUploadComponent;
export const VideoInput = VideoInputComponent;
export const AudioInput = AudioInputComponent;