import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useSelector,
	useDispatch,
} from "react-redux";
import {
	Dialog,
	DialogTitle,
	InputAdornment,
	OutlinedInput,
	makeStyles,
} from '@material-ui/core';
import {
	Sync
} from '@material-ui/icons';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	addProviderMessage,
	getProviderMessages,
	flagNames,
} from '../../../../store/modules/actions/requests.actions';
import moment from "moment";
import { ArrowRightIcon } from "../../../../common/icons";
import { getUser } from "../../../../store/modules/selectors/account.selector";
import { getRequestMessages } from "../../../../store/modules/selectors/request.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		messageInput: {
			borderRadius: 6,

			"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
				borderWidth: 1,
				borderColor: "rgba(46, 91, 255, 0.24)",
			},
			"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderWidth: 2,
			}
		},
		messageContainer: {
			marginTop: 15,
		},
		message: {
			marginBottom: 5,

			"& pre": {
				color: "inherit",
				fontSize: "inherit",
				whiteSpace: "break-spaces",
			},
		},
		myMessage: {
			color: "white",
			background: theme.palette.primary.main,
			borderTopLeftRadius: 8,
			borderBottomLeftRadius: 8,
			borderBottomRightRadius: 8,

			"& .user": {
				fontWeight: "bold",
				color: "white",
				marginBottom: 5,
			},

			"& .date": {
				color: "white",
				opacity: 0.6,
			}
		},
		otherMessage: {
			background: "rgba(176, 186, 201, 0.28)",
			borderBottomRightRadius: 8,
			borderTopLeftRadius: 8,
			borderTopRightRadius: 8,

			"& .user": {
				fontWeight: "bold",
				marginBottom: 5,
			},

			"& .date": {
				color: "#2E384D",
				opacity: 0.6,
			}
		}
	}
})



function Chat(props) {
	const {
		provider,
		handleClose,
	} = props;
	const intl = useIntl();
	const dispatch = useDispatch();
	const classes = useStyles();
	const messageList = useRef();

	const user = useSelector(getUser);
	const flags = useSelector(getFlags);
	const messageDataList = useSelector(getRequestMessages);
	const messages = useMemo(() => messageDataList?.slice()?.reverse() || [], [messageDataList]);
	
	const [ fetchCounter, setFetchCounter ] = useState(0);

	useEffect(() => {
		dispatch(getProviderMessages(provider));
	}, [dispatch, fetchCounter, provider]);

	useEffect(() => {
		if (messages && messages.length && messageList.current) {
			messageList.current.scrollTop = messageList.current.scrollHeight;
		}
	}, [messages]);

	const loading = flags.loading[flagNames.MESSAGES];
	const error = flags.error[flagNames.MESSAGES];

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth={false}
			classes={{
				paper: "col-4 col-lg-5 mx-auto"
			}}
		>
			<DialogTitle className="d-none">
				{''}
			</DialogTitle>

			<div className='m-4 d-flex flex-column' style={{ height: '80vh' }}>
				<div className="row f-15 label-4">
					<div className="col-8 offset-2">
						<h4 className="mb-0 text-center">
							<FormattedMessage id="REQUEST.PROVIDER.CHAT.TITLE" />
						</h4>
					</div>
					<div className="d-flex align-items-center justify-content-end col-2">
						<div onClick={() => setFetchCounter(fetchCounter + 1)} className="mx-2">
							<Sync />
						</div>
						<div className="float-right cursor-pointer mx-2" onClick={() => handleClose()}>
							<span className="fas fa-times"></span>
						</div>
					</div>
				</div>

				<div className="flex-grow-1 overflow-auto">
					{
						loading ? (
							<div className="w-100 h-100 d-flex justify-content-center">
								<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
							</div>
						) : (
							<React.Fragment>
								{
									error ? (
										<div className="d-flex justify-content-center">
											<Alert color="danger">
												{error}
											</Alert>
										</div>
									): (
										<div className="d-flex flex-column h-100">
											<div
												className="flex-grow-1"
												style={{ overflowY: 'auto' }}
												ref={(ref) => messageList.current = ref}
											>
												<div className="row w-100 m-auto">
													{
														messages.map((messageDetails) => {
															const isMyMessage = messageDetails.userId === user._id;

															return (
																<div
																	key={messageDetails._id}
																	className={clsx(
																		"px-3 py-3 px-md-4 py-md-3",
																		isMyMessage ? "col-11 offset-1 col-md-8 offset-md-4" : "col-11 col-md-8",
																		classes.messageContainer,
																		isMyMessage ? classes.myMessage : classes.otherMessage
																	)}
																>
																	<div className="f-15px user d-none d-md-block">
																		{`${messageDetails.firstName} ${messageDetails.lastName}`}
																	</div>
																	<div className={clsx("f-15px", classes.message)}>
																		<pre>
																			{messageDetails.message}
																		</pre>
																	</div>
																	<div className="small date">
																		{moment(messageDetails.createdAt).format('DD/MM/YYYY hh:mm A')}
																	</div>
																</div>
															)
														})
													}
												</div>
											</div>
											<div className={clsx("flex-shrink-0 pt-4", { "d-none": !user.chatService })}>
												<Formik
													initialValues={{
														message: ''
													}}
													validate={values => {
														const error = {};

														if (!values.message) {
															error.message = intl.formatMessage({
																id: "REQUEST.PROVIDER.CHAT.MESSAGE.VALIDATION.REQUIRED"
															});
														}
														
														return error;
													}}
													validateOnChange={false}
													validateOnBlur={true}
													onSubmit={async (values, { resetForm }) => {
														await dispatch(addProviderMessage(provider, values.message));
														resetForm();
													}}
												>
													{({
														values,
														errors,
														touched,
														handleChange,
														handleBlur,
														handleSubmit,
														setFieldValue,
													}) => {
														return (
															<form
																noValidate={true}
																autoComplete="off"
																onSubmit={handleSubmit}
															>
																<div className="row w-100 m-0 no-gutters">
																	<div className="col-12 d-flex align-items-center">
																		<div className={clsx("dark-custom-input form-group mb-0 w-100")}>
																			<FormattedMessage id="REQUEST.PROVIDER.CHAT.MESSAGE">
																				{
																					(text) => (
																						<OutlinedInput
																							placeholder={text}
																							type="text"
																							classes={{
																								input: "bg-white",
																								root: "py-0 pl-0",
																							}}
																							multiline
																							onKeyDown={(e) => (e.metaKey || e.ctrlKey) && e.key === 'Enter' && handleSubmit()}
																							className="w-100"
																							name={`message`}
																							onBlur={handleBlur}
																							onChange={handleChange}
																							value={values.message}
																							endAdornment={
																								<InputAdornment position="end">
																									<button className="border-0 bg-transparent">
																										<ArrowRightIcon />
																									</button>
																								</InputAdornment>
																							}
																						/>
																					)
																				}
																			</FormattedMessage>
																		</div>
																	</div>
																</div>
															</form>
														);
													}}
												</Formik>
											</div>
										</div>
									)
								}
							</React.Fragment>
						)
					}
				</div>
			</div>
		</Dialog>
	);
}

export default Chat;
