import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Row, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";
import { useStripe } from "@stripe/react-stripe-js";

import { loadFeatures } from "../../../store/modules/actions/features.actions";
import { getAddons, purchaseAddons } from "../../../crud/subscriptions.crud";
import { useSnackbar } from "notistack";
import Confirm from "../../../common/modals/confirm";
import { CircleCheckIcon } from "../../../common/icons";
import { globalGA } from "../../../../_metronic";

const useStyles = makeStyles((theme) => {
	return {
		field: {
			background: "white",
			color: theme.palette.dark.primary.main,
			borderRadius: 4,
			maxWidth: 40,
			border: `1px solid rgba(0, 0, 0, 0.4)`,
			textAlign: "center",
			outline: "none",

			"&:focus": {
				boxShadow: `0 0 0 1pt rgba(0, 0, 0, 0.4)`,
			},

			"-moz-appearance": "textfield",
			"&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
				"-webkit-appearance": "none",
				margin: 0,
			}
		},
		fieldButton: {
			background: "white",
			color: theme.palette.dark.primary.main,
			height: 18,
			width: 18,
			borderRadius: 9,
			border: `1px solid ${theme.palette.dark.primary.main}`,
		},
		totalRow: {
			background: "#EBEFFF",
			borderRadius: 4,
		}
	}
});

function ManageAddon() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const stripe = useStripe();
	const intl = useIntl();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	
	const [addonList, setAddonList] = useState(null);
	const [addons, setAddons] = useState({});
	const [isProcessing, setIsProcessing] = useState(false);
	const [confirmPurchase, setConfirmPurchase] = useState(false);

	useEffect(() => {
		async function loadData() {
			dispatch(loadFeatures());
			const addonResponse = await getAddons();
			const addonList = addonResponse.data.data;
			
			setAddonList(addonList);

			const initialAddonCount = addonList.reduce((p, addon) => {
				p[addon.id] = 0;
				return p;
			}, {});
			setAddons(initialAddonCount);
		}

		loadData();
	}, [
		dispatch
	]);

	const updateAddon = useCallback((addonKey, value) => {
		setAddons({
			...addons,
			[addonKey]: value
		});
	}, [
		addons,
	]);

	const items = useMemo(() => {
		if (!addonList) {
			return [];
		}

		return addonList
			.filter((addon) => {
				return addon.isActive;
			})
			.map((addon) => {
				return {
					title: addon.name,
					quota: addon.quantity,
					price: addon.price.AUD,
					counterKey: addon.id,
				};
			});
	}, [
		addonList
	]);

	const totalAmountToPay = useMemo(() => {
		const total = items.reduce((p, item) => {
			const amountForItem = item.price * addons[item.counterKey];
			return p + amountForItem;
		}, 0);

		return total;
	}, [
		items,
		addons,
	]);

	const priceText = useSelector((state) => {
		const priceFeature = state.features.list.find((feature) => feature.key === "PRICE");
		return priceFeature ? priceFeature.name : "";
	});

	const purchaseAddon = useCallback(async () => {
		try {
			const purchaseData = _.map(addons, (quantity, addonId) => {
				return {
					addonId,
					quantity,
				}
			});

			setIsProcessing(true);
			
			const {
				data: {
					sessionId,
				}
			} = await purchaseAddons(purchaseData);

			stripe
				.redirectToCheckout({
					sessionId
				})

			globalGA("clicked_purchase_addons", {
				eventAction: "click",
				eventCategory: "subscription"
			});
		}
		catch (e) {
			const message = intl.formatMessage({ id: "MANAGE_ADDON.ERROR.PURCHASE" });
			enqueueSnackbar(message, { variant: "error" });
			console.log(e);
		}
		finally {
			setIsProcessing(false);
		}
	}, [
		addons,
		stripe,
		enqueueSnackbar,
		intl,
	])

	const handleConfirmationAddonPurchaseClose = useCallback(async (wasSubmitted) => {
		if (wasSubmitted) {
			await purchaseAddon();
		}

		setConfirmPurchase(false);
	}, [
		purchaseAddon,
	]);

	if (!priceText || !addonList) {
		return (
			<div className="d-flex flex-grow-1 justify-content-center align-items-center pt-2 mt-2 pt-md-5 mt-md-5">
				<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
			</div>
		)
	}

	return (
		<div>
			<div className="mb-20px">
				<div className="f-20px text-dark">
					<FormattedMessage id="MANAGE_ADDON.TITLE" />
				</div>
			</div>

			<div className="bg-white rounded p-25px">
				<Row>
					{
						items.map((item, index, arr) => {
							return (
								<React.Fragment key={index}>
									<Col md={3} className="d-flex justify-content-between align-items-center">
										<div className="f-15px text-dark">{item.title}</div>
									</Col>
									<Col md={1} className="d-flex align-items-center">
										<div className="f-15px text-dark">{item.quota}</div>
									</Col>
									<Col md={3} className="d-flex align-items-center">
										<div className="f-15px text-dark">{`$${item.price} ${priceText}`}</div>
									</Col>
									<Col md={3} className="d-flex justify-content-center align-items-center">
										<div>
											<button
												className={clsx("d-flex justify-content-center align-items-center", classes.fieldButton)}
												disabled={addons[item.counterKey] <= 0}
												onClick={() => updateAddon(item.counterKey, addons[item.counterKey] - 1)}
											>
												-
											</button>
										</div>
										<div>
											<input
												className={clsx("mx-15px", classes.field)}
												type="number"
												value={addons[item.counterKey]}
												onChange={(e) => updateAddon(item.counterKey, parseInt(e.target.value))}
											/>
										</div>
										<div>
											<button
												className={clsx("d-flex justify-content-center align-items-center", classes.fieldButton)}
												onClick={() => updateAddon(item.counterKey, addons[item.counterKey] + 1)}
											>
												+
											</button>
										</div>
									</Col>
									<Col md={2} className="d-flex justify-content-end align-items-center pr-40px">
										<div className="f-15px text-dark">
											{`$${addons[item.counterKey]*item.price}`}
										</div>
									</Col>

									{
										index !== arr.length - 1 && (
											<Col sm={12}>
												<hr />
											</Col>
										)
									}
								</React.Fragment>
							);
						})
					}
				</Row>

				<Row className={clsx("mt-30px py-15px", classes.totalRow)}>
					<Col md={3} className="d-flex justify-content-between" />
					<Col md={4} />
					<Col md={3} className="d-flex justify-content-center align-items-center">
						<div className="f-15px text-dark">
							Total
						</div>
					</Col>
					<Col md={2} className="d-flex justify-content-end pr-40px align-items-center">
						<div className="f-15px text-dark">
							{`$${totalAmountToPay}`}
						</div>
					</Col>
				</Row>

				<div className="mt-30px d-flex justify-content-end">
					<button
						className={clsx(
							"btn btn-primary",
							{
								"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isProcessing,
							}
						)}
						onClick={() => setConfirmPurchase(true)}
						disabled={totalAmountToPay <= 0}
					>
						<FormattedMessage id="MANAGE_ADDON.SUBMIT" />
					</button>
				</div>
			</div>

			<Row className="mt-20px">
				<Col sm={12} md={8} className="f-15px text-dark fw-300">
					<FormattedMessage id="MANAGE_ADDON.FOOTER" />
				</Col>
			</Row>
			
			<Confirm
				open={confirmPurchase}
				icon={<CircleCheckIcon fill={theme.palette.success.main} />}
				variant="success"
				handleClose={handleConfirmationAddonPurchaseClose}
				title="MANAGE_ADDON.PURCHASE.CONFIRMATION.TITLE"
				submitButtonText="GENERAL.YES"
				cancelButtonText="GENERAL.NO"
			/>
		</div>
	);
}

export default ManageAddon;
