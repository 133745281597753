import React, { useCallback, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDropzone } from "react-dropzone";
import { Card, CardContent, Fab, makeStyles, ClickAwayListener, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import LightTooltip from '../../../../../common/LightTooltip';
import { CancelIcon, UploadIcon, PlusIcon, CancelCircleIcon } from "../../../../../common/icons";
import Input from "../../../../../common/Input";
import Constants from "../../../../../common/constants";
import Select from "../../../../../common/Select";
import FileCard from "./FileCard";
import CustomAutocomplete from "../../../../../common/autocomplete";
import { isAttachmentValid, getDummyFileRenameStrings, sanitizeFile } from "../../../../../../_metronic";
import SizeIndicator from "../../../../../common/size-indicator";
import { getSelectedTemplate } from "../../../../../store/modules/selectors/template.selector";
import { useElements } from "@stripe/react-stripe-js";
import { getBankStatementUrl } from "../../../../../store/modules/selectors/common.selector";

const useStylesForItemCard = makeStyles((theme) => {
	return {
		descriptionLabel: {
			fontSize: "12px",
			letterSpacing: "0.01em",
			textTransform: "uppercase",
		},
		documentName: {
			textDecoration: "underline"
		},
		files: {
			background: "rgba(229, 234, 249, 0.35)",
			borderRadius: 6,
		},
		fileListContainer: {
			minHeight: 160,
		},
		emptyFileListContainer: {
			background: "rgba(255, 255, 255, 0.47)",
			border: "1px solid white",
			borderRadius: 6,
		},
		filesList: {
			position: "absolute",
			overflowY: "scroll",
			minHeight: 140, // height of emptyFileListContainer - padding of 10px on top and bottom
			left: 0,
			right: 0,
			paddingRight: 10,

			"&::-webkit-scrollbar": {
				width: 5,
			},

			/* Track */
			"&::-webkit-scrollbar-track": {
				borderRadius: 5,
				background: "rgba(255, 255, 255, 0.3)"
			},

			/* Handle */
			"&::-webkit-scrollbar-thumb": {
				background: "#FFFFFF",
				borderRadius: 15,
			}
		},
		emptyContainerMessage: {
			color: "#2E384D"
		},
		addFileRenameButton: {
			backgroundColor: "white !important",
			height: "28px !important",
			width: "28px !important",
			minHeight: "28px !important",

			"&:hover": {
				backgroundColor: "white !important",
			}
		},
		removeFileRenameButton: {
			position: "absolute !important",
			top: -9,
			right: 2,
			height: "18px !important",
			width: "18px !important",
			minHeight: "18px !important",
			backgroundColor: `${theme.palette.extraColors.grey} !important`,
			borderRadius: 9,

			'& svg': {
				position: 'relative',
				top: -2,
				left: -1,
			}
		},
		autoComplete: {
			background: theme.palette.extraColors.background,
		},
		sizeContainer: {
			background: "rgba(229, 234, 249, 0.35)",
			borderRadius: "5px",
		}
	}
});

export default function ItemCard(props) {
	const {
		className,
		item,
		handleNameChange,
		handleTypeChange,
		handleDelete,
		handleAddFiles,
		handleDeleteFile,
		handleFilePreview,
		handleMessageChange,
		handleFileRenameAdd,
		handleFileRenameChange,
		handleFileRenameRemove,
	} = props;
	const classes = useStylesForItemCard();
	const { enqueueSnackbar } = useSnackbar();
	const intl = useIntl();

	const template = useSelector(getSelectedTemplate);
	const bankStatementUrl = useElements(getBankStatementUrl);
	
	const [ fileRenameTooltipStatus, setFileRenameTooltipStatus ] = useState(false);

	const onDrop = useCallback(async (acceptedFiles) => {
		const validFiles = acceptedFiles.filter((file) => isAttachmentValid(file));
		
		acceptedFiles
			.filter((file) => !validFiles.includes(file))
			.forEach((file) => {
				const msg = intl.formatMessage({
					id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
				}, file);
				enqueueSnackbar(msg, { variant: 'error' })
			});

		const promises = validFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		handleAddFiles(files);
	}, [
		enqueueSnackbar,
		handleAddFiles,
		intl,
	]);

	const getTotalAttachmentSize = useCallback(() => {
		const totalSize = item.attachments.reduce((p, attachment) => {
			const attachmentSizeInB = attachment._id ? attachment.size * 1024 : attachment.size;
			return p + attachmentSizeInB;
		}, 0);

		return totalSize;
	}, [
		item
	]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: false });

	if (!item) {
		return null;
	}

	const fileRenameHintStrings = [
		item.fileRename.length > 0
			? intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.HINT_TITLE" })
			: intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.EMPTY.HINT_TITLE" }),
		...getDummyFileRenameStrings(item.fileRename),
	]

	return (
		<Card
			elevation={0}
			className={clsx(
				"rounded-card document-card border",
				className,
				{ "border-primary": isDragActive }
			)}
		>
			<CardContent>
				<div className="d-flex">
					<div className="row flex-grow-1">
						<div className="col-md-6 col-12 mb-20px">
							<Input
								value={item.name}
								InputProps={{
									classes: {
										root: "blue-border"
									},
								}}
								labelClass="line-height-inherit"
								onChange={handleNameChange}
								className="m-0 w-100"
								variant="outlined"
								labelId="REQUEST.PROVIDER.ITEM.NAME"
								classes={{
									root: "MuiTextField-bg-white"
								}}
							/>
						</div>
						
						<div className="col-md-4 col-12 mb-20px dark-custom-input form-group">
							<div className="dark-input-label mb-10px d-flex align-items-center">
								<FormattedMessage id="REQUEST.PROVIDER.ITEM.TYPE" />
							</div>

							<Select
								className="blue-border"
								value={item.type || ''}
								classes={{	
									root: `d-flex align-items-center bg-white`,
								}}
								name="template"
								variant="outlined"
								onChange={(e) => handleTypeChange(e.target.value)}
								fullWidth
								placeholder={intl.formatMessage({ id: "GENERAL.SELECT" })}
							>
								<MenuItem
									value={Constants.DocumentType.REGULAR}
								>
									<FormattedMessage id="TEMPLATE.TYPE.REGULAR" />
								</MenuItem>
								<MenuItem
									value={Constants.DocumentType.READ_ONLY}
								>
									<FormattedMessage id="TEMPLATE.TYPE.READ_ONLY" />
								</MenuItem>
								{
									bankStatementUrl && (
										<MenuItem
											value={Constants.DocumentType.BANK_STATEMENTS}
										>
											<FormattedMessage id="TEMPLATE.TYPE.BANK_STATEMENTS" />
										</MenuItem>
									)
								}
							</Select>
						</div>

						<div className="col-md-6 col-12 description mb-20px">
							<Input
								value={item.description}
								classes={{
									root: "MuiTextField-bg-white condensed-textarea"
								}}
								InputProps={{
									classes: {
										root: "blue-border"
									},
								}}
								onChange={handleMessageChange}
								multiline
								labelId="REQUEST.PROVIDER.ITEM.DESCRIPTION"
								className="m-0 w-100"
								rows={item.type === Constants.DocumentType.BANK_STATEMENTS && item.refData && item.refData.bankStatementsUrl ? 9 : 12}
								variant="outlined"
								placeholder={intl.formatMessage({ id: "TEMPLATE.DESCRIPTION.PLACEHOLDER" })}
							/>

							{
								item.type === Constants.DocumentType.BANK_STATEMENTS && (
									<div className="mt-10px d-flex align-items-center">
										<div className="input-label mr-2">
											<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL" />
										</div>
										<div>
											<a
												href={item.refData ? item.refData.bankStatementsUrl : bankStatementUrl}
												target="_blank"
												rel="noopener noreferrer"
											>
												<FormattedMessage id="PROVIDER.DOCUMENT.BANK_STATEMENT.URL_TEXT" />
											</a>
										</div>
									</div>
								)
							}
						</div>

						<div className="col-md-6 col-12 mb-20px d-flex flex-column">
							<div className="dark-input-label mb-10px d-flex align-items-center">
								<FormattedMessage id="PROVIDER.ATTACHMENTS.TITLE" />

								<SizeIndicator
									className={clsx(classes.sizeContainer, "px-2 py-1 text-primary ml-10px")}
									sizeInBytes={getTotalAttachmentSize()}
									hideIfZero
								/>
							</div>

							<div className={clsx(classes.files, "p-3 d-flex flex-column flex-grow-1")}>
								<div
									className={clsx(
										"position-relative d-flex flex-column flex-grow-1",
										classes.fileListContainer,
										{
											"d-flex justify-content-center align-items-center": !item.attachments.length
										}
									)}
									{...getRootProps()}
								>
									<input {...getInputProps()} />
									{
										item.attachments.length > 0 ? (
											<div className={clsx(classes.filesList, "h-100")}>
												{
													item.attachments.map((file, index, arr) => (
														<div
															key={index}
															className={clsx({
																"mb-5px": index !== arr.length - 1
															})}
														>
															<FileCard
																onClick={(e) => {
																	e.preventDefault();
																	e.stopPropagation();

																	handleFilePreview(index);
																}}
																templateId={template && template._id}
																documentId={item._id}
																file={file}
																onDelete={() => handleDeleteFile(index)}
															/>
														</div>
													))
												}
											</div>
										) : (
												<div className={`w-100 h-100 d-flex align-items-center justify-content-center flex-column ${classes.fileListContainer} ${classes.emptyFileListContainer}`}>
												<div className="mb-15px text-center">
													<UploadIcon />
												</div>
												<div className={clsx(classes.emptyContainerMessage, "text-center")}>
													<FormattedMessage id="TEMPLATE.DOCUMENT.EMPTY_DOCUMENT.MESSAGE" />
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>

					<div className="cursor-pointer ml-2 d-none d-md-block" onClick={handleDelete}>
						<span className="input-label">
							<CancelIcon />
						</span>
					</div>
					<div
						className="cursor-pointer ml-2 d-block d-md-none position-absolute"
						style={{ right: 20 }}
						onClick={handleDelete}	
					>
						<span className="input-label">
							<CancelIcon />
						</span>
					</div>
				</div>

				<div className="mt-20px">
					<div className="dark-input-label mb-10px">
						<FormattedMessage id="TEMPLATE.FILE_RENAME" />

						<ClickAwayListener onClickAway={() => setFileRenameTooltipStatus(false)}>
							<LightTooltip
								className="ml-2"
								open={fileRenameTooltipStatus}
								title={
									<div>
										{ fileRenameHintStrings.map((str, index) => <div key={index}>{str}</div>) }
									</div>
								}
								placement="right"
							>
								<span onClick={() => setFileRenameTooltipStatus(true)}>
									<i className="fas fa-info-circle cursor-pointer" />
								</span>
							</LightTooltip>
						</ClickAwayListener>
					</div>

					<div className="row align-items-center">
						{
							item.fileRename.map((item, index) => {
								return (
									<div key={index} className="col-md-3 col-sm-6 col-12 mb-15px">
										<CustomAutocomplete
											value={item}
											options={Constants.FILE_RENAME_OPTIONS}
											freeSolo
											disableClearable
											classes={{
												inputRoot: `p-0 ${classes.autoComplete}`,
												input: "px-10px py-15px bg-transparent"
											}}
											onChange={(value) => handleFileRenameChange(index, value)}
											inputProps={{
												fullWidth: true,
												type: "text",
												variant: "outlined",
												onBlur: (e) => {
													if (!item && e.target.value) {
														handleFileRenameChange(index, e.target.value)
													}
												}
											}}
										/>

										<div
											className={`shadow cursor-pointer ${classes.removeFileRenameButton}`}
											onClick={() => handleFileRenameRemove(index)}
										>
											<CancelCircleIcon
												size={20}
												fill="white"
											/>
										</div>
									</div>
								);
							})
						}

						{
							item.fileRename.length < Constants.FILE_RENAME_LIMIT && (
								<div className="col-3 mb-15px">
									<Fab
										classes={{
											root: classes.addFileRenameButton
										}}
										size="small"
										color="primary"
										onClick={handleFileRenameAdd}
									>
										<PlusIcon />
									</Fab>
								</div>
							)
						}
					</div>
				</div>
			</CardContent>
		</Card>
	)
}
