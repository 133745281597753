import { makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from 'linkifyjs/html';

import FileCard from './FileCard';

const useStyles = makeStyles((theme) => {
	return {
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto",

			'&::-webkit-scrollbar': {
				marginLeft: 5,
				width: 8,
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				borderRadius: 5,
				background: 'transparent'
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: 'rgb(235, 239, 255)',
				borderRadius: 15,
			}
		},
	}
});

const SharedFiles = forwardRef((props, _ref) => {
	const { data, onPreview } = props;
	const classes = useStyles();

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className={classes.fileListContainer}>
				<div className={clsx(classes.fileList, "my-10px d-md-flex d-block flex-md-column flex-grow-1 px-0")}>
					<div className="input-label mb-10px d-flex align-items-center">
						<FormattedMessage id="PROVIDER.ATTACHMENTS.TITLE" />
					</div>

					<div
						className={clsx(
							"position-relative flex-grow-1 px-0",
							{
								"d-flex justify-content-center align-items-center": !data.attachments.length
							}
						)}
					>
						<div>
							{
								data.attachments.map((file, index, arr) => (
									<div
										key={index}
										className={
											clsx(
												{
													"mb-5px": index !== arr.length - 1,
													"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
												}
											)
										}
									>
										<FileCard
											canDownload={file._id}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();

												onPreview(file);
											}}
											file={file}
											isAttachment
										/>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})

export default SharedFiles;
