import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import Profile from './Profile';

export default function ProfilePage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/profile" exact component={Profile} />
			</Switch>
		</Suspense>
	);
}
