export default [
	{
		_id: 'request-0',
		providers: [{
			firstName: 'Mill',
			lastName: 'Gates',
		}],
		status: 'PENDING',
		createdAt: new Date(),
	},
	{
		_id: 'request-1',
		providers: [
			{
				firstName: 'Ray',
				lastName: 'Dalie'
			},
			{
				firstName: 'Minoo',
				lastName: 'Javadi',
			},
		],
		status: 'IN_PROGRESS',
		createdAt: new Date(),
	},
	{
		_id: 'request-2',
		status: 'IN_PROGRESS',
		providers: [
			{
				_id: 'provider-0',
				firstName: 'Ray',
				lastName: 'Dalie',
				email: 'raydalie@gmail.com',
				phone: '1234567890',
				items: [
					{
						_id: 'template-item-0',
						name: 'Driver License',
						message: "Please ensure to provide photos of both the front and back of your document"
					},
					{
						_id: 'template-item-1',
						name: 'Passport',
						message: "Please ensure to provide photos of both the front and back of your document"
					},
					{
						_id: 'template-item-2',
						name: 'Medicare Card',
						message: "Please ensure to provide photos of both the front and back of your document"
					},
					{
						_id: 'template-item-3',
						name: 'Bank Statement',
						message: "Please ensure to provide photos of both the front and back of your document"
					}
				],
			}
		],
		createdAt: new Date(),
	},
	{
		_id: 'request-3',
		status: 'CANCELLED',
		providers: [{
			firstName: 'Stan',
			lastName: 'Maloepa',
		}],
		cancelReason: 'Reason for cancellation',
		createdAt: new Date(),
	},
	{
		_id: 'request-3',
		status: 'IN_PROGRESS',
		providers: [{
			firstName: 'Stan',
			lastName: 'Maloepa',
		}],
		archived: true
	},
	{
		_id: 'request-3',
		status: 'IN_PROGRESS',
		providers: [{
			firstName: 'Stan',
			lastName: 'Maloepa',
		}],
		deletedAt: new Date(),
	}
];
