import { actionTypes } from "./actions/features.actions";

const initialState = {
	list: [],
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FEATURES_LOADED: {
			return {
				...state,
				list: action.payload,
			}
		}
		default:
			return state;
	}
}
