import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { CloudQueueOutlined, CloudDone } from "@material-ui/icons";

const useStyles = makeStyles(() => {
	return {
		savingSpinner: {
			"&:before": {
				height: 36,
				width: 36,
			},
			position: "absolute",
			top: 4,
			left: -8,
		}
	}
})

function LoadingIndicator ({ loading, className }) {
	const classes = useStyles();

	return (
		<div className={clsx("text-success", className)}>
			<div className="position-relative">
				{
					loading && (
						<div className={clsx(classes.savingSpinner, "kt-spinner kt-spinner--v2 kt-spinner--success")} />
					)
				}
				{
					loading ? (
						<CloudQueueOutlined />
					) : (
						<CloudDone />
					)
				}
			</div>
		</div>
	)
}

export default LoadingIndicator;
