import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { Icon, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { AccountBalance } from "@material-ui/icons";

import { loadIntegrations } from "../../../store/modules/actions/integrations.actions";
import Constants from "../../../common/constants";
import useUserRestriction from "../../../common/hooks/user-restriction";

const useStyles = makeStyles((theme) => {
	const isDownXs = theme.breakpoints.down("xs");

	return {
		integrationCard: {
			width: window.screen.availWidth * 0.13,
			[isDownXs]: {
				width: "100%"
			},
			height: window.screen.availHeight * 0.13,
		},
		activeIcon: {
			color: theme.palette.extraColors.lightText,
			position: "absolute",
			top: 10,
			right: 10,
		},
	}
})

function IntegrationCard ({ title, config, icon, route }) {
	const history = useHistory();
	const classes = useStyles();
	const intl = useIntl();

	return (
		<div className="m-40px position-relative">
			<Tooltip title={intl.formatMessage({ id: config?.isActive ? "INTEGRATIONS.ENABLED_HINT" : "INTEGRATIONS.DISABLED_HINT" })}>
				<div className={clsx(classes.activeIcon, { "text-success": config?.isActive })}>
					<Icon>tungsten</Icon>
				</div>
			</Tooltip>

			<div
				className={clsx("cursor-pointer d-flex justify-content-center align-items-center rounded bg-white", classes.integrationCard)}
				onClick={() => history.push(route)}
			>
				{icon}
			</div>

			<div className="mt-15px f-15px text-center text-dark">
				<FormattedMessage id={title} />
			</div>

			<div className="d-flex justify-content-center mt-15px">
				<button className="btn btn-outline-dark settings-button" onClick={() => history.push(route)}>
					<FormattedMessage id="INTEGRATIONS.INTEGRATION_SETTING" />
				</button>
			</div>
		</div>
	)
}

function Integrations() {
	const dispatch = useDispatch();
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const integrations = useSelector(({ integrations }) => {
		const {
			list,
		} = integrations;

		return list;
	});
	
	useEffect(() => {
		dispatch(loadIntegrations());
	}, [
		dispatch,
	]);

	if (!restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	if (isInReadOnlyMode || !restrictions.features.INTEGRATIONS) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<Alert color="danger">
					<FormattedHTMLMessage id="GENERAL.UPGRADE_MESSAGE_WITH_LINK" />
				</Alert>
			</div>
		)
	}

	return (
		<div className="d-flex flex-column flex-sm-row">
			{
				integrations
				.filter((integration) => integration.isActive)
				.map((integration) => {
					if (integration.slug === Constants.IntegrationsType.BANK_STATEMENTS) {
						return (
							<IntegrationCard
								config={integration.config}
								key={integration._id}
								icon={<AccountBalance style={{ fontSize: 48 }} />}
								title="INTEGRATIONS.BANK_STATEMENT.FIELD.BANK_STATEMENT_SERVICE"
								route="/integrations/bank-statements"
							/>
						)
					}

					if (integration.slug === Constants.IntegrationsType.ZAPIER) {
						return (
							<IntegrationCard
								key={integration._id}
								config={integration.config}
								icon={<img src="/media/logos/zapier_logo.png" alt="zapier" height={20} width={75} />}
								title="INTEGRATIONS.ZAPIER.FIELD.ZAPIER_SERVICE"
								route="/integrations/zapier"
							/>
						)
					}

					return null;
				})
			}
		</div>
	);
}

export default Integrations;