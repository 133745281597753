import React from "react";
import Title from "./Title";
import PricingTable from "./PricingTable";

export default function Pricing () {
	return (
		<div>
			<div className="mb-40px">
				<Title />
			</div>

			<div>
				<PricingTable />
			</div>
		</div>
	)
}