import  React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import Value from './Value';

const useStyles = makeStyles((theme) => {
	return {
		tableContainer: {
			width: "inherit",
		},
		table: {
			borderCollapse: 'separate',
			borderSpacing: 0,
		},
		evenRowCell: {
			background: "#FFFFFF",
		},
		oddRowCell: {
			background: "#F7F9FF"
		},
		spacerCell: {
			minWidth: 5,
			maxWidth: 5,
			width: 5,
		},
		lastSpacerCell: {
			minWidth: 15,
			maxWidth: 15,
			width: 15,
		},
		borderTop: {
			borderTop: '1px solid lightgrey',
		},
		borderRight: {
			borderRight: '1px solid lightgrey',
		},
		borderBottom: {
			borderBottom: '1px solid lightgrey',
		},
		borderLeft: {
			borderLeft: '1px solid lightgrey',
		},
		borderHorizontal: {
			borderLeft: '1px solid lightgrey',
			borderRight: '1px solid lightgrey',
		},
		roundedTopLeft: {
			borderRadius: "8px 0 0 0",
		},
		roundedTopRight: {
			borderRadius: "0 8px 0 0",
		},
		roundedBottomRight: {
			borderRadius: "0 0 8px 0",
		},
		roundedBottomLeft: {
			borderRadius: "0 0 0 8px",
		},
		cellAlignTop: {
			verticalAlign: "top",
		},
	}
});

const ENTERPRISE_PLAN = "ENTERPRISE";

function PlanColumnTitle({ title, subtitle, onSelect, isEnterprisePlan }) {
	return (
		<div
			className="rounded-top px-20px pt-30px pb-20px d-flex flex-column align-items-center"
			style={{ minWidth: 150 }}
		>
			<div className="f-25px mb-20px text-dark">
				{title}
			</div>
			<div className="mb-20px text-center text-dark">
				{subtitle}
			</div>
			<div>
				<button
					className={clsx(
						"btn btn-primary",
						{
							// remove button because there's description in enterprise plan that we are aligning
							// with buttons of rest of the plans
							"d-none": isEnterprisePlan
						}
					)}
					onClick={!isEnterprisePlan ? onSelect : null}
				>
					<FormattedMessage id="GENERAL.SELECT" />
				</button>
			</div>
		</div>
	)
}

const PLAN_TYPE = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY"
};

function ComparisonMD ({ plans, className, features, onPlanSelect, isBillingAnnually }) {
	const classes = useStyles();
	const planSet = plans.filter(({ type, slug }) => {
		const isPlanEnterprise = slug === ENTERPRISE_PLAN;
		const isPlanYearly = type === PLAN_TYPE.YEARLY;
		const isPlanMonthly = type === PLAN_TYPE.MONTHLY;

		return isPlanEnterprise || (isBillingAnnually ? isPlanYearly : isPlanMonthly);
	});

	return (
		<div className={clsx("table-responsive", className, classes.tableContainer)}>
			<table className={clsx("mb-50px", classes.table)}>
				<tbody>
					<tr>
						<td></td>
						{
							planSet.map((plan, index) => {
								return (
									<React.Fragment key={index}>
										<td className={clsx(classes.borderTop, classes.cellAlignTop, classes.borderHorizontal, "rounded-top bg-white")}>
											<PlanColumnTitle
												title={plan.name}
												subtitle={plan.description}
												isEnterprisePlan={plan.isEnterprisePlan}
												onSelect={() => onPlanSelect(plan.id)}
											/>
										</td>
										<td>
											<div className={classes.spacerCell} />
										</td>
									</React.Fragment>
								)
							})
						}
					</tr>
					<tr>
						<td
							style={{ minWidth: 300 }}
							className={clsx(classes.evenRowCell, classes.roundedTopLeft, classes.borderLeft, classes.borderTop, classes.roundedTopLeft, "bg-white p-20px")}
						>
							<span className="f-16px font-weight-bold">
								<FormattedMessage id="PRICING.FEATURE.TITLE" />
							</span>
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderHorizontal, "p-20px")}>
							<div />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderTop)}>
							<div className={classes.spacerCell} />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderHorizontal, "p-20px")}>
							<div />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderTop)}>
							<div className={classes.spacerCell} />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderHorizontal, "p-20px")}>
							<div />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderTop)}>
							<div className={classes.spacerCell} />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderHorizontal, "p-20px")}>
							<div />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderTop)}>
							<div className={classes.spacerCell} />
						</td>
						<td className={clsx(classes.evenRowCell, classes.borderHorizontal, "p-20px")}>
							<div />
						</td>
						<td className={clsx(classes.evenRowCell, classes.roundedTopRight, classes.borderTop, classes.borderRight)}>
							<div className={classes.lastSpacerCell} />
						</td>
					</tr>

					{
						features.map((feature, index, arr) => {
							const rowNumber = index + 1;
							const isLastFeature = index === arr.length - 1;

							return (
								<tr key={index}>
									<td
										className={clsx(
											"p-20px",
											classes.borderLeft,
											{
												[classes.oddRowCell]: rowNumber % 2 !== 0,
												[classes.evenRowCell]: rowNumber % 2 === 0,
												[`${classes.roundedBottomLeft} ${classes.borderBottom}`]: isLastFeature,
											}
										)}
									>
										<span className="f-16px">
											{feature.name}
										</span>
									</td>

									{
										planSet.map((plan, index, arr) => {
											const isLastCell = index === arr.length - 1;

											return (
												<React.Fragment key={index}>
													<td
														className={clsx(
															"f-16px p-20px text-center",
															classes.borderHorizontal,
															{
																[classes.oddRowCell]: rowNumber % 2 !== 0,
																[classes.evenRowCell]: rowNumber % 2 === 0,
																"font-weight-bold": feature.key === "PRICE"
															}
														)}
													>
														<Value
															plan={plan}
															feature={feature}
														/>
													</td>
													<td
														className={clsx({
															[classes.borderRight]: isLastCell,
															[classes.oddRowCell]: rowNumber % 2 !== 0,
															[classes.evenRowCell]: rowNumber % 2 === 0,
															[classes.borderBottom]: isLastFeature,
															[classes.roundedBottomRight]: isLastFeature && isLastCell,
														})}
													>
														<div
															className={
																clsx({
																	[classes.spacerCell]: !isLastCell,
																	[classes.lastSpacerCell]: isLastCell,
																})
															}
														/>
													</td>
												</React.Fragment>
											)
										})
									}
								</tr>
							)
						})
					}
		
					<tr>
						<td></td>

						{
							planSet.map((plan, index) => {
								return (
									<React.Fragment key={index}>
										<td className={clsx(classes.evenRowCell, classes.borderHorizontal, classes.borderBottom, "p-20px text-center rounded-bottom")}>
											<button
												className={clsx(
													"btn btn-primary",
													{
														// to keep the space occupied by button and hence keeping elements aligned
														"pointer-events-none opacity-0": plan.isEnterprisePlan
													}
												)}
												onClick={() => onPlanSelect(plan.id)}
											>
												<FormattedMessage id="GENERAL.SELECT" />
											</button>
										</td>
										<td>
											<div className={classes.spacerCell} />
										</td>
									</React.Fragment>
								)
							})
						}
					</tr>
				</tbody>
			</table>
		</div>
	)
}

export default ComparisonMD;