import React, { useCallback } from "react";
import { withStyles, Tab } from "@material-ui/core";
import clsx from "clsx";
import { addAlpha } from "../../../../../_metronic";

const StyledTab = withStyles((theme) => ({
	container: {
		borderRadius: 30,
		height: 40,
		background: ({ isSelected }) => isSelected ? "white" : "transparent",
		border: ({ isSelected }) => `1px solid ${isSelected ? theme.palette.primary.main : addAlpha(theme.palette.extraColors.black, 0.10)}`,
		'&.error': {
			border: `1px solid ${theme.palette.danger.main}`,
		},
		'&.with-icon .MuiTab-wrapper': {
			paddingRight: '25px !important'
		}
	},
	root: {
		textTransform: "inherit",
	},
	textColorPrimary: {
		color: ({ isSelected, hasError }) => {
			let color = theme.palette.extraColors.black;

			if (isSelected) { 
				color = theme.palette.primary.main;
			}

			if (hasError) {
				color = theme.palette.danger.main;
			}

			return `${color} !important`;
		},
	},
	actionIconContainer: {
		position: 'absolute',
		right: 10,
	},
	selected: {},
}))(({ hasError, actionIcon, onActionClick, ...props }) => {
	const {
		classes: {
			container,
			actionIconContainer,
			...otherClasses
		},
	} = props;

	const handleActionClick = useCallback((e) => {
		if (typeof onActionClick === "function") {
			onActionClick(e);
		}
	}, [onActionClick]);

	return (
		<div
			className={clsx(
				"d-flex align-items-center position-relative text-16px",
				container,
				{
					"error": hasError,
					"with-icon": !!actionIcon,
				}
			)}
		>
			<Tab
				disableRipple
				{...props}
				classes={otherClasses}
			/>

			{
				!!actionIcon && (
					<div
						className={clsx(actionIconContainer, { "cursor-pointer": !!onActionClick })}
						onClick={handleActionClick}
					>
						{actionIcon}
					</div>
				)
			}
		</div>
	)
});

export default StyledTab;