import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../../_metronic";

import CreateNew from "./new";
import CreateLegacy from "./legacy";

export default function CreateRequests() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/requests/create/new" exact component={CreateNew} />
				<Route path="/requests/create/legacy" exact component={CreateLegacy} />
			</Switch>
		</Suspense>
	);
}
