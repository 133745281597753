import { makeStyles, useTheme } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { CUSTOM_OPTION_ID } from "./index";
import { validateCheckbox } from "../../ElementPicker";
import ErrorMessage from "../ErrorMessage";
import Option, { CustomOption } from "./option";
import useDebounce from "../../../useDebounce";
import AutoExpandInput from "../../../auto-expand-input";

const useStyles = makeStyles((theme) => {
  return {
    checkboxes: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: theme.elementSizes.checkboxes.maxWidth,
      },
    },
  };
});

const Checkbox = forwardRef((props, _ref) => {
  const {
    id,
    data,
    response,
    onChange,
    onChangeResponse,
    isActive,
  } = props;
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  const [title, setTitle] = useState(data.title);
  const debouncedTitle = useDebounce(title, 750);

  const [subtitle, setSubtitle] = useState(data.subtitle);
  const debouncedSubtitle = useDebounce(subtitle, 750);

  const [error, setError] = useState({});

  useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

  useEffect(() => {
    const newData = {
      ...data,
      title: debouncedTitle,
      subtitle: debouncedSubtitle,
    };
    const error = validateCheckbox(newData);

    onChange(newData);
    setError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle, debouncedSubtitle]);

	const updateOptions = useCallback((newData) => {
		onChange(newData);
	}, [onChange]);

	const handleOptionDrop = useCallback((result) => {
    const newOptions = Array.from(data.options);
    const [removed] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, removed);

    const newData = {
      ...data,
      options: newOptions,
    };

    updateOptions(newData);
  }, [data, updateOptions]);
	
  const hasCustomOption = useMemo(() => {
    return data.options.some(({ id }) => id === CUSTOM_OPTION_ID);
  }, [data]);

  const addOption = useCallback(
    (index, isCustomOption) => {
      const newOption = {
        title: "",
        id: isCustomOption ? CUSTOM_OPTION_ID : data.options.length.toString(),
      };
      const newOptions = data.options.slice();

      newOptions.splice(index + 1, 0, newOption);

      const newData = {
        ...data,
        options: newOptions,
      };

			updateOptions(newData);

      const error = validateCheckbox(newData);
      setError(error);

      if (!isCustomOption) {
        setTimeout(() => {
          const elementList = document.querySelector(`#${id}-option-list`)
          const optionElement = elementList.children[index+1]
          const input = optionElement.querySelector('textarea[placeholder]')
          
          if (input) {
            input.focus();
          }
        }, 1000);
      }
    },
    [data, id, updateOptions]
  );

  const removeOption = useCallback(
    (id) => {
      const newOptions = data.options.filter((option) => option.id !== id);

      let newValues = [];
      if (response.value) {
        newValues = response.value.filter((optionId) => optionId !== id);
      }

      const newData = {
        ...data,
        options: newOptions,
      };
      const newResponse = {
        ...response,
        value: newValues,
      };

      if (id === CUSTOM_OPTION_ID) {
        newResponse.custom = undefined;
      }

			onChangeResponse(newResponse);
			updateOptions(newData);
      
      const error = validateCheckbox(newData);
      setError(error);
    },
    [data, onChangeResponse, response, updateOptions]
  );

  const handleOptionChange = useCallback(
    (e, index) => {
      const newOptionTitle = e.target.value;
      const newOptions = data.options.map((option, optionIndex) => {
        if (optionIndex === index) {
          return {
            ...option,
            title: newOptionTitle,
          };
        }

        return option;
      });
      const newData = {
        ...data,
        options: newOptions,
      };

      updateOptions(newData);

      const error = validateCheckbox(newData);
      setError(error);
    },
    [data, updateOptions]
  );

  const handleKeyPress = useCallback(
    (e, index) => {
      if (e.key === "Enter") {
        addOption(index);
        e.preventDefault();
      }
    },
    [addOption]
  );

  const toggleResponseOption = useCallback((option) => {
    const isAlreadySelected = response.value.includes(option.id);
    let newResponseValues = [];

    if (isAlreadySelected) {
      newResponseValues = response.value.filter((id) => id !== option.id);
    }
    else {
      newResponseValues = [
        ...response.value,
        option.id,
      ];
    }

		onChangeResponse({
			...response,
			value: newResponseValues,
		});
	}, [onChangeResponse, response]);

	const handleCustomOptionTitleChange = useCallback((customText) => {
		onChangeResponse({
			...response,
			custom: customText,
		});
	}, [onChangeResponse, response]);

  return (
    <div>
      <div>
        <AutoExpandInput
          className="f-18px text-dark font-weight-medium mb-0"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={intl.formatMessage({
            id: "ELEMENT_LIST.ITEM.CHECKBOXES.TITLE.PLACEHOLDER",
          })}
        />
      </div>

      {error.title && <ErrorMessage message={error.title} />}

      <div>
        <AutoExpandInput
          className="f-14px text-dark font-weight-regular mb-0 w-100 pb-0 break-word"
          style={{ color: theme.palette.extraColors.lightText }}
          onChange={(e) => setSubtitle(e.target.value)}
          value={subtitle}
          placeholder={intl.formatMessage({
            id: "ELEMENT_LIST.ITEM.SHORT_TEXT.SUBTITLE.PLACEHOLDER",
          })}
          allowHTML
        />
      </div>

      {error.optionsMain && <ErrorMessage message={error.optionsMain} />}

      <div className={classes.checkboxes}>
        <DragDropContext onDragEnd={handleOptionDrop}>
          <Droppable droppableId={`${id}-option-list`}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  data.options
                  .filter((option) => option.id !== CUSTOM_OPTION_ID)
                  .map((option, index) => (
                    <Draggable
                      key={option.id}
                      draggableId={option.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          id={`${id}-option-list-option-${index}`}
                          ref={provided.innerRef}
                          tabIndex={-1}
                          {...provided.draggableProps}
                        >
                          <Option
                            index={index}
                            dragHandleProps={provided.dragHandleProps}
                            data={option}
														onSelect={() => toggleResponseOption(option)}
                            onChange={(e) => handleOptionChange(e, index)}
                            onKeyPress={(e) => handleKeyPress(e, index)}
                            onDelete={() => removeOption(option.id)}
                            isElementActive={isActive}
                            error={error?.options?.[index]}
                            isSelected={
                              response.value ? response.value.includes(option.id) : false
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {hasCustomOption && (
          <CustomOption
            data={{
              title: response.custom || '',
            }}
            onChange={(e) => handleCustomOptionTitleChange(e.target.value)}
						onSelect={() => toggleResponseOption({ id: CUSTOM_OPTION_ID })}
            isElementActive={isActive}
            onDelete={() => removeOption(CUSTOM_OPTION_ID)}
            isSelected={
              response.value ? response.value.includes(CUSTOM_OPTION_ID) : false
            }
          />
        )}
      </div>

      <div className="d-flex align-items-center f-16px">
        <div
          className="text-dark text-decoration-underline cursor-pointer"
          onClick={() => addOption(data.options.length - 1)}
        >
          <FormattedMessage id="ELEMENT_LIST.ITEM.CHECKBOXES.ADD_OPTION.TITLE" />
        </div>

        {!hasCustomOption && (
          <>
            <div className="text-dark mx-2">
              <FormattedMessage id="GENERAL.OR" />
            </div>
            <div
              className="text-primary cursor-pointer text-decoration-underline"
              onClick={() => addOption(data.options.length - 1, true)}
            >
              <FormattedMessage id="ELEMENT_LIST.ITEM.CHECKBOXES.ADD_OTHER.TITLE" />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default Checkbox;
