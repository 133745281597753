import React from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	Alert,
} from 'reactstrap';

function Step3(props) {
	const {
		nextStep,
	} = props;
	
	return (
		<div>
			<div className='d-flex justify-content-center'>
				<Alert color="success" className="mb-0 text-center">
					<FormattedMessage id="PROVIDER.RESET_PASSWORD.SUCCESS.MESSAGE" />
				</Alert>
			</div>

			<div className="pt-20 d-flex justify-content-center">
				<button
					type="submit"
					onClick={nextStep}
					className={`btn btn-primary btn-elevate kt-login__btn-primary py-3`}
				>
					<FormattedMessage id="PROVIDER.RESET_PASSWORD.SUCCESS.SUBMIT" />
				</button>
			</div>
		</div>
	)
}

export default Step3;