import clsx from "clsx";
import React from "react";
import {
	FormattedMessage,
} from "react-intl";
import { Col, Row } from "reactstrap";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	info: {
		color: "#5f6c72",
	}
}));

function Limit ({ used, allowed, suffix = "" }) {
	const isLimitReached = used === allowed;
	const classes = useStyles();

	return (
		<div
			className={
				clsx(
					"d-flex align-items-center", 
					{
						"text-dark": !isLimitReached,
						"text-danger": isLimitReached
					}
				)
			}
		>
			{
				isLimitReached && (
					<div className={clsx(classes.info, "mr-1")}>
						<Info />
					</div>
				)
			}
			<div>
				{`${used}/${allowed}${suffix}`}
			</div>
		</div>
	)
}

function AccountUsage({ billingData }) {
	const limits = {
		users: billingData.ACCOUNT_USAGE.numberOfUsers,
		requests: billingData.ACCOUNT_USAGE.requestsPerMonth,
		storage: billingData.ACCOUNT_USAGE.storageIncluded,
		smsNotification: billingData.ACCOUNT_USAGE.smsNotification,
	};

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-20px text-dark">
				<FormattedMessage id="BILLING.ACCOUNT_USAGE.TITLE" />
			</div>
			
			<Row className="mb-10px">
				<Col sm={6} xs={12} className="pr-sm-5">
					<div className="d-flex justify-content-between align-items-center">
						<div className="f-15px text-dark">
							<FormattedMessage id="BILLING.ACCOUNT_USAGE.USERS" />
						</div>
						<Limit
							used={limits.users.used}
							allowed={limits.users.allowed}
						/>
					</div>
					<hr className="my-20px" />
					<div className="d-flex justify-content-between align-items-center">
						<div className="f-15px text-dark">
							<FormattedMessage id="BILLING.ACCOUNT_USAGE.STORAGE" />
						</div>
						<Limit
							used={limits.storage.used}
							allowed={limits.storage.allowed}
							suffix="GB"
						/>
					</div>
				</Col>

				<Col sm={6} xs={12} className="pl-sm-5">
					<hr className="my-20px d-block d-sm-none" />
					<div className="d-flex justify-content-between align-items-center">
						<div className="f-15px text-dark">
							<FormattedMessage id="BILLING.ACCOUNT_USAGE.REQUESTS" />
						</div>
						<Limit
							used={limits.requests.used}
							allowed={limits.requests.allowed}
						/>
					</div>
					<hr className="my-20px" />
					<div className="d-flex justify-content-between align-items-center">
						<div className="f-15px text-dark">
							<FormattedMessage id="BILLING.ACCOUNT_USAGE.SMS_NOTIFICATIONS" />
						</div>
						<Limit
							used={limits.smsNotification.used}
							allowed={limits.smsNotification.allowed}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default AccountUsage;
