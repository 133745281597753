import React from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.extraColors.darkBlueBorder,
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}));

function StyledCheckbox({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Checkbox
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
}

export default StyledCheckbox;