import React, {
	useState,
} from "react";
import {
	FormattedMessage,
	injectIntl,
} from "react-intl";
import {
	Dialog,
	DialogTitle,
} from '@material-ui/core';
import clsx from "clsx";
import { withSnackbar } from "notistack";
import { resendRequest } from "../../../../crud/requests.crud";
import { copyTextToClipboard } from "../../../../../_metronic";

function ResendRequest(props) {
	const {
		open,
		request,
		handleClose,
		intl,
		enqueueSnackbar
	} = props;

	const [resendStatus, setResendStatus] = useState({});

	const resendRequestToProvider = async (provider) => {
		try {
			setResendStatus({
				...resendStatus,
				[provider._id]: true
			});

			await resendRequest(request._id, [provider._id]);

			const msg = intl.formatMessage({ id: "REQUEST.RESEND.NOTIFICATION_SUCCESS" }, provider);
			enqueueSnackbar(msg, { variant: 'success' });

			setResendStatus({
				...resendStatus,
				[provider._id]: false
			});
		}
		catch (e) {
			console.log(e);

			setResendStatus({
				...resendStatus,
				[provider._id]: false
			});

			const msg = intl.formatMessage({ id: "REQUEST.RESEND.NOTIFICATION_ERROR" }, provider);
			enqueueSnackbar(msg, { variant: 'error' });
		}
	}

	const copyLink = (provider) => {
		const link = `${window.location.origin}/provider/${request._id}/${provider._id}`;
		
		copyTextToClipboard(link)
			.then(() => {
				const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_SUCCESS" });
				enqueueSnackbar(msg, { variant: 'success' });
			})
			.catch((err) => {
				const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_ERROR" });
				enqueueSnackbar(msg, { variant: 'error' });
				console.log(err);
			})
	}

	console.log(resendStatus);

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			fullWidth
			maxWidth="sm"
			classes={{
				paper: "rounded-card"
			}}
		>
			<DialogTitle className='d-none'>
				{''}
			</DialogTitle>

			<div className="p-20">
				<div className="row f-15 label-4">
					<div className="col-10 offset-1">
						<h4 className="mb-0 text-center">
							<FormattedMessage id="REQUEST.RESEND_REQUEST" />
						</h4>
					</div>
					<div className="col-1">
						<div className="float-right cursor-pointer" onClick={() => handleClose()}>
							<span className="fas fa-times"></span>
						</div>
					</div>
				</div>

				<div className="m-20 border rounded">
					{
						request.documentProviders.map((provider, index, arr) => {
							return (
								<React.Fragment key={index}>
									<div className="file-item px-40 my-20 d-flex align-items-center justify-content-between flex-column flex-sm-row">
										<div className="d-flex align-items-center flex-column flex-sm-row mb-3 mb-sm-0">
											<div className="text-center text-sm-left">
												<h5 className="m-0">
													{`${provider.firstName} ${provider.lastName}`}
												</h5>
											</div>
										</div>
										<div className="d-flex pl-0 pl-sm-4 justify-content-between align-items-center cursor-pointer">
											<div className="mr-3">
												<button
													className={`btn btn-primary px-5 ${clsx({
														"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": resendStatus[provider._id]
													})}`}
													onClick={() => resendRequestToProvider(provider)}
												>
													<FormattedMessage id="REQUEST.RESEND" />
												</button>
											</div>
											<div
												className="d-flex align-items-center text-muted cursor-pointer"
												onClick={() => copyLink(provider)}
											>
												<div>
													<FormattedMessage id="REQUEST.SHARE" />
												</div>
												<div className="pl-5px">
													<span className="fas fa-link" />
												</div>
											</div>
										</div>
									</div>

									{
										index !== arr.length - 1 && (
											<hr />
										)
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</div>
		</Dialog>
	);
}

export default withSnackbar(
	injectIntl(
		ResendRequest
	)
);
