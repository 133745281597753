import axios from 'axios';
import _ from 'lodash';
import { vanillaAxios } from '../../_metronic';

export function updateProvider(providerId, data) {
	const sanitizedData = _.omit(data, [ 'stats' ]);
	return axios.patch(`/api/v1/document-providers/${providerId}`, sanitizedData);
}
export function updateProviderV2(providerId, data) {
	const sanitizedData = _.omit(data, ['stats']);
	return axios.patch(`/api/v2/document-providers/${providerId}`, sanitizedData);
}

export function updateProviderStatus(providerId, status) {
	return axios.post(`/api/v1/document-providers/${providerId}/status-update`, { status });
}

export function getProviderMessages(providerId) {
	return axios.get(`/api/v1/document-providers/${providerId}/messages`);
}

export function addProviderMessage(providerId, message) {
	return axios.post(`/api/v1/document-providers/${providerId}/add-message`, { message });
}

export function getSignedUrl(name, type, token, acl) {
	return axios.post(`/services/aws-signed-url`, {
		key: name,
		contentType: type,
		extension: name.split('.').pop(),
		acl,
		token,
	});
}

export function getSignedUrlForAttachment(requestId, providerId, documentId, attachmentId, token) {
	return axios.get(`/api/v1/document-requests/${requestId}/${providerId}/${documentId}/attachment?attachmentId=${attachmentId}&token=${token}`);
}
export function getSignedUrlForFile(requestId, providerId, documentId, fileId, token) {
	return axios.get(`/api/v1/document-requests/${requestId}/${providerId}/${documentId}/file?fileId=${fileId}&token=${token}`);
}

export function uploadFileToDocument(requestId, providerId, documentId, file, token, existingFiles = []) {
	return axios.post(`/api/v1/document-requests/${requestId}/${providerId}/${documentId}/upload-file?token=${token}`, {
		fileName: file.name,
		extension: file.name.split('.').pop(),
		contentType: file.type,
		files: existingFiles,
	});
}

export function uploadFileToElement(requestId, providerId, elementId, file, token, existingFiles = []) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/upload-file?token=${token}`, {
		fileName: file.name,
		extension: file.name.split('.').pop(),
		contentType: file.type,
		files: existingFiles,
	});
}

export function uploadFile(url, file, cancelTokenId, onProgress) {
	const cancelSource = cancelTokenId && window.AXIOS_GLOBAL_CANCEL_TOKENS[cancelTokenId];
	const cancelToken = cancelSource && cancelSource.token;

	return vanillaAxios.put(url, file, {
		cancelToken,
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

			if (onProgress && typeof onProgress === "function") {
				onProgress(percentCompleted);
			}
		},
		headers: {
			"Content-Type": file.type,
		}
	});
}

export function saveProviderResponse(requestId, providerId, elements, token) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/update`, {
		elements,
		token,
	})
}

export function getElementComments(requestId, providerId, elementId, token) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/comments`, {
		token
	});
}

export function addElementComment(requestId, providerId, elementId, token, comment) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/add-comment`, {
		comment,
		token,
	});
}

export function updateElement(requestId, providerId, elementId, token, data) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/update`, {
		...data,
		token,
	})
}

export function updateElementStatus(requestId, providerId, elementId, token, data) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/update-status`, {
		...data,
		token,
	})
}
