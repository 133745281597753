import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import Value from './Value';

const useStyles = makeStyles((theme) => {
	return {
		planCard: {
			boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.13)"
		},
		evenRowCell: {
			background: "#F7F9FF"
		},
		oddRowCell: {
			background: "#FFFFFF",
		},
	}
});

const PLAN_TYPE = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY"
};

function ComparisonSM({ plans, features, className, onPlanSelect, isBillingAnnually }) {
	const classes = useStyles();

	const planSet = plans.filter(({ type, slug, isEnterprisePlan }) => {
		const isPlanYearly = type === PLAN_TYPE.YEARLY;
		const isPlanMonthly = type === PLAN_TYPE.MONTHLY;

		return isEnterprisePlan || (isBillingAnnually ? isPlanYearly : isPlanMonthly);
	});

	return (
		<div className={clsx(className, "row justify-content-center")}>
			{
				planSet.map((plan, index) => {
					return (
						<div
							key={index}
							className={clsx("col-12 col-sm-8 offset-sm-2 bg-white rounded mb-20px px-0 py-20px", classes.planCard)}
						>
							<div className="plan-info px-15px">
								<div className="plan-name f-22px mb-5px text-dark">
									{plan.name}
								</div>
								<div className="plan-description f-14px mb-10px text-dark">
									{plan.description}
								</div>

								<div className="mb-20px">
									<button
										className={clsx(
											"btn btn-primary",
											{
												// to keep the space occupied by button and hence keeping elements aligned
												"pointer-events-none opacity-0": plan.isEnterprisePlan
											}
										)}
										onClick={() => onPlanSelect(plan.id)}
									>
										<FormattedMessage id="GENERAL.SELECT" />
									</button>
								</div>
							</div>

							<div className="feature-list mb-20px">
								{
									features.map((feature, index) => {
										const rowNumber = index;

										return (
											<div
												key={index}
												className={clsx(
													"feature-price d-flex p-20px",
													{
														[classes.oddRowCell]: rowNumber % 2 !== 0,
														[classes.evenRowCell]: rowNumber % 2 === 0,
													}
												)}
											>
												<div className="flex-grow-1 feature-title f-16px">
													{feature.name}
												</div>
												<div
													className={
														clsx(
															"feature-value f-18px",
															{
																"font-weight-bold": feature.key === "PRICE"
															}
														)
													}
												>
													<Value
														feature={feature}
														plan={plan}
													/>
												</div>
											</div>
										)
									})
								}
							</div>

							<div className="px-15px">
								<button
									className={clsx(
										"btn btn-primary",
										{
											// to keep the space occupied by button and hence keeping elements aligned
											"pointer-events-none opacity-0": plan.isEnterprisePlan
										}
									)}
									onClick={() => onPlanSelect(plan.id)}
								>
									<FormattedMessage id="GENERAL.SELECT" />
								</button>
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default ComparisonSM;