import { useTheme, makeStyles } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import clsx from 'clsx';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import { sanitizeFile, requestSignedUrl, base64ToFile } from '../../../../../_metronic';
import Preview from './Preview';
import ErrorMessage from '../ErrorMessage';
import Settings from './Settings';
import { getEmptyErrorObj, validateImage } from '../../ElementPicker';
import { getSignedUrl, uploadFile } from '../../../../crud/provider.crud';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '..';
import AutoExpandInput from '../../../auto-expand-input';
import CropModal from '../../../modals/crop-modal';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		background: "rgba(229, 234, 249, 0.35)",
		borderRadius: 6,
		minHeight: 160,
	},
	thumbnail: {
		[theme.breakpoints.up('sm')]: {
			height: theme.elementSizes.image.maxHeight,
			maxWidth: theme.elementSizes.image.maxWidth,
		},
		width: "100%",
		objectFit: "cover",
	}
}));

const Image = forwardRef((props, _ref) => {
	const { id, data, additionalData, onChange } = props;
	const intl = useIntl();
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();

	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	const [loadingImage, setLoadingImage] = useState(false);
	const [publicUrl, setPublicUrl] = useState(null);
	const [error, setError] = useState({});
	const [uploading, setUploading] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

	const [imageFile, setImageFile] = useState(null);

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	useEffect(() => {
		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
		};
		const error = validateImage(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.IMAGE);
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	])

	useEffect(() => {
		async function loadImage (s3Url) {
			return requestSignedUrl(s3Url).then((response) => {
				return response.data.url;
			});
		}

		function loadPreview(url) {
			setLoadingImage(true);

			loadImage(url)
				.then((localUrl) => {
					setPublicUrl(localUrl);
					setLoadingImage(false);
				})
				.catch((err) => {
					console.log(err);
					setLoadingImage(false);
				});
		}

		if (data.attachment && data.attachment.url) {
			loadPreview(data.attachment.url);
		}
	}, [
		data.attachment,
	]);

	const onDrop = useCallback(async (acceptedFiles) => {
		const uploadedImage = acceptedFiles[0];
		const { type } = uploadedImage;

		if (!type.includes("image/")) {
			const msg = intl.formatMessage({
				id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
			}, uploadedImage);
			enqueueSnackbar(msg, { variant: 'error' })
			return;
		}

		const sanitizedFile = await sanitizeFile(uploadedImage);

		setImageFile(sanitizedFile);
	}, [enqueueSnackbar, intl]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
	} = useDropzone({
		onDrop,
		noClick: false,
		multiple: false,
		// disabled: isUploading ||
		// 	[Constants.DocumentType.READ_ONLY, Constants.DocumentType.BANK_STATEMENTS].includes(item.type) ||
		// 	item.status === Constants.DocumentStatus.APPROVED
	});

	const uploadImage = useCallback(async (file) => {
		setUploading(true);
		const {
			requestUUID,
			providerUUID,
			templateUUID,
		} = additionalData;
		const {
			name,
			type,
		} = imageFile;
		let filePath = null;

		if (templateUUID) {
			filePath = `templates/${templateUUID}/${id}/attachments/${name}`;
		}
		else {
			filePath = `/document-requests/${requestUUID}/${providerUUID}/${id}/attachments/${name}`;
		}

		const { data: signedUrlData } = await getSignedUrl(filePath, type);
		const {
			signedRequest,
			url
		} = signedUrlData;

		// upload actual files
		await uploadFile(signedRequest, file);

		setUploading(false);

		return url;
	}, [additionalData, id, imageFile]);

	const hideCropModal = useCallback(async (croppedImageUrl) => {
		if (croppedImageUrl) {
			const {
				name,
				type,
			} = imageFile;
			const croppedImage = await base64ToFile(croppedImageUrl, name, { type });
			const url = await uploadImage(croppedImage);
			const newData = {
				...data,
				attachment: {
					name,
					size: croppedImage.size/1024,
					url,
				}
			}

			const error = validateImage(newData);
			const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.IMAGE);
			const isSafe = _.isEqual(error, emptyErrorObj);

			if (isSafe) {
				onChange(newData);
			}

			setError(error);
		}

		setImageFile(null);
	}, [data, imageFile, onChange, uploadImage]);


	return (
		<div>
			<div>
				<div>
					<AutoExpandInput
						className="f-18px text-dark font-weight-medium mb-0"
						onChange={(e) => setTitle(e.target.value)}
						value={title}
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.IMAGE.TITLE.PLACEHOLDER"
							})
						}
					/>
				</div>

				{
					error.title && (
						<ErrorMessage message={error.title} />
					)
				}

				<div>
					<AutoExpandInput
						className="f-14px text-dark font-weight-regular mb-10px w-100 pb-0 break-word"
						style={{ color: theme.palette.extraColors.lightText }}
						onChange={(e) => setSubtitle(e.target.value)}
						value={subtitle}
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.IMAGE.SUBTITLE.PLACEHOLDER"
							})
						}
						allowHTML
					/>
				</div>

				{
					error.image && (
						<ErrorMessage message={error.image} />
					)
				}

				{
					(loadingImage || uploading) ? (
						<div className="d-flex justify-content-center align-items-center">
							<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
						</div>
					) : (
						<React.Fragment>
							{
								!data.attachment || !data.attachment.url ? (
									<div
										className={clsx(
											classes.imageContainer,
											"d-flex position-relative flex-grow-1",
										)}
										{...getRootProps()}
									>
										<div
											className={clsx(
												"flex-grow-1 d-flex flex-column justify-content-center align-items-center",
												{ "border border-primary": isDragActive }
											)}
										>
											{
												uploading ? (
													<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
												) : (
													<React.Fragment>
														<div className="mb-5px text-center">
															<CloudUpload style={{ height: 48, width: 48 }} />
														</div>
														<div>
															<button className="btn btn-primary">
																<FormattedMessage id="ELEMENT_LIST.ITEM.IMAGE.UPLOAD" />
																<input {...getInputProps()} />
															</button>
														</div>
														<div className="text-center message text-muted">
															<FormattedMessage id="ELEMENT_LIST.ITEM.IMAGE.DRAG_AND_DROP" />
														</div>
													</React.Fragment>
												)
											}
										</div>
									</div>
								) : (
									<div className="cursor-pointer" onClick={() => setShowPreview(true)}>
										<img
											className={clsx(classes.thumbnail, "border rounded")}
											src={publicUrl}
											alt={data.title}
										/>
									</div>
								)
							}
						</React.Fragment>
					)
				}
			</div>

			<Settings
				id={id}
				data={data}
				rootProps={getRootProps()}
				inputProps={getInputProps()}
			/>

			<Preview
				open={showPreview}
				link={publicUrl}
				title={data.title}
				handleClose={() => setShowPreview(false)}
			/>

			{
				imageFile && (
					<CropModal
						restrictPosition={false}
						cropWindow={{
							width: theme.elementSizes.image.maxWidth,
							height: theme.elementSizes.image.maxHeight
						}}
						file={imageFile}
						titleLabelId={'CUSTOMIZATION.COMMUNICATION.BUSINESS_LOGO.CROP'}
						handleClose={(croppedImageUrl) => hideCropModal(croppedImageUrl)}
					/>
				)
			}
		</div>
	)
});

export default Image;
