/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Routes from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, customTheme } from "./_metronic";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function App({ store, basename }) {
  return (
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <LastLocationProvider>
            {/* Provide Metronic theme overrides. */}
            <ThemeProvider theme={customTheme}>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                <SnackbarProvider
                  maxSnack = {5}
                  classes = {{
                    variantSuccess: 'bg-success text-light',
                    variantError: 'bg-error text-light',
                    variantWarning: 'bg-warning text-light',
                    // variantPrimary: 'bg-primary text-light',
                    variantInfo: 'bg-info text-light',
                  }}
                  anchorOrigin = {{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  autoHideDuration = { 3000}
                >
                  <Elements stripe={stripePromise}>
                    <Routes />
                  </Elements>
                </SnackbarProvider>
              </I18nProvider>
            </ThemeProvider>
          </LastLocationProvider>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
}
