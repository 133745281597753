import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RawIntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";

import { kaddimIntl } from "./intl";

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const [intl, setIntl] = useState(kaddimIntl.intl);

  useEffect(() => {
    kaddimIntl.locale = locale;
    setIntl(kaddimIntl.intl);
  }, [locale]);

  if (!intl) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center mt-5">
        <div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
      </div>
    )
  };

  return (
    <RawIntlProvider value={intl}>
      {children}
    </RawIntlProvider>
  );
}
