import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import IframeLoader from "../../common/IframeLoader";

function AgreementModal ({titleLabelId, url, handleClose}) {
	const [loading, setLoading] = useState(true);

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open
			scroll="body"
			classes={{
				paper: "h-75"
			}}
			onClose={handleClose}
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<div className="p-20 d-flex flex-column h-100">
				<div className="f-15 label-4 mb-15 flex-grow-0">
					<div className="row">
						<div className="col-8 offset-2 text-center">
							<h4 className="m-0">
								<FormattedMessage id={titleLabelId} />
							</h4>
						</div>
						<div className="col-2">
							<div className="float-right cursor-pointer" onClick={() => handleClose()}>
								<span className="fas fa-times"></span>
							</div>
						</div>
					</div>
				</div>

				<div className="flex-grow-1">
					<IframeLoader
						className={loading ? "invisible" : "visible fade-in w-100 h-100"}
						onLoad={() => setLoading(false)}
						src={url}
						frameBorder={0}
					/>

					{
						!!loading && (
							<div className="kt-spinner kt-spinner--center kt-spinner--lg kt-spinner--brand p-4" />
						)
					}
				</div>
			</div>
		</Dialog>
	);
}

export default AgreementModal;