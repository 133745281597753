import React, { useState } from "react";
import clsx from "clsx";
import { Select, MenuItem } from "@material-ui/core";

const CustomSelect = (allProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const {
		placeholder,
		children,
		classes = {},
		displayEmpty,
		...props
	} = allProps;

	const {
		root = '',
		...otherClasses
	} = classes;

	return (
		<Select
			{...props}
			classes={{
				...otherClasses,
				root: `${root} ${clsx({
					"text-secondary": !props.value
				})}`
			}}
			displayEmpty={displayEmpty || !!placeholder}
			onOpen={() => setIsOpen(true)}
			MenuProps={{
				onExited: () => setIsOpen(false)
			}}
		>
			{
				placeholder && !isOpen && (
					<MenuItem value="" disabled className="text-secondary">
						{placeholder}
					</MenuItem>
				)
			}
			{children}
		</Select>
	)
}

export default CustomSelect;