import * as DocumentStatus from './document.status';
import * as ProviderStatus from './provider.status';
import * as RequestStatus from './request.status';
import * as DocumentType from './document.type';
import * as ProviderEmailStatus from "./provider-email.status";
import * as IntegrationsType from "./integrations.type";
import PLANS from './plans.config';

export default {
	DocumentStatus,
	ProviderStatus,
	RequestStatus,
	DocumentType,
	ProviderEmailStatus,
	IntegrationsType,
	FILE_RENAME_OPTIONS: [
		{
			title: "First Name",
			value: "{{firstName}}"
		},
		{
			title: "Last Name",
			value: "{{lastName}}"
		},
		{
			title: "Date & Time",
			value: "{{date}}"
		},
		{
			title: "Month",
			value: "{{mm}}"
		},
		{
			title: "Year",
			value: "{{yyyy}}"
		},
	],
	FILE_RENAME_LIMIT: 6,
	ATTACHMENTS_LIMIT_MB: 50,
	VIDEO_ELEMENT_STORAGE_LIMIT_MB: 100,
	FILE_UPLOAD_LIMIT: Infinity,
	SURVICATE_SURVEY_ID: process.env.REACT_APP_SURVICATE_SURVEY_ID,
	PLANS,
	UI_SIZE: {
		EMAIL_FIELDS: {
			WIDTH: 592,
		},
	}
};