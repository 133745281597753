import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useMemo } from "react"
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => {
	return {
		accordion: {
			backgroundColor: "transparent",
			boxShadow: "none !important",
			color: "inherit",

			"&::before": {
				opacity: 0,
			},

			"& .MuiAccordionSummary-root": {
				backgroundColor: "transparent",
				padding: 0,
			},
		},
	}
});

function FAQ () {
	const classes = useStyles();
	const faqs = useMemo(() => {
		const list = [];

		for (let i = 1; i <= 3; i++) {
			list.push({
				question: `PRICING.FAQ.Q${i}`,
				answer: `PRICING.FAQ.A${i}`,
			})
		}

		return list;
	}, []);

	return (
		<div>
			<div className="mb-15px f-25px text-dark">
				<FormattedMessage id="PRICING.FAQ.TITLE" />
			</div>
			{
				faqs.map((faq, index) => {
					return (
						<Accordion key={index} classes={{ root: classes.accordion }}>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								className="bg-transparent pl-0 fw-400 f-18px"
								classes={{
									content: "mb-10px mt-20px",
									expanded: "text-dark",
								}}
							>
								<FormattedMessage id={faq.question} />
							</AccordionSummary>
							<AccordionDetails className="p-0 f-16px fw-300 text-dark">
								<FormattedMessage id={faq.answer} />
							</AccordionDetails>
						</Accordion>
					)
				})
			}
		</div>
	)
}

export default FAQ;
