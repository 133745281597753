import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import {
	loadTemplate,
	loadTemplates,
	flagNames,
} from '../../../../../store/modules/actions/templates.actions';
import NewLayout from './New';
import OldLayout from './Legacy';
import { getFlags } from '../../../../../store/modules/selectors/common.selector';
import { getSelectedTemplate } from '../../../../../store/modules/selectors/template.selector';

export default function EditTemplate () {
	const dispatch = useDispatch();
	const params = useParams();
	
	useEffect(() => {
		dispatch(loadTemplates());
		dispatch(loadTemplate(params.template));
	}, [params.template, dispatch]);

	const flags = useSelector(getFlags);
	const template = useSelector(getSelectedTemplate);
	
	if (
		flags.loading[flagNames.TEMPLATES] ||
		flags.loading[flagNames.TEMPLATE]
	) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	if (!template) {
		return (
			<div className="d-flex justify-content-center mt-5">
				<Alert color="danger">
					<FormattedMessage id="TEMPLATES.EDIT.TEMPLATE_NOT_FOUND" />
				</Alert>
			</div>
		)
	}

	if (template.format === "MODERN") {
		return (
			<NewLayout template={params.template} />
		);
	}
	else {
		return (
			<OldLayout template={params.template} />
		);
	}
}