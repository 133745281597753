import React from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	makeStyles
} from "@material-ui/core";
import { Col, Row } from "reactstrap";
import clsx from "clsx";
import { useHistory } from "react-router";
import { globalGA } from "../../../../_metronic";

const useStyles = makeStyles((theme) => {
	return {
		manageAddonsButton: {
			background: "#EBEFFF",
			borderRadius: 4,
			padding: "10px 15px",
			color: theme.palette.primary.main
		}
	}
});

function ActiveAddons({ billingData }) {
	const classes = useStyles();
	const history = useHistory();
	const addons = {
		sms: billingData.ACTIVE_ADDONS.additionalSms,
		requests: billingData.ACTIVE_ADDONS.additionalRequest,
	};

	return (
		<div className="p-20px bg-white rounded">
			<div className="mb-30px f-20px text-dark">
				<FormattedMessage id="BILLING.ACTIVE_ADDONS.TITLE" />
			</div>

			<Row>
				<Col xs={12} sm={8} md={8} lg={6}>
					<div className="d-flex justify-content-between align-items-center mb-10px">
						<div className="text-dark f-15px">
							<FormattedMessage id="BILLING.ACTIVE_ADDONS.SMS" />
						</div>
						<div className="text-dark f-15px">
							{addons.sms}
						</div>
					</div>
					<div className="d-flex justify-content-between align-items-center mb-10px">
						<div className="text-dark f-15px">
							<FormattedMessage id="BILLING.ACTIVE_ADDONS.REQUESTS" />
						</div>
						<div className="text-dark f-15px">
							{addons.requests}
						</div>
					</div>
				</Col>
			</Row>
			
			<div className="mt-30px mb-10px d-flex">
				<div
					className={clsx(classes.manageAddonsButton, "cursor-pointer")}
					onClick={() => {
						globalGA("open_manage_addons", {
							eventAction: "success",
							eventCategory: "subscription"
						});
						history.push("/manage-addon")
					}}
				>
					<FormattedMessage id="BILLING.ACTIVE_ADDONS.MANAGE_PAYMENT" />
				</div>
			</div>
		</div>
	);
}

export default ActiveAddons;
