import React, { Fragment, forwardRef, useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle } from 'react';
import { Switch, makeStyles, useTheme } from '@material-ui/core';
import { ReportProblemSharp, Mic } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import * as Sentry from "@sentry/react";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from 'linkifyjs/html';
import { useReactMediaRecorder } from 'react-media-recorder';
import moment from 'moment';
import { Alert } from 'reactstrap';
import { isIOS } from "react-device-detect";
import { useDropzone } from 'react-dropzone';
import { uuid } from 'uuidv4';

import FileCard from './FileCard';
import { addAlpha, isAttachmentValid, sanitizeFile } from '../../../../../_metronic';
import Confirm from '../../../modals/confirm';
import { DangerBlocksIcon, FileErrorIcon } from '../../../icons';
import { uploadFileToElement } from '../../../../store/modules/actions/provider.actions';
import Constants from '../../../constants';
import AttachmentCard from './AttachmentCard';
import TimerWidget from './TimerWidget';
import CountdownTimer from './CountdownTimer';
import AudioPreview from './VideoPreview';
import { getUploadTaskResults } from '../../../../store/modules/selectors/tasks.selector';

const useStyles = makeStyles((theme) => {
	return {
		attachmentListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
			border: `2px dashed ${theme.palette.extraColors.grey}`,
			borderRadius: 6,
		},
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		uploadContainer: {
			height: 170,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto",

			'&::-webkit-scrollbar': {
				marginLeft: 5,
				width: 8,
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				borderRadius: 5,
				background: 'transparent'
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: 'rgb(235, 239, 255)',
				borderRadius: 15,
			}
		},
		viewPreviewInfo: {
			position: "absolute",
			left: "50%",
			bottom: "5%",
			transform: "translateX(-50%)",
			zIndex: 3,
		},
		acquiringMediaText: {
			position: "absolute",
			bottom: "20%",
			left: "50%",
			transform: "translateX(-50%)",
			zIndex: 3,
		},
		cameraPreviewContainer: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,

			'& video': {
				zIndex: 2,
			}
		},
		iosFileInput: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			opacity: 0,
		},
		audioPreviewOverlay: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: addAlpha(theme.palette.dark.primary.main, 0.4),
		}
	}
});

const VideoInput = forwardRef((props, _ref) => {
	const {
		id,
		data,
		response,
		additionalData,
		onChange,
		onPreview
	} = props;

	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const uploadTaskResult = useSelector(getUploadTaskResults);

	const {
		providerUUID,
		hideDownload,
	} = additionalData;

	const uploadTasksRef = useRef({});
	const [deleteFileIndex, setDeleteFileIndex] = useState(-1);
	const [zeroSizeFiles, setZeroSizeFiles] = useState([]);
	const [showOriginalName, setShowOriginalName] = useState(false);
	const [showStorageLimitWarning, setShowStorageLimitWarning] = useState(false);
	const hasTimerCompleted = useRef(false);
	const wasStoppedBeforeStart = useRef(false);
	const isPausedManually = useRef(false);

	const hasValidOriginalName = useMemo(() => {
		return response.files.some(({ originalName, name }) => name && originalName && name !== originalName );
	}, [response.files]);

	const isStorageLimitReached = useCallback((newFiles) => {
		const existingAttachmentSizeKB = response.files.reduce((p, file) => {
			return p + file.size;
		}, 0);
		const newFilesKB = newFiles.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.VIDEO_ELEMENT_STORAGE_LIMIT_MB;
	}, [response]);

	const onFileDrop = useCallback(async (selectedFiles) => {
		const [zeroSizeFiles, acceptedFiles] = _.partition(selectedFiles, (file) => file.size < 1024)
		const validFiles = acceptedFiles.filter((file) => {
			const isValidFile = isAttachmentValid(file);
			const extension = file.name.split('.').pop().toLowerCase();
			const isValidAudioFile = ['mp3', 'wav'].includes(extension);

			return isValidFile && isValidAudioFile;
		});
		setZeroSizeFiles(zeroSizeFiles);

		acceptedFiles
			.filter((file) => !validFiles.includes(file))
			.forEach((file) => {
				const msg = intl.formatMessage({
					id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
				}, file);
				enqueueSnackbar(msg, { variant: 'error' })
			});
		
		if (zeroSizeFiles.length) {
			Sentry.captureMessage(`user ${providerUUID} uploaded ${zeroSizeFiles.length} files with size less than 1kb`);
		}

		if (isStorageLimitReached(validFiles)) {
			setShowStorageLimitWarning(true);
			return;
		}

		const promises = validFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		const uploadId = uuid();

		dispatch(uploadFileToElement(id, files, uploadId));
		uploadTasksRef.current[uploadId] = true;
	}, [isStorageLimitReached, dispatch, id, intl, enqueueSnackbar, providerUUID]);

	const {
		getRootProps: getFileInputPropsRoot,
		getInputProps: getFileInputProps,
	} = useDropzone({
		onDrop: onFileDrop,
		accept: "audio/*",
		noClick: false,
	});

	const handleDelete = useCallback((fileIndexToDelete) => {
		const files = response.files.filter((file, index) => index !== fileIndexToDelete);

		onChange({
			...response,
			files,
		});
	}, [
		onChange,
		response,
	]);

	const handleAudioRecordingComplete = useCallback((bloblUrl, blob) => {
		if (wasStoppedBeforeStart.current) {
			return;
		}

		const timeString = moment().format("DD-MM-YYYY hh:mm:SS A");
		const extension = "wav";
		const fileName = `audio recording ${timeString}.${extension}`; 
		const audioFile = new File([blob], fileName, { type: "audio/wav" });

		onFileDrop([audioFile]);
	}, [onFileDrop]);

	const { status, startRecording, resumeRecording, pauseRecording, stopRecording, previewAudioStream: previewStream } = useReactMediaRecorder({
		blobPropertyBag: {
			type: "audio/wav",
		},
		onStop: handleAudioRecordingComplete,
	});
	useImperativeHandle(_ref, () => ({
		shouldStopMouseListener: () => status === "recording" || status === "paused",
	}));

	const handleRecordingStart = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		wasStoppedBeforeStart.current = false;
		hasTimerCompleted.current = false;
		isPausedManually.current = false;

		startRecording();
	}, [startRecording]);

	const handleTimerComplete = useCallback((e) => {
		hasTimerCompleted.current = true;
		resumeRecording();
	}, [resumeRecording]);

	const handleRecordingPause = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		isPausedManually.current = true;
		pauseRecording();
	}, [pauseRecording]);

	const handleRecordingResume = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		isPausedManually.current = false;
		resumeRecording();
	}, [resumeRecording]);

	const handleRecordingStop = useCallback((e) => {
		wasStoppedBeforeStart.current = !hasTimerCompleted.current;
		stopRecording();
	}, [stopRecording]);
	
	useEffect(() => {
		if (status === "recording" && !hasTimerCompleted.current) {
			pauseRecording();
		}
	}, [status, pauseRecording]);

	useEffect(() => {
		const currentUploadIds = Object.keys(uploadTasksRef.current);

		for (const key of currentUploadIds) {
			const result = uploadTaskResult[key];

			if (result) {
				delete uploadTasksRef.current[key];

				onChange({
					...response,
					files: [
						...result,
						...response.files,
					]
				});
			}
		}
	}, [onChange, response, uploadTaskResult]);

	const isNotRecording = useMemo(() => status === "idle" || status === "stopped", [status]);

	return (
		<div>
			<div>
				<div className="d-flex align-items-center mr-5 mr-md-0 pr-3">
					<div className="mr-2 row">
						<span className="col-12 pr-0 text-dark font-weight-medium f-18px">
							{data.title}
						</span>
					</div>
				</div>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className={clsx("row", { "pointer-events-none": !isNotRecording })}>
				<div className="col-12">
					<div className="my-10px d-flex flex-column flex-grow-1 px-0">
						{
							data.attachments.length > 0 && (
								<div className={clsx(classes.attachmentListContainer, "mb-15px")}>
									<div className="f-14px mb-5px px-10px pt-10px fw-500 text-muted">
										<FormattedMessage
											id="ELEMENT_LIST.ITEM.AUDIO_INPUT.ATTACHMENTS.TITLE"
											defaultMessage="Attached Files"
										/>
									</div>

									<div className={classes.fileList}>
										{
											data.attachments.map((attachment, index, arr) => (
												<div
													key={index}
													className={
														clsx(
															{
																"mb-5px": index !== arr.length - 1,
																"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
															}
														)
													}
												>
													<AttachmentCard
														canDownload
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();

															onPreview(attachment);
														}}
														attachment={attachment}
													/>
												</div>
											))
										}
									</div>
								</div>	
							)
						}

						<div className={clsx(classes.fileListContainer, "rounded bg-white p-10px")}>
							<div className="f-14px fw-500 mb-5px text-muted">
								<FormattedMessage
									id="ELEMENT_LIST.ITEM.AUDIO_INPUT.FILES.TITLE"
									defaultMessage="Uploaded Files"
								/>
							</div>

							<div className={classes.fileList}>
								{
									response.files.map((file, index, arr) => (
										<div
											key={index}
											className={
												clsx(
													{
														"mb-5px": index !== arr.length - 1,
														"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
													}
												)
											}
										>
											<FileCard
												canDownload={!hideDownload && file._id}
												fileNameField={showOriginalName ? 'originalName' : undefined}
												onDelete={() => setDeleteFileIndex(index)}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();

													if (typeof onPreview !== "function") {
														console.log("preview function is not available");
														return;
													}

													onPreview(file);
												}}
												file={file}
											/>
										</div>
									))
								}
							</div>

							<div
								className={clsx(
									"mt-10px p-10px position-relative flex-grow-1 rounded",
									{
										"d-flex justify-content-center align-items-center": !response.files.length,
										"bg-dark": !isNotRecording ,
									}
								)}
								style={{
									background: theme.palette.extraColors.backgroundBlue,
								}}
								{...getFileInputPropsRoot()}
							>
								<div className={clsx(classes.uploadContainer, "w-100 d-flex align-items-center justify-content-center flex-column")}>
									<div className={clsx("align-items-center text-muted", { "d-none": !isNotRecording, "d-flex": isNotRecording })}>
										<Mic fontSize="large" />
									</div>

									<div className="mt-5px d-flex flex-column align-items-center">
										{
											isIOS ? (
												<div onClick={(e) => e.stopPropagation()}>
													{/* this is done to prevent the parent input element (used for drag and drop files) to intercept the upload event */}
													<label className="btn btn-primary btn-sm d-flex align-items-center position-relative">
														<input
															className={classes.iosFileInput}
															type="file"
															capture="environment"
															accept="audio/wav"
															onChange={(e) => onFileDrop(e.target.files)}
														/>
														<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.START_BUTTON_TEXT" />
													</label>
												</div>
											) : (
												<div className={clsx({ [classes.cameraPreviewContainer]: !isNotRecording })}>
													{
														!isNotRecording && (
															<Fragment>
																{
																	status === "acquiring_media" && (
																		<div className={clsx("text-light", classes.acquiringMediaText)}>
																			<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.ACQUIRING_MEDIA" />
																		</div>
																	)
																}
																
																<div className={classes.viewPreviewInfo}>
																	<div>
																		<CountdownTimer
																			wasPausedManually={isPausedManually.current}
																			status={status}
																			onComplete={handleTimerComplete}
																		/>
																	</div>

																	<div className='pointer-events-all'>
																		<TimerWidget
																			status={status}
																			canPause={hasTimerCompleted.current}
																			onPause={handleRecordingPause}
																			onResume={handleRecordingResume}
																			onStop={handleRecordingStop}
																		/>
																	</div>
																</div>
															</Fragment>
														)
													}

													{
														status === "media_in_use" && (
															<Alert color="danger">
																<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.MEDIA_IN_USE_ERROR" />
															</Alert>
														)
													}
														
													{
														isNotRecording && (
															<button
																onClick={handleRecordingStart}
																className="btn btn-primary btn-sm d-flex align-items-center"
															>
																<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.START_BUTTON_TEXT" />
															</button>
														)
													}

													{
														status === "paused" && (
															<div className={classes.audioPreviewOverlay} />
														)
													}

													{
														!isNotRecording && (
															<AudioPreview stream={previewStream} />
														)
													}
												</div>
											)
										}
									</div>

									{
										isNotRecording && (
											<div className='d-flex mt-15px'>
												<div>or</div>
												&nbsp;
												<div>
													<input {...getFileInputProps()} />
													<span className='cursor-pointer text-primary text-decoration-underline'>
														<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.FILES.MANUAL_UPLOAD" />
													</span>
												</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					hasValidOriginalName && (
						<div className="d-flex align-items-center mt-5px px-3">
							<div className="f-16px text-muted">
								<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.ORIGINAL_FILE_NAME.LABEL" />
							</div>

							<div className="ml-2">
								<Switch
									checked={showOriginalName}
									classes={{
										thumb: showOriginalName ? 'bg-dark' : 'bg-white'
									}}
									onChange={(e) => setShowOriginalName(!showOriginalName)}
								/>
							</div>
						</div>
					)
				}
			</div>

			{
				deleteFileIndex > -1 && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={(wasSubmitted) => {
							if (wasSubmitted) {
								handleDelete(deleteFileIndex)
							}
							setDeleteFileIndex(-1);
						}}
						title="PROVIDER.ELEMENT.FILE.DELETE.FILE.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.ELEMENT.FILE.DELETE.FILE.DESCRIPTION"
							}, {
								elementTitle: data.title,
								fileName: response.files[deleteFileIndex].name
							})
						}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			<Confirm
				open={showStorageLimitWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setShowStorageLimitWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.VIDEO_ELEMENT_STORAGE_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>

			<Confirm
				open={zeroSizeFiles && zeroSizeFiles.length > 0}
				icon={<ReportProblemSharp color="error" style={{ height: 80, width: 100 }} />}
				variant="danger"
				handleClose={() => setZeroSizeFiles([])}
				title={intl.formatMessage({ id: 'PROVIDER.FILES.ZERO_SIZE_ERROR.TITLE' })}
				message={
					<div>
						<div>
							<FormattedMessage id="PROVIDER.FILES.ZERO_SIZE_ERROR.MESSAGE" />
						</div>

						<div className="mt-4">
							<ol>
								{
									zeroSizeFiles.map((file, index) => {
										return (
											<li
												key={index}
												className="text-left"
											>
												{file.name}
											</li>
										)
									})
								}
							</ol>
						</div>
					</div>
				}
				submitButtonText="GENERAL.DISMISS"
			/>
		</div>
	)
});

export default VideoInput;
