import { useTheme } from '@material-ui/core';
import React, { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import { validateVideo, getVideoConfig, getEmptyErrorObj } from '../../ElementPicker';
import Input from '../../../Input';
import ErrorMessage from '../ErrorMessage';
import EmbeddedVideo from './EmbeddedVideo';
import Settings from './Settings';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '..';
import AutoExpandInput from '../../../auto-expand-input';

const Video = forwardRef((props, _ref) => {
	const { id, data, onChange } = props;
	const intl = useIntl();
	const theme = useTheme();

	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	const [videoUrl, setVideoUrl] = useState(data.videoObj && data.videoObj.url);
	const debouncedVideoUrl = useDebounce(videoUrl, 500);

	const [videoConfig, setVideoConfig] = useState({
		type: null,
		videoId: null,
		start: null,
	});
	const [error, setError] = useState({});
	
	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	useEffect(() => {
		const {
			type,
			videoId,
			start,
		} = getVideoConfig(debouncedVideoUrl);
		
		setVideoConfig({
			type,
			videoId,
			start,
		});

		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
			videoObj: {
				...data.videoObj,
				url: debouncedVideoUrl,
				type,
				videoId,
				refObj: {
					start,
				}
			},
		};
		const error = validateVideo(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.VIDEO)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
		debouncedVideoUrl,
	]);

	return (
		<div className="row">
			<div className="col-12">
				<div>
					<AutoExpandInput
						className="f-18px text-dark font-weight-medium mb-0"
						onChange={(e) => setTitle(e.target.value)}
						value={title}
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.VIDEO.TITLE.PLACEHOLDER"
							})
						}
					/>
				</div>

				{
					error.title && (
						<ErrorMessage message={error.title} />
					)
				}

				<div>
					<AutoExpandInput
						className="f-14px text-dark font-weight-regular mb-10px w-100 pb-0 break-word"
						style={{ color: theme.palette.extraColors.lightText }}
						onChange={(e) => setSubtitle(e.target.value)}
						value={subtitle}
						placeholder={
							intl.formatMessage({
								id: "ELEMENT_LIST.ITEM.VIDEO.SUBTITLE.PLACEHOLDER"
							})
						}
						allowHTML
					/>
				</div>

				{
					videoConfig.type ? (
						<EmbeddedVideo
							key={videoConfig.videoId}
							type={videoConfig.type}
							videoId={videoConfig.videoId}
							start={videoConfig.start}
						/>
					) : (
						<Input
							type="text"
							variant="outlined"
							fullWidth
							InputProps={{
								classes: {
									root: "blue-border"
								},
							}}
							placeholder={
								intl.formatMessage({
									id: "ELEMENT_LIST.ITEM.VIDEO.URL.PLACEHOLDER"
								})
							}
							name="videoUrl"
							onChange={(e) => setVideoUrl(e.target.value)}
							defaultValue={videoUrl}
						/>
					)
				}
				{
					error.url && (
						<ErrorMessage message={error.url} />
					)
				}
			</div>

			<Settings
				id={id}
				videoConfig={videoConfig}
				handleChangeUrl={(url) => setVideoUrl(url)}
				videoUrl={videoUrl}
			/>
		</div>
	)
});

export default Video;
