import React, {
	useEffect,
} from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	useSelector,
	connect
} from "react-redux";
import {
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	DialogContent,
	// makeStyles
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	loadTemplate,
	deleteTemplate,
	flagNames,
} from '../../../../store/modules/actions/templates.actions';
import { getSelectedTemplate } from "../../../../store/modules/selectors/template.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

// const useStyles = makeStyles((theme) => {
// 	return {
// 		deleteButton: {
// 			background: theme.palette.error.main,
// 			color: theme.palette.error.contrastText,
// 			'&:hover': {
// 				background: theme.palette.error.light,
// 			}
// 		}
// 	}
// });

function Delete(props) {
	const {
		open,
		template,
		handleClose,
		loadTemplate,
		deleteTemplate,
	} = props;

	const selectedTemplate = useSelector(getSelectedTemplate);
	const flags = useSelector(getFlags);
	// const classes = useStyles();

	useEffect(() => {
		loadTemplate(template);
	}, [ loadTemplate, template ]);

	const loading = flags.loading[flagNames.TEMPLATE];
	const error = flags.error[flagNames.TEMPLATE];

	const handleSubmit = async () => {
		try {
			await deleteTemplate(selectedTemplate._id);
			handleClose();
		}
		catch (e) {
			console.log(e);
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>
				<FormattedMessage id="TEMPLATE.DELETE.TITLE" />
			</DialogTitle>
			<DialogContent>
				{
					loading ? (
						<div className="w-100 h-100 d-flex justify-content-center py-5">
							<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
						</div>
					) : (
							error ? (
								<div className="d-flex justify-content-center">
									<Alert color="danger">
										{error}
									</Alert>
								</div>
							) : (
									selectedTemplate ? (
										<DialogContentText>
											<FormattedMessage
												id="TEMPLATE.DELETE.MESSAGE"
												values={{ ...selectedTemplate }}
											/>

											{
												flags.error[flagNames.DELETE] && (
													<div className="d-flex justify-content-center">
														<Alert color="danger">
															{flags.error[flagNames.DELETE]}
														</Alert>
													</div>
												)
											}
										</DialogContentText>
									) : (
											<div className="d-flex justify-content-center">
												<Alert color="danger">
													<FormattedMessage id="TEMPLATE.NOT_FOUND" />
												</Alert>
											</div>
										)
								)
						)
				}
			</DialogContent>
			{
				selectedTemplate && (
					<DialogActions>
						<button
							className={`btn btn-danger ${clsx({
								"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--light": flags.loading[flagNames.DELETE]
							})}`}
							onClick={handleSubmit}
							variant="contained"
						>
							<FormattedMessage id="TEMPLATE.DELETE.SUBMIT" />
						</button>
					</DialogActions>
				)
			}
		</Dialog>
	);
}

export default connect(
	null,
	{
		deleteTemplate,
		loadTemplate,
	}
)(Delete)
