import axios from 'axios';

import {
	ASK_QUESTION_URL,
	NOT_REQUIRED_URL,
	REQUESTS_URL,
	REQUESTS_URL_V2,
} from "./request.url";

import Constants from "../common/constants";

const newAxiosInstance = axios.create();

export function getRequests(search, fromDate, toDate, status, updatedOnly, archived, emailStatus) {
	return axios.get(REQUESTS_URL_V2, {
		params: {
			search,
			fromDate,
			toDate,
			status,
			updatedOnly,
			archived,
			emailStatus,
		}
	});
}

export function createRequest(body) {
	return axios.post(`${REQUESTS_URL}/create`, body);
}
export function createRequestV2(body) {
	return axios.post(`${REQUESTS_URL_V2}/create`, body);
}

export function getRequest(requestId) {
	return axios.get(`${REQUESTS_URL}/${requestId}`);
}
export function getRequestV2(requestId) {
	return axios.get(`${REQUESTS_URL_V2}/${requestId}`);
}

export function updateRequestStatus(requestId, body) {
	return axios.post(`${REQUESTS_URL}/${requestId}/status-update`, body);
}

export function addProvider(requestId, providerData) {
	return axios.post(`${REQUESTS_URL}/${requestId}/add-provider`, providerData);
}
export function addProviderV2(requestId, providerData) {
	return axios.post(`${REQUESTS_URL_V2}/${requestId}/add-provider`, providerData);
}

export function removeProvider(requestId, providerId) {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				status: 200,
				data: {},
			});
		}, 4000);
	});
}

export function askQuestion(requestId, documentId, message) {
	return axios.post(ASK_QUESTION_URL, {
		message
	});
}

export function markNotRequired(requestId, documentId, markNotRequiredReason) {
	return axios.post(NOT_REQUIRED_URL, {
		status: Constants.DocumentStatus.NOT_REQUIRED,
		markNotRequiredReason,
	});
}

export function checkStatus(requestId, providerId) {
	return axios.get(`${REQUESTS_URL}/${requestId}/${providerId}/check`);
}

export function setProviderPassword(requestId, providerId, password, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/set-password`, {
		token,
		password,
	});
}

export function getProviderData(requestId, providerId, password, isRefresh, token) {
	let url = `${REQUESTS_URL_V2}/${requestId}/${providerId}/get-details`;

	if (isRefresh) {
		url += `?refresh=true`;
	}

	return axios.post(url, {
		token,
		password,
	});
}

export function getProviderMessages(requestId, providerId, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/get-messages`, {
		token,
	});
}

export function addProviderMessage(requestId, providerId, message, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/add-message`, {
		token,
		message,
	});
}

export function updateDocument(requestId, providerId, documentId, token, data) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/${documentId}/update`, {
		...data,
		token,
	});
}

export function updateProviderStatus(requestId, providerId, status, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/update-provider-status`, {
		status,
		token,
	});
}

export function providerForgotPasswordRequest(requestId, providerId, email, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/forgot-password`, {
		email,
		token,
	});
}
export function providerResetPassword(requestId, providerId, code, password, token) {
	return axios.post(`${REQUESTS_URL}/${requestId}/${providerId}/update-password`, {
		code,
		password,
		token,
	});
}

export function resendRequest(requestId, providerIds) {
	return axios.post(`${REQUESTS_URL}/${requestId}/resend-request`, {
		providerIds,
	});
}

export function archiveRequests(requestIds, archiveStatus) {
	return axios.post(`${REQUESTS_URL}/archive-request`, {
		requestIds,
		archived: archiveStatus
	});
}

export function deleteRequests(requestIds) {
	return axios.post(`${REQUESTS_URL}/delete-request`, {
		requestIds,
	});
}

export function updateTitle(requestId, title) {
	return axios.patch(`${REQUESTS_URL}/${requestId}/update`, {
		title
	});
}

export function markRequestUnread(requestId) {
	return axios.patch(`${REQUESTS_URL}/${requestId}/update`, {
		updates: true,
	});
}

export function bulkDownload(providerId, documentId, token, convertToPDF) {
	// token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNDBiZjg1NjQwYTEwMWUxYWMzZGYxYyIsImNyZWF0ZWRBdCI6IjIwMjAtMDgtMThUMTk6MTA6MjQuMzA0WiIsImlhdCI6MTU5Nzc3NzgyNH0.pnaP3tAI-ST1p6B0hwuloUugFPaImIgk8oJ7wKvkHiw";
	// providerId = "5e4ed4b900ffca58eb32a10b";
	const domain = process.env.REACT_APP_ENV === 'production' ? `https://scheduler.kaddim.com` : `https://beta-scheduler.kaddim.com`;
	const params = {};

	if (documentId) {
		params.documentId = documentId;
	}
	if (convertToPDF) {
		params.pdf = "true";
	}

	return newAxiosInstance.get(`${domain}/api/v1/document-providers/${providerId}/bulk-download`, {
		params,
		headers: {
			Authorization: `Bearer ${token}`
		},
	});
}

export function bulkDownloadV2(providerId, elementId, token, convertToPDF) {
	const domain = process.env.REACT_APP_ENV === 'production' ? `https://scheduler.kaddim.com` : `https://beta-scheduler.kaddim.com`;
	const params = {};

	if (elementId) {
		params.elementId = elementId;
	}
	if (convertToPDF) {
		params.pdf = "true";
	}

	return newAxiosInstance.get(`${domain}/api/v2/document-providers/${providerId}/bulk-download`, {
		params,
		headers: {
			Authorization: `Bearer ${token}`
		},
	});
}

export function getElementComments(providerId, elementId) {
	return axios.get(`/api/v2/document-providers/${providerId}/${elementId}/comments`)
}

export function addElementComment(providerId, elementId, comment) {
	return axios.post(`/api/v2/document-providers/${providerId}/${elementId}/add-comment`, {
		comment
	});
}

export function updateElementStatus (requestId, providerId, elementId, payload) {
	return axios.post(`/api/v2/document-requests/${requestId}/${providerId}/${elementId}/status-update`, payload)
}


export function uploadFileToElement(providerId, elementId, file, existingFiles = []) {
	return axios.post(`/api/v2/document-providers/${providerId}/${elementId}/upload-file`, {
		fileName: file.name,
		extension: file.name.split('.').pop(),
		contentType: file.type,
		files: existingFiles,
	});
}

export function getLogsHistory(providerId) {
	return axios.get(`/api/v2/document-providers/${providerId}/request-logs`);
}
