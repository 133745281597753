import { useTheme } from '@material-ui/core';
import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';

import { getEmptyErrorObj, validateHeading } from '../../ElementPicker';
import ErrorMessage from '../ErrorMessage';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '..';
import AutoExpandInput from '../../../auto-expand-input';

const Heading = forwardRef((props, _ref) => {
	const { data, onChange } = props;
	const intl = useIntl();
	const theme = useTheme();
	
	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	const [error, setError] = useState({});

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	useEffect(() => {
		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
		};
		const error = validateHeading(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.HEADING)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	])

	return (
		<div>
			<div>
				<AutoExpandInput
					className="f-32px text-dark font-weight-medium mb-0 w-100"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.HEADING.TITLE.PLACEHOLDER"
						})
					}
				/>
			</div>

			{
				error.title && (
					<ErrorMessage message={error.title} />
				)
			}

			<div>
				<AutoExpandInput
					className="f-14px text-dark font-weight-regular mb-0 w-100 pb-0 break-word"
					style={{ color: theme.palette.extraColors.lightText }}
					onChange={(e) => setSubtitle(e.target.value)}
					value={subtitle}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.HEADING.SUBTITLE.PLACEHOLDER"
						})
					}
					allowHTML
				/>
			</div>
		</div>
	)
});

export default Heading;
