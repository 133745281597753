import axios from "axios";

export const NOTIFICATIONS_URL = "api/v1/notifications";

export function getNotifications(skip, limit) {
	return axios.get(NOTIFICATIONS_URL, {
		params: {
			skip,
			limit
		}
	});
}

export function getUnreadCount() {
	return axios.get(`${NOTIFICATIONS_URL}/count`, {
		params: {
			hasRead: false,
		}
	});
}

export function markNotificationAsRead(notificationIds) {
	return axios.post(`${NOTIFICATIONS_URL}/mark-as-read`, {
		notificationIds
	});
}

export function deleteNotification(notificationId) {
	return axios.delete(`${NOTIFICATIONS_URL}/${notificationId}`);
}