import React, { useCallback } from "react";
import { uuid } from "uuidv4";
import _ from "lodash"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useRouteMatch } from "react-router-dom";

import Element from "./Element";
import ElementPicker from "./ElementPicker";
import { doScrolling } from "../../../_metronic";

function List({
  refreshId,
  className,
  showElementPicker,
  handleElementPickerClose,
  elements,
  handleChange,
  handlePreview,
  providerMode,
  additionalData,
}) {
  const ELEMENT_LIST_FOOTER_ID = "element-list-footer";
  const routeMatch = useRouteMatch("/provider/:request/:provider");
  const updateElementList = useCallback((elements) => {
    handleChange(elements);
  }, [handleChange]);

  const updateElement = useCallback(
    (elementId, elementData) => {
      let hasActualChanges = false;

      const newElements = elements.map((element) => {
        const areElementsSame = _.isEqual(element, elementData);

        if (element.uuid === elementId && !areElementsSame) {  
          hasActualChanges = true;

          return {
            ...element,
            ...elementData,
          };
        }

        return element;
      });

      if (hasActualChanges) {
        updateElementList(newElements);
      }
    },
    [elements, updateElementList]
  );

  const updateData = useCallback(
    (elementId, dataObj) => {
      let elementObj = null;

      for (const element of elements) {
        if (element.uuid === elementId) {
          elementObj = {
            ...element,
            dataObj,
          };
          break;
        }
      }

      updateElement(elementId, elementObj);
    },
    [elements, updateElement]
  );

  const updateResponse = useCallback(
    (elementId, responseObj) => {
      let elementObj = null;

      for (const element of elements) {
        if (element.uuid === elementId) {
          elementObj = {
            ...element,
            responseObj,
          };
          break;
        }
      }

      updateElement(elementId, elementObj);
    },
    [elements, updateElement]
  );

  const addElement = useCallback(
    (type, dataObj, responseObj) => {
      const newElements = [
        ...elements,
        {
          uuid: uuid(),
          type,
          dataObj,
          responseObj,
          order: elements.length,
        },
      ];

      updateElementList(newElements);

      setTimeout(() => {
        doScrolling(`#${ELEMENT_LIST_FOOTER_ID}`);
      }, 1000);
    },
    [elements, updateElementList]
  );

  const onDragEnd = useCallback((result) => {
    const updatedElements = Array.from(elements);
    const [removed] = updatedElements.splice(result.source.index, 1);
    updatedElements.splice(result.destination.index, 0, removed);

    updatedElements.forEach((element, index) => {
      element.order = index;
    });

    updateElementList(updatedElements);
  }, [elements, updateElementList]);

  return (
    <div
      className={className}
    >
      <ElementPicker
        open={showElementPicker}
        handleClose={handleElementPickerClose}
        onAddElement={addElement}
      />
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {elements.map((element, index) => (
                <Draggable
                  isDragDisabled={routeMatch?.isExact}
                  key={element.uuid}
                  draggableId={element.uuid}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      id={`element-${element.uuid}`}
                      {...provided.draggableProps}
                    >
                      {
                        providerMode ? (
                          <Element.ReadOnly
                            element={element}
                            className={`${index !== 0 ? 'mt-3' : ''} ${index !== elements.length - 1 ? 'mb-3' : ''}`}
                            additionalData={additionalData}
                            onPreview={handlePreview}
                            onChangeElement={(elementObj) => updateElement(element.uuid, elementObj)}
                            onChangeResponse={(responseObj) => updateResponse(element.uuid, responseObj)}
                          />
                        ) : (
                          <Element.Updatable
                            dragHandleProps={provided.dragHandleProps}
                            element={element}
                            onChangeElement={(elementObj) => updateElement(element.uuid, elementObj)}
                            onChange={(dataObj) => updateData(element.uuid, dataObj)}
                            onChangeResponse={(responseObj) => updateResponse(element.uuid, responseObj)}
                            onPreview={handlePreview}
                            additionalData={additionalData}
                          />
                        )
                      }
                      {
                        index !== elements.length - 1 && (
                          <div className="px-3">
                            <div className="d-block d-md-none">
                              <hr className="my-4" />
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>


      {/* used as anchor position to scroll to when new element is added */}
      <div id={ELEMENT_LIST_FOOTER_ID} />
    </div>
  );
}

export default List;
