import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { ControlPointOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router";
import { Alert } from "reactstrap";
import { uuid } from "uuidv4";

import {
	validateElement,
	getEmptyErrorObj,
} from "../../../../../../common/dnd-element-list/ElementPicker";
import {
	loadTemplates,
	createTemplateV2,
	flagNames as templateFlagNames,
	V2_OPERATIONS,
	performOperation,
} from "../../../../../../store/modules/actions/templates.actions";
import { ElementList } from "../../common/ElementList";
import { EmailTemplate } from "../../common/EmailTemplate";
import Preview from "../../../../../../common/Preview";
import AutoExpandInput from "../../../../../../common/auto-expand-input";
import useUserRestriction from "../../../../../../common/hooks/user-restriction";
import { getFlags } from "../../../../../../store/modules/selectors/common.selector";
import { getTemplateOperation, getTemplatesList } from "../../../../../../store/modules/selectors/template.selector";

const useStyles = makeStyles((theme) => {
	const {
		layoutSizes: {
			elementListContainer: {
				leftMenuSpace,
				editContainerPadding,
				containerWidth,
				spaceBetweenContainerAddButton,
				addButtonWidth,
				safeSpace,
			}
		}
	} = theme;
	const finalWidth = leftMenuSpace +
		(editContainerPadding * 2) +
		containerWidth +
		spaceBetweenContainerAddButton +
		addButtonWidth +
		safeSpace;

	return {
		container: {
			maxWidth: containerWidth,

			[`@media (min-width: ${finalWidth}px)`]: {
				maxWidth: containerWidth + spaceBetweenContainerAddButton + addButtonWidth,

				'& .template-section': {
					maxWidth: containerWidth,
				}
			}
		},
		addElementButton: {
			position: "fixed",
			right: 10,
			bottom: 90,

			'& button': {
				background: 'white !important',
				width: addButtonWidth,
			},

			[`@media (min-width: ${finalWidth}px)`]: {
				marginLeft: 15,
				height: "fit-content",
				position: "sticky",
				bottom: 40,
				alignSelf: 'flex-end',
			}
		},
	};
});

function CreateNewTemplate(props) {
	const intl = useIntl();
	const dispatch = useDispatch();
	const history = useHistory();
	const [restrictions] = useUserRestriction();
	const classes = useStyles();

	const flags = useSelector(getFlags);
	const templates = useSelector(getTemplatesList);
	const operation = useSelector(getTemplateOperation);
	const fileToPreview = useMemo(() => {
		if (operation.action === V2_OPERATIONS.PREVIEW) {
			return operation.data;
		}
	}, [operation]);
	const [showElementPicker, setShowElementPicker] = useState(false);

	useEffect(() => {
		document.body.classList.add("modern-input-container");

		return () => {
			document.body.classList.remove("modern-input-container");
		}
	}, []);

	useEffect(() => {
		if (restrictions && !restrictions.features.FORM_REQUEST) {
			history.push('/templates/list');
		}
	}, [restrictions, history]);

	useEffect(() => {
		dispatch(loadTemplates());
	}, [dispatch]);

	const validate = useCallback((values) => {
		const error = {};

		if (!values.name) {
			error.name = intl.formatMessage({
				id: "TEMPLATE.NAME.VALIDATION.REQUIRED"
			});
		}
		else {
			const isAlreadyExists = templates.find(({ name, _id }) => name === values.name);

			if (isAlreadyExists) {
				error.name = intl.formatMessage({
					id: "TEMPLATE.NAME.VALIDATION.DUPLICATE"
				}, values);
			}
		}

		if (!values.elements || !values.elements.length) {
			error.elements = intl.formatMessage({
				id: "TEMPLATE.TOAST_ERROR.ELEMENTS.REQUIRED"
			});
		}
		else {
			for (let element of values.elements) {
				const elementError = validateElement(element);
				const emptyErrorObj = getEmptyErrorObj(element.type);
				const hasError = !_.isEqual(elementError, emptyErrorObj);

				if (hasError) {
					error.elements = intl.formatMessage({
						id: "TEMPLATE.TOAST_ERROR.ELEMENTS.ERROR"
					});
					break;
				}
			}
		}

		return error;
	}, [
		intl,
		templates,
	]);

	const submit = useCallback(async (values) => {
		const {
			data: {
				data: {
					template: {
						_id,
					}
				}
			}
		} = await dispatch(createTemplateV2(values));

		history.push(`/templates/${_id}`);
	}, [
		history,
		dispatch,
	])

	return (
		<div className={clsx("mx-20px modern-input-container", classes.container)}>
			<Formik
				initialValues={{
					uuid: uuid(),
					name: "",
					format: "MODERN",
					isActive: true,
					elements: [],
					bcc: [],
					emailMeta: {
						subject: "",
						content: ""
					}
				}}
				validate={validate}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={submit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => {
					return (
						<div>
							<div className="row">
								<div className="col-12 mb-20px">
									<AutoExpandInput
										name="name"
										className="f-24px font-weight-medium text-dark mr-2"
										onChange={handleChange}
										value={values.name}
										placeholder={
											intl.formatMessage({
												id: 'TEMPLATE.TITLE'
											})
										}
										onBlur={handleBlur}
									/>

									{
										errors.name && (
											<div className="text-danger">
												{errors.name}
											</div>
										)
									}
								</div>
							</div>

							<div>
								<div className="template-section bg-white p-20px rounded-top">
									<EmailTemplate />
								</div>

								<div className="d-flex">
									<div className="template-section flex-grow-1 rounded-bottom bg-white px-20px pb-20px">
										<ElementList
											additionalData={{
												fileUpload: {
													preventFileUpload: true,
												},
											}}
											showElementPicker={showElementPicker}
											handleElementPickerClose={() => setShowElementPicker(false)}
										/>
									</div>

									<div className={classes.addElementButton}>
										<button
											className="btn-elevate d-flex btn btn-light pl-2 f-16px px-20px"
											onClick={() => setShowElementPicker(true)}
										>
											<div className="mr-2">
												<ControlPointOutlined fontSize="large" />
											</div>
											<div>
												<FormattedMessage id="REQUEST.PROVIDER.TEMPLATE.CONTENT.ADD_ELEMENT" />
											</div>
										</button>
									</div>
								</div>
							</div>

							{
								!!errors.elements && (
									<div className="my-4 d-flex justify-content-center">
										<Alert color="danger" className="d-flex justify-content-center">
											{errors.elements}
										</Alert>
									</div>
								)
							}

							{
								!!flags.error[templateFlagNames.CREATE_V2] && (
									<div className="my-4 d-flex justify-content-center">
										<Alert color="danger" className="d-flex justify-content-center">
											{flags.error[templateFlagNames.CREATE_V2]}
										</Alert>
									</div>
								)
							}

							<div className="my-4 d-flex justify-content-center justify-content-md-end align-items-center">
								<div>
									<button
										type="button"
										onClick={handleSubmit}
										disabled={flags.loading[templateFlagNames.CREATE_V2]}
										className={clsx(
											"btn py-4 btn-primary btn-lg btn-block kt-login__btn-primary",
											{
												"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": flags.loading[templateFlagNames.CREATE_V2]
											}
										)}
									>
										<FormattedMessage id="TEMPLATE.CREATE.SUBMIT" />
									</button>
								</div>
							</div>

							{
								fileToPreview && (
									<Preview
										file={fileToPreview}
										handleClose={() => dispatch(performOperation())}
									/>
								)
							}
						</div>
					)
				}}
			</Formik>
		</div>
	)
};

export default CreateNewTemplate;
