import axios from "axios";

export const BASE_URL = "api/v1/templates";
export const BASE_URL_V2 = "api/v2/templates";
export const DEFAULT_TEMPLATES_URL = "/default-templates";

export function getUserTemplates() {
	return axios.get(BASE_URL);
}

export function getUserTemplate(templateId) {
	return axios.get(`${BASE_URL}/${templateId}`);
}

export function deleteUserTemplate(templateId) {
	return axios.delete(`${BASE_URL}/${templateId}`);
}

export function createTemplate(data) {
	return axios.post(BASE_URL, data);
}
export function createTemplateV2(data) {
	return axios.post(BASE_URL_V2, data);
}

export function updateTemplate(_id, data) {
	return axios.patch(`${BASE_URL}/${_id}`, data);
}
export function updateTemplateV2(_id, data) {
	return axios.patch(`${BASE_URL_V2}/${_id}`, data);
}

export function getSignedUrlForAttachment(templateId, documentId, attachmentId) {
	return axios.get(`${BASE_URL}/${templateId}/${documentId}/download?attachmentId=${attachmentId}`);
}