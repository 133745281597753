import React, {
	useCallback, useState,
} from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	useHistory,
} from "react-router-dom";
import {
	makeStyles,
	useMediaQuery,
	useTheme,
	Dialog,
	DialogContent,
	DialogTitle,
	Toolbar,
	AppBar,
	Typography,
	IconButton,
} from '@material-ui/core';
import {
	HourglassEmptyOutlined,
	DoneOutlined,
	Whatshot,
	Archive,
	CloseOutlined,
} from '@material-ui/icons';
import clsx from 'clsx';
import _ from "lodash";

import {
	updateFilter,
	unselectRequestItem,
} from '../../../../store/modules/actions/requests.actions';
import Constants from '../../../../common/constants';
import { Tab, Tabs } from "../../../../common/tabs";
import RequestList from "./RequestList";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { setUpgradeDialog } from "../../../../store/modules/actions/app.actions";
import { getRequestFilter, getRequestList, getRequestListSelection } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => {
	return {
		actionButton: {
			background: 'transparent',
			height: 30,
			borderRadius: 15,
			width: 30,
			'&:hover': {
				background: 'transparent',
			}
		},
		filterButtonIcon: {
			height: 48,
			maxWidth: 48,
			minWidth: 48,
			width: 48,
			borderRadius: 24,
			display: 'flex !important',
			alignItems: 'center',
			justifyContent: 'center',
		},
		filterButtonSmallIcon: {
			height: 24,
			maxWidth: 24,
			minWidth: 24,
			width: 24,
			borderRadius: 12,
			display: 'flex !important',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inProgressButtonIcon: {
			background: '#eee6fe',
			color: "#8b5cfa"
		},
		completedButtonIcon: {
			background: "#daf6f9",
			color: "#41bdc9"
		},
		filterButton: {
			borderRadius: "8px !important",
		},
		tabTitle: {
			fontSize: 12,
			[theme.breakpoints.up('sm')]: {
				fontSize: 16,
			},
		},
		table: {
			borderCollapse: "separate",
			borderSpacing: "0 7px",
		},
		searchInputBox: {
			[theme.breakpoints.down('xs')]: {
				flexDirection: "column",
			}
		},
		searchInput: {
			[theme.breakpoints.down('xs')]: {
				flexDirection: "column",
				boxSizing: "border-box",
				minHeight: 50,
			}
		},
		searchEndAdornments: {
			minWidth: 280,
			[theme.breakpoints.down('xs')]: {
				minWidth: 250,
				width: "100%",
				marginBottom: 15,
			}
		},
		dateFilterContainer: {
			[`@media (max-width: 335px)`]: {
				flexDirection: "column"
			}
		},
		searchIconContainer: {
			[`@media (max-width: 335px)`]: {
				display: "none"
			}
		},
		dateFilter: {
			width: 100,
			color: theme.palette.primary.main,
			"& input::placeholder": {
				opacity: 1,
				color: theme.palette.primary.main
			},
			"& input:disabled": {
				opacity: 1,
				color: theme.palette.primary.main
			},
			"& .MuiInputBase-root.MuiInput-root.MuiInput-underline::before": {
				borderBottomStyle: "none"
			},
			"& .MuiInputAdornment-positionEnd": {
				marginLeft: 0,

				"button": {
					padding: 0
				}
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: 15,
			}
		},
		tableHeader: {
			height: 50,
		},
		tableBody: {

		},
		requestRow: {
			boxShadow: `0px 1px 0px ${theme.palette.extraColors.gray}`,
			minHeight: 50,
		},
		progressBar: {
			width: "63%"
		},
		cancelledRequest: {
			color: "#C8C8C8"
		},
		requestListFilter: {
			border: "1px solid rgba(49, 94, 255, 0.4)",
			boxShadow: "0px 4px 16px rgba(46, 91, 255, 0.29)"
		},
		requestMenuOption: {
			minWidth: 35,
		},
		activeFilterCount: {
			height: 24,
			width: 24,
			borderRadius: 12,
		},
		copyLinkButton: {
			height: 24,
			width: 24,
			borderRadius: 12,
		},
	}
});

const TABS = {
	NORMAL: "NORMAL",
	ARCHIVED: "ARCHIVED"
};

function List() {
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles();
	
	const [restrictions, isInReadOnlyMode] = useUserRestriction();

	const requests = useSelector(getRequestList);
	const filter = useSelector(getRequestFilter);
	const requestSelection = useSelector(getRequestListSelection);
	
	const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
	const [showSelection, setShowSelection] = useState(false);
	const {
		inProgressCount,
		completedCount
	} = requests.reduce((p, request, index) => {
		const isInProgress = request.status === Constants.RequestStatus.IN_PROGRESS;
		const isCompleted = request.status === Constants.RequestStatus.COMPLETED;

		if (isInProgress) {
			p.inProgressCount++;
		}
		else if (isCompleted) {
			p.completedCount++;
		}

		return p;
	}, {
		inProgressCount: 0,
		completedCount: 0
	});

	const handleCreate = useCallback(() => {
		if (isInReadOnlyMode || restrictions.requestAllowed <= 0) {
			dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
			return;
		}

		if (restrictions.features.FORM_REQUEST) {
			setShowSelection(true);
		}
		else {
			history.push('/requests/create/legacy');
		}
	}, [
		dispatch,
		isInReadOnlyMode,
		restrictions,
		history,
	])

	const switchToTab = useCallback((tab) => {
		_.forEach(requestSelection.requests, (request) => {
			dispatch(unselectRequestItem(request))
		});

		if (tab === TABS.ARCHIVED) {
			dispatch(updateFilter({
				fromDate: null,
				toDate: null,
				order: "asc",
				orderBy: "index",
				statusFilters: [],
				archived: true,
				unreadOnly: false,
				updatedOnly: false,
			}));
		}
		else if (tab === TABS.NORMAL) {
			dispatch(updateFilter({
				fromDate: null,
				toDate: null,
				order: "asc",
				orderBy: "index",
				statusFilters: [],
				archived: false,
				unreadOnly: false,
				updatedOnly: false,
			}));
		}
	}, [
		dispatch,
		requestSelection
	])

	return (
		<div className="container request-list-container">
			<div className='row mb-30 action-button-container'>
				<div className='col-12 col-md-4 mb-4 mb-md-0 pl-0 pr-0 pr-md-3'>
					<button
						className="btn-block btn-lg btn btn-primary py-3 px-4 h-100"
						disabled={!restrictions}
						onClick={handleCreate}
					>
						<FormattedMessage id="REQUESTS.CREATE" />
					</button>
				</div>
				<div className='col-6 col-md-4 pl-0 pl-md-3'>
					<div
						className={`btn d-flex bg-white btn-lg btn-block p-10 cursor-default h-100 ${classes.filterButton}`}
					>
						<div className="w-100 align-items-center d-none d-sm-flex">
							<div className={`px-2 d-none d-sm-block flex-1 ${classes.filterButtonIcon} ${classes.inProgressButtonIcon}`}>
								<HourglassEmptyOutlined />
							</div>
							<div className="flex-2">
								<h2 className="f-30px font-weight-regular m-0">{inProgressCount}</h2>
							</div>
							<div className="flex-2 f-15 text-primary-dark">
								<FormattedMessage id="REQUESTS.FILTER.ONLY_IN_PROGRESS" />
							</div>
						</div>

						<div className="w-100 d-flex flex-column align-items-center d-sm-none h-100 pb-5px">
							<div className="d-flex align-items-center justify-content-left w-100 mb-10px">
								<div className={`px-2 ${classes.filterButtonSmallIcon} ${classes.inProgressButtonIcon}`}>
									<HourglassEmptyOutlined fontSize="small" />
								</div>
								<div className="ml-2">
									<h6 className="f-20px font-weight-regular m-0">{inProgressCount}</h6>
								</div>
							</div>
							<div className="w-100">
								<div className="text-left f-12px text-primary-dark">
									<FormattedMessage id="REQUESTS.FILTER.ONLY_IN_PROGRESS" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-6 col-md-4 pr-0'>
					<div
						className={`btn-block cursor-default d-flex btn bg-white p-10 h-100 btn-lg ${classes.filterButton}`}
					>
						<div className="w-100 align-items-center d-none d-sm-flex">
							<div className={`px-2 d-none d-sm-block flex-1 ${classes.filterButtonIcon} ${classes.completedButtonIcon}`}>
								<DoneOutlined />
							</div>
							<div className="flex-2">
								<h2 className="f-30px font-weight-regular m-0">{completedCount}</h2>
							</div>
							<div className="flex-2 text-primary-dark f-15">
								<FormattedMessage id="REQUESTS.FILTER.ONLY_COMPLETED" />
							</div>
						</div>

						<div className="w-100 d-flex flex-column align-items-center d-sm-none h-100 pb-5px">
							<div className="d-flex align-items-center justify-content-left w-100 mb-10px">
								<div className={`px-2 ${classes.filterButtonSmallIcon} ${classes.completedButtonIcon}`}>
									<DoneOutlined fontSize="small" />
								</div>
								<div className="ml-2">
									<h6 className="f-20px font-weight-regular m-0">{completedCount}</h6>
								</div>
							</div>
							<div className="w-100">
								<div className="text-primary-dark text-left f-12px">
									<FormattedMessage id="REQUESTS.FILTER.ONLY_COMPLETED" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mb-40px">
				<Tabs
					value={filter.archived ? TABS.ARCHIVED : TABS.NORMAL}
					variant={isDownSm ? "fullWidth" : null}
					onChange={(e, nv) => switchToTab(nv)}
				>
					<Tab
						classes={{
							root: "opacity-100"
						}}
						value={TABS.NORMAL}
						label={
							<div
								className={
									clsx(
										"d-flex justify-content-center align-items-center",
										classes.tabTitle,
										{
											"text-primary": !filter.archived,
											"text-primary-dark opacity-100": filter.archived,
										}
									)
								}
								style={{
									minWidth: 275,
								}}
							>
								<div className="mr-2">
									<Whatshot />
								</div>
								<div>
									<FormattedMessage id="REQUEST.LIST.TABS.NORMAL" />
								</div>
							</div>
						}
					/>
					<Tab
						classes={{
							root: "opacity-100"
						}}
						value={TABS.ARCHIVED}
						label={
							<div
								className={
									clsx(
										"d-flex justify-content-center align-items-center",
										classes.tabTitle,
										{
											"text-primary": filter.archived,
											"text-primary-dark": !filter.archived,
										}
									)
								}
								style={{
									minWidth: 275,
								}}
							>
								<div className="mr-2">
									<Archive />
								</div>
								<div>
									<FormattedMessage id="REQUEST.LIST.TABS.ARCHIVE" />
								</div>
							</div>
						}
					/>
				</Tabs>
			</div>

			<RequestList />

			<Dialog
				open={showSelection}
				onClose={() => setShowSelection(false)}
			>
				<DialogTitle className="p-0">
					<AppBar
						elevation={0}
						position="static"
						classes={{
							root: "rounded-top bg-light"
						}}
					>
						<Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
							<div className="flex-grow-1">
								<Typography
									variant="span"
									className="f-16px"
								>
									<FormattedMessage id="REQUESTS.CREATE" />
								</Typography>
							</div>
							<div>
								<IconButton onClick={() => setShowSelection(false)}>
									<CloseOutlined />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
				</DialogTitle>
				<DialogContent>
					<div className="mt-10px f-14px text-primary-dark">
						<FormattedMessage id="REQUEST.CREATE.SELECTION.TITLE" />:
					</div>
					<div className="row my-20px">
						<div className="col-12 col-md-6 mb-4 mb-md-0">
							<div
								className="rounded p-15px border shadow"
								style={{ background: theme.palette.extraColors.backgroundBlue }}
							>
								<div className="f-18px text-dark">
									<FormattedMessage id="REQUEST.CREATE.SELECTION.NEW.TITLE" />
								</div>

								<div className="f-14px font-weight-light text-dark mb-20px">
									<FormattedMessage id="REQUEST.CREATE.SELECTION.NEW.SUBTITLE" />
								</div>

								<div>
									<button
										className="btn bg-primary text-light f-16px"
										onClick={() => history.push("/requests/create/new")}
									>
										<FormattedMessage id="REQUEST.CREATE.SELECTION.NEW.SUBMIT" />
									</button>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6">
							<div className="rounded p-15px border bg-white">
								<div className="f-18px">
									<FormattedMessage id="REQUEST.CREATE.SELECTION.LEGACY.TITLE" />
								</div>

								<div className="f-14px mb-10px font-weight-light mb-20px">
									<FormattedMessage id="REQUEST.CREATE.SELECTION.LEGACY.SUBTITLE" />
								</div>

								<div>
									<button
										className="btn text-dark f-16px"
										style={{ background: theme.palette.extraColors.backgroundBlue }}
										onClick={() => history.push("/requests/create/legacy")}
									>
										<FormattedMessage id="REQUEST.CREATE.SELECTION.LEGACY.SUBMIT" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default List;
