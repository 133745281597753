import React, { Fragment, useState, useEffect, useCallback } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  TableSortLabel,
  TablePagination,
  makeStyles,
  Checkbox,
  Popper,
  useMediaQuery,
  ClickAwayListener,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardArrowDownOutlined,
  Archive,
  FilterList,
  FiberManualRecord,
  MoreVert,
  OfflinePin,
  Link as LinkIcon,
  NoSim,
  Unarchive,
  Close,
  NotificationsActive,
  Error,
	DeleteForever,
} from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import clsx from "clsx";
import _ from "lodash";

import {
  loadRequests,
  flagNames,
  setPage,
  updateFilter,
  selectRequestItem,
  unselectRequestItem,
  archiveRequests,
  completeRequest,
  cancelRequest,
  markRequestAsUnread,
  moveRequestToInProgress,
	deleteRequests,
} from "../../../../store/modules/actions/requests.actions";
import Constants from "../../../../common/constants";
import Input from "../../../../common/Input";
import useDebounce from "../../../../common/useDebounce";
import {
  PrimaryBorderLinearProgress,
  SuccessBorderLinearProgress,
  SecondaryBorderLinearProgress,
} from "../../../../common/LinearProgress";
import Confirm, { Color } from "../../../../common/modals/confirm";
import { copyTextToClipboard } from "../../../../../_metronic";
import { useSnackbar } from "notistack";
import { CircleCheckIcon, SettingsIcon } from "../../../../common/icons";
import RequestListFilter from "./RequestListFilter";
import { updateSettings } from "../../../../store/modules/actions/auth.actions";
import LightTooltip from "../../../../common/LightTooltip";
import { getUserLayoutSettings, isUserSuperAdmin } from "../../../../store/modules/selectors/account.selector";
import { getRequestFilter, getRequestList, getRequestListPage, getRequestListSelection } from "../../../../store/modules/selectors/request.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const DOCUMENT_SELECTOR_MEDIA_QUERY = "(min-width:550px)";

const useStyles = makeStyles((theme) => {
  return {
    table: {
      borderCollapse: "separate",
      borderSpacing: "0 7px",
      overflowX: "auto",
    },
    searchInputBox: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    searchInput: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        boxSizing: "border-box",
        minHeight: 50,
      },
    },
    requestRow: {
      boxShadow: `0px 1px 0px ${theme.palette.extraColors.gray}`,
      minHeight: 50,
    },
    progressBar: {
      width: "63%",
    },
    cancelledRequest: {
      color: "#C8C8C8",
    },
    requestMenuOption: {
      minWidth: 35,
    },
    activeFilterCount: {
      height: 24,
      width: 24,
      borderRadius: 12,
    },
    copyLinkButton: {
      height: 24,
      width: 24,
      borderRadius: 12,
    },
    minWidth100p: {
      minWidth: "100%",
    },
  };
});

function RequestList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  
  const isSuperAdmin = useSelector(isUserSuperAdmin);
  const layoutSettings = useSelector(getUserLayoutSettings);
  const filter = useSelector(getRequestFilter);
  const flags = useSelector(getFlags);
  const requests = useSelector(getRequestList);
  const page = useSelector(getRequestListPage);
  const selection = useSelector(getRequestListSelection);

  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  const [search, setSearch] = useState(filter.search);

  // this setting is save now in layout settings under user profile so no need to maintain it in reducer anymore
  const [pageSize, setPageSize] = useState(0);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [requestsToArchive, setRequestsToArchive] = useState([]);
  const [requestsToDelete, setRequestsToDelete] = useState([]);
  const [requestsToMoveToInProgress, setRequestsToMoveToInProgress] = useState(
    []
  );
  const [requestsToCancel, setRequestsToCancel] = useState([]);
  const [requestsToComplete, setRequestsToComplete] = useState([]);
  const [todayDate] = useState(new Date());
  const debouncedSearchTerm = useDebounce(search, 500);
  const totalSelectedRequestsCount = Object.keys(selection.requests).length;
  const isWideEnoughForDocumentSelector = useMediaQuery(
    DOCUMENT_SELECTOR_MEDIA_QUERY
  );

  useEffect(() => {
    dispatch(loadRequests());
  }, [dispatch, filter]);

  useEffect(() => {
    const pageSize = layoutSettings?.defaultPageSize ?? 10;

    setPageSize(pageSize);
  }, [layoutSettings]);

  useEffect(() => {
    if (filter.search !== debouncedSearchTerm) {
      dispatch(updateFilter({ search: debouncedSearchTerm }));
    }
  }, [dispatch, filter.search, debouncedSearchTerm]);

  const clearAllFilters = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      dispatch(
        updateFilter({
          statusFilters: [],
          updatedOnly: false,
          unreadOnly: false,
        })
      );
    },
    [dispatch]
  );

  const handleMobileMenuOpen = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setMobileMenuAnchorEl(event.currentTarget);
    },
    [setMobileMenuAnchorEl]
  );

  const handleMobileMenuClose = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setMobileMenuAnchorEl(null);
    },
    [setMobileMenuAnchorEl]
  );

  const markRequestUnread = useCallback(
    async (request) => {
      await dispatch(markRequestAsUnread(request._id));
    },
    [dispatch]
  );
  const handleBatchArchive = useCallback(() => {
    const requests = _.values(selection.requests);

    if (requests.length) {
      setRequestsToArchive(requests);
    }
  }, [selection]);
	const handleBatchDelete = useCallback(() => {
    const requests = _.values(selection.requests);

    if (requests.length) {
      setRequestsToDelete(requests);
    }
  }, [selection]);
  const handleBatchCancel = useCallback(() => {
    const requests = _.values(selection.requests);

    if (requests.length) {
      setRequestsToCancel(requests);
    }
  }, [selection]);
  const handleBatchCancelSubmit = useCallback(
    async (wasSubmitted) => {
      if (wasSubmitted) {
        for (let i = 0; i < requestsToCancel.length; i++) {
          const request = requestsToCancel[i];
          dispatch(unselectRequestItem(request));
          await dispatch(cancelRequest(request._id));
        }
      }

      setRequestsToCancel([]);
    },
    [dispatch, requestsToCancel]
  );
  const handleBatchCompleteSubmit = useCallback(
    async (wasSubmitted) => {
      if (wasSubmitted) {
        for (let i = 0; i < requestsToComplete.length; i++) {
          const request = requestsToComplete[i];
          await dispatch(completeRequest(request._id));
        }
      }

      setRequestsToComplete([]);
    },
    [dispatch, requestsToComplete]
  );
  const handleBatchArchiveSubmit = useCallback(
    async (wasSubmitted) => {
      if (wasSubmitted) {
        const isShowingNormalRequest = !filter.archived;
        await dispatch(
          archiveRequests(requestsToArchive, isShowingNormalRequest)
        );

        requestsToArchive.forEach((request) => {
          dispatch(unselectRequestItem(request));
        });
      }

      setRequestsToArchive([]);
    },
    [requestsToArchive, filter, dispatch]
  );
	const handleBatchDeleteSubmit = useCallback(
    async (wasSubmitted) => {
      if (wasSubmitted) {
        const { status } = await dispatch(
          deleteRequests(requestsToDelete)
        );

				if (status !== 200) return;

        requestsToDelete.forEach((request) => {
          dispatch(unselectRequestItem(request));
        });
      }

      setRequestsToDelete([]);
    },
    [dispatch, requestsToDelete]
  );
  const handleBatchInProgressSubmit = useCallback(
    async (wasSubmitted) => {
      if (wasSubmitted) {
        for (let i = 0; i < requestsToMoveToInProgress.length; i++) {
          const request = requestsToMoveToInProgress[i];
          await dispatch(moveRequestToInProgress(request._id));
        }
      }

      setRequestsToMoveToInProgress([]);
    },
    [requestsToMoveToInProgress, dispatch]
  );

  const handleRootCheckboxClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const shouldSelectAll =
        selection.selectedCountFromCurrentList !== requests.length;

      requests.forEach((request) => {
        if (shouldSelectAll) {
          dispatch(selectRequestItem(request));
        } else {
          dispatch(unselectRequestItem(request));
        }
      });
    },
    [requests, selection, dispatch]
  );

  const handlePageSizeChange = useCallback(
    (newPageSize) => {
      dispatch(
        updateSettings({
          layoutSettings: {
            ...layoutSettings,
            defaultPageSize: newPageSize,
          },
        })
      );
    },
    [dispatch, layoutSettings]
  );

  const totalActiveFiltersCount =
    filter.statusFilters.length +
    (filter.updatedOnly ? 1 : 0) +
    (filter.unreadOnly ? 1 : 0);
  const rows = requests.map((element, index) => {
    const documentProvidersName = element.documentProviders
      .map(({ firstName, lastName }) => {
        return [firstName, lastName].join(" ");
      })
      .join(" & ");
    const requesterName = element.user
      ? `${element.user.firstName} ${element.user.lastName}`
      : "";

    return {
      ...element,
      index,
      requesterName,
      documentProvidersName,
    };
  });

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(property) {
    const isDesc = filter.orderBy === property && filter.order === "desc";

    dispatch(
      updateFilter({
        order: isDesc ? "asc" : "desc",
        orderBy: property,
      })
    );
  }

  let rowWidth = {
    selection: 6,
    index: 6,
    title: 11,
    providerName: 25,
    createdAt: 12,
    status: 32,
    action: 8,
  };
  let minWidth = {
    selection: 65,
    index: 65,
    title: 250,
    requesterName: 250,
    providerName: 250,
    createdAt: 120,
    status: 300,
    action: 100,
  };

  if (isSuperAdmin) {
    rowWidth = {
      selection: 6,
      index: 6,
      title: 11,
      requesterName: 15,
      providerName: 15,
      createdAt: 12,
      status: 32,
      action: 8,
    };
  }

  const headRows = [
    {
      id: "selection",
      width: rowWidth.selection,
      className: "py-15px pl-10px pr-15px",
      minWidth: minWidth.selection,
    },
    {
      id: "index",
      label: "ID",
      className: "d-none d-md-table-cell p-15px",
      width: rowWidth.index,
      minWidth: minWidth.index,
    },
    {
      id: "title",
      label: "Title",
      className: "d-none d-md-table-cell p-15px",
      width: rowWidth.title,
      minWidth: minWidth.title,
    },
    {
      id: "createdAt",
      label: "Created",
      className: "d-none d-md-table-cell p-15px",
      width: rowWidth.createdAt,
      minWidth: minWidth.createdAt,
    },
    {
      id: "documentProvidersName",
      label: "Document Provider",
      className: "p-15px",
      width: rowWidth.providerName,
      minWidth: minWidth.providerName,
    },
    {
      id: "status",
      label: "Status",
      className: "p-15px",
      width: rowWidth.status,
      minWidth: minWidth.status,
    },
    {
      id: "actions",
      label: "Actions",
      className: "p-15px",
      minWidth: minWidth.action,
    },
  ];

  if (isSuperAdmin) {
    headRows.splice(2, 0, {
      id: "requesterName",
      label: "Requester",
      width: rowWidth.requesterName,
      minWidth: minWidth.requesterName,
      className: "d-none d-md-table-cell p-15px",
    });
  }

  const loadingList = flags.loading[flagNames.REQUESTS];

  return (
    <div>
      <div className="row">
        <div className="d-flex col-12 d-flex justify-content-end flex-column flex-lg-row">
          <div className="d-flex flex-grow-1 flex-column flex-md-row">
            <div className="flex-grow-1">
              <Input
                type="text"
                variant="outlined"
                fullWidth
                className="rounded-card"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                inputProps={{
                  className: "p-15px bg-transparent",
                }}
                InputProps={{
                  classes: {
                    root: `blue-border ${classes.searchInputBox}`,
                    input: classes.searchInput,
                  },
                }}
                endAdornment={
                  <div
                    className={clsx("cursor-pointer px-1 py-3", { "d-none": !search })}
                    onClick={() => setSearch("")}
                  >
                    <Close />
                  </div>
                }
                placeholder={intl.formatMessage({ id: "REQUEST.LIST.SEARCH" })}
              />
            </div>

            <div className="d-flex mt-4 mt-md-0">
              <div className="ml-0 ml-md-4 flex-grow-1">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    open={fromDateOpen}
                    onOpen={() => setFromDateOpen(true)}
                    onClose={() => setFromDateOpen(false)}
                    className="h-100 dark-custom-input"
                    InputProps={{
                      classes: {
                        root: "h-100 blue-border",
                      },
                    }}
                    PopoverProps={{
                      PaperProps: {
                        classes: {
                          root: "kaddim-date-picker-calendar"
                        }
                      }
                    }}
                    inputProps={{
                      style: {
                        background: "transparent",
                      },
                    }}
                    KeyboardButtonProps={{
                      size: "small",
                    }}
                    keyboardIcon={
                      <div className="d-flex align-items-center pointer-events-none">
                        <div
                          className="pointer-events-all cursor-pointer text-dark"
                          onClick={() => setFromDateOpen(true)}
                        >
                          <KeyboardArrowDownOutlined />
                        </div>
                        {filter.fromDate && (
                          <div
                            className="ml-2 pointer-events-all cursor-pointer"
                            onClick={() =>
                              dispatch(updateFilter({ fromDate: null }))
                            }
                          >
                            <Close color="error" />
                          </div>
                        )}
                      </div>
                    }
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    value={filter.fromDate}
                    placeholder={intl.formatMessage({
                      id: "REQUEST.FILTER.START_DATE",
                    })}
                    onChange={(date) =>
                      dispatch(updateFilter({ fromDate: date }))
                    }
                    maxDate={filter.toDate || todayDate}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div className="ml-4 flex-grow-1">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    open={toDateOpen}
                    onOpen={() => setToDateOpen(true)}
                    onClose={() => setToDateOpen(false)}
                    className="h-100 dark-custom-input"
                    InputProps={{
                      classes: {
                        root: "h-100 blue-border",
                      },
                    }}
                    PopoverProps={{
                      PaperProps: {
                        classes: {
                          root: "kaddim-date-picker-calendar"
                        }
                      }
                    }}
                    inputProps={{
                      style: {
                        background: "transparent",
                      },
                    }}
                    variant="inline"
                    inputVariant="outlined"
                    fullWidth
                    value={filter.toDate}
                    KeyboardButtonProps={{
                      size: "small",
                    }}
                    keyboardIcon={
                      <div className="d-flex align-items-center pointer-events-none">
                        <div
                          className="pointer-events-all cursor-pointer text-dark"
                          onClick={() => setToDateOpen(true)}
                        >
                          <KeyboardArrowDownOutlined />
                        </div>
                        {filter.toDate && (
                          <div
                            className="ml-2 pointer-events-all cursor-pointer"
                            onClick={() =>
                              dispatch(updateFilter({ toDate: null }))
                            }
                          >
                            <Close color="error" />
                          </div>
                        )}
                      </div>
                    }
                    placeholder={intl.formatMessage({
                      id: "REQUEST.FILTER.END_DATE",
                    })}
                    onChange={(date) =>
                      dispatch(updateFilter({ toDate: date }))
                    }
                    minDate={filter.fromDate || undefined}
                    maxDate={todayDate}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-30px mb-4 mb-md-0 d-md-flex col-12 flex-md-row d-block px-0">
          <div className="col-12 col-md-5 d-flex align-items-center">
            <div
              className="bg-white rounded border-blue d-flex align-items-center p-10px cursor-pointer cursor-pointer"
              onClick={(e) =>
                setFilterAnchorEl(filterAnchorEl ? null : e.currentTarget)
              }
            >
              <div>
                <FilterList color="primary" />
              </div>
              <div className="text-primary flex-grow-1 px-3">
                Filter Requests
              </div>
              <div className="d-flex align-items-center">
                {totalActiveFiltersCount > 0 && (
                  <div
                    className={clsx(
                      classes.activeFilterCount,
                      "bg-danger d-flex justify-content-center align-items-center text-white mx-2"
                    )}
                  >
                    {totalActiveFiltersCount}
                  </div>
                )}
                <KeyboardArrowDownOutlined color="primary" />
              </div>
            </div>
            <Popper
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              placement={
                isWideEnoughForDocumentSelector ? "bottom-start" : "bottom"
              }
              transition
            >
              <ClickAwayListener onClickAway={() => setFilterAnchorEl(null)}>
                <div>
                  <RequestListFilter />
                </div>
              </ClickAwayListener>
            </Popper>

            <div className="ml-3" onClick={clearAllFilters}>
              <div className="cursor-pointer text-primary fw-500 text-decoration-underline">
                <FormattedMessage id="REQUEST.ACTION.CLEAR_FILTERS" />
              </div>
            </div>
          </div>

          {/* mobile control for select all requests */}
          <div className="col-12 flex-grow-1 d-flex d-md-none mt-2">
            <Checkbox
              className="px-0 mr-2 mobile-control"
              color="primary"
              onClick={handleRootCheckboxClicked}
              checked={selection.selectedCountFromCurrentList === rows.length}
              indeterminate={
                selection.selectedCountFromCurrentList > 0 &&
                selection.selectedCountFromCurrentList !== rows.length
              }
            />

            <div
              className={clsx(
                "cursor-pointer align-items-center mr-3",
                { "d-none": totalSelectedRequestsCount === 0 },
                { "d-flex": totalSelectedRequestsCount > 0 }
              )}
            >
              <strong>
                <FormattedMessage
                  id="REQUEST.BATCH.COUNT"
                  values={{ count: totalSelectedRequestsCount }}
                />
              </strong>
            </div>

            <div
              className={clsx(
                "text-muted cursor-pointer align-items-center",
                { "d-none": totalSelectedRequestsCount === 0 },
                { "d-flex": totalSelectedRequestsCount > 0 }
              )}
              onClick={handleMobileMenuOpen}
            >
              <MoreVert />
            </div>

            <Menu
              anchorEl={mobileMenuAnchorEl}
              keepMounted
              open={!!mobileMenuAnchorEl}
              onClose={handleMobileMenuClose}
            >
              <MenuItem onClick={handleBatchArchive} dense>
                <FormattedMessage
                  id={
                    filter.archived
                      ? "REQUEST.ACTION.BATCH_UNARCHIVE"
                      : "REQUEST.ACTION.BATCH_ARCHIVE"
                  }
                >
                  {(text) => (
                    <ListItemText
                      primary={text}
                      classes={{ primary: "f-12px" }}
                    />
                  )}
                </FormattedMessage>
              </MenuItem>

              {!filter.archived && (
                <MenuItem onClick={handleBatchCancel} dense>
                  <FormattedMessage id="REQUEST.ACTION.BATCH_CANCEL">
                    {(text) => (
                      <ListItemText
                        primary={text}
                        classes={{ primary: "f-12px" }}
                      />
                    )}
                  </FormattedMessage>
                </MenuItem>
              )}
            </Menu>
          </div>

          <div className="col-12 col-md-2 align-items-center justify-content-center d-none d-md-flex">
            {totalSelectedRequestsCount > 0 && (
              <div className="text-center">
                <FormattedMessage
                  id="REQUEST.BATCH.COUNT"
                  values={{ count: totalSelectedRequestsCount }}
                />
              </div>
            )}

						{
							filter.archived ? (
								<Fragment>
									<div
										className={clsx("mx-2", {
											"text-muted": totalSelectedRequestsCount === 0,
											"cursor-pointer": totalSelectedRequestsCount > 0,
										})}
										onClick={handleBatchArchive}
									>
										<FormattedMessage id="REQUEST.ACTION.BATCH_UNARCHIVE_TOOLTIP">
											{(text) => {
												return (
													<Tooltip title={text}>
														<Unarchive fontSize="large" />
													</Tooltip>
												);
											}}
										</FormattedMessage>
									</div>

									<div
										className={clsx("mx-2", {
											"text-muted": totalSelectedRequestsCount === 0,
											"cursor-pointer": totalSelectedRequestsCount > 0,
										})}
										onClick={handleBatchDelete}
									>
										<FormattedMessage id="REQUEST.ACTION.BATCH_DELETE_TOOLTIP">
											{(text) => {
												return (
													<Tooltip title={text}>
														<DeleteForever fontSize="large" />
													</Tooltip>
												);
											}}
										</FormattedMessage>
									</div>
								</Fragment>
							) : (
								<Fragment>
									<div
										className={clsx("mx-2", {
											"text-muted": totalSelectedRequestsCount === 0,
											"cursor-pointer": totalSelectedRequestsCount > 0,
										})}
										onClick={handleBatchArchive}
									>
										<FormattedMessage
											id="REQUEST.ACTION.BATCH_ARCHIVE_TOOLTIP"
										>
											{(text) => {
												return (
													<Tooltip title={text}>
														<Archive fontSize="large" />
													</Tooltip>
												);
											}}
										</FormattedMessage>
									</div>
									<div
										className={clsx("mx-2", {
											"text-muted": totalSelectedRequestsCount === 0,
											"cursor-pointer": totalSelectedRequestsCount > 0,
										})}
										onClick={handleBatchCancel}
									>
										<FormattedMessage id="REQUEST.ACTION.BATCH_CANCEL_TOOLTIP">
											{(text) => {
												return (
													<Tooltip title={text}>
														<NoSim fontSize="large" />
													</Tooltip>
												);
											}}
										</FormattedMessage>
									</div>
								</Fragment>
							)
						}
          </div>

          <div className="col-12 d-flex justify-content-center justify-content-md-end flex-grow-1 mt-3 mt-md-0 col-md-5 pr-0">
            {rows.length > 0 && !loadingList && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => dispatch(setPage(newPage))}
                onRowsPerPageChange={(e) =>
                  handlePageSizeChange(e.target.value)
                }
              />
            )}
          </div>
        </div>
      </div>

      {loadingList ? (
        <div className="w-100 h-100 d-flex justify-content-center mt-5">
          <div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
        </div>
      ) : rows.length > 0 ? (
        <React.Fragment>
          <div className={clsx(classes.table, "d-none d-md-block")}>
            <div className={clsx("d-inline-flex mt-2", classes.minWidth100p)}>
              {headRows.map((row) => (
                <div
                  key={row.id}
                  style={{
                    width: `${row.width}%`,
                    minWidth: row.minWidth || 0,
                  }}
                  className={`border-bottom-0 ${row.className}`}
                >
                  {typeof row.label === "string" ? (
                    <TableSortLabel
                      active={filter.orderBy === row.id}
                      direction={filter.order}
                      onClick={() => handleRequestSort(row.id)}
                      className="text-primary-dark f-12px"
                      classes={{ icon: "text-primary-dark" }}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    <Checkbox
                      className="p-0"
                      color="primary"
                      onClick={handleRootCheckboxClicked}
                      checked={
                        selection.selectedCountFromCurrentList === rows.length
                      }
                      indeterminate={
                        selection.selectedCountFromCurrentList > 0 &&
                        selection.selectedCountFromCurrentList !== rows.length
                      }
                    />
                  )}
                </div>
              ))}
            </div>

            <div
              className={clsx(
                "d-inline-flex flex-column mt-2",
                classes.minWidth100p
              )}
            >
              {stableSort(rows, getSorting(filter.order, filter.orderBy))
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((row, index) => {
                  return (
                    <div
                      className={clsx(
                        "d-flex bg-white mb-10px",
                        classes.requestRow,
                        { "cursor-pointer": !filter.archived }
                      )}
                      key={index}
                      onClick={() => history.push(`/requests/${row._id}`)}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{
                          width: `${rowWidth.selection}%`,
                          minWidth: minWidth.selection,
                        }}
                      >
                        <div className="d-flex align-items-center mt-15px">
                          <div>
                            <Checkbox
                              classes={{
                                root: "p-0 mx-10px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (selection.requests[row._id]) {
                                  dispatch(unselectRequestItem(row));
                                } else {
                                  dispatch(selectRequestItem(row));
                                }
                              }}
                              checked={!!selection.requests[row._id]}
                              color="primary"
                            />
                          </div>
                          <div className={clsx("mb-20px", { "d-none": !row.updates })}>
                            <FiberManualRecord color="primary" />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: `${rowWidth.index}%`,
                          minWidth: minWidth.index,
                        }}
                        className="d-none d-md-table-cell f-15 p-15px text-dark"
                      >
                        {`#${row.index}`}
                      </div>
                      {isSuperAdmin && (
                        <div
                          style={{
                            width: `${rowWidth.requesterName}%`,
                            minWidth: minWidth.requesterName,
                          }}
                          className="d-none d-md-table-cell f-15 text-dark p-15px"
                        >
                          {row.requesterName}
                        </div>
                      )}
                      <div
                        style={{
                          width: `${rowWidth.title}%`,
                          minWidth: minWidth.title,
                        }}
                        className={clsx(
                          "d-none d-md-table-cell f-15 text-dark p-15px",
                          { "text-dark": row.title, "text-muted": !row.title }
                        )}
                      >
                        {row.title ? (
                          <span>{row.title}</span>
                        ) : (
                          <FormattedMessage id="REQUEST.LIST.TITLE.PLACEHOLDER" />
                        )}
                      </div>
                      <div
                        className="d-none d-md-table-cell f-15 text-dark p-15px"
                        style={{
                          width: `${rowWidth.createdAt}%`,
                          minWidth: minWidth.createdAt,
                        }}
                      >
                        {moment(row.createdAt).format("DD/MM/YYYY")}
                      </div>
                      <div
                        className="f-15 text-dark p-15px"
                        style={{
                          width: `${rowWidth.providerName}%`,
                          minWidth: minWidth.providerName,
                        }}
                      >
                        {row.documentProvidersName}
                      </div>
                      <div
                        className="f-15 text-dark p-15px"
                        style={{
                          width: `${rowWidth.status}%`,
                          minWidth: minWidth.status,
                        }}
                      >
                        <StatusColumn request={row} />
                      </div>
                      <div
                        className="d-flex align-items-center text-dark p-15px"
                        style={{
                          width: `${rowWidth.action}%`,
                          minWidth: minWidth.action,
                        }}
                      >
                        <ActionColumn
                          request={row}
                          handleCompleteRequest={() =>
                            setRequestsToComplete([row])
                          }
                          handleCancelRequest={() => setRequestsToCancel([row])}
                          handleArchiveRequest={() =>
                            setRequestsToArchive([row])
                          }
													handleDeleteRequest={() =>
                            setRequestsToDelete([row])
                          }
                          handleInProgressRequest={() =>
                            setRequestsToMoveToInProgress([row])
                          }
                          handleMarkRequestUnread={() => markRequestUnread(row)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="request-list-mobile d-block d-md-none">
            {stableSort(rows, getSorting(filter.order, filter.orderBy))
              .slice(page * pageSize, page * pageSize + pageSize)
              .map((row, index) => {
                return (
                  <div
                    key={index}
                    className="bg-white p-15px mb-10px"
                    onClick={() => history.push(`/requests/${row._id}`)}
                  >
                    <div>
                      <div
                        className={clsx("mb-20px", { "d-none": !row.updates })}
                      >
                        <FiberManualRecord color="primary" />
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          <Checkbox
                            classes={{
                              root: "p-0",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (selection.requests[row._id]) {
                                dispatch(unselectRequestItem(row));
                              } else {
                                dispatch(selectRequestItem(row));
                              }
                            }}
                            checked={!!selection.requests[row._id]}
                            color="primary"
                          />
                        </div>

                        <div
                          className="text-muted"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setRequestsToArchive([row]);
                          }}
                        >
                          <Archive style={{ height: 20, width: 20 }} />
                        </div>
                      </div>
                    </div>

                    <hr className="my-20px" />

                    <div className="mb-20px">
                      <div className="input-label mb-10px">
                        <FormattedMessage id="REQUEST.LIST.ID" />
                      </div>
                      <div>{`#${row.index}`}</div>
                    </div>

                    {isSuperAdmin && (
                      <div className="mb-20px">
                        <div className="input-label mb-10px">
                          <FormattedMessage id="REQUEST.LIST.REQUESTER" />
                        </div>
                        <div>{row.requesterName}</div>
                      </div>
                    )}

                    <div className="mb-20px">
                      <div className="input-label mb-10px">
                        <FormattedMessage id="REQUEST.LIST.TITLE" />
                      </div>
                      <div>
                        {row.title ? (
                          <span>{row.title}</span>
                        ) : (
                          <span className="text-muted">
                            <FormattedMessage id="REQUEST.LIST.TITLE.PLACEHOLDER" />
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-20px">
                      <div className="input-label mb-10px">
                        <FormattedMessage id="REQUEST.LIST.PROVIDER" />
                      </div>
                      <div>{row.documentProvidersName}</div>
                    </div>

                    <div className="mb-20px">
                      <div className="input-label mb-10px">
                        <FormattedMessage id="REQUEST.LIST.CREATED_AT" />
                      </div>
                      <div>{moment(row.createdAt).format("DD/MM/YYYY")}</div>
                    </div>

                    <div>
                      <div className="input-label mb-10px">
                        <FormattedMessage id="REQUEST.LIST.STATUS" />
                      </div>
                      <div>
                        <StatusColumn request={row} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="row d-flex justify-content-center justify-content-md-end flex-grow-1 mt-3 pr-0">
            {rows.length > 0 && !loadingList && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => dispatch(setPage(newPage))}
                onRowsPerPageChange={(e) =>
                  handlePageSizeChange(e.target.value)
                }
              />
            )}
          </div>

          {requestsToComplete.length > 0 && (
            <Confirm
              open
              icon={({ color }) => <CircleCheckIcon fill={color} />}
              variant="success"
              handleClose={handleBatchCompleteSubmit}
              title="REQUEST.COMPLETE.TITLE"
              submitButtonText="GENERAL.YES"
              cancelButtonText="GENERAL.NO"
              loading={flags.loading[flagNames.COMPLETE]}
              error={flags.error[flagNames.COMPLETE]}
            />
          )}

          {requestsToCancel.length > 0 && (
            <Confirm
              open
              icon={
                <NoSim
                  className="text-danger"
                  style={{ height: 75, width: 75 }}
                />
              }
              variant="danger"
              handleClose={handleBatchCancelSubmit}
              title="REQUEST.CANCEL.CONFIRM.TITLE"
              submitButtonText="GENERAL.YES"
              cancelButtonText="GENERAL.NO"
              loading={flags.loading[flagNames.CANCEL]}
              error={flags.error[flagNames.CANCEL]}
            />
          )}

          {requestsToArchive.length > 0 && (
            <Confirm
              open
              icon={
                filter.archived ? (
                  <Unarchive color="action" style={{ height: 75, width: 75 }} />
                ) : (
                  <Archive color="action" style={{ height: 75, width: 75 }} />
                )
              }
              variant="primary"
              handleClose={handleBatchArchiveSubmit}
              title={`REQUEST.${
                filter.archived ? "UNARCHIVE" : "ARCHIVE"
              }.CONFIRM.TITLE`}
              titleValues={{ totalCount: requestsToArchive.length }}
              message={
                requestsToArchive.some(
                  ({ status }) =>
                    ![
                      Constants.RequestStatus.CANCELLED,
                      Constants.RequestStatus.COMPLETED,
                    ].includes(status)
                )
                  ? "REQUEST.ACTION.BATCH_ARCHIVE.WARNING"
                  : ""
              }
              submitButtonText="GENERAL.YES"
              submitButtonForcedColor={Color.secondary}
              cancelButtonText="GENERAL.NO"
              loading={flags.loading[flagNames.ARCHIVE]}
              error={flags.error[flagNames.ARCHIVE]}
            />
          )}

					{requestsToDelete.length > 0 && (
            <Confirm
              open
              icon={({color}) => <DeleteForever style={{ height: 75, width: 75, color }} />}
              variant="danger"
              handleClose={handleBatchDeleteSubmit}
              title="REQUEST.DELETE.CONFIRM.TITLE"
              message="REQUEST.ACTION.BATCH_DELETE.WARNING"
              submitButtonText="REQUEST.DELETE.CONFIRM.SUBMIT"
              cancelButtonText="GENERAL.CANCEL"
              loading={flags.loading[flagNames.DELETE]}
              error={flags.error[flagNames.DELETE]}
            />
          )}

          {requestsToMoveToInProgress.length > 0 && (
            <Confirm
              open
              icon={<SettingsIcon size={75} viewBox="0 0 20 20" />}
              variant="primary"
              handleClose={handleBatchInProgressSubmit}
              title="REQUEST.IN_PROGRESS.CONFIRM.TITLE"
              submitButtonText="GENERAL.YES"
              cancelButtonText="GENERAL.NO"
              loading={flags.loading[flagNames.IN_PROGRESS]}
              error={flags.error[flagNames.IN_PROGRESS]}
            />
          )}
        </React.Fragment>
      ) : (
        <div className="text-center text-muted py-5">
          <FormattedMessage id="REQUEST.LIST.EMPTY_LIST" />
        </div>
      )}
    </div>
  );
}

function StatusColumn({ request: row }) {
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const copyRequestLink = useCallback(
    async (event, provider) => {
      try {
        event.stopPropagation();
        event.preventDefault();
        const link = `${window.location.origin}/provider/${row._id}/${provider._id}`;
        await copyTextToClipboard(link);
        const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_SUCCESS" });
        enqueueSnackbar(msg, { variant: "success" });
      } catch (err) {
        const msg = intl.formatMessage({ id: "REQUEST.RESEND.COPY_ERROR" });
        enqueueSnackbar(msg, { variant: "error" });
        console.log(err);
      }
    },
    [intl, enqueueSnackbar, row]
  );

  return row.documentProviders
    .filter((provider) => {
      const shouldHide = provider.stats
        ? provider.stats.approved === 0 && !provider.emailStatus
        : true;
      return !shouldHide;
    })
    .map((provider, index, arr) => {
      return (
        <div
          key={`${row._id}-${index}`}
          className={clsx({ "mb-15px": index !== arr.length - 1 })}
        >
          <div
            className={clsx("f-12px", {
              "d-none": row.documentProviders.length === 1,
            })}
          >
            {`${provider.firstName} ${provider.lastName}`}
          </div>

          <div className="d-flex align-items-center">
            {row.status === Constants.ProviderStatus.CANCELLED && (
              <div className="d-flex align-items-center flex-grow-1">
                <div className={clsx("mr-2", classes.progressBar)}>
                  <SecondaryBorderLinearProgress
                    variant="determinate"
                    value={100}
                  />
                </div>
                <div className={clsx("f-12px", classes.cancelledRequest)}>
                  <FormattedMessage id={`REQUEST.STATUS.${row.status}`} />
                </div>
              </div>
            )}

            {row.status === Constants.ProviderStatus.COMPLETED && (
              <div className="d-flex align-items-center flex-grow-1">
                <div className={clsx("mr-2", classes.progressBar)}>
                  <SuccessBorderLinearProgress
                    variant="determinate"
                    value={100}
                  />
                </div>
                <div className="text-success f-12px">
                  <FormattedMessage id={`REQUEST.STATUS.${row.status}`} />
                </div>
              </div>
            )}

            {row.status === Constants.ProviderStatus.IN_PROGRESS &&
              (provider.stats.approved > 0 ? (
                <div className="d-flex align-items-center flex-grow-1">
                  <div className={clsx("mr-2", classes.progressBar)}>
                    <PrimaryBorderLinearProgress
                      variant="determinate"
                      value={provider.stats.percentage}
                    />
                  </div>
                  <div className="d-flex ml-2 align-items-center">
                    <div className="d-flex text-primary align-items-end">
                      <div className="f-15px font-weight-bold">
                        {provider.stats.approved}
                      </div>
                      <div className="f-12px" style={{ marginBottom: 1 }}>
                        /
                      </div>
                      <div className="f-12px" style={{ marginBottom: 1 }}>
                        {provider.stats.total}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={clsx("align-items-center flex-grow-1", {
                    "d-flex": !!provider.emailStatus,
                    "d-none": !provider.emailStatus,
                  })}
                >
                  <div className={clsx("mr-2", classes.progressBar)}>
                    <PrimaryBorderLinearProgress
                      variant="determinate"
                      value={0}
                    />
                  </div>
                  {provider.emailStatus ===
                  Constants.ProviderEmailStatus.UNDELIVERED ? (
                    <div className="text-warning">
                      <LightTooltip
                        title={intl.formatMessage({
                          id: "GENERAL.EMAIL_STATUS.UNDELIVERED",
                        })}
                      >
                        <Error />
                      </LightTooltip>
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        "text-muted f-12px",
                        classes.cancelledRequest
                      )}
                    >
                      <FormattedMessage
                        id={`GENERAL.EMAIL_STATUS.${provider.emailStatus}`}
                      />
                    </div>
                  )}
                </div>
              ))}

            {!row.archived && (
              <div>
                <div
                  className={clsx(
                    classes.copyLinkButton,
                    "cursor-pointer bg-light-blue d-flex justify-content-center align-items-center"
                  )}
                  onClick={(e) => copyRequestLink(e, provider)}
                >
                  <LinkIcon color="primary" fontSize="small" />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });
}

function ActionColumn({
  request: row,
  handleCancelRequest,
  handleArchiveRequest,
	handleDeleteRequest,
  handleCompleteRequest,
  handleInProgressRequest,
  handleMarkRequestUnread,
}) {
  const classes = useStyles();
  const canArchive = !row.archived && (
    row.status === Constants.RequestStatus.CANCELLED ||
    row.status === Constants.RequestStatus.COMPLETED
	);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      setAnchorEl(null);
    },
    [setAnchorEl]
  );

  const onCancelRequest = useCallback(
    (event, provider) => {
      event.preventDefault();
      event.stopPropagation();

      handleCancelRequest();
      handleMenuClose();
    },
    [handleCancelRequest, handleMenuClose]
  );

  const onMarkRequestAsUnread = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      handleMarkRequestUnread();
      handleMenuClose();
    },
    [handleMarkRequestUnread, handleMenuClose]
  );

  const onArchiveRequest = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      handleArchiveRequest();
      handleMenuClose();
    },
    [handleArchiveRequest, handleMenuClose]
  );

	const onDeleteRequest = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      handleDeleteRequest();
      handleMenuClose();
    },
    [handleDeleteRequest, handleMenuClose]
  );

  const onCompleteRequest = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      handleCompleteRequest();
      handleMenuClose();
    },
    [handleCompleteRequest, handleMenuClose]
  );

  const onInProgressRequest = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      handleInProgressRequest();
      handleMenuClose();
    },
    [handleInProgressRequest, handleMenuClose]
  );

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-center">
        <div className="text-muted cursor-pointer" onClick={handleMenuOpen}>
          <MoreVert />
        </div>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleMenuClose}
        >
          {row.archived ? [
						<MenuItem key="unarchive" onClick={onArchiveRequest}>
							<ListItemIcon className={classes.requestMenuOption}>
								<Unarchive fill="#757575" size={20} />
							</ListItemIcon>

							<FormattedMessage id="REQUEST.UNARCHIVE">
								{(text) => <ListItemText primary={text} />}
							</FormattedMessage>
						</MenuItem>,

						<MenuItem key="delete" onClick={onDeleteRequest}>
							<ListItemIcon className={classes.requestMenuOption}>
								<DeleteForever fill="#757575" size={20} />
							</ListItemIcon>

							<FormattedMessage id="REQUEST.DELETE">
								{(text) => <ListItemText primary={text} />}
							</FormattedMessage>
						</MenuItem>
					] : [
						row.status !== Constants.RequestStatus.IN_PROGRESS ? (
							<MenuItem key="in_progress" onClick={onInProgressRequest}>
								<ListItemIcon className={classes.requestMenuOption}>
									<SettingsIcon fill="#757575" size={20} />
								</ListItemIcon>

								<FormattedMessage id="REQUEST.ACTION.MOVE_TO_IN_PROGRESS">
									{(text) => <ListItemText primary={text} />}
								</FormattedMessage>
							</MenuItem>
						) : null,

						row.status !== Constants.RequestStatus.COMPLETED ? (
							<MenuItem key="mark_as_completed" onClick={onCompleteRequest}>
								<ListItemIcon className={classes.requestMenuOption}>
									<OfflinePin />
								</ListItemIcon>

								<FormattedMessage id="REQUEST.ACTION.MARK_AS_COMPLETED">
									{(text) => <ListItemText primary={text} />}
								</FormattedMessage>
							</MenuItem>
						) : null,

						row.status !== Constants.RequestStatus.CANCELLED ? (
							<MenuItem key="mark_as_cancelled" onClick={onCancelRequest}>
								<ListItemIcon className={classes.requestMenuOption}>
									<NoSim />
								</ListItemIcon>
								<FormattedMessage id="REQUEST.ACTION.MARK_AS_CANCELLED">
									{(text) => <ListItemText primary={text} />}
								</FormattedMessage>
							</MenuItem>
						) : null,
						
						row.status !== Constants.RequestStatus.CANCELLED ? (
							<MenuItem
								key="mark_as_unread"
								onClick={(event) => onMarkRequestAsUnread(event, row)}
							>
								<ListItemIcon className={classes.requestMenuOption}>
									<NotificationsActive />
								</ListItemIcon>

								<FormattedMessage id="REQUEST.ACTION.MARK_AS_UNREAD">
									{(text) => <ListItemText primary={text} />}
								</FormattedMessage>
							</MenuItem>
						) : null,
					]}
        </Menu>

        {canArchive && (
          <div className="pl-10px text-muted" onClick={onArchiveRequest}>
						<Archive />
          </div>
        )}
      </div>
    </div>
  );
}

export default RequestList;
