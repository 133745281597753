import { useTheme, Switch, makeStyles, CircularProgress } from '@material-ui/core';
import React, { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { Mic } from '@material-ui/icons';
import { Alert } from 'reactstrap';
import moment from 'moment';
import { useReactMediaRecorder } from 'react-media-recorder';
import { isIOS } from 'react-device-detect';
import { uuid } from 'uuidv4';

import { getEmptyErrorObj, validateFileUpload } from '../../ElementPicker';
import ErrorMessage from '../ErrorMessage';
// import SizeIndicator from '../../../size-indicator';
import { addAlpha, isAttachmentValid, sanitizeFile } from '../../../../../_metronic';
import FileCard from './FileCard';
import AttachmentCard from './AttachmentCard';
import useDebounce from '../../../useDebounce';
import { ELEMENT_TYPES } from '..';
import {
	bulkDownloadV2,
	flagNames,
	uploadAttachmentsToElement,
	uploadFileToElement as uploadFileToRequestElement,
} from '../../../../store/modules/actions/requests.actions';
import {
	uploadFileToElement as uploadFileToTemplateElement,
} from '../../../../store/modules/actions/templates.actions';
import Settings from './Settings';
import AutoExpandInput from '../../../auto-expand-input';
import { DangerBlocksIcon, FileErrorIcon } from '../../../icons';
import Confirm from '../../../modals/confirm';
import Constants from "../../../constants";
import CountdownTimer from './CountdownTimer';
import TimerWidget from './TimerWidget';
import AudioPreview from './VideoPreview';
import { getSelectedRequest } from '../../../../store/modules/selectors/request.selector';
import { getFlags } from '../../../../store/modules/selectors/common.selector';
import { getUploadTaskResults } from '../../../../store/modules/selectors/tasks.selector';

const useStyles = makeStyles((theme) => {
	return {
		attachmentListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
			border: `2px dashed ${theme.palette.extraColors.grey}`,
			borderRadius: 6,
		},
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		uploadContainer: {
			height: 170,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto",

			'&::-webkit-scrollbar': {
				marginLeft: 5,
				width: 8,
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				borderRadius: 5,
				background: 'transparent'
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: 'rgb(235, 239, 255)',
				borderRadius: 15,
			}
		},
		sizeContainer: {
			background: theme.palette.extraColors.blueSecondary,
			borderRadius: "5px",
		},
		viewPreviewInfo: {
			position: "absolute",
			left: "50%",
			bottom: "5%",
			transform: "translateX(-50%)",
			zIndex: 3,
		},
		acquiringMediaText: {
			position: "absolute",
			bottom: "20%",
			left: "50%",
			transform: "translateX(-50%)",
			zIndex: 3,
		},
		cameraPreviewContainer: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,

			'& video': {
				zIndex: 2,
			}
		},
		iosFileInput: {
			position: "absolute",
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			opacity: 0,
		},
		audioPreviewOverlay: {
			position: "absolute",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			background: addAlpha(theme.palette.dark.primary.main, 0.4),
		}
	}
});

const AudioInput = forwardRef((props, _ref) => {
	const {
		id,
		data,
		response,
		onChangeResponse,
		onChange,
		onPreview,
		additionalData,
	} = props;

	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { fileUpload, providerUUID, hideDownloadAllFiles } = additionalData;

	const uploadTaskResult = useSelector(getUploadTaskResults);

	const [showStorageLimitWarning, setShowStorageLimitWarning] = useState(false);

	const hasTimerCompleted = useRef(false);
	const wasStoppedBeforeStart = useRef(false);
	const isPausedManually = useRef(false);
	const attachmentUploadTasksRef = useRef({});
	const fileUploadTasksRef = useRef({});

	const isUploadDisabled = useMemo(() => fileUpload?.preventFileUpload, [fileUpload]);
	
	const [deleteFileIndex, setDeleteFileIndex] = useState(-1);
	const [deleteAttachmentIndex, setAttachmentFileIndex] = useState(-1);
	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);

	const [error, setError] = useState({});

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	useEffect(() => {
		const uploadIds = [
			...Object.keys(fileUploadTasksRef.current),
			...Object.keys(attachmentUploadTasksRef.current),
		];

		for (const key of uploadIds) {
			const result = uploadTaskResult[key];

			if (result) {
				if (fileUploadTasksRef.current[key]) {
					delete fileUploadTasksRef.current[key];
					
					onChangeResponse({
						...response,
						files: [
							...result,
							...response.files,
						]
					});
				}
				else if (attachmentUploadTasksRef.current[key]) {
					delete attachmentUploadTasksRef.current[key];
					
					onChange({
						...data,
						attachments: [
							...result,
							...data.attachments,
						]
					});
				}
			}
		}
	}, [data, onChange, onChangeResponse, response, uploadTaskResult]);

	const selectedRequest = useSelector(getSelectedRequest);
	const flags = useSelector(getFlags);

	const provider = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;

		const provider = selectedRequest?.documentProviders.find(({ uuid }) => uuid === providerUUID);
		return provider;
	}, [hideDownloadAllFiles, providerUUID, selectedRequest]);
	const elementId = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;

		if (selectedRequest && provider) {
			const element = provider.elements.find(({ uuid }) => uuid === id);

			if (element) {
				return element._id;
			}
		}
	}, [hideDownloadAllFiles, id, provider, selectedRequest]);

	const isDownloadingAllFiles = useMemo(() => {
		if (hideDownloadAllFiles) return undefined;
		const loadingFlag = flags.loading[flagNames.BULK_DOWNLOAD] || {};
		return !!loadingFlag[elementId];
	}, [elementId, flags.loading, hideDownloadAllFiles]);

	const hasValidOriginalName = useMemo(() => {
		return response.files.some(({ originalName, name }) => name && originalName && name !== originalName );
	}, [response.files]);

	useEffect(() => {
		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
		};
		const error = validateFileUpload(newData);
		const emptyErrorObj = getEmptyErrorObj(ELEMENT_TYPES.AUDIO_INPUT)
		const isSafe = _.isEqual(error, emptyErrorObj);

		if (isSafe) {
			onChange(newData);
		}

		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	])

	// const getTotalSize = useCallback(() => {
	// 	const totalSize = response.files.reduce((p, attachment) => {
	// 		const attachmentSizeInB = attachment._id ? attachment.size * 1024 : attachment.size;
	// 		return p + attachmentSizeInB;
	// 	}, 0);

	// 	return totalSize;
	// }, [
	// 	response,
	// ]);

	const handleDownloadAllFiles = useCallback(() => {
		const filename = `${title}.zip`;

		dispatch(
			bulkDownloadV2(
				provider,
				{ _id: elementId },
				filename,
				'',
				false,
			)
		)
	}, [
		provider,
		title,
		elementId,
		dispatch,
	]);

	const isStorageLimitReached = useCallback((newFiles) => {
		const existingAttachmentSizeKB = response.files.reduce((p, file) => {
			return p + file.size;
		}, 0);
		const newFilesKB = newFiles.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.VIDEO_ELEMENT_STORAGE_LIMIT_MB;
	}, [response]);

	const uploadFiles = useCallback(async (acceptedFiles, areAttachments) => {
		const {
			requestUUID,
			providerUUID,
			templateUUID,
		} = additionalData;
		const validFiles = acceptedFiles.filter((file) => isAttachmentValid(file));

		acceptedFiles
			.filter((file) => !validFiles.includes(file))
			.forEach((file) => {
				const msg = intl.formatMessage({
					id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
				}, file);
				enqueueSnackbar(msg, { variant: 'error' })
			});

		if (isStorageLimitReached(validFiles)) {
			setShowStorageLimitWarning(true);
			return;
		}

		const promises = validFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		const uploadId = uuid();

		if (templateUUID) {
			dispatch(uploadFileToTemplateElement(templateUUID, id, files, uploadId));
			fileUploadTasksRef.current[uploadId] = true;
		}
		else if (areAttachments) {
			dispatch(uploadAttachmentsToElement(requestUUID, providerUUID, id, files, uploadId));
			attachmentUploadTasksRef.current[uploadId] = true;
		}
		else {
			dispatch(uploadFileToRequestElement(providerUUID, id, files, uploadId));
			fileUploadTasksRef.current[uploadId] = true;
		}
	}, [
		additionalData,
		dispatch,
		enqueueSnackbar,
		id,
		intl,
		isStorageLimitReached
	]);

	const onFileDrop = useCallback(async (acceptedFiles) => {
		const validFiles = acceptedFiles.filter((file) => {
			const isValidFile = isAttachmentValid(file);
			const extension = file.name.split('.').pop().toLowerCase();
			const isValidAudioFile = ['mp3', 'wav'].includes(extension);

			return isValidFile && isValidAudioFile;
		});

		uploadFiles(validFiles);
	}, [uploadFiles]);

	const onAttachmentDrop = useCallback(async (acceptedFiles) => {
		uploadFiles(acceptedFiles, true);
	}, [uploadFiles]);

	const handleDeleteFile = useCallback((fileIndexToDelete) => {
		const files = response.files.filter((file, index) => index !== fileIndexToDelete);

		onChangeResponse({
			...response,
			files,
		});
	}, [
		onChangeResponse,
		response,
	]);

	const handleDeleteAttachment = useCallback((attachmentIndexToDelete) => {
		const attachments = data.attachments.filter((file, index) => index !== attachmentIndexToDelete);

		onChange({
			...data,
			attachments,
		});
	}, [
		onChange,
		data,
	]);

	const handleAudioRecordingComplete = useCallback((bloblUrl, blob) => {
		if (wasStoppedBeforeStart.current) {
			return;
		}

		const timeString = moment().format("DD-MM-YYYY hh:mm:SS A");
		const extension = "wav";
		const fileName = `audio recording ${timeString}.${extension}`; 
		const audioFile = new File([blob], fileName, { type: "audio/wav" });

		onFileDrop([audioFile]);
	}, [onFileDrop]);

	const { status, startRecording, resumeRecording, pauseRecording, stopRecording, previewAudioStream: previewStream } = useReactMediaRecorder({
		blobPropertyBag: {
			type: "audio/wav",
		},
		onStop: handleAudioRecordingComplete,
	});
	useImperativeHandle(_ref, () => ({
		shouldStopMouseListener: () => status === "recording" || status === "paused",
	}));

	const {
		getRootProps: getFileInputPropsRoot,
		getInputProps: getFileInputProps,
	} = useDropzone({
		onDrop: onFileDrop,
		noClick: false,
		accept: "audio/*",
		disabled: isUploadDisabled,
	});

	const {
		getRootProps: getAttachmentInputPropsRoot,
		getInputProps: getAttachmentInputProps,
	} = useDropzone({
		onDrop: onAttachmentDrop,
		noClick: false,
	});

	const handleRecordingStart = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		wasStoppedBeforeStart.current = false;
		hasTimerCompleted.current = false;
		isPausedManually.current = false;

		startRecording();
	}, [startRecording]);

	const handleTimerComplete = useCallback((e) => {
		hasTimerCompleted.current = true;
		resumeRecording();
	}, [resumeRecording]);

	const handleRecordingPause = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		isPausedManually.current = true;
		pauseRecording();
	}, [pauseRecording]);

	const handleRecordingResume = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		isPausedManually.current = false;
		resumeRecording();
	}, [resumeRecording]);

	const handleRecordingStop = useCallback((e) => {
		wasStoppedBeforeStart.current = !hasTimerCompleted.current;
		stopRecording();
	}, [stopRecording]);
	
	useEffect(() => {
		if (status === "recording" && !hasTimerCompleted.current) {
			pauseRecording();
		}
	}, [status, pauseRecording]);

	const isNotRecording = useMemo(() => status === "idle" || status === "stopped", [status]);

	return (
		<div>
			<div>
				<AutoExpandInput
					className="f-18px text-dark font-weight-medium mb-0"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.AUDIO_INPUT.TITLE.PLACEHOLDER"
						})
					}
				/>
			</div>

			{
				error.title && (
					<ErrorMessage message={error.title} />
				)
			}

			<div>
				<AutoExpandInput
					className="f-14px text-dark font-weight-regular mb-0 w-100 pb-0 break-word"
					style={{ color: theme.palette.extraColors.lightText }}
					onChange={(e) => setSubtitle(e.target.value)}
					value={subtitle}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.HEADING.SUBTITLE.PLACEHOLDER"
						})
					}
					allowHTML
				/>
			</div>

			<div className={clsx("my-10px d-flex flex-column flex-grow-1 px-0 col-12", { "pointer-events-none": !isNotRecording })}>
				{
					data.attachments.length ? (
						<div className={clsx(classes.attachmentListContainer, "mb-15px")}>
							<div
								className="f-14px mb-5px px-10px pt-10px fw-500 text-muted cursor-pointer"
								{...getAttachmentInputPropsRoot()}
							>
								<FormattedMessage
									id="ELEMENT_LIST.ITEM.AUDIO_INPUT.ATTACHMENTS.TITLE"
									defaultMessage="Attached Files"
								/>
								<input {...getAttachmentInputProps()} />
							</div>

							<div className={classes.fileList}>
								{
									data.attachments.map((attachment, index, arr) => (
										<div
											key={index}
											className={
												clsx(
													{
														"mb-5px": index !== arr.length - 1,
														"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
													}
												)
											}
										>
											<AttachmentCard
												canDownload={attachment._id}
												onDelete={() => setAttachmentFileIndex(index)}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();

													onPreview(attachment);
												}}
												attachment={attachment}
											/>
										</div>
									))
								}
							</div>
						</div>	
					) : (
						<div
							className="text-primary text-decoration-underline cursor-pointer mb-15px"
							{...getAttachmentInputPropsRoot()}
						>
							<FormattedMessage
								id="ELEMENT_LIST.ITEM.AUDIO_INPUT.ATTACH_FILE"
								defaultMessage="Attach Files"
							/>
							<input {...getAttachmentInputProps()} />
						</div>
					)
				}
				
				<div className={clsx(classes.fileListContainer, "rounded bg-white p-10px")}>
					<div className="f-14px fw-500 text-muted">
						<FormattedMessage
							id="ELEMENT_LIST.ITEM.AUDIO_INPUT.FILES.TITLE"
							defaultMessage="Uploaded Files"
						/>
					</div>

					<div className={classes.fileList}>
						{
							response.files.map((file, index, arr) => (
								<div
									key={index}
									className={
										clsx(
											{
												"mb-5px": index !== arr.length - 1,
												"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
											}
										)
									}
								>
									<FileCard
										canDownload={file._id}
										fileNameField={data.viewOriginalFileName ? 'originalName' : undefined}
										onDelete={() => setDeleteFileIndex(index)}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();

											onPreview(file);
										}}
										file={file}
									/>
								</div>
							))
						}
					</div>

					<div
						className={clsx(
							"mt-10px p-10px position-relative flex-grow-1 rounded",
							{
								"d-flex justify-content-center align-items-center": !response.files.length,
								"bg-dark": !isNotRecording,
							}
						)}
						style={{
							background: theme.palette.extraColors.backgroundBlue,
						}}
						{...getFileInputPropsRoot()}
					>
						<div className={clsx(classes.uploadContainer, "w-100 d-flex align-items-center justify-content-center flex-column")}>
							<div className={clsx("align-items-center text-muted", { "d-none": !isNotRecording, "d-flex": isNotRecording })}>
								<Mic className="f-50px" />
							</div>

							<div className="mt-5px d-flex flex-column align-items-center">
								{
									isIOS ? (
										<div className='bg-danger' onClick={(e) => e.stopPropagation()}>
											{/* this is done to prevent the parent input element (used for drag and drop files) to intercept the upload event */}
											<label className={clsx("btn btn-primary btn-sm d-flex align-items-center position-relative", { "btn-disabled pointer-events-none": isUploadDisabled })}>
												<input
													className={classes.iosFileInput}
													disabled={isUploadDisabled}
													type="file"
													capture="environment"
													accept="audio/*"
													onChange={(e) => onFileDrop(e.target.files)}
												/>
												<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.START_BUTTON_TEXT" />
											</label>
										</div>
									) : (
										<div className={clsx({ [classes.cameraPreviewContainer]: !isNotRecording })}>
											{
												!isNotRecording && (
													<Fragment>
														{
															status === "acquiring_media" && (
																<div className={clsx("text-light", classes.acquiringMediaText)}>
																	<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.ACQUIRING_MEDIA" />
																</div>
															)
														}
														
														<div className={classes.viewPreviewInfo}>
															<div>
																<CountdownTimer
																	wasPausedManually={isPausedManually.current}
																	status={status}
																	onComplete={handleTimerComplete}
																/>
															</div>

															<div className='pointer-events-all'>
																<TimerWidget
																	status={status}
																	canPause={hasTimerCompleted.current}
																	onPause={handleRecordingPause}
																	onResume={handleRecordingResume}
																	onStop={handleRecordingStop}
																/>
															</div>
														</div>
													</Fragment>
												)
											}

											{
												status === "media_in_use" && (
													<Alert color="danger">
														<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.MEDIA_IN_USE_ERROR" />
													</Alert>
												)
											}
												
											{
												isNotRecording && (
													<button
														onClick={handleRecordingStart}
														className={clsx("btn btn-primary btn-sm d-flex align-items-center", { "btn-disabled pointer-events-none": isUploadDisabled })}
													>
														<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.RECORD.START_BUTTON_TEXT" />
													</button>
												)
											}

											{
												status === "paused" && (
													<div className={classes.audioPreviewOverlay} />
												)
											}

											{
												!isNotRecording && (
													<AudioPreview stream={previewStream} />
												)
											}
										</div>
									)
								}
							</div>

							{
								isNotRecording && !isUploadDisabled && (
									<div className='d-flex mt-15px'>
										<div>or</div>
										&nbsp;
										<div>
											<input {...getFileInputProps()} />
											<span className='cursor-pointer text-primary text-decoration-underline'>
												<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.FILES.MANUAL_UPLOAD" />
											</span>
										</div>
									</div>
								)
							}
						</div>
					</div>
				</div>

				{
					hasValidOriginalName && (
						<div className="d-flex align-items-center mt-5px">
							<div className="f-16px text-muted">
								<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.ORIGINAL_FILE_NAME.LABEL" />
							</div>

							<div className="ml-2">
								<Switch
									checked={!!data.viewOriginalFileName}
									classes={{
										thumb: data.viewOriginalFileName ? 'bg-dark' : 'bg-white'
									}}
									onChange={(e) => {
										onChange({
											...data,
											viewOriginalFileName: e.target.checked
										})
									}}
								/>
							</div>
						</div>
					)
				}
			</div>

			{
				!additionalData.hideDownloadAllFiles && response.files.length > 0 && elementId && (
					<div className="d-flex">
						<div
							className={
								clsx(
									"text-decoration-underline cursor-pointer text-body f-16px d-flex align-items-center",
									{
										"pointer-events-none text-muted": isDownloadingAllFiles
									}
								)
							}
							onClick={() => handleDownloadAllFiles()}
						>
							{
								isDownloadingAllFiles && (
									<div className="mr-2">
										<CircularProgress size={10} />
									</div>
								)
							}
							<div className="text-dark font-weight-light">
								<FormattedMessage id="ELEMENT_LIST.ITEM.AUDIO_INPUT.DOWNLOAD_ALL_FILES" />
							</div>
						</div>
					</div>
				)
			}

			{
				deleteFileIndex > -1 && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={(wasSubmitted) => {
							if (wasSubmitted) {
								handleDeleteFile(deleteFileIndex)
							}
							setDeleteFileIndex(-1);
						}}
						title="PROVIDER.ELEMENT.FILE.DELETE.FILE.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.ELEMENT.FILE.DELETE.FILE.DESCRIPTION"
							}, {
								elementTitle: data.title,
								fileName: response.files[deleteFileIndex].name
							})
						}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			{
				deleteAttachmentIndex > -1 && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={(wasSubmitted) => {
							if (wasSubmitted) {
								handleDeleteAttachment(deleteAttachmentIndex)
							}
							setAttachmentFileIndex(-1);
						}}
						title="PROVIDER.ELEMENT.FILE.DELETE.ATTACHMENT.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.ELEMENT.FILE.DELETE.ATTACHMENT.DESCRIPTION"
							}, {
								elementTitle: data.title,
								fileName: data.attachments[deleteAttachmentIndex].name
							})
						}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			<Confirm
				open={showStorageLimitWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setShowStorageLimitWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.VIDEO_ELEMENT_STORAGE_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>

			<Settings
				id={id}
				data={data}
				onChange={onChange}
			/>
		</div>
	)
});

export default AudioInput;
