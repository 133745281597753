import React from 'react';
import { Switch, withStyles } from '@material-ui/core';

const IOSSwitch = withStyles(theme => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 3,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				// backgroundColor: 'red',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			// color: '#52d869',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 20,
		height: 20,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid #C9CFDB`,
		backgroundColor: '#C9CFDB',
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))(({ classes, rootClass = '', enabledTrackClass = '', trackClass= '', ...props }) => {

	props.color = props.color || 'default';

	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: `${classes.root} ${rootClass}`,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: `${classes.track} ${trackClass} ${props.checked ? enabledTrackClass : ''}`,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

export default IOSSwitch;