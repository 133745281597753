import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import NotificationListToggler from "./NotificationListToggler";
import { flagNames } from "../../../app/store/modules/actions/requests.actions";
import LoadingIndicator from "../../../app/common/LoadingIndicator";
import { getFlags } from "../../../app/store/modules/selectors/common.selector";
import { getSelectedRequest } from "../../../app/store/modules/selectors/request.selector";

function Topbar () {
  const flags = useSelector(getFlags);
  const selectedRequest = useSelector(getSelectedRequest);
  const isSaving = useMemo(() => flags.loading[flagNames.PROVIDER_UPDATE] || flags.loading[flagNames.UPDATE_TITLE], [flags.loading]);

  const shouldShowSaveStatus = useMemo((store) => selectedRequest?.type === "MODERN", [selectedRequest]);

  return (
    <div className="kt-header__topbar">
      {
        shouldShowSaveStatus && (
          <LoadingIndicator
            className="d-flex align-items-center px-4 mb-1"
            loading={isSaving}
          />
        )
      }
      <NotificationListToggler toggleBy="kt_notification_list_toggler_btn" />
    </div>
  );
}

export default Topbar;
