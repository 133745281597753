import React, { useState, useCallback, useEffect } from "react";

import {
	FormattedMessage,
} from "react-intl";
import {
	Dialog,
	AppBar,
	Toolbar,
} from '@material-ui/core';
import {
	Close,
} from "@material-ui/icons";
import { Alert } from 'reactstrap';
import {
	useDispatch,
	useSelector,
} from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { CloudDownloadIcon } from "../../../../common/icons";
import PDFPreview from "../../../../common/PDFPreview";
import { requestSignedUrl } from "../../../../../_metronic";
import { downloadFileTask } from "../../../../store/modules/actions/tasks.actions";
import { doesRequirePassword } from "../../../../store/modules/selectors/provider.selector";

function Preview(props) {
	const {
		handleClose,
		docId,
		fileIndex,
		attachmentIndex,
	} = props;
	const dispatch = useDispatch();
	const [signedUrl, setSignedUrl] = useState(null);
	const [isImageLoaded, setImageLoaded] = useState(false);

	const canDownload = useSelector(doesRequirePassword);
	const item = useSelector((store) => {
		let item = null;

		store.provider.data.documents.forEach((doc) => {
			if (doc._id === docId) {
				item = doc;
			}
		});

		return item;
	});

	const getSignedUrl = useCallback(async () => {
		const isFile = fileIndex > -1;
		const selectedFile = isFile ? item.files[fileIndex] : item.attachments[attachmentIndex];
		
		return requestSignedUrl(selectedFile.url);
	}, [
		item,
		fileIndex,
		attachmentIndex,
	]);

	useEffect(() => {
		if (!item) {
			return;
		}

		getSignedUrl()
			.then(({ data }) => {
				setSignedUrl(data.url);
				return Promise.resolve()
			})
			.catch((err) => {
				console.log(err);
				setSignedUrl(null);
			})
	}, [
		getSignedUrl,
		item
	]);

	const downloadFile = useCallback(async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const isFile = fileIndex > -1;
		const selectedFile = isFile ? item.files[fileIndex] : item.attachments[attachmentIndex];

		const { data } = await getSignedUrl();
		try {
			await dispatch(
				downloadFileTask(
					data.url,
					selectedFile.name
				)
			);
		}
		catch (e) {
			console.log(e);
		}
	}, [
		fileIndex,
		attachmentIndex,
		getSignedUrl,
		item,
		dispatch,
	]);

	const selectedFile = fileIndex > -1 ? item.files[fileIndex] : item.attachments[attachmentIndex];
	const type = selectedFile.name.split('.').pop();
	const isImageFile = [/png/i, /jpg/i, /jpeg/i].some((imageType) => imageType.test(type));

	return (
		<Dialog
			fullScreen
			open 
			onClose={handleClose}
			className="d-flex flex-column"
		>
			<AppBar position="relative">
				<Toolbar className="d-flex">
					<div className="flex-grow-1 d-flex align-items-center text-white justify-content-between">	
						<div>
							<h6 className="m-0 pr-20">
								{`${selectedFile.name.slice(0, 30)}${selectedFile.name.length > 30 ? '...' : ''}`}
							</h6>
						</div>
						{
							canDownload && (
								<div className="cursor-pointer" onClick={downloadFile}>
									<span className="text-light">
										<div className="text-center">
											<div className="white-download-icon">
												<CloudDownloadIcon />
											</div>
											<div>
												<span>
													<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />			
												</span>
											</div>
										</div>
									</span>
								</div>
							)
						}
					</div>
					<div className="pl-4 cursor-pointer" onClick={() => handleClose()}>
						<Close />
					</div>
				</Toolbar>
			</AppBar>
			
			<div className="flex-grow-1 d-flex flex-column preview-container">
				{
					isImageFile ? (
						<TransformWrapper
							pan={{
								paddingSize: 1
							}}
							options={{
								minScale: 0,
							}}
						>
							{
								() => (
									<div className={`image-preview flex-grow-1 d-flex justify-content-center align-items-center w-100 p-4 ${isImageLoaded ? '' : 'loading'}`}>
									{
										<TransformComponent>
											<img
												onLoad={() => setImageLoaded(true)}
												className="rounded"
												src={signedUrl}
												alt={selectedFile.name}
											/>
										</TransformComponent>
									}
									</div>
								)
							}
						</TransformWrapper>
					) : (
						<React.Fragment>
							{
								type === 'pdf' ? (
									<PDFPreview
										fileName={selectedFile.name}
										url={signedUrl}
										containerClass="flex-grow-1"
									/>
								) : (
									<div className="d-flex flex-grow-1 w-100 justify-content-center align-items-center flex-column">
										<Alert color="danger">
											<FormattedMessage id="REQUEST.DOCUMENT.PREVIEW.UNSUPPORTED_CONTENT" />
										</Alert>

										<div
											className={`rounded border border-primary mt-2 py-2 px-4 cursor-pointer text-primary`}
											onClick={downloadFile}
										>
											<span className="mr-2">
												<CloudDownloadIcon />
											</span>
											<FormattedMessage id="REQUEST.DOCUMENT.EXPORT" />
										</div>
									</div>
								)
							}
						</React.Fragment>
					)
				}
			</div>
		</Dialog>
	);
}

export default Preview;