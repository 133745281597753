import React, {
	Fragment,
	useCallback,
	useState,
	useEffect,
	useRef,
	useMemo,
} from "react";
import {
	AppBar,
	Button,
	makeStyles,
	Toolbar,
} from "@material-ui/core";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useParams
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { Alert } from "reactstrap";

import * as builder from "../../../_metronic/ducks/builder";
import {
	loadCheckData,
	flagNames as providerFlagNames,
	getProviderData,
} from "../../store/modules/actions/provider.actions";
import SocketService from "../../common/services/SocketService";
import LegacyLayout from "./Layout/legacy";
import NewLayout from "./Layout/new";
import ValidatePassword from "./ValidatePassword";
import SetPassword from "./SetPassword";
import LoadingIndicator from "../../common/LoadingIndicator";
import { TASK_VIEWER_ANCHOR } from "../../common/tasks-status-viewer";
import HeaderLogo from "./HeaderLogo";
import { getProvider, getProviderInfo } from "../../store/modules/selectors/provider.selector";
import { getFlags } from "../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		layoutContainer: {
			// marginBottom: 150,
		},
	}
});

function Layout (props) {
	const params = useParams();
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const classes = useStyles();

	const [isReloading, setIsReloading] = useState(false);
	const intl = useIntl();
	const reloadNotificationKeys = useRef({});

	// eslint-disable-next-line no-unused-vars
	const [password, setPassword] = useState('');

	const provider = useSelector(getProvider);
	const providerInfo = useSelector(getProviderInfo);
	const flags = useSelector(getFlags);
	const isModernRequest = useMemo(() => provider?.data?.documentRequest?.type === "MODERN", [ provider ])

	// useEffect(() => {
	// 	const taskId = 'test';
	// 	let progress = 1;

	// 	setInterval(() => {
	// 		dispatch(
	// 			setTask(taskId, {
	// 				text: "Test task",
	// 				type: "axios",
	// 				progress,
	// 			})
	// 		);
	// 		progress++;
	// 	}, 2000)
	// }, [dispatch]);

	useEffect(() => {
		dispatch(loadCheckData(params.request, params.provider));
		dispatch(builder.actions.setTaskViewerAnchor(TASK_VIEWER_ANCHOR.LEFT))
	}, [params.request, params.provider, dispatch]);

	useEffect(() => {
		if (provider.check && !provider.check.requirePassword) {
			dispatch(getProviderData(params.request, params.provider));
		}
	}, [provider.check, dispatch, params]);

	const dismissAllReloadNotifications = useCallback(() => {
		const keys = Object.keys(reloadNotificationKeys.current);

		keys.forEach((key) => {
			closeSnackbar(+key);
		});

		reloadNotificationKeys.current = {};
	}, [
		closeSnackbar
	]);

	const showReloadNotification = useCallback(() => {
		enqueueSnackbar(intl.formatMessage({ id: "REQUEST.RELOAD_DATA" }), {
			variant: 'info',
			persist: true,
			action: (key) => {
				reloadNotificationKeys.current = {
					...reloadNotificationKeys.current,
					[key]: true
				};

				return (
					<Fragment>
						<Button
							className="text-light"
							onClick={async (e) => {
								try {
									e.target.textContent = '';
									e.target.classList.add("kt-spinner", "kt-spinner--right", "kt-spinner--md", "kt-spinner--light")

									setIsReloading(true);
									await dispatch(getProviderData(params.request, params.provider, undefined, true));
									enqueueSnackbar(
										intl.formatMessage({ id: "REQUEST.RELOADED_SUCCESSFULLY" }),
										{
											variant: 'success',
											autoHideDuration: 2000
										}
									);

									dismissAllReloadNotifications();
								}
								catch (e) {
									window.location.reload();
								}
								finally {
									setIsReloading(false);
								}
							}}
						>
							<FormattedMessage id="REQUEST.RELOAD_DATA.ACTION.REFRESH" />
						</Button>
						<Button
							className="text-light"
							onClick={() => dismissAllReloadNotifications()}
						>
							<FormattedMessage id="REQUEST.RELOAD_DATA.ACTION.IGNORE" />
						</Button>
					</Fragment>
				);
			},
		});
	}, [
		dismissAllReloadNotifications,
		dispatch,
		enqueueSnackbar,
		intl,
		params,
	]);

	useEffect(() => {
		const reloadEventName = `documentRequest/${params.request}/documentProvider/${params.provider}`;

		if (provider.data._id) {
			SocketService.socket.on(
				reloadEventName,
				(data) => {
					console.log(data);
					if (data && data.userId !== provider.data._id) {
						showReloadNotification();
					}
				}
			);
		}

		return function () {
			SocketService.socket.removeAllListeners([reloadEventName]);
		}
	}, [
		provider.data._id,
		params.provider,
		params.request,
		showReloadNotification,
	]);

	// const classes = useLayoutStyles();

	if (!provider.check) {
		if (flags.error[providerFlagNames.CHECK]) {
			return (
				<div
					style={{ height: '100vh' }}
					className="w-100 d-flex justify-content-center align-items-center pt-5"
				>
					<Alert className="text-center" color="danger">
						{flags.error[providerFlagNames.CHECK]}
					</Alert>
				</div>
			);
		}

		if (flags.loading[providerFlagNames.CHECK]) {
			return (
				<div
					style={{ height: '100vh' }}
					className="w-100 d-flex justify-content-center"
				>
					<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
				</div>
			);
		}

		return null;
	}

	if (!provider.data._id) {
		if (provider.check.requirePassword) {
			if (provider.check.isPasswordSet) {
				return (
					<ValidatePassword
						handleClose={(password) => {
							if (password) {
								setPassword(password)
							}
						}}
					/>
				);
			}
			else {
				return (
					<SetPassword
						handleClose={(password) => {
							if (password) {
								setPassword(password)
							}
						}}
					/>
				)
			}
		}
		else {
			return (
				<div
					style={{ height: '100vh' }}
					className="w-100 d-flex justify-content-center"
				>
					<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
				</div>
			);
		}
	}

	return (
		<div className="h-100">
			<AppBar position="static" className="bg-white">
				<Toolbar>
					<div className="col-8 offset-2 col-md-4 offset-md-4 py-3">
						<div className="d-flex justify-content-center align-items-center flex-column">
							<HeaderLogo
								customLogoUrl={(providerInfo.whiteLabelling && providerInfo.companyLogo) ? providerInfo.companyLogo : undefined}
							/>
							<div className="text-dark mt-10px text-center">
								<div className="f-14px font-weight-light">
									<FormattedMessage
										id="PROVIDER.SECURE_TEXT"
										values={{
											requesterName: `${provider.data.firstName} ${provider.data.lastName}`
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="col-2 col-md-4">
						<LoadingIndicator
							className="d-flex justify-content-end"
							loading={flags.loading[providerFlagNames.SAVE_PROVIDER_RESPONSE]}
						/>
					</div>
				</Toolbar>
			</AppBar>

			{
				isReloading ? (
					<div className="d-flex p-5 justify-content-center align-items-center">
						<div className="mt-5 kt-spinner kt-spinner--lg kt-spinner--v2 kt-spinner--primary" />
					</div>
				) : (
					<div className={classes.layoutContainer}>
						{
							isModernRequest ? (
								<NewLayout {...props} />
							) : (
								<LegacyLayout {...props} />
							)
						}
					</div>
				)
			}
		</div>
	)
}

export default Layout;