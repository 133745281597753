import React from "react";

export default ({ fill = '#2E5BFF' }) => (
	<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0)">
			<path d="M21.8336 10.4246L1.1312 0.869644C0.801558 0.719949 0.408212 0.809129 0.178893 1.09259C-0.0520186 1.37606 -0.0599811 1.77896 0.159783 2.07038L6.96769 11.1476L0.159783 20.2248C-0.0599811 20.5162 -0.0520186 20.9207 0.1773 21.2026C0.331772 21.3953 0.562684 21.4988 0.79678 21.4988C0.909847 21.4988 1.02291 21.4749 1.12961 21.4255L21.832 11.8706C22.1155 11.74 22.2954 11.4581 22.2954 11.1476C22.2954 10.8371 22.1155 10.5552 21.8336 10.4246Z" fill={fill} />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="22.2955" height="22.2955" fill="white" />
			</clipPath>
		</defs>
	</svg>
)