import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import FileIcon from "../../../file-icon";

import { downloadLocalFile, requestSignedUrl } from "../../../../../_metronic";
import { downloadFileTask } from "../../../../store/modules/actions/tasks.actions";
import { CloudDownloadIcon, DeleteIcon } from "../../../icons";
import SizeIndicator from "../../../size-indicator";

const useStylesForFileCard = makeStyles((theme) => {
	return {
		container: {
			padding: "10px 15px",
			background: "rgba(224, 231, 255, 0.2)",
			border: `1px solid ${theme.palette.extraColors.blueBorder}`,
			borderRadius: 4,
		},
		actionButton: {
			height: "24px !important",
			width: "24px !important",
		},
		fileName: {
			display: "block",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
		sizeContainer: {
			borderRadius: "5px",
		},
		fileNameContainer: {
			display: '-webkit-box',
			'-webkit-line-clamp': 2, /* number of lines to show */
			lineClamp: 2,
			'-webkit-box-orient': 'vertical',

			maxWidth: 165,
			overflow: "hidden",
			textOverflow: "ellipsis",

			[theme.breakpoints.down('sm')]: {
				maxWidth: 135,
			}
		},
	}
});

export default function FileCard({
	onClick,
	file,
	onDelete,
	canDownload,
	fileNameField,
}) {
	const classes = useStylesForFileCard();
	const dispatch = useDispatch();
	const filename = useMemo(() => {
		let name = null;

		if (fileNameField) {
			name = file[fileNameField];
		}

		if (!name) {
			name = file.name;
		}

		return name;
	}, [file, fileNameField]);
	const [isDownloading, setDownloading] = useState(false);

	const downloadFile = useCallback(async () => {
		if (!file._id) {
			if (file instanceof File) {
				downloadLocalFile(file, filename);
				return;
			}

			console.log(`file is not uploaded yet`);
			return null;
		}

		const { data } = await requestSignedUrl(file.url);

		setDownloading(true);

		try {
			await dispatch(
				downloadFileTask(
					data.url,
					filename
				)
			);
		}
		finally {
			setDownloading(false);
		}
	}, [
		file,
		filename,
		dispatch
	]);

	return (
		<div
			onClick={onClick}
			className={clsx(classes.container, "d-flex align-items-center bg-white cursor-pointer bg-light-blue")}
		>
			<div className="mr-2" title={filename}>
				<FileIcon
					height={22}
					width={18}
					filename={filename}
				/>
			</div>
			<div className="flex-grow-1 d-flex justify-content-between px-0 align-items-center">
				<div className={classes.fileNameContainer} title={filename}>
					{filename}
				</div>

				<div className="d-flex align-items-center align-items-md-stretch">
					<SizeIndicator
						className={clsx(classes.sizeContainer, "d-flex justify-content-center align-items-center px-2 text-primary")}
						sizeInBytes={file.size * 1024}
					/>

					{
						canDownload && (
							<div
								className={
									clsx(
										classes.actionButton,
										{ "pressed": isDownloading },
										"d-flex justify-content-center align-items-center cursor-pointer ml-10px"
									)
								}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										downloadFile();
									}}
								>
									<CloudDownloadIcon />
								</span>
							</div>
						)
					}

					{
						typeof onDelete === "function" && (
							<div
								className={clsx(classes.actionButton, "d-flex justify-content-center align-items-center cursor-pointer ml-10px")}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onDelete()
								}}
							>
								<DeleteIcon />
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
}
