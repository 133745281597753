import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import List from './List';
import CreateNew from './Create/New';
import CreateLegacy from './Create/Legacy';
import Edit from './Edit';

export default function Templates() {
	return (
		<Switch>
			<Route path="/templates/list" exact component={List} />
			<Route path="/templates/create/new" exact component={CreateNew} />
			<Route path="/templates/create/legacy" exact component={CreateLegacy} />
			<Route path="/templates/:template" exact component={Edit} />
			<Redirect to="/templates/list" />
		</Switch>
	);
}
