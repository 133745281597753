import * as NotificationsCRUD from '../../../crud/notifications.crud';
import { setErrorFlag, setLoadingFlag } from './loading-flag.actions';
import store from '../../store';

export const actionTypes = {
	NOTIFICATIONS_LOADED: "[NOTIFICATIONS] NOTIFICATIONS_LOADED",
	NOTIFICATIONS_CLEAR: "[NOTIFICATIONS] NOTIFICATIONS_CLEAR",
	UNREAD_COUNT: "[NOTIFICATIONS] UNREAD_COUNT",
	MARK_NOTIFICATIONS_READ: "[NOTIFICATIONS] MARK_NOTIFICATIONS_READ",
	NOTIFICATION_DELETED: "[NOTIFICATIONS] NOTIFICATION_DELETED",
};

export const flagNames = {
	LOADING_NOTIFICATIONS: "[NOTIFICATIONS] LOADING_NOTIFICATIONS",
	LOADING_UNREAD_COUNT: "[NOTIFICATIONS] LOADING_UNREAD_COUNT",
	MARKING_NOTIFICATIONS_READ: "[NOTIFICATIONS] MARKING_NOTIFICATIONS_READ",
	DELETING_NOTIFICATION: "[NOTIFICATIONS] DELETING_NOTIFICATION",
};

export const loadNotifications = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOADING_NOTIFICATIONS, true));
		const state = store.getState();
		const skip = state.notifications.list.length;

		try {
			const { status, data } = await NotificationsCRUD.getNotifications(skip);

			if (status === 200) {

				dispatch({
					type: actionTypes.NOTIFICATIONS_LOADED,
					payload: {
						list: data.data,
						total: data.count,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOADING_NOTIFICATIONS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOADING_NOTIFICATIONS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.LOADING_NOTIFICATIONS, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const markNotificationsAsRead = (notificationIds) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.MARKING_NOTIFICATIONS_READ, [notificationIds]));

		try {
			const { status, data } = await NotificationsCRUD.markNotificationAsRead(notificationIds);

			if (status === 200) {
				dispatch({
					type: actionTypes.MARK_NOTIFICATIONS_READ,
					payload: {
						notificationIds
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.MARKING_NOTIFICATIONS_READ, data.message));
			}

			dispatch(setLoadingFlag(flagNames.MARKING_NOTIFICATIONS_READ, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.MARKING_NOTIFICATIONS_READ, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const loadUnreadCount = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOADING_UNREAD_COUNT));
		
		try {
			const { status, data } = await NotificationsCRUD.getUnreadCount();

			if (status === 200) {
				dispatch({
					type: actionTypes.UNREAD_COUNT,
					payload: data
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOADING_UNREAD_COUNT, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOADING_UNREAD_COUNT, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.LOADING_UNREAD_COUNT, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const deleteNotification = (notificationId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.DELETING_NOTIFICATION));

		try {
			const { status, data } = await NotificationsCRUD.deleteNotification(notificationId);

			if (status === 200) {
				console.log(actionTypes.NOTIFICATION_DELETED, data);
				dispatch({
					type: actionTypes.NOTIFICATION_DELETED,
					payload: {
						notificationId,
					}
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.DELETING_NOTIFICATION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.DELETING_NOTIFICATION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.DELETING_NOTIFICATION, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const clearNotifications = () => {
	return {
		type: actionTypes.NOTIFICATIONS_CLEAR,
	};
};