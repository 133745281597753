const { LinearProgress, withStyles } = require("@material-ui/core");

const PrimaryBorderLinearProgressComponent = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#F0F3FD",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  }))(LinearProgress);

const DangerBorderLinearProgressComponent = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F0F3FD"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.error.main,
  },
}))(LinearProgress);

const SuccessBorderLinearProgressComponent = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F0F3FD",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}))(LinearProgress);

const SecondaryBorderLinearProgressComponent = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#F0F3FD",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#C8C8C8",
  },
}))(LinearProgress);

const DarkBorderLinearProgressComponent = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.dark.primary.contrastText,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.dark.primary.main,
  },
}))(LinearProgress);

export const PrimaryBorderLinearProgress = PrimaryBorderLinearProgressComponent;
export const DangerBorderLinearProgress = DangerBorderLinearProgressComponent;
export const SuccessBorderLinearProgress = SuccessBorderLinearProgressComponent;
export const SecondaryBorderLinearProgress = SecondaryBorderLinearProgressComponent;
export const DarkBorderLinearProgress = DarkBorderLinearProgressComponent;