import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
} from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl
} from "react-intl";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	useHistory
} from "react-router-dom";
import {
	Card,
	Fab,
	makeStyles,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import { uuid } from "uuidv4";
import { useSnackbar } from "notistack";

import Input from '../../../../common/Input';
import {
	PlusIcon,
	CancelIcon,
	EnvelopeIcon,
	FileErrorIcon,
	CircleCheckIcon
} from '../../../../common/icons';
import ItemCard from "../common/ItemCard";
import {
	loadTemplates,
	flagNames as templateFlagNames
} from '../../../../store/modules/actions/templates.actions';
import {
	createRequest,
	flagNames as requestFlagNames
} from '../../../../store/modules/actions/requests.actions';

import ProviderDetails from "../common/ProviderDetails";
import Confirm from "../../../../common/modals/confirm";
import Constants from "../../../../common/constants";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { copyTextToClipboard, globalGA } from "../../../../../_metronic";
import Preview from "../../../../common/Preview";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		addProviderContainer: {
			paddingLeft: 15
		},
		addProviderButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		addDocumentButton: {
			minHeight: "30px !important",
			height: "30px !important",
			width: "30px !important"
		},
		cancelIcon: {
			position: "absolute",
			right: 0,
			top: -15,
		}
	};
});

const DEFAULT_CUSTOM_ITEM = {
	enabled: false,
	name: '',
	description: '',
	shouldSaveNote: true,
	files: [],
	attachments: [],
	fileRename: [],
	type: Constants.DocumentType.REGULAR
};

const DEFAULT_PROVIDER = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	documents: [],
	templateId: '',
	smsNotification: false,
	reminderNotification: true,
	requiredPassword: false,
	dueDate: moment().startOf('day').add(4, 'days').toDate(),
	emailMeta: {
		subject: "",
		content: ""
	}
};

function CreateLegacyRequest(props) {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const [restrictions, isInReadOnlyMode] = useUserRestriction();
	
	const flags = useSelector(getFlags);

	const [confirmModalStatus, setConfirmModalStatus] = useState(false);
	const [previewInfo, setPreviewInfo] = useState(null);
	const [showAttachmentSizeWarning, setAttachmentSizeWarning] = useState(false);
	const [markAsSentModalStatus, setMarkAsSentModalStatus] = useState(false);
	const submittedManually = useRef(false);

	useEffect(() => {
		dispatch(loadTemplates());
		globalGA("request_creator_launched", {
			eventAction: "success",
			eventCategory: "request_creation_C1"
		});
	}, [dispatch]);

	const doesAttachmentsExceedsLimit = useCallback((provider, files) => {
		let existingAttachmentSizeKB = 0;

		provider.documents.forEach((doc) => {
			doc.attachments.forEach((attachment) => {
				existingAttachmentSizeKB += (attachment instanceof File ? (attachment.size / 1024) : attachment.size);
			});
		});

		const newFilesKB = files.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}, []);

	const markAsSentHandleClose = useCallback(async (isSuccess, values) => {
		if (isSuccess) {
			const providerData = values.providers.map((provider) => {
				if (!provider.templateId) {
					delete provider.templateId;
				}

				return {
					...provider,
				};
			});

			const { data } = await dispatch(
				createRequest({
					title: values.title,
					uuid: values.uuid,
					data: providerData,
					triggerNotification: values.triggerNotification
				})
			);

			if (providerData.length === 1) {
				try {
					const link = `${window.location.origin}/provider/${data.data._id}/${data.data.documentProviders[0]._id}`;
					await copyTextToClipboard(link);
					const msg = intl.formatMessage({ id: "REQUEST.MARK_AS_SENT.COPY_SUCCESS" });
					enqueueSnackbar(msg, { variant: 'success' });
				}
				catch (e) {
					enqueueSnackbar(e.message, { variant: 'error' });
				}
			}

			globalGA("mark_as_sent_request", {
				eventAction: "success",
				eventCategory: "request_creation_C1"
			});
			history.push('/requests');
		}
		else {
			setMarkAsSentModalStatus(false);
		}
	}, [dispatch, enqueueSnackbar, history, intl]);

	const confirmModalHandleClose = useCallback(async (isSuccess, values) => {
		if (isSuccess) {
			const providerData = values.providers.map((provider) => {
				if (!provider.templateId) {
					delete provider.templateId;
				}

				return {
					...provider,
				};
			});

			await dispatch(
				createRequest({
					title: values.title,
					uuid: values.uuid,
					data: providerData
				})
			);

			globalGA("send_request", {
				eventAction: "success",
				eventCategory: "request_creation_C1"
			});
			history.push('/requests');
		}
		else {
			setConfirmModalStatus(false);
		}
	}, [dispatch, history])

	if (flags.loading[templateFlagNames.TEMPLATES] || !restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand kt-spinner--center" />
			</div>
		);
	}

	if (isInReadOnlyMode || restrictions.requestAllowed <= 0) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<Alert color="danger">
					<FormattedHTMLMessage id="GENERAL.UPGRADE_MESSAGE_WITH_LINK" />
				</Alert>
			</div>
		);
	}

	return (
		<div className="create-request-container">
			<Formik
				initialValues={{
					uuid: uuid(),
					title: "",
					triggerNotification: true,
					providers: [
						{
							...DEFAULT_PROVIDER,
							uuid: uuid(),
						}
					]
				}}
				validate={values => {
					const error = {
						providers: [],
					};
					const providerWithErrors = {
						invalidData: {},
						noDocuments: {},
					};
					const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

					values.providers.forEach((provider, index) => {
						const providerError = {};

						if (!provider.firstName) {
							providerError.firstName = intl.formatMessage({
								id: "PROVIDER.FIRST_NAME.VALIDATION.REQUIRED"
							});
						}
						if (!provider.lastName) {
							providerError.lastName = intl.formatMessage({
								id: "PROVIDER.LAST_NAME.VALIDATION.REQUIRED"
							});
						}
						if (!provider.email) {
							providerError.email = intl.formatMessage({
								id: "PROVIDER.EMAIL.VALIDATION.REQUIRED"
							});
						} else if (!emailRegex.test(provider.email)) {
							providerError.email = intl.formatMessage({
								id: "PROVIDER.EMAIL.VALIDATION.INVALID"
							});
						}
						if (provider.dueDate) {
							if (moment(provider.dueDate).isBefore(moment().startOf('day'))) {
								providerError.dueDate = intl.formatMessage({
									id: "PROVIDER.DUE_DATE.VALIDATION.IN_PAST"
								});
							}
						}
						else {
							providerError.dueDate = intl.formatMessage({
								id: "PROVIDER.DUE_DATE.VALIDATION.REQUIRED"
							});
						}
						if (!_.isEmpty(providerError)) {
							providerWithErrors.invalidData[index] = true;
						}

						if (!provider.documents || !provider.documents.length) {
							providerWithErrors.noDocuments[index] = true;
							providerError.documents = intl.formatMessage({
								id: "PROVIDER.DOCUMENT.VALIDATION.REQUIRED"
							});
						}
						else {
							let hasDocumentError = false;

							providerError.documentErrors = provider.documents.map((doc) => {
								const err = {};

								if (!doc.name) {
									err.name = intl.formatMessage({
										id: "REQUEST.PROVIDER.ITEM.NAME.VALIDATION.REQUIRED"
									});
									hasDocumentError = true;
								}

								return err;
							});

							if (hasDocumentError) {
								providerWithErrors.invalidData[index] = true;
							}
							else {
								delete providerError.documentErrors;
							}
						}

						error.providers.push(providerError);
					});

					const hasErrors = error.providers.some((providerError) => Object.keys(providerError).length > 0);

					if (submittedManually.current) {
						const toastErrors = [];
						const invalidDataProviderIndexes = _.keys(providerWithErrors.invalidData);
						const noDocumentProviderIndexes = _.keys(providerWithErrors.noDocuments);

						if (invalidDataProviderIndexes.length) {
							if (invalidDataProviderIndexes.length === 1) {
								const index = invalidDataProviderIndexes[0];
								const provider = values.providers[index];
								const providerName = `${provider.firstName} ${provider.lastName}`.trim() || `Document Provider ${index + 1}`;
								const errorMessage = intl.formatMessage({
									id: "REQUEST.CREATE.PROVIDER.VALIDATION_FAILED"
								}, {
									providerName,
								});
								toastErrors.push(errorMessage);
							}
							else {
								const errorMessage = intl.formatMessage({
									id: "REQUEST.CREATE.PROVIDER.VALIDATION_FAILED.GENERIC"
								});
								toastErrors.push(errorMessage);
							}
						}

						if (noDocumentProviderIndexes.length) {
							if (noDocumentProviderIndexes.length === 1) {
								const index = noDocumentProviderIndexes[0];
								const provider = values.providers[index];
								const providerName = `${provider.firstName} ${provider.lastName}`.trim() || `Document Provider ${index + 1}`;
								const errorMessage = intl.formatMessage({
									id: "REQUEST.CREATE.PROVIDER.NO_DOCUMENTS_ERRORS"
								}, {
									providerName,
								});
								toastErrors.push(errorMessage);
							}
							else {
								const errorMessage = intl.formatMessage({
									id: "REQUEST.CREATE.PROVIDER.NO_DOCUMENTS_ERRORS.GENERIC"
								});
								toastErrors.push(errorMessage);
							}
						}

						toastErrors.forEach((errorMessage) => {
							const options = {
								variant: 'error',
								autoHideDuration: 5000
							};
							enqueueSnackbar(errorMessage, options);
						});

						submittedManually.current = false;
					}

					return hasErrors ? error : {};
				}}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={(values) => {
					if (values.triggerNotification) {
						setConfirmModalStatus(true);
					}
					else {
						setMarkAsSentModalStatus(true);
					}
				}}
			>
				{({
					values,
					status,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					setFieldTouched,
				}) => {
					return (
						<div>
							<div className="row">
								<div className="col-md-4 col-12">
									<Input
										containerClass="mb-30px"
										labelId="REQUEST.TITLE"
										type="text"
										variant="outlined"
										fullWidth
										name={`title`}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.title}
										helperText={_.get(touched, `title`) && _.get(errors, `title`)}
										error={Boolean(_.get(touched, `title`) && _.get(errors, `title`))}
									/>
								</div>
							</div>
							{
								values.providers.map((provider, providerIndex, arr) => {
									return (
										<Card
											elevation={0}
											className="mb-4 rounded-card provider-card"
											key={providerIndex}
										>
											<div className="row my-15 px-10">
												<div className="col-12 d-flex align-items-center px-0">
													<div className="provider-title flex-grow-1">
														<FormattedMessage id="REQUEST.PROVIDER.TITLE" values={{ index: providerIndex + 1 }} />
													</div>
													<div
														className={clsx("cursor-pointer ml-40", classes.cancelIcon)}
														onClick={() => setFieldValue(`providers`, values.providers.filter((_, index) => index !== providerIndex), true)}
													>
														<CancelIcon />
													</div>
												</div>
											</div>

											<ProviderDetails
												isInCreateMode
												index={providerIndex}
												fieldNamePrefix={`providers[${providerIndex}].`}
												provider={provider}
												handleBlur={handleBlur}
												handleChange={handleChange}
												setFieldValue={setFieldValue}
												setFieldTouched={() => { }}
												touched={_.get(touched, `providers.${providerIndex}`, {})}
												errors={_.get(errors, `providers.${providerIndex}`, {})}
											/>

											<hr className="my-30px" />

											<div className="row mt-4 px-0 w-100 m-0">
												{
													_.get(touched, `providers.${providerIndex}.templateId`) &&
													errors.providers &&
													errors.providers[providerIndex].documents && (
														<div className="col-12 mb-4 d-flex justify-content-center">
															<Alert color="danger">
																{errors.providers[providerIndex].documents}
															</Alert>
														</div>
													)
												}

												{
													provider.documents && provider.documents.length > 0 && provider.documents.map((item, itemIndex) => {
														const errorStatus = _.get(errors, `providers.${providerIndex}.documentErrors.${itemIndex}`);
														const touchedStatus = _.get(touched, `providers.${providerIndex}.documents.${itemIndex}`);

														return (
															<div className="col-12 mb-4" key={`${itemIndex}-${item.attachments.length}`}>
																<ItemCard
																	className="p-15"
																	item={item}
																	touched={touchedStatus}
																	error={errorStatus}

																	handleNameChange={(e) => {
																		const field = `providers[${providerIndex}].documents[${itemIndex}].name`;
																		setFieldTouched(field, true);
																		setFieldValue(
																			field,
																			e.target.value,
																			true
																		);
																	}}
																	handleTypeChange={(newType) => {
																		const field = `providers[${providerIndex}].documents[${itemIndex}].type`;
																		setFieldTouched(field, true);
																		setFieldValue(
																			field,
																			newType,
																			true
																		);
																	}}
																	handleDelete={() => {
																		setFieldValue(
																			`providers[${providerIndex}].documents`,
																			provider.documents.filter((doc, index) => index !== itemIndex),
																			true,
																		);
																	}}
																	handleMessageChange={(e) => {
																		const field = `providers[${providerIndex}].documents[${itemIndex}].description`;
																		setFieldTouched(field, true)
																		setFieldValue(
																			field,
																			e.target.value,
																			true
																		)
																	}}
																	handleSaveNoteChange={() => {
																		setFieldValue(
																			`providers[${providerIndex}].documents[${itemIndex}].shouldSaveNote`,
																			!item.shouldSaveNote,
																			true
																		);
																	}}

																	handleAddAttachments={(files) => {
																		const currentProvider = values.providers[providerIndex];

																		if (doesAttachmentsExceedsLimit(currentProvider, files)) {
																			setAttachmentSizeWarning(true);
																			return;
																		}

																		setFieldValue(
																			`providers[${providerIndex}].documents[${itemIndex}].attachments`,
																			[
																				...item.attachments,
																				...files
																			],
																			true
																		)
																	}}
																	handleDeleteAttachment={(index) => {
																		const newAttachments = item.attachments.filter((file, attachmentIndex) => attachmentIndex !== index)
																		setFieldValue(
																			`providers[${providerIndex}].documents[${itemIndex}].attachments`,
																			newAttachments,
																			true
																		)
																	}}

																	handleFileRenameAdd={() => {
																		const field = `providers[${providerIndex}].documents[${itemIndex}].fileRename`;
																		setFieldValue(
																			field,
																			[...item.fileRename, ""],
																			true
																		)
																	}}
																	handleFileRenameChange={(index, value) => {
																		const field = `providers[${providerIndex}].documents[${itemIndex}].fileRename[${index}]`;
																		setFieldTouched(field, true);
																		setFieldValue(field, value, true)
																	}}
																	handleFileRenameRemove={(index) => {
																		const updatedFileRename = item.fileRename.filter((v, i) => i !== index);
																		setFieldValue(
																			`providers[${providerIndex}].documents[${itemIndex}].fileRename`,
																			updatedFileRename,
																			true
																		)
																	}}

																	selectAttachmentForPreview={(attachmentIndex) => setPreviewInfo({
																		providerIndex,
																		itemIndex,
																		attachmentIndex
																	})}
																/>
															</div>
														);
													})
												}
											</div>

											<div className="row">
												<div
													className="cursor-pointer col-12 col-sm-6 col-md-4 mb-4 mb-md-0"
													onClick={() => {
														globalGA("add_document", {
															eventAction: "success",
															eventCategory: "request_creation_C1"
														});
														setFieldValue(
															`providers[${providerIndex}].documents`,
															[
																...provider.documents,
																{
																	...DEFAULT_CUSTOM_ITEM,
																	uuid: uuid(),
																	enabled: true,
																}
															],
															true
														);
													}}
												>
													<div className="d-flex align-items-center">
														<Fab
															className={clsx(classes.addDocumentButton, "bg-white text-primary")}
															size="small"
														>
															<PlusIcon />
														</Fab>
														<div>
															<span className="ml-15 f-15">
																<FormattedMessage id="TEMPLATE.ADD_DOCUMENT" />
															</span>
														</div>
													</div>
												</div>
											</div>
										</Card>
									);
								})
							}

							{
								flags.error[requestFlagNames.CREATE] && (
									<div className="d-flex justify-content-center mt-5">
										<Alert color="danger">
											{flags.error[requestFlagNames.CREATE]}
										</Alert>
									</div>
								)
							}

							<div className="row mb-4">
								<div className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start mb-5 mb-md-0">
									<div
										className={clsx(
											classes.addProviderContainer,
											"d-flex align-items-center flex-column flex-sm-row",
											{ "cursor-pointer": values.providers.length < 4 }
										)}
										onClick={() => {
											if (values.providers.length >= 4) return;

											setFieldValue(
												'providers',
												[
													...values.providers,
													{
														...DEFAULT_PROVIDER,
														uuid: uuid(),
													}
												],
												true
											);
										}}
									>
										<Fab
											className={clsx(classes.addProviderButton, "mb-2 mb-sm-0 text-white bg-primary")}
											disabled={values.providers.length >= 4}
										>
											<PlusIcon fill="white" />
										</Fab>
										<div>
											<span className="ml-0 ml-sm-3 f-15 text-dark">
												<FormattedMessage id="REQUEST.ADD_PROVIDER" />
											</span>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
									<div className="d-flex flex-column align-items-center">
										<button
											type="button"
											className={`py-4 btn btn-lg float-right ${clsx({
												"btn-primary": !(flags.loading[requestFlagNames.CREATE] ||
													flags.error[requestFlagNames.CREATE] ||
													!values.providers.length),
												"btn-secondary": flags.loading[requestFlagNames.CREATE] ||
													flags.error[requestFlagNames.CREATE] ||
													!values.providers.length
											})}`}
											disabled={
												flags.loading[requestFlagNames.CREATE] ||
												flags.error[requestFlagNames.CREATE] ||
												!values.providers.length
											}
											onClick={() => {
												submittedManually.current = true;
												handleSubmit();
											}}
										>
											<FormattedMessage id="REQUESTS.CREATE.SUBMIT" />
										</button>

										<div className="d-flex justify-content-center mt-5px">
											<div className="mr-1">
												<FormattedMessage id="REQUESTS.CREATE.OR" />
											</div>
											<div
												className="text-primary cursor-pointer"
												onClick={() => {
													setFieldValue('triggerNotification', false);
													submittedManually.current = true;
													handleSubmit();
												}}
											>
												<FormattedMessage id="REQUESTS.CREATE.MARK_AS_SENT" />
											</div>
										</div>
									</div>
								</div>
							</div>

							{
								!!confirmModalStatus && (
									<Confirm
										open
										icon={<EnvelopeIcon />}
										variant="primary"
										handleClose={async (isSuccess) => {
											if (!isSuccess) {
												setFieldValue('triggerNotification', true);
											}
											confirmModalHandleClose(isSuccess, values);
										}}
										title="REQUEST.CREATE.CONFIRM"
										submitButtonText="GENERAL.YES"
										cancelButtonText="GENERAL.NO"
										loading={flags.loading[requestFlagNames.CREATE]}
										error={flags.error[requestFlagNames.CREATE]}
									/>
								)
							}

							{
								!!markAsSentModalStatus && (
									<Confirm
										open
										icon={<CircleCheckIcon />}
										variant="primary"
										handleClose={(isSuccess) => {
											if (!isSuccess) {
												setFieldValue('triggerNotification', true);
											}
											markAsSentHandleClose(isSuccess, values);
										}}
										title="REQUEST.MARK_AS_SENT.CONFIRM"
										submitButtonText="GENERAL.YES"
										cancelButtonText="GENERAL.NO"
										loading={flags.loading[requestFlagNames.CREATE]}
										error={flags.error[requestFlagNames.CREATE]}
									/>
								)
							}

							{
								previewInfo &&
								previewInfo.providerIndex > -1 &&
								previewInfo.itemIndex > -1 &&
								previewInfo.attachmentIndex > -1 && (
									<Preview
										file={
											values
												.providers[previewInfo.providerIndex]
												.documents[previewInfo.itemIndex]
												.attachments[previewInfo.attachmentIndex]
										}
										handleClose={() => setPreviewInfo(null)}
									/>
								)
							}
						</div>
					);
				}}
			</Formik>

			<Confirm
				open={showAttachmentSizeWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setAttachmentSizeWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>
		</div>
	);
}

export default CreateLegacyRequest;