import React, {
	useState,
	useEffect,
	useCallback
} from "react";
import moment from 'moment';
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl
} from "react-intl";
import {
	useSelector,
	connect,
	useDispatch
} from "react-redux";
import {
	useHistory,
} from "react-router-dom";
import {
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableSortLabel,
	TableCell,
	TablePagination,
	FormControlLabel,
	Checkbox,
	makeStyles,
	InputAdornment,
	useTheme,
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	AppBar,
	Toolbar,
	Typography,
	IconButton
} from '@material-ui/core';
import { Add, Close, CloseOutlined } from "@material-ui/icons";
import { Alert } from "reactstrap";
import clsx from "clsx";

import {
	loadTemplates,
	selectTemplate,
	flagNames,
	updateFilter,
} from '../../../../store/modules/actions/templates.actions';
import DeleteModal from "./Delete";
import { DeleteIcon, SearchIcon } from "../../../../common/icons";
import useDebounce from "../../../../common/useDebounce";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import { setUpgradeDialog } from "../../../../store/modules/actions/app.actions";
import Input from '../../../../common/Input';
import { getTemplatesFilter, getTemplatesList } from "../../../../store/modules/selectors/template.selector";
import { getFlags } from "../../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		createTemplateButton: {
			minHeight: 30,
			height: 30,
			width: 30
		},
		table: {
			borderCollapse: "separate",
			borderSpacing: "0 7px",
		},
	}
});

const DarkCheckbox = withStyles((theme) => {
	return {
		root: {
			color: theme.palette.dark.primary.main,
			'&$checked': {
				color: theme.palette.dark.primary.main,
			},
		},
		checked: {},
	}
})((props) => <Checkbox color="default" {...props} />);

function List(props) {
	const {
		loadTemplates,
	} = props;

	const intl = useIntl();
	const [restrictions, isInReadOnlyMode] = useUserRestriction();
	const dispatch = useDispatch();
	const theme = useTheme();
	const history = useHistory();
	
	const [deleteTemplate, setDeleteTemplate] = useState(null);
	const [showSelection, setShowSelection] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage] = useState(10);
	
	const templates = useSelector(getTemplatesList);
	const filter = useSelector(getTemplatesFilter);
	const flags = useSelector(getFlags);

	const [search, setSearch] = useState(filter.search);
	const debouncedSearch = useDebounce(search, 750);

	useEffect(() => {
		dispatch(updateFilter({ search: debouncedSearch }))
	}, [ debouncedSearch, dispatch ])

	useEffect(() => {
		loadTemplates();
	}, [ loadTemplates ]);

	const rows = templates.filter((template) => {
		if (filter.search) {
			const templateName = template.name.toLowerCase();
			const searchText = filter.search.trim().toLowerCase();

			if (!templateName.includes(searchText)) {
				return false;
			}
		}

		if (!filter.includeClassicTemplates && template.format === "CLASSIC") {
			return false;
		}

		if (!filter.includeModernTemplates && template.format === "MODERN") {
			return false;
		}

		return true;
	});

	const closeDeleteModal = useCallback(() => {
		selectTemplate(null);
		setDeleteTemplate(null);
	}, []);
	const handleAddTemplate = useCallback(() => {
		if (isInReadOnlyMode) {
			dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
			return;
		}
		
		if (restrictions.features.FORM_REQUEST) {
			setShowSelection(true);
		}
		else {
			history.push("/templates/create/legacy");
		}
	}, [
		dispatch,
		isInReadOnlyMode,
		restrictions,
		history,
	]);

	const handleDeleteTemplate = useCallback((templateId) => {
		if (isInReadOnlyMode) {
			dispatch(setUpgradeDialog("GENERAL.UPGRADE_MESSAGE"));
			return;
		}

		setDeleteTemplate(templateId);
	}, [
		dispatch,
		isInReadOnlyMode,
	])


	if (flags.loading[flagNames.TEMPLATES] || !restrictions) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center mt-5">
				<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
			</div>
		);
	}
	
	if (!restrictions.features.TEMPLATES) {
		return (
			<div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
				<Alert color="danger">
					<FormattedHTMLMessage id="GENERAL.UPGRADE_MESSAGE_WITH_LINK" />
				</Alert>
			</div>
		);
	}

	return (
		<div className="container template-list-container">
			<div className="f-25px font-weight-bold text-dark mb-25px">
				<FormattedMessage id="MENU.TEMPLATES" />
			</div>

			<div className="row mb-35px">
				<div className="col-12 col-md-5">
					<Input
						type="text"
						variant="outlined"
						InputProps={{
							classes: {
								root: "blue-border"
							},
						}}
						fullWidth
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						placeholder={
							intl.formatMessage({
								id: "TEMPLATE.LIST.SEARCH"
							})
						}
						startAdornment={
							<InputAdornment position="start">
								<SearchIcon fill={theme.palette.extraColors.lightText} />
							</InputAdornment>
						}
						endAdornment={
							<div
								className={clsx("cursor-pointer px-1 py-3", { "d-none": !search })}
								onClick={() => setSearch("")}
							>
								<Close />
							</div>
						}
					/>
				</div>
				<div className="offset-md-3 col-md-4 col-12 d-flex justify-content-md-end justify-content-start">
					<button
						className="btn btn-primary d-flex"
						disabled={!restrictions}
						onClick={handleAddTemplate}
					>
						<div>
							<Add fill="white" />
						</div>
						<div>
							<FormattedMessage id="TEMPLATE.CREATE" />
						</div>
					</button>
				</div>
			</div>

			<div className="row text-primary-dark">
				<div className="d-flex align-items-center col-md-8 col-12">
					<div className="mb-0 mb-md-2 mr-3">
						<FormattedMessage id="TEMPLATE.LIST.FILTER.VISIBILITY" />
					</div>

					<div>
						<FormControlLabel
							control={
								<DarkCheckbox
									checked={filter.includeClassicTemplates}
									onChange={(e) => dispatch(updateFilter({ includeClassicTemplates: !filter.includeClassicTemplates }))}
									color="primary"
								/>
							}
							label={
								intl.formatMessage({
									id: "TEMPLATE.LIST.FILTER.VISIBILITY.CLASSIC"
								})
							}
						/>
					</div>

					<div>
						<FormControlLabel
							control={
								<DarkCheckbox
									checked={filter.includeModernTemplates}
									onChange={(e) => dispatch(updateFilter({ includeModernTemplates: !filter.includeModernTemplates }))}
									color="primary"
								/>
							}
							label={
								intl.formatMessage({
									id: "TEMPLATE.LIST.FILTER.VISIBILITY.MODERN"
								})
							}
						/>
					</div>
				</div>

				<div className="col-12 col-md-4">
					<TablePagination
						rowsPerPageOptions={[10]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={(e, newPage) => setPage(newPage)}
						onRowsPerPageChange={() => { }}
					/>
				</div>
			</div>
			
			<TemplateTable
				rows={rows}
				onDelete={handleDeleteTemplate}
				page={page}
				rowsPerPage={rowsPerPage}
			/>

			{
				deleteTemplate && (
					<DeleteModal
						open
						template={deleteTemplate}
						handleClose={closeDeleteModal}
					/>
				)
			}

			<Dialog
				open={showSelection}
				onClose={() => setShowSelection(false)}
			>
				<DialogTitle className="p-0">
					<AppBar
						elevation={0}
						position="static"
						classes={{
							root: "rounded-top bg-light"
						}}
					>
						<Toolbar classes={{ root: "pr-1 border-bottom text-dark" }}>
							<div className="flex-grow-1">
								<Typography
									variant="span"
									className="f-16px"
								>
									<FormattedMessage id="TEMPLATE.NEW" />
								</Typography>
							</div>
							<div>
								<IconButton onClick={() => setShowSelection(false)}>
									<CloseOutlined />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
				</DialogTitle>
				<DialogContent>
					<div className="row my-10px">
						<div className="col-12 mb-20px">
							<div className="f-14px text-muted">
								<FormattedMessage id="TEMPLATE.SELECT_TYPE" />
							</div>
						</div>
						<div className="col-6">
							<div
								className="rounded p-15px border shadow"
								style={{ background: theme.palette.extraColors.backgroundBlue }}
							>
								<div className="f-18px text-dark">
									<FormattedMessage id="TEMPLATE.CREATE.SELECTION.NEW.TITLE" />
								</div>

								<div className="f-14px font-weight-light text-dark mb-20px">
									<FormattedMessage id="TEMPLATE.CREATE.SELECTION.NEW.SUBTITLE" />
								</div>

								<div>
									<button
										className="btn bg-primary text-light"
										onClick={() => history.push("/templates/create/new")}
									>
										<FormattedMessage id="TEMPLATE.CREATE.SELECTION.NEW.SUBMIT" />
									</button>
								</div>
							</div>
						</div>

						<div className="col-6">
							<div className="rounded p-15px border bg-white">
								<div className="f-18px">
									<FormattedMessage id="TEMPLATE.CREATE.SELECTION.LEGACY.TITLE" />
								</div>

								<div className="f-14px font-weight-light mb-20px">
									<FormattedMessage id="TEMPLATE.CREATE.SELECTION.LEGACY.SUBTITLE" />
								</div>

								<div>
									<button
										className="btn text-dark"
										style={{ background: theme.palette.extraColors.backgroundBlue }}
										onClick={() => history.push("/templates/create/legacy")}
									>
										<FormattedMessage id="TEMPLATE.CREATE.SELECTION.LEGACY.SUBMIT" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function TemplateTable(props) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('index');
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles();

	function desc(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function stableSort(array, cmp) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = cmp(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map(el => el[0]);
	}

	function getSorting(order, orderBy) {
		return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
	}

	function handleRequestSort(property) {
		const isDesc = orderBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderBy(property);
	}

	const {
		rows,
		onDelete,
	} = props;

	const headRows = [
		{ id: 'index', label: 'Index', className: 'd-none d-md-table-cell' },
		{ id: 'name', label: 'Name', },
		{ id: 'type', label: 'Type', },
		{ id: 'documents', label: 'Documents Count', className: 'text-center ml-3', labelClassName: 'ml-4' },
		{ id: 'createdAt', label: 'Created At', className: 'd-none d-md-table-cell ' },
		// { id: 'actions', },
	];

	const finalRows = stableSort(rows, getSorting(order, orderBy))
		.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage);

	return (
		<React.Fragment>
			<div className="d-none d-sm-block">
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							{
								headRows.map(row => (
									<TableCell
										key={row.id}
										sortDirection={orderBy === row.id ? order : false}
										className={`border-bottom-0 ${row.className}`}
									>
										<TableSortLabel
											active={orderBy === row.id}
											direction={order}
											onClick={() => handleRequestSort(row.id)}
											className={clsx("text-primary-dark f-12px", row.labelClassName)}
											classes={{ icon: "text-primary-dark" }}
										>
											{row.label}
										</TableSortLabel>
									</TableCell>
								))
							}
						</TableRow>
					</TableHead>

					<TableBody>
						{
							finalRows.map((row, index) => {
								const isModern = row.format === "MODERN";
								const documentCount = isModern ? row.elements.length : row.items.length;
								return (
									<TableRow
										hover
										style={{ height: 70 }}
										key={index}
										className="bg-white cursor-pointer"
										onClick={() => history.push(`/templates/${row._id}`)}
									>
										<TableCell
											component="th"
											scope="row"
											className="d-none d-md-table-cell f-15 text-dark"
										>
											{index}
										</TableCell>
										<TableCell className="f-15 text-dark">
											{row.name}
										</TableCell>
										<TableCell className="f-15 text-dark">
											{isModern ? "Modern" : "Classic"}
										</TableCell>
										<TableCell className="f-15 text-dark text-center">
											{documentCount < 9 ? `0${documentCount}` : documentCount}
										</TableCell>
										<TableCell className="d-none d-md-table-cell f-15 text-dark">
											{moment(row.createdAt).format('DD/MM/YYYY')}
										</TableCell>

										<TableCell className="d-none d-md-table-cell f-15 text-dark">
											<div className="d-flex justify-content-center">
												<div
													className="d-flex justify-content-center align-items-center cursor-pointer p-2"
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();

														onDelete(row._id)
													}}
												>
													<div className="d-flex">
														<DeleteIcon fill={theme.palette.dark.primary.main} />
													</div>
												</div>
											</div>
										</TableCell>
										{
											// <TableCell onClick={(e) => e.stopPropagation()} className="text-center">
											// 	<UncontrolledDropdown direction="left">
											// 		<DropdownToggle className={`${classes.actionButton} border-0 p-0`}>
											// 			<MoreVert />
											// 		</DropdownToggle>
											// 		<DropdownMenu>
											// 			<DropdownItem
											// 				className="bg-danger text-light"
											// 				onClick={() => onDelete(row._id)}
											// 			>
											// 				<Delete className="mr-2" />
											// 				<FormattedMessage id="TEMPLATE.DELETE.SUBMIT" />
											// 			</DropdownItem>
											// 		</DropdownMenu>
											// 	</UncontrolledDropdown>
											// </TableCell>
										}
									</TableRow>
								);
							})
						}
					</TableBody>
				</Table>
			</div>

			<div className="d-block d-sm-none">
				{
					finalRows.map((row, index) => {
						return (
							<div
								className="p-25px border rounded"
								key={index}
								id={index}
							>
								<div className="row mb-15px">
									<div className="col-6 input-label">
										{headRows[0].label}
									</div>
									<div className="col-6 text-dark">
										{index}
									</div>
								</div>

								<div className="row mb-15px">
									<div className="col-6 input-label">
										{headRows[1].label}
									</div>
									<div className="col-6 text-dark">
										{row.name}
									</div>
								</div>

								<div className="row mb-15px">
									<div className="col-6 input-label">
										{headRows[2].label}
									</div>
									<div className="col-6 text-dark">
										{row.items.length}
									</div>
								</div>

								<div className="row">
									<div className="col-6 input-label">
										{headRows[3].label}
									</div>
									<div className="col-6 text-dark">
										{moment(row.createdAt).format('DD/MM/YYYY')}
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</React.Fragment>
	)
}

export default connect(
	null,
	{
		loadTemplates,
		selectTemplate,
	}
)(List)
