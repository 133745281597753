import constants from "../../../common/constants";

export const getFlags = (store) => store.flags;

export const getBankStatementUrl = (store) => {
  const { integrations } = store;
  const bankStatementIntegration = integrations.list.find(({ slug }) => slug === constants.IntegrationsType.BANK_STATEMENTS);
  const config = bankStatementIntegration?.config;
  const isBankStatementIntegrationActive = config?.isActive;
  const bankStatementUrl = config?.configObj?.url;
  
  return isBankStatementIntegrationActive && bankStatementUrl;
};
