export const actionTypes = {
	SET_UPGRADE_DIALOG: '[APP] SET_UPGRADE_DIALOG',
	SET_ELEMENT_DRAGGING_STATE: '[APP] SET_ELEMENT_DRAGGING_STATE'
}

export const setUpgradeDialog = (messageId) => {
	return {
		type: actionTypes.SET_UPGRADE_DIALOG,
		payload: {
			messageId,
		}
	};
};
