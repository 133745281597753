import { makeStyles, useTheme } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { validateDropdown } from '../../ElementPicker';
import ErrorMessage from '../ErrorMessage';
import Option from './option';
import useDebounce from '../../../useDebounce';
import AutoExpandInput from '../../../auto-expand-input';

const useStyles = makeStyles((theme) => {
	return {
		dropdown: {
			[theme.breakpoints.up('sm')]: {
				maxWidth: theme.elementSizes.dropdown.maxWidth,
			}
		}
	};
});

const Dropdown = forwardRef((props, _ref) => {
	const {
		id,
		data,
		response,
		onChange,
		onChangeResponse,
		isActive,
	} = props;
	const theme = useTheme();
	const intl = useIntl();
	const classes = useStyles();

	const [title, setTitle] = useState(data.title);
	const debouncedTitle = useDebounce(title, 750);

	const [subtitle, setSubtitle] = useState(data.subtitle);
	const debouncedSubtitle = useDebounce(subtitle, 750);
	
	const [error, setError] = useState({});

	useEffect(() => {
		setTitle(data.title);
	}, [data.title]);
	useEffect(() => {
		setSubtitle(data.subtitle);
	}, [data.subtitle]);

	useEffect(() => {
		const newData = {
			...data,
			title: debouncedTitle,
			subtitle: debouncedSubtitle,
		};
		const error = validateDropdown(newData);

		onChange(newData);
		setError(error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedTitle,
		debouncedSubtitle,
	]);

	const updateOptions = useCallback((newData) => {
		onChange(newData);
	}, [onChange]);

	const handleOptionDrop = useCallback((result) => {
		const newOptions = Array.from(data.options);
		const [removed] = newOptions.splice(result.source.index, 1);
		newOptions.splice(result.destination.index, 0, removed);

		const newData = {
			...data,
			options: newOptions,
		};

    	updateOptions(newData);
  	}, [data, updateOptions]);

	const addOption = useCallback((index) => {
		const newOption = {
			title: '',
			id: data.options.length.toString(),
		};
		const newOptions = data.options.slice()
		
		newOptions.splice(index + 1, 0, newOption);

		const newData = {
			...data,
			options: newOptions,
		};

		updateOptions(newData);

		const error = validateDropdown(newData);
		setError(error);
	}, [data, updateOptions]);

	const removeOption = useCallback((index) => {
		const newOptions = data.options.slice();
		newOptions.splice(index, 1);

		const newData = {
			...data,
			options: newOptions,
		};

		updateOptions(newData);

		const error = validateDropdown(newData);
		setError(error);
	}, [data, updateOptions]);

	const handleOptionChange = useCallback((e, index) => {
		const newOptionTitle = e.target.value;
		const newOptions = data.options.map((option, optionIndex) => {
			if (optionIndex === index) {
				return {
					...option,
					title: newOptionTitle,
				}
			}

			return option;
		});

		const newData = {
			...data,
			options: newOptions,
		};

		updateOptions(newData);

		const error = validateDropdown(newData);
		setError(error);
	}, [data, updateOptions]);

	const handleKeyPress = useCallback((e, index) => {
		if (e.key === 'Enter') {
			addOption(index);
			e.preventDefault();
		}
	}, [
		addOption,
	]);

	const handleResponseChange = useCallback((option) => {
		onChangeResponse({
			...response,
			value: option.id,
		});
	}, [onChangeResponse, response]);

	return (
		<div>
			<div>
				<AutoExpandInput
					className="f-18px mb-0 text-dark font-weight-medium"
					onChange={(e) => setTitle(e.target.value)}
					value={title}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.DROPDOWN.TITLE.PLACEHOLDER"
						})
					}
				/>
			</div>

			{
				error.title && (
					<ErrorMessage message={error.title} />
				)
			}

			<div>
				<AutoExpandInput
					className="f-14px mb-0 w-100 text-dark font-weight-regular pb-0 break-word"
					style={{ color: theme.palette.extraColors.lightText }}
					onChange={(e) => setSubtitle(e.target.value)}
					value={subtitle}
					placeholder={
						intl.formatMessage({
							id: "ELEMENT_LIST.ITEM.SHORT_TEXT.SUBTITLE.PLACEHOLDER"
						})
					}
					allowHTML
				/>
			</div>

			<div className="my-2">
				<button
					className="btn btn-primary py-1 d-flex justify-content-center align-items-center pl-2 pr-3"
					onClick={() => addOption(data.options.length - 1)}
				>
					<div>
						<AddOutlined  />
					</div>
					<div>
						<FormattedMessage
							id="ELEMENT_LIST.ITEM.DROPDOWN.ADD_OPTION.TITLE"
						/>
					</div>
				</button>
			</div>

			{
				error.optionsMain && (
					<ErrorMessage message={error.optionsMain} />
				)
			}

			<div className={classes.dropdown}>
				<DragDropContext onDragEnd={handleOptionDrop}>
					<Droppable droppableId={`${id}-option-list`}>
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
							>
								{
									data.options.map((option, index) => (
										<Draggable
											key={option.id}
											draggableId={option.id}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													id={`${id}-option-list-option-${index}`}
													ref={provided.innerRef}
													{...provided.draggableProps}
												>
													<Option
														index={index}
														dragHandleProps={provided.dragHandleProps}
														data={option}
														onChange={(e) => handleOptionChange(e, index)}
														onSelect={() => handleResponseChange(option)}
														onKeyPress={(e) => handleKeyPress(e, index)}
														onDelete={() => removeOption(option.id)}
														isElementActive={isActive}
														error={error?.options?.[index]}
														isSelected={response.value === option.id}
													/>
												</div>
											)}
										</Draggable>
									))
								}

								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		</div>
	)
});

export default Dropdown;
