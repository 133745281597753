import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	FormattedMessage,
} from "react-intl";
import clsx from "clsx";
import {
	makeStyles, ClickAwayListener
} from "@material-ui/core";

import IOSSwitch from '../../../common/IOSSwitch';
import LightTooltip from "../../../common/LightTooltip";
import { flagNames, updateNotificationSettings } from "../../../store/modules/actions/auth.actions";
import { getUser } from "../../../store/modules/selectors/account.selector";
import { getFlags } from "../../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		colHeader: {
			textTransform: "uppercase",
			color: theme.palette.dark.primary.main,
			fontSize: 12,
		},
		switchContainer: {
			minWidth: 60,
		}
	}
});

function NotificationSettings() {
	const dispatch = useDispatch();
	const classes = useStyles();

	const user = useSelector(getUser);
	const flags = useSelector(getFlags);

	const hasSmsServiceEnabled = useMemo(() => user.smsService, [user.smsService]);
	const notificationSettings = useMemo(() => user.notificationSettings, [user.notificationSettings]);

	const [clientNotificationSMSTooltip, setClientNotificationSMSTooltip] = useState(false);
	const [yourNotificationSMSTooltip, setYourNotificationSMSTooltip] = useState(false)
	const hideSMSNotificationForKeys = ['DAILY_REPORTS'];
	const updatingFieldInfo = flags.loading[flagNames.UPDATE_NOTIFICATION_SETTINGS];

	const updateNotificationSetting = useCallback((type, key, field, newValue) => {
		dispatch(updateNotificationSettings(type, key, field, newValue));
	}, [ dispatch ]);

	const settingTypes = _.groupBy(notificationSettings, 'type');

	return (
		<div className="row pb-4">
			<div className="col-12 col-md-6">
				<div className="px-20px pb-20px mb-4 mb-md-0 bg-white rounded">
					<div className="f-20px text-dark pt-20px">
						<FormattedMessage id="NOTIFICATION_SETTINGS.CLIENT_NOTIFICATIONS.TITLE" />
					</div>

					<div className="mt-20px">
						<div className="d-flex justify-content-end mb-20px">
							<div className={clsx("text-center", classes.colHeader, classes.switchContainer)}>
								<FormattedMessage id="NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.EMAIL" />
							</div>
							<div className={clsx("text-center", classes.colHeader, classes.switchContainer)}>
								<FormattedMessage id="NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.SMS" />

								{
									!hasSmsServiceEnabled && (
										<ClickAwayListener onClickAway={() => setClientNotificationSMSTooltip(false)}>
											<FormattedMessage id={`NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.SMS.TOOLTIP`}>
												{
													(text) => (
														<LightTooltip
															open={clientNotificationSMSTooltip}
															disableFocusListener
															disableHoverListener
															disableTouchListener
															className="ml-1"
															title={
																<div dangerouslySetInnerHTML={{ __html: text }} />
															}
															placement="bottom"
															arrow
														>
															<span onClick={() => setClientNotificationSMSTooltip(true)}>
																<i className={clsx(classes.colHeader, "fas fa-info-circle cursor-pointer")} />
															</span>
														</LightTooltip>
													)
												}
											</FormattedMessage>
										</ClickAwayListener>
									)
								}
							</div>
						</div>

						{
							_.map(settingTypes.CLIENT_NOTIFICATION, (settings) => {
								const isBeingUpdated = updatingFieldInfo && updatingFieldInfo.type === "CLIENT_NOTIFICATION" && updatingFieldInfo.key === settings.key;

								return (
									<div className="d-flex mb-20px" key={settings.key}>
										<div className="flex-grow-1 flex-shrink-1">
											<div className="text-dark f-15px">
												<FormattedMessage id={`NOTIFICATION_SETTINGS.CLIENT_NOTIFICATION.${settings.key}_TITLE`} />
											</div>
											<div className="text-dark f-14px fw-300">
												<FormattedMessage id={`NOTIFICATION_SETTINGS.CLIENT_NOTIFICATION.${settings.key}_SUBTITLE`} />
											</div>
										</div>
										<div className={clsx(classes.switchContainer, "d-flex justify-content-center align-items-center")}>
											<IOSSwitch
												rootClass={clsx(
													"m-0",
													{ "opacity-30": isBeingUpdated && updatingFieldInfo.field === "email" }
												)}
												checked={settings.email}
												enabledTrackClass="bg-primary"
												onChange={() => !isBeingUpdated && updateNotificationSetting("CLIENT_NOTIFICATION", settings.key, "email", !settings.email)}
											/>
										</div>
										<div className={clsx(classes.switchContainer, "d-flex justify-content-center align-items-center")}>
											<IOSSwitch
												rootClass={clsx(
													"m-0",
													{ "opacity-30": isBeingUpdated && updatingFieldInfo.field === "sms" }
												)}
												checked={hasSmsServiceEnabled && settings.sms}
												enabledTrackClass="bg-primary"
												onChange={() => !isBeingUpdated && hasSmsServiceEnabled && updateNotificationSetting("CLIENT_NOTIFICATION", settings.key, "sms", !settings.sms)}
											/>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<div className="col-12 col-md-6">
				<div className="px-20px pb-20px bg-white rounded">
					<div className="f-20px text-dark pt-20px">
						<FormattedMessage id="NOTIFICATION_SETTINGS.YOUR_NOTIFICATIONS.TITLE" />
					</div>

					<div className="mt-20px">
						<div className="d-flex justify-content-end mb-20px">
							<div className={clsx("offset-8 offset-md-8 text-center", classes.colHeader, classes.switchContainer)}>
								<FormattedMessage id="NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.EMAIL" />
							</div>
							<div className={clsx("text-center", classes.colHeader, classes.switchContainer)}>
								<FormattedMessage id="NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.SMS" />

								{
									!hasSmsServiceEnabled && (
										<ClickAwayListener onClickAway={() => setYourNotificationSMSTooltip(false)}>
											<FormattedMessage id={`NOTIFICATION_SETTINGS.NOTIFICATION_TYPE.SMS.TOOLTIP`}>
												{
													(text) => (
														<LightTooltip
															open={yourNotificationSMSTooltip}
															disableFocusListener
															disableHoverListener
															disableTouchListener
															className="ml-1"
															title={
																<div dangerouslySetInnerHTML={{ __html: text }} />
															}
															placement="bottom"
															arrow
														>
															<span onClick={() => setYourNotificationSMSTooltip(true)}>
																<i className={clsx(classes.colHeader, "fas fa-info-circle cursor-pointer")} />
															</span>
														</LightTooltip>
													)
												}
											</FormattedMessage>
										</ClickAwayListener>
									)
								}
							</div>
						</div>

						{
							_.map(settingTypes.YOUR_NOTIFICATION, (settings) => {
								const isBeingUpdated = updatingFieldInfo && updatingFieldInfo.type === "YOUR_NOTIFICATION" && updatingFieldInfo.key === settings.key;

								return (
									<div className="d-flex mb-20px" key={settings.key}>
										<div className="flex-grow-1 flex-shrink-1">
											<div className="f-15px text-dark">
												<FormattedMessage id={`NOTIFICATION_SETTINGS.YOUR_NOTIFICATION.${settings.key}_TITLE`} />
											</div>
											<div className="f-14px text-dark fw-300">
												<FormattedMessage id={`NOTIFICATION_SETTINGS.YOUR_NOTIFICATION.${settings.key}_SUBTITLE`} />
											</div>
										</div>
										<div className={clsx(classes.switchContainer, "d-flex justify-content-center align-items-center")}>
											<IOSSwitch
												rootClass={clsx(
													"m-0",
													{ "opacity-30": isBeingUpdated && updatingFieldInfo.field === "email" }
												)}
												checked={settings.email}
												enabledTrackClass="bg-primary"
												disabled={isBeingUpdated && updatingFieldInfo.field === "email"}
												onChange={() => !isBeingUpdated && updateNotificationSetting("YOUR_NOTIFICATION", settings.key, "email", !settings.email)}
											/>
										</div>
										<div className={clsx(classes.switchContainer, "d-flex justify-content-center align-items-center")}>
											{
												!hideSMSNotificationForKeys.includes(settings.key) && (
													<IOSSwitch
														rootClass={clsx(
															"m-0",
															{ "opacity-30": isBeingUpdated && updatingFieldInfo.field === "sms" }
														)}
														checked={hasSmsServiceEnabled && settings.sms}
														enabledTrackClass="bg-primary"
														onChange={() => !isBeingUpdated && hasSmsServiceEnabled && updateNotificationSetting("YOUR_NOTIFICATION", settings.key, "sms", !settings.sms)}
													/>
												)
											}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default NotificationSettings;
