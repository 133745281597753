import React, { useCallback } from "react";
import {
	Formik
} from "formik";
import {
	FormattedHTMLMessage,
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useSelector,
	useDispatch
} from "react-redux";
import {
	Dialog,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	flagNames,
	setDocumentsWithError,
} from '../../store/modules/actions/provider.actions';
import { getFlags } from "../../store/modules/selectors/common.selector";
import { getDocumentsWithError } from "../../store/modules/selectors/provider.selector";

function MarkNotRequired({ id, name, handleMarkNotRequired, handleClose, }) {
	const dispatch = useDispatch();
	const intl = useIntl();

	const flags = useSelector(getFlags);
	const documentsWithError = useSelector(getDocumentsWithError);
	const error = flags.error[flagNames.UPDATE];
	
	const onValidate = useCallback((values) => {
		const error = {};

		if (!values.reason) {
			error.reason = intl.formatMessage({
				id: "PROVIDER.DOCUMENT.MARK_NOT_REQUIRED.REASON.VALIDATION.REQUIRED"
			});
		}

		return error;
	}, [ intl ]);
	const onSubmit = useCallback(async ({ reason }) => {
		await handleMarkNotRequired(reason);
		dispatch(
			setDocumentsWithError({
				...documentsWithError,
				[id]: false,
			})
		);

		handleClose(true);
	}, [
		dispatch,
		id,
		handleMarkNotRequired,
		handleClose,
		documentsWithError,
	]);

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth="xs"
			classes={{
				paper: "rounded-card"
			}}
			className="mark-not-required-container"
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<Formik
				initialValues={{
					reason: '',
				}}
				validate={onValidate}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
				}) => {
					return (
						<form
							noValidate={true}
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div className="container p-20">
								<div className="row f-15 label-4">
									<div className="col-10 offset-1">
										<h4 className="mb-0 text-center">
											<FormattedHTMLMessage
												id="PROVIDER.DOCUMENT.MARK_NOT_REQUIRED.TITLE"
												values={{ name }}
											/>
										</h4>
									</div>
									<div className="col-1">
										<div className="float-right cursor-pointer" onClick={() => handleClose()}>
											<span className="fas fa-times"></span>
										</div>
									</div>
								</div>

								{
									error ? (
										<div className="d-flex justify-content-center">
											<Alert color="danger">
												{error}
											</Alert>
										</div>
									) : (
										<div>

											<div className="row">
												<div className="col-12 my-15">
													<div className="form-group mb-0">
														<FormattedMessage id="PROVIDER.DOCUMENT.MARK_NOT_REQUIRED.REASON">
															{
																(text) => (
																	<TextField
																		type="text"
																		placeholder={text}
																		variant="outlined"
																		className="w-100"
																		name="reason"
																		multiline
																		rows="4"
																		classes={{
																			root: 'MuiTextField-bg-white condensed-textarea'
																		}}
																		onBlur={handleBlur}
																		onChange={handleChange}
																		value={values.reason}
																		helperText={touched.reason && errors.reason}
																		error={Boolean(touched.reason && errors.reason)}
																	/>
																)
															}
														</FormattedMessage>
													</div>
												</div>
											</div>

											{
												flags.error[flagNames.UPDATE] && (
													<div className="d-flex justify-content-center">
														<Alert color="danger">
															{flags.error[flagNames.UPDATE]}
														</Alert>
													</div>
												)
											}

											<div className="row">
												<div className="col-6 px-4">
													<button
														type="button"
														className={`btn btn-danger btn-block`}
														onClick={() => handleClose()}
													>
														<FormattedMessage id="GENERAL.NO" />
													</button>
												</div>
												<div className="col-6 px-4">
													<button
														type="submit"
														className={
															clsx(
																"btn btn-block",
																{
																	"btn-secondary": !values.reason,
																	"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--right kt-spinner--primary": flags.loading[flagNames.UPDATE],
																	"btn-primary": !!values.reason,
																}
															)
														}
														onClick={handleSubmit}
													>
														<FormattedMessage id="GENERAL.YES" />
													</button>
												</div>
											</div>
										</div>
									)
								}
							</div>
						</form>
					);
				}}
			</Formik>
		</Dialog>
	);
}

export default MarkNotRequired;
