import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import Integrations from "./Integrations";
import BankStatements from "./BankStatements";
import Zapier from "./Zapier";

export default function IntegrationsPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/integrations" exact component={Integrations} />
        <Route
          path="/integrations/bank-statements"
          exact
          component={BankStatements}
        />
        <Route path="/integrations/zapier" exact component={Zapier} />
      </Switch>
    </Suspense>
  );
}
