import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Row, Col, } from "reactstrap";
import queryString from "query-string";
import { useSnackbar } from "notistack";

import Title from "./Title";
import PlanInfo from "./PlanInfo";
import AccountUsage from "./AccountUsage";
import PaymentInfo from "./PaymentInfo";
import ActiveAddons from "./ActiveAddons";
import PlanFeatures from "./PlanFeatures";
import { getBillingDetails } from "../../../crud/auth.crud";
import ExpiredPlanInfo from "./ExpiredPlanInfo";

function Billing() {
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [billingData, setBillingData] = useState(null);

	useEffect(() => {
		async function fn () {
			const queryStringObj = queryString.parse(window.location.search);

			if (queryStringObj.type) {
				const message = queryStringObj.message || intl.formatMessage({ id: "BILLING.PLANS.SUBSCRIPTION_SUCCESS" });
				enqueueSnackbar(message, { variant: "success" });
			}

			const { data } = await getBillingDetails();
			setBillingData(data);
		}

		fn();
	}, [
		enqueueSnackbar,
		intl,
	]);

	if (!billingData) {
		return (
			<div className="d-flex flex-grow-1 justify-content-center align-items-center mt-2 pt-2 mt-md-5 pt-md-5">
				<div className="kt-spinner kt-spinner--center kt-spinner--lg kt-spinner--primary" />
			</div>
		)
	}

	return (
		<div>
			<div className="pb-40px">
				<Row>
					<Col md={12}>
						{
							!!billingData.PLAN_INFO && (
								<div className="mb-30px">
									<Title billingData={billingData} />
								</div>
							)
						}

						{
							!!billingData.READ_ONLY && (
								<div className="mb-20px">
									<ExpiredPlanInfo billingData={billingData} />
								</div>
							)
						}

						{
							!!billingData.PLAN_INFO && (
								<div className="mb-20px">
									<PlanInfo billingData={billingData} />
								</div>
							)
						}

						{
							!!billingData.PAYMENT_INFO && (
								<div className="mb-20px">
									<PaymentInfo billingData={billingData} />
								</div>
							)
						}
						
						{
							!!billingData.ACTIVE_ADDONS && (
								<div className="mb-20px">
									<ActiveAddons billingData={billingData} />
								</div>
							)
						}
						
						{
							!!billingData.ACCOUNT_USAGE && (
								<div className="mb-20px">
									<AccountUsage billingData={billingData} />
								</div>
							)
						}
						
						{
							!!billingData.FEATURES && (
								<div className="mb-20px">
									<PlanFeatures billingData={billingData} />
								</div>
							)
						}
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default Billing;
