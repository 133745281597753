import React from "react";

export default () => (
	<svg width="84" height="66" viewBox="0 0 84 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 40.1699H52.8558V65.772H0V40.1699Z" fill="#E79633" />
		<path d="M29.2358 14.5674H83.9999V35.2142H29.2358V14.5674Z" fill="#E79633" />
		<path d="M36.9541 0.227539H53.7679V9.61209H36.9541V0.227539Z" fill="#E79633" />
		<path d="M6.60693 25.7998H23.4207V35.2148H6.60693V25.7998Z" fill="#E79633" />
		<path d="M60.021 0.227539H76.8349V9.61209H60.021V0.227539Z" fill="#E79633" />
		<path d="M57.811 40.1699H84V65.772H57.811V40.1699Z" fill="#E79633" />
	</svg>
)