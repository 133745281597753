import React, { useCallback, useMemo, useState } from "react";
import { ExpandMore, Info } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  makeStyles,
  Switch,
  ClickAwayListener,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import useUserRestriction from "../../../../common/hooks/user-restriction";
import LightTooltip from "../../../../common/LightTooltip";
import { useFormikContext } from "formik";
import { getUser } from "../../../../store/modules/selectors/account.selector";
import { getSelectedRequest } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => {
  return {
    roundedBottom0: {
      borderBottomLeftRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    },
    accordionBody: {
      "& .date-picker": {
        width: 201,

        "& .MuiInputBase-root": {
          paddingRight: 0,
        },

        "& .MuiInputAdornment-positionEnd": {
          position: "absolute",
          right: 10,

          "& .MuiButtonBase-root": {
            padding: 0,
          },
        },
      },
    },
    datePickerIcon: {
      "& .MuiSvgIcon-root": {
				fill: theme.palette.dark.primary.main
			},
    },
  };
});

export default function RequestSettings({ isInCreateMode, keyPrefix }) {
  const classes = useStyles();
  const intl = useIntl();
  const {
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const user = useSelector(getUser);
  const request = useSelector(getSelectedRequest);
  
  const [restrictions] = useUserRestriction();
  const isRequestDisabled = isInCreateMode ? false : request.archived;
  const [tooltipStatus, setTooltipStatus] = useState({
    smsNotification: false,
    requirePassword: false,
    reminderNotification: false,
  });

  const data = _.get(values, keyPrefix, values);

  const requestSettings = useMemo(() => {
    const isSmsAllowed = restrictions && restrictions.smsAllowed;

    return [
      {
        label: "REQUEST.PROVIDER.REQUIRE_PASSWORD",
        key: "requirePassword",
        isDisabled: isRequestDisabled,
        value: data.requirePassword,
        tooltip: "REQUEST.PROVIDER.REQUIRE_PASSWORD_TOOLTIP",
        disabledTooltip: "REQUEST.PROVIDER.REQUIRE_PASSWORD_DISABLED_TOOLTIP",
      },
      {
        label: "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS",
        key: "reminderNotification",
        isDisabled: isRequestDisabled,
        value: data.reminderNotification,
        tooltip: "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS_TOOLTIP",
        disabledTooltip:
          "REQUEST.PROVIDER.REMINDER_NOTIFICATIONS_DISABLED_TOOLTIP",
      },
      {
        label: "REQUEST.PROVIDER.NOTIFY_VIA_SMS",
        key: "smsNotification",
        isDisabled: isRequestDisabled || !user.smsService || !isSmsAllowed,
        value: user.smsService && isSmsAllowed ? data.smsNotification : false,
        tooltip: "REQUEST.PROVIDER.NOTIFY_VIA_SMS_TOOLTIP",
        disabledTooltip: isSmsAllowed
          ? "REQUEST.PROVIDER.NOTIFY_VIA_SMS_DISABLED_TOOLTIP"
          : "REQUEST.PROVIDER.NOTIFY_VIA_SMS_UPGRADE_TOOLTIP",
      },
    ];
  }, [isRequestDisabled, data, user, restrictions]);
  const handleTooltipToggle = useCallback(
    (key) => {
      const newTooltipStatus = _.reduce(
        tooltipStatus,
        (p, value, tooltipKey) => {
          p[tooltipKey] = key === tooltipKey;
          return p;
        },
        {}
      );

      setTooltipStatus(newTooltipStatus);
    },
    [tooltipStatus]
  );
  const closeAllTooltips = useCallback(() => {
    setTooltipStatus({
      smsNotification: false,
      requirePassword: false,
      reminderNotification: false,
    });
  }, [setTooltipStatus]);

  const updateField = useCallback(
    (field, value) => {
      const key = `${keyPrefix}${keyPrefix ? "." : ""}${field}`;
      setFieldValue(key, value);
      setTimeout(() => setFieldTouched(key, true), 250);
    },
    [setFieldTouched, setFieldValue, keyPrefix]
  );

  return (
    <Accordion
      classes={{
        expanded: "m-0",
      }}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <IconButton size="small" className="bg-light-blue">
            <ExpandMore />
          </IconButton>
        }
        className="bg-light"
        classes={{
          root: "rounded py-10px px-20px",
          expanded: classes.roundedBottom0,
          content: "m-0",
        }}
      >
        <div className="f-16px">
          <FormattedMessage id="REQUEST.PROVIDER.REQUEST_SETTINGS" />
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={`rounded-bottom px-20px pt-10px pb-20px ${classes.accordionBody}`}
      >
        <div>
          <ClickAwayListener onClickAway={closeAllTooltips}>
            <div>
              {requestSettings.map(
                ({
                  key,
                  value,
                  label,
                  isDisabled,
                  disabledTooltip,
                  tooltip,
                }) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between"
                      key={key}
                    >
                      <div className="d-flex mr-40px">
                        <div className="f-16px mr-5px">
                          <FormattedMessage id={label} />
                        </div>
                        <div className="text-muted">
                          <LightTooltip
                            open={!!tooltipStatus[key]}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            className="ml-1"
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: intl.formatMessage({
                                    id: isDisabled ? disabledTooltip : tooltip,
                                  }),
                                }}
                              />
                            }
                            placement="bottom"
                            arrow
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleTooltipToggle(key, 3);
                              }}
                            >
                              <Info />
                            </span>
                          </LightTooltip>
                        </div>
                      </div>
                      <div>
                        <Switch
                          checked={!!value}
                          onChange={(e) => updateField(key, e.target.checked)}
                          color="primary"
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </ClickAwayListener>

          <div className="mt-15px">
            <div className="dark-custom-input d-flex align-items-center">
              <div className="f-16px mr-10px">
                <FormattedMessage id="REQUEST.PROVIDER.DUE_DATE" />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  InputAdornmentProps={{
                    className: classes.datePickerIcon,
                  }}
                  PopoverProps={{
                    PaperProps: {
                      classes: {
                        root: "kaddim-date-picker-calendar"
                      }
                    }
                  }}
                  className="date-picker"
                  variant="inline"
                  inputVariant="outlined"
                  value={data.dueDate}
                  onChange={(date) => {
                    const value = moment(date)
                      .startOf("day")
                      .toDate();
                    updateField("dueDate", value);
                  }}
                  disablePast={isInCreateMode}
                  disabled={isRequestDisabled}
                  format="dd/MM/yyyy"
                  minDateMessage={intl.formatMessage({ id: "PROVIDER.DUE_DATE.VALIDATION.IN_PAST" })}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          {
            // <div>
            // 	<button className="btn btn-dark text-light f-14px px-20px py-10px">
            // 		<FormattedMessage id="REQUESTS.PROVIDER.UPDATE" />
            // 	</button>
            // </div>
          }
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
