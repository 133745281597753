import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, useEffect, useState } from "react";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import { requestSignedUrl } from "../../../../../_metronic";
import ErrorMessage from "../ErrorMessage";
import Preview from "./Preview";

const useStyles = makeStyles((theme) => ({
	thumbnail: {
		[theme.breakpoints.up('sm')]: {
			height: theme.elementSizes.image.maxHeight,
			maxWidth: theme.elementSizes.image.maxWidth,
		},
		width: "100%",
		objectFit: "cover",
	}
}));

const Image = forwardRef((props, _ref) => {
	const { data } = props;
	const [error, setError] = useState(null);
	const [publicUrl, setPublicUrl] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		async function loadImage(s3Url) {
			const {
				data: {
					url: signedUrl
				}
			} = await requestSignedUrl(s3Url);

			return signedUrl;
		}

		async function loadPreview(url) {
			try {
				const localUrl = await loadImage(url);
				setPublicUrl(localUrl);
			}
			catch (err) {
				console.log(err);
				setError(err.toString())
			}
		}

		if (data.attachment && data.attachment.url) {
			loadPreview(data.attachment.url);
		}
	}, [
		data.attachment,
	])

	return (
		<div>
			<div>
				{
					data.title && (
						<div className="mb-10px mr-5 mr-md-0 pr-3">
							<span className="px-0 text-dark font-weight-medium f-18px">
								{data.title}
							</span>
						</div>
					)
				}

				{
					!!data.subtitle && (
						<div className="mb-10px">
							<span
								className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
								dangerouslySetInnerHTML={{
									__html: linkifyHtml(data.subtitle, {
										attributes: {
											target: "_blank",
											rel: "noopener noreferrer",
										}
									})
								}}
							/>
						</div>
					)
				}

				{
					publicUrl ? (
						<div className="cursor-pointer" onClick={() => setShowPreview(true)}>
							<img
								className={clsx(classes.thumbnail, "border rounded w-100")}
								src={publicUrl}
								alt={data.title}
							/>
						</div>
					) : (
						<div className="d-flex justify-content-center align-items-center">
							<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
						</div>
					)
				}
			</div>

			{
				error && (
					<ErrorMessage message={error} />
				)
			}

			<Preview
				open={showPreview}
				link={publicUrl}
				title={data.title}
				handleClose={() => setShowPreview(false)}
			/>
		</div>
	)
})

export default Image;
