export const getUser = (store) => store?.auth?.user;
export const getRestrictions = (store) => store?.auth?.restrictions;
export const getUserId = (store) => store?.auth?.user?._id;
export const isUserSuperAdmin = (store) => {
  const roles = store?.auth?.user?.roles;

  return roles?.includes("SUPER_ADMIN");
}

export const getUserLayoutSettings = (store) => store?.auth?.user?.settings?.layoutSettings;
export const getFileRenameSeparator = (store) => store?.auth?.user?.settings?.fileRenameSeparator;