import React, { forwardRef } from "react";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import EmbeddedVideo from "./EmbeddedVideo";

const Video = forwardRef((props, _ref) => {
	const { data } = props;

	return (
		<div className="row">
			<div className="col-12">
				{
					data.title && (
						<div className="mr-5 mr-md-0 pr-3">
							<span className="px-0 text-dark font-weight-medium f-18px">
								{data.title}
							</span>
						</div>
					)
				}

				{
					!!data.subtitle && (
						<div className="mb-10px">
							<span
								className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
								dangerouslySetInnerHTML={{
									__html: linkifyHtml(data.subtitle, {
										attributes: {
											target: "_blank",
											rel: "noopener noreferrer",
										}
									})
								}}
							/>
						</div>
					)
				}

				<EmbeddedVideo
					type={data.videoObj.type}
					videoId={data.videoObj.videoId}
					start={data.videoObj.refObj && data.videoObj.refObj.start}
				/>
			</div>
		</div>
	)
})

export default Video;
