import { actionTypes } from "./actions/requests.actions";
import _ from "lodash";

const initialState = {
	list: {
		data: [],
		count: 0
	},
	page: 0,
	filter: {
		search: "",
		fromDate: null,
		toDate: null,
		order: "asc",
		orderBy: "index",
		statusFilters: [],
		archived: false,
		unreadOnly: false,
		updatedOnly: false,
	},
	selection: {
		requests: {},
		selectedCountFromCurrentList: false,
	},
	messages: {
		data: [],
		count: 0,
	},
	comments: {
		data: [],
		count: 0,
	},
	progressIndicatorData: {
		progress: null,
		message: null
	},
	selectedRequest: null,
	logs: [],
	operation: {},

	// serves as cache so that we know updated form state while save operation is going on
	providerForm: {

	},
};

const getSelectedRequestCount = (requests, selectionState) => {
	const selectedCount = requests.reduce((p, request) => {
		return p + (selectionState[request._id] ? 1 : 0)
	}, 0);

	return selectedCount;
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.REQUESTS_LOADED: {
			const { list } = action.payload;
			const selectedRequestCount = getSelectedRequestCount(list.data, state.selection.requests);

			return {
				...state,
				list,
				selection: {
					...state.selection,
					selectedCountFromCurrentList: selectedRequestCount
				}
			};
		}
		case actionTypes.REQUEST_STATUS_CHANGED: {
			const {
				payload: {
					request,
				}
			} = action;
			let {
				list: {
					data
				},
			} = state;

			data = data.map((req) => request._id  === req._id ? request : req);

			return {
				...state,
				list: {
					...state.list,
					data,
				},
			};
		}
		case actionTypes.REQUESTS_ARCHIVED:
		case actionTypes.REQUESTS_DELETED:
		case actionTypes.REQUESTS_UNARCHIVED: {
			const {
				list,
			} = state;
			const { requestIds } = action.payload;
			const updatedList = list.data.filter((request) => !requestIds.includes(request._id));

			return {
				...state,
				list: {
					data: updatedList,
					count: list.count - requestIds.length
				}
			};
		}
		case actionTypes.NEW_REQUEST: {
			return {
				...state,
				selectedRequest: {}
			};
		}
		case actionTypes.REQUEST_LOADED: {
			// action.payload.documentProviders[0].documents[0].files = [
			// 	{
			// 		url: 'http://www.africau.edu/images/default/sample.pdf',
			// 		thumbnail: 'https://via.placeholder.com/150',
			// 		filename: 'sample.pdf',
			// 		date: new Date(),
			// 	},
			// 	{
			// 		url: 'http://www.africau.edu/images/default/sample.pdf',
			// 		thumbnail: 'https://via.placeholder.com/150',
			// 		filename: 'sample.pdf',
			// 		date: new Date(),
			// 	}
			// ];

			return {
				...state,
				selectedRequest: action.payload,
			};
		}
		case actionTypes.MARKED_AS_UNREAD: {
			const {
				payload: {
					requestId,
				}
			} = action;
			let {
				list: {
					data
				},
			} = state;

			data = data.map((req) => {
				if (requestId === req._id) {
					req.updates = true;
				}

				return req;
			});

			return {
				...state,
				list: {
					...state.list,
					data,
				},
			};
		}
		case actionTypes.PROVIDER_ADDED: {
			const {
				selectedRequest,
			} = state;

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: [
						...selectedRequest.documentProviders,
						{ ...action.payload }
					],
				},
			};
		}

		case actionTypes.PROVIDER_UPDATED: {
			const {
				selectedRequest,
			} = state;

			if (!selectedRequest) {
				return state;
			}

			const documentProviders = selectedRequest.documentProviders.map((provider) => {
				if (provider._id === action.payload._id) {
					return {
						...provider, 
						...action.payload,
					};
				}
				else {
					return provider;
				}
			});

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: [
						...documentProviders
					],
				},
			};
		}

		case actionTypes.PROVIDER_REMOVED: {
			const {
				payload: {
					providerId,
				}
			} = action;
			const {
				selectedRequest,
			} = state;

			if (!selectedRequest) {
				return state;
			}

			const documentProviders = selectedRequest.documentProviders.filter((provider) => {
				return provider._id !== providerId;
			});

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: [
						...documentProviders
					],
				},
			};
		}

		case actionTypes.DOCUMENT_STATUS_UPDATED: {
			const {
				selectedRequest,
			} = state;

			if (!state.selectedRequest) {
				return state;
			}

			const documentProviders = _.cloneDeep(state.selectedRequest.documentProviders);

			documentProviders.forEach((provider) => {
				provider.documents = provider.documents.map((doc) => {
					const docObj = doc._id === action.payload._id ? action.payload : doc;

					return {
						...docObj
					};
				});
			});

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: [
						...documentProviders
					],
				},
			};
		}

		case actionTypes.ELEMENT_STATUS_UPDATED: {
			const {
				selectedRequest,
			} = state;

			if (!state.selectedRequest) {
				return state;
			}

			const documentProviders = _.cloneDeep(state.selectedRequest.documentProviders);

			documentProviders.forEach((provider) => {
				provider.elements = provider.elements.map((element) => {
					const elementObj = element._id === action.payload._id ? action.payload : element;
					return elementObj;
				});
			});

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: [
						...documentProviders
					],
				},
			};
		}
		
		case actionTypes.DOCUMENT_UPDATED: {
			const {
				selectedRequest,
			} = state;

			if (!selectedRequest) {
				return state;
			}

			const request = _.cloneDeep(selectedRequest);
			request.documentProviders = request.documentProviders.map((provider) => {
				provider.documents = provider.documents.map((doc) => {
					const docObj = doc._id === action.payload._id ? action.payload : doc;

					return {
						...docObj
					};
				});

				return {
					...provider
				};
			});

			return {
				...state,
				selectedRequest: request,
			};
		}

		case actionTypes.DOCUMENT_DELETED: {
			const {
				selectedRequest,
			} = state;

			if (!selectedRequest) {
				return state;
			}

			const request = _.cloneDeep(selectedRequest);
			request.documentProviders = request.documentProviders.map((provider) => {
				provider.documents = provider.documents.filter((doc) => doc._id !== action.payload);
				return provider;
			});

			return {
				...state,
				selectedRequest: request,
			};
		}

		case actionTypes.UPDATE_TITLE: {
			return {
				...state,
				selectedRequest: {
					...state.selectedRequest,
					title: action.payload.title
				}
			}
		}

		case actionTypes.MESSAGES_LOADED: {
			const {
				payload: {
					providerId,
					messages,
				}
			} = action;

			return {
				...state,
				selectedRequest: {
					...state.selectedRequest,
					documentProviders: state.selectedRequest.documentProviders.map((provider) => {
						if (provider._id === providerId) {
							return {
								...provider,
								unreadMessagesCount: 0,
							};
						}
						else {
							return {
								...provider,
							}
						}
					})
				},
				messages,
			};
		}
		case actionTypes.MESSAGE_ADDED: {
			return {
				...state,
				messages: {
					data: [
						action.payload.data,
						...state.messages.data,
					],
					count: state.messages.count + 1
				},
			};
		}

		case actionTypes.ELEMENT_COMMENTS_LOADED: {
			const {
				selectedRequest,
			} = state;
			const {
				documentProviders,
			} = selectedRequest;
			const {
				payload: {
					comments,
					providerId,
					elementId,
				}
			} = action;

			let updatedDocumentProviders = _.cloneDeep(documentProviders);
			
			updatedDocumentProviders = updatedDocumentProviders.map((documentProvider) => {
				if (documentProvider._id === providerId) {
					documentProvider.elements = documentProvider.elements.map((element) => {
						if (element._id === elementId) {
							// set count of unread comments as 0 as user has loaded the comments
							element.unreadCommentsCount = 0;
						}

						return element;
					});
				}
				
				return documentProvider;
			});

			return {
				...state,
				selectedRequest: {
					...selectedRequest,
					documentProviders: updatedDocumentProviders,
				},
				comments,
			};
		}
		case actionTypes.ELEMENT_COMMENT_ADDED: {
			return {
				...state,
				comments: {
					data: [
						action.payload.data,
						...state.comments.data,
					],
					count: state.comments.count + 1
				},
			};
		}

		case actionTypes.CHANGE_PAGE: {
			return {
				...state,
				page: action.payload.page,
			};
		}

		case actionTypes.UPDATE_FILTER: {
			return {
				...state,
				page: 0,
				filter: {
					...state.filter,
					...action.payload.data
				},
			};
		}

		case actionTypes.SET_PROGRESS_INDICATOR_DATA: {
			return {
				...state,
				progressIndicatorData: {
					...state.progressIndicatorData,
					...action.payload.data,
				} 
			}
		}

		case actionTypes.SELECT_REQUEST: {
			const selectedRequests = _.clone(state.selection.requests);
			const { request } = action.payload;
			const requestId = request._id;

			selectedRequests[requestId] = request;

			const selectedRequestCount = getSelectedRequestCount(state.list.data, selectedRequests);

			return {
				...state,
				selection: {
					requests: selectedRequests,
					selectedCountFromCurrentList: selectedRequestCount,
				}
			}
		}
		case actionTypes.UNSELECT_REQUEST: {
			const selectedRequests = _.clone(state.selection.requests);
			const { request } = action.payload;
			const requestId = request._id;

			delete selectedRequests[requestId];

			const selectedRequestCount = getSelectedRequestCount(state.list.data, selectedRequests);

			return {
				...state,
				selection: {
					requests: selectedRequests,
					selectedCountFromCurrentList: selectedRequestCount
				}
			}
		}
		case actionTypes.PERFORM_OPERATION: {
			return {
				...state,
				operation: {
					action: action.payload.action,
					data: action.payload.data,
				}
			}
		}

		case actionTypes.PROVIDER_FORM: {
			return {
				...state,
				providerForm: action.payload,
			}
		}

		case actionTypes.LOGS_HISTORY_LOADED: {
			return {
				...state,
				logs: action.payload,
			}
		}

		default:
			return state;
	}
}
