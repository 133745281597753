import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React from "react";

export default function Preview ({ title, link, open, handleClose }) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen
		>
			<DialogContent className="text-center">
				<div className="d-flex justify-content-end mb-2">
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</div>

				<TransformWrapper
					wheel={{
						step: 2,
					}}
					pan={{
						paddingSize: 1
					}}
					options={{
						minScale: 0,
					}}
				>
					{
						() => (
							<div className="image-preview flex-grow-1 d-flex justify-content-center align-items-center w-100">
								<TransformComponent>
									<img src={link} alt={title} className="rounded w-100" />
								</TransformComponent>
							</div>
						)
					}
				</TransformWrapper>
			</DialogContent>
		</Dialog>
	)
}