import _ from "lodash";
import { actionTypes } from "./actions/templates.actions";

const initialState = {
	list: [],
	selectedTemplate: null,
	default: [],
	operation: {},
	filter: {
		search: "",
		includeClassicTemplates: true,
		includeModernTemplates: true,
	},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TEMPLATES_LOADED: {
			const { list, user } = action.payload;
			const [ defaultTemplates, userTemplates ] = _.partition(list, (template) => {
				return template.type === 'PUBLIC';
			});

			const isAtleastAdmin = _.intersection(user.roles, ["ADMIN", "SUPER_ADMIN"]).length > 0;

			return {
				...state,
				list: (isAtleastAdmin ? list : userTemplates),
				default: (isAtleastAdmin ? list : defaultTemplates)
			};
		}

		case actionTypes.TEMPLATE_LOADED: {
			const { template } = action.payload;

			return {
				...state,
				selectedTemplate: template
			};
		}

		case actionTypes.PERFORM_OPERATION: {
			return {
				...state,
				operation: {
					action: action.payload.action,
					data: action.payload.data,
				}
			}
		}

		case actionTypes.UPDATE_FILTER: {
			return {
				...state,
				page: 0,
				filter: {
					...state.filter,
					...action.payload.data
				},
			};
		}

		default:
			return state;
	}
}
