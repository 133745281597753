export const actionTypes = {
	SET_LOADING_FLAG: '[FLAG] SET_LOADING_FLAG',
	SET_ERROR_FLAG: '[FLAG] SET_ERROR_FLAG',
};

export const setLoadingFlag = (name, status) => {
	return {
		type: actionTypes.SET_LOADING_FLAG,
		payload: {
			name,
			status,
		}
	}
}

export const setErrorFlag = (name, message) => {
	return {
		type: actionTypes.SET_ERROR_FLAG,
		payload: {
			name,
			message,
		}
	}
}