import { createTheme } from "@material-ui/core";

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Rubik"].join(",")
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },

    palette: {
      contrastThreshold: 2,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#2E5BFF",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#0abb87",
        // dark: will be calculated from palette.primary.main,
        contrastText: "#ffffff"
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#ff5f58"
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        main: "#2cc2a5",
      },
      warning: {
        main: "#FFB800",
      },
      danger: {
        main: "#ff5f58"
      },
      extraColors: {
        black: "#000000",
        grey: "#B0BAC9",
        blueSecondary: "#F4F6FC",
        backgroundBlue: "#EBEFFF",
        background: "rgba(224, 231, 255, 0.2)",
        lightText: "#858FA3",
        darkBlueBorder: "#A1B1E6",
        blueBorder: "#E0E7FF",
        gray: "#E0E0E0",
      },
      dark: {
        primary: {
          main: "#2E384D",
          contrastText: "#FFFFFF"
        }
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },

    elementSizes: {
      inputFields: {
        maxWidth: 592,
        readableMaxWidth: 592,
      },
      radioButton: {
        maxWidth: 592
      },
      dropdown: {
        maxWidth: 592,
        readableMaxWidth: 377,
      },
      checkboxes: {
        maxWidth: 592
      },
      image: {
        maxWidth: 592,
        maxHeight: 314,
      },
      video: {
        maxWidth: 592,
        maxHeight: 314,
      },
      fileList: {
        maxWidth: 379,
        maxHeight: 347,
      },
      templatePicker: {
        maxWidth: 323,
      },
    },
    layoutSizes: {
      elementListContainer: {
        leftMenuSpace: 265,
        editContainerPadding: 25,
        containerWidth: 932,
        spaceBetweenContainerAddButton: 15,
        addButtonWidth: 185,
        safeSpace: 35,
      }
    }
  }
);

// export default function ThemeProvider(props) {
//   const { children } = props;

//   return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
// }
export default theme;