import React, {
	useEffect,
	useCallback,
	useState,
	useMemo,
} from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
	useIntl,
} from "react-intl";
import {
	useSelector,
	useDispatch,
} from "react-redux";
import {
	Dialog,
	makeStyles,
	AppBar,
	useTheme,
	Toolbar,
	Typography,
	IconButton,
	InputBase,
} from '@material-ui/core';
import {
	CloseOutlined,
	Sync
} from '@material-ui/icons';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";
import moment from "moment";

import {
	addElementComment,
	getElementComments,
	performOperation,
	flagNames,
} from '../../../../store/modules/actions/requests.actions';
import { getFlags } from "../../../../store/modules/selectors/common.selector";
import { getRequestComments } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => {
	return {
		messageContainer: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12);',

			"& .message": {
				color: "inherit",
				fontSize: "inherit",
				whiteSpace: "break-spaces",
			},
		},
		comment: {
			marginBottom: 5,
		},
	}
})

function ElementComments({ element }) {
	const theme = useTheme();
	const intl = useIntl();
	const dispatch = useDispatch();
	const classes = useStyles();
	
	const flags = useSelector(getFlags);
	const commentDataList = useSelector(getRequestComments);
	const comments = useMemo(() => commentDataList?.slice()?.reverse(), [commentDataList]);
	
	const [fetchCounter, setFetchCounter] = useState(0);

	useEffect(() => {
		dispatch(getElementComments(element.documentProviderId, element._id));
	}, [ dispatch, element ]);

	useEffect(() => {
		dispatch(getElementComments(element.documentProviderId, element._id));
	}, [fetchCounter, element, dispatch]);

	const handleClose = useCallback(() => {
		dispatch(performOperation());
	}, [
		dispatch,
	]);

	const loading = flags.loading[flagNames.ELEMENT_COMMENTS];
	const error = flags.error[flagNames.ELEMENT_COMMENTS];

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth="sm"
		>
			<AppBar
				position="static"
				classes={{
					root: "rounded-top"
				}}
				style={{ backgroundColor: theme.palette.dark.primary.main }}
			>
				<Toolbar classes={{ root: "pr-1" }}>
					<div className="flex-grow-1">
						<Typography
							variant="h6"
							style={{ color: theme.palette.dark.primary.contrastText }}
						>
							<FormattedMessage
								id="REQUEST.PROVIDER.ELEMENT_COMMENT.TITLE"
								values={element.dataObj}
							/>
						</Typography>
					</div>
					<div className="d-flex">
						<IconButton
							onClick={() => setFetchCounter(fetchCounter + 1)}
							className="mx-2"
						>
							<Sync style={{ color: theme.palette.dark.primary.contrastText }} />
						</IconButton>
						<IconButton onClick={() => handleClose()}>
							<CloseOutlined style={{ color: theme.palette.dark.primary.contrastText }} />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>

			<div className='d-flex flex-column' style={{ height: '75vh' }}>
				{
					loading ? (
						<div className="w-100 h-100 d-flex justify-content-center">
							<div className="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--brand" />
						</div>
					) : (
						<React.Fragment>
							{
								error ? (
									<div className="d-flex justify-content-center">
										<Alert color="danger">
											{error}
										</Alert>
									</div>
								) : (
									<div className="d-flex flex-column h-100">
										<div className="flex-grow-1" style={{ overflowY: 'auto' }}>
											<div className="row w-100 mx-0">
												{
													comments.map((commentDetails) => {
														const createdAtMoment = moment(commentDetails.createdAt)
														return (
															<div
																key={commentDetails._id}
																className={clsx(
																	"px-4 col-12 py-15px",
																	classes.messageContainer,
																)}
															>
																<div className="mb-10px d-flex justify-content-between align-items-center">
																	<div className="f-16px">
																		{`${commentDetails.firstName} ${commentDetails.lastName}`}
																	</div>
																	<div className="f-14px" title={createdAtMoment.format('DD/MM/YYYY hh:mm A')}>
																		{createdAtMoment.fromNow()}
																	</div>
																</div>
																<div className={"f-16px font-weight-light"}>
																	<pre className="message">
																		{commentDetails.comment}
																	</pre>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
										<div className="flex-shrink-0 px-4 py-3 border-top">
											<Formik
												initialValues={{
													comment: ''
												}}
												validate={values => {
													const error = {};

													if (!values.comment) {
														error.comment = intl.formatMessage({
															id: "REQUEST.PROVIDER.CHAT.MESSAGE.VALIDATION.REQUIRED"
														});
													}

													return error;
												}}
												validateOnChange={false}
												validateOnBlur={true}
												onSubmit={async (values, { resetForm }) => {
													await dispatch(addElementComment(element.documentProviderId, element._id, values.comment));
													resetForm();
												}}
											>
												{({
													values,
													handleChange,
													handleBlur,
													handleSubmit,
												}) => {
													return (
														<form
															noValidate={true}
															autoComplete="off"
															onSubmit={handleSubmit}
														>
															<div className="row w-100 m-0 align-items-center">
																<div className="col-12 col-md-9 d-flex align-items-center mb-2 mb-md-0">
																	<div className="form-group mb-0 w-100">
																		<FormattedMessage id="ELEMENT_LIST.ITEM.COMMENTS.INPUT.PLACEHOLDER">
																			{
																				(text) => (
																					<InputBase
																						autoFocus
																						placeholder={text}
																						type="text"
																						classes={{
																							root: "py-0 pl-0",
																						}}
																						multiline
																						onKeyDown={(e) => (e.metaKey || e.ctrlKey) && e.key === 'Enter' && handleSubmit()}
																						className="w-100"
																						name="comment"
																						onBlur={handleBlur}
																						onChange={handleChange}
																						value={values.comment}
																					/>
																				)
																			}
																		</FormattedMessage>
																	</div>
																</div>

																<div className="col-12 col-md-3 px-0">
																	<button
																		type="submit"
																		className="btn btn-primary btn-block"
																	>
																		<FormattedMessage id="ELEMENT_LIST.ITEM.COMMENTS.SUBMIT" />
																	</button>
																</div>
															</div>
														</form>
													);
												}}
											</Formik>
										</div>
									</div>
								)
							}
						</React.Fragment>
					)
				}
			</div>
		</Dialog>
	);
}

export default ElementComments;
