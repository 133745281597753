import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Switch, makeStyles, useTheme } from '@material-ui/core';
import { ReportProblemSharp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import * as Sentry from "@sentry/react";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from 'linkifyjs/html';
import { uuid } from 'uuidv4';

import FileCard from './FileCard';
import { isAttachmentValid, sanitizeFile } from '../../../../../_metronic';
import Confirm from '../../../modals/confirm';
import { DangerBlocksIcon, FileErrorIcon } from '../../../icons';
import { uploadFileToElement } from '../../../../store/modules/actions/provider.actions';
import Constants from '../../../constants';
import AttachmentCard from './AttachmentCard';
import { getUploadTaskResults } from '../../../../store/modules/selectors/tasks.selector';

const useStyles = makeStyles((theme) => {
	return {
		attachmentListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
			border: `2px dashed ${theme.palette.extraColors.grey}`,
			borderRadius: 6,
		},
		fileListContainer: {
			maxWidth: theme.elementSizes.fileList.maxWidth,
		},
		uploadContainer: {
			height: 170,
		},
		fileList: {
			maxHeight: 200,
			overflowY: "auto",

			'&::-webkit-scrollbar': {
				marginLeft: 5,
				width: 8,
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				borderRadius: 5,
				background: 'transparent'
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: 'rgb(235, 239, 255)',
				borderRadius: 15,
			}
		},
	}
});

function FileUpload({
	id,
	data,
	response,
	additionalData,
	onChange,
	onPreview
}) {
	const classes = useStyles();
	const intl = useIntl();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const uploadTaskResult = useSelector(getUploadTaskResults);

	const {
		providerUUID,
		hideDownload,
	} = additionalData;

	const [deleteFileIndex, setDeleteFileIndex] = useState(-1);
	const [zeroSizeFiles, setZeroSizeFiles] = useState([]);
	const [showOriginalName, setShowOriginalName] = useState(false);
	const [showStorageLimitWarning, setShowStorageLimitWarning] = useState(false);
	const uploadTasksRef = useRef({});

	const hasValidOriginalName = useMemo(() => {
		return response.files.some(({ originalName, name }) => name && originalName && name !== originalName );
	}, [response.files]);

	const isStorageLimitReached = useCallback((newFiles) => {
		const existingAttachmentSizeKB = response.files.reduce((p, file) => {
			return p + file.size;
		}, 0);
		const newFilesKB = newFiles.reduce((p, file) => p + (file.size / 1024), 0);

		const totalSizeMB = (existingAttachmentSizeKB + newFilesKB) / 1024;

		return totalSizeMB > Constants.ATTACHMENTS_LIMIT_MB;
	}, [response]);

	const onFileDrop = useCallback(async (selectedFiles) => {
		const [zeroSizeFiles, acceptedFiles] = _.partition(selectedFiles, (file) => file.size < 1024)
		const validFiles = acceptedFiles.filter((file) => isAttachmentValid(file));
		setZeroSizeFiles(zeroSizeFiles);

		acceptedFiles
			.filter((file) => !validFiles.includes(file))
			.forEach((file) => {
				const msg = intl.formatMessage({
					id: "TEMPLATE.ATTACHMENTS.INVALID_FILE"
				}, file);
				enqueueSnackbar(msg, { variant: 'error' })
			});
		
		if (zeroSizeFiles.length) {
			Sentry.captureMessage(`user ${providerUUID} uploaded ${zeroSizeFiles.length} files with size less than 1kb`);
		}

		if (isStorageLimitReached(validFiles)) {
			setShowStorageLimitWarning(true);
			return;
		}

		const promises = validFiles.map((file) => sanitizeFile(file));
		const files = await Promise.all(promises);
		const uploadId = uuid();

		dispatch(uploadFileToElement(id, files, uploadId));

		uploadTasksRef.current[uploadId] = true;
	}, [isStorageLimitReached, dispatch, id, intl, enqueueSnackbar, providerUUID]);

	const handleDelete = useCallback((fileIndexToDelete) => {
		const files = response.files.filter((file, index) => index !== fileIndexToDelete);

		onChange({
			...response,
			files,
		});
	}, [
		onChange,
		response,
	]);

	const {
		getRootProps: getFileInputPropsRoot,
		getInputProps: getFileInputProps,
	} = useDropzone({
		onDrop: onFileDrop,
		noClick: false,
	});

	useEffect(() => {
		const currentUploadIds = Object.keys(uploadTasksRef.current);

		for (const key of currentUploadIds) {
			const result = uploadTaskResult[key];

			if (result) {
				delete uploadTasksRef.current[key];

				onChange({
					...response,
					files: [
						...result,
						...response.files,
					]
				});
			}
		}
	}, [onChange, response, uploadTaskResult]);

	return (
		<div>
			<div>
				<div className="d-flex align-items-center mr-5 mr-md-0 pr-3">
					<div className="mr-2 row">
						<span className="col-12 pr-0 text-dark font-weight-medium f-18px">
							{data.title}
						</span>
					</div>
				</div>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="row">
				<div className="col-12">
					<div className="my-10px d-flex flex-column flex-grow-1 px-0">
						{
							data.attachments.length > 0 && (
								<div className={clsx(classes.attachmentListContainer, "mb-15px")}>
									<div className="f-14px mb-5px px-10px pt-10px fw-500 text-muted">
										<FormattedMessage
											id="ELEMENT_LIST.ITEM.FILE_UPLOAD.ATTACHMENTS.TITLE"
											defaultMessage="Attached Files"
										/>
									</div>

									<div className={classes.fileList}>
										{
											data.attachments.map((attachment, index, arr) => (
												<div
													key={index}
													className={
														clsx(
															{
																"mb-5px": index !== arr.length - 1,
																"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
															}
														)
													}
												>
													<AttachmentCard
														canDownload
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();

															onPreview(attachment);
														}}
														attachment={attachment}
													/>
												</div>
											))
										}
									</div>
								</div>	
							)
						}

						<div className={clsx(classes.fileListContainer, "rounded bg-white p-10px")}>
							<div className="f-14px fw-500 mb-5px text-muted">
								<FormattedMessage
									id="ELEMENT_LIST.ITEM.FILE_UPLOAD.FILES.TITLE"
									defaultMessage="Uploaded Files"
								/>
							</div>

							<div className={classes.fileList}>
								{
									response.files.map((file, index, arr) => (
										<div
											key={index}
											className={
												clsx(
													{
														"mb-5px": index !== arr.length - 1,
														"mr-10px": arr.length > 4, // after 4 files because of hardcoded maxHeight scrollbar appears
													}
												)
											}
										>
											<FileCard
												canDownload={!hideDownload && file._id}
												fileNameField={showOriginalName ? 'originalName' : undefined}
												onDelete={() => setDeleteFileIndex(index)}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();

													if (typeof onPreview !== "function") {
														console.log("preview function is not available");
														return;
													}

													onPreview(file);
												}}
												file={file}
											/>
										</div>
									))
								}
							</div>

							<div
								className={clsx(
									"mt-10px p-10px position-relative flex-grow-1 rounded",
									{
										"d-flex justify-content-center align-items-center": !response.files.length
									}
								)}
								style={{
									background: theme.palette.extraColors.backgroundBlue,
								}}
								{...getFileInputPropsRoot()}
							>
								<input {...getFileInputProps()} />
								<div className={clsx(classes.uploadContainer, "w-100 d-flex align-items-center justify-content-center flex-column")}>
									<div className="d-flex align-items-center text-muted">
										<Icon fontSize="large">cloud_upload</Icon>
									</div>
									<div className="mt-5px">
										<button className="btn btn-primary btn-sm">
											<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.UPLOAD.BUTTON_TEXT" />
										</button>
									</div>
									<div className="text-center message text-muted mt-20px">
										<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.UPLOAD.DND_TEXT" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{
					hasValidOriginalName && (
						<div className="d-flex align-items-center mt-5px px-3">
							<div className="f-16px text-muted">
								<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.ORIGINAL_FILE_NAME.LABEL" />
							</div>

							<div className="ml-2">
								<Switch
									checked={showOriginalName}
									classes={{
										thumb: showOriginalName ? 'bg-dark' : 'bg-white'
									}}
									onChange={(e) => setShowOriginalName(!showOriginalName)}
								/>
							</div>
						</div>
					)
				}
			</div>

			{
				deleteFileIndex > -1 && (
					<Confirm
						open
						icon={<DangerBlocksIcon />}
						variant="danger"
						handleClose={(wasSubmitted) => {
							if (wasSubmitted) {
								handleDelete(deleteFileIndex)
							}
							setDeleteFileIndex(-1);
						}}
						title="PROVIDER.ELEMENT.FILE.DELETE.FILE.TITLE"
						message={
							intl.formatMessage({
								id: "PROVIDER.ELEMENT.FILE.DELETE.FILE.DESCRIPTION"
							}, {
								elementTitle: data.title,
								fileName: response.files[deleteFileIndex].name
							})
						}
						submitButtonText="GENERAL.YES"
						cancelButtonText="GENERAL.NO"
					/>
				)
			}

			<Confirm
				open={showStorageLimitWarning}
				icon={<FileErrorIcon />}
				variant="danger"
				handleClose={() => setShowStorageLimitWarning(false)}
				title="TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.TITLE"
				message={intl.formatMessage({ id: "TEMPLATE.ATTACHMENTS.SIZE_LIMIT_WARNING.MESSAGE" }, { size: `${Constants.ATTACHMENTS_LIMIT_MB} MB` })}
				submitButtonText="GENERAL.OK"
			/>

			<Confirm
				open={zeroSizeFiles && zeroSizeFiles.length > 0}
				icon={<ReportProblemSharp color="error" style={{ height: 80, width: 100 }} />}
				variant="danger"
				handleClose={() => setZeroSizeFiles([])}
				title={intl.formatMessage({ id: 'PROVIDER.FILES.ZERO_SIZE_ERROR.TITLE' })}
				message={
					<div>
						<div>
							<FormattedMessage id="PROVIDER.FILES.ZERO_SIZE_ERROR.MESSAGE" />
						</div>

						<div className="mt-4">
							<ol>
								{
									zeroSizeFiles.map((file, index) => {
										return (
											<li
												key={index}
												className="text-left"
											>
												{file.name}
											</li>
										)
									})
								}
							</ol>
						</div>
					</div>
				}
				submitButtonText="GENERAL.DISMISS"
			/>
		</div>
	)
}

export default FileUpload;
