import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRestrictions } from '../../crud/auth.crud';
import { setUserRestriction } from '../../store/modules/actions/auth.actions';
import { getRestrictions, getUser } from '../../store/modules/selectors/account.selector';

const RESTRICTIONS_LIFE_SPAN = 10000;

function useUserRestriction() {
	const dispatch = useDispatch();

	const user = useSelector(getUser);
	const restrictions = useSelector(getRestrictions);

	useEffect(() => {
		const userId = user._id;
		const loadUserRestrictions = async () => {
			try {
				const difference = restrictions ? new Date() - restrictions.loadedOn : Infinity;
				const shouldLoadAgain = difference >= RESTRICTIONS_LIFE_SPAN;
				
				if (shouldLoadAgain) {
					const { data } = await getUserRestrictions();
					dispatch(setUserRestriction(data))
				}
			}
			catch (err) {
				console.log(err);
			}
		}

		if (userId) {
			loadUserRestrictions();
		}
		else {
			dispatch(setUserRestriction(null))
		}
	}, [
		user,
		dispatch,
		restrictions,
	]);

	const isInReadOnlyMode = (user && user.readOnly);

	return [restrictions?.data ?? null, isInReadOnlyMode];
}

export default useUserRestriction;
