import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Toolbar, Card, CardContent  } from '@material-ui/core';
import { FormattedMessage } from "react-intl";
import { getStorage } from '../../../_metronic/utils/utils';

import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import RegistrationSuccess from "./RegistrationSuccess";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import { requestLoggedInUser, flagNames } from "../../store/modules/actions/auth.actions";
import * as builder from "../../../_metronic/ducks/builder";
import { getFlags } from "../../store/modules/selectors/common.selector";

function AuthPage(props) {
  const {
    requestLoggedInUser
  } = props;

	const flags = useSelector(getFlags);
  const headerLogo = useSelector(builder.selectors.getLogo);

  useEffect(() => {
    if (getStorage('token')) {
      requestLoggedInUser();
    }
  }, [ requestLoggedInUser ])

  return (
    <>
      <AppBar position="static" className="bg-white auth-app-bar">
        <Toolbar>
          <a href="https://kaddim.com">
            <div className="d-flex">
              <div className="mr-2">
                <img src={headerLogo} alt="logo" />
              </div>
              <div className="text-dark f-16px">
                <FormattedMessage id="APP.NAME" />
              </div>
            </div>
          </a>
        </Toolbar>
      </AppBar>
      <div className="auth-layout-container container kt-grid kt-grid--ver kt-grid--root">
        {
          flags.loading[flagNames.LOGGED_IN_USER] ? (
            <div className="d-flex justify-content-center w-100 align-items-center pt-2">
              <div className="kt-loader kt-loader--lg kt-loader--brand"></div>
            </div>
          ) : (
            <div className="row flex-grow-1">
              <div className="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 px-0">
                <div className="bg-transparent">
                  <Card className="rounded-card mt-0 my-sm-5" elevation={0}>
                    <CardContent className='p-25 m-25'>
                      <div className="d-flex justify-content-center d-sm-none mb-50">
                        <div className="mr-2">
                          <img alt="logo" src={headerLogo} />
                        </div>
                        <div className="text-dark d-flex align-items-center">
                          <h4 className="m-0">
                            <FormattedMessage id="APP.NAME" />
                          </h4>
                        </div>
                      </div>
                      
                      <Switch>
                        <Route path="/auth/login" component={Login} />
                        <Route path="/auth/registered-successfully" component={RegistrationSuccess} />
                        <Route path="/auth/registration" component={Registration} />
                        <Redirect from="/auth" exact={true} to="/auth/login" />
                        <Route
                          path="/auth/forgot-password"
                          component={ForgotPassword}
                        />
                        <Route
                          path="/reset/:user/:token"
                          component={ResetPassword}
                        />
                        <Redirect from="/auth" exact={true} to="/auth/login" />
                        <Redirect to="/auth/login" />
                      </Switch>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default connect(null, { requestLoggedInUser })(AuthPage);