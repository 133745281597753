import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { parse } from "query-string";
import { Card, CardContent } from "@material-ui/core";

import { flagNames, validateTwoFactorAuthToken } from "../../store/modules/actions/auth.actions";
import * as builder from "../../../_metronic/ducks/builder";
import { getFlags } from "../../store/modules/selectors/common.selector";

function ConfirmStatus ({ wasTokenConfirmed }) {
  const history = useHistory();
  
	const flags = useSelector(getFlags);
  
  const loading = flags.loading[flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN];
	const error = flags.error[flagNames.VALIDATE_TWO_FACTOR_AUTH_TOKEN];

  if (wasTokenConfirmed) {
    return (
      <div className="col-12 text-center mb-25">
        <Alert color="success" className="text-center d-flex justify-content-center">
          <FormattedMessage id="AUTH.CONFIRM_TWO_FACTOR_AUTH.SUCCESS" />
        </Alert>

        <div className="col-4 offset-4">
          <button
            className="btn btn-primary btn-block btn-elevate"
            onClick={() => history.push('/requests')}
          >
            <FormattedMessage id="AUTH.CONFIRM_TWO_FACTOR_AUTH.GO_TO_APP" />
          </button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div className='mt-2 text-center'>
          <FormattedHTMLMessage id="AUTH.CONFIRM_TWO_FACTOR_AUTH.CONFIRMING" />
        </div>
        <div className="mt-4 position-relative kt-spinner kt-spinner--lg kt-spinner--primary" style={{ left: '-15px' }} />
      </div>
    );
  }

  if (error) {
    return (
      <Alert color="danger">
        <FormattedMessage id="AUTH.CONFIRM_TWO_FACTOR_AUTH.ERROR" />
      </Alert>
    );
  }

  return null;
}

const ConfirmTwoFactorAuth = (props) => {
	const dispatch = useDispatch();

  const [wasTokenConfirmed, setTokenConfirmed] = useState(false);

  const headerLogo = useSelector(builder.selectors.getLogo);
	
  useEffect(() => {
    async function validateToken() {
      const { uid, token } = parse(window.location.search);

      const { status } = await dispatch(validateTwoFactorAuthToken(uid, token));

      setTokenConfirmed(status === 200);
    }

    validateToken();
  }, [dispatch]);

	return (
    <div className="min-vh-100 min-vw-100 row align-items-center justify-content-center">
      <div className="px-0">
        <Card className="rounded-card mt-0 my-sm-5" elevation={0}>
          <CardContent className='p-25 m-25'>
            <div className="d-flex justify-content-center mb-50">
              <div className="mr-2">
                <img alt="logo" src={headerLogo} />
              </div>
              <div className="text-dark d-flex align-items-center">
                <h4 className="m-0">
                  <FormattedMessage id="APP.NAME" />
                </h4>
              </div>
            </div>

            <div className="mt-20px">
              <div className="text-center mb-50">
                <h3>
                  <FormattedMessage id="AUTH.CONFIRM_TWO_FACTOR_AUTH.TITLE" />
                </h3>
              </div>

              <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                <ConfirmStatus
                  wasTokenConfirmed={wasTokenConfirmed}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
		</div>
	);
}

export default ConfirmTwoFactorAuth;
