import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import List from "./List";
import Create from "./Create/index";
import Edit from "./Edit/index";

export default function Requests() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/requests" exact component={List} />
				<Route path="/requests/create" component={Create} />
				<Route path="/requests/:request" exact component={Edit} />
			</Switch>
		</Suspense>
	);
}
