import React from "react";
import {
	Formik
} from "formik";
import {
	FormattedMessage,
} from "react-intl";
import {
	useSelector,
	useDispatch,
} from "react-redux";
import {
	Dialog,
	DialogTitle,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	updateDocument,
	flagNames,
} from '../../../../store/modules/actions/requests.actions';
import { getFlags } from "../../../../store/modules/selectors/common.selector";

function DeleteFile(props) {
	const dispatch = useDispatch();
	const {
		open = true,
		document: doc,
		fileIndexToDelete,
		handleClose,
	} = props;

	const flags = useSelector(getFlags);
	const loading = flags.loading[flagNames.DOCUMENT_UPDATE];
	const error = flags.error[flagNames.DOCUMENT_UPDATE];

	return (
		<Dialog
			open={open}
			onClose={() => handleClose()}
			fullWidth
			classes={{
				paper: "rounded-card"
			}}
			maxWidth="xs"
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<Formik
				initialValues={{
					reason: '',
				}}
				validateOnChange={false}
				validateOnBlur={true}
				onSubmit={async (values) => {
					await dispatch(
						updateDocument(doc._id, {
							...doc,
							files: doc.files.filter((_, fileIndex) => fileIndex !== fileIndexToDelete),
						})
					);

					handleClose(true);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => {
					return (
						<form
							noValidate={true}
							autoComplete="off"
							onSubmit={handleSubmit}
						>
							<div className="container p-20">
								<div className="row f-15 label-4">
									<div className="col-10 offset-1">
										<h4 className="mb-0 text-center">
											<FormattedMessage id="REQUEST.DOCUMENT.FILE.DELETE.TITLE" />
										</h4>
									</div>
									<div className="col-1">
										<div className="float-right cursor-pointer" onClick={() => handleClose()}>
											<span className="fas fa-times"></span>
										</div>
									</div>
								</div>

								<div className="text-center f-15 label-4 mb-15 mt-15">
									<h6 className="m-0">
										<FormattedMessage
											id="REQUEST.DOCUMENT.FILE.DELETE.MESSAGE"
											values={{
												...doc.files[fileIndexToDelete],
												document: doc.name,
											}}
										/>
									</h6>
								</div>

								<div>
									{
										error && (
											<div className="d-flex justify-content-center mb-15">
												<Alert color="danger">
													{error}
												</Alert>
											</div>
										)
									}

									<div className="row">
										<div className="col-6 pl-4">
											<button
												className={`btn btn-danger btn-block`}
												type="button"
												onClick={() => handleClose()}
												disabled={loading}
											>
												<FormattedMessage id="GENERAL.CANCEL" />
											</button>
										</div>
										<div className="col-6 pr-4">
											<button
												className={`btn btn-block ${clsx(
													{
														"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
														// "btn-secondary": !values.reason,
														// "btn-primary": values.reason,
														"btn-primary": true,
													}
												)}`}
												type="submit"
												disabled={loading}
											>
												<FormattedMessage id="GENERAL.YES" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					);
				}}
			</Formik>
		</Dialog>
	);
}

export default DeleteFile;
