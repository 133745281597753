import React, { useMemo } from "react";
import { Icon, makeStyles, Tooltip } from "@material-ui/core";
import { useIntl } from "react-intl";
import clsx from "clsx";

import Constants from "../constants";
import theme from "../../../_metronic/materialUIThemeProvider/ThemeProvider";

function getBackgroundColor (status) {
	switch (status) {
		case Constants.DocumentStatus.APPROVED: {
			return theme.palette.success.main;
		}
		case Constants.DocumentStatus.REJECTED: {
			return theme.palette.error.main;
		}
		case Constants.DocumentStatus.REQUESTED: {
			return theme.palette.extraColors.backgroundBlue;
		}
		default:
		case Constants.DocumentStatus.UPLOADED: {
			return theme.palette.warning.main;
		}
	}
}
function getTextColor(status) {
	switch (status) {
		case Constants.DocumentStatus.REJECTED:
		case Constants.DocumentStatus.APPROVED: {
			return "white"
		}
		case Constants.DocumentStatus.REQUESTED: {
			return theme.palette.extraColors.lightText
		}
		default:
		case Constants.DocumentStatus.UPLOADED: {
			return theme.palette.dark.primary.main;
		}
	}
}

const useStyles = makeStyles({
	container: {
		height: 26,
		borderRadius: 13,
		padding: 10,
		color: (props) => getTextColor(props.status),
		backgroundColor: (props) => getBackgroundColor(props.status)
	},
});


export default function ElementStatus({ status, tooltipText = "" }) {
	const classes = useStyles({ status });
	const intl = useIntl();
	const statusText = useMemo(() => {
		const prefix = "REQUEST.DOCUMENT.STATUS";
		const txt = intl.formatMessage({ id: `${prefix}.${status}` });

		if (status === Constants.DocumentStatus.UPLOADED) {
			return null;
		}

		return txt.startsWith(prefix) ? null : txt;
	}, [ intl, status ]);

	const iconName = useMemo(() => {
		switch (status) {
			case Constants.DocumentStatus.APPROVED: {
				return "done_all";
			}
			case Constants.DocumentStatus.NOT_REQUIRED: {
				return "done_all";
			}
			case Constants.DocumentStatus.REJECTED: {
				return "error";
			}
			case Constants.DocumentStatus.REQUESTED: {
				return "hourglass_empty";
			}
			default:
			case Constants.DocumentStatus.UPLOADED: {
				return "timelapse";
			}
		}
	}, [ status ]);

	return (
		<div className={clsx(classes.container, "d-flex align-items-center")}>
			<Tooltip
				title={tooltipText}
				open={tooltipText ? undefined : false}
			>
				<div className="d-flex align-items-center">
					<div className="d-flex">
						<Icon fontSize="small">{iconName}</Icon>
					</div>
					{
						statusText && (
							<div className="ml-1 f-12px">
								{statusText}
							</div>
						)				
					}
				</div>
			</Tooltip>
		</div>
	)
};
