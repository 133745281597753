import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import Requests from "./Requests/index";
// import Customization from "./Customizations/index";
import Profile from "./Profile/index";
import NotificationSettings from "./NotificationSettings/index";
import Templates from "./Customizations/Templates";
import Billing from "./Billing";
import Pricing from "./Pricing";
import ManageAddon from "./ManageAddon";
import Integrations from "./Integrations/index";
import { LayoutSplashScreen } from "../../../_metronic";
import SocketService from "../../common/services/SocketService";
import { loadUnreadCount } from "../../store/modules/actions/notifications.actions";
import { loadIntegrations } from "../../store/modules/actions/integrations.actions";
import UpgradePlanModal from "../../common/modals/upgrade-plan";

function HomePage(props) {
  const dispatch = useDispatch();

  const auth = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(loadUnreadCount());
    dispatch(loadIntegrations());
  }, [
    dispatch
  ]);

  useEffect(() => {
    const eventName = `notification/${auth.user._id}`;

    SocketService.socket.on(eventName, loadUnreadCount);

    return function () {
      SocketService.socket.removeAllListeners([ eventName ]);
    }
  }, [
    auth,
  ]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/requests" component={Requests} />
        <Route path="/templates" component={Templates} />
        <Route path="/profile" component={Profile} />
        <Route path="/notification-settings" component={NotificationSettings} />
        <Route path="/integrations" component={Integrations} />
        <Route path="/billing" component={Billing} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/manage-addon" component={ManageAddon} />
        <Redirect to="/requests" />
      </Switch>

      <UpgradePlanModal />
    </Suspense>
  );
}

export default HomePage;
