import axios from "axios";
import _ from "lodash";

export const BASE_URL = "api/v1/users";
export const LOGIN_URL = `${BASE_URL}/login`;
export const REGISTER_URL = `${BASE_URL}/signup`;
export const REQUEST_PASSWORD_URL = `${BASE_URL}/reset`;
export const RESET_PASSWORD_URL = `${BASE_URL}/update-password`;
export const LOGOUT_URL = `${BASE_URL}/logoff`;
export const UPDATE_PASSWORD_URL = `${BASE_URL}/change-password`;

export const ME_URL = `${BASE_URL}/me`;

export function login(email, password, twoFactorAuthCode) {
  return axios.post(LOGIN_URL, { email, password, code: twoFactorAuthCode });
}

export function register(email, firstName, lastName, password, company, phone, referralCode, howFoundOut, howFoundOutSource) {
  return axios.post(REGISTER_URL, { email, firstName, lastName, password, company, phone, referralCode, howFoundOut, howFoundOutSource });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(userId, token, password) {
  return axios.post(RESET_PASSWORD_URL, { userId, token, password });
}

export function updatePassword(oldPassword, newPassword) {
  return axios.post(UPDATE_PASSWORD_URL, { oldPassword, newPassword });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function update(userId, firstName, lastName, company, phone, profilePicture) {
  const data = {
    firstName,
    lastName,
    company,
    phone,
  };

  if (!_.isNil(profilePicture)) {
    data.companyLogo = profilePicture;
  }

  return axios.patch(`${BASE_URL}/${userId}`, data);
}

export function updateChatService(userId, chatService) {
  const data = {
    chatService,
  };

  return axios.patch(`${BASE_URL}/${userId}`, data);
}

export async function updateTwoFactorAuthService(userId, twoFactorAuthService, password) {
  const path = `mfa/${twoFactorAuthService ? "enable" : "disable"}`;
  return axios.post(`${BASE_URL}/${path}`, { password });
}

export async function addTwoFactorAuthEmail(userId, email) {
  return axios.post(`${BASE_URL}/mfa/add-email`, { email });
}

export async function removeTwoFactorAuthEmail(userId, email) {
  return axios.post(`${BASE_URL}/mfa/remove-email`, { email });
}

export async function validateTwoFactorAuthToken(uid, token) {
  return axios.post(`${BASE_URL}/mfa/verify-email`, { uid, token });
}

export function updateDownloadFormat(userId, downloadFormat) {
  const data = {
    downloadFormat,
  };

  return axios.patch(`${BASE_URL}/${userId}`, data);
}

export function updateFileRenameSeparator(userId, fileRenameSeparator) {
  const data = {
    fileRenameSeparator,
  };

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        status: 200,
        data,
      })
    }, 500);
  });
  // return axios.patch(`${BASE_URL}/${userId}`, data);
}

export function updateNotificationSettings(userId, notificationSettings) {
  const data = {
    notificationSettings
  };

  return axios.patch(`${BASE_URL}/${userId}`, data);
}

export function logout() {
  return axios.post(LOGOUT_URL);
}

function getLatestAgreement(type) {
  return axios.get(`api/v1/agreements/latest?type=${type}`)
    .then((res) => res.data.file);
}
export function getLatestTOS() {
  return getLatestAgreement('tos');
}

export function getLatestPP() {
  return getLatestAgreement('pp');
}

export function getBillingDetails () {
  return axios.get(`${BASE_URL}/billing-details`)
}

export function managePayment () {
  return axios.post(`${BASE_URL}/manage-payment`);
}

export function getUserRestrictions () {
  return axios.get(`${BASE_URL}/restrictions`);
}

export function updateSettings({ layoutSettings, fileRenameSeparator }) {
  const data = {
    layoutSettings,
    fileRenameSeparator,
  };

  return axios.patch(`${BASE_URL}/update-settings`, data);
}