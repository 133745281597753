import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Icon, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { FormattedMessage, useIntl } from "react-intl";
import {
	ELEMENT_TYPES,
	Heading,
	ShortText,
	LongText,
	RadioButton,
	Checkbox,
	Video,
	Dropdown,
	Image,
	BankStatement,
	Note,
	SharedFiles,
	FileUpload,
	VideoInput,
	AudioInput,
} from "./ElementType";
import Constants from "../constants";
import { performOperation, V2_OPERATIONS } from "../../store/modules/actions/requests.actions";
import ErrorMessage from "./ElementType/ErrorMessage";
import { READ_ONLY_TYPES, TYPE_WITH_SETTINGS } from "./Element";
import ElementStatus from "./ElementStatus";
import { getBankStatementUrl } from "../../store/modules/selectors/common.selector";

const useStyles = makeStyles((theme) => {
	return {
		actionColumn: {
			minWidth: 134,
		},
		handleStyle: {
			color: theme.palette.primary.main,
			cursor: "move",
		},
		unreadCommentsCount: {
			height: 28,
			width: 28,
			borderRadius: 14,

			position: "absolute",
			top: 0,
			right: 0,
			transform: "translate(50%, -50%)",
		}
	}
});

function UpdatableComponentActionButtons({
	show,
	id,
	type,
	status,
	additionalData,
	unreadCommentsCount,
}) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const isReadOnly = useMemo(() => READ_ONLY_TYPES.includes(type), [type]);
	const hasSettings = useMemo(() => TYPE_WITH_SETTINGS.includes(type), [type]);

	return (
		<div>
			{
				!additionalData.hideComment && (
					<div className="mb-2 position-relative">
						<button
							className={
								clsx(
									"btn btn-block d-flex align-items-center",
									{
										"btn-dark text-light": !!unreadCommentsCount,
										"btn-light text-dark": !unreadCommentsCount && show,
										"opacity-0": !unreadCommentsCount && !show,
									}
								)
							}
							onClick={() => dispatch(
								performOperation(
									V2_OPERATIONS.VIEW_COMMENTS,
									{ uuid: id }
								)
							)}
						>
							<Icon className="mr-2">chat</Icon>
							<span className="f-14px">
								<FormattedMessage id="ELEMENT_LIST.ITEM.COMMENTS" />
							</span>
						</button>

						{
							unreadCommentsCount > 0 && (
								<div className={clsx(classes.unreadCommentsCount, "p-2 d-flex justify-content-center align-items-center bg-danger text-light")}>
									{unreadCommentsCount}
								</div>
							)
						}
					</div>
				)
			}

			{
				!isReadOnly && (
					<React.Fragment>
						{
							!additionalData.hideApprove && (
								<div
									className={clsx(
										"mb-2",
										{
											"opacity-0": !show,
										}
									)}
								>
									<button
										className={
											clsx(
												"btn btn-block d-flex align-items-center",
												{
													"btn-disabled opacity-50 pointer-events-none": status === Constants.DocumentStatus.REQUESTED,
													"btn-light text-success": status !== Constants.DocumentStatus.APPROVED,
													"btn-success text-light": status === Constants.DocumentStatus.APPROVED,
												}
											)
										}
										disabled={[Constants.DocumentStatus.APPROVED, Constants.DocumentStatus.REQUESTED].includes(status) || additionalData.disabled}
										onClick={() => dispatch(
											performOperation(
												V2_OPERATIONS.APPROVE,
												{ uuid: id }
											)
										)}
									>
										<Icon className="mr-2">thumb_up_off_alt</Icon>
										<span className="f-14px">
											<FormattedMessage id="ELEMENT_LIST.ITEM.APPROVE" />
										</span>
									</button>
								</div>
							)
						}
						{
							!additionalData.hideReject && (
								<div
									className={clsx(
										"mb-2",
										{
											"opacity-0": !show,
										}
									)}
								>
									<button
										className={
											clsx(
												"btn btn-block d-flex align-items-center",
												{
													"btn-disabled opacity-50 pointer-events-none": status === Constants.DocumentStatus.REQUESTED,
													"btn-light text-danger": status !== Constants.DocumentStatus.REJECTED,
													"btn-danger text-light": status === Constants.DocumentStatus.REJECTED,
												}
											)
										}
										disabled={[Constants.DocumentStatus.REJECTED, Constants.DocumentStatus.REQUESTED].includes(status) || additionalData.disabled}
										onClick={() => dispatch(
											performOperation(
												V2_OPERATIONS.REJECT,
												{ uuid: id }
											)
										)}
									>
										<Icon className="mr-2">thumb_down_off_alt</Icon>
										<span className="f-14px">
											<FormattedMessage id="ELEMENT_LIST.ITEM.REJECT" />
										</span>
									</button>
								</div>
							)
						}
					</React.Fragment>
				)
			}

			{
				hasSettings && (
					<div
						className={clsx(
							"mb-2",
							{
								"opacity-0": !show,
							}
						)}
						id={`element-settings-container-${id}`}
					/>
				)
			}

			<div className="mb-2">
				<button
					className={clsx(
						"btn btn-light btn-block",
						{
							"d-flex align-items-center": show,
							"d-none": !show,
						}
					)}
					onClick={() => dispatch(
						performOperation(
							V2_OPERATIONS.DELETE_ELEMENT,
							{ uuid: id }
						)
					)}
				>
					<Icon className="mr-2">delete_outline</Icon>
					<span className="f-14px">
						<FormattedMessage id="ELEMENT_LIST.ITEM.DELETE" />
					</span>
				</button>
			</div>
		</div>
	)
}

function UpdatableWrapperComponent({ Component, type, ...otherProps }) {
	const {
		id,
		status,
		additionalData = {},
		unreadCommentsCount,
		markNotRequiredReason,
		rejectedReason,
		dragHandleProps,
	} = otherProps;
	const elementRef = useRef();
	const [isActive, setIsActive] = useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

	const tooltipText = useMemo(() => {
		if (status === Constants.DocumentStatus.NOT_REQUIRED || status === Constants.DocumentStatus.APPROVED) {
			return markNotRequiredReason;
		}
		else if (status === Constants.DocumentStatus.REJECTED) {
			return rejectedReason;
		}
		else {
			return undefined;
		}
	}, [
		status,
		markNotRequiredReason,
		rejectedReason,
	]);
	const isReadOnly = useMemo(() => READ_ONLY_TYPES.includes(type), [type]);

	return (
		<div
			className="d-flex flex-column flex-md-row"
			onMouseOver={() => !isDownSm && !(elementRef.current?.shouldStopMouseListener()) &&  setIsActive(true)}
			onMouseOut={() => !isDownSm && !(elementRef.current?.shouldStopMouseListener()) && setIsActive(false)}
		>
			<div
				style={{
					visibility: isActive ? "visible" : "hidden",
					cursor: "move"
				}}
				className={clsx(classes.handleStyle, "mt-2 mr-3")}
				{...dragHandleProps}
			>
				<Icon>drag_handle</Icon>
			</div>

			<div className="row mx-0 mx-md-auto flex-grow-1">
				<div className="col-12 col-md-10 pl-0 pr-0 pr-md-2">
					{
						!additionalData.hideStatus && !isReadOnly && (
							<div className="d-flex mr-10px mb-1">
								<ElementStatus
									status={status}
									tooltipText={tooltipText}
								/>
							</div>
						)
					}

					<Component
						{...otherProps}
						ref={elementRef}
						isActive={isActive}
					/>

					<div className="d-flex d-md-none my-3 pl-0">
						<div
							className="p-2 text-primary cursor-pointer bg-light rounded d-flex align-items-center"
							onClick={() => setIsActive(!isActive)}
						>
							<MoreHoriz />
						</div>
					</div>
				</div>

				<div
					className={
						clsx(
							classes.actionColumn,
							"col-2 d-md-flex flex-column px-0 px-md-2 pt-md-2 pt-lg-0",
							{
								"h-0": !isActive,
							}
						)
					}
				>
					<UpdatableComponentActionButtons
						show={isActive}
						id={id}
						type={type}
						status={status}
						additionalData={additionalData}
						unreadCommentsCount={unreadCommentsCount}
					/>
				</div>
			</div>
		</div>
	)
}

const Updatable = ({
	dragHandleProps,
	element,
	onChangeElement,
	onChange,
	onChangeResponse,
	onPreview,
	additionalData,
}) => {
	const {
		uuid: id,
		type,
		status,
		markNotRequiredReason,
		dataObj: data,
		refData,
		responseObj: response,
		unreadCommentsCount,
	} = element;
	const commonProps = {
		id,
		type,
		status,
		data,
		refData,
		response,
		additionalData,
		onChangeElement,
		onChange,
		onChangeResponse,
		onPreview,
		unreadCommentsCount,
		markNotRequiredReason,
		dragHandleProps,
	};
	const intl = useIntl();
	const canUseBankStatementElement = useSelector(getBankStatementUrl);

	return (
		<div className="py-4 rounded d-flex position-relative updatable-element element-container">
			<div className="flex-grow-1">
				<div className="element">
					{
						type === ELEMENT_TYPES.HEADING && (
							<UpdatableWrapperComponent
								Component={Heading.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.SHORT_TEXT && (
							<UpdatableWrapperComponent
								Component={ShortText.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.LONG_TEXT && (
							<UpdatableWrapperComponent
								Component={LongText.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.RADIO_BUTTONS && (
							<UpdatableWrapperComponent
								Component={RadioButton.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.CHECKBOXES && (
							<UpdatableWrapperComponent
								Component={Checkbox.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.VIDEO && (
							<UpdatableWrapperComponent
								Component={Video.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.DROPDOWN && (
							<UpdatableWrapperComponent
								Component={Dropdown.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.IMAGE && (
							<UpdatableWrapperComponent
								Component={Image.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.BANK_STATEMENTS && (
							canUseBankStatementElement ? (
								<UpdatableWrapperComponent
									Component={BankStatement.Updatable}
									{...commonProps}
								/>
							) : (
								<div>
									<ErrorMessage
										message={
											intl.formatMessage({
												id: "ELEMENT_LIST.ITEM.BANK_STATEMENTS.INACTIVE_INTEGRATION"
											})
										}
									/>
								</div>
							)
						)
					}
					{
						type === ELEMENT_TYPES.NOTES && (
							<UpdatableWrapperComponent
								Component={Note.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.SHARED_FILES && (
							<UpdatableWrapperComponent
								Component={SharedFiles.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.FILE_UPLOAD && (
							<UpdatableWrapperComponent
								Component={FileUpload.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.VIDEO_INPUT && (
							<UpdatableWrapperComponent
								Component={VideoInput.Updatable}
								{...commonProps}
							/>
						)
					}
					{
						type === ELEMENT_TYPES.AUDIO_INPUT && (
							<UpdatableWrapperComponent
								Component={AudioInput.Updatable}
								{...commonProps}
							/>
						)
					}
				</div>
			</div>
		</div>
	);
}

export default Updatable;
