import _ from "lodash";

import { setErrorFlag, setLoadingFlag } from "./loading-flag.actions";
import * as IntegrationsCURD from "../../../crud/integrations.crud";

export const actionTypes = {
	INTEGRATIONS_LOADED: "[INTEGRATIONS] INTEGRATIONS_LOADED",
	INTEGRATION_ADDED: "[INTEGRATIONS] INTEGRATION_ADDED",
	INTEGRATION_UPDATED: "[INTEGRATIONS] INTEGRATION_UPDATED",
	INTEGRATION_REGENERATED: "[INTEGRATIONS] INTEGRATION_REGENERATED",
};

export const flagNames = {
	LOADING_INTEGRATIONS: "[INTEGRATIONS] LOADING_INTEGRATIONS",
	ADDING_INTEGRATION: "[INTEGRATIONS] ADDING_INTEGRATION",
	UPDATING_INTEGRATION: "[INTEGRATIONS] UPDATING_INTEGRATION",
	REGENERATING_INTEGRATION: "[INTEGRATIONS] REGENERATING_INTEGRATION",
};

export const loadIntegrations = () => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.LOADING_INTEGRATIONS, true));

		try {
			const { status, data } = await IntegrationsCURD.getIntegrations();

			if (status === 200) {
				dispatch({
					type: actionTypes.INTEGRATIONS_LOADED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.LOADING_INTEGRATIONS, data.message));
			}

			dispatch(setLoadingFlag(flagNames.LOADING_INTEGRATIONS, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.LOADING_INTEGRATIONS, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const addConfig = (integrationId, isActive, configObj) => {
	return async (dispatch, getStore) => {
		dispatch(setLoadingFlag(flagNames.ADDING_INTEGRATION, integrationId));

		try {
			const store = getStore();
			const userId = _.get(store, 'auth.user._id', null);
			const { status, data } = await IntegrationsCURD.addIntegrationConfig(integrationId, {
				userId,
				isActive,
				configObj,
			});

			if (status === 200) {
				dispatch({
					type: actionTypes.INTEGRATION_ADDED,
					payload: data.data.integrationConfig,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.ADDING_INTEGRATION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.ADDING_INTEGRATION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.ADDING_INTEGRATION, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const updateConfig = (integrationConfigId, isActive, configObj) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPDATING_INTEGRATION, integrationConfigId));

		try {
			const { status, data } = await IntegrationsCURD.updateIntegrationConfig(integrationConfigId, {
				isActive,
				configObj
			});

			if (status === 200) {
				dispatch({
					type: actionTypes.INTEGRATION_UPDATED,
					payload: data,
				});
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATING_INTEGRATION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATING_INTEGRATION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.UPDATING_INTEGRATION, e.toString()));
			return Promise.reject(e);
		}
	}
};

// specifically used for zapier config when user enables the integration for the first time
export const activateConfig = (integrationConfigId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.UPDATING_INTEGRATION, integrationConfigId));

		try {
			const { status, data } = await IntegrationsCURD.activateIntegration(integrationConfigId);

			if (status === 200) {
				dispatch(loadIntegrations());
			}
			else {
				dispatch(setErrorFlag(flagNames.UPDATING_INTEGRATION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.UPDATING_INTEGRATION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.UPDATING_INTEGRATION, e.toString()));
			return Promise.reject(e);
		}
	}
};

export const regenerateConfig = (integrationConfigId) => {
	return async (dispatch) => {
		dispatch(setLoadingFlag(flagNames.REGENERATING_INTEGRATION, integrationConfigId));

		try {
			const { status, data } = await IntegrationsCURD.regenerateIntegrationConfig(integrationConfigId);

			if (status === 200) {
				await dispatch(loadIntegrations());
			}
			else {
				dispatch(setErrorFlag(flagNames.REGENERATING_INTEGRATION, data.message));
			}

			dispatch(setLoadingFlag(flagNames.REGENERATING_INTEGRATION, false));
			return Promise.resolve({ status, data });
		}
		catch (e) {
			dispatch(setErrorFlag(flagNames.REGENERATING_INTEGRATION, e.toString()));
			return Promise.reject(e);
		}
	}
}