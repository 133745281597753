import React from "react";
import { withStyles, Tab as DefaultTab, Tabs as DefaultTabs } from "@material-ui/core";

export const Tabs = withStyles({
	root: {
		borderBottom: '1px solid #e8e8e8',
	},
	indicator: {
		backgroundColor: '#3461F6',
	},
})(DefaultTabs);

export const Tab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(4),
		fontSize: 16,
		'&:hover': {
			color: '#3461F6',
			opacity: 1,
		},
		'&$selected': {
			color: '#626C71',
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: '#3461F6',
		},
	},
	selected: {},
}))((props) => <DefaultTab disableRipple {...props} />);
