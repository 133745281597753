import React, {
	useRef,
	useCallback,
} from "react";
import {
	FormattedMessage,
	useIntl
} from "react-intl";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	FormControlLabel,
	Checkbox,
	FormGroup,
	makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import {
	updateFilter,
} from '../../../../store/modules/actions/requests.actions';
import Constants from '../../../../common/constants';
import { getRequestFilter } from "../../../../store/modules/selectors/request.selector";

const useStyles = makeStyles((theme) => {
	return {
		requestListFilter: {
			border: "1px solid rgba(49, 94, 255, 0.4)",
			boxShadow: "0px 4px 16px rgba(46, 91, 255, 0.29)"
		},
	};
});

function RequestListFilter() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const intl = useIntl();

	const filter = useSelector(getRequestFilter);
	const statuses = useRef([
		{
			id: Constants.RequestStatus.IN_PROGRESS,
			label: intl.formatMessage({ id: `REQUEST.STATUS.IN_PROGRESS` })
		},
		{
			id: Constants.RequestStatus.COMPLETED,
			label: intl.formatMessage({ id: `REQUEST.STATUS.COMPLETED` })
		},
		{
			id: Constants.RequestStatus.CANCELLED,
			label: intl.formatMessage({ id: `REQUEST.STATUS.CANCELLED` })
		},
	]);

	const toggleFilter = useCallback((value) => {
		const index = filter.statusFilters.indexOf(value);
		const newFilters = filter.statusFilters.slice();

		if (index > -1) {
			newFilters.splice(index, 1);
		}
		else {
			newFilters.push(value);
		}

		dispatch(updateFilter({
			statusFilters: newFilters,
		}));
	}, [
		dispatch,
		filter
	])

	return (
		<div className={clsx("bg-white rounded py-15px px-20px mt-5px", classes.requestListFilter)}>
			<div>
				<div className="text-muted fw-500">
					<FormattedMessage id="REQUEST.FILTER.SHOW" />
				</div>
				<div>
					<FormGroup row className="flex-column flex-sm-row">
						<FormattedMessage id="REQUEST.FILTER.UNREAD_ONLY">
							{
								(text) => (
									<FormControlLabel
										className="mb-0 pl-3 pl-sm-0 pr-5 pr-sm-0"
										control={
											<Checkbox
												checked={filter.unreadOnly}
												onChange={(e) => dispatch(updateFilter({ unreadOnly: e.target.checked }))}
												color="primary"
											/>
										}
										label={text}
									/>
								)
							}
						</FormattedMessage>
						<FormattedMessage id="REQUEST.FILTER.UPDATED_ONLY">
							{
								(text) => (
									<FormControlLabel
										className="mb-0 pl-3 pl-sm-0 pr-5 pr-sm-0"
										control={
											<Checkbox
												checked={filter.updatedOnly}
												onChange={(e) => dispatch(updateFilter({ updatedOnly: e.target.checked }))}
												color="primary"
											/>
										}
										label={text}
									/>
								)
							}
						</FormattedMessage>
					</FormGroup>
				</div>
			</div>

			<div>
				<hr className="mt-10px mb-20px" />
			</div>

			<div>
				<div className="text-muted fw-500">
					<FormattedMessage id="REQUEST.FILTER.STATUS" />
				</div>
				<div>
					<FormGroup row className="flex-column flex-sm-row">
						{
							statuses.current.map(({ id, label }) => (
								<FormControlLabel
									className="mb-0 pl-3 pl-sm-0 pr-5 pr-sm-0"
									control={
										<Checkbox
											checked={filter.statusFilters.includes(id)}
											onChange={() => toggleFilter(id)}
											name={label}
											color="primary"
										/>
									}
									label={label}
								/>
							))
						}
					</FormGroup>
				</div>
			</div>
		</div>
	);
}

export default RequestListFilter;
