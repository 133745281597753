import React, { useMemo } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getFileExtension } from "../../../_metronic";

function CustomFileIcon ({ filename, height = 20, width = 20 }) {
	const extension = useMemo(() => getFileExtension(filename), [filename])
	const additionalStyles = useMemo(() => {
		const key = extension && extension.toLowerCase();
		return defaultStyles[key] || {};
	}, [extension]);
	const glyphColor = useMemo(() => extension === "PDF" ? "#D93831" : undefined, [extension]);

	if (!extension) {
		return null;
	}

	return (
		<div style={{ height, width }}>
			<FileIcon
				extension={extension}
				{...additionalStyles}
				glyphColor={glyphColor}
			/>
		</div>
	);
}

export default CustomFileIcon;
