import React, { useState,  } from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	Card,
	CardContent,
} from '@material-ui/core';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

function FormContent({ data, step, setStep }) {
	if (step === 1) {
		return (
			<Step1
				data={data}
				nextStep={(data) => setStep(2, {1: data})}
			/>
		);
	}
	else if (step === 2) {
		return (
			<Step2
				data={data}
				nextStep={(data) => setStep(3, {2: data})}
			/>
		);
	}
	else {
		return (
			<Step3
				data={data}
				nextStep={() => setStep(0)}
			/>
		);
	}
}

function ResetPassword(props) {
	const {
		onComplete,
	} = props;
	const [step, setStep] = useState(1);
	const [data, setData] = useState({});
	
	return (
		<div className="container px-4">
			<div className="py-50 row flex-grow-1">
				<div className="col-10 offset-1 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 px-0 pb-50">
					<div className="f-30 strong mb-25 text-center">
						<FormattedMessage
							id={
								step === 1
									? "PROVIDER.FORGOT_PASSWORD.CARD_TITLE"
									: "PROVIDER.RESET_PASSWORD.CARD_TITLE"
							}
						/>
					</div>
					<div className="m-0 f-15 text-center mb-40">
						<FormattedMessage id="PROVIDER.FORGOT_PASSWORD.DESCRIPTION" />
					</div>

					<Card className="rounded-card" elevation={0}>
						<CardContent className='p-25 m-25'>
							<FormContent
								data={data}
								step={step}
								setStep={(value, stepData={}) => {
									if (value === 0) {
										return onComplete();
									}

									setStep(value);
									
									setData({
										...data,
										...stepData
									});
								}}
							/>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
