import { cancelRequest, downloadFileFromUrl, getNewCancelToken, vanillaAxios } from "../../../../_metronic";
import axios from "axios";
import * as ProviderCRUD from "../../../crud/provider.crud";

export const actionTypes = {
	SET_TASK: '[TASKS] SET_TASK',
	REMOVE_TASK: '[TASKS] REMOVE_TASK',
	TASK_RESULT: '[TASKS] TASK_RESULT',
};

export const setTask = (id, data) => {
	const payload = {
		id,
		...data,
	};

	return {
		type: actionTypes.SET_TASK,
		payload
	}
}

export const cancelTask = async ({id, type}) => {
	if (type === "axios") {
		cancelRequest(id);
	}
	else {
		console.error(`cancelling task ${id} of type ${type} is not supported`);
	}
}

export const downloadFileTask = (url, filename, message) => {
	return async (dispatch) => {
		let source = null;

		try {
			source = getNewCancelToken();

			const response = await downloadFileFromUrl(
				url,
				filename,
				source.id,
				(progress) => {
					dispatch(
						setTask(source.id, {
							text: message || `Downloading ${filename}`,
							type: "axios",
							progress,
						})
					);
				}
			);

			setTimeout(() => {
				dispatch(removeTask(source.id));
			}, 2000);

			return response;
		}
		catch (e) {
			if (axios.isCancel(e)) {
				dispatch(removeTask(source.id));
				return {
					wasCancelled: true
				};
			}

			throw e;
		}
	}
}

export const uploadFileTask = (url, file, message, requestConfig) => {
	return async (dispatch) => {
		let source = null;
		
		try {
			source = getNewCancelToken();
			let response = null;

			if (requestConfig) {
				response = await vanillaAxios({
					...requestConfig,
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

						dispatch(
							setTask(source.id, {
								text: message || `Uploading ${file.name}`,
								type: "axios",
								progress: percentCompleted,
							})
						);
					},
				})
			}
			else {
				response = await ProviderCRUD.uploadFile(
					url,
					file,
					source.id,
					(progress) => {
						dispatch(
							setTask(source.id, {
								text: message || `Uploading ${file.name}`,
								type: "axios",
								progress,
							})
						);
					}
				);
			}

			setTimeout(() => {
				dispatch(removeTask(source.id));
			}, 2000);

			return response;
		}
		catch (e) {
			console.log(e);
			if (axios.isCancel(e)) {
				dispatch(removeTask(source.id));
				return {
					wasCancelled: true
				};
			}

			throw e;
		}
	}
}

export const removeTask = (id, shouldCancel = true) => {
	return async (dispatch, getStore) => {
		const { tasks: { status } } = getStore();
		const task = status[id];

		if (!task) {
			return;
		}

		if (shouldCancel) {
			await cancelTask({ id, type: task.type });
		}

		dispatch({
			type: actionTypes.REMOVE_TASK,
			payload: {
				id,
			}
		});
	}
}

export const uploadFileTaskResult = (uploadId, result) => {
	return {
		type: actionTypes.TASK_RESULT,
		payload: {
			uploadId,
			result,
		},
	};
}
