/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";

import KTOffcanvas from "../../../_metronic/_assets/js/offcanvas";
import {
  clearNotifications,
  loadNotifications,
  markNotificationsAsRead,
  deleteNotification,
  flagNames,
} from "../../store/modules/actions/notifications.actions";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

function NotificationsList ({
  loading,
  error,
  notifications,
  totalCount,
  onLoadMore,
  onDelete,
  markRead
}) {
  return (
    <div className="kt-quick-panel__content pt-0">
      <div
        className="px-4 text-right text-primary cursor-pointer"
        onClick={() => {
          const notificationIds = notifications.map(({ _id }) => _id);
          markRead(notificationIds);
        }}
      >
        <FormattedMessage id="APP.NOTIFICATIONS.MARK_ALL_READ" />
      </div>

      {
        !!error && (
          <div className="d-flex justify-content-center mt-2">
            <Alert color="danger">
              {error}
            </Alert>
          </div>
        )
      }

      <PerfectScrollbar
        options={perfectScrollbarOptions}
        style={{
          maxHeight: "calc(100vh - 100px)",
          position: "relative"
        }}
      >
        <div className="kt-notification">
          {
            notifications.map((notification) => {
              return (
                <Link
                  onClick={() => markRead([notification._id], true)}
                  to={notification.url}
                  key={notification._id}
                  className={`kt-notification__item ${clsx({
                    "unread": !notification.hasRead
                  })}`}
                >
                  <div className="kt-notification__item-details">
                    <div className="d-flex align-items-center w-100">
                      <div className="flex-grow-1">
                        <div className="kt-notification__item-title">
                          {notification.title}
                        </div>
                      </div>
                      <div className="text-dark" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(notification._id);
                      }}>
                        <span className="fas fa-times"></span>
                      </div>
                    </div>
                    <div
                      className="kt-notification__item-description"
                      dangerouslySetInnerHTML={{ __html: notification.description }}
                    />
                    <div className="kt-notification__item-time">
                      {moment(notification.createdAt).fromNow()}
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>

        {
          (!loading && !error && notifications.length < totalCount) && (
            <div
              className="mt-1 text-center text-primary cursor-pointer"
              onClick={() => onLoadMore()}
            >
              <FormattedMessage id="APP.NOTIFICATIONS.LOAD_MORE" />
            </div>
          )
        }
      </PerfectScrollbar>

      {
        loading && (
          <div className="mt-15 kt-spinner kt-spinner--center kt-spinner--md kt-spinner--brand"></div>
        )
      }
    </div>
  );
}

class QuickPanel extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line
    const panel = document.getElementById("kt_notification_list_panel");

    // eslint-disable-next-line
    this.offCanvas = new KTOffcanvas(panel, {
      overlay: true,
      baseClass: "kt-quick-panel",
      closeBy: "kt_notification_list_panel_close_btn",
      toggleBy: [
        "kt_notification_list_toggler_btn",
        "kt_notification_list_toggler_btn_mobile"
      ],
    });

    this.offCanvas.on('afterHide', () => {
      this.props.clearNotifications();
    });

    this.offCanvas.on('beforeShow', () => {
      this.props.loadNotifications();
    });
  }

  render() {
    const {
      loading,
      error,
      notifications,
      markNotificationsAsRead,
    } = this.props;

    return (
      <div id="kt_notification_list_panel" className="kt-quick-panel">
        <div className="d-flex justify-content-between align-items-center p-4">
          <div>
            <h3 className="m-0">
              <FormattedMessage id="APP.NOTIFICATIONS.TITLE" />
            </h3>
          </div>
          <div>
            <span className="cursor-pointer" id="kt_notification_list_panel_close_btn">
              <i className="fas fa-times fa-2x" />
            </span>
          </div>
        </div>

        <NotificationsList
          loading={loading}
          error={error}
          notifications={notifications.list}
          totalCount={notifications.total}
          onDelete={(notificationId) => this.props.deleteNotification(notificationId)}
          markRead={(notificationIds, shouldHideSidebar) => {
            markNotificationsAsRead(notificationIds);

            if (shouldHideSidebar) {
              this.offCanvas.hide();
            }
          }}
          onLoadMore={() => this.props.loadNotifications()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ flags, notifications }) => {
  return {
    loading: flags.loading[flagNames.LOADING_NOTIFICATIONS],
    error: flags.error[flagNames.LOADING_NOTIFICATIONS],
    notifications: notifications,
  };
}

export default connect(
  mapStateToProps,
  {
    clearNotifications,
    loadNotifications,
    markNotificationsAsRead,
    deleteNotification,
  }
)(QuickPanel);