import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { downloadFileFromUrl } from "../../../_metronic";
import { CloudDownloadIcon } from "../icons";

// import PDFPreviewDesktop from "./PDFPreviewDesktop";
// import PDFPreviewMobile from "./PDFPreviewMobile";

function PDFPreview ({ url, fileName = "file.pdf", containerClass }) {
	const [wasIframeLoaded, setIframeLoaded] = useState(false);
	const [isWaiting, setWaiting] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setWaiting(false);
		}, 10000);
	}, []);

	const handleIframeLoad = useCallback(() => {
		setIframeLoaded(true);
		setWaiting(false);
	}, [
		setIframeLoaded,
		setWaiting
	]);

	return (
		<object
			className={clsx("d-flex flex-column pdf-preview-container", containerClass)}
			data={url}
			type="application/pdf"
		>
			<iframe
				style={{ opacity: !isWaiting && wasIframeLoaded ? 1 : 0, maxHeight: !isWaiting && wasIframeLoaded ? "initial" : 1 }}
				title="preview"
				src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(url)}&rm=minimal`}
				className={clsx("pdf-preview-container", containerClass)}
				onLoad={handleIframeLoad}
			/>

			{
				isWaiting ? (
					<div className="d-flex flex-grow-1 justify-content-center align-items-center">
						<div className="kt-spinner kt-spinner--center kt-spinner--md kt-spinner--primary" />
					</div>
				) : (
						!wasIframeLoaded && (
							<div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
								<div className="text-center">
									<Typography variant="h6" className="mb-3">We were not able to load preview, you can download the file by pressing the button below</Typography>
								</div>
								<div>
									<button
										className={`btn btn-primary btn-elevate`}
										onClick={() => downloadFileFromUrl(url, fileName)}
									>
										<CloudDownloadIcon fill="white" />
										<span className="ml-2">Download</span>
									</button>
								</div>
							</div>
						)
					)
			}
		</object>
	)
}

export default PDFPreview;