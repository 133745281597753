import React from "react";
import {
	FormattedMessage,
} from "react-intl";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	Dialog,
	DialogTitle,
} from '@material-ui/core';
import {
	Alert,
} from 'reactstrap';
import clsx from "clsx";

import {
	updateProviderStatus,
	flagNames,
} from '../../store/modules/actions/provider.actions';
import Constants from "../../common/constants";
import { getFlags } from "../../store/modules/selectors/common.selector";
import { getDocuments, getProviderId, getProviderRequest } from "../../store/modules/selectors/provider.selector";

const canMarkCompleted = (request) => {
	if (request.status === Constants.ProviderStatus.COMPLETED) {
		return false;
	}

	const actedUponAllDocuments = request.documents
		.filter((doc) => doc.type === Constants.DocumentType.REGULAR)
		.every((doc) => {
			return [
				Constants.DocumentStatus.APPROVED,
				Constants.DocumentStatus.NOT_REQUIRED,
				Constants.DocumentStatus.UPLOADED,
			].includes(doc.status);
		});

	return actedUponAllDocuments;
};

function MarkCompleted(props) {
	const {
		handleClose,
	} = props;
	const dispatch = useDispatch();

	const flags = useSelector(getFlags);
	const documentRequest = useSelector(getProviderRequest);
	const providerId = useSelector(getProviderId);
	const documents = useSelector(getDocuments);

	const loading = flags.loading[flagNames.UPDATE_STATUS];
	const error = flags.error[flagNames.UPDATE_STATUS];
	let canMarkComplete = canMarkCompleted({ ...documentRequest, documents });

	const handleSubmit = async () => {
		//make api call to mark this provider completed
		await dispatch(
			updateProviderStatus(
				documentRequest._id,
				providerId,
				Constants.ProviderStatus.COMPLETED
			),
		)
		handleClose(true);
	};

	return (
		<Dialog
			open
			onClose={() => handleClose()}
			fullWidth
			maxWidth="xs"
			classes={{
				paper: "rounded-card"
			}}
			className="mark-not-required-container"
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>

			<div className="container p-20">
				<div>
					<div className="row f-15 label-4">
						<div className="col-10 offset-1">
							<h4 className="mb-0 text-center">
								<FormattedMessage id="PROVIDER.MARK_COMPLETED.TITLE"/>
							</h4>
						</div>
						<div className="col-1">
							<div className="float-right cursor-pointer" onClick={() => handleClose()}>
								<span className="fas fa-times"></span>
							</div>
						</div>
					</div>

					{
						!!error && (
							<div className="d-flex justify-content-center mt-15">
								<Alert color="danger" className="mb-0">
									{error}
								</Alert>
							</div>
						)
					}

					{
						canMarkComplete ? (
							<React.Fragment>
								<div className="d-flex justify-content-center mt-15">
									<FormattedMessage
										id="PROVIDER.MARK_COMPLETED.MARK_COMPLETE"
										values={documentRequest.user}
									/>
								</div>

								<div className="row mt-15">
									<div className="col-6 px-4">
										<button
											type="button"
											className={`btn btn-danger btn-block`}
											onClick={() => handleClose()}
										>
											<FormattedMessage id="GENERAL.NO" />
										</button>
									</div>
									<div className="col-6 px-4">
										<button
											type="submit"
											className={`btn btn-block btn-primary ${clsx({
												"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--right kt-spinner--primary": loading,
											})}`}
											onClick={handleSubmit}
										>
											<FormattedMessage id="GENERAL.YES" />
										</button>
									</div>
								</div>
							</React.Fragment>
						) : (
							<div className="d-flex justify-content-center mt-15">
								<Alert color="danger" className="mb-0">
									<FormattedMessage
										id="PROVIDER.MARK_COMPLETED.INCOMPLETE_REQUEST"
									/>
								</Alert>
							</div>
						)
					}
				</div>
			</div>
		</Dialog>
	);
}

export default MarkCompleted;