import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { Visualizer } from "react-sound-visualizer";

const useStyles = makeStyles((theme) => {
	return {
    canvas: {
      width: '100%',
      height: '80%',
      top: '5%',
      position: 'relative',
    }
  };
});

const AudioPreview = ({ stream }) => {
  const theme = useTheme();
  const classes = useStyles();

  if (!stream) {
    return null;
  }
  
	return (
    <Visualizer
      audio={stream}
      autoStart
      strokeColor={theme.palette.primary.main}
    >
      {({ canvasRef }) => (
        <canvas
          ref={canvasRef}
          className={classes.canvas}
        />
      )}
    </Visualizer>
  );
};

export default AudioPreview;