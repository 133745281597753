import { actionTypes } from "./actions/loading-flag.actions";

const initialState = {
	loading: {},
	error: {},
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LOADING_FLAG: {
			const { name, status } = action.payload;
			const { loading, error, } = state;

			return {
				...state,
				error: {
					...error,
					[name]: status ? null : error[name]
				},
				loading: {
					...loading,
					[name]: status
				}
			};
		}

		case actionTypes.SET_ERROR_FLAG: {
			const { name, message } = action.payload;
			const { error, loading } = state;

			return {
				...state,
				loading: {
					...loading,
					[name]: message ? false : loading[name]
				},
				error: {
					...error,
					[name]: message,
				}
			};
		}

		default:
			return state;
	}
};