import React, { forwardRef } from 'react';
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from 'linkifyjs/html'

const Note = forwardRef((props, _ref) => {
	const { data } = props;
	return (
		<div className="pr-0 f-14px text-dark font-weight-light mb-10px">
			<div
				className="white-space-pre-line break-word"
				dangerouslySetInnerHTML={{
					__html: linkifyHtml(data.content, {
						attributes: {
							target: "_blank",
							rel: "noopener noreferrer",
						}
					})
				}}
			/>
		</div>
	)
})

export default Note;
