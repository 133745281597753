import React, { useCallback } from "react";
import {
	FormattedHTMLMessage,
	FormattedMessage,
} from "react-intl";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	makeStyles
} from '@material-ui/core';
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { InfoIcon } from "../icons";
import { setUpgradeDialog } from "../../store/modules/actions/app.actions";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => {
	return {
		header: {
			height: "150px",
		},
		title: {
			fontWeight: 500,
			fontSize: '18px'
		},
		message: {
			fontSize: '14px'
		},
		cancelButton: {
			background: theme.palette.extraColors.gray,
		},
		actionButton: {
			minWidth: 145,
		}
	}
})

function UpgradePlan(props) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const message = useSelector((state) => state.app.upgradeModalMessageId);

	const handleClose = useCallback((wasSubmitted) => {
		dispatch(setUpgradeDialog(false));
		
		if (wasSubmitted) {
			history.push("/billing");
		}
	}, [
		dispatch,
		history,
	])

	return (
		<Dialog
			open={!!message}
			onClose={() => handleClose(false)}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle className="d-none">
				{' '}
			</DialogTitle>
			<DialogContent className="p-0">
				<div
					className={clsx(classes.header, "d-flex align-items-center justify-content-center header-container")}
					style={{ background: "#D8E0FF" }}
				>
					<InfoIcon />
				</div>
				
				<div className="text-center p-30px pb-0">
					<div className={clsx(classes.title)}>
						<FormattedMessage id="UPGRADE_MODAL.TITLE" />
					</div>

					{
						!!message && (
							<div className={clsx(classes.message, "mt-15px")}>
								<FormattedHTMLMessage id={message} />
							</div>
						)
					}
				</div>

				<div className={clsx(classes.buttonContainer, "d-flex justify-content-center align-items-center p-40px")}>
					<div>
						<button
							className={clsx(classes.actionButton, classes.cancelButton, "btn btn-lg btn-elevate py-3 mr-30px")}
							onClick={() => handleClose(false)}
						>
							<FormattedMessage id="GENERAL.DISMISS" />
						</button>
					</div>

					<div>
						<button
							className={clsx("btn btn-lg btn-primary btn-elevate py-3 text-white", classes.actionButton)}
							onClick={() => handleClose(true)}
						>
							<FormattedMessage id="GENERAL.UPGRADE" />
						</button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export default UpgradePlan;
