import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { ClickAwayListener, Icon, makeStyles } from "@material-ui/core";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

import ElementSettings from '../Settings';
import { CancelCircleIcon, PlusIcon } from "../../../icons";
import LightTooltip from "../../../LightTooltip";
import CustomAutocomplete from "../../../autocomplete";
import Constants from "../../../constants";
import { flagNames as requestFlags } from "../../../../store/modules/actions/requests.actions";
import { flagNames as templateFlags } from "../../../../store/modules/actions/templates.actions";
import { getDummyFileRenameStrings } from "../../../../../_metronic";

const useStyles = makeStyles((theme) => ({
	addFileRenameButton: {
		backgroundColor: "white !important",
		height: "28px !important",
		width: "28px !important",
		minHeight: "28px !important",

		"&:hover": {
			backgroundColor: "white !important",
		}
	},
	removeFileRenameButton: {
		position: "absolute !important",
		top: -9,
		right: 2,
		height: "18px !important",
		width: "18px !important",
		minHeight: "18px !important",
		backgroundColor: `${theme.palette.extraColors.grey} !important`,
		borderRadius: 9,

		'& svg': {
			position: 'relative',
			top: -2,
			left: -1,
		}
	},
	autoComplete: {
		background: theme.palette.extraColors.background,
	},
}));

function Settings({ id, data, onChange }) {
	const classes = useStyles();
	const intl = useIntl();
	const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
	const [fileRenameTooltipStatus, setFileRenameTooltipStatus] = useState(false);
	const [rootElement, setRootElement] = useState(null);
	const isSaving = useSelector((store) => {
		const {
      flags: {
        loading,
      },
    } = store;

		return !!loading[requestFlags.PROVIDER_UPDATE] ||
			!!loading[templateFlags.UPDATE_V2];
 	});

	const onFileRenameChange = useCallback((fileRename) => {
		onChange({
			...data,
			fileRename
		});
	}, [data, onChange]);

	const handleFileRenameAdd = useCallback(() => {
		onFileRenameChange([
			...data.fileRename,
			""
		]);
	}, [onFileRenameChange, data]);

	const handleFileRenameRemove = useCallback((index) => {
		onFileRenameChange(data.fileRename.filter((v, i) => i !== index));
	}, [onFileRenameChange, data]);

	const handleFileRenameChange = useCallback((index, newValue) => {
		onFileRenameChange(data.fileRename.map((value, i) => i !== index ? value : newValue))
	}, [onFileRenameChange, data]);

	const fileRenameHintStrings = useMemo(() => [
		data.fileRename.length > 0
			? intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.HINT_TITLE" })
			: intl.formatMessage({ id: "TEMPLATE.FILE_RENAME.EMPTY.HINT_TITLE" }),
		...getDummyFileRenameStrings(data.fileRename),
	], [ data, intl ]);

	useEffect(() => {
		// let timerHandle = null;

		// if (!rootElement) {
		// 	timerHandle = setInterval(() => {
				const rootElement = document.getElementById(`element-settings-container-${id}`);
				// if (rootElement) {
					setRootElement(rootElement);
		// 		}
		// 	}, 3000);
		// }

		// return () => {
		// 	clearInterval(timerHandle)
		// };
	}, [
		id,
		rootElement,
	]);

	if (!rootElement) return null;

	return ReactDOM.createPortal(
		<React.Fragment>
			<button
				className="btn btn-light btn-block d-flex align-items-center"
				onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
			>
				<Icon className="mr-2">settings</Icon>
				<span className="f-14px">
					<FormattedMessage id="ELEMENT_LIST.ITEM.SETTINGS" />
				</span>
			</button>

			<ElementSettings
				anchorEl={settingsAnchorEl}
				handleClose={() => setSettingsAnchorEl(null)}
			>
				<div className="d-flex align-items-center mb-15px">
					<div className="text-dark f-16px">
						<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.FILE_RENAME.LABEL" />
					</div>
					<div>
						<ClickAwayListener onClickAway={() => setFileRenameTooltipStatus(false)}>
							<LightTooltip
								className="ml-2"
								open={fileRenameTooltipStatus}
								title={
									<div>
										{fileRenameHintStrings.map((str, index) => <div key={index}>{str}</div>)}
									</div>
								}
								placement="right"
							>
								<span onClick={() => setFileRenameTooltipStatus(true)}>
									<i className="fas fa-info-circle cursor-pointer" />
								</span>
							</LightTooltip>
						</ClickAwayListener>
					</div>
					<div className="ml-2">
						<button
							className="btn btn-sm btn-primary d-flex px-2"
							onClick={handleFileRenameAdd}
							disabled={isSaving || data.fileRename.length >= Constants.FILE_RENAME_LIMIT}
						>
							<div className="mr-1">
								<PlusIcon fill="white" />
							</div>
							<div>
								<FormattedMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.FILE_RENAME.ADD" />
							</div>
						</button>
					</div>
				</div>
				
				<div className="row align-items-center">
					{
						data.fileRename.map((item, index) => {
							return (
								<div key={index} className="col-md-4 col-12 mb-15px">
									<CustomAutocomplete
										value={item}
										options={Constants.FILE_RENAME_OPTIONS}
										freeSolo
										disabled={isSaving}
										disableClearable
										classes={{
											inputRoot: `p-0 ${classes.autoComplete}`,
											input: "px-10px py-15px bg-transparent"
										}}
										onChange={(value) => handleFileRenameChange(index, value)}
										inputProps={{
											fullWidth: true,
											type: "text",
											variant: "outlined",
											disabled: isSaving,
											onBlur: (e) => {
												if (!item && e.target.value) {
													handleFileRenameChange(index, e.target.value)
												}
											}
										}}
									/>

									<div
										className={`shadow cursor-pointer ${classes.removeFileRenameButton}`}
										onClick={() => handleFileRenameRemove(index)}
									>
										<CancelCircleIcon
											size={20}
											fill="white"
										/>
									</div>
								</div>
							);
						})
					}
				</div>

				<div className="mt-40px text-light-text">
					<FormattedHTMLMessage id="ELEMENT_LIST.ITEM.FILE_UPLOAD.FILE_RENAME.HELP_TEXT" />
				</div>
			</ElementSettings>
		</React.Fragment>,
		rootElement
	);
}

export default Settings;