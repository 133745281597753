import { createIntl, createIntlCache } from "react-intl";

import deMessages from "./messages/de";
import enMessages from "./messages/en";
import esMessages from "./messages/es";
import frMessages from "./messages/fr";
import jaMessages from "./messages/ja";
import zhMessages from "./messages/zh";

const allMessages = {
	de: deMessages,
	en: enMessages,
	es: esMessages,
	fr: frMessages,
	ja: jaMessages,
	zh: zhMessages
};

const cache = createIntlCache();
export const kaddimIntl = {
	currentLocale: null,
	intlInstance: null,

	set locale(loc) {
		this.currentLocale = loc;
		this.intlInstance = createIntl(
			{
				locale: loc,
				messages: allMessages[loc],
			},
			cache
		);
	},
	get locale() {
		return this.currentLocale;
	},
	get intl() {
		return this.intlInstance;
	}
};
