import React, { useRef } from "react";
import moment from "moment";
import {
	FormattedHTMLMessage,
	FormattedMessage,
} from "react-intl";

function Title({ billingData }) {
	const currentDate = useRef(new Date());

	return (
		<div>
			<div className="f-20px text-dark mb-5px">
				{
					billingData.READ_ONLY ? (
						<FormattedMessage id="BILLING.TITLE.READ_ONLY_MODE" />
					) : (
						<FormattedHTMLMessage
							id="BILLING.TITLE.PLAN_DETAILS"
							values={{ planName: billingData.PLAN_INFO.name }}
						/>
					)
				}
			</div>

			{
				billingData.PLAN_INFO.expiryDate && (
					<div className="text-danger">
					{
						moment(currentDate).isBefore(billingData.PLAN_INFO.expiryDate) ? (
							<FormattedMessage
								id="BILLING.TITLE.EXPIRY_DETAILS"
								values={{
									monthDateString: moment(billingData.PLAN_INFO.expiryDate).format("MMMM DD"),
									relativeDate: moment.duration(moment(billingData.PLAN_INFO.expiryDate).diff(moment())).humanize()
								}}
							/>
						) : (
							<FormattedMessage
								id="BILLING.TITLE.EXPIRED_DETAILS"
								values={{
									monthDateString: moment(billingData.PLAN_INFO.expiryDate).format("MMMM DD"),
								}}
							/>
						)
					}
					</div>
				)
			}
		</div>
	);
}

export default Title;
