import React from "react";
import {
	useSelector
} from "react-redux";
import {
	FormattedMessage,
} from "react-intl";
import { getUser } from "../../../store/modules/selectors/account.selector";

function Title() {
	const user = useSelector(getUser);
	
	return (
		<div className="d-flex justify-content-center">
			<div className="f-25px fw-500 pt-45px mb-5px text-dark">
				<FormattedMessage id={`PRICING.TITLE.${user ? "USER" : "GUEST"}`} />
			</div>
		</div>
	);
}

export default Title;
