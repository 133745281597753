import React, { useEffect } from 'react';
import { AppBar, ClickAwayListener, IconButton, makeStyles, Paper, Popper, Toolbar, Typography, useTheme } from "@material-ui/core";
import { CloseOutlined } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {
	return {
		settingsModal: {
			width: 500,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
	};
});

export default function Settings ({ anchorEl, children, handleClose }) {
	const theme = useTheme();
	const classes = useStyles();
	const className = 'element-settings-visible';

	useEffect(() => {
		if (anchorEl) {
			document.body.classList.add(className);
		}
		else {
			document.body.classList.remove(className);
		}
	}, [anchorEl])

	return (
		<Popper
			open={!!anchorEl}
			anchorEl={anchorEl}
			transition
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Paper
					className={clsx(classes.settingsModal, "element-settings")}
					elevation={3}
					onMouseOut={(e) => {
						// NOTE: this is done to prevent mouse out event from bubbling out to UpdatableElement component
						// which if receives the event will try to show/hide action buttons which re-renders the element settings
						// and that constant re-rendering breaks the settings popup for file rename components
						e.stopPropagation();
					}}
				>
					<AppBar
						position="static"
						classes={{
							root: "rounded-top"
						}}
						style={{ backgroundColor: theme.palette.dark.primary.main }}
					>
						<Toolbar classes={{ root: "pr-1" }}>
							<div className="flex-grow-1">
								<Typography
									variant="h6"
									style={{ color: theme.palette.dark.primary.contrastText }}
								>
									<FormattedMessage
										id="ELEMENT_LIST.ITEM.SETTINGS"
									/>
								</Typography>
							</div>
							<div>
								<IconButton onClick={handleClose}>
									<CloseOutlined style={{ color: theme.palette.dark.primary.contrastText }} />
								</IconButton>
							</div>
						</Toolbar>
					</AppBar>
					<div className="p-4 bg-white rounded-bottom">
						{children}
					</div>
				</Paper>
			</ClickAwayListener>
		</Popper>
	)
}