import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../../../../_metronic";

import Pricing from './Pricing';

export default function PricingPage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Route path="/pricing" exact component={Pricing} />
			</Switch>
		</Suspense>
	);
}
