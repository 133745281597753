import { RadioGroup, FormControlLabel, makeStyles, TextareaAutosize } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
// react-linkify does not work with dangerouslySetInnerHTML so we're using linkifyjs instead
import linkifyHtml from "linkifyjs/html";

import { CUSTOM_OPTION_ID } from ".";
import Radio from "./Radio";
import useDebounce from "../../../useDebounce";

const useStyles = makeStyles((theme) => {
	return {
		customOptionContainer: {
			position: 'relative',
			left: '-2px',
		},
		customOptionRadio: {
			position: 'relative',
			top: 3,
		}
	};
});

const RadioButton = forwardRef((props, _ref) => {
	const {
		data,
		response,
		onChange,
	} = props;
	const intl = useIntl();
	const classes = useStyles();
	const customOptionInputRef = useRef();
	const hasCustomOption = useMemo(() => {
		return data.options.some(({ id }) => id === CUSTOM_OPTION_ID);
	}, [data.options]);

	const [customOptionTitle, setCustomOptionTitle] = useState(response.custom);
	const debouncedCustomTitle = useDebounce(customOptionTitle, 750);

	useEffect(() => {
		const newData = {
			...response,
			custom: debouncedCustomTitle,
		};

		onChange(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		debouncedCustomTitle,
	]);

	const handleOptionChange = useCallback((value) => {
		const newData = {
			...response,
			value,
		};

		onChange(newData);
	}, [onChange, response]);

	return (
		<div>
			<div className="mr-5 mr-md-0 pr-3">
				<span className="px-0 text-dark font-weight-medium f-18px">
					{data.title}
				</span>
			</div>

			{
				!!data.subtitle && (
					<div className="mb-10px">
						<span
							className="f-14px text-dark font-weight-regular white-space-pre-line break-word"
							dangerouslySetInnerHTML={{
								__html: linkifyHtml(data.subtitle, {
									attributes: {
										target: "_blank",
										rel: "noopener noreferrer",
									}
								})
							}}
						/>
					</div>
				)
			}

			<div className="row">
				<div className="col-12 col-md-8">
					<RadioGroup
						value={data.responseObj && data.responseObj.value}
						onChange={(e) => handleOptionChange(e.target.value)}
					>
						{
							data.options
							.filter(({ id }) => id !== CUSTOM_OPTION_ID)
							.map((option) => {
								const isSelected = response.value === option.id;

								return (
									<FormControlLabel
										key={option.id}
										label={option.title}
										value={option.id}
										classes={{
											root: "row",
											label: `f-16px text-dark ${isSelected ? 'font-weight-medium' : 'font-weight-regular'} col pl-0 break-word`
										}}
										control={
											<Radio
												className="py-0"
												color="primary"
												checked={isSelected}
											/>
										}
									/>
								)
							})
						}

						{
							hasCustomOption && (
								<div className={clsx(classes.customOptionContainer, "d-flex")}>
									<div className={classes.customOptionRadio}>
										<Radio
											className="py-0 pl-0 pr-2"
											checked={response.value === CUSTOM_OPTION_ID}
											color="primary"
											onClick={() => customOptionInputRef.current?.focus()}
											value={CUSTOM_OPTION_ID}
										/>
									</div>

									<div>
										<TextareaAutosize
											ref={(ref) => customOptionInputRef.current = ref}
											minRows={1}
											className={clsx(
												"span mb-0 f-16px text-dark w-100 resize-none border-0 bg-transparent",
												{
													"font-weight-medium": response.value === CUSTOM_OPTION_ID,
													"font-weight-regular": response.value !== CUSTOM_OPTION_ID,
												}
											)}
											onChange={(e) => setCustomOptionTitle(e.target.value)}
											defaultValue={customOptionTitle}
											placeholder={
												intl.formatMessage({
													id: "ELEMENT_LIST.ITEM.RADIO_BUTTONS.CHOICES.CUSTOM_CHOICE.TITLE.PLACEHOLDER"
												})
											}
										/>
									</div>
								</div>
							)
						}
					</RadioGroup>
				</div>
			</div>
		</div>
	)
})

export default RadioButton;
